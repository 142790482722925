import React, { useState, useEffect, useRef } from "react";
import "./Graphs.css";
import Graphs from "./Graphs";
import LabServices from "../../services/LabServices";
import loglevel from "../../logger/Logger";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import UtilityServices from "../utility/Utiity";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { Toast } from "primereact/toast";
import { Constants } from "../../constants/constants";

const ChartDashboard = () => {
  const toast = useRef(null);
  const [purposeUsageData, setPurposeUsageData] = useState([]);
  const [labUsageData, setLabUsageData] = useState([]);
  const [selectedDays, setSelectedDays] = useState({ filterByDays: "Last 15 days", days: 15 });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totalDays, setTotalDays] = useState(0)
  const [enable, setEnable] = useState(true);

  // dropdown options for changing days
  const filterDays = [
    { filterByDays: "Last 7 days", days: 7 },
    { filterByDays: "Last 15 days", days: 15 },
    { filterByDays: "Last 30 days", days: 30 },
    { filterByDays: "Last 90 days", days: 90 },
  ];

  // method called on changing start date
  const startDateChange = (e) => {
    setStartDate(e.target.value);
    console.log("StartD", e.value);
  };

  // method called on changing end date
  const endDateChange = (e) => {
    setEndDate(e.target.value);
    console.log("EndD", e.value);
  };

  // search button to search data based on start and end dates
  const searchHandle = async () => {
    let g1 = new Date(startDate);
    let g2 = new Date(endDate);

    if (g1.getTime() < g2.getTime()) {
      console.log("g1 is lesser than g2");

      const oneDay = 24 * 60 * 60 * 1000;

      const diffDays = Math.round(Math.abs((g1 - g2) / oneDay));

      console.log("### No. of days:", diffDays)
      if (diffDays + 1 > 30) {
        setEnable(true);
      } else {
        setEnable(false);
      }
      setTotalDays(diffDays + 1);
      let d1 = moment(startDate).format("YYYY-MM-DD");
      let d2 = moment(endDate).format("YYYY-MM-DD");

      let param = `?startDate=${d1}&endDate=${d2}`
      console.log("### param:", param)
      LabServices.getPurposeUsage(param).then((res) => {
        console.log("apiResss", res.data)
        setPurposeUsageData(res.data);
        console.log("user api response", res);
      })

      LabServices.getLabUsage(param).then((res) => {
        console.log("apiResss", res.data)
        setLabUsageData(res.data);
        console.log("user api response", res);
      })
    }
    else if (g1.getTime() > g2.getTime()) {
      console.log("g1 is greater than g2");
      return false;
    }
    else {
      console.log("both are equal");
      return false;
    }


  };

  // resetting fields to default on reset button click
  const resetHandle = async (e) => {
    setStartDate("");
    setEndDate("");
    setSelectedDays({ filterByDays: "Last 90 days", days: 90 });
    setEnable(true);
    let days = 90;
    setTotalDays(90);
    let param = `?days=${days}`

    LabServices.getPurposeUsage(param).then((res) => {
      console.log("apiResss", res.data)
      setPurposeUsageData(res.data);
      console.log("user api response", res);
    })

    LabServices.getLabUsage(param).then((res) => {
      console.log("apiResss", res.data)
      setLabUsageData(res.data);
      console.log("user api response", res);
    })
  };

  useEffect(() => {
    async function fetch() {
      let days = 15;
      let param = `?days=${days}`
      //api call for purpose usage pie chart
      await LabServices.getPurposeUsage(param).then((res) => {
        console.log("apiResss", res.data)
        setPurposeUsageData(res.data);
        console.log("user api response", res);
      })
      //api call for lab usage pie chart
      await LabServices.getLabUsage(param).then((res) => {
        console.log("apiResss", res.data)
        setLabUsageData(res.data);
        console.log("user api response", res);
      })

    }
    fetch()
  }, []);

  // method call on changing days from dropdown
  const onDaysChange = (e) => {
    console.log(e.value, "city")
    setSelectedDays(e.value);
    let days = 90;
    if (e.value.days === 7) {
      days = 7;
    }

    else if (e.value.days === 30) {
      days = 30;

    }
    else if (e.value.days === 90) {
      days = 90;

    } else {
      days = 15
    }
    let param = `?days=${days}`
    LabServices.getPurposeUsage(param).then((res) => {
      console.log("apiResss", res.data)
      setPurposeUsageData(res.data);
      console.log("user api response", res);
    })

    LabServices.getLabUsage(param).then((res) => {
      console.log("apiResss", res.data)
      setLabUsageData(res.data);
      console.log("user api response", res);
    })
  }

  // method call for generating pdf
  const generatePDF = (e) => {
    let days = selectedDays.days
    let input = window.document.getElementsByClassName("lab_purpose")[0];
    let filename = "lab_purpose_usage.pdf";
    let canvasType = "graph";
    UtilityServices.exportPDF(days, input, filename, canvasType, "Lab Usage And Purpose Lab");
    toast.current.show({
      severity: "success",
      summary: Constants.EXPORT_TOAST_SUMMARY,
      detail: Constants.EXPORT_TOAST_DETAIL,
      life: 6000,
    })
  };

  return (
    <div className="main-grid">
      <Toast ref={toast} position="top-center" />
      <div className="chart ht">
        <div className="parent-row">
          <div className="item-left-trend-lab">
            <div className="chart-panel-item">
              {/* Dropdown for days */}
              <Dropdown
                value={selectedDays}
                options={filterDays}
                onChange={onDaysChange}
                optionLabel="filterByDays"
                placeholder="Select quick dates"
                className='dropdown-days days-selection'
              /></div>
            <div className="chart-panel-item">
              {/* Start date */}
              <Calendar
                value={startDate}
                onChange={(e) => startDateChange(e)}
                dateFormat="mm/dd/yy"
                mask="99/99/9999"
                selectOtherMonths={true}
                placeholder="Select Start Date"
                icon="pi pi-calendar"
                showIcon
                className="date-icon mr-left-10"
              />
            </div>

            <div className="chart-panel-item">
              {/* End Date */}
              <Calendar
                value={endDate}
                onChange={(e) => endDateChange(e)}
                dateFormat="mm/dd/yy"
                mask="99/99/9999"
                selectOtherMonths={true}
                placeholder="Select End Date"
                icon="pi pi-calendar"
                showIcon
                className="date-icon mr-left-10"
              />
            </div>

          </div>
          <div className="item-right-trend-lab">
            {/* Search button */}
            <div className="chart-panel-item">
              <Button
                id="search-button"
                label="SEARCH"
                className="p-button-primary search-btn search-btn-trend"
                onClick={searchHandle}
              />
            </div>
            {/* Reset button */}
            <div className="chart-panel-item">
              <Button
                id="reset-button"
                label="RESET"
                className="p-button-primary reset-btn mr-left-10 reset-btn-trend"
                onClick={resetHandle}
              />
            </div>
            <div className="chart-panel-item">
              {/* Eport pdf button */}
              <Button
                id="export-button"
                icon="pi pi-file-pdf"
                label="EXPORT"
                className="p-button-primary reset-btn mr-left-10 mr-bottom-2"
                onClick={generatePDF}
              />
            </div>
          </div>
        </div>

        <div className="pie-chart chart-header lab_purpose">

          <Graphs
            id="graph"
            labs={labUsageData.lab_name}
            labCount={labUsageData.lab_usage_percentage}
            purpose={purposeUsageData.lab_name}
            purposeCount={purposeUsageData.lab_usage_percentage}
            className="chart lab_usage_chart"

          />
        </div>
      </div>
    </div>
  );
};

export default ChartDashboard;
