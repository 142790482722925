import React, { useState } from "react";
import DailyUtilizationReport from "../reports/utilization/DailyUtilizationReport";
import WeeklyUtilizationReport from "../reports/utilization/WeeklyUtilizationReport";
import DailyPercentUtilizationReport from "../reports/utilization/DailyPercentUtilizationReport";
import WeeklyPercentUtilizationReport from "../reports/utilization/WeeklyPercentUtilizationReport";
import LabCategories from "../rbac/lab-management/labcategory-crud/LabCategories";
import "./Admin.css";
import LabType from "../rbac/lab-management/labtype-crud/LabType";
import LabScheduleTab from "../rbac/lab-management/lab-crud/LabScheduleTab";

import { useGlobalState } from '../../config/state';
import ActiveLabsTab from "../rbac/lab-management/active-labs/ActiveLabsTab";

const UtilizationReportsManagement = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [isAdmin, setIsAdmin] = useGlobalState('isAdmin');

    const setIndex = (index) => {
        setActiveIndex(index);
    }

    return (
        <div>
            {(isAdmin) ? (
                <>
                    <div className="tabmenu">
                        {/* Daily Tab */}
                        <button
                            className="menuitem-link"
                            style={{
                                borderColor:
                                    activeIndex === 0
                                        ? "#979797 #979797 #192a32 #979797" //Border colour of tab when it is active
                                        : "#192a32 #192a32 #979797 #192a32", // Border colour of tab when it is inactive
                            }}
                            onClick={() => setIndex(0)} // on click Daily Tab
                            data-testid="all-labs-title"
                        >
                            <div className={activeIndex === 0 ? "active-tab" : "inactive-tab"}>  Daily</div>
                        </button>

                        {/* Weekly Tab */}
                        <button
                            className="menuitem-link"
                            style={{
                                borderColor:
                                    activeIndex === 1
                                        ? "#979797 #979797 #192a32 #979797" //Border colour of tab when it is active
                                        : "#192a32 #192a32 #979797 #192a32", //Border colour of tab when it is inactive
                            }}
                            onClick={() => setIndex(1)} // On click Weekly Tab
                        >
                            <div className={activeIndex === 1 ? "active-tab" : "inactive-tab"}>  Weekly</div>
                        </button>

                        {/* Percentage Daily Tab */}
                        <button
                            className="menuitem-link"
                            style={{
                                borderColor:
                                    activeIndex === 2
                                        ? "#979797 #979797 #192a32 #979797" //Border colour of tab when it is active
                                        : "#192a32 #192a32 #979797 #192a32", //Border colour of tab when it is inactive
                            }}
                            onClick={() => setIndex(2)} // on click Percentage Daily Tab
                            data-testid="all-labs-title"
                        >
                            <div className={activeIndex === 2 ? "active-tab" : "inactive-tab"}>  Percentage Daily</div>
                        </button>

                        {/* Percentage Weekly Tab */}
                        <button
                            className="menuitem-link"
                            style={{
                                borderColor:
                                    activeIndex === 3
                                        ? "#979797 #979797 #192a32 #979797" //Border colour of tab when it is active
                                        : "#192a32 #192a32 #979797 #192a32", //Border colour of tab when it is inactive
                            }}
                            onClick={() => setIndex(3)} // on click Percentage Weekly Tab
                            data-testid="all-labs-title"
                        >
                            <div className={activeIndex === 3 ? "active-tab" : "inactive-tab"}>  Percentage Weekly</div>
                        </button>
                    </div>
                    <div className="data">
                        {activeIndex === 0 ? <DailyUtilizationReport /> : ""}       {/*Daily Tab */}
                        {activeIndex === 1 ? <WeeklyUtilizationReport /> : ""}        {/*Weekly Tab */}
                        {activeIndex === 2 ? <DailyPercentUtilizationReport /> : ""}  {/*Percentage Daily Tab */}
                        {activeIndex === 3 ? <WeeklyPercentUtilizationReport /> : ""} {/*Percentage Weekly Tab */}
                    </div>
                </>
            ) : (
                window.location.assign("/not-found")
            )}
        </div>
    );
};

export default UtilizationReportsManagement;
