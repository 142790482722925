
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { confirmPopup } from "primereact/confirmpopup";
import parse from "html-react-parser";
import LabServices from "../../services/LabServices";
import loglevel from "../../logger/Logger";
import { Message } from "primereact/message";

const UpdateSettings = ({ configName, setChanger }) => {
  const [updateConfigDisplay, setupdateConfigDisplay] = useState(false);
  const updateCatDialogMap = {
    updateConfigDisplay: setupdateConfigDisplay,
  };
  const [cname, setCname] = useState("");
  const [description, setDescription] = useState("");
  const [cValue, setCValue] = useState("");
  const [isValidated, setIsValidated] = useState(false);
  const [commonErrorMsg, setCommonErrorMsg] = useState("");
  const [dataType, setDataType] = useState('string')
  const [defaultValue, setDefaultValue] = useState("")
  const toast = useRef(null);
  const [isReset, setIsReset] = useState(false)

  // opening dialog for updating config values
  const onClick = async (name) => {
    setCommonErrorMsg("");
    setCValue("")
    setCname("")
    setDefaultValue("")
    setDescription("")
    setIsValidated(false)
    // api call for getting details of cong name
    LabServices.getConfig(configName).then((res) => {
      setCname(res.data.config_name);
      setCValue(res.data.config_value)
      setDescription(res.data.description);
      setDataType(res.data.data_type)
      setDefaultValue(res.data.default_value)
    });

    updateCatDialogMap[`${name}`](true);
  };

  // closing dialog 
  const onHide = (name) => {
    setCValue("")
    setCname("")
    setDescription("")
    setIsValidated(false);
    setCValue("");
    setCommonErrorMsg("");
    setDataType('')
    setDefaultValue('')
    updateCatDialogMap[`${name}`](false);

  };


  const accept = async (requestBody) => {
    setIsReset(false)
    // api call for updating values
    await LabServices.updateConfig(requestBody)
      .then((response) => {
        if (response.status === 200) {
          toast.current.show({
            severity: "success",
            summary: "Configuration Successfully Updated",
            detail: "The Configuration has been successfully updated.",
            life: 6000,
          });
          updateCatDialogMap["updateConfigDisplay"](false);

        } else {
          loglevel.debug("Category Updation is Failed");
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setCommonErrorMsg(error.response.data.msg);
        }
      });

      // refreshing config list in datatable with updated value
    await LabServices.getConfigList().then((res) => {
      setChanger(res.data);
    });
  };

  // method call on clicking update button
  const onUpdate = (e) => {
    setIsValidated(true);
    <Toast ref={toast} position="top-center" />;
    if (cname && description && cValue) {
      const requestBody = {
        config_name: `${cname}`,
        description: `${description}`,
        config_value: dataType === "Integer" ? parseInt(cValue) : cValue,
        updated_by: localStorage.getItem("users_id")
      };

      var confirmationMsg = `Want to save changes?`;

      // confirmation before updating values
      confirmPopup({
        target: e.currentTarget,
        message: parse(`${confirmationMsg}`),
        icon: "pi pi-exclamation-triangle",
        className: "popup",
        acceptLabel: "YES",
        rejectLabel: "NO",
        accept: () => accept(requestBody),
      });
    }
  };

  // method call when changing value of any config
  const onConFigValueChange = (e) => {
    if (dataType === "Integer" && parseInt(e.target.value) > 0) {
      setCValue(parseInt(e.target.value))
    } else if (dataType === "Integer" && parseInt(e.target.value) === 0) {
      setCValue('')
    } else {
      setCValue(e.target.value)
    }

  }

  // resetting configuration value to default
  const onReset = () => {
    if (dataType === "Integer") {
      setCValue(parseInt(defaultValue))
    }
    else {
      setCValue(defaultValue)
    }

    setIsReset(true)

  }

  // footer of update dialog box
  const renderFooter = (name) => {
    return (
      <div className={"footer-buttons"}>
        <div className="reset-btn-section">
          <Button label="RESET" onClick={onReset} className="p-button-primary" />
        </div>
        <div className={"schedule-lab-button"}>
          <Button
            data-testid="bt-schedule-test"
            onClick={(e) => onUpdate(e)}

          >
            <span className="name">UPDATE</span>
          </Button>
        </div>

      </div>
    );
  };

  return (
    <div className="user-tag-edit">
      <Toast ref={toast} position="top-center" />
      <Button
        icon="pi pi-pencil"
        className="p-button-rounded p-button-success ml-2"
        onClick={() => onClick("updateConfigDisplay")}
      />
      {/* Dialog box */}
      <Dialog
        header={`Edit - ${configName}`}
        visible={updateConfigDisplay}
        footer={renderFooter("updateConfigDisplay")}
        onHide={() => onHide("updateConfigDisplay")}
        className="config-edit-dialog-box"
      >
        {commonErrorMsg && <Message severity="warn" text={commonErrorMsg} className='common-inline-error common-error-message p-mb-3' />}
        <div className="dialog-content">
          <div className="name-input">
            <div className="config-name-label">
              Configuration Name:
              <span className="cat-required-field">*</span>
            </div>
            <div>
              <InputText
                value={cname}
                placeholder="Enter Configuration Name"
                maxLength={30}
                className={
                  !cname && isValidated
                    ? "cat-input-error"
                    : "edit-cat-name-text-box"
                }
                disabled={true}
              />
              {!cname && isValidated ? (
                <div className="cat-error-message-inline">
                  <small className="cat-error-message" id="error-message">
                    Configuration Name is mandatory
                  </small>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="cat-description">
            <div className="config-description-label">
              Configuration Description:
              <span className="cat-required-field">*</span>
            </div>
            <div>
              <InputTextarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                maxLength={1000}
                rows={2}
                cols={40}
                placeholder="Enter Configuration Description"
                autoResize={true}
                className={
                  !description && isValidated
                    ? "config-desc-input-error"
                    : "config-desc-box"
                }
              />
              {!description && isValidated ? (
                <div>
                  <small className="cat-error-message" id="error-message">
                    Configuration description is mandatory
                  </small>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div >
            <div className="name-input">
              <div className="config-value-label">Configuration Value:
                <span className="cat-required-field">*</span>
              </div>
              <div>
                <InputText
                  value={cValue}
                  onChange={(e) => onConFigValueChange(e)}
                  placeholder="Configuration Value"
                  className="edit-config-value-text-box"
                  keyfilter={dataType === "Integer" ? "pint" : ""}
                />
                {!cValue && isValidated ? (
                  <div >
                    <small className="cat-error-message" id="error-message">
                      Configuration value is mandatory
                    </small>
                  </div>
                ) : (
                  isReset ? <div className="reset-success-message">
                    <div className="reset-success-icon">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4ZM12 18.6667C8.3181 18.6667 5.33333 15.6819 5.33333 12C5.33333 8.3181 8.3181 5.33333 12 5.33333C15.6819 5.33333 18.6667 8.3181 18.6667 12C18.6667 13.7681 17.9643 15.4638 16.714 16.714C15.4638 17.9643 13.7681 18.6667 12 18.6667ZM7.56 12.4933L10.8933 15.8267L16.3533 10.3667C16.5799 10.1022 16.5646 9.70787 16.3184 9.46163C16.0721 9.21538 15.6778 9.20015 15.4133 9.42667L10.8933 13.9467L8.5 11.5533C8.23549 11.3268 7.8412 11.342 7.59496 11.5883C7.34871 11.8345 7.33348 12.2288 7.56 12.4933Z" fill="#60B515" />
                      </svg>

                    </div>
                    <div className="configuration-success-message" >
                      The value has been reset successfully.
                    </div>
                  </div> : ""
                )}
              </div>
            </div>

          </div>

        </div>
      </Dialog>
    </div>
  );
};

export default UpdateSettings;
