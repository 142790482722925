import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import "../../Rbac.css";
import "../InventoryMgmt.css"
import React, { useState, useRef, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { confirmPopup } from "primereact/confirmpopup";
import parse from "html-react-parser";
import LabServices from "../../../../services/LabServices";
import { Dropdown } from "primereact/dropdown";
import loglevel from "../../../../logger/Logger";
import { InputTextarea } from "primereact/inputtextarea";
import { Message } from "primereact/message";



const UpdateServerModel = ({ id, serverName, setChanger }) => {
    const [isValidated, setIsValidated] = useState(false);
    const [updateServerModelDisplay, setUpdateServerModelDisplay] = useState(false);
    const [isAddManufacturerVisible, setIsAddManufacturerVisible] = useState(false);
    const [manufacturersList, setManufacturersList] = useState([]);
    const [modelDesc, setModelDesc] = useState('');
    const [manufacturer, setManufacturer] = useState('');
    const [newManufacturer, setNewManufacturer] = useState('');
    const [modelNum, setModelNum] = useState('');
    const [modelName, setModelName] = useState('');
    const [serverErrorMsg, setServerErrorMsg] = useState("");


    const toast = useRef(null);


    const updateServerModelDialogMap = {
        updateServerModelDisplay: setUpdateServerModelDisplay,
    };

    const onClick = async (name) => {
        setIsAddManufacturerVisible(false)
        setNewManufacturer('')
        
        await LabServices.getManufacturers()
        .then((res) => {
            
            setManufacturersList(res.data)
        })
        .catch((error) => {
            loglevel.error("Error while fetching Manufacturers list", error);
        });
        // <------ Get server model details by id -----  >>
        await LabServices.getServerModelById(id).then((res) => {
            setModelName(res.data.name);
            setModelDesc(res.data.description);
            setManufacturer(res.data.manufacturer);
            setModelNum(res.data.model_no);
            setIsValidated(false);

        });
        

        updateServerModelDialogMap[`${name}`](true);
    }

    const onShowDialog=(name)=>{
        setIsAddManufacturerVisible(false)
        setNewManufacturer('')
        updateServerModelDialogMap[`${name}`](true);
    }

    const onHide = (name) => {
        setModelName('');
        setModelDesc('');
        setManufacturer('');
        setNewManufacturer('');
        setModelNum('');
        setIsValidated(false);
        setIsAddManufacturerVisible(false);
        setServerErrorMsg('');


        updateServerModelDialogMap[`${name}`](false);
    };

    const accept = async (requestBody) => {

        await LabServices.updateServerModel(id, requestBody)
            .then((response) => {
                if (response.status === 200) {
                    toast.current.show({
                        severity: "success",
                        summary: "Server Model Successfully updated",
                        detail: "Server Model has been successfully updated.",
                        life: 6000,
                    });
                    setIsAddManufacturerVisible(false)
                    updateServerModelDialogMap["updateServerModelDisplay"](false);
                } 
            })
            .catch((error) => {
        loglevel.error("Server Model updation failure", error.response.data);
                if (error.response.status === 400) {
                setServerErrorMsg(error.response.data.msg);
                    // toast.current.show({
                    //     severity: "error",
                    //     summary: "Server Model could not be updated",
                    //     detail: `${error.response.data.msg}`,
                    //     sticky: true
                    // });
                }
            })

        await LabServices.getServerModelsList().then((res) => {
            console.log(res.data, "Server Model list");
            setChanger(res.data);
        });

        

    };

    const onUpdate = async (e) => {
        setIsValidated(true);
        <Toast ref={toast} position="top-center"/>;


        if (modelName && modelNum && (manufacturer || newManufacturer)) {
            const requestBody = {
                name: modelName,
                model_no: modelNum,
                manufacturer: newManufacturer || manufacturer,
                description: modelDesc
            }

            var confirmationMsg = `Want to update the changes?`;

            confirmPopup({
                target: e.currentTarget,
                message: parse(`${confirmationMsg}`),
                icon: "pi pi-exclamation-triangle",
                className: "popup",
                acceptLabel: "YES",
                rejectLabel: "NO",
                accept: () => accept(requestBody),
            });
        };
    };

    const onNewManufacturerSelect = (e) => {
        setManufacturer('')
        setNewManufacturer(e.target.value)
      }

    const renderFooter = (name) => {
        return (
            <div>
                <Button
                    // label="Update"
                    className="calender-button"
                    style={{ background: "#49AFD9" }}
                    onClick={(e) => onUpdate(e)}
                >
                    <span className="name">UPDATE</span>
                </Button>
            </div>
        );
    };

    const visibleManufacturer=()=>{
      setIsAddManufacturerVisible(true)
    }

    const notVisibleManufacturer=()=>{
        setIsAddManufacturerVisible(false)
    }

    return (
        <div className=".asset-panel-content">
            <Toast ref={toast} position="top-center"/>
            <div className=".asset-panel">
                <div className=".asset-panel-item">
                    <div className="create-asset-button">
                        <Button
                            id="reset-button"
                            onClick={() => onClick("updateServerModelDisplay")}
                            icon="pi pi-pencil"
                            className="p-button-rounded p-button-success mr-2"
                        />
                    </div>
                </div>
            </div>
            <Dialog
                header={`Update Server Model : ${serverName}`}
                visible={updateServerModelDisplay}
                footer={renderFooter("updateServerModelDisplay")}
                onHide={() => onHide("updateServerModelDisplay")}
                onShow={() => onShowDialog("updateServerModelDisplay")}
                className="add-asset-dialog-box"
            >
      {serverErrorMsg && <Message severity="warn" text={serverErrorMsg} className='common-inline-error common-error-message p-mb-3'/>}

                {/*  --------------------------Model Name:------------------------------ */}
                <div className="asset-input-text">
                    <div className="asset-label">Model Name<span className="asset-required-field">*</span> :
                    </div>
                    <div>
                        <InputText
                            value={modelName}
                            onChange={(e) => setModelName(e.target.value)}
                            placeholder="Enter Model Name"
                            maxLength={20}
                            className={!modelName && isValidated ? "asset-input-error asset-text-box" : "asset-text-box"}
                        />
                        {!modelName && isValidated ? (
                            <div className="cat-error-message-inline">
                                <small className="p-error p-d-block" id="error-message">Model name is mandatory</small>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>

                {/*  --------------------------Description:------------------------------- */}
                <div className="asset-input-text">
                    <div className="asset-label">Description
                        <span className="optional-fields"> (Optional)</span> :
                    </div>
                    <div>
                        <InputTextarea rows={5} cols={30} 
                            value={modelDesc}
                            onChange={(e) => setModelDesc(e.target.value)}
                            placeholder="Enter Description"
                            className="model-text-area"
                        />
                    </div>
                </div>
                {/*  ----------------------------Model No.:----------------------------- */}
                <div className="asset-input-text">
                    <div className="asset-label">Model No.
                        <span className="asset-required-field">*</span> :
                    </div>
                    <div>
                        <InputText
                            value={modelNum}
                            onChange={(e) => setModelNum(e.target.value)}
                            placeholder="Enter Model No."
                            maxLength={20}
                            className={!modelNum && isValidated ? "asset-input-error asset-text-box" : "asset-text-box"}
                        />
                        {!modelNum && isValidated ? (
                            <div className="cat-error-message-inline">
                                <small className="p-error p-d-block" id="error-message">Model No. is mandatory</small>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>

                {/*  -------------------------Manufacturer:-------------------------------- */}
                <div className="asset-input-text">
                    <div className="asset-label">Manufacturer
                        <span className="asset-required-field">*</span> :
                    </div>
                    <div className="tags-container">
                        <div>
                            <Dropdown
                                value={manufacturer}
                                name="asset-dropdown"
                                disabled = {newManufacturer ? true : false}
                                options={manufacturersList}
                                onChange={(e) => setManufacturer(e.target.value)}
                                placeholder="Choose Manufacturer"
                                className={!(manufacturer || newManufacturer) && isValidated ? "asset-input-error asset-dropdown" : "asset-dropdown"}
                                allowDuplicate={false}
                            />
                            {!(manufacturer || newManufacturer) && isValidated ? (
                                <div className="cat-error-message-inline">
                                    <small className="p-error p-d-block" id="error-message">Manufacturer is mandatory</small>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        {isAddManufacturerVisible ?
                            <div className="add-tag-ip-btn">
                                <div>
                                    <InputText
                                        value={newManufacturer}
                                        onChange={(e) => onNewManufacturerSelect(e)}
                                        placeholder="Enter Manufacturer Name"
                                        maxLength={20}
                                        className="model-additional-text-box"
                                    />
                                </div>
                                <div className="asset-plus-icon">
                                    <i
                                        className="pi pi-times-circle"
                                        style={{ fontSize: "1.4em", color: "#ADBBC4" }}
                                        onClick={() => notVisibleManufacturer()}
                                    ></i>
                                </div>
                            </div>
                            :  <div className="asset-plus-icon">
                            <i
                                className="pi pi-plus-circle"
                                style={{ fontSize: "1.4em", color: "#ADBBC4" }}
                                onClick={() => visibleManufacturer()}
                            ></i>
                        </div>}
                        
                    </div>
                </div>

            </Dialog>
        </div>
    );
};

export default UpdateServerModel;
