import React, { useState, useEffect, useRef } from 'react';
import './Graphs.css'
import { Dropdown } from "primereact/dropdown";
import { Chart } from 'primereact/chart';
import LabServices from '../../services/LabServices';
import UtilityServices from "../utility/Utiity";
import { Button } from "primereact/button";
import { Calendar } from 'primereact/calendar';
import moment from "moment";
import { Toast } from "primereact/toast";
import { Constants } from "../../constants/constants";

const LineStylesChart = () => {
    const toast = useRef(null);
    const [selectedDays, setSelectedDays] = useState(
        { filterByDays: "Last 90 days", days: 90 },
    );
    const [data, setData] = useState([])
    const [dates, setDates] = useState([])
    const [visitedData, setVisitedData] = useState([])
    const [visitedDates, setVisitedDates] = useState([])
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [totalDays, setTotalDays] = useState(0)
    const [enable, setEnable] = useState(true);

    // Dropdown options for different days
    const filterDays = [
        { filterByDays: "Last 7 days", days: 7 },
        { filterByDays: "Last 15 days", days: 15 },
        { filterByDays: "Last 30 days", days: 30 },
        { filterByDays: "Last 90 days", days: 90 },
    ];

    // method call on changing start date
    const startDateChange = (e) => {
        setStartDate(e.target.value);
        console.log("StartD", e.value);
    };

    // method call on changing end date
    const endDateChange = (e) => {
        setEndDate(e.target.value);
        console.log("EndD", e.value);
    };

    //search button
    const searchHandle = async () => {
        let g1 = new Date(startDate);
        let g2 = new Date(endDate);

        // start date is less than end date
        if (g1.getTime() < g2.getTime()) {
            const oneDay = 24 * 60 * 60 * 1000;
            const diffDays = Math.round(Math.abs((g1 - g2) / oneDay));
            if (diffDays + 1 > 30) {
                setEnable(true);
            } else {
                setEnable(false);
            }
            setTotalDays(diffDays + 1);
            let d1 = moment(startDate).format("YYYY-MM-DD");
            let d2 = moment(endDate).format("YYYY-MM-DD");
            let param = `?startDate=${d1}&endDate=${d2}`
            LabServices.getPortalUsage(param).then((res) => {
                setData(res.data);
                if (res.data.length > 0)
                    setDates(res.data[0].dates);
            })

            LabServices.getVisited(param).then((res) => {
                setVisitedData(res.data);
                if (res.data.length > 0)
                    setVisitedDates(res.data[0].dates);
            })
        }
        else if (g1.getTime() > g2.getTime()) { // if start date is greater than end date
            return false;
        }
        else { // if start and end dates are equal
            return false;
        }


    };

    // method call on reset button press 
    const resetHandle = async (e) => {
        setStartDate("");
        setEndDate("");
        setSelectedDays({ filterByDays: "Last 90 days", days: 90 });
        setEnable(true);
        let days = 90;
        setTotalDays(90);
        getPortal();
    };

    const getPortal = async () => {
        let days = 90;
        let param = `?days=${days}`
        // api call for portal usage data
        LabServices.getPortalUsage(param).then((res) => {
            console.log("apiResss", res.data)
            setData(res.data);
            if (res.data.length > 0)
                setDates(res.data[0].dates);
            console.log("user api response", res);
        })

        //api call for loged in user
        LabServices.getVisited(param).then((res) => {
            console.log("apiResss", res.data)
            setVisitedData(res.data);
            if (res.data.length > 0)
                setVisitedDates(res.data[0].dates);
            console.log("user api response", res);
        })
    }
    useEffect(() => {
        getPortal();
    }, []);

    const COLOR = ['#FFBB28', '#0088FE']
    const COLORS = ['red', 'green', '#FF8042', '#FFBB28']
    const records = []
    const records2 = []
    console.log(data)

    // method call on changing days from dropdown
    const onDaysChange = (e) => {
        console.log(e.value, "city")
        setSelectedDays(e.value);
        let days = 90;
        if (e.value.days === 7) {
            days = 7;
            setTotalDays(7);
            setEnable(false)

        }
        else if (e.value.days === 15) {
            days = 15;
            setTotalDays(15);
            setEnable(false)

        }
        else if (e.value.days === 30) {
            days = 30;
            setTotalDays(30);
            setEnable(false)

        }
        else if (e.value.days === 90) {
            days = 90;
            setTotalDays(90);
            setEnable(true)

        }
        let param = `?days=${days}`
        LabServices.getPortalUsage(param).then((res) => {
            setData(res.data);
            if (res.data.length > 0)
                setDates(res.data[0].dates);
        })

        LabServices.getVisited(param).then((res) => {
            setVisitedData(res.data);
            if (res.data.length > 0)
                setVisitedDates(res.data[0].dates);
        })
    }

    // for generating pdf
    const generatePDF = (e) => {
        console.log('Inside generate PDF')
        let days = selectedDays.days;
        let input = window.document.getElementsByClassName("portal-usage")[0];
        let filename = "portal_usage.pdf";
        let canvasType = "graph";
        UtilityServices.exportPDF(days, input, filename, canvasType, "Portal Usage");
        toast.current.show({
            severity: "success",
            summary: Constants.EXPORT_TOAST_SUMMARY,
            detail: Constants.EXPORT_TOAST_DETAIL,
            life: 6000,
        })
    };

    for (let i = 0; i < data.length; i += 1) {
        records.push(data[i])
        console.log(records)
        records[i].fill = true;
        records[i].borderColor = COLORS[i % COLORS.length];
        records[i].backgroundColor = COLORS[i % COLORS.length];
        records[i].tension = .4;
        records[i].label = data[i].title
        records[i].data = data[i].count
    }

    for (let i = 0; i < visitedData.length; i += 1) {
        records2.push(visitedData[i])
        console.log(records2)
        records2[i].fill = false;
        records2[i].borderColor = COLOR[i % COLOR.length];
        records2[i].backgroundColor = COLOR[i % COLOR.length];
        records2[i].tension = .4;
        records2[i].label = visitedData[i].title
        records2[i].data = visitedData[i].count
    }

   // pluging for line chart
    const lineOptions = {
        maintainAspectRatio: false,
        plugins: {

            legend: {
                display: true,
                labels: {
                    color: "white",
                    boxWidth: 20,
                    padding: 30,
                },
            },
            datalabels: {
                anchor: 'end',
                align: 'center'
            }

        },
        scales: {
            y: {

                ticks: {
                    stepSize: 10,
                    color: '#ebedef'
                },

                grid: {
                    color: '#495057'
                },

            },
            x: {

                ticks: {
                    color: '#ebedef'
                },


            }
        }
    }

    // plugins for stacked bar chart
    const stackedOptions = {
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
            intersect: false,
            mode: 'index',
        },
        plugins: {
            tooltip: {
                callbacks: {}
            },
            legend: {
                display: true,
                labels: {
                    color: "white",
                    boxWidth: 20,
                    padding: 30,
                },
            },
            datalabels: {
                anchor: 'end',
                align: 'center'
            }

        },
        scales: {
            y: {
                stacked: true,
                ticks: {
                    stepSize: 10,
                    color: '#ebedef'
                },

                grid: {
                    color: '#495057'
                },
            },
            x: {
                stacked: true,
                ticks: {
                    color: '#ebedef'
                },
            }
        }
    }

    for (let i = 0; i < records.length; i += 1) {
        console.log(records[i], "i=" + i)
    }


    const stackedBar = {
        labels: dates,
        datasets: records
    };

    const lineChart = {
        labels: visitedDates,
        datasets: records2
    }

    return (
        <div className='main-grid'>
            <Toast ref={toast} position="top-center" />
            <div className='portal-chart ht portal-usage-height'>
                <div className="parent-row">
                    <div className="item-left-trend-lab">
                        {/* Dropdown for days */}
                        <div className="chart-panel-item">
                            <Dropdown
                                value={selectedDays}
                                options={filterDays}
                                onChange={onDaysChange}
                                optionLabel="filterByDays"
                                placeholder="Select quick dates"
                                className='dropdown-days days-selection'
                            /></div>
                            {/* start date */}
                        <div className="chart-panel-item">
                            <Calendar
                                value={startDate}
                                onChange={(e) => startDateChange(e)}
                                dateFormat="mm/dd/yy"
                                mask="99/99/9999"
                                selectOtherMonths={true}
                                placeholder="Select Start Date"
                                icon="pi pi-calendar"
                                showIcon
                                className="date-icon mr-left-10"
                            /></div>
                            {/* end date */}
                        <div className="chart-panel-item">
                            <Calendar
                                value={endDate}
                                onChange={(e) => endDateChange(e)}
                                dateFormat="mm/dd/yy"
                                mask="99/99/9999"
                                selectOtherMonths={true}
                                placeholder="Select End Date"
                                icon="pi pi-calendar"
                                showIcon
                                className="date-icon mr-left-10"
                            /></div>
                    </div>
                    <div className="item-right-trend-lab">
                        {/* search button */}
                        <div className="chart-panel-item">
                            <Button
                                id="search-button"
                                label="SEARCH"
                                className="p-button-primary search-btn search-btn-trend"
                                onClick={searchHandle}
                            /></div>
                            {/* reset button */}
                        <div className="chart-panel-item">
                            <Button
                                id="reset-button"
                                label="RESET"
                                className="p-button-primary reset-btn mr-left-10 search-btn-trend"
                                onClick={resetHandle}
                            /></div>
                            {/* export button */}
                        <div className="chart-panel-item">
                            <Button
                                id="export-button"
                                icon="pi pi-file-pdf"
                                label="EXPORT"
                                className="p-button-primary reset-btn mr-left-10 mr-bottom-2"
                                onClick={generatePDF}
                            />
                        </div>
                    </div>
                </div>
                    <div className='bar-label child chart-header'>Portal Usage</div>
                    <div className='portal-usage'>
                        <Chart type="line" data={lineChart} options={lineOptions} height="17rem" width="38vw" margin="1rem" />
                        <Chart type="bar" data={stackedBar} options={stackedOptions} height="17rem" width="38vw" margin="1rem" />
                    </div>
                    <div className="legend-info">Click over legends, to show/hide chart elements</div>


            </div>
        </div>
    )
}
export default LineStylesChart