import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Message } from "primereact/message";
import { Toast } from "primereact/toast";
import { useRef, useState } from "react";
import LabServices from "../../../../services/LabServices";
import "../../Rbac.css"
import loglevel from "../../../../logger/Logger";


const DeleteLabSchedule = ({ schedule_id, fetchData, lab_name, lab_owner, showSuccessToast, showFailureToast }) => {
    const [deleteLabScheduleDisplay, setDeleteLabScheduleDisplay] = useState(false);
    const deleteLabScheduleDialogMap = {
        deleteLabScheduleDisplay: setDeleteLabScheduleDisplay,
    };
    const [checked, setChecked] = useState(false);
    const [commonErrorMsg, setCommonErrorMsg] = useState("");
    const toast = useRef(null);
    const errorRef = useRef();

    //on opening delete lab schedule Dialog
    const onShow = (name) => {
        setChecked(false);
        setCommonErrorMsg("");
        deleteLabScheduleDialogMap[`${name}`](true);
    };

    //on closing Delete lab schedule dialog
    const onHide = (name) => {
        deleteLabScheduleDialogMap[`${name}`](false);
        setChecked(false);
        setCommonErrorMsg("");
    };

    //method to update the list
    const updateList = () => fetchData()

    //method to show success toast
    const displaySuccessToast = (detail) => showSuccessToast(detail)

    //method to show failure toast
    const displayFailureToast = (error) => showFailureToast(error)

    const onDelete = (e) => {
        if (checked) {
            //api call for deleting lab schedule
            LabServices.deleteSchedule(schedule_id)
                .then((response) => {
                    updateList();
                    //toast nessage after successful lab schedule deletion
                    displaySuccessToast(`The lab schedule named "${lab_name}" has been successfully deleted.`)
                    deleteLabScheduleDialogMap["deleteLabScheduleDisplay"](false);
                })
                .catch((error) => {
                    if (error.response.status === 400) {
                        setCommonErrorMsg(error.response.data.msg);
                        errorRef.current.scrollIntoView({ behavior: "smooth" });
                    }
                });
        }
    }

    const renderFooter = (name) => {
        return (
            <div className="delete-btn-section">
                {/* Delete button */}
                <Button
                    label="DELETE LAB SCHEDULE"
                    className={
                        checked ? "p-button-outlined p-button-danger" : "p-button-outlined p-button-secondary"
                    }
                    onClick={(e) => onDelete(e)}
                    disabled={!checked}
                >
                </Button>
                {/* Cancel button */}
                <Button
                    className="p-button-primary"
                    label="CANCEL"
                    onClick={() => onHide(name)}
                />
            </div>
        );
    };

    return (
        <div>
            <Toast ref={toast} position="top-center" />
            <Button
                icon="pi pi-trash"
                className="p-button-rounded global-delete-button"
                onClick={() => onShow("deleteLabScheduleDisplay")}
            />

            <Dialog
                header={`Delete Lab Schedule : ${lab_name}`}
                visible={deleteLabScheduleDisplay}
                footer={renderFooter("deleteLabScheduleDisplay")}
                onHide={() => onHide("deleteLabScheduleDisplay")}
                className="tag-delete-dialog-box"
            >
                <div ref={errorRef} className={commonErrorMsg ? "" : "hidden"}>
                    {commonErrorMsg && (
                        <Message
                            severity="warn"
                            text={commonErrorMsg}
                            className="common-inline-error common-error-message p-mb-3"
                        />
                    )}
                </div>{" "}
                <div>
                    If you delete the lab schedule, it will cancel any pending processor jobs & remove all records from portal.
                </div>
                <br></br>
                <div>
                    {/* checkbox */}
                    <Checkbox
                        inputId="binary"
                        className=".checkbox"
                        checked={checked}
                        onChange={(e) => setChecked(e.checked)}
                    />
                    {"  "} I understand. Still want to delete the Lab Schedule.
                </div>
            </Dialog>
        </div>
    );
};

export default DeleteLabSchedule