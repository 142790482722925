import React, { useRef, useState } from 'react'
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from "primereact/dialog";
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import loglevel from "../../../logger/Logger";
import GuideService from '../../../services/GuideService';
import parse from "html-react-parser";
import { confirmPopup } from 'primereact/confirmpopup';

const UpdateAdminGuides = ({ adminGuideId, setAlladminGuideDetails }) => {
  const toast = useRef(null);
  const errorRef = useRef(null);
  const [guideName, setGuideName] = useState("");
  const [updateAdminGuideDisplay, setUpdateAdminGuideDisplay] = useState(false);
  const [commonErrorMsg, setCommonErrorMsg] = useState("");
  const [formSubmit, setFormSubmit] = useState(false);
  const [adminGuideLinks, setAdminGuideLinks] = useState({
    guide_links: [
      {
        label_name: "",
        user_guide_link: "",
      },
    ],
  });

  const updateAdminGuideDialogMap = {
    updateAdminGuideDisplay: setUpdateAdminGuideDisplay,
  };

  // validating url
  const is_url = (str) =>
  {
    var regexp = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
          if (regexp.test(str))
          {
            return true; //if url is valid
          }
          else
          {
            return false; // if url is invalid
          }
  }

  const accept=async(reqBody)=>{

    //api call for updating admin guide
    await GuideService.updateAdminGuide(adminGuideId, reqBody)
        .then((response) => {
          if (response.status === 200) {
            // toast message after updating guide
            toast.current.show({
              severity: "success",
              summary: "Admin Guide Successfully Updated",
              detail: "The admin guide has been successfully updated.",
              life: 5000,
            });
            updateAdminGuideDialogMap["updateAdminGuideDisplay"](false);
          } else {
            loglevel.debug("admin guide creation has failed");
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            setCommonErrorMsg(error.response.data.msg);
            // for scrolling to top of dialog
            errorRef.current.scrollIntoView({ behavior: "smooth" });
          }
        });

        //api call for refreshing list of guide after updating
      await GuideService.getAdminGuideList().then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          res.data[i]["closedStatus"] = true;
          res.data[i]["closedGroupsStatus"] = true;

        };
        setAlladminGuideDetails(res.data);
      });

  }


  const updateAdminGuide = async (e) => {

    setFormSubmit(true);
    let validForm = true;
    let guideLinkArray = []
    for (let i = 0; i < adminGuideLinks.guide_links.length; i++) { // if guide name and url is present and url is valid
      if (adminGuideLinks.guide_links[i].label_name && adminGuideLinks.guide_links[i].user_guide_link && is_url(adminGuideLinks.guide_links[i].user_guide_link)) {
        guideLinkArray.push(
          {
            label_name: adminGuideLinks.guide_links[i].label_name,
            user_guide_link: adminGuideLinks.guide_links[i].user_guide_link,
          }
        )
         //if guide name or url is missing or if both are present then url is invalid     
      } else if ((!adminGuideLinks.guide_links[i].label_name && 
        (adminGuideLinks.guide_links[i].user_guide_link) && is_url(adminGuideLinks.guide_links[i].user_guide_link)) ||
        (adminGuideLinks.guide_links[i].label_name &&
          (!adminGuideLinks.guide_links[i].user_guide_link) ||  (adminGuideLinks.guide_links[i].user_guide_link) && !is_url(adminGuideLinks.guide_links[i].user_guide_link))) {
        validForm = false;
      } else if ((!adminGuideLinks.guide_links[i].label_name &&
        !adminGuideLinks.guide_links[i].user_guide_link && i === 0)) {
        validForm = false;
      } else if (validForm) { //if form valid
        validForm = true;
      }
    }

    if (guideName && validForm && guideLinkArray.length >= 1) {
      //request body for updating guide
      let reqBody = {
        guide_name: guideName,
        admin_guide: guideLinkArray

      }

      //confirmation msg
      var confirmationMsg = `Want to save changes?`;

      // confirmation popup before updating guide details
      confirmPopup({
          target: e.currentTarget,
          message: parse(`${confirmationMsg}`),
          icon: "pi pi-exclamation-triangle",
          className: "popup",
          acceptLabel: "YES",
          rejectLabel: "NO",
          accept: () => accept(reqBody), // if accepted to update details of guide
      });     
    }
  }

  // opening dialog to update guide details
  const onClick = async (name) => {
    setFormSubmit(false);
    setCommonErrorMsg("")
    await GuideService.getAdminGuideDetails(parseInt(adminGuideId)).then((res) => {
      console.log(res.data);
      setGuideName(res.data.guide_name);
      setAdminGuideLinks({
        guide_links: res.data.adminGuideLabel,
      });
    })
    updateAdminGuideDialogMap[`${name}`](true);


  }

  //closing or hoding dialog
  const onHide = (name) => {
    setCommonErrorMsg("")
    setFormSubmit(false);
    setAdminGuideLinks({
      guide_links: [
        {
          label_name: "",
          user_guide_link: "",
        },
      ],
    })
    setGuideName("")
    updateAdminGuideDialogMap[`${name}`](false);


  }

  //onchanging url or guide name
  const AddUrlhandleChange = (idx) => (e) => {
    setFormSubmit(false);
    setCommonErrorMsg("")
    const { name, value } = e.target;
    const guide_links = [...adminGuideLinks.guide_links];
    guide_links[idx] = {
      ...adminGuideLinks.guide_links[idx],
      [name]: value,
    };

    setAdminGuideLinks({
      guide_links,
    });
  };

  // on adding new row for new guide
  const handleAddRowLink = () => {
    let currentRow = adminGuideLinks.guide_links.length - 1
    if (adminGuideLinks.guide_links[currentRow].label_name && adminGuideLinks.guide_links[currentRow].user_guide_link) {
      const item = {
        label_name: "",
        user_guide_link: "",
      };

      setAdminGuideLinks({
        guide_links: [...adminGuideLinks.guide_links, item],
      });
    }

  };

  // on removing specific row
  const handleRemoveSpecificRow = (idx) => () => {
    const guide_links = [...adminGuideLinks.guide_links];
    guide_links.splice(idx, 1);
    setAdminGuideLinks({ guide_links });
  };

  // on changing guide name
  const onGuideNameChange=(e)=>{
    setCommonErrorMsg("")
    setGuideName(e.target.value)
  }

  const renderFooter = (name) => {
    return (
      // update button in footer of dialog
      <div className="lab-btn-section">
        <Button
          onClick={(e) => updateAdminGuide(e)}
          style={{ background: "#49AFD9" }}
        >
          <span className="name">UPDATE GUIDE</span>
        </Button>
      </div>
    );
  };


  return (
    <>
      <Toast ref={toast} position="top-center" />
      {/*  pencil button for update dialog box to open */}
      <Button
        icon="pi pi-pencil"
        className="p-button-rounded p-button-success ml-2"
        onClick={() => onClick("updateAdminGuideDisplay")}
      />
      <Dialog
        header={`Update Admin Guide - ${guideName}`}
        visible={updateAdminGuideDisplay}
        onHide={() => onHide("updateAdminGuideDisplay")}
        className="lab-dialog-box"
        footer={renderFooter("updateAdminGuideDisplay")}
        style={{ width: "710px" }}
      >
        {/* error response on top of dialog */}
        <div ref={errorRef} className={commonErrorMsg ? "" : "hidden"}>
          {commonErrorMsg && (
            <Message
              severity="warn"
              text={commonErrorMsg}
              className="common-inline-error common-error-message p-mb-3"
            />
          )}
        </div>
        <div className="dialog-content">
          <div className="guide-name-input">
            <div className="guide-name-label">
              Guide Name:
              <span className="cat-required-field">*</span>
            </div>
            <div>
              <InputText
                value={guideName}
                onChange={(e) => onGuideNameChange(e)}
                placeholder="Enter Guide Name"
                maxLength={20}
                className={
                  !guideName && formSubmit
                    ? "cat-input-error" // css for error
                    : "cat-name-text-box"
                }
              />
              {!guideName && formSubmit ? (
                <div className="cat-error-message-inline">
                  <small className="cat-error-message" id="error-message">
                    Guide name is mandatory
                  </small>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="guide-link-container">
            {adminGuideLinks.guide_links.map((item, idx) => (
              <div>
                <div className="table-row" id="addr0" key={idx}>
                  {/* for first guide name and url */}
                  <div className="table-data">
                    {idx === 0 ? (
                      <div className="guide-link-label">
                        Admin Guide Link:
                        <span className="cat-required-field">*</span>
                      </div>
                    ) : (
                      <div className="guide-link-label">
                        Admin Guide Link
                        <span className="optional-label">
                          {" "}
                          ( Optional ) :
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="table-data">
                    <InputText
                      type="text"
                      name="label_name"
                      value={adminGuideLinks.guide_links[idx].label_name}
                      onChange={AddUrlhandleChange(idx)}
                      maxLength={50}
                      className={
                        (idx === 0 && adminGuideLinks.guide_links[idx].label_name) && formSubmit ?
                          "edit-external-url-label-text-box" :
                          (idx === 0 && !adminGuideLinks.guide_links[idx].label_name) && formSubmit ?
                            "edit-external-url-label-text-box error-border" :
                            (idx !== 0 &&
                              !adminGuideLinks.guide_links[idx].label_name &&
                              adminGuideLinks.guide_links[idx].user_guide_link) && formSubmit ?
                              "edit-external-url-label-text-box error-border" :
                              "edit-external-url-label-text-box"
                      }
                      placeholder="Enter Guide Label"
                    />
                    {
                      (idx === 0 && adminGuideLinks.guide_links[idx].label_name) && formSubmit ?
                        ("") :
                        (idx === 0 && !adminGuideLinks.guide_links[idx].label_name) && formSubmit ?
                          (
                            <div className="cat-error-message-inline">
                              <small className="cat-error-message" id="error-message">
                                Label is mandatory
                              </small>
                            </div>
                          ) :
                          (idx !== 0 &&
                            !adminGuideLinks.guide_links[idx].label_name &&
                            adminGuideLinks.guide_links[idx].user_guide_link) && formSubmit ?
                            (
                              <div className="cat-error-message-inline">
                                <small className="cat-error-message" id="error-message">
                                  Label is mandatory
                                </small>
                              </div>
                            ) :
                            ("")
                    }
                  </div>
                  <div className="table-data">
                    <InputText
                      type="text"
                      name="user_guide_link"
                      value={
                        adminGuideLinks.guide_links[idx].user_guide_link
                      }
                      onChange={AddUrlhandleChange(idx)}
                      maxLength={2000}
                      className={
                        (idx === 0 && adminGuideLinks.guide_links[idx].user_guide_link) && is_url(adminGuideLinks.guide_links[idx].user_guide_link) && formSubmit ?
                          "lab-external-url-text-box" :
                          (idx === 0 && (!adminGuideLinks.guide_links[idx].user_guide_link ||(adminGuideLinks.guide_links[idx].user_guide_link && !is_url(adminGuideLinks.guide_links[idx].user_guide_link)))) && formSubmit ?
                            "lab-external-url-text-box error-border" :
                            (idx !== 0 &&
                             ( !adminGuideLinks.guide_links[idx].user_guide_link || (adminGuideLinks.guide_links[idx].user_guide_link && !is_url(adminGuideLinks.guide_links[idx].user_guide_link))) &&
                              adminGuideLinks.guide_links[idx].label_name) && formSubmit ?
                              "lab-external-url-text-box error-border" :
                              "lab-external-url-text-box"
                      }
                      placeholder="Enter URL"
                    />
                   {
                      (idx === 0 && adminGuideLinks.guide_links[idx].user_guide_link) && !is_url(adminGuideLinks.guide_links[idx].user_guide_link) && formSubmit ?
                        (<div className="cat-error-message-inline">
                             <small className="cat-error-message" id="error-message">
                               Invalid link
                             </small>
                           </div>) :
                        (idx === 0 && !adminGuideLinks.guide_links[idx].user_guide_link) && formSubmit ?
                          (
                            <div className="cat-error-message-inline">
                              <small className="cat-error-message" id="error-message">
                                Link is mandatory
                              </small>
                            </div>
                          ) :
                          (idx !== 0 &&
                            !adminGuideLinks.guide_links[idx].user_guide_link &&
                            adminGuideLinks.guide_links[idx].label_name) && formSubmit ?
                            (
                              <div className="cat-error-message-inline">
                                <small className="cat-error-message" id="error-message">
                                  Link is mandatory
                                </small>
                              </div>
                            ) :(idx !== 0 &&
                              adminGuideLinks.guide_links[idx].user_guide_link && !is_url(adminGuideLinks.guide_links[idx].user_guide_link) &&
                              adminGuideLinks.guide_links[idx].label_name) && formSubmit ?
                              (
                                <div className="cat-error-message-inline">
                                  <small className="cat-error-message" id="error-message">
                                    Invalid Link
                                  </small>
                                </div>
                              ):""
                    }
                  </div>
                  <div className="table-data">
                    {adminGuideLinks.guide_links.length - 1 === idx ? (
                      <div onClick={handleAddRowLink} className="add-row-icon">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM10 18.75C5.16751 18.75 1.25 14.8325 1.25 10C1.25 5.16751 5.16751 1.25 10 1.25C14.8325 1.25 18.75 5.16751 18.75 10C18.75 12.3206 17.8281 14.5462 16.1872 16.1872C14.5462 17.8281 12.3206 18.75 10 18.75ZM10.625 9.37505H15.1062C15.4514 9.37505 15.7312 9.65487 15.7312 10C15.7312 10.3452 15.4514 10.625 15.1062 10.625H10.625V15.1063C10.625 15.4515 10.3452 15.7313 9.99999 15.7313C9.65481 15.7313 9.37499 15.4515 9.37499 15.1063V10.625H4.89374C4.54856 10.625 4.26874 10.3452 4.26874 10C4.26874 9.65487 4.54856 9.37505 4.89374 9.37505H9.37499V4.8938C9.37499 4.54862 9.65481 4.2688 9.99999 4.2688C10.3452 4.2688 10.625 4.54862 10.625 4.8938V9.37505Z"
                            fill={(adminGuideLinks.guide_links[idx].label_name && adminGuideLinks.guide_links[idx].user_guide_link)?"#49AFD9": "#6D7884"}
                          />
                        </svg>
                      </div>
                    ) : (
                      <div
                        className="remove-row-icon"
                        onClick={handleRemoveSpecificRow(idx)}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.0711 2.92892C13.1658 -0.976326 6.83418 -0.976326 2.92893 2.92892C-0.97631 6.83416 -0.976311 13.1658 2.92893 17.0711C6.83418 20.9763 13.1658 20.9763 17.0711 17.0711C18.9464 15.1957 20 12.6521 20 9.99998C20 7.34782 18.9464 4.80428 17.0711 2.92892ZM3.81282 16.1872C0.39573 12.7701 0.395729 7.22989 3.81282 3.8128C7.2299 0.395713 12.7701 0.395713 16.1872 3.8128C19.6043 7.22989 19.6043 12.7701 16.1872 16.1872C14.5462 17.8281 12.3206 18.75 10 18.75C7.67936 18.75 5.45376 17.8281 3.81282 16.1872ZM10.8838 10L14.0526 13.1687C14.2966 13.4128 14.2966 13.8085 14.0526 14.0526C13.8085 14.2967 13.4128 14.2967 13.1687 14.0526L9.99996 10.8839L6.83123 14.0526C6.58716 14.2967 6.19143 14.2967 5.94735 14.0526C5.70327 13.8085 5.70327 13.4128 5.94735 13.1687L9.11607 10L5.94735 6.83129C5.70327 6.58721 5.70327 6.19148 5.94735 5.9474C6.19143 5.70333 6.58716 5.70333 6.83123 5.9474L9.99996 9.11613L13.1687 5.9474C13.4128 5.70333 13.8085 5.70333 14.0526 5.9474C14.2966 6.19148 14.2966 6.58721 14.0526 6.83129L10.8838 10Z"
                            fill="#49AFD9"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default UpdateAdminGuides;
