import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";
import { Message } from "primereact/message";
import "../../Rbac.css";
import React, { useState, useRef, useEffect } from "react";
import LabServices from "../../../../services/LabServices";
import loglevel from "../../../../logger/Logger";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const DeleteLabCategory = ({ catId, catName, setChanger }) => {
  const [deleteCatDisplay, setDeleteCatDisplay] = useState(false);
  const deleteCatDialogMap = {
    deleteCatDisplay: setDeleteCatDisplay,
  };
  const [checked, setChecked] = useState(false);
  const [commonErrorMsg, setCommonErrorMsg] = useState("");
  const [isLabAssociated, setIsLabAssociated] = useState(false);
  const [isSubCategoryAssociated,setIsSubCategoryAssociated] = useState(false);
  const [labs, setLabs] = useState([]);
  const [subCategories,setSubCategories] = useState([])
  const [activeIndex, setActiveIndex] = useState(0);
  const toast = useRef(null);
  const dt = useRef(null);
  const errorRef = useRef();

  const labList=async(catId)=> {
    await LabServices.getLabsListAssociatedWithCategory(catId)
      .then((res) => {
        if (res.status === 200) {
          setLabs(res.data);
          if (res.data.length === 0) setIsLabAssociated(false);
          else setIsLabAssociated(true);
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setCommonErrorMsg(error.response.data.msg);
          errorRef.current.scrollIntoView({ behavior: "smooth" });
        }
      });

     await LabServices.getLabCategory(catId).then((res) => {
      if (res.status === 200) {
         setSubCategories(res.data.subCategoryMasterEntities);
        if (res.data.subCategoryMasterEntities.length === 0) 
        setIsSubCategoryAssociated(false);
        else setIsSubCategoryAssociated(true);
      }
     })
     .catch((error) => {
      if (error.response.status === 400) {
        setCommonErrorMsg(error.response.data.msg);
        errorRef.current.scrollIntoView({ behavior: "smooth" });
      }
    });
  }

  const onShow = (name) => {
    setIsLabAssociated(false)
    setIsSubCategoryAssociated(false)
    labList(catId);
    setChecked(false);
    setCommonErrorMsg("");
    deleteCatDialogMap[`${name}`](true);
  };

  const onHide = (name) => {
    deleteCatDialogMap[`${name}`](false);
    setChecked(false);
    setCommonErrorMsg("");
  };

  const onDelete = async(e) => {
    if (checked) {
      await LabServices.deleteLabCategory(catId)
        .then((response) => {
          if (response.status === 200) {
            toast.current.show({
              severity: "success",
              summary: "Category Successfully Deleted",
              detail: `The category named "${catName}" has been successfully deleted.`,
              life: 6000,
            });
            deleteCatDialogMap["deleteCatDisplay"](false);
          } else {
            loglevel.debug("Category Deletion is Failed");
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            setCommonErrorMsg(error.response.data.msg);
            errorRef.current.scrollIntoView({ behavior: "smooth" });
          }
        });
    }

    //----- Added timeout as list fetching and delete api was getting called simultaneously resulting in fetching old Tag List--------
    setTimeout(async() => {
      await LabServices.getLabCategoryList().then((res) => {
        setChanger(res.data);
      });
    }, 1000);
  };

  const renderFooter = (name) => {
    return isLabAssociated || isSubCategoryAssociated ? (
      <div className="delete-btn-section">
        <Button className="p-button-primary" onClick={() => onHide(name)}>
          OK
        </Button>
      </div>
    ) : (
      <div className="delete-btn-section">
        <Button
        label="DELETE CATEGORY"
          className={
            checked ? "p-button-outlined p-button-danger" : "p-button-outlined p-button-secondary"
          }
          onClick={(e) => onDelete(e)}
          disabled={!checked}
        >
        </Button>

        <Button
          className="p-button-primary"
          label="CANCEL"
          onClick={() => onHide(name)}
        />
      </div>
    );
  };

  const statusTemplate = (rowData) => {
    return rowData.enabled ? "Enabled" : "Disabled";
  };

  const setIndex = (e) => {
    setActiveIndex(e);
  };

  return (
    <div className="tag-delete">
      <Toast ref={toast} position="top-center" />
      <Button
        icon="pi pi-trash"
        className="p-button-rounded global-delete-button"
        onClick={() => onShow("deleteCatDisplay")}
      />
      {/* {isLabAssociated ? (
        <Dialog
          header={`Delete Category : ${catName}`}
          visible={deleteCatDisplay}
          footer={renderFooter("deleteCatDisplay")}
          onHide={() => onHide("deleteCatDisplay")}
          className="tag-delete-dialog-box"
        >
          <div ref={errorRef} className={commonErrorMsg ? "" : "hidden"}>
            {commonErrorMsg && (
              <Message
                severity="warn"
                text={commonErrorMsg}
                className="common-inline-error common-error-message p-mb-3"
              />
            )}
          </div>
          <div>Lab associated with Category</div>
          <br></br>
          <DataTable
            value={labs}
            ref={dt}
            columnResizeMode="fit"
            className="cat-delete-table"
            autoLayout={true}
            responsiveLayout="scroll"
          >
            <Column field="name" header="Lab Name" style={{ minWidth: "10rem" ,paddingLeft:"15px" }}></Column>
            <Column
              field="enabled"
              header="Lab Status"
              body={statusTemplate}
            ></Column>
          </DataTable>
          <br></br>
          <Message
            severity="warn"
            text={`If you want to delete the category, remove or change the category for labs associated with this category.`}
            className="common-inline-error common-inline-warn"
          />
          <br></br>
        </Dialog>
            ) */}

          <Dialog
          header={`Delete Category : ${catName}`}
          visible={deleteCatDisplay}
          footer={renderFooter("deleteCatDisplay")}
          onHide={() => onHide("deleteCatDisplay")}
          className="tag-delete-dialog-box"
        >
          <div ref={errorRef} className={commonErrorMsg ? "" : "hidden"}>
            {commonErrorMsg && (
              <Message
                severity="warn"
                text={commonErrorMsg}
                className="common-inline-error common-error-message p-mb-3"
              />
            )}
            </div>
           {isLabAssociated || isSubCategoryAssociated ? (
          <div>
            <div className="p-mb-2">Labs and sub-categories associated with category</div>
            {/* ------------------------------------ */}
            <>
              <div className="tabmenu">
                <button
                  className="cmdb-menuitem-link"
                  style={{
                    borderColor:
                      activeIndex === 0
                        ? "#979797 #979797 #22343C #979797"
                        : "#22343C #22343C #979797 #22343C",
                  }}
                  onClick={() => setIndex(0)}
                >
                  Labs
                </button>
                <button
                  className="cmdb-menuitem-link"
                  style={{
                    borderColor:
                      activeIndex === 1
                        ? "#979797 #979797 #22343C #979797"
                        : "#22343C #22343C #979797 #22343C",
                  }}
                  onClick={() => setIndex(1)}
                >
                  Sub-Categories
                </button>
              </div>
              
              <div className="data">
            {activeIndex === 0 ? (
            <>
             <br />
                    {/*  Labs datatable */}
            {isLabAssociated?
            <DataTable
            value={labs}
            ref={dt}
            columnResizeMode="fit"
            className="cat-delete-table"
            autoLayout={true}
            responsiveLayout="scroll"
          >
            <Column field="name" header="Lab Name" style={{ minWidth: "10rem" ,paddingLeft:"15px" }}></Column>
            <Column
              field="enabled"
              header="Lab Status"
              body={statusTemplate}
            ></Column>
            </DataTable>
            :<div>No labs associated with this category</div>}
            </>
                ) : ""}
                {activeIndex === 1 ? (
                  <>
                    <br />
                    {/* P assets datatable */}
                    {isSubCategoryAssociated?
                    <DataTable
                      value={subCategories}
                      columnResizeMode="fit"
                      className="disable-lab-crud-table"
                      autoLayout={true}
                      responsiveLayout="scroll"
                    >
                      <Column
                        field="name"
                        header="Category"
                        style={{ minWidth: "10rem" ,paddingLeft:"15px" }}
                      ></Column>
                      {/* <Column
                        // field="enabled"
                        header="Status"
                        body={tagTemplate}
                      // style={{ minWidth: "5rem" }}
                      ></Column> */}
                      <Column
                      field="enabled"
                      header="Lab Status"
                      body={statusTemplate}
                    ></Column>
                    </DataTable>
                     :<div>No available sub categories associated with this category</div>}
                  </>
                ) : ""}
              </div>
            </>
            {/* ------------------------------------ */}

            <div className="lab-schedule-warning">
              <div className="lab-warning-icon">
                <svg
                  width="17"
                  height="15.98"
                  viewBox="0 0 18 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.7266 1.06685L17.22 13.0269V13.0469C17.5562 13.6669 17.5409 14.4182 17.1799 15.024C16.8188 15.6299 16.1653 16.0008 15.46 16.0002H2.4733C1.76195 16.0048 1.10172 15.6311 0.739365 15.019C0.377014 14.4068 0.367087 13.6483 0.713297 13.0269L7.2133 1.06685C7.56349 0.423434 8.23742 0.0229492 8.96996 0.0229492C9.70251 0.0229492 10.3764 0.423434 10.7266 1.06685ZM15.46 14.6469C15.6953 14.6468 15.9133 14.5227 16.0333 14.3202C16.154 14.118 16.1591 13.8671 16.0466 13.6602L9.5533 1.70019C9.43692 1.48457 9.21165 1.35017 8.96663 1.35017C8.72161 1.35017 8.49634 1.48457 8.37996 1.70019L1.88663 13.6602C1.77346 13.867 1.77795 14.1182 1.89845 14.3209C2.01895 14.5236 2.23753 14.6475 2.4733 14.6469H15.46ZM8.96663 13.1802C9.51892 13.1802 9.96663 12.7325 9.96663 12.1802C9.96663 11.6279 9.51892 11.1802 8.96663 11.1802C8.41435 11.1802 7.96663 11.6279 7.96663 12.1802C7.96663 12.7325 8.41435 13.1802 8.96663 13.1802ZM9.86663 9.34685C9.86663 9.8255 9.47861 10.2135 8.99996 10.2135C8.76778 10.2135 8.54529 10.1204 8.38237 9.95498C8.21945 9.78955 8.12972 9.56567 8.1333 9.33352V5.33352C8.1333 4.85487 8.52132 4.46685 8.99996 4.46685C9.47861 4.46685 9.86663 4.85487 9.86663 5.33352V9.34685Z"
                    fill="#E9ECEF"
                  />
                </svg>
              </div>
              <div className="lab-warning-msg">
                <div className="p-mb-1">
                  If you want to delete the category, remove associated labs and sub categories.
                </div>
              </div>
            </div>
          </div>
        )
       : ( 
       <>
          <div>No lab/subcategories associated with this category.</div>
          <br></br>
          <div>
            <Checkbox
              inputId="binary"
              className=".checkbox"
              checked={checked}
              onChange={(e) => setChecked(e.checked)}
            />
            {"   "} I understand. Delete the category.
          </div>
        </>
      )}
      </Dialog>
    </div>
  );
};

export default DeleteLabCategory;
