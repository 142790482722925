import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import "../../Rbac.css";
import React, { useState, useRef, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import LabServices from "../../../../services/LabServices";
import loglevel from "../../../../logger/Logger";
import { Divider } from "primereact/divider";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { InputSwitch } from "primereact/inputswitch";
import { Tooltip } from "primereact/tooltip";

// LabTypeCreate accepts input i.e. setChanger from file LabType file
const LabTypeCreate = ({ setChanger }) => {
  // Initialize states
 const [addExternalLink, setAddExternalLink] = useState({
    external_links: [
      {
        package_name: "",
        version_number: "",
      },
    ],
  });
  const [isValidated, setIsValidated] = useState(false);
  const [createLabTypeDisplay, setcreateLabTypeDisplay] = useState(false);
  const [commonErrorMsg, setCommonErrorMsg] = useState("");
  const createLabTypeDialogMap = {
    createLabTypeDisplay: setcreateLabTypeDisplay,
  };
  const [labTypeName, setLabTypeName] = useState("");
  const [labTypeDescription, setLabTypeDescription] = useState("");
  const [reference, setReference] = useState("");
  const toast = useRef(null);
  const [processList, setProcessList] = useState([]);
  const [selectedPreprocess, setSelectedPreprocess] = useState([]);
  const [selectedPostprocess, setSelectedPostprocess] = useState([]);
  const [totalPreprocessTime, setTotalPreprocessTime] = useState(0);
  const [totalPostprocessTime, setTotalPostprocessTime] = useState(0);
  const [totalEditablePreprocessTime, setTotalEditablePreprocessTime] = useState();
  const [totalEditablePostprocessTime, setTotalEditablePostprocessTime] = useState();
  const [preProcessErr, setPreProcessErr] = useState(false)
  const [postProcessErr, setPostProcessErr] = useState(false)
  const [labTypeProcessList, setLabTypeProcessList] = useState([])
  const [isStatic, setIsStatic] = useState(false);
  const [processorErr, setProcessorErr] = useState(false)
  const [totalPreprocessorTimeErr, setTotalPreprocessorTimeErr] = useState(false)
  const [totalPostprocessorTimeErr, setTotalPostprocessorTimeErr] = useState(false)
  const [timeoutHours, setTimeoutHours] = useState(0);
  const errorRef = useRef();

  // Reset and initialize pre and post with 1 row once Create Workflow is clicked
  const onClick = async (name) => {
    setLabTypeName("");
    setLabTypeDescription("");
    setCommonErrorMsg("")
    setIsStatic(false)
    setProcessorErr(false)
    setPreProcessErr(false)
    setPostProcessErr(false)
    let selectedPreprocessList = [];
    for (let i = 0; i < data_preprocess.length; i++) {
      selectedPreprocessList[i] = { processName: "", reference: "", duration: 0, sequence: (i + 1) }
    }
    let selectedPostprocessList = [];
    for (let i = 0; i < data_postprocess.length; i++) {
      selectedPostprocessList[i] = { processName: "", reference: "", duration: 0, sequence: (i + 1) }
    }
    setSelectedPreprocess(selectedPreprocessList);
    setSelectedPostprocess(selectedPostprocessList)
    setTotalPreprocessTime(0)
    setTotalEditablePreprocessTime(0)
    setTotalPostprocessTime(0)
    setTotalEditablePostprocessTime(0)
    setReference("");
    setCommonErrorMsg("");
    setTimeoutHours(0)
    setIsValidated(false);
    createLabTypeDialogMap[`${name}`](true);
  };

  // Reset and close the create workflow dialogue window
  const onHide = (name) => {
    setCommonErrorMsg("");
    setLabTypeName("");
    setIsStatic(false)
    setTotalPostprocessorTimeErr(false)
    setTotalPreprocessorTimeErr(false)
    setCommonErrorMsg("")
    setTimeoutHours(0)
    setIsValidated(false);
    createLabTypeDialogMap[`${name}`](false);
  };

  // Submit the entered preprocess and postprocess details to middleware
  const onAdd = async () => {
    setIsValidated(true);
    setCommonErrorMsg("")
    if (parseInt(totalEditablePreprocessTime) < parseInt(totalPreprocessTime)) {
      setTotalPreprocessorTimeErr(true)
    }
    if (parseInt(totalEditablePostprocessTime) < parseInt(totalPostprocessTime)) {
      setTotalPostprocessorTimeErr(true)
    }
    let currentPreprocessRow = selectedPreprocess.length - 1;
    let currentPostprocessRow = selectedPostprocess.length - 1;
    if ((selectedPreprocess[currentPreprocessRow].processName === "" &&
      selectedPostprocess[currentPostprocessRow].processName === "") && (selectedPostprocess.length === 1 && selectedPreprocess.length === 1)) {
      setProcessorErr(true)
    } else {
      setProcessorErr(false)
      console.log("Pre Process Details: ", selectedPreprocess)
      let PreProcess = [];
      let PostProcess = [];
      let empty_curl_command_executor = false
      selectedPreprocess.forEach(element => {
        if (element.processName.name !== undefined)
          PreProcess.push({ name: element.processName.name, reference: element.reference, duration: element.duration, sequence: element.sequence })
        if ((element.processName.name == 'curl_command_executor') && (element.reference == "")) {
              empty_curl_command_executor = true
        }
      });

      selectedPostprocess.forEach(element => {
        if (element.processName.name !== undefined)
          PostProcess.push({ name: element.processName.name, reference: element.reference, duration: element.duration, sequence: element.sequence })
        if ((element.processName.name == 'curl_command_executor') && (element.reference == "")) {
            empty_curl_command_executor = true
        }
      });
      <Toast ref={toast} position="top-center" />;
      if (labTypeName && labTypeDescription && (totalPostprocessorTimeErr === false && totalPreprocessorTimeErr === false) && (empty_curl_command_executor == false)) {
        // Preparing the request body before calling middleware api
        const requestBody = {
          name: labTypeName,
          description: labTypeDescription,
          PreProcess: PreProcess,
          PostProcess: PostProcess,
          is_static_lab: !!isStatic,
          preprocess_total_hours: parseInt(totalEditablePreprocessTime),
          postprocess_total_hours: parseInt(totalEditablePostprocessTime),
          timeout_hours: timeoutHours ? parseInt(timeoutHours) : 0,
            versions: addExternalLink.external_links
        };
        console.log(requestBody, "labtypereq")
        // Calling add lab type api of middleware
        await LabServices.addLabType(requestBody)
          .then((response) => {
            // If middleware API is success and response status is 201 
            if (response.status === 201) {
              toast.current.show({
                severity: "success",
                summary: "Lab Type Successfully Added",
                detail: "The Lab Type has been successfully added.",
                life: 6000,
              });
              createLabTypeDialogMap["createLabTypeDisplay"](false);
            } else {
              // If middleware API response status is not 201 
              loglevel.debug("Lab Type Creation is Failed");
            }
          })
          .catch((error) => {
            // Excecute below if got error while calling middleware API
            if (error.response.status === 400) {
              setCommonErrorMsg(error.response.data.msg)
              errorRef.current.scrollIntoView({ behavior: "smooth" });
            }
          });

        // Fetch list of all workflow once create workflow is success  
        await LabServices.getLabType().then((res) => {
          console.log(res.data, "Lab list");
          for (let i = 0; i < res.data.length; i++) {
            res.data[i].is_static_lab = res.data[i].is_static_lab ? "Yes" : "No"
          }
          let labTypeDetails = res.data.filter((type) => {
            return type.is_default == false
          })
          // Reflecting main datatable of workflow list
          setChanger(labTypeDetails);
        });
      }
    }

  };

  // Call below method once static toggle button is clicked
  const onChangeStatic = (e) => {
    console.log(processList, "processList")
    let selectedPreprocessList = [];
    for (let i = 0; i < data_preprocess.length; i++) {
      selectedPreprocessList[i] = { processName: "", reference: "", duration: 0, sequence: (i + 1) }
    }
    let selectedPostprocessList = [];
    for (let i = 0; i < data_postprocess.length; i++) {
      selectedPostprocessList[i] = { processName: "", reference: "", duration: 0, sequence: (i + 1) }
    }
    setSelectedPreprocess(selectedPreprocessList);
    setSelectedPostprocess(selectedPostprocessList)

    console.log(e, "switch")
    if (e.value === true) {
      let staticProcessList = processList.filter((process) => { return (process.is_cmdb === false) })
      console.log(staticProcessList, "staticProcessList")

      setLabTypeProcessList(staticProcessList)
    } else {
      let nonStaticProcessList = processList.filter((process) => { return (process) })
      console.log(nonStaticProcessList, "nonstaticProcessList")
      setLabTypeProcessList(nonStaticProcessList)
    }
    console.log(selectedPreprocess.length, "selectedpreprocesslenngth")
    console.log(selectedPreprocess, "selectedpreprocess")

    setIsStatic(e.value)
  }

  // Render output UI for footer of dialogue box
  const renderFooter = (name) => {
    return (
      <div>
        <Button
          className="calender-button"
          style={{ background: "#49AFD9" }}
          onClick={() => onAdd()}
        >
          <span className="name">CREATE WORKFLOW</span>
        </Button>
      </div>
    );
  };

  const data_preprocess = [
    { "processName": "", "reference": "", "duration": 0, sequence: 1 }
  ];

  const data_postprocess = [
    { "processName": "", "reference": "", "duration": 0, sequence: 1 },
  ];

  // call below method once pre process datatable row is dragged up/down
  const onPreRowReorder = (e) => {
    let details = [];
    const data = e.value;
    for (let i = 0; i < e.value.length; i++) {
      details.push({ processName: e.value[i].processName, reference: e.value[i].reference, duration: e.value[i].duration, sequence: (i + 1) })
    }
    setSelectedPreprocess(details);
  }
  // call below method once post process datatable row is dragged up/down
  const onPostRowReorder = (e) => {
    let details = [];
    const data = e.value;
    for (let i = 0; i < e.value.length; i++) {
      details.push({ processName: e.value[i].processName, reference: e.value[i].reference, duration: e.value[i].duration, sequence: (i + 1) })
    }
    setSelectedPostprocess(details);
  }

  // call below method once Add Preprocess link is clicked
  const addPreprocess = () => {

    let currentPreprocessRow = selectedPreprocess.length - 1;
    if (selectedPreprocess[currentPreprocessRow].processName === "") {
      setPreProcessErr(true)
    } else {
      setPreProcessErr(false)
      let preDetails = []
      let totPreProcessTime = 0;
      for (let i = 0; i < selectedPreprocess.length; i++) {
        preDetails.push({ processName: selectedPreprocess[i].processName, reference: selectedPreprocess[i].reference, duration: selectedPreprocess[i].duration, sequence: (i + 1) })
        if (selectedPreprocess[i].duration === null || selectedPreprocess[i].duration === undefined) {
          selectedPreprocess[i].duration = 0;
        }
        totPreProcessTime = totPreProcessTime + parseInt(selectedPreprocess[i].duration)
      }
      preDetails.push({ processName: "", reference: "", duration: 0, sequence: (selectedPreprocess.length + 1) })
      setSelectedPreprocess(preDetails);
      setTotalPreprocessTime(totPreProcessTime);
      setTotalEditablePreprocessTime(totPreProcessTime)

    }

  }
  
  // call below method once Add Postprocess link is clicked
  const addPostprocess = () => {
    let currentPostprocessRow = selectedPostprocess.length - 1;
    if (selectedPostprocess[currentPostprocessRow].processName === "") {
      setPostProcessErr(true)
    } else {
      setPostProcessErr(false)
      let postDetails = [];
      let totPostProcessTime = 0;
      for (let i = 0; i < selectedPostprocess.length; i++) {
        postDetails.push({ processName: selectedPostprocess[i].processName, reference: selectedPostprocess[i].reference, duration: selectedPostprocess[i].duration, sequence: (i + 1) })
        totPostProcessTime = totPostProcessTime + parseInt(selectedPostprocess[i].duration)
      }
      postDetails.push({ processName: "", reference: "", duration: 0, sequence: (selectedPostprocess.length + 1) })
      setSelectedPostprocess(postDetails);
      setTotalPostprocessTime(totPostProcessTime);
      setTotalEditablePostprocessTime(totPostProcessTime)
    }
  }

  // useEffect hook will call getLabTypeProcessors() after render.
  useEffect(async () => {
    await LabServices.getLabTypeProcessors()
      .then((response) => {
        if (response.status === 200) {
          setProcessList(response.data);
          setLabTypeProcessList(response.data)
        }
      })
      .catch((error) => {
        // log error while calling getLabTypeProcessors API from Middleware
        loglevel.error(error);
      });;

    let selectedPreprocessList = [];
    for (let i = 0; i < data_preprocess.length; i++) {
      selectedPreprocessList[i] = { processName: "", reference: "", duration: 0, sequence: (i + 1) }
    }
    setSelectedPreprocess(selectedPreprocessList);
    setTotalPreprocessTime(0);
    setTotalEditablePreprocessTime(0)

    let selectedPostprocessList = [];
    for (let i = 0; i < data_postprocess.length; i++) {
      selectedPostprocessList[i] = { processName: "", reference: "", duration: 0, sequence: (i + 1) }
    }
    setSelectedPostprocess(selectedPostprocessList)
    setTotalPostprocessTime(0);
    setTotalEditablePostprocessTime(0)
  }, []);

  // Call below method while changing selection from pre process list
  const onPreProcessChange = (e, rowData) => {
    setPreProcessErr(false)
    setPostProcessErr(false)
    setProcessorErr(false)
    setTotalPreprocessorTimeErr(false)
    let tempPreprocessList = [];
    let totPreProcessTime = 0;
    for (let i = 0; i < selectedPreprocess.length; i++) {
      if (i === rowData.sequence - 1) {
        tempPreprocessList[i] = { processName: e.value, reference: e.value.global_reference, duration: e.value.duration, sequence: (i + 1) }
        totPreProcessTime = totPreProcessTime + parseInt(e.value.duration);
      } else {
        tempPreprocessList[i] = { processName: selectedPreprocess[i].processName, reference: selectedPreprocess[i].reference, duration: selectedPreprocess[i].duration, sequence: (i + 1) }
        totPreProcessTime = totPreProcessTime + parseInt(selectedPreprocess[i].duration);
      }
    }
    setSelectedPreprocess(tempPreprocessList);
    setTotalPreprocessTime(totPreProcessTime);
    setTotalEditablePreprocessTime(totPreProcessTime)
  }

  // Call below method while changing selection from post process list
  const onPostProcessChange = (e, rowData) => {
    setPreProcessErr(false)
    setPostProcessErr(false)
    setProcessorErr(false)
    setTotalPostprocessorTimeErr(false)
    let tempPostprocessList = [];
    let totPostProcessTime = 0;
    for (let i = 0; i < selectedPostprocess.length; i++) {
      if (i === rowData.sequence - 1) {
        tempPostprocessList[i] = { processName: e.value, reference: e.value.global_reference, duration: e.value.duration, sequence: (i + 1) }
        totPostProcessTime = totPostProcessTime + parseInt(e.value.duration);
      } else {
        tempPostprocessList[i] = { processName: selectedPostprocess[i].processName, reference: selectedPostprocess[i].reference, duration: selectedPostprocess[i].duration, sequence: (i + 1) }
        totPostProcessTime = totPostProcessTime + parseInt(selectedPostprocess[i].duration);
      }
    }
    setSelectedPostprocess(tempPostprocessList);
    setTotalPostprocessTime(totPostProcessTime);
    setTotalEditablePostprocessTime(totPostProcessTime)

  }

// Styling for datatable column pre 'Process Name'
  const preProcessTemplate = (rowData, obj) => {
    return (
      <Dropdown
        value={selectedPreprocess[obj.rowIndex].processName}
        options={labTypeProcessList}
        onChange={(e) => onPreProcessChange(e, rowData)}
        optionLabel="name"
        placeholder="Select Preprocess"
        className={selectedPreprocess[obj.rowIndex].processName.name === 'curl_command_executor' ? "quick-date-selector w-11_4375 v-a-top" : (selectedPreprocess[obj.rowIndex].processName.name === undefined && preProcessErr) ? "processErr" : " quick-date-selector w-11_4375"}
      />
    );
  };

  // Styling for datatable column post 'Process Name'
  const postProcessTemplate = (rowData, obj) => {
    return (
      <Dropdown
        value={selectedPostprocess[obj.rowIndex].processName}
        options={labTypeProcessList}
        onChange={(e) => onPostProcessChange(e, rowData)}
        optionLabel="name"
        placeholder="Select Postprocess"
        className={selectedPostprocess[obj.rowIndex].processName.name === 'curl_command_executor' ? "quick-date-selector w-11_4375 v-a-top" : (selectedPostprocess[obj.rowIndex].processName.name === undefined && postProcessErr) ? "processErr" : " quick-date-selector w-11_4375"}
      />
    );
  };

  // Styling for datatable column pre 'Reference/Curl Command'
  const preProcessReferenceTemplate = (rowData, obj) => {
    const name = selectedPreprocess[obj.rowIndex].processName.name;
    let globalReference = "";
    processList.forEach((value) => {
      if (value.name == name) {
        globalReference = value.global_reference
      }
    })
    return (
        <div>
      <InputText
        type="text"
        disabled={
          (selectedPreprocess[obj.rowIndex].processName.name === "" || selectedPreprocess[obj.rowIndex].processName.name === undefined)
            ? true
            : (selectedPreprocess[obj.rowIndex].processName.name === "curl_command_executor")
              ? false
              : (selectedPreprocess[obj.rowIndex].processName.name !== "" && globalReference == "") ? false : true
        }
        value={selectedPreprocess[obj.rowIndex].reference}
        onChange={(e) => setPreReferenceVal(e.target.value, rowData)}
        placeholder="Type Reference"
        className={selectedPreprocess[obj.rowIndex].processName.name === 'curl_command_executor' ? "w-16_85 h-4" : "w-16_85"}
      />
            {(selectedPreprocess[obj.rowIndex].processName.name === "curl_command_executor") && (selectedPreprocess[obj.rowIndex].reference == "") ? (
                <div>
                    <small className="cat-error-message" id="error-message">
                        Reference/Curl Command is mandatory
                    </small>
                </div>
            ) : ("")}
        </div>
    );
  };

  // Styling for datatable column post 'Reference/Curl Command'
  const postProcessReferenceTemplate = (rowData, obj) => {
    const name = selectedPostprocess[obj.rowIndex].processName.name;
    let globalReference = "";
    processList.forEach((value) => {
      if (value.name == name) {
        globalReference = value.global_reference
      }
    })
    return (
        <div>
      <InputText
        type="text"
        disabled={
          (selectedPostprocess[obj.rowIndex].processName.name === "" || selectedPostprocess[obj.rowIndex].processName.name === undefined)
            ? true
            : (selectedPostprocess[obj.rowIndex].processName.name === "curl_command_executor")
              ? false
              : (selectedPostprocess[obj.rowIndex].processName.name !== "" && globalReference == "") ? false : true
        }
        value={selectedPostprocess[obj.rowIndex].reference}
        onChange={(e) => setPostReferenceVal(e.target.value, rowData)}
        placeholder="Type Reference"
        className={selectedPostprocess[obj.rowIndex].processName.name === 'curl_command_executor' ? "w-16_85 h-4" : "w-16_85"}
      />
        {(selectedPostprocess[obj.rowIndex].processName.name === "curl_command_executor") && (selectedPostprocess[obj.rowIndex].reference == "") ? (
            <div>
                <small className="cat-error-message" id="error-message">
                    Reference/Curl Command is mandatory
                </small>
            </div>
            ) : ("")}
        </div>
    );
  };

  // Styling for datatable column pre 'Duration'
  const preProcessDurationTemplate = (rowData, obj) => {
    return (
      <InputText
        type="text"
        placeholder="0"
        value={selectedPreprocess[obj.rowIndex].duration}
        onInput={onDurationChange}
        onChange={(e) => setPreDurationVal(e.target.value, rowData)}
        className={selectedPreprocess[obj.rowIndex].processName.name === 'curl_command_executor' ? "w-3_75" : "w-3_75"}
      />
    );
  };

  // Styling for datatable column post 'Duration'
  const postProcessDurationTemplate = (rowData, obj) => {
    return (
      <InputText
        type="text"
        placeholder="0"
        value={selectedPostprocess[obj.rowIndex].duration}
        onInput={onDurationChange}
        onChange={(e) => setPostDurationVal(e.target.value, rowData)}
        className={selectedPostprocess[obj.rowIndex].processName.name === 'curl_command_executor' ? "w-3_75" : "w-3_75"}
      />
    );
  };

  // Styling for datatable column for deleting pre process row
  const preProcessDeleteTemplate = (rowData, obj) => {
    return (
      <div className="del-row" onClick={(e) => deleteRowPreProcess(obj)}>
        <i
          className="pi pi-times-circle icon-del"
          style={{
            fontSize: "1.4em",
            color: "#324f61",
          }}
        ></i>
      </div>
    );
  };

  // Styling for datatable column for deleting post process row
  const postProcessDeleteTemplate = (rowData, obj) => {
    return (
      <div className="del-row" onClick={(e) => deleteRowPostProcess(obj)}>
        <i
          className="pi pi-times-circle icon-del"
          style={{
            fontSize: "1.4em",
            color: "#324f61",
          }}
        ></i>
      </div>
    );
  };

  // below method accepts only numbers with single dot
  const onDurationChange = e => {
    let value = e.target.value;
    let numbers = value.replace(/[^.\d]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2");
    e.target.value = numbers;
  };

  // update preprocess list with row's pre reference val
  const setPreReferenceVal = (e, rowData) => {
    let tempPreprocessList = [];
    for (let i = 0; i < selectedPreprocess.length; i++) {
      if (i === rowData.sequence - 1) {
        tempPreprocessList[i] = { processName: selectedPreprocess[i].processName, reference: e, duration: selectedPreprocess[i].duration, sequence: (i + 1) }
      } else {
        tempPreprocessList[i] = { processName: selectedPreprocess[i].processName, reference: selectedPreprocess[i].reference, duration: selectedPreprocess[i].duration, sequence: (i + 1) }

      }
    }
    setSelectedPreprocess(tempPreprocessList)
  }
  
  // update postprocess list with row's post reference val
  const setPostReferenceVal = (e, rowData) => {
    let tempPostprocessList = [];
    for (let i = 0; i < selectedPostprocess.length; i++) {
      if (i === rowData.sequence - 1) {
        tempPostprocessList[i] = { processName: selectedPostprocess[i].processName, reference: e, duration: selectedPostprocess[i].duration, sequence: (i + 1) }
      } else {
        tempPostprocessList[i] = { processName: selectedPostprocess[i].processName, reference: selectedPostprocess[i].reference, duration: selectedPostprocess[i].duration, sequence: (i + 1) }

      }
    }
    setSelectedPostprocess(tempPostprocessList)
  }

  // update preprocess list with row's pre process duration and also update Total PreProcess Time (Hours) input
  const setPreDurationVal = (e, rowData) => {
    setTotalPreprocessorTimeErr(false)
    let tempPreprocessList = [];
    let totPreProcessTime = 0;
    for (let i = 0; i < selectedPreprocess.length; i++) {
      if (i === rowData.sequence - 1) {
        tempPreprocessList[i] = { processName: selectedPreprocess[i].processName, reference: selectedPreprocess[i].reference, duration: e, sequence: (i + 1) }
        if (e === "" || e === undefined) e = 0
        totPreProcessTime = totPreProcessTime + parseInt(e);
      } else {
        tempPreprocessList[i] = { processName: selectedPreprocess[i].processName, reference: selectedPreprocess[i].reference, duration: selectedPreprocess[i].duration, sequence: (i + 1) }
        totPreProcessTime = totPreProcessTime + parseInt(selectedPreprocess[i].duration);
      }
    }
    setSelectedPreprocess(tempPreprocessList);
    setTotalPreprocessTime(totPreProcessTime);
    setTotalEditablePreprocessTime(totPreProcessTime)
  }

  // update postprocess list with row's post process duration and also update Total PostProcess Time (Hours) input
  const setPostDurationVal = (e, rowData) => {
    setTotalPostprocessorTimeErr(false)
    let tempPostprocessList = [];
    let totPostProcessTime = 0;
    for (let i = 0; i < selectedPostprocess.length; i++) {
      if (i === rowData.sequence - 1) {
        tempPostprocessList[i] = { processName: selectedPostprocess[i].processName, reference: selectedPostprocess[i].reference, duration: e, sequence: (i + 1) }
        if (e === "" || e === undefined) e = 0
        totPostProcessTime = totPostProcessTime + parseInt(e);
      } else {
        tempPostprocessList[i] = { processName: selectedPostprocess[i].processName, reference: selectedPostprocess[i].reference, duration: selectedPostprocess[i].duration, sequence: (i + 1) }
        totPostProcessTime = totPostProcessTime + parseInt(selectedPostprocess[i].duration);
      }
    }
    setSelectedPostprocess(tempPostprocessList);
    setTotalPostprocessTime(totPostProcessTime);
    setTotalEditablePostprocessTime(totPostProcessTime)
  }

  // deleting pre process row and also update Total PreProcess Time (Hours)
  const deleteRowPreProcess = (obj) => {
    setPreProcessErr(false)
    console.log(obj, "object")
    let totPreProcessTime = 0;
    if (selectedPreprocess.length > 1) {
      setSelectedPreprocess(prevProcesses => (
        prevProcesses.filter((value, i) => {
          if (i !== obj.rowIndex) {
            return true;
          }
        })
      ));
      setSelectedPreprocess(prevProcesses => (
        totPreProcessTime = 0,
        prevProcesses.filter((value, i) => {
          value.sequence = i + 1;
          totPreProcessTime = totPreProcessTime + parseInt(value.duration);
          setTotalPreprocessTime(totPreProcessTime)
          setTotalEditablePreprocessTime(totPreProcessTime)
          return true;
        })
      ));

    }
  }

  // deleting post process row and also update Total PostProcess Time (Hours)
  const deleteRowPostProcess = (obj) => {
    setPostProcessErr(false)
    let totPostProcessTime = 0;
    if (selectedPostprocess.length > 1) {
      setSelectedPostprocess(prevProcesses => (
        prevProcesses.filter((value, i) => {
          if (i !== obj.rowIndex) {
            return true;
          }
        })
      ));
      setSelectedPostprocess(prevProcesses => (
        totPostProcessTime = 0,
        prevProcesses.filter((value, i) => {
          value.sequence = i + 1;
          totPostProcessTime = totPostProcessTime + parseInt(value.duration);
          setTotalPostprocessTime(totPostProcessTime)
          setTotalEditablePostprocessTime(totPostProcessTime)
          return true;
        })
      ));
    }
  }

  // Update Total PreProcess Time (Hours) once pre process time for row is changed
  const onPreprocessTimeChange = (e) => {
    if (!isNaN(parseInt(e.target.value))) {
      setTotalEditablePreprocessTime(parseInt(e.target.value));
      setTotalPreprocessorTimeErr(false)
    } else {
      setTotalEditablePreprocessTime(e.target.value);
      setTotalPreprocessorTimeErr(false)
    }
  }

  // Update Total PostProcess Time (Hours) once post process time for row is changed
  const onPostprocessTimeChange = (e) => {

    if (!isNaN(parseInt(e.target.value))) {
      setTotalEditablePostprocessTime(parseInt(e.target.value));
      setTotalPostprocessorTimeErr(false)
    } else {
      setTotalEditablePostprocessTime(e.target.value);
      setTotalPostprocessorTimeErr(false)
    }
  }

  //Update Timeout hours
  const onTimeoutChange = (e) => {
    if(!isNaN(parseInt(e.target.value))) {
      setTimeoutHours(parseInt(e.target.value))
    } else {
      setTimeoutHours(e.target.value)
    }  
  }

    // ......................................Add Links................................
  const AddUrlhandleChange = (idx) => (e) => {
    const { name, value } = e.target;
    const external_links = [...addExternalLink.external_links];
    external_links[idx] = {
      ...addExternalLink.external_links[idx],
      [name]: value,
    };
    setAddExternalLink({
      external_links,
    });
  };
  const handleAddRowLink = () => {
    let currentRow = addExternalLink.external_links.length - 1
    if (addExternalLink.external_links[currentRow].package_name && addExternalLink.external_links[currentRow].version_number) {
      const item = {
        package_name: "",
        version_number: "",
      };

      setAddExternalLink({
        external_links: [...addExternalLink.external_links, item],
      });
    }
  };

  const handleRemoveSpecificRow = (idx) => () => {
    const external_links = [...addExternalLink.external_links];
    external_links.splice(idx, 1);
    setAddExternalLink({ external_links });
  };
  //...........................AddLinkEnd...............................................

  // Render output UI
  return (
    <div>
      {/* Middleware API error show, if any, while submiting Create Workflow */}
      <Toast ref={toast} position="top-center" />


      <div className="create-labtype-button p-mt-15">
        <Button
          id="reset-button"
          label="CREATE A NEW WORKFLOW"
          className="p-button-primary"
          onClick={() => onClick("createLabTypeDisplay")}
        />
      </div>

      {/* Dialog box for create workflow */}
      <Dialog
        header="Create New Workflow"
        visible={createLabTypeDisplay}
        footer={renderFooter("createLabTypeDisplay")}
        onHide={() => onHide("createLabTypeDisplay")}
        className="lab-type-dialog-box"
      >
        <div ref={errorRef} className={commonErrorMsg ? "" : "hidden"}>
          {commonErrorMsg && <Message severity="warn" text={commonErrorMsg} className='common-inline-error common-error-message p-mb-3' />}
        </div>
        <div className="form-inline">
        <Tooltip
                className="editLabToolTip"
                target={`.custom-target-icon`}
              />
          <div className="workflow-name-field">
            <div className="labtype-name-label">Name:<span className="cat-required-field">*</span></div>
            <div>
              <InputText
                value={labTypeName}
                onChange={(e) => setLabTypeName(e.target.value)}
                placeholder="Enter Lab Type"
                maxLength={20}
                className={
                  !labTypeName && isValidated ? "type-input-error" : "lab-name-text-box"
                }
              />

              {!labTypeName && isValidated ? (
                <div className="p-mb-1">
                  <small className="cat-error-message" id="error-message">
                    Lab Type Name is mandatory
                  </small>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="static-switch-container">
            <div className="labtype-view-label">
              Static:<span className="lab-required-field">*</span>
            </div>
            <div className="switch-option">
              <InputSwitch
                className="input-switch"
                checked={isStatic}
                onChange={(e) => onChangeStatic(e)}
              />
            </div>
          </div>

        </div>
        <div className="form-inline">
          <div className="workflow-desc-field">
            <div className="labtype-description-label">Description:<span className="cat-required-field">*</span></div>
            <div>
              <InputText
                value={labTypeDescription}
                onChange={(e) => setLabTypeDescription(e.target.value)}
                placeholder="Enter description"
                maxLength={1000}
                className={
                  !labTypeDescription && isValidated ? "type-input-error" : "lab-name-text-box"
                }
              />
              {!labTypeDescription && isValidated ? (
                <div>
                  <small className="cat-error-message" id="error-message">
                    Description is mandatory
                  </small>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="workflow-timeout-field">
            <div className="labtype-timeout-label">
              PreProcessor Timeout (Hours):
              <span
                className="custom-target-icon"
                data-pr-position="top"
                data-pr-tooltip={"Time portal should wait after pre-processor workflow timeout happens before triggering post-processor workflows"}
              >
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 11 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 5.33333C0 2.38781 2.38781 0 5.33333 0C6.74782 0 8.10437 0.561903 9.10457 1.5621C10.1048 2.56229 10.6667 3.91885 10.6667 5.33333C10.6667 8.27885 8.27885 10.6667 5.33333 10.6667C2.38781 10.6667 0 8.27885 0 5.33333ZM5.92444 2.62222C5.92444 2.96587 5.64587 3.24445 5.30222 3.24445C4.95858 3.24445 4.68 2.96587 4.68 2.62222C4.68 2.27858 4.95858 2 5.30222 2C5.64587 2 5.92444 2.27858 5.92444 2.62222ZM5.33333 9.77778C2.87873 9.77778 0.888889 7.78793 0.888889 5.33333C0.888889 2.87873 2.87873 0.888889 5.33333 0.888889C7.78793 0.888889 9.77778 2.87873 9.77778 5.33333C9.77778 6.51207 9.30953 7.64254 8.47603 8.47603C7.64254 9.30953 6.51207 9.77778 5.33333 9.77778ZM5.77778 7.55556H6.66667C6.91213 7.55556 7.11111 7.75454 7.11111 8C7.11111 8.24546 6.91213 8.44444 6.66667 8.44444H4C3.75454 8.44444 3.55556 8.24546 3.55556 8C3.55556 7.75454 3.75454 7.55556 4 7.55556H4.88889V4.88889H4.44444C4.19898 4.88889 4 4.6899 4 4.44444C4 4.19898 4.19898 4 4.44444 4H5.77778V7.55556Z"
                    fill="#49AFD9"
                  />
                </svg>
              </span>
            </div>
            <div>
                <InputText
                  value={timeoutHours}
                  maxLength={8641}
                  className="text-box"
                  placeholder="0"
                  onChange={onTimeoutChange}
                  keyfilter="pnum"
                />
            </div>
          </div>
        </div>
        <Divider />
        <div className="second-section">
          <div className="w-100-p">
                <div>
                    Packages & Versions
                  {addExternalLink.external_links.map((item, idx) => (
                    <div>
                      <div className="table-row" id="addr0" key={idx}>

                        <div className="table-data">
                          <InputText
                            type="text"
                            name="package_name"
                            value={addExternalLink.external_links[idx].package_name}
                            onChange={AddUrlhandleChange(idx)}
                            maxLength={50}
                            className="edit-external-url-label-text-box"
                            placeholder="Enter Package"
                          />

                        </div>
                        <div className="table-data">
                          <InputText
                            type="text"
                            name="version_number"
                            value={
                              addExternalLink.external_links[idx].version_number
                            }
                            onChange={AddUrlhandleChange(idx)}
                            maxLength={2000}
                            className="lab-external-url-text-box"
                            placeholder="Enter Version"
                          />
                        </div>
                        <div className="table-data">
                          {addExternalLink.external_links.length - 1 === idx ? (
                            <div onClick={handleAddRowLink} className="add-row-icon">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM10 18.75C5.16751 18.75 1.25 14.8325 1.25 10C1.25 5.16751 5.16751 1.25 10 1.25C14.8325 1.25 18.75 5.16751 18.75 10C18.75 12.3206 17.8281 14.5462 16.1872 16.1872C14.5462 17.8281 12.3206 18.75 10 18.75ZM10.625 9.37505H15.1062C15.4514 9.37505 15.7312 9.65487 15.7312 10C15.7312 10.3452 15.4514 10.625 15.1062 10.625H10.625V15.1063C10.625 15.4515 10.3452 15.7313 9.99999 15.7313C9.65481 15.7313 9.37499 15.4515 9.37499 15.1063V10.625H4.89374C4.54856 10.625 4.26874 10.3452 4.26874 10C4.26874 9.65487 4.54856 9.37505 4.89374 9.37505H9.37499V4.8938C9.37499 4.54862 9.65481 4.2688 9.99999 4.2688C10.3452 4.2688 10.625 4.54862 10.625 4.8938V9.37505Z"
                                  fill={(addExternalLink.external_links[idx].package_name && addExternalLink.external_links[idx].version_number) ? "#49AFD9" : "#6D7884"}
                                />
                              </svg>
                            </div>
                          ) : (
                            <div
                              className="remove-row-icon"
                              onClick={handleRemoveSpecificRow(idx)}
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0711 2.92892C13.1658 -0.976326 6.83418 -0.976326 2.92893 2.92892C-0.97631 6.83416 -0.976311 13.1658 2.92893 17.0711C6.83418 20.9763 13.1658 20.9763 17.0711 17.0711C18.9464 15.1957 20 12.6521 20 9.99998C20 7.34782 18.9464 4.80428 17.0711 2.92892ZM3.81282 16.1872C0.39573 12.7701 0.395729 7.22989 3.81282 3.8128C7.2299 0.395713 12.7701 0.395713 16.1872 3.8128C19.6043 7.22989 19.6043 12.7701 16.1872 16.1872C14.5462 17.8281 12.3206 18.75 10 18.75C7.67936 18.75 5.45376 17.8281 3.81282 16.1872ZM10.8838 10L14.0526 13.1687C14.2966 13.4128 14.2966 13.8085 14.0526 14.0526C13.8085 14.2967 13.4128 14.2967 13.1687 14.0526L9.99996 10.8839L6.83123 14.0526C6.58716 14.2967 6.19143 14.2967 5.94735 14.0526C5.70327 13.8085 5.70327 13.4128 5.94735 13.1687L9.11607 10L5.94735 6.83129C5.70327 6.58721 5.70327 6.19148 5.94735 5.9474C6.19143 5.70333 6.58716 5.70333 6.83123 5.9474L9.99996 9.11613L13.1687 5.9474C13.4128 5.70333 13.8085 5.70333 14.0526 5.9474C14.2966 6.19148 14.2966 6.58721 14.0526 6.83129L10.8838 10Z"
                                  fill="#49AFD9"
                                />
                              </svg>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <Divider />
            <div>
              Lab PreProcessor workflow:<span className="lab-required-field">*</span>
            </div>
            <div className="card">
              <DataTable value={selectedPreprocess} className="lab-type-crud-table"
                onRowReorder={onPreRowReorder}
                responsiveLayout="scroll">

                <Column rowReorder className="w-3" />
                <Column field="processName" header="Process Name"
                  body={preProcessTemplate}
                />
                <Column field="reference" header="Reference/Curl Command *"
                  className="w-17"
                  body={preProcessReferenceTemplate} />
                <Column field="duration" header="Duration (Hrs)"
                  body={preProcessDurationTemplate} />
                <Column field="sequence" header="Sequence" />
                <Column className="w-3" body={preProcessDeleteTemplate} />

              </DataTable>

            </div>
            <div className={preProcessErr?"err_widgets_div": "noProcessErr"}>
              Process name is mandatory
            </div>
            <div className="widgets_div">
              <div className="icon_div" onClick={addPreprocess}>
                <span><i className="pi pi-plus-circle icon-css"></i></span>
              </div>
              <div className="text_div" onClick={addPreprocess}>
                <span>Add Preprocess</span>
              </div>
            </div>
          </div>
        </div>
        <div className="second-section">
          <div className="w-100-p">
            <div>
              Lab PostProcessor workflow:<span className="lab-required-field">*</span>
            </div>
            <div className="card">
              <DataTable value={selectedPostprocess} className="lab-type-crud-table"
                onRowReorder={onPostRowReorder}
                responsiveLayout="scroll">

                <Column rowReorder className="w-3" />
                <Column field="processName" header="Process Name"
                  body={postProcessTemplate}
                />
                <Column field="reference" header="Reference/Curl Command"
                  className="w-17"
                  body={postProcessReferenceTemplate} />
                <Column field="duration" header="Duration (Hrs)"
                  body={postProcessDurationTemplate} />
                <Column field="sequence" header="Sequence" />
                <Column className="w-3" body={postProcessDeleteTemplate} />

              </DataTable>
            </div>
            <div className={processorErr ? "err_widgets_div" : postProcessErr ? "err_widgets_div" : " noProcessErr"}>
              {processorErr || (processorErr && postProcessErr) ? "Atleast select one of the preprocessors or postprocessors required" : " Process name is mandatory"}
            </div>
            <div className="widgets_div">
              <div className="icon_div" onClick={addPostprocess}>
                <span><i className="pi pi-plus-circle icon-css"></i></span>
              </div>
              <div className="text_div" onClick={addPostprocess}>
                <span>Add Postprocess</span>
              </div>
            </div>
          </div>
        </div>
        <div className="fifth-section">
          <div className="row-1">
            <div className="col-1">
              <div>
                <div className="text-box-label-preprocessor">
                  Total PreProcess Time (Hours):
                  <span className="lab-required-field">*</span>
                  <span
                    className="custom-target-icon"
                    data-pr-position="top"
                    data-pr-tooltip={"Total time in hours needed for the preparation of labs with the defined in pre-processor workflows before it is activated and allowed for the scheduled users to connect to the lab"}
                  >
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 5.33333C0 2.38781 2.38781 0 5.33333 0C6.74782 0 8.10437 0.561903 9.10457 1.5621C10.1048 2.56229 10.6667 3.91885 10.6667 5.33333C10.6667 8.27885 8.27885 10.6667 5.33333 10.6667C2.38781 10.6667 0 8.27885 0 5.33333ZM5.92444 2.62222C5.92444 2.96587 5.64587 3.24445 5.30222 3.24445C4.95858 3.24445 4.68 2.96587 4.68 2.62222C4.68 2.27858 4.95858 2 5.30222 2C5.64587 2 5.92444 2.27858 5.92444 2.62222ZM5.33333 9.77778C2.87873 9.77778 0.888889 7.78793 0.888889 5.33333C0.888889 2.87873 2.87873 0.888889 5.33333 0.888889C7.78793 0.888889 9.77778 2.87873 9.77778 5.33333C9.77778 6.51207 9.30953 7.64254 8.47603 8.47603C7.64254 9.30953 6.51207 9.77778 5.33333 9.77778ZM5.77778 7.55556H6.66667C6.91213 7.55556 7.11111 7.75454 7.11111 8C7.11111 8.24546 6.91213 8.44444 6.66667 8.44444H4C3.75454 8.44444 3.55556 8.24546 3.55556 8C3.55556 7.75454 3.75454 7.55556 4 7.55556H4.88889V4.88889H4.44444C4.19898 4.88889 4 4.6899 4 4.44444C4 4.19898 4.19898 4 4.44444 4H5.77778V7.55556Z"
                        fill="#49AFD9"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <div>
                <InputText
                  value={totalEditablePreprocessTime}
                  maxLength={8641}
                  className="text-box"
                  placeholder="0"
                  onChange={onPreprocessTimeChange}
                  onInput={onDurationChange}
                  keyfilter="pnum"
                />
              </div>
            </div>
            <div>
              <div className="col-2">
                <div>
                  <div className="text-box-label-postprocessor">
                    Total PostProcess Time (Hours):
                    <span className="lab-required-field">*</span>
                    <span
                    className="custom-target-icon"
                    data-pr-position="top"
                    data-pr-tooltip={"Total time in hours needed for the post-activities or the cleanup tasks as defined in the post-processor workflows as part of lab deactivation process (i.e., upon scheduled lab timer expiry)"}
                  >
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 5.33333C0 2.38781 2.38781 0 5.33333 0C6.74782 0 8.10437 0.561903 9.10457 1.5621C10.1048 2.56229 10.6667 3.91885 10.6667 5.33333C10.6667 8.27885 8.27885 10.6667 5.33333 10.6667C2.38781 10.6667 0 8.27885 0 5.33333ZM5.92444 2.62222C5.92444 2.96587 5.64587 3.24445 5.30222 3.24445C4.95858 3.24445 4.68 2.96587 4.68 2.62222C4.68 2.27858 4.95858 2 5.30222 2C5.64587 2 5.92444 2.27858 5.92444 2.62222ZM5.33333 9.77778C2.87873 9.77778 0.888889 7.78793 0.888889 5.33333C0.888889 2.87873 2.87873 0.888889 5.33333 0.888889C7.78793 0.888889 9.77778 2.87873 9.77778 5.33333C9.77778 6.51207 9.30953 7.64254 8.47603 8.47603C7.64254 9.30953 6.51207 9.77778 5.33333 9.77778ZM5.77778 7.55556H6.66667C6.91213 7.55556 7.11111 7.75454 7.11111 8C7.11111 8.24546 6.91213 8.44444 6.66667 8.44444H4C3.75454 8.44444 3.55556 8.24546 3.55556 8C3.55556 7.75454 3.75454 7.55556 4 7.55556H4.88889V4.88889H4.44444C4.19898 4.88889 4 4.6899 4 4.44444C4 4.19898 4.19898 4 4.44444 4H5.77778V7.55556Z"
                        fill="#49AFD9"
                      />
                    </svg>
                  </span>
                  </div>
                </div>
                <div>
                  <InputText
                    value={totalEditablePostprocessTime}
                    maxLength={999}
                    className="text-box"
                    placeholder="0"
                    keyfilter="pint"
                    onChange={onPostprocessTimeChange}
                    onInput={onDurationChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row-1">
            <div className={totalPreprocessorTimeErr ? "col-1 err_widgets_div" : "col-1 noProcessErr"}>
              Total PreProcess Time should be greater than or equal to {totalPreprocessTime}
            </div>
            <div className={totalPostprocessorTimeErr ? "col-1 err_widgets_div m-l-10" : "col-1 noProcessErr"}>
              Total PostProcess Time should be greater than or equal to {totalPostprocessTime}
            </div>
          </div>
        </div >
      </Dialog >
    </div >
  );
};

export default LabTypeCreate;
