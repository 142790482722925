import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import "../../Rbac.css";
import React, { useState, useRef } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Message } from "primereact/message";
import LabServices from "../../../../services/LabServices";
import loglevel from "../../../../logger/Logger";
import { Checkbox } from "primereact/checkbox";

// LabTypeDelete accepts input i.e. lab type id, lab name type and setChanger from file LabType file
const LabTypeDelete = ({ labTypeId, nameLabType, setChanger }) => {
  // Initialize states
  const [deleteLabTypeDisplay, setDeleteLabTypeDisplay] = useState(false);
  const deleteLabTypeDialogMap = {
    deleteLabTypeDisplay: setDeleteLabTypeDisplay,
  };
  const [checked, setChecked] = useState(false);
  const [associatedLabs, setAssociatedLabs] = useState([]);
  const [labTypeErrorMsg, setLabTypeErrorMsg] = useState('');

  const toast = useRef(null);

  // Show workflow delete dialogue window
  const onClick = async (name) => {
    setChecked(false)
    setLabTypeErrorMsg('');

    // api to fetch lab type data
    LabServices.getLabsForLabType(labTypeId).then((res) => {
      setAssociatedLabs(res.data);
    });
    deleteLabTypeDialogMap[`${name}`](true);
  };

  // Hide workflow delete dialogue window
  const onHide = (name) => {
    deleteLabTypeDialogMap[`${name}`](false);
    setChecked(false)
  };

  // Method to delete workflow
  const onDelete = async (e) => {
    // api call for deleting workflow
    <Toast ref={toast} position="top-center" />;
    if (checked) {
      await LabServices.deleteLabType(labTypeId)
        .then((response) => {
          if (response.status === 200) {
            toast.current.show({
              severity: "success",
              summary: "Workflow Successfully Deleted",
              detail: `The workflow named "${nameLabType}" has been deleted successfully.`,
              life: 6000,
            });
            deleteLabTypeDialogMap["deleteLabTypeDisplay"](false);
          } else {
            loglevel.debug("Workflow deletion has failed");
          }
        })
        .catch((error) => {
          // Excecute below if got error while calling middleware API
          if (error.response.status === 400) {
            setLabTypeErrorMsg(error.response.data.msg);
          }
        });
    }
    // Fetch list of all workflow once delete workflow is success 
    await LabServices.getLabType().then((res) => {
      for (let i = 0; i < res.data.length; i++) {
        res.data[i].is_static_lab = res.data[i].is_static_lab ? "Yes" : "No"
      }
      let labTypeDetails = res.data.filter((type) => {
        return type.is_default == false
      })
      // Reflecting main datatable of workflow list 
      setChanger(labTypeDetails);
    })
  };

  // Render output UI for footer of dialogue box
  const renderFooter = (name) => {
    return (
      !associatedLabs.length ?
        (<div className="disable-footer-button">

          <Button
            label="DELETE WORKFLOW"
            className={
              checked ? "p-button-outlined p-button-danger" : "p-button-outlined p-button-secondary"
            }
            onClick={() => onDelete("deleteLabTypeDisplay")}
            disabled={!checked}
          />
          <div>
            {" "}
            <Button
              label="CANCEL"
              onClick={() => onHide('deleteLabTypeDisplay')}
              className="p-button-outlined p-button-primary"
            />
          </div></div>) :
        <div className="disable-footer-button">
          <Button
            label="OK"
            onClick={() => onHide('deleteLabTypeDisplay')}
            className="p-button-outlined p-button-primary"
          />
        </div>
    );
  };

  // Styling for datatable column 'Lab status'
  const labStatusTemplate = (rowData) => {
    return rowData.enabled ? <div>Enabled</div> : <div>Disabled</div>
  };

// Render output UI
  return (
    <div className="lab-type-edit">
      <Toast ref={toast} position="top-center" />
      <Button
        icon="pi pi-trash"
        className="p-button-rounded global-delete-button"
        onClick={() => onClick("deleteLabTypeDisplay")}
      />
      {/* Dialog box for Delete workflow */}
      <Dialog
        header={`Delete Workflow : ${nameLabType}`}
        visible={deleteLabTypeDisplay}
        footer={renderFooter("deleteLabTypeDisplay")}
        onHide={() => onHide("deleteLabTypeDisplay")}
        className="lab-type-delete-dialog-box"
      >
        <div>
          {labTypeErrorMsg && <Message severity="error" text={labTypeErrorMsg} className='common-inline-error common-inline-error p-mt-1 p-mb-3 full-width' />}
        </div>
        {associatedLabs.length ? (
          <div>
            <div className="p-mb-2">Lab associated with workflow</div>
            <DataTable
              value={associatedLabs}
              columnResizeMode="fit"
              className="disable-lab-crud-table"
              autoLayout={true}
              responsiveLayout="scroll"
            >
              <Column
                field="name"
                header="Lab Name"
                style={{ minWidth: "10rem" ,paddingLeft:"15px" }}
              ></Column>
              <Column
                field="enabled"
                header="Lab Status"
                body={labStatusTemplate}
              ></Column>
            </DataTable>
            <div className="lab-schedule-warning">
              <div className="lab-warning-icon">
                <svg
                  width="17"
                  height="15.98"
                  viewBox="0 0 18 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.7266 1.06685L17.22 13.0269V13.0469C17.5562 13.6669 17.5409 14.4182 17.1799 15.024C16.8188 15.6299 16.1653 16.0008 15.46 16.0002H2.4733C1.76195 16.0048 1.10172 15.6311 0.739365 15.019C0.377014 14.4068 0.367087 13.6483 0.713297 13.0269L7.2133 1.06685C7.56349 0.423434 8.23742 0.0229492 8.96996 0.0229492C9.70251 0.0229492 10.3764 0.423434 10.7266 1.06685ZM15.46 14.6469C15.6953 14.6468 15.9133 14.5227 16.0333 14.3202C16.154 14.118 16.1591 13.8671 16.0466 13.6602L9.5533 1.70019C9.43692 1.48457 9.21165 1.35017 8.96663 1.35017C8.72161 1.35017 8.49634 1.48457 8.37996 1.70019L1.88663 13.6602C1.77346 13.867 1.77795 14.1182 1.89845 14.3209C2.01895 14.5236 2.23753 14.6475 2.4733 14.6469H15.46ZM8.96663 13.1802C9.51892 13.1802 9.96663 12.7325 9.96663 12.1802C9.96663 11.6279 9.51892 11.1802 8.96663 11.1802C8.41435 11.1802 7.96663 11.6279 7.96663 12.1802C7.96663 12.7325 8.41435 13.1802 8.96663 13.1802ZM9.86663 9.34685C9.86663 9.8255 9.47861 10.2135 8.99996 10.2135C8.76778 10.2135 8.54529 10.1204 8.38237 9.95498C8.21945 9.78955 8.12972 9.56567 8.1333 9.33352V5.33352C8.1333 4.85487 8.52132 4.46685 8.99996 4.46685C9.47861 4.46685 9.86663 4.85487 9.86663 5.33352V9.34685Z"
                    fill="#E9ECEF"
                  />
                </svg>
              </div>
              <div className="lab-warning-msg">
                <div className="p-mb-1">
                  If you want to delete the workflow, remove associated labs for this workflow.
                </div>
              </div>
            </div>
          </div>
        ) :
          (<div style={{ width: "365px" }}>
            <div>This workflow is not associated with any Lab.</div>
            <div className="field-checkbox p-mb-3 p-mt-3">
              <div>
                {" "}
                <Checkbox
                  inputId="binary"
                  className="checkbox"
                  checked={checked}
                  onChange={(e) => setChecked(e.checked)}
                />
              </div>

              <div className="checkbox-label" htmlFor="binary">
                I understand. Delete the workflow.</div>
            </div></div>)}

      </Dialog>
    </div>
  );
};

export default LabTypeDelete;
