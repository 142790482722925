import React, { useEffect, useState } from 'react'
import UserPermission from '../rbac/user-management/user-crud/UserPermission';
import UserGroups from '../rbac/user-management/group-crud/UserGroups';
import UserTags from '../rbac/user-management/tag-crud/UserTags';
import './Admin.css'

import { useGlobalState } from '../../config/state';
import PortalRequests from '../rbac/user-management/portal-requests/PortalRequestsTab';
import LabRequests from '../rbac/user-management/lab-requests/LabRequestsTab';
import { Badge } from 'primereact/badge';
import eventBus from '../utility/EventBus';
import LabServices from '../../services/LabServices';

const UserManagement = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isAdmin, setIsAdmin] = useGlobalState('isAdmin');
  const [labAccessRequestsCount, setLabAccessRequestsCount] = useState(0);
  const [portalAccessRequestsCount, setPortalAccessRequestsCount] = useState(0);

  useEffect(async () => {
    getPortalAccessRequests()
    getLabAccessRequests()
  }, [])

  useEffect(async () => {
    eventBus.on("requestPortalNotification", async () => {
      getPortalAccessRequests()
    })

    eventBus.on("requestLabNotification", async () => {
      getLabAccessRequests()
    })
  }, [])

  const getPortalAccessRequests = async () => {
    await LabServices.getPortalAccessRequestsCount().then((res) => {
      setPortalAccessRequestsCount(res.data)
    })
  }

  const getLabAccessRequests = async () => {
    await LabServices.getLabAccessRequestsCount().then((res) => {
      setLabAccessRequestsCount(res.data)
    })
  }

  const setIndex = (index) => {
    setActiveIndex(index);
  }

  return (
    <div>
      {(isAdmin) ? (
        <>
          <div className="tabmenu">
            {/* Users Tab */}
            <button
              className="menuitem-link"
              style={{
                borderColor:
                  activeIndex === 0
                    ? "#979797 #979797 #192a32 #979797" // Border colour of tab when it is active
                    : "#192a32 #192a32 #979797 #192a32", // Border colour of tab when it is active
              }}
              onClick={() => setIndex(0)} //on click Users Tab
              data-testid="all-labs-title"
            >
              <div className={activeIndex === 0 ? "active-tab" : "inactive-tab"}> Users</div>
            </button>

            {/* Groups */}
            <button
              className="menuitem-link"
              style={{
                borderColor:
                  activeIndex === 1
                    ? "#979797 #979797 #192a32 #979797" // Border colour of tab when it is active
                    : "#192a32 #192a32 #979797 #192a32", // Border colour of tab when it is inactive
              }}
              onClick={() => setIndex(1)} // on click Groups Tab
            >
              <div className={activeIndex === 1 ? "active-tab" : "inactive-tab"}> Groups</div>
            </button>

            {/* Tags */}
            <button
              className="menuitem-link"
              style={{
                borderColor:
                  activeIndex === 2
                    ? "#979797 #979797 #192a32 #979797" // Border colour of tab when it is active
                    : "#192a32 #192a32 #979797 #192a32", // Border colour of tab when it is inactive
              }}
              onClick={() => setIndex(2)} // on click Tags Tab
            >
              <div className={activeIndex === 2 ? "active-tab" : "inactive-tab"}> Tags</div>
            </button>

            {/* Portal Requests */}
            <button
              className="menuitem-link"
              style={{
                borderColor:
                  activeIndex === 3
                    ? "#979797 #979797 #192a32 #979797" // Border colour of tab when it is active
                    : "#192a32 #192a32 #979797 #192a32", // Border colour of tab when it is inactive
              }}
              onClick={() => setIndex(3)} // on click Portal Requests Tab
            >
              <div className={activeIndex === 3 ? "active-tab" : "inactive-tab"}> Portal Requests {portalAccessRequestsCount > 0 ? <span><Badge style={{height: '21px', minWidth: '21px'}} value={portalAccessRequestsCount}></Badge></span> : ""}</div>
            </button>

            {/* Lab Requests */}
            <button
              className="menuitem-link"
              style={{
                borderColor:
                  activeIndex === 4
                    ? "#979797 #979797 #192a32 #979797" // Border colour of tab when it is active
                    : "#192a32 #192a32 #979797 #192a32", // Border colour of tab when it is inactive
              }}
              onClick={() => setIndex(4)} // on click Portal Requests Tab
            >
              <div className={activeIndex === 4 ? "active-tab" : "inactive-tab"}> Lab Requests {labAccessRequestsCount > 0 ? <span><Badge style={{height: '21px', minWidth: '21px'}} value={labAccessRequestsCount}></Badge></span> : ""}</div>
            </button>
          </div>
          <div className="data">
            {activeIndex === 0 ? <UserPermission /> : ""} {/*Users Tab */}
            {activeIndex === 1 ? <UserGroups /> : ""}     {/*Groups Tab */}
            {activeIndex === 2 ? <UserTags /> : ""}       {/*Tags Tab */}
            {activeIndex === 3 ? <PortalRequests /> : ""} {/*Portal Requess */}
            {activeIndex === 4 ? <LabRequests /> : ""} {/*Lab Requess */}
          </div>
        </>
      ) : (
        window.location.assign("/not-found")
      )}
    </div>
  )
}

export default UserManagement