import { Divider } from "primereact/divider";
import React, { useState, useRef, useEffect } from "react";
import "./ContactUs.css";
import { Toast } from "primereact/toast";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import LabServices from "../../services/LabServices";
import loglevel from "../../logger/Logger";
import eventBus from "../utility/EventBus";

const ContactUs = () => {
  const [options, setOptions] = useState("Feedback");
  const [selectedFile, setSelectedFile] = useState([]);
  const [fileLimitError, setFileLimitError] = useState(null);
  const [fileTypeError, setFileTypeError] = useState(null);
  const [feedbackList, setFeedbackList] = useState([]);
  const [feedbackValue, setFeedbackValue] = useState({});
  const [totalSize, setTotalSize] = useState(0);
  const [comments, setComments] = useState("");
  const [isValidated, setIsValidated] = useState(false);
  const [emailLabel,setEmailLabel]=useState(null)
  const [emailUrl,setEmailUrl]=useState(null)
  const [slackLabel,setSlackLabel]=useState(null)
  const [slackUrl,setSlackUrl]=useState(null)
  const [loading,setLoading]=useState(false)
  const toast = useRef(null);
  const fileInput = useRef(null);
  // extension for valid file
  let typeList = [".jpeg", ".jpg", ".png", ".doc", ".docx", ".pdf"];

  useEffect(() => {
    async function fetchData() {
      // list of fedback and issue
      await LabServices.getFeedbackIssueList(options)
        .then((res) => {
          console.log(res);
          let feedbackList = [];
          res.data.purpose.forEach((option) => {
            feedbackList.push({ option: option });
          });
          setFeedbackList(feedbackList);
          setFeedbackValue(feedbackList[0]);
        })
        .catch((error) => {
          loglevel.error(error);
        });

       // api call for  contacting via email and slack 
      await LabServices.getContactUsUrls(0)
        .then((res) => {
          console.log(res.data, "urls");
          setEmailLabel(res.data.contact1_text)
          setEmailUrl(res.data.contact1_url)
          setSlackLabel(res.data.contact2_text)
          setSlackUrl(res.data.contact2_url)
        })
        .catch((error) => {
          loglevel.error(error);
        });
    }
    fetchData();
  }, []);

  // method call for checking allowd files
  const checkAllowedFile = (fileName, fileSize) => {
    let extention = fileName.substr(fileName.lastIndexOf(".")).toLowerCase();
    // if extention of file is allowed
    if (typeList.includes(extention)) {
      return true;
    } else {
      setFileTypeError(
        "Files format is Invalid. Upload .jpg,.jpeg,.doc,.docx,png,pdf Only."
      );
      setTimeout(function () {
        setFileTypeError(null)
      }, 10000)
      return false;
    }
  };

  // method call on changing dropdown
  const handleOptions = async (e) => {
    setOptions(e.value);
    setIsValidated(false)
   
    // api call for listing feedbacks or issues
    await LabServices.getFeedbackIssueList(e.value)
      .then((res) => {
        console.log(res);
        let feedbackList = [];
        res.data.purpose.forEach((option) => {
          feedbackList.push({ option: option });
        });
        setFeedbackList(feedbackList);
        setFeedbackValue(feedbackList[0]);
      })
      .catch((error) => {
        loglevel.error(error);
      });
  };

  // checking for duplicate files
  function removeDuplicateObjectFromArray(array, key) {
    var check = new Set();
    return array.filter((obj) => !check.has(obj[key]) && check.add(obj[key]));
  }

  // method call after clicking browse buttons for file
  const handleFileInput = (e) => {
    setFileTypeError(null);
    setFileLimitError(null);
    const files = e.target.files;
    let output = [];

    for (let i = 0; i < files.length; i++) {
       if (checkAllowedFile(files[i].name, files[i].size)) { //checking for allowed file based on size and extension
        output.push(files[i]);
      }
    }

    let fileArray = output;
    let array = [...selectedFile, ...fileArray];

    if (array.length > 0) {
      let totalFileArray = removeDuplicateObjectFromArray(array, "name");
      let tSize = 0;
      let finalArray = [];
      for (let i = 0; i < totalFileArray.length; i++) {
        if (tSize + totalFileArray[i].size <= 1049067 * 10) { // max size of files is upto 10 mb
          tSize += totalFileArray[i].size;
          setTotalSize(tSize);
          finalArray.push(totalFileArray[i]);
        } else { //error if size is greater than 10 mb
          setFileLimitError("Total file size should not exceed 10 MB. You already selected");
          setTimeout(function () {
            setFileLimitError(null)
          }, 10000)
          break;
        }
      }
      setSelectedFile(finalArray);
    }
  };

  // method call after removing files
  const onRemove = (name, size) => {
    console.log(name, "onRemove");
    setFileLimitError(null);
    // removing selected file
    let files = selectedFile.filter((file) => file.name !== name);
    let reduceSize = totalSize - size;
    setTotalSize(reduceSize);
    setSelectedFile(files);
  };

  // method call for refreshing notifications
  const updateHeaderNotification = () => {
    eventBus.dispatch("notificationCount", { message: "notificationCount" });
  };

  //method call on submitting
  const onSubmit = async (e) => {
    setIsValidated(true);
   
    if (comments) {
      setLoading(true)
      var formData = new FormData();
      formData.append("type", options);
      formData.append("purpose", feedbackValue.option);
      formData.append("comment", comments);
      for (let i = 0; i < selectedFile.length; i++) {
        formData.append("files", selectedFile[i], selectedFile[i].name);
      }
      // api call for submitting issue or feedback
      await LabServices.contactUsDetails(formData)
        .then((response) => {
          if (response.status === 201) {
            setLoading(false)
            // toast message after successfully reported issue or feedback
            toast.current.show({
              severity: "success",
              summary:
                options === "Feedback"
                  ? "Feedback Successfully Added"
                  : "Issue Successfully Reported",
              detail:
                options === "Feedback"
                  ? "Your Feedback has been successfully added."
                  : "Your Issue has been successfully reported.",
              life: 4000,
            });
            updateHeaderNotification();
            setComments("");
            setFeedbackValue({});
            setTotalSize(0);
            setSelectedFile([]);
            setFileLimitError(null);
            setFileTypeError(null);
            setIsValidated(false);
          } else {
            loglevel.debug("Lab creation is Failed");
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            setLoading(false)
            // toast message for error message response
            toast.current.show({
              severity: "error",
              detail: `${error.response.data.msg}`,
              life: 5000,
            });
          }
        });
      
      // resetting form
      await LabServices.getFeedbackIssueList(options)
        .then((res) => {
          console.log(res);
          let feedbackList = [];
          res.data.purpose.forEach((option) => {
            feedbackList.push({ option: option });
          });
          setFeedbackList(feedbackList);
          setFeedbackValue(feedbackList[0]);
        })
        .catch((error) => {
          loglevel.error(error);
        });
    }
  };

  // method call for showing file after browsing
  const FileList = () => {
    let files = selectedFile.map((file, index) => {
      return (
        // listing selected file in for of tag
        <Tag className="p-mr-2 p-mb-2" key={index}>
          <div className="upload-tag">
            <div>
              <svg
                className="p-mr-1 "
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.52 1.77778H11.5556C12.0465 1.77778 12.4444 2.17575 12.4444 2.66667V10.6667C12.4444 11.1576 12.0465 11.5556 11.5556 11.5556H0.888889C0.397969 11.5556 0 11.1576 0 10.6667V0.888892C0 0.397972 0.397969 3.00004e-06 0.888889 3.00004e-06H3.77333C4.05783 -0.00073506 4.32548 0.134746 4.49333 0.364447L5.52 1.77778ZM0.888889 10.6667H11.5556V2.66667H5.29333C5.15109 2.66704 5.01726 2.5993 4.93333 2.48445L3.77333 0.888892H0.888889V2.66667H5.02667C5.02667 3.15759 4.6287 3.55556 4.13778 3.55556H0.888889V10.6667Z"
                  fill="black"
                />
              </svg>
            </div>
            <div className="p-mr-2">{file.name}</div>
            <div
              className="p-mt-1 tag-icon"
              onClick={() => onRemove(file.name, file.size)}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.99992 13.4173C3.45609 13.4173 0.583252 10.5445 0.583252 7.00065C0.583252 3.45682 3.45609 0.583984 6.99992 0.583984C10.5437 0.583984 13.4166 3.45682 13.4166 7.00065C13.4166 8.70246 12.7405 10.3346 11.5372 11.5379C10.3338 12.7413 8.70172 13.4173 6.99992 13.4173ZM6.99992 1.38607C3.89907 1.38607 1.38534 3.8998 1.38534 7.00065C1.38534 10.1015 3.89907 12.6152 6.99992 12.6152C10.1008 12.6152 12.6145 10.1015 12.6145 7.00065C12.6145 3.8998 10.1008 1.38607 6.99992 1.38607ZM7.6456 7.00065L9.59466 5.05159C9.73092 4.89247 9.72176 4.65528 9.57363 4.50715C9.4255 4.35902 9.18831 4.34985 9.02919 4.48612L7.08013 6.41513L5.11903 4.45404C4.95992 4.31777 4.72273 4.32693 4.57459 4.47506C4.42646 4.6232 4.4173 4.86039 4.55356 5.0195L6.51065 7.00065L4.59367 8.89357C4.47922 8.99158 4.42937 9.14547 4.46461 9.29197C4.49985 9.43847 4.61423 9.55286 4.76073 9.5881C4.90724 9.62334 5.06113 9.57348 5.15914 9.45904L7.07211 7.54607L8.97304 9.44701C9.13216 9.58327 9.36935 9.57411 9.51748 9.42598C9.66562 9.27784 9.67478 9.04065 9.53851 8.88154L7.6456 7.00065Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
        </Tag>
      );
    });
    return files;
  };

  return (
    <div className="contactus-container">
      <Toast ref={toast} position="top-center"></Toast>
      <div className="contactus-title">Contact Us</div>
      <Divider style={{ margin: 0 }}/>
      <div className="link-message">
        Please reach out to us via{" "}
        <a
          className="link"
          href={emailUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {emailLabel}
        </a>{" "}
        (Via email) or{" "}
        <a
          className="link"
          href={slackUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {slackLabel}
        </a>{" "}
        (Google Chat Space)
      </div>
      <Divider align="center">
        <svg
          width="41"
          height="41"
          viewBox="0 0 41 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="20.0899"
            cy="20.0898"
            r="19.5899"
            transform="rotate(-90 20.0899 20.0898)"
            fill="#1B2A32"
            stroke="#324F61"
          />
          <path
            d="M10.6733 20.5171C10.6733 18.9725 11.0881 17.7643 11.9175 16.8926C12.7469 16.0166 13.8175 15.5786 15.1294 15.5786C15.9884 15.5786 16.7629 15.7839 17.4526 16.1943C18.1424 16.6048 18.6672 17.1782 19.0269 17.9146C19.3908 18.6466 19.5728 19.4782 19.5728 20.4092C19.5728 21.3529 19.3823 22.1971 19.0015 22.9419C18.6206 23.6867 18.0811 24.2516 17.3828 24.6367C16.6846 25.0176 15.9313 25.208 15.123 25.208C14.2471 25.208 13.4642 24.9964 12.7744 24.5732C12.0846 24.1501 11.562 23.5724 11.2065 22.8403C10.8511 22.1082 10.6733 21.3338 10.6733 20.5171ZM11.9429 20.5361C11.9429 21.6576 12.2433 22.542 12.8442 23.1895C13.4494 23.8327 14.2069 24.1543 15.1167 24.1543C16.0435 24.1543 16.8052 23.8285 17.4019 23.1768C18.0028 22.5251 18.3032 21.6004 18.3032 20.4028C18.3032 19.6453 18.1742 18.9852 17.916 18.4224C17.6621 17.8553 17.2876 17.4173 16.7925 17.1084C16.3016 16.7952 15.7493 16.6387 15.1357 16.6387C14.264 16.6387 13.5129 16.9391 12.8823 17.54C12.256 18.1367 11.9429 19.1354 11.9429 20.5361ZM21.1851 25.0493V15.7437H25.311C26.1405 15.7437 26.771 15.8283 27.2026 15.9976C27.6343 16.1626 27.9792 16.4567 28.2373 16.8799C28.4954 17.3031 28.6245 17.7707 28.6245 18.2827C28.6245 18.9429 28.4108 19.4993 27.9834 19.9521C27.556 20.4049 26.8958 20.6927 26.0029 20.8154C26.3288 20.972 26.5763 21.1265 26.7456 21.2788C27.1053 21.6089 27.446 22.0215 27.7676 22.5166L29.3862 25.0493H27.8374L26.606 23.1133C26.2463 22.5547 25.95 22.1273 25.7173 21.8311C25.4845 21.5348 25.2751 21.3275 25.0889 21.209C24.9069 21.0905 24.7207 21.008 24.5303 20.9614C24.3906 20.9318 24.1621 20.917 23.8447 20.917H22.4165V25.0493H21.1851ZM22.4165 19.8506H25.0635C25.6263 19.8506 26.0664 19.7935 26.3838 19.6792C26.7012 19.5607 26.9424 19.3745 27.1074 19.1206C27.2725 18.8625 27.355 18.5832 27.355 18.2827C27.355 17.8426 27.1942 17.4808 26.8726 17.1973C26.5552 16.9137 26.0516 16.772 25.3618 16.772H22.4165V19.8506Z"
            fill="white"
          />
        </svg>
      </Divider>

      <div className="parent-container">
        {/* radiobutton container for feedback and issues */}
        <div className="radiobutton-container">
          <div className="field-title">Choose:</div>
          <div className="radiobutton-options">
            <div className="radiobutton-option1">
              <RadioButton
                inputId="option1"
                name="option"
                value="Feedback"
                onChange={(e) => handleOptions(e)}
                checked={options === "Feedback"}
              />
              <label className="contactus-option-label">Feedback</label>
            </div>
            <div className="radiobutton-option2">
              <RadioButton
                inputId="option2"
                name="option"
                value="Issue"
                onChange={(e) => handleOptions(e)}
                checked={options === "Issue"}
              />
              <label htmlFor="option1" className="contactus-option-label">Report an Issue</label>
            </div>
          </div>
        </div>
        <div className="feedback">
          <div className="field-title">Subject/Purpose:</div>
          <div className="feedback-options">
            <Dropdown
              value={feedbackValue}
              options={feedbackList}
              onChange={(e) => setFeedbackValue(e.value)}
              optionLabel="option"
              placeholder={options}
              filter
              filterBy="option"
            />
          </div>
        </div>
        <div className="comments-container">
          <div className="field-title">
            Comments:<span className="lab-required-field">*</span>
          </div>
          <div
            className={!comments && isValidated ? "comments-error" : "comments"}
          >
            <InputTextarea
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              rows={2}
              cols={40}
              placeholder="Enter Comments"
              autoResize={true}
            />
            {!comments && isValidated ? (
              <div className="error-message-inline">
                <small className="error-message" id="error-message">
                  Comment is mandatory
                </small>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="upload-option">
          <div className="attachment-label">Attachment (<span className="optional-label">Optional</span>):</div>
          <div className="file-upload">
            <div className="browse-button p-mb-2">
              <input
                style={{ display: "none" }}
                type="file"
                onChange={(e) => handleFileInput(e)}
                ref={fileInput}
                accept=".jpg,.jpeg,.doc,.docx,.png,.pdf"
                multiple
              />
              <div className="attachment-button">
                <div>
                  {/* Browse button */}
                  <Button
                    className="p-button-outlined p-button-primary"
                    onClick={(e) =>
                      fileInput.current && fileInput.current.click()
                    }
                  >
                    <svg
                      className="p-mr-1"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.00005 0.777344C3.56362 0.777344 0.777832 3.56313 0.777832 6.99957C0.777832 10.436 3.56362 13.2218 7.00005 13.2218C10.4365 13.2218 13.2223 10.436 13.2223 6.99957C13.2223 5.34933 12.5667 3.76668 11.3998 2.59979C10.2329 1.4329 8.65029 0.777344 7.00005 0.777344ZM7.00005 12.444C3.99317 12.444 1.55561 10.0064 1.55561 6.99957C1.55561 3.99268 3.99317 1.55512 7.00005 1.55512C10.0069 1.55512 12.4445 3.99268 12.4445 6.99957C12.4445 8.44352 11.8709 9.82834 10.8499 10.8494C9.82883 11.8704 8.44401 12.444 7.00005 12.444ZM7.38894 6.61068H10.1773C10.3921 6.61068 10.5662 6.78479 10.5662 6.99957C10.5662 7.21434 10.3921 7.38845 10.1773 7.38845H7.38894V10.1768C7.38894 10.3916 7.21483 10.5657 7.00005 10.5657C6.78528 10.5657 6.61117 10.3916 6.61117 10.1768V7.38845H3.82283C3.60805 7.38845 3.43394 7.21434 3.43394 6.99957C3.43394 6.78479 3.60805 6.61068 3.82283 6.61068H6.61117V3.82234C6.61117 3.60757 6.78528 3.43345 7.00005 3.43345C7.21483 3.43345 7.38894 3.60757 7.38894 3.82234V6.61068Z"
                        fill="#49AFD9"
                      />
                    </svg>
                    <span className="button-label">BROWSE</span>
                  </Button>
                </div>
                <div className="p-ml-2  upload-conditions">
                  {"*upto 10 MB. JPG, JPEG, PNG, DOC, DOCX, PDF only"}
                </div>
              </div>
              <div className="p-mt-1 upload-conditions">
                {fileTypeError ? (
                  <div className="upload-error">{fileTypeError}</div>
                ) : fileLimitError ? (
                  <div className="upload-error">{fileLimitError + ` ${parseFloat(totalSize/1049067).toFixed(4)} MB`}</div>
                ) : (
                  <div>{ `Currently selected files size: ${parseFloat(totalSize/1049067).toFixed(4)} MB`}</div>
                )}
              </div>
            </div>
            <div className="selected-files">
              {selectedFile.length > 0 ? FileList() : ""}
            </div>
          </div>
        </div>
        <Divider/>  
        {/* submit button      */}
        <div className="submit-button">
          <Button  onClick={(e) => onSubmit(e)}> {loading?<div><i className="pi pi-spin pi-spinner" style={{'fontSize': '13px', 'color':"black" , "marginRight":"4px"}}></i></div>:""}SUBMIT</Button>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
