import React, { useState, useEffect, useRef } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Button } from "primereact/button";
import LabServices from "../../services/LabServices";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import "./Reports.css";
import ChartDashboard from "../charts/ChartDashboard";
import LineStylesChart from "../charts/LineStylesChart";
import EquipmentGraph from "../charts/EquipmentGraph";
import DetailedEquipmentGraph from "../charts/DetailedEquipmentGraph";
import { SplitButton } from "primereact/splitbutton";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { MultiSelect } from "primereact/multiselect";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import LoginAttemptsGraph from "../charts/LoginAttemptsGraph";
import LoginReports from "./LoginReports";
import { useGlobalState } from '../../config/state';
import { Toast } from "primereact/toast";
import { Constants } from "../../constants/constants";

const PortalReports = () => {
  const dt = useRef(null);
  const toast = useRef(null);
  const exportButton = useRef(null);
  const [expandedRows, setExpandedRows] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredData, setFilteredData] = useState("");
  const [selectedDays, setSelectedDays] = useState({
    filterByDays: "Last 90 days",
    days: 90,
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [adminData, setAdminData] = useState([]);

  const [globalFilterValue1, setGlobalFilterValue1] = useState("");
  const [users, setUsers] = useState([]);
  const [filters1, setFilters1] = useState(null);
  const [labNames, setLabNames] = useState([]);
  const [purposes, setPurposes] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [remarks, setRemarks] = useState([]);
  const [usedBy, setUsedBy] = useState([]);
  const [filteredValue, setFilteredValue] = useState([]);

  const indexOfLastItem = itemsPerPage + currentPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // Dropdown options for admin report
  const filterDays = [
    { filterByDays: "Last 7 days", days: 7 },
    { filterByDays: "Last 15 days", days: 15 },
    { filterByDays: "Last 30 days", days: 30 },
    { filterByDays: "Last 90 days", days: 90 },
    { filterByDays: "Last 180 days", days: 180 },
    { filterByDays: "Last 365 days", days: 365 },
  ];

  // Dropdown options for admin report
  const onDaysChange = (e) => {
    setSelectedDays(e.value);
    let days = 90;
    if (e.value.days === 7) {
      days = 7;
      let today = new Date();// current date
      let priorSeven = new Date(new Date().setDate(today.getDate() - 7)); // date priot to 7 days
      setStartDate(priorSeven);//setting start date as prior 7 days
      setEndDate(today);//setting last date as current date
    } else if (e.value.days === 15) {
      days = 15;
      let today = new Date();
      let priorFifteen = new Date(new Date().setDate(today.getDate() - 15));
      setStartDate(priorFifteen);
      setEndDate(today);
    } else if (e.value.days === 30) {
      days = 30;
      let today = new Date();
      let priorThirty = new Date(new Date().setDate(today.getDate() - 30));
      setStartDate(priorThirty);
      setEndDate(today);
    } else if (e.value.days === 90) {
      days = 90;
      let today = new Date();
      let priorNinety = new Date(new Date().setDate(today.getDate() - 90));
      setStartDate(priorNinety);
      setEndDate(today);
    } else if (e.value.days === 180) {
      days = 180;
      let today = new Date();
      let priorOneEighty = new Date(new Date().setDate(today.getDate() - 180));
      setStartDate(priorOneEighty);
      setEndDate(today);
    } else if (e.value.days === 365) {
      days = 365;
      let today = new Date();
      let prioroneyear = new Date(new Date().setDate(today.getDate() - 365));
      setStartDate(prioroneyear);
      setEndDate(today);
    }
    let labUsers = [];
    // changing data from api to filter usable format
    LabServices.adminReports(days).then((res) => {
      res.data.forEach((item) => {
        labUsers.push({
          lab_details: { lab_name: item.lab_name },
          purpose_details: { purpose: item.purpose },
          customer_details: { customer: item.customer },
          start_time: item.start_time,
          end_time: item.end_time,
          remark_details: { remarks: item.remarks },
          usedBy_details: { used_by: item.used_by },
            deal_value_details: {deal_value: item.deal_value},
            sfid_details: {sfid: item.sfid}
        });
      });
      setUsers(labUsers);
      setFilteredValue(labUsers)
      setAdminData(res.data);
    });
  };

  const getAdmin = () => {
    LabServices.adminReports(90).then((res) => {
      setAdminData(res.data);
      let users = [];
      let labFilters = [];
      let purposeFilters = [];
      let customerFilters = [];
      let remarkFilters = [];
      let usedByFilters = [];

      res.data.filter(function (item) {
        var lab = labFilters.findIndex((x) => x.lab_name == item.lab_name);
        if (lab <= -1) {
          labFilters.push({ lab_name: item.lab_name });
        }
        //purpose
        var purpose = purposeFilters.findIndex(
          (x) => x.purpose == item.purpose
        );
        if (purpose <= -1) {
          purposeFilters.push({ purpose: item.purpose });
        }
        // customers
        var customer = customerFilters.findIndex(
          (x) => x.customer == item.customer
        );
        if (customer <= -1) {
          customerFilters.push({ customer: item.customer });
        }
        // remarks
        var remarks = remarkFilters.findIndex((x) => x.remarks == item.remarks);
        if (remarks <= -1) {
          remarkFilters.push({ remarks: item.remarks });
        }
        // used By
        var used_by = usedByFilters.findIndex((x) => x.used_by == item.used_by);
        if (used_by <= -1) {
          usedByFilters.push({ used_by: item.used_by });
        }
        return null;
      });

      setLabNames(labFilters);
      setPurposes(purposeFilters);
      setCustomers(customerFilters);
      setRemarks(remarkFilters);
      setUsedBy(usedByFilters);
      res.data.forEach((item) => {
        users.push({
          lab_details: { lab_name: item.lab_name },
          purpose_details: { purpose: item.purpose },
          customer_details: { customer: item.customer },
          start_time: item.start_time,
          end_time: item.end_time,
          remark_details: { remarks: item.remarks },
          usedBy_details: { used_by: item.used_by },
            deal_value_details: {deal_value: item.deal_value},
            sfid_details: {sfid: item.sfid}
        });
      });
      setUsers(users);
      setFilteredValue(users);
    });
    initFilters1();
  };

  const initFilters1 = () => {
    setFilters1({
      lab_details: { value: null, matchMode: FilterMatchMode.IN },
      purpose_details: { value: null, matchMode: FilterMatchMode.IN },
      customer_details: { value: null, matchMode: FilterMatchMode.IN },
      usedBy_details: { value: null, matchMode: FilterMatchMode.IN },
      remark_details: { value: null, matchMode: FilterMatchMode.IN },
    });
    setGlobalFilterValue1("");
  };

  // setting start date
  const startDateChange = (e) => {
    setStartDate(e.target.value);
  };
  // setting end date
  const endDateChange = (e) => {
    setEndDate(e.target.value);
  };

  // getting dates between start and end date
  var getDaysArray = function (start, end) {
    for (
      var arr = [], dt = new Date(start);
      dt <= end;
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt).toLocaleDateString("en-CA"));
    }
    return arr;
  };

  const filteredLabs = (start, end) => {
    var daylist = getDaysArray(start, end);
    // filter labs on the basis of start and end dates
    const filteredData = users.filter((labs) => {
      return daylist.includes(
        new Date(labs.start_time).toLocaleDateString("en-CA")
      );
    });
    return filteredData;
  };

  const currentItems = !filteredData
    ? adminData.slice(indexOfFirstItem, indexOfLastItem)
    : filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // seaching on basis of start and end date
  const searchHandle = () => {
    const filtered = filteredLabs(startDate, endDate)
    setUsers(filtered);
    setFilteredValue(filtered);
  };

  const resetHandle = (e) => {
    setStartDate("");
    setEndDate("");
    setSelectedDays({ filterByDays: "Last 90 days", days: 90 });
    initFilters1();
    setUsers(users);
    getAdmin();
  };

  const onBasicPageChange = (event) => {
    setCurrentPage(event.first);
    setItemsPerPage(event.rows);
  };

  useEffect(() => {
    let days = 90;
    let today = new Date();
    let priorNinety = new Date(new Date().setDate(today.getDate() - 90));
    setStartDate(priorNinety);
    setEndDate(today);
    //api call when page loads for user reports 90 days
    getAdmin();
  }, []);

  // method to export admin details in csv
  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
    toast.current.show({
      severity: "success",
      summary: Constants.EXPORT_TOAST_SUMMARY,
      detail: Constants.EXPORT_TOAST_DETAIL,
      life: 6000,
    })
  };

  // method to  export user details in pdf
  const exportPdf = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "VMware Admin Report";
    // clumns of pdf
    const headers = [
      [
        "#",
        "LAB NAME",
        "PURPOSE",
        "CUSTOMER",
        "SCHEDULED BY",
        "START TIME",
        "END TIME",
        "REMARK",
      ],
    ];

    const data = filteredValue.map((labs, index) => [
      index + 1,
      labs.lab_details.lab_name,
      labs.purpose_details.purpose,
      labs.customer_details.customer,
      labs.usedBy_details.used_by,
      labs.start_time,
      labs.end_time,
      labs.remark_details.remarks,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    //pdf name
    doc.save("lab_admin_report.pdf");
    toast.current.show({
      severity: "success",
      summary: Constants.EXPORT_TOAST_SUMMARY,
      detail: Constants.EXPORT_TOAST_DETAIL,
      life: 6000,
    })
  };

  // dropdown to show pdf and csv
  const items = [
    {
      label: "CSV",
      icon: "pi pi-file",
      command: (e) => {
        exportCSV();
      },
    },
    {
      label: "PDF",
      icon: "pi pi-file-pdf",
      command: (e) => {
        exportPdf();
      },
    },
  ];

  // index column on table
  const onIndexTemplate = (rowData, props) => {
    return props.rowIndex + 1;
  };

  const onSheduledByTemplate = (rowData) => {
    let fname = localStorage.getItem("fname");
    let lname = localStorage.getItem("lname");
    return (rowData = `${fname} ${lname}`);
  };

  //labname template
  const labNameBodyTemplate = (rowData) => {
    const labDetails = rowData.lab_details;
    return (
      <React.Fragment>
        <span className="lab-name-text">
          {labDetails.lab_name}
        </span>
      </React.Fragment>
    );
  };

  // filter on lab name column
  const labNameFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={labNames}
        itemTemplate={labNameItemTemplate}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="lab_name"
        placeholder="Select Labs"
        className="p-column-filter name-filter"
        maxSelectedLabels={3}
      />
    );
  };

  const labNameItemTemplate = (option) => {
    return (
      <span className="p-multiselect-filteredData-option">
        <span className="lab-name-text">{option.lab_name}</span>
      </span>
    );
  };

  // purpose template
  const purposeBodyTemplate = (rowData) => {
    const labDetails = rowData.purpose_details;
    return (
      <React.Fragment>
        <span className="lab-name-text">
          {labDetails.purpose}
        </span>
      </React.Fragment>
    );
  };

  // filter on purpose column
  const purposeFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={purposes}
        itemTemplate={purposeItemTemplate}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="purpose"
        placeholder="Select Purposes"
        className="p-column-filter name-filter"
        maxSelectedLabels={3}
      />
    );
  };

  const purposeItemTemplate = (option) => {
    return (
      <span className="p-multiselect-filteredData-option">
        <span className="lab-name-text">{option.purpose}</span>
      </span>
    );
  };

  // customer template
  const customerBodyTemplate = (rowData) => {
    const labDetails = rowData.customer_details;
    return (
      <React.Fragment>
        <span className="lab-name-text">
          {labDetails.customer}
        </span>
      </React.Fragment>
    );
  };

  //filter on customer column
  const customerFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={customers}
        itemTemplate={customerItemTemplate}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="customer"
        placeholder="Select Customers"
        className="p-column-filter name-filter"
        maxSelectedLabels={3}
      />
    );
  };

  const customerItemTemplate = (option) => {
    return (
      <span className="p-multiselect-filteredData-option">
        <span className="lab-name-text">{option.customer}</span>
      </span>
    );
  };

  // used by column
  const usedByBodyTemplate = (rowData) => {
    const labDetails = rowData.usedBy_details;
    return (
      <React.Fragment>
        <span className="lab-name-text">
          {labDetails.used_by}
        </span>
      </React.Fragment>
    );
  };

  // filter on used by column
  const usedByFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={usedBy}
        itemTemplate={usedByItemTemplate}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="used_by"
        placeholder="Select Shedulers"
        className="p-column-filter name-filter"
        maxSelectedLabels={3}
      />
    );
  };

  const usedByItemTemplate = (option) => {
    return (
      <span className="p-multiselect-filteredData-option">
        <span className="lab-name-text">{option.used_by}</span>
      </span>
    );
  };

  // remarks column
  const remarkBodyTemplate = (rowData) => {
    const labDetails = rowData.remark_details;
    return (
      <React.Fragment>
        <span className="lab-name-text">
          {labDetails.remarks}
        </span>
      </React.Fragment>
    );
  };

  // filter on remarks column
  const remarkFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={remarks}
        itemTemplate={remarkItemTemplate}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="remarks"
        placeholder="Select Remarks"
        className="p-column-filter name-filter"
        maxSelectedLabels={3}
      />
    );
  };

  const remarkItemTemplate = (option) => {
    return (
      <span className="p-multiselect-filteredData-option">
        <span className="lab-name-text">{option.remarks}</span>
      </span>
    );
  };

  const set_rows = (e) => {
    setExpandedRows(e.data)
  }

  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-3">
        <h3>Deal Value: {data.deal_value_details.deal_value} Salesforce-ID: {data.sfid_details.sfid} </h3>
      </div>
    );
  };

  return (
    <div>
      <Toast ref={toast} position="top-center" />
      <div className="container">
        <br />
        {/* <div style={{ padding: "0.5rem 0" }}><span class="admin-report-header">Portal Reports</span></div> */}
        <div className="panel report-panel panel-content ">
          {/* <div className=" report-panel p-ai-center"> */}
          <div className="panel">
            <div className="panel-item">
              {/* Dropdown option to show days */}
              <Dropdown
                value={selectedDays}
                options={filterDays}
                onChange={onDaysChange}
                optionLabel="filterByDays"
                placeholder="Select quick dates"
                className="quick-date-selector"
              />
            </div>
            <div className="panel-item start-date-calender">
              {/* start date */}
              <Calendar
                // id="range"
                value={startDate}
                onChange={(e) => startDateChange(e)}
                dateFormat="mm/dd/yy"
                mask="99/99/9999"
                selectOtherMonths={true}
                placeholder="Select Start Date"
                icon="pi pi-calendar"
                showIcon
                className="date-icon"
              />

            </div>
            <div className="panel-item end-date-calender">
              {/* end date */}
              <Calendar
                value={endDate}
                onChange={(e) => endDateChange(e)}
                dateFormat="mm/dd/yy"
                mask="99/99/9999"
                selectOtherMonths={true}
                placeholder="Select End Date"
                icon="pi pi-calendar"
                showIcon
                className="date-icon"
              />
            </div>
          </div>
          <div className="action-buttons">
            <div className="panel-item">
              {/* search button */}
              <Button
                id="search-button"
                label="SEARCH"
                className="p-button-primary search-btn"
                onClick={searchHandle}
              />
            </div>
            <div className="panel-item">
              {/* Reset button */}
              <Button
                id="reset-button"
                label="RESET"
                className="p-button-primary reset-btn"
                onClick={resetHandle}
              />
            </div>
            <div className="panel-item p-splitbutton">
              {/* split button to show csv and pdf export option */}
              <SplitButton
                ref={exportButton}
                // style={{ width: "95px"}}
                className="p-splitbutton-button export-btn"
                onClick={() => exportButton.current.onDropdownButtonClick()}
                label="EXPORT"
                model={items}
              ></SplitButton>
            </div>
          </div>
          {/* </div> */}
        </div>

        <br />
        <DataTable
          ref={dt}
          value={users}
          expandedRows={expandedRows}
          onRowToggle={(e) => {set_rows(e)}} //{setExpandedRows(e.data)}}
          rowExpansionTemplate={rowExpansionTemplate}
          responsiveLayout="scroll"
          className="table-type"
          columnResizeMode="fit"
          filters={filters1}
          paginator
          rows={50}
          totalRecords={users.length}
          pageLinkSize={1}
          rowsPerPageOptions={[5, 10, 20, 50]}
          removableSort={true}
          exportFilename="lab_admin_report"
          onValueChange={filteredData => setFilteredValue(filteredData)}
        >
          <Column expander header="Sales Details" className="expand-cell" style={{ minWidth: '6rem', paddingLeft: "15px" }} />
          <Column
            field="Index"
            header="#"
            style={{ textAlign: "center", fontWeight: "600", width: "3rem", paddingLeft: "15px" }}
            body={onIndexTemplate}
          />
          <Column
            field={`lab_details.lab_name`}
            expander
            showFilterMatchModes={false}
            sortable={true}
            header="Lab Name"
            filterMenuStyle={{ width: "14rem" }}
            body={labNameBodyTemplate}
            filter
            filterElement={labNameFilterTemplate}
            filterField={"lab_details"}
            style={{ minWidth: "11rem", fontWeight: "600" }}
          />
          <Column
            field={`purpose_details.purpose`}
            showFilterMatchModes={false}
            sortable
            header="Purpose"
            filterMenuStyle={{ width: "14rem" }}
            body={purposeBodyTemplate}
            filter
            filterElement={purposeFilterTemplate}
            filterField="purpose_details"
          ></Column>
          <Column
            field={`customer_details.customer`}
            showFilterMatchModes={false}
            sortable
            header="Customer"
            filterMenuStyle={{ width: "14rem" }}
            body={customerBodyTemplate}
            filter
            filterElement={customerFilterTemplate}
            filterField="customer_details"
          ></Column>
          <Column
            field={`usedBy_details.used_by`}
            showFilterMatchModes={false}
            sortable
            header="Scheduled By"
            filterMenuStyle={{ width: "14rem" }}
            body={usedByBodyTemplate}
            filter
            filterElement={usedByFilterTemplate}
            filterField="usedBy_details"
            style={{ minWidth: "12rem" }}
          ></Column>
          <Column
            field="start_time"
            sortable
            header="Start Time"
            style={{ minWidth: "9rem" }}
          ></Column>
          <Column
            field="end_time"
            sortable
            header="End Time"
            style={{ minWidth: "9rem" }}
          ></Column>
          <Column
            field={`remark_details.remarks`}
            showFilterMatchModes={false}
            sortable
            header="Remark"
            filterMenuStyle={{ width: "14rem" }}
            body={remarkBodyTemplate}
            filter
            filterElement={remarkFilterTemplate}
            filterField="remark_details"
          ></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default PortalReports;
