import { Button } from 'primereact/button';
import React, { useState, useEffect } from 'react'
import LabServices from '../../services/LabServices';
import eventBus from '../utility/EventBus';
import "./BroadcastMessage.css"

const BroadcastMessage = () => {
    const [messageData, setMessageData] = useState([])
    const [messageIndex, setMessageIndex] = useState(0);
    const [broadcast, setBroadcast] = useState(true);

    useEffect(() => {
        //api for showng broadcast message
        LabServices.getBroadcastMessages().then((response) => {
            let messages = [];
            response.data.map((data) => messages.push(data.message));
            setMessageData(messages)
        })
        // refreshing broadcast message
        eventBus.on("Broadcast", async () => {
            LabServices.getBroadcastMessages().then((response) => {
                let messages = [];
                response.data.map((data) => messages.push(data.message));
                setMessageData(messages)
            })
        })
        return () => {
            setMessageData([]);
        }
    }, [])


    // on left arrow button click to show previous message
    const onLeftClick = () => {
        setMessageIndex(messageIndex - 1)
    }

    // on right arrow button click to show next message
    const onRightClick = () => {
        setMessageIndex(messageIndex + 1)
    }

    // on clicking 'X' to close broadcast messages
    const onCloseBroadcast = () => {
        setBroadcast(false);
    }
    return (
        <div className="broadcast-container">
            {broadcast && messageData.length !== 0 && <div className='p-grid p-m-0'>
                <div className='broadcast-pagination p-col-3'>
                    <Button icon='pi pi-angle-left' disabled={messageIndex >= 1 ? false : true} className='p-button-text p-button-rounded left-arrow broadcastMsg' onClick={() => onLeftClick(messageIndex)} />
                    <p className='p-ml-3 p-mr-3'> {messageData.indexOf(messageData[messageIndex]) + 1} / {messageData.length} </p>
                    <Button icon='pi pi-angle-right' className='p-button-text p-button-rounded right-arrow broadcastMsg' onClick={() => onRightClick(messageIndex)} disabled={messageIndex !== messageData.length - 1 ? false : true} />
                </div>
                <div className='broadcast-message p-col-9'>
                    <p className='p-m-0 broadcast-message-content'>{messageData[messageIndex]}</p>
                    <div className='broadcast-message-close'>
                        <Button icon='pi pi-times' className='broadcast-message-close-icon p-button-text p-button-rounded p-ml-auto' onClick={onCloseBroadcast} />
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default BroadcastMessage