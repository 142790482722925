import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import LabServices from "../../../../services/LabServices";
import "../../Rbac.css";
import loglevel from "../../../../logger/Logger";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import { Dropdown } from "primereact/dropdown";
import DenyLabRequest from "./DenyLabRequest";
import ApproveLabRequest from "./ApproveLabRequest";
import moment from "moment";
import LoadingSpinner from "../../../loader/LoadingSpinner";
import eventBus from "../../../utility/EventBus";

const LabRequests = () => {
    const [labRequests, setLabRequests] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const filterOptions = ["Approved", "Pending", "Denied"]
    const toast = useRef(null);
    const dt = useRef(null);
    const filterDays = [
        { filterByDays: "Last 90 days", days: 90 },
        { filterByDays: "Last 30 days", days: 30 },
        { filterByDays: "Last 15 days", days: 15 },
        { filterByDays: "Last 7 days", days: 7 },
    ];
    const [selectedDays, setSelectedDays] = useState({
        filterByDays: "Last 7 days",
        days: 7
    });
    const [loading, setLoading] = useState(false);

    async function fetchData() {
        let days = selectedDays.days
        getAllLabRequestsList(days)
    }

    const getAllLabRequestsList = async (days) => {
        setLoading(true);
        //api call for lab requests list
        await LabServices.getLabAccessRequests(days).then((res) => {
            setLabRequests(res.data)
            //Update notification count
            eventBus.dispatch("requestNotification")
            //Update lab notification count
            eventBus.dispatch("requestLabNotification")
        }).catch((error) => {
            loglevel.error("getLabAccessRequests", error);
        }).finally(() => {
            setLoading(false)
        });
    }

    const onDaysChange = (e) => {
        setSelectedDays(e.value)
        let days = e.value.days
        setLabRequests([])
        getAllLabRequestsList(days)
    }

    //method to show success toast message
    const showSuccessToast = (detail) => {
        console.log("this is toast message", detail)
        toast.current.show({
            severity: "success",
            detail: detail,
            life: 6000,
        });
    }

    useEffect(() => {
        fetchData();
    }, [])

    // index column in datatable
    const onIndexTemplate = (data, props) => {
        return (
            <div className="cat-name-table">
                {props.rowIndex + 1}
            </div>)
    }

    // purpose column in datatable
    const purposeTemplate = (rowData) => {
        return (
            <div className="short-desc">
                {rowData.purpose}
            </div>
        );
    };

    // request date column in datatable
    const requestDateTemplate = (rowData) => {
        return (
            <div>
                {moment(rowData.createdAt).format('DD MMM YYYY')}
            </div>
        );
    };

    // status column in datatable
    const statusTemplate = (rowData) => {
        if (rowData.status == "Pending") {
            return (
                <div className="enable-disable-container">
                    <div className="disable-icon">
                        <svg width="14" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00003 0.888916C4.07267 0.888916 0.888916 4.07267 0.888916 8.00003C0.888916 11.9274 4.07267 15.1111 8.00003 15.1111C11.9274 15.1111 15.1111 11.9274 15.1111 8.00003C15.1111 6.11404 14.3619 4.3053 13.0283 2.97171C11.6947 1.63812 9.88601 0.888916 8.00003 0.888916ZM5.86862 4.0667C4.43627 4.84283 3.5473 6.34427 3.55558 7.97336C3.56358 10.079 5.04804 11.89 7.11114 12.3111L6.99114 12.9911C4.95572 12.596 3.35787 11.0152 2.94094 8.9841C2.52402 6.95304 3.36989 4.87058 5.08505 3.70554C6.8002 2.5405 9.04782 2.52167 10.7823 3.6578L10.4045 4.26225C9.04423 3.36572 7.30098 3.29057 5.86862 4.0667ZM8.40892 4.77781V8.1778L10.6356 9.69336C10.7773 9.77716 10.8611 9.93247 10.8534 10.097C10.8456 10.2615 10.7475 10.4082 10.5984 10.4782C10.4494 10.5482 10.2739 10.5301 10.1422 10.4311L7.52003 8.65336V4.77781C7.52003 4.53235 7.71901 4.33336 7.96447 4.33336C8.20993 4.33336 8.40892 4.53235 8.40892 4.77781ZM1.7778 8.00003C1.7778 11.4365 4.56359 14.2222 8.00003 14.2222C9.65026 14.2222 11.2329 13.5667 12.3998 12.3998C13.5667 11.2329 14.2222 9.65026 14.2222 8.00003C14.2222 4.56359 11.4365 1.7778 8.00003 1.7778C4.56359 1.7778 1.7778 4.56359 1.7778 8.00003Z" fill="#ADBBC4" />
                        </svg>
                    </div>
                    <div className="datatable-disable-pending-label">Pending</div>
                </div>
            );
        } else if (rowData.status == "Approved") {
            return (
                <div className="enable-disable-container">
                    <div className="enable-icon">
                        <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0.777344 7.00005C0.777344 10.4365 3.56313 13.2223 6.99957 13.2223C8.6498 13.2223 10.2324 12.5667 11.3993 11.3998C12.5662 10.2329 13.2218 8.65029 13.2218 7.00005C13.2218 3.56362 10.436 0.777832 6.99957 0.777832C3.56313 0.777832 0.777344 3.56362 0.777344 7.00005ZM1.55512 7.00005C1.55512 3.99317 3.99268 1.55561 6.99957 1.55561C10.0064 1.55561 12.444 3.99317 12.444 7.00005C12.444 10.0069 10.0064 12.4445 6.99957 12.4445C3.99268 12.4445 1.55512 10.0069 1.55512 7.00005Z"
                                fill="#61B715"
                            />
                            <path
                                d="M6.28622 8.61488L4.54789 6.87821C4.49126 6.81209 4.49507 6.71352 4.55663 6.65195C4.61819 6.59039 4.71676 6.58659 4.78289 6.64321L6.28289 8.14321L9.21455 5.21488C9.28068 5.15825 9.37925 5.16206 9.44081 5.22362C9.50238 5.28518 9.50618 5.38375 9.44955 5.44988L6.28622 8.61488Z"
                                fill="#61B715"
                                stroke="#61B715"
                                strokeWidth="0.4"
                            />
                        </svg>
                    </div>

                    <div className="datatable-enable-label">Approved</div>
                </div>
            );
        } else if (rowData.status == "Denied") {
            return (
                <div className="enable-disable-container">
                    <div className="disable-icon">
                        <svg width="14" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.9" fill-rule="evenodd" clip-rule="evenodd" d="M16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 20.2435 30.3143 24.3131 27.3137 27.3137C24.3131 30.3143 20.2435 32 16 32ZM16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2ZM22.47 11.14L17.61 16L22.33 20.69C22.6698 21.0868 22.6469 21.6782 22.2776 22.0476C21.9082 22.4169 21.3168 22.4398 20.92 22.1L16.18 17.36L11.41 22.13C11.1656 22.4154 10.7819 22.5397 10.4166 22.4518C10.0513 22.3639 9.76606 22.0787 9.67819 21.7134C9.59032 21.3481 9.71462 20.9644 10 20.72L14.78 16L9.9 11.06C9.56022 10.6632 9.58307 10.0718 9.95244 9.70244C10.3218 9.33307 10.9132 9.31022 11.31 9.65L16.2 14.54L21.06 9.73C21.4568 9.39022 22.0482 9.41307 22.4176 9.78244C22.7869 10.1518 22.8098 10.7432 22.47 11.14Z" fill="#882D31" />
                        </svg>
                    </div>
                    <div className="datatable-disable-label">Denied</div>
                </div>
            );
        }
    };

    const actionBodyTemplate = (rowData) => {
        if (rowData.status == "Pending") {
            return (
                <div className="table-action-body">
                    <div className="table-update-icon">
                        {/* Approve Lab request component */}
                        <ApproveLabRequest rowData={rowData} fetchData={fetchData} showSuccessToast={showSuccessToast} />
                    </div>
                    <div className="table-delete-icon">
                        {/* Deny Lab req component */}
                        <DenyLabRequest rowData={rowData} fetchData={fetchData} showSuccessToast={showSuccessToast} />
                    </div>
                </div>
            );
        }
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={filterOptions} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Select a Status" className="p-column-filter" showClear />;
    }

    const statusItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }

    return (
        <div>
            <Toast ref={toast} position="top-center" />
            {loading ?
                <div className="loader">
                    <LoadingSpinner />
                </div> :
                <div className="datatable-crud-demo">
                    <div className="admin-panel">
                        <div className="admin-panel-search-bar">
                            <span className="p-input-icon-left">
                                <i className="pi pi-search" />
                                {/* Search bar */}
                                <InputText
                                    type="search"
                                    onInput={(e) => setGlobalFilter(e.target.value)}
                                    placeholder="Search"
                                    className="global-search-filter"
                                    style={{ height: "33px" }}
                                />
                            </span>
                        </div>
                        <div className="admin-panel-search-bar" style={{ paddingRight: "10px" }}>
                            <Dropdown
                                value={selectedDays}
                                options={filterDays}
                                onChange={onDaysChange}
                                optionLabel="filterByDays"
                                placeholder="Select quick dates"
                                className="quick-date-selector"
                            />
                        </div>
                    </div>

                    <DataTable
                        value={labRequests}
                        ref={dt}
                        className="lab-crud-table"
                        columnResizeMode="fit"
                        paginator
                        rows={50}
                        pageLinkSize={1}
                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                        globalFilter={globalFilter}
                        responsiveLayout="scroll"
                        autoLayout={true}
                        removableSort={true}
                        let-i="rowIndex"
                        style={{ width: "100%" }}
                        filters={""}
                    >
                        <Column
                            field="Index"
                            header="#"
                            body={onIndexTemplate}
                            style={{ width: "3rem", paddingLeft: "15px" }}
                        ></Column>
                        <Column
                            sortable
                            field="createdAt"
                            header="Request Date"
                            body={requestDateTemplate}
                            style={{ minWidth: "6rem", maxWidth: "6rem" }}>
                        </Column>
                        <Column
                            sortable
                            field="first_name"
                            header="First Name"
                            style={{ minWidth: "9rem" }}>
                        </Column>
                        <Column
                            sortable
                            field="last_name"
                            header="Last Name"
                            style={{ minWidth: "9rem" }}>
                        </Column>
                        <Column
                            sortable
                            field="email"
                            header="Email"
                            style={{ minWidth: "9rem" }}>
                        </Column>
                        <Column
                            sortable
                            field="lab_name"
                            header="Lab Name"
                            style={{ minWidth: "9rem" }}>
                        </Column>
                        {/* <Column
                            sortable
                            field="purpose"
                            header="Purpose"
                            body={purposeTemplate}
                            style={{ minWidth: "8rem", maxWidth: "8rem" }}>
                        </Column> */}
                        <Column
                            sortable
                            field="status"
                            header="Status"
                            body={statusTemplate}
                            filter
                            filterElement={statusFilterTemplate}
                            showFilterMatchModes={false}
                            style={{ minWidth: "4rem" }}>
                        </Column>
                        <Column
                            body={actionBodyTemplate}
                            exportable={false}
                            style={{ minWidth: "4rem" }}
                        ></Column>
                    </DataTable>
                </div>
            }
        </div>
    );
};

export default LabRequests