import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import "../../Rbac.css";
import React, { useState, useRef } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Message } from "primereact/message";
import LabServices from "../../../../services/LabServices";
import loglevel from "../../../../logger/Logger";
import { Checkbox } from "primereact/checkbox";
import { Calendar } from "primereact/calendar";
import moment from "moment";



const DeleteAsset = ({ assetId, assetName, enabled, deleted, fetchData, showToastSuccess }) => {
  const [deleteAssetDisplay, setDeleteAssetDisplay] = useState(false);
  const deleteAssetDialogMap = {
    deleteAssetDisplay: setDeleteAssetDisplay,
  };
  const [checked, setChecked] = useState(false);
  const [associatedLabs, setAssociatedLabs] = useState([]);
  const [assetErrorMsg, setAssetErrorMsg] = useState('');
  const [endDate, setEndDate] = useState("");
  const [isEmptyDate, setIsEmptyDate] = useState(true);
  const [dateDeleteMsg, setDateDeleteMsg] = useState("");
  const [strikeOutDates, setStrikeOutDates] = useState([]);
  const [date, setDate] = useState(null);
  const [isValidated, setIsValidated] = useState(false);

  const errorRef = useRef();

  const updateList = () => fetchData()

  const showToast = (data) => showToastSuccess(data)

  const onClick = async (name) => {

    setChecked(false)
    setAssetErrorMsg('');
    setDateDeleteMsg('')
    setDate(null)
    LabServices.getLabListForAsset(assetId).then((res) => {
      setAssociatedLabs(res.data);
    });

    LabServices.getAssetById(assetId).then((res) => {
      console.log(res.data.asset_disabled_strike_out_date, "resssp**********")
      let strikeOutDate = res.data.asset_disabled_strike_out_date.map((date) => {
        return new Date(date);
      });
      console.log(strikeOutDate, "strikeoutDates")
      setStrikeOutDates(strikeOutDate);
    })
    deleteAssetDialogMap[`${name}`](true);
  };

  const onHide = (name) => {
    deleteAssetDialogMap[`${name}`](false);
    setChecked(false)
  };

  const onDelete = async (e) => {
    // api call for deleting asset
    setIsValidated(true);
    if (date && checked) {
      console.log(date, "Asset date");
      await LabServices.deleteAsset(assetId, date)
        .then((response) => {
          console.log(response, "asset response");
          if (response.status === 200) {
            showToast({
              summary: "Physical asset Successfully Deleted",
              detail: `Physical asset ${assetName} has been deleted successfully.`,
            })
            deleteAssetDialogMap[`deleteAssetDisplay`](false);
            updateList()
          } else {
            loglevel.debug("Asset deletion is Failed");
          }
        })
        .catch((error) => {
          setAssetErrorMsg(error.response.data.msg);
          errorRef.current.scrollIntoView({ behavior: "smooth" });
        });
    }
  };

  const renderFooter = (name) => {
    return (
      <div className="disable-footer-button">


        <Button
          label="DELETE ASSET"
          className={
            checked && date ? "p-button-outlined p-button-danger" : "p-button-outlined p-button-secondary"
          }
          onClick={() => onDelete("deleteAssetDisplay")}
          disabled={!checked || !date}
        />

        <div>
          {" "}
          <Button
            label="CANCEL"
            onClick={() => onHide('deleteAssetDisplay')}
            className="p-button-outlined p-button-primary"
          />
        </div></div>
    );
  };

  const onSelectDate = (e) => {
    var message = `Physical asset will be deleted on ${moment(e.value).format("MM-DD-YYYY")}`;
    setDateDeleteMsg(message);
    setIsEmptyDate(false);
  };

  return (
    <div className="lab-type-edit">
      <div className={(!enabled && deleted) ? "trash-button" : ""}>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded global-delete-button"
          onClick={() => onClick("deleteAssetDisplay")}
          disabled={!enabled && deleted}
        />
      </div>
      <Dialog
        header={`Delete Physical Asset : ${assetName}`}
        visible={deleteAssetDisplay}
        footer={renderFooter("deleteAssetDisplay")}
        onHide={() => onHide("deleteAssetDisplay")}
        className="lab-type-delete-dialog-box"
      >
        <div ref={errorRef} className={assetErrorMsg ? "" : "hidden"}>
          {assetErrorMsg && <Message severity="error" text={assetErrorMsg} className='common-inline-error common-inline-error p-mt-1 p-mb-3 full-width' />}
        </div>
        {associatedLabs.length ? (
          <div>
            <div className="p-mb-2">Upcoming Lab Schedules associated with Physical Asset</div>
            <DataTable
              value={associatedLabs}
              columnResizeMode="fit"
              className="disable-lab-crud-table"
              autoLayout={true}
              responsiveLayout="scroll"
            >
              <Column
                field="lab_name"
                header="Lab Name"
              ></Column>
              <Column
                field="schedule_start_date"
                header="Start Date"
              ></Column>
              <Column
                field="schedule_end_date"
                header="End Date"
              ></Column>
              <Column
                field="user_time_zone"
                header="Timezone"
              ></Column>
              <Column
                field="client_name"
                header="Client Name"
              ></Column>
              <Column
                field="purpose"
                header="Purpose"
              ></Column>
            </DataTable>
          </div>
        ) :
          (<div>
            <div className="no-scheduled-icon m-t-30">
              <div>
                <svg
                  width="44"
                  height="51"
                  viewBox="0 0 44 51"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.6556 49.8753C20.9977 49.8654 20.3548 49.8613 19.725 49.8315L19.687 50.6404C20.33 50.671 20.9845 50.6817 21.6564 50.685C22.1459 50.685 22.6296 50.6759 23.1042 50.6586L23.0736 49.8481C22.6081 49.8654 22.136 49.8753 21.6556 49.8753Z"
                    fill="#66B0D6"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.4979 42.6001C19.3284 42.6315 18.1802 42.7124 17.0874 42.8419L17.1815 43.6459C18.2504 43.5196 19.3746 43.4404 20.5186 43.4098L20.4979 42.6001Z"
                    fill="#66B0D6"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.85219 46.6353C9.85219 46.5495 9.86788 46.4645 9.89759 46.3811L9.13492 46.1079C9.07385 46.2788 9.04248 46.4562 9.04248 46.6353C9.04248 47.5334 9.79524 48.3324 11.2785 49.0075L11.6136 48.2704C10.4787 47.7538 9.85219 47.1718 9.85219 46.6353Z"
                    fill="#66B0D6"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.5483 43.252C13.2326 43.5285 12.1192 43.8751 11.2368 44.282L11.5752 45.0175C12.4023 44.6361 13.4588 44.3093 14.7142 44.0443L14.5483 43.252Z"
                    fill="#66B0D6"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.7495 49.8364C14.7722 50.0906 15.9096 50.2912 17.1312 50.434L17.2236 49.6292C16.0367 49.4906 14.9348 49.2958 13.9443 49.0498L13.7495 49.8364Z"
                    fill="#66B0D6"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M32.2958 44.4455C31.4465 44.0081 30.3388 43.6325 29.0033 43.3296L28.8242 44.1187C30.0747 44.4026 31.1469 44.765 31.9244 45.1653L32.2958 44.4455Z"
                    fill="#66B0D6"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M26.4669 42.8862C25.3807 42.745 24.2334 42.6526 23.0588 42.6113L23.0308 43.4202C24.1797 43.4615 25.3014 43.5523 26.3629 43.6901L26.4669 42.8862Z"
                    fill="#66B0D6"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M33.3422 46.635C33.3422 47.2244 32.5713 47.8748 31.2275 48.4212L31.5338 49.1706C33.6988 48.2924 34.1519 47.3061 34.1519 46.635L34.1503 46.5698L33.3422 46.635Z"
                    fill="#66B0D6"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M25.5752 49.6725L25.661 50.4781C26.876 50.3502 28.0151 50.1661 29.0468 49.93L28.8661 49.1401C27.8657 49.3688 26.7596 49.5487 25.5752 49.6725Z"
                    fill="#66B0D6"
                  />
                  <mask
                    id="mask0_13387_14263"
                    style={{ "maskType": "alpha" }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="44"
                    height="39"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 0H43.4363V38.8761H0V0Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask0_13387_14263)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M36.8754 8.35797C32.8368 9.35505 27.4543 9.90476 21.7187 9.90476C15.983 9.90476 10.5997 9.35505 6.56108 8.35797C2.03708 7.24038 0.830349 5.95029 0.830349 5.36508C0.830349 4.77987 2.03708 3.4906 6.56108 2.37302C10.5997 1.37511 15.983 0.825397 21.7187 0.825397C27.4543 0.825397 32.8368 1.37511 36.8754 2.37302C41.3994 3.4906 42.6062 4.77987 42.6062 5.36508C42.6062 5.95029 41.3994 7.24038 36.8754 8.35797ZM23.7987 24.4937V36.1961C23.5164 36.6699 22.8759 37.2039 21.9217 37.6092C20.8297 38.0723 20.0159 38.0855 19.6717 37.979V24.4937L3.31232 8.21187C7.15289 9.724 13.9583 10.7302 21.7187 10.7302C29.5145 10.7302 36.348 9.71492 40.1787 8.19124L23.7987 24.4937ZM43.4365 5.36508C43.4365 2.4019 33.7133 0 21.7187 0C9.72317 0 0 2.4019 0 5.36508C0 5.92387 0.346667 6.46203 0.987175 6.968L0.986349 6.96883L18.8463 24.8238V38.4098C19.1013 38.7359 19.566 38.8762 20.1248 38.8762C20.767 38.8762 21.5338 38.6905 22.2461 38.3884C23.419 37.8898 24.4672 37.0669 24.6224 36.2729V24.8238L42.4848 6.96883L42.4683 6.95314C43.0973 6.4513 43.4365 5.9181 43.4365 5.36508Z"
                      fill="#66B0D6"
                    />
                  </g>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.56115 2.37265C2.03715 3.49023 0.82959 4.7795 0.82959 5.36471C0.82959 5.95074 2.03715 7.24001 6.56115 8.3576C9.14794 8.99646 12.2894 9.4496 15.7197 9.69392L12.7574 1.31201C10.4834 1.57036 8.38032 1.92363 6.56115 2.37265Z"
                    fill="#66B0D6"
                    fill-opacity="0.4"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.5003 10.5367L22.2178 24.4867V37.4628C23.0011 37.0798 23.5483 36.6168 23.7992 36.1958V24.4933L40.1784 8.19092C37.3316 9.32336 32.8258 10.1743 27.5003 10.5367Z"
                    fill="#66B0D6"
                    fill-opacity="0.4"
                  />
                </svg>
              </div>
              <div className="lab-scheduled-msg">
                We couldn’t find any lab Schedule!
              </div>
            </div>
          </div>)}

        {/* {/-------------------------------------------*  */}

        <div className="lab-schedule-warning m-t-30">
          <div className="lab-warning-icon">
            <svg
              width="17"
              height="15.98"
              viewBox="0 0 18 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.7266 1.06685L17.22 13.0269V13.0469C17.5562 13.6669 17.5409 14.4182 17.1799 15.024C16.8188 15.6299 16.1653 16.0008 15.46 16.0002H2.4733C1.76195 16.0048 1.10172 15.6311 0.739365 15.019C0.377014 14.4068 0.367087 13.6483 0.713297 13.0269L7.2133 1.06685C7.56349 0.423434 8.23742 0.0229492 8.96996 0.0229492C9.70251 0.0229492 10.3764 0.423434 10.7266 1.06685ZM15.46 14.6469C15.6953 14.6468 15.9133 14.5227 16.0333 14.3202C16.154 14.118 16.1591 13.8671 16.0466 13.6602L9.5533 1.70019C9.43692 1.48457 9.21165 1.35017 8.96663 1.35017C8.72161 1.35017 8.49634 1.48457 8.37996 1.70019L1.88663 13.6602C1.77346 13.867 1.77795 14.1182 1.89845 14.3209C2.01895 14.5236 2.23753 14.6475 2.4733 14.6469H15.46ZM8.96663 13.1802C9.51892 13.1802 9.96663 12.7325 9.96663 12.1802C9.96663 11.6279 9.51892 11.1802 8.96663 11.1802C8.41435 11.1802 7.96663 11.6279 7.96663 12.1802C7.96663 12.7325 8.41435 13.1802 8.96663 13.1802ZM9.86663 9.34685C9.86663 9.8255 9.47861 10.2135 8.99996 10.2135C8.76778 10.2135 8.54529 10.1204 8.38237 9.95498C8.21945 9.78955 8.12972 9.56567 8.1333 9.33352V5.33352C8.1333 4.85487 8.52132 4.46685 8.99996 4.46685C9.47861 4.46685 9.86663 4.85487 9.86663 5.33352V9.34685Z"
                fill="#E9ECEF"
              />
            </svg>
          </div>
          <div className="lab-warning-msg">
            {associatedLabs.length ?
              <div className="p-mb-1">
                If you delete the physical asset, future associated lab schedules will be cancelled but Active lab schedules will remain and run as they are.
              </div>
              :
              <div className="p-mb-1">
                This physical asset is not associated with any lab schedules.
              </div>
            }
          </div>
        </div>

        {/* -------------------------------------------------------------- */}

        <div className="delete-lab-datepicker">
          <div className="delete-lab-datepicker-label">
            Effective Delete Date<span className="lab-required-field">*</span> :
          </div>
          <div className="delete-lab-calendar">
            <Calendar
              id="icon"
              minDate={new Date()}
              selectOtherMonths={true}
              value={date}
              disabledDates={strikeOutDates}
              className={isEmptyDate && isValidated ? "error-block" : ""}
              onChange={(e) => setDate(moment(e.value).format("YYYY-MM-DD"))}
              onSelect={(e) => onSelectDate(e)}
              placeholder="Select Date"
              showIcon
              readOnlyInput
            />
            {isEmptyDate && isValidated ? (
              <div className="error-message-inline">
                <small className="error-message" id="error-message">
                  To Delete an asset, date is mandatory.
                </small>
              </div>
            ) : (
              ""
            )}
          </div>
          {dateDeleteMsg ? <div>{dateDeleteMsg}</div> : ""}
        </div>
        <div className="field-checkbox">
          <div>
            {" "}
            <Checkbox
              inputId="binary"
              className=".checkbox"
              checked={checked}
              onChange={(e) => setChecked(e.checked)}
            />
          </div>

          {associatedLabs.length ?
            <div className="checkbox-label" htmlFor="binary">
              I understand. Still want to delete the physical asset.
            </div> :
            <div className="checkbox-label" htmlFor="binary">
              I understand. Delete the physical asset.
            </div>
          }
        </div>

      </Dialog>
    </div>
  );
};

export default DeleteAsset;
