import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import "../../Rbac.css";
import "../InventoryMgmt.css"
import React, { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { confirmPopup } from "primereact/confirmpopup";
import parse from "html-react-parser";
import LabServices from "../../../../services/LabServices";
import loglevel from "../../../../logger/Logger";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from 'primereact/inputtextarea';
import { Message } from "primereact/message";

 
const CreateServerModel = ({ setChanger }) => {
  const [isValidated, setIsValidated] = useState(false);
  const [createServerModelDisplay, setCreateServerModelDisplay] = useState(false);
  const createServerModelDialogMap = {
    createServerModelDisplay: setCreateServerModelDisplay
  };
  const [isAddManufacturerVisible, setIsAddManufacturerVisible] = useState(false);
  const [manufacturersList, setManufacturersList] = useState([]);
  const [modelDesc, setModelDesc] = useState('');
  const [manufacturer, setManufacturer] = useState('');
  const [newManufacturer, setNewManufacturer] = useState('');
  const [modelNum, setModelNum] = useState('');
  const [modelName, setModelName] = useState('');
  const [serverErrorMsg, setServerErrorMsg] = useState("");


  const toast = useRef(null);

  // On click "Create New Server Model"
  const onClick = async (name) => {
    await LabServices.getManufacturers()
            .then((res) => {
                setManufacturersList(res.data)
            })
            .catch((error) => {
                loglevel.error("Error while fetching Manufacturers list", error);
            });

    setModelName('');
    setManufacturer('');
    setNewManufacturer('');
    setModelNum('');
    setModelDesc('');
    setIsValidated(false);
    setIsAddManufacturerVisible(false);
    setServerErrorMsg('');

    createServerModelDialogMap[`${name}`](true);


  };

  const onHide = (name) => {
    setIsValidated(false);
    setIsAddManufacturerVisible(false);
    setServerErrorMsg('');
    createServerModelDialogMap[`${name}`](false);
  };

  const accept = async (requestBody) => {
    await LabServices.createServerModel(requestBody)
      .then((response) => {
        if (response.status === 201) {
          toast.current.show({
            severity: "success",
            summary: "Server Model successfully added",
            detail: "Server Model has been successfully added.",
            life: 6000,
          });
          createServerModelDialogMap["createServerModelDisplay"](false);
        } else {
          loglevel.debug("Server Model not added.");
        }
      })
      .catch((error) => {
        loglevel.error("Server Model addition failure", error.response.data);
        if (error.response.status === 400) {
          setServerErrorMsg(error.response.data.msg);
          // toast.current.show({
          //   severity: "error",
          //   summary: "Server Model could not be added",
          //   detail: `${error.response.data.msg}`,
          //   sticky: true,
          // });
        }
      })
    await LabServices.getServerModelsList().then((res) => {
      setChanger(res.data);
    });
  };

  const onAdd = async (e) => {
    <Toast ref={toast} position="top-center"/>;

    if (modelName && modelNum && (manufacturer || newManufacturer)) {
        const requestBody = {
                name: modelName,
                model_no: modelNum,
                manufacturer: newManufacturer || manufacturer,
                description: modelDesc   
        }
        
        await LabServices.createServerModel(requestBody)
        .then((response) => {
          if (response.status === 201) {
            toast.current.show({
              severity: "success",
              summary: "Server Model successfully added",
              detail: "Server Model has been successfully added.",
              life: 6000,
            });
            createServerModelDialogMap["createServerModelDisplay"](false);
          } else {
            loglevel.debug("Server Model not added.");
          }
        })
        .catch((error) => {
          loglevel.error("Server Model addition failure", error.response.data);
          if (error.response.status === 400) {
            setServerErrorMsg(error.response.data.msg);
            // toast.current.show({
            //   severity: "error",
            //   summary: "Server Model could not be added",
            //   detail: `${error.response.data.msg}`,
            //   sticky: true,
            // });
          }
        })
      await LabServices.getServerModelsList().then((res) => {
        setChanger(res.data);
      });
// --------------------------------------------
      // console.log(requestBody)
      // var confirmationMsg = `Are you sure you want to add a new model?`;

      // confirmPopup({
      //   target: e.currentTarget,
      //   message: parse(`${confirmationMsg}`),
      //   icon: "pi pi-exclamation-triangle",
      //   className: "popup",
      //   acceptLabel: "YES",
      //   rejectLabel: "NO",
      //   accept: () => accept(requestBody),
      // });
    } else {
      setIsValidated(true);
    }
  };

  const onNewManufacturerSelect = (e) => {
    setManufacturer('')
    setNewManufacturer(e.target.value)
  }

  const onCloseManufacturer = (e) => {
      setIsAddManufacturerVisible(false)
      setNewManufacturer('')
  }

//   const onEditDesc = (e) => {
//   (e.target.value ? setModelDesc(e.target.value) : setModelDesc('-'))
// }

  const renderFooter = (name) => {
    return (
      <div>
        <Button
          // label="Update"
          className="calender-button"
          style={{ background: "#49AFD9" }}
          onClick={(e) => onAdd(e)}
        >
          <span className="name">CREATE MODEL</span>
        </Button>
      </div>
    );
  };

  return (
    <div className="admin-panel-content">
            <Toast ref={toast} position="top-center"/>
     
          <div className="create-asset-button">
            <Button
              id="reset-button"
              label="CREATE A NEW MODEL"
              className="p-button-primary"
              onClick={() => onClick("createServerModelDisplay")}
            />
          </div>

      <Dialog
        header="Create new Server Model"
        visible={createServerModelDisplay}
        footer={renderFooter("createServerModelDisplay")}
        onHide={() => onHide("createServerModelDisplay")}
        className="add-asset-dialog-box"
      >
      {serverErrorMsg && <Message severity="warn" text={serverErrorMsg} className='common-inline-error common-error-message p-mb-3'/>}

        {/*  --------------------------Model Name:------------------------------ */}
        <div className="asset-input-text">
          <div className="asset-label">Model Name<span className="asset-required-field">*</span> :</div>
          <div>
            <InputText
              value={modelName}
              onChange={(e) => setModelName(e.target.value)}
              placeholder="Enter Model Name"
              maxLength={20}
              className={!modelName && isValidated ? "asset-input-error asset-text-box" : "asset-text-box"}
            />
            {!modelName && isValidated ? (
              <div className="cat-error-message-inline">
                <small className="p-error p-d-block" id="error-message">Model name is mandatory</small>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/*  --------------------------Description:------------------------------- */}
        <div className="asset-input-text">
          <div className="asset-label">Description
          <span className="optional-fields"> (Optional)</span> :
          </div>
          <div>
            <InputTextarea
              value={modelDesc}
              onChange={(e) => setModelDesc(e.target.value)}
              placeholder="Enter Description"
              className="model-text-area"
            />
          </div>
        </div>
        {/*  ----------------------------Model No.:----------------------------- */}
        <div className="asset-input-text">
          <div className="asset-label">Model No.
            <span className="asset-required-field">*</span> :
          </div>
          <div>
            <InputText
              value={modelNum}
              onChange={(e) => setModelNum(e.target.value)}
              placeholder="Enter Model No."
              maxLength={20}
              className={!modelNum && isValidated ? "asset-input-error asset-text-box" : "asset-text-box"}
            />
            {!modelNum && isValidated ? (
              <div className="cat-error-message-inline">
                <small className="p-error p-d-block" id="error-message">Model No. is mandatory</small>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        {/*  -------------------------Manufacturer:-------------------------------- */}
        <div className="asset-input-text">
          <div className="asset-label">Manufacturer
            <span className="asset-required-field">*</span> :
          </div>
          <div className="tags-container">
            <div>
              <Dropdown
                value={manufacturer}
                name="asset-dropdown"
                disabled = {newManufacturer ? true : false}
                options={manufacturersList}
                onChange={(e) => setManufacturer(e.target.value)}
                placeholder="Choose Manufacturer"
                className={!(manufacturer || newManufacturer) && isValidated ? "asset-input-error asset-dropdown" : "asset-dropdown"}
                display="chip"
                allowDuplicate={false}
              />
              {!(manufacturer || newManufacturer) && isValidated ? (
                <div className="cat-error-message-inline">
                  <small className="p-error p-d-block" id="error-message">Manufacturer is mandatory</small>
                </div>
              ) : (
                ""
              )}
            </div>
            {isAddManufacturerVisible ?
              <div className="add-tag-ip-btn">
                <div>
                  <InputText
                    //value={manufacturer}
                    onChange={(e) => onNewManufacturerSelect(e)}
                    placeholder="Enter Manufacturer Name"
                    className="model-additional-text-box"
                  />
                </div>
                <div className="asset-plus-icon">
                  <i
                    className="pi pi-times-circle"
                    style={{ fontSize: "1.4em", color: "#ADBBC4" }}
                    onClick={() => onCloseManufacturer()}
                  ></i>
                </div>
              </div>
              : ""}
            {!isAddManufacturerVisible ?
              <div className="asset-plus-icon">
                <i
                  className="pi pi-plus-circle"
                  style={{ fontSize: "1.4em", color: "#ADBBC4" }}
                  onClick={() => setIsAddManufacturerVisible(true)}
                ></i>
              </div> : ""}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default CreateServerModel;
