import "../../Rbac.css";
import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import CreateLabCategory from "./CreateLabCategory";
import LabServices from "../../../../services/LabServices";
import loglevel from "../../../../logger/Logger";
import UpdateLabCategory from "./UpdateLabCategory";
import DeleteLabCategory from "./DeleteLabCategory";

const LabCategories = () => {
  const [categories, setCategories] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    async function fetchData() {
      await LabServices.getLabCategoryList()
        .then((res) => {
          loglevel.debug("getReservedLabs Response", res);
          setCategories(res.data);
          console.log("json", res.data);
          //setCategories(data1);
        })
        .catch((error) => {
          loglevel.error("getLabsCategoryList", error);
        });
    }
    fetchData();
  }, []);

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="table-action-body">
        <div className="table-update-icon">
        <UpdateLabCategory
          catId={rowData.categoryId}
          catName={rowData.name}
          setChanger={setCategories}
        />
        </div>
        <div className="table-delete-icon">
        <DeleteLabCategory
          catId={rowData.categoryId}
          catName={rowData.name} 
          setChanger={setCategories}
        /> 
        </div>
      </div>
    );
  };

  const EnabledTemplate = (rowData) => {
    if (rowData.enabled) {
      return (
        <div className="enable-disable-container">
          <div className="enable-icon">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.777344 7.00005C0.777344 10.4365 3.56313 13.2223 6.99957 13.2223C8.6498 13.2223 10.2324 12.5667 11.3993 11.3998C12.5662 10.2329 13.2218 8.65029 13.2218 7.00005C13.2218 3.56362 10.436 0.777832 6.99957 0.777832C3.56313 0.777832 0.777344 3.56362 0.777344 7.00005ZM1.55512 7.00005C1.55512 3.99317 3.99268 1.55561 6.99957 1.55561C10.0064 1.55561 12.444 3.99317 12.444 7.00005C12.444 10.0069 10.0064 12.4445 6.99957 12.4445C3.99268 12.4445 1.55512 10.0069 1.55512 7.00005Z"
                fill="#61B715"
              />
              <path
                d="M6.28622 8.61488L4.54789 6.87821C4.49126 6.81209 4.49507 6.71352 4.55663 6.65195C4.61819 6.59039 4.71676 6.58659 4.78289 6.64321L6.28289 8.14321L9.21455 5.21488C9.28068 5.15825 9.37925 5.16206 9.44081 5.22362C9.50238 5.28518 9.50618 5.38375 9.44955 5.44988L6.28622 8.61488Z"
                fill="#61B715"
                stroke="#61B715"
                strokeWidth="0.4"
              />
            </svg>
          </div>

          <div className="datatable-enable-label">Enabled</div>
        </div>
      );
    } else {
      return (
        <div className="enable-disable-container">
          <div className="disable-icon">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.99957 13.2223C3.56313 13.2223 0.777344 10.4365 0.777344 7.00005C0.777344 3.56362 3.56313 0.777832 6.99957 0.777832C10.436 0.777832 13.2218 3.56362 13.2218 7.00005C13.2218 8.65029 12.5662 10.2329 11.3993 11.3998C10.2324 12.5667 8.6498 13.2223 6.99957 13.2223ZM6.99957 1.55561C3.99268 1.55561 1.55512 3.99317 1.55512 7.00005C1.55512 10.0069 3.99268 12.4445 6.99957 12.4445C10.0064 12.4445 12.444 10.0069 12.444 7.00005C12.444 3.99317 10.0064 1.55561 6.99957 1.55561ZM9.51568 5.11005L7.62568 7.00005L9.46123 8.82394C9.59337 8.97824 9.58448 9.20824 9.44084 9.35188C9.2972 9.49553 9.06719 9.50441 8.9129 9.37228L7.06957 7.52894L5.21457 9.38394C5.11953 9.49492 4.9703 9.54326 4.82824 9.50909C4.68618 9.47492 4.57526 9.364 4.54108 9.22194C4.50691 9.07988 4.55525 8.93065 4.66623 8.83561L6.52512 7.00005L4.62734 5.07894C4.49521 4.92465 4.50409 4.69465 4.64774 4.551C4.79138 4.40736 5.02138 4.39847 5.17568 4.53061L7.07734 6.43228L8.96734 4.56172C9.12164 4.42959 9.35164 4.43847 9.49528 4.58211C9.63893 4.72576 9.64781 4.95576 9.51568 5.11005Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="datatable-disable-label">Disabled</div>
        </div>
      );
    }
  };

  const onIndexTemplate = (data, props) => {
    return <div className="cat-name-table">{props.rowIndex + 1}</div>;
  };

  const labnameTemplate = (rowData) => {
    return <div className="cat-name-table">{rowData.name}</div>;
  };

  const parentCategoryTemplate = (rowData) => {
    return <div className="cat-name-table">{rowData.parent_category}</div>;
  };

  const labNumTemplate = (rowData) => {
    return <div className="cat-name-table">{rowData.no_of_labs}</div>;
  };

  const descriptionTemplate = (rowData) => {
    return (
      <div className="cat-data-short-desc-table">{rowData.description}</div>
    );
  };

  // const tooltipTemplate = (rowData) => {
  //   return <div className="cat-tooltip-table">{rowData.tooltip}</div>;
  // };

  return (
    <div className="datatable-crud-demo">
      <div className="admin-panel">
        <div className="admin-panel-search-bar">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search"
              className="global-search-filter"
            />
          </span>
        </div>
      <CreateLabCategory setChanger={setCategories} />
      </div>
      <Toast ref={toast}  position="top-center" />
      <DataTable
        value={categories}
        ref={dt}
        className="lab-crud-table"
        columnResizeMode="fit"
        paginator
        rows={50}
        pageLinkSize={1}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        globalFilter={globalFilter}
        responsiveLayout="scroll"
        autoLayout={true}
        let-i="rowIndex"
        style={{ width: "100%" }}
        removableSort={true}
      >
        <Column
          field="Index"
          header="#"
          body={onIndexTemplate}
          style={{ width: "3rem", paddingLeft: "10px", fontWeight:"600" }}
        ></Column>
        <Column
          sortable
          field="name"
          header="Category Name"
          body={labnameTemplate}
          style={{ minWidth: "10rem",fontWeight:"600" }}
        ></Column>
        <Column
          sortable
          field="no_of_labs"
          header="No. of labs"
          body={labNumTemplate}
          style={{ width: "7rem", textAlign: "center" }}
        ></Column>
        <Column
          sortable
          field="description"
          header="Description"
          body={descriptionTemplate}
          style={{ minWidth: "16rem" }}
        ></Column>  
        <Column
          sortable
          field="parent_category"
          header="Parent Category"
          body={parentCategoryTemplate}
          style={{ minWidth: "12rem" }}
        ></Column>     
         <Column
          sortable
          field="enabled"
          header="Status"
          placeholder="-"
          body={EnabledTemplate}
          style={{ minWidth: "7rem" }}
        ></Column>
        <Column
          body={actionBodyTemplate}
          exportable={false}
          style={{ minWidth: "4rem" }}
        ></Column>
      </DataTable>
    </div>
  );
};

export default LabCategories;
