
import React, { useState } from "react";
import { NavLink ,Link} from "react-router-dom";
import "./Sidebar.css";

const TreeSidebar = ({ data ,child}) => {
  return (
    <div className="d-tree">
      <ul className="d-tree-container flex-column">
        {data.map((tree) => (
          <TreeNode node={tree} child ={child} />
        ))}
      </ul>
    </div>
  );
};



const TreeNode = ({ node,child}) => {
  const [childVisible, setChildVisiblity] = useState(false);

  const hasChild = (node.subCategoryList && node.subCategoryList.length>0)  ? true : false;

  return (
    <li className=" border-0">
      
       
        
       
    { hasChild?<NavLink to ={`/home/labs/${node.category_id}`}   className="sidebar-tree-has-child-item " onClick={(e) => setChildVisiblity((v) => !v)}>
      {hasChild && (
          <div className="toggle-icon">
            <i className={ childVisible ?"pi pi-angle-down":"pi pi-angle-right"} style={{'fontSize': '1.5em' ,width:"fit-content",cursor:"pointer"}}></i>
          </div>
        )}
        <div className="node-child">
        {/* <NavLink to ={`/home/labs/${node.id}`} className="col d-tree-head "> */}
        
          {node.category_name}
        {/* </NavLink> */}
        </div >
       
      </NavLink>:<NavLink to ={`/home/labs/${node.category_id}`}   className={child?"sidebar-child":"sidebar-tree-item"} onClick={(e) => setChildVisiblity((v) => !v)}>
      {hasChild && (
          <div className="toggle-icon">
            <i className={ childVisible ?"pi pi-angle-down":"pi pi-angle-right"} style={{'fontSize': '1.5em' ,width:"fit-content",cursor:"pointer"}}></i>
          </div>
        )}
        <div className="node-child">
        {/* <NavLink to ={`/home/labs/${node.id}`} className="col d-tree-head "> */}
        
          {node.category_name}
        {/* </NavLink> */}
        </div >
       
      </NavLink>
     }
     

      {hasChild && childVisible && (
        <div className="d-tree-content ">
          <ul className="d-flex d-tree-container   flex-column">
            <TreeSidebar data={node.subCategoryList} child={true} />
          </ul>
        </div>
      )}
       
    </li>
  );
};

export default TreeSidebar;