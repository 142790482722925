import http from "../http-comman";

class LabServices {
    // getAvailableLabs(categoryId) {
    //     return http.get("/lab/list?enabled=true&category=" + categoryId);
    // }

    // getComingSoonLabs(categoryId) {
    //     return http.get("/lab/list?enabled=false&category=" + categoryId);
    // }

    getReservedLabs() {
        return http.get("/lab/schedule/list/" + localStorage.getItem("users_id"));
    }

    // Lab Management - Category

    getLabCategoryList() {
        return http.get("/lab/category/list");
    }

    getLabCategory(categoryId) {
        return http.get(`/lab/category/${categoryId}`);
    }

    getParenCategoryList(){
        return http.get(`/lab/category/parent/list`);

    }

    addCategory(requestPayload) {
        return http.post("/lab/category", requestPayload);
    }

    updateCategory(categoryId, requestPayload) {
        return http.put(`/lab/category/${categoryId}`, requestPayload);
    }

    getLabsListAssociatedWithCategory(categoryId) {
        return http.get(`/lab/lablist/${categoryId}`);
    }

    deleteLabCategory(categoryId) {
        return http.delete(`lab/category/${categoryId}`);
    }

    // ----- END Lab Management - Category  -----------

    getHistoryReservedLabs() {
        return http.get("/lab/schedule/history/list/" + localStorage.getItem("users_id"));
    }

    getStatusList() {
        return http.get("/lab/status");
    }

    getlabNotAvailableData(labId, timezoneCode) {
        return http.get("/lab/labNotAvailable?lab_id=" + labId + "&user_time_zone=" + timezoneCode);
    }

    getPurposeList() {
        return http.get("/lab/purpose/list");
    }

    scheduleLab(requestPayload) {
        return http.post("/lab/schedule", requestPayload);
    }

    async sendClickUpAPI(deal, sfid, task_title) {
        return http.get(`/lab/clickup?deal=${deal}&sfid=${sfid}&task_title=${task_title}`);
    }

    // Check Extend Lab Possibility
    checkExtendLab(lab_schedule_id, new_end_date, prev_end_date) {
        let param = lab_schedule_id + "?new_end_date=" + new_end_date + "&prev_end_date=" + prev_end_date
        return http.get(`/lab/schedule/checkextendlab/${param}`)
    }

    // Extend Lab Access
    extendLab(requestPayload) {
        return http.post("/lab/extendlab", requestPayload);
    }

    cancelSchedule(schedule_id) {
        return http.put(`/lab/cancelschedule?schedule_id=${schedule_id}&users_id=${localStorage.getItem("users_id")}`);
    }

    deleteSchedule(schedule_id) {
        return http.delete(`/lab/deleteschedule?schedule_id=${schedule_id}&users_id=${localStorage.getItem("users_id")}`);
    }

    getHelpContent() {
        return http.get("lab/help/content");
    }

    getCategoryList() {

        return http.get("lab/category/labs/list");
    }

    connectLab(scheduleId) {
        return http.put(`lab/connect/hit/count?schedule_id=${scheduleId}&user_id=${localStorage.getItem("users_id")}`)
    }

    getLabList() {
        return http.get("lab/list/details");
    }

    getLab(labId) {
        return http.get(`lab/detail/${labId}`);
    }

    getLabListForAsset(assetId) {
        return http.get(`asset/disable/schedule/list/${assetId}`);
    }

    deletePhysicalAsset(assetId, date) {
        return http.delete(`asset/inventory/${assetId}?asset_delete_date=${date}&users_id=${localStorage.getItem("users_id")}`);
    }

    //User Reports
    userReports(days) {
        return http.get(`lab/user/reports?user_id=${localStorage.getItem("users_id")}&days=${days}`)
    }

    getTrendingLabs(days) {
        return http.get(`lab/chart/trending${days}`)
    }


    //admin Report
    adminReports(days) {
        return http.get(`lab/admin/reports?days=${days}`)
    }


    getPurposeUsage(param) {
        return http.get(`lab/purpose/usage/admin/reports${param}`)
    }

    getLabUsage(param) {
        return http.get(`lab/usage/admin/reports${param}`)
    }

    getEquipmentUsage(param) {
        return http.get(`lab/chart/equipment-usage${param}`)
    }


    getPortalUsage(param) {
        return http.get(`lab/portal/usage/report${param}`)
    }

    getVisited(param) {
        return http.get(`lab/portal/visited/report${param}`)
    }

    getCMDBLabTypeTags() {
        return http.get(`lab/cmdb/type`)
    }

    addOSType(requestPayload) {
        return http.post("/lab/cmdb/type", requestPayload);
    }

    updateOSType(cmdb_lab_type_id, requestPayload) {
        return http.put(`/lab/cmdb/type/${cmdb_lab_type_id}`, requestPayload);
    }

    deleteOSType(id) {
        return http.delete(`/lab/cmdb/type/${id}`);
    }

    getCMDBTags() {
        return http.get(`lab/cmdb/tag`)
    }

    getLabsForOSType(id) {
        return http.get(`lab/cmdb/type/lab/list/${id}`)
    }

    getPermissionTagList() {
        return http.get(`tag/list`)
    }

    getLabType() {
        return http.get(`lab/type`)
    }

    addLabType(requestPayload) {
        return http.post("/lab/type", requestPayload);
    }

    deleteLabType(id) {
        return http.delete(`/lab/type/${id}`);
    }

    getLabsForLabType(id) {
        return http.get(`/lab/type/lablist/${id}`);
    }

    validatePhysicalServer(requestPayload) {
        return http.post("/lab/validate/physical/server", requestPayload);
    }

    updateLabType(labTypeId, requestPayload) {
        return http.put(`/lab/type/${labTypeId}`, requestPayload);
    }

    getLabTypeDetails(labTypeId) {
        return http.get(`lab/type/${labTypeId}`)
    }

    createLab(requestPayload) {
        return http.post("/lab/create", requestPayload);
    }

    UpdateLab(labId, requestPayload) {
        return http.put(`/lab/update/${labId}`, requestPayload);
    }

    getAllSchedules(labId) {
        return http.get(`lab/disable/schedule/list/${labId}`)
    }

    createTag(requestPayload) {
        return http.post("/tag", requestPayload);
    }

    deleteLab(labId, date) {
        return http.delete(`/lab/${labId}?lab_delete_date=${date}&users_id=${localStorage.getItem("users_id")}`);
    }

    // User groups

    getUserGroupList() {
        return http.get("/group/list");
    }

    getUserGroup(groupId) {
        return http.get(`group/${groupId}`)
    }

    getUserList() {
        return http.get("/user/list");
    }

    createUserGroup(requestPayload) {
        return http.post("/map/group/tag/user", requestPayload);
    }

    UpdateUserGroup(groupId, requestPayload) {
        return http.put(`/map/group/tag/user/${groupId}`, requestPayload);
    }

    deleteUserGroup(groupId) {
        return http.delete(`/group/${groupId}`);
    }

    // User permission

    getUserPermissions() {
        return http.get(`/permission/user/list`);
    }

    createUser(requestPayload) {
        return http.post("/permission/user/register", requestPayload);
    }

    getUserGroups() {
        return http.get("/activegroup/list");
    }

    getUserById(userId) {
        return http.get(`/permission/user/${userId}`)
    }

    updateUserById(userId, requestPayload) {
        return http.put(`/permission/user/${userId}`, requestPayload);
    }

    getDetailedEquipmentUsage(param) {
        return http.get(`lab/chart/equipment-usage-granular${param}`)
    }

    getLoginAttempts(param) {
        return http.get(`user/login/attempt/status/count${param}`)
    }

    getFuturescheduleList(userId) {
        return http.get(`/lab/futureschedule/list/${userId}`);
    }

    deleteUser(userId, deletedBy) {
        return http.delete(`/permission/user?users_id=${userId}&deletedby=${deletedBy}`)
    }

    // User Tags

    getUserTagList() {
        return http.get("/tag/listwithdesc");
    }

    createUserTag(requestBody) {
        return http.post("/tag", requestBody);
    }

    updateTag(requestBody) {
        return http.put("/tag/update", requestBody);
    }

    deleteUserTag(tagName) {
        return http.delete(`/tag/${tagName}`);
    }

    //CMDB Tags

    getCMDBTagList() {
        return http.get("/lab/cmdb/tag");
    }

    createCMDBTag(requestBody) {
        return http.post("/lab/cmdb/tag", requestBody);
    }

    updateCMDBTag(tagName, requestBody) {
        return http.put(`lab/cmdb/tag/${tagName}`, requestBody);
    }

    getLabListForCmdbTag(tagName) {
        return http.get(`lab/cmdb/tag/lablist/${tagName}`);
    }

    getAssetListForCmdbTag(tagName) {
        return http.get(`lab/cmdb/tag/assetlist/${tagName}`);
    }

    deleteCmdbTag(tagName) {
        return http.delete(`lab/cmdb/tag/${tagName}`);
    }

    //Shared lab

    getSharedUsersList(schedule_id) {
        return http.get(`lab/shared/user/list/?schedule_id=${schedule_id}&users_id=${localStorage.getItem("users_id")}`);
    }

    deleteSharedUser(id, schedule_id) {
        return http.delete(`lab/shared/user?id=${id}&schedule_id=${schedule_id}&users_id=${localStorage.getItem("users_id")}`);
    }

    shareLab(requestPayload) {
        return http.post("lab/share", requestPayload);
    }

    getShareLabDetails(schedule_id) {
        return http.get(`lab/shared/details?schedule_id=${schedule_id}&users_id=${localStorage.getItem("users_id")}`);
    }

    // Physical Assets

    getPhysicalAssets(days) {
        return http.get(`asset/inventory/list?days=${days}`);
    }

    getAssetById(assetId) {
        return http.get(`asset/inventory/${assetId}`);
    }

    createPhysicalAsset(requestPayload) {
        return http.post('asset/inventory', requestPayload);
    }

    updatePhysicalAsset(asset_id, requestPayload) {
        return http.put(`asset/inventory/${asset_id}`, requestPayload);
    }

    getManufacturerModelsList() {
        return http.get("asset/server/model/list");
    }

    getManufacturers() {
        return http.get("asset/inventory/manufacture/list");
    }

    getModels() {
        return http.get("asset/inventory/model/list");
    }

    getGradeList() {
        return http.get("asset/classifier");
    }

    deleteAsset(assetId, date) {
        return http.delete(`asset/inventory/${assetId}?asset_delete_date=${date}&users_id=${localStorage.getItem("users_id")}`);
    }

    createCmdbTag(requestPayload) {
        return http.post('lab/cmdb/tag', requestPayload);
    }

    // Server-Model

    getServerModelsList() {
        return http.get("asset/server/model/lists");
    }

    getServerModelById(id) {
        return http.get(`asset/server/model/lists/${id}`);
    }

    updateServerModel(id, requestPayload) {
        return http.put(`asset/server/model/${id}`, requestPayload);
    }

    createServerModel(requestPayload) {
        return http.post('asset/server/model', requestPayload);
    }

    deleteServerModel(serverId) {
        return http.delete(`asset/server/model/${serverId}`);
    }

    getAssetListForServer(serverId) {
        return http.get(`asset/server/asset/list/${serverId}`)
    }

    // Virtual env

    // getVirtualEnvCount() {
    //     return http.get("asset/common/config?config_name=virtual_resources");
    // }

    // updateVirtualEnvCount(count) {
    //     return http.put(`asset/common/config/update?config_name=virtual_resources&config_value=${count}`);
    // }

    getRbacLabList(param) {
        return http.get(`lab/lists${param}`);
    }

    // feedback and issues

    getFeedbackIssueList(param) {
        return http.get(`contact/${param}`);
    }

    contactUsDetails(requestPayload) {
        return http.post('contact/us/' + localStorage.getItem("users_id"), requestPayload);
    }

    getContactUsUrls(param) {
        return http.get(`contact/details/${param}`)
    }

    getLabTypeProcessors() {
        return http.get("/lab/type/workflow/processor");
    }

    getSchedulesLabType(labTypeId) {
        return http.get(`/lab/workflow/${labTypeId}`);
    }

    // Portal User Notifications
    getUnreadNotificationCount() {
        return http.get(`/portal/notificaton/count/${localStorage.getItem("users_id")}`);
    }

    getNotificationList() {
        return http.get(`/portal/notificaton/list/${localStorage.getItem("users_id")}`);
    }

    changeReadStatus(portal_notification_id) {
        return http.put(`/portal/notificaton/${portal_notification_id}`);
    }

    deleteNotifications() {
        return http.delete(`/portal/notificaton/${localStorage.getItem("users_id")}`);
    }

    getStaticInstanceList() {
        return http.get("/lab/static/instance/list");
    }

    //Aboutus
    getAboutUsList() {
        return http.get("/contact/about/us");
    }

    updateAboutUs(params, reqBody) {
        return http.put(`/contact/about/us${params}`, reqBody);
    }

    // Admin messages

    createAdminMessage(requestPayload) {
        return http.post(`/message/sendmessage`, requestPayload);
    }

    getAdminMessageHistory() {
        return http.get(`/message/historymessage`);
    }

    getBroadcastMessages() {
        return http.get(`/message/usersmessages/${localStorage.getItem("users_id")}`);
    }

    // Virtual Environmet

    getVirtualEnvironmentDetails(days) {
        return http.get(`/lab/virtual/instances?days=${days}`);
    }

    createVirtualEnvironmentDetails(requestPayload) {
        return http.post(`/lab/virtual/instances`, requestPayload);
    }

    getVirtualEnvironmentDetailsById(params) {
        return http.get(`/lab/virtual/instances/${params}`)
    }

    updateVirtualEnvironmentDetailsById(params, requestPayload) {
        return http.put(`/lab/virtual/instances/${params}`, requestPayload)
    }

    //Request Lab Access
    checkScheduleLabServer(reqBody) {
        return http.post("/lab/schedule/physical/server/avaibility/check", reqBody);
    }

    sendLabAccessRequest(requestBody) {
        return http.post("/lab/request/access", requestBody);
    }

    //common config

    getConfigList() {
        return http.get(`/common/config/list`);
    }

    updateConfig(reqBody) {
        return http.put(`/common/config/update`, reqBody);
    }

    getConfig(configName) {
        return http.get(`/common/config/${configName}`);
    }

    getLabScheduleList(labId, days, flag) {
        let param = labId + "?days=" + days + "&flag=" + flag
        return http.get(`/lab/scheduledlab/list/${param}`)
    }

    getAllLabScheduleList(days, flag) {
        let param = "?days=" + days + "&flag=" + flag
        return http.get(`/lab/scheduledlab/list/${param}`)
    }

    getLabAssetDetails(lab_schedule_id) {
        return http.get(`/lab/asset/details/${lab_schedule_id}`)
    }

    //Asset Details
    getAssetDetails(assetId, days, flag) {
        return http.get(`/asset/inventory/scheduledlabhistory/${assetId}?days=${days}&flag=${flag}`);
    }

    //Virtual Asset Details
    getVirEnvDetails(virEnvId, days, flag) {
        return http.get(`/lab/virtual/instances/scheduledlabhistory/${virEnvId}?days=${days}&flag=${flag}`)
    }

    // User Login Details
    getUserLoginDetails(days) {
        return http.get(`user/login/details?days=${days}`)
    }

    //close Account
    getCloseAccountReasons() {
        return http.get(`/user/close/account/reasons`);
    }

    closeAccount(reqBody) {
        return http.post(`/user/close/account`,reqBody);
    }

    //Asset Schedule
    getAssetSchedule(days, flag) {
        return http.get(`/asset/inventory/assets/scheduledetails?days=${days}&flag=${flag}`)
    }
    

    shareLabEditPermission(id,scheduleId,email,permission){
        return http.put(`/lab/shared?id=${id}&users_id=${localStorage.getItem("users_id")}&schedule_id=${scheduleId}&email=${email}&permission=${permission}`);
       
    }

    //Active Labs list
    getActiveLabsList() {
        return http.get(`/lab/activelabs/list`);
    }

    //Portal request access
    getPortalAccessRequests(days) {
        return http.get(`/user/access/requests/${days}`);
    }

    //Lab request access
    getLabAccessRequests(days) {
        return http.get(`/lab/access/requests/${days}`);
    }

    //Deny Lab access request
    denyLabRequest(id) {
        return http.put(`/lab/access/request/deny/${id}`);
    }

    //Approve Lab access request
    approveLabRequest(id) {
        return http.put(`/lab/access/request/approve/${id}`);
    }

    //Deny Portal access request
    denyPortalRequest(id) {
        return http.put(`/user/access/request/deny/${id}`);
    }

    //Approve Portal access request
    approvePortalRequest(id) {
        return http.put(`/permission/access/request/approve/${id}`);
    }

    //Lab request access count
    getLabAccessRequestsCount() {
        return http.get(`/lab/access/request/count`);
    }

    //Portal request access count
    getPortalAccessRequestsCount() {
        return http.get(`/user/access/request/count`);
    }

    //Delete Admin messages
    deleteAdminMessage(message_id) {
        return http.delete(`/message/${message_id}`);
    }

    //Utilization Reports
    //Daily
    getAssetUtilizationDaily(param) {
        return http.get(`lab/utilization/daily${param}`)
    }

    //Daily Percentage
    getAssetUtilizationDailyPercentage(param) {
        return http.get(`lab/utilization/daily/percentage${param}`)
    }

    //Weekly
    getAssetUtilizationWeekly(param) {
        return http.get(`lab/utilization/weekly${param}`)
    }

    //Weekly Percentage
    getAssetUtilizationWeeklyPercentage(param) {
        return http.get(`lab/utilization/weekly/percentage${param}`)
    }

    getConcurrentInstanceList() {
        return http.get("/lab/concurrent/instances/list");
    }

}

export default new LabServices();