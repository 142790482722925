import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Divider } from "primereact/divider";
import { Checkbox } from "primereact/checkbox";
import { MultiSelect } from "primereact/multiselect";
import LabServices from "../../../../services/LabServices";
import loglevel from "../../../../logger/Logger";
import { Toast } from "primereact/toast";
import LoadingSpinner from "../../../loader/LoadingSpinner";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import eventBus from "../../../utility/EventBus";
import { InputTextarea } from "primereact/inputtextarea";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import funnelImage from "../../../../images/funnel_image.svg";

const UpdateUser = ({ rowId,userName, setAlluserDetails }) => {
  const toast = useRef(null);
  const errorRef = useRef();
  const [loading, setLoading] = useState(false);
  const [updateUserDisplay, setupdateLabDisplay] = useState(false);
  const [selectedTagValue, setselectedTagValue] = useState([]);
  const [tag1, setTag1] = useState(false);
  const [tag2, setTag2] = useState(false);
  const [tag3, setTag3] = useState(false);
  const [tagsArray, setTagsArray] = useState([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [enableUser, setEnableUser] = useState(true);
  const [userGroups, setUserGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [inactiveReason, setInactiveReason] = useState("");
  const [inactivePopup, setInactivePopup] = useState(false);
  const [futurescheduleList, setFuturescheduleList] = useState([]);
  // validations
  const [fnameError, setFnameError] = useState(false);
  const [fnameErrorMsg, setFnameErrorMsg] = useState("");
  const [lnameError, setLnameError] = useState(false);
  const [lnameErrorMsg, setLnameErrorMsg] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [tagsError, setTagsError] = useState(false);
  const [tagsErrorMsg, setTagsErrorMsg] = useState("");
  const [inactiveReasonError, setInactiveReasonError] = useState(false);
  const [inactiveReasonErrorMsg, setInactiveReasonErrorMsg] = useState("");
  const [commonErrorMsg, setCommonErrorMsg] = useState("");

  const inactiveFuncMap = {
    inactivePopup: setInactivePopup,
  };

  const onShowInactivePopup = (name) => {
    inactiveFuncMap[`${name}`](true);
  };

  let grpArr = [];
  selectedGroup.forEach((item) => {
    grpArr.push(item.group_id);
  });

  const updateUserDialogMap = {
    updateUserDisplay: setupdateLabDisplay,
  };

  useEffect(() => {
    // api call for user groups
    LabServices.getUserGroups().then((response) => {
      setUserGroups(response.data);
    });
  }, []);

 // on user update
  const onUserUpdate = async (name) => {
    setLoading(true);
    //api call for fetchig details for particular user
    await LabServices.getUserById(rowId)
      .then((response) => {
        setFirstName(response.data.firstName);
        setLastName(response.data.lastName);
        setEmail(response.data.email);
        setEnableUser(response.data.enabled);
        setInactiveReason(response.data.reason_for_inactive);
        let groups = [];
        response.data.groups.forEach((res) => {
          userGroups.forEach((item) => {
            if (item.group_id === res) {
              groups.push(item);
            }
          });
        });
        setSelectedGroup(groups);
        let array = [];
        for (var i = 0; i < response.data.tags.length; i++) {
          
          if (response.data.tags[i] === "read-only") {//if read only tag checked
            setTag1(true);
          } else if (response.data.tags[i] === "portal-user") { //if  portal-user tag checked
            setTag2(true);
          } else if (response.data.tags[i] === "cec-dev-test") { // if cec-dev-test tag is checked
            setTag3(true);
          } else {
            array.push({ tag: response.data.tags[i] });
          }
        }
        setselectedTagValue(array);
      })
      .catch((error) => {
        loglevel.error("getUserDetailsById", error);
      })
      .finally(() => {
        setLoading(false);
      });
    await LabServices.getPermissionTagList()
      .then((response) => {
        let tagsArrayData = [];
        // loop for storing other selected tag other than predefined
        for (var i = 0; i < response.data.length; i++) {
          if (
            response.data[i] === "read-only" ||
            response.data[i] === "cec-dev-test" ||
            response.data[i] === "portal-user"
          ) {
            continue;
          } else {
            tagsArrayData.push({ tag: response.data[i] });
          }
        }
        setTagsArray(tagsArrayData);
      })
      .catch((error) => {
        loglevel.error(error);
      });
    updateUserDialogMap[`${name}`](true);
  };

  let tags = [];
  //if read-only tag selected
  if (tag1 === true) {
    tags.push("read-only");
  }
  //if portal-user tag selected
  if (tag2 === true) {
    tags.push("portal-user");
  }
 //if cec-dev-test tag selected
  if (tag3 === true) {
    tags.push("cec-dev-test");
  }
  let selectedTagArray = [...selectedTagValue];
  let changedTagArray = [];
  selectedTagValue.forEach((element) => {
    changedTagArray.push(element.tag);
  });

  let tagArray = [...new Set([...tags, ...changedTagArray])];

  // closing dialog box
  const onHide = (name) => {
    updateUserDialogMap[`${name}`](false);
    setFnameError(false);
    setFnameErrorMsg("");
    setLnameError(false);
    setLnameErrorMsg("");
    setEmailError(false);
    setEmailErrorMsg("");
    setInactiveReason(false);
    setInactiveReasonErrorMsg("");
  };

  const onInactivePopupHide = (name) => {
    inactiveFuncMap[`${name}`](false);
  };

  // on selecting/delecting tags
  const onTagChange = (e) => {
    setselectedTagValue(e.target.value);
  };

  //on changing groups 
  const onGroupChange = (e) => {
    setSelectedGroup(e.target.value);
  };

  const onEnableUser = (e) => {
    setEnableUser(e.target.value);
    if (e.target.value === true) {
      setInactiveReason("");
      inactiveFuncMap[`inactivePopup`](false);
    } else {
      setLoading(true);
      //getting future schedule list
      LabServices.getFuturescheduleList(rowId)
        .then((response) => {
          setFuturescheduleList(response.data);
          console.log("getFuturescheduleList", response.data);
          inactiveFuncMap[`inactivePopup`](true);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onInactiveReasonChange = (e) => {
    setInactiveReason(e.target.value);
    if (e.target.value === "") { //if reason is not provide for inactivating user
      setInactiveReasonError(true);
      setInactiveReasonErrorMsg("Reason for Inactive is mandatory");
    } else {
      setInactiveReasonError(false);
      setInactiveReasonErrorMsg("");
    }
  };

  const renderFooter = (name) => {
    return (
      <div className="terms-section">
        {/* Update user button */}
        <Button
          className="create-user-button"
          label="UPDATE USER"
          style={{ background: "#49AFD9" }}
          onClick={confirmUpdate}
        />
      </div>
    );
  };

  const inactivePopupFooter = (name) => {
    return (
      <div>
        {/* Inactive user button */}
        <Button
          label="INACTIVE USER"
          className="p-button-outlined p-button-danger"
          onClick={() => onInactivePopupHide(name)}
        />
        {/* Cancel button */}
        <Button
          label="CANCEL"
          className="inactive-cancel-btn"
          onClick={() => onInactiveCancel(name)}
        />
      </div>
    );
  };

  // closing dialog for inactivating user
  const onInactiveCancel = (name) => {
    setEnableUser(true);
    inactiveFuncMap[`${name}`](false);
  };

  const editUser = () => {
    if (fnameError === false && lnameError === false) {
      const requestBody = {
        users_id: rowId,
        firstName: firstName,
        lastName: lastName,
        email: email,
        enabled: enableUser,
        reason_for_inactive: inactiveReason,
        tags: tagArray,
        groups: grpArr,
      };
      updateUser(requestBody);
    }
  };

  const updateUser = async (requestBody) => {
    setLoading(true);
    //regex for email validation
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (firstName === "" && lastName === "" && !regexEmail.test(email)) { // if first last name and email not enterd
      setFnameError(true);
      setFnameErrorMsg("First name is mandatory.");
      setLnameError(true);
      setLnameErrorMsg("Last name is mandatory.");
      setEmailError(true);
      setEmailErrorMsg("Email is not valid");
    } else if (firstName === "" && lastName === "") { //if first and last name not enterd
      setFnameError(true);
      setFnameErrorMsg("First name is mandatory.");
      setLnameError(true);
      setLnameErrorMsg("Last name is mandatory."); 
    } else if (firstName === "" && lastName !== "") { // if first name is not enterd
      setFnameError(true);
      setFnameErrorMsg("First name is mandatory.");
      setLnameError(false);
      setLnameErrorMsg("");
    } else if (firstName !== "" && lastName === "") { // if last name is not provided
      setFnameError(false);
      setFnameErrorMsg("");
      setLnameError(true);
      setLnameErrorMsg("Last name is mandatory.");
    }

    if (!regexEmail.test(email)) { //if email is not matched with regex
      setEmailError(true);
      setEmailErrorMsg("Enter a valid email address");
      return;
    } else { //if email is  matched with regex
      setEmailError(false);
      setEmailErrorMsg("");
    }

    if (inactiveReason === "" || inactiveReason === null) { // if reason is not given for inactivating user
      setInactiveReasonError(true);
      setInactiveReasonErrorMsg("Reason for Inactive is mandatory");
    } else {
      setInactiveReasonError(false);
      setInactiveReasonErrorMsg("");
    }

    // api call for updating user details
    await LabServices.updateUserById(rowId, requestBody)
      .then(() => {
        // toast message after successful updation
        toast.current?.show({
          severity: "success",
          summary: "User Updated Successfully",
          detail: "User has been updated successfully.",
          life: 5000,
        });

        // closing dialog box after updation
        updateUserDialogMap[`updateUserDisplay`](false);
        eventBus.dispatch("pageReloaded", { message: "pageReloaded" });
      })
      .catch((error) => {
        loglevel.error("updateUser", error);
        if (error.response?.status === 409) {
          setCommonErrorMsg(error.response.data.msg);
          errorRef.current.scrollIntoView({ behavior: "smooth" });
        }
      })
      .finally(() => {
        setLoading(false);
      });
    setLoading(true);

    // refreshing user list 
    await LabServices.getUserPermissions()
      .then((res) => {
        res.data.forEach((element) => {
          element["name"] = element.firstName + " " + element.lastName;
        });
        setAlluserDetails(res.data);
      })
      .catch((error) => {
        loglevel.error("updateUser", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const confirmUpdate = (event) => {
    confirmPopup({
      target: event.currentTarget,
      className: "popup",
      message: "Want to update the changes?",
      icon: "pi pi-exclamation-triangle",
      accept: () => editUser(),
    });
  };

  // on first name change
  const onFnameChange = (e) => {
    setFirstName(e.target.value);
    if (e.target.value === "") { //if first name is not provided
      setFnameError(true);
      setFnameErrorMsg("First name is mandatory.");
    } else if (e.target.value.length > 100) { //if first name character eceeds 100 characters
      setFnameError(true);
      setFnameErrorMsg(
        `Maximum 100 Characters (Current- ${e.target.value.length})`
      );
    } else { // if valid first name
      setFnameError(false);
      setFnameErrorMsg("");
    }
  };

  //on last nname change
  const onLnameChange = (e) => {
    setLastName(e.target.value);

    if (e.target.value === "") { // if last name is empty
      setLnameError(true);
      setLnameErrorMsg("Last name is mandatory.");
    } else if (e.target.value.length > 100) { //if last name provided eceeds 100 characters
      setLnameError(true);
      setLnameErrorMsg(
        `Maximum 100 Characters (Current- ${e.target.value.length})`
      );
    } else {// if valid  last name
      setLnameError(false);
      setLnameErrorMsg("");
    }
  };

  const onEmailChange = (e) => {
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    setEmail(e.target.value);
    if (!regexEmail.test(e.target.value)) {
      setEmailError(true);
      setEmailErrorMsg("Enter a valid email address");
      return;
    } else {
      setEmailError(false);
      setEmailErrorMsg("");
    }
  };

  //on selecting/deselecting read-only tag
  const onTag1Change = (e) => {
    setTag1(e.checked);
  };

   //on selecting/deselecting portal-user tag
  const onTag2Change = (e) => {
    setTag2(e.checked);
  };

  //on selecting/deselecting cec-dev-test tag
  const onTag3Change = (e) => {
    setTag3(e.checked);
  };

  return (
    <div>
      {/* <Toast ref={toast} position="top-center" /> */}
      <div className="update-user-actions p-d-flex">
        <Toast ref={toast} position="top-center" />
        {/* Update button on listing page*/}
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded update-user-button"
          onClick={() => onUserUpdate("updateUserDisplay")}
        />
      </div>
      <Dialog
        header={`Update user : ${userName}`}
        visible={updateUserDisplay}
        footer={renderFooter("updateUserDisplay")}
        onHide={() => onHide("updateUserDisplay")}
        className="lab-dialog-box"
        style={{ width: "700px" }}
      >
        <div className="dialog-content">
          <div ref={errorRef} className={commonErrorMsg ? "" : "hidden"}>
            {commonErrorMsg && (
              <Message
                severity="warn"
                text={commonErrorMsg}
                className="common-inline-error common-error-message p-mb-3"
              />
            )}
          </div>
          <div className="first-section">
            <div className="name-input-text">
              <div className="namefield-label">
                Name<span className="mandatory-label">*</span> :
              </div>
              <div>
                {/* first name */}
                <InputText
                  value={firstName}
                  maxLength={100}
                  onChange={onFnameChange}
                  placeholder="Enter First Name"
                  className={
                    fnameError
                      ? "input-error-border-color namefield-text-box"
                      : "namefield-text-box"
                  }
                  keyfilter={/^[a-zA-Z0-9-_.]*$/}
                />
                {fnameError && (
                  <small
                    className="input-error-message p-d-block"
                    id="error-message"
                  >
                    {fnameErrorMsg}
                  </small>
                )}
              </div>
              <div>
                {/* last name */}
                <InputText
                  value={lastName}
                  maxLength={100}
                  onChange={onLnameChange}
                  placeholder="Enter First Name"
                  className={
                    lnameError
                      ? "input-error-border-color namefield-text-box p-ml-2"
                      : "namefield-text-box p-ml-2"
                  }
                  keyfilter={/^[a-zA-Z0-9-_.]*$/}
                />
                {lnameError && (
                  <small
                    style={{ marginLeft: "10px" }}
                    className="input-error-message p-d-block"
                    id="error-message"
                  >
                    {lnameErrorMsg}
                  </small>
                )}
              </div>
            </div>
          </div>
          <div className="section second-section p-mt-2">
            <div className="name-input-text">
              <div className="namefield-label">
                Business Email<span className="mandatory-label">*</span> :
              </div>
              <div>
                {/* business email */}
                <InputText
                  value={email}
                  maxLength={255}
                  placeholder="Enter Bussiness Email"
                  className={
                    emailError
                      ? "input-error-border-color email-text-box"
                      : "email-text-box"
                  }
                />
                {emailError && (
                  <small
                    className="input-error-message p-d-block"
                    id="error-message"
                  >
                    {emailErrorMsg}
                  </small>
                )}
              </div>
            </div>
          </div>
          <div className="section third-section">
            <div className="name-input-text">
              <div className="namefield-label">Enable User:</div>
              {/* switch for enabling and disabling user */}
              <InputSwitch
                checked={enableUser}
                onChange={(e) => onEnableUser(e)}
              />
              <span className="user-status">
                User is {enableUser === true ? "Active" : "Inactive"}
              </span>
            </div>
          </div>
          {enableUser === false && (
            <div className="section fourth-section">
              <div className="name-input-text">
                <div className="namefield-label">
                  Reason for Inactive<span className="mandatory-label">*</span>{" "}
                  :
                </div>
                <div>
                  {/* Reason for inactivating user */}
                  <InputTextarea
                    className={
                      inactiveReasonErrorMsg
                        ? "input-error-border-color reason-text-box"
                        : "reason-text-box"
                    }
                    autoResize
                    onChange={onInactiveReasonChange}
                    value={inactiveReason}
                  />
                  {inactiveReasonError && (
                    <small
                      className="input-error-message p-d-block"
                      id="error-message"
                    >
                      {inactiveReasonErrorMsg}
                    </small>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="section fifth-section p-d-flex">
            <div className="section tags-label">
              Tags <span className="optional-label">(Optional)</span> :
            </div>
            <div className="tagscheck-and-tagsdropdown">
              <div className="lab-tags section">
                <div className="lab-tag">
                  {/* read only tab */}
                  <Checkbox onChange={onTag1Change} checked={tag1}></Checkbox>
                  <label htmlFor="cb1" className="checkbox-label">
                    read-only
                  </label>
                </div>
                <div className="lab-tag">
                  {/* portal-user tag */}
                  <Checkbox onChange={onTag2Change} checked={tag2}></Checkbox>
                  <label htmlFor="cb1" className="checkbox-label">
                    Portal-User
                  </label>
                </div>
                <div className="lab-tag">
                  {/* cec-dev-test tag */}
                  <Checkbox
                    className="lab-checkbox"
                    onChange={onTag3Change}
                    checked={tag3}
                  ></Checkbox>
                  <label htmlFor="cb1" className="checkbox-label">
                    cec-dev-test
                  </label>
                </div>
              </div>
              <div className="tag-dropdown section">
                <MultiSelect
                  showSelectAll={false}
                  value={selectedTagValue}
                  name="category-dropdown"
                  options={tagsArray}
                  optionLabel="tag"
                  onChange={onTagChange}
                  placeholder="Select Tags"
                  className="tags-dropdown select-permissions"
                  filter
                  display="chip"
                />
              </div>
            </div>
          </div>
          {/* <Divider /> */}
          <div className="section sixth-section">
            <div className="name-input-text input-groups-dropdown">
              <div className="namefield-label">
                User Groups <span className="optional-label">(Optional)</span> :
              </div>
              {/* User Group */}
              <MultiSelect
                showSelectAll={false}
                value={selectedGroup}
                name="category-dropdown"
                options={userGroups}
                optionLabel="name"
                onChange={onGroupChange}
                placeholder="Select Groups"
                className="groups-dropdown select-groups"
                filter
                display="chip"
              />
            </div>
          </div>
        </div>
      </Dialog>

      {/* Inactive user dialog */}

      <div className="inactive-popup-container">
        <Dialog
          header={`Inactive User - ${firstName} ${lastName}`}
          visible={inactivePopup}
          style={{ width: futurescheduleList.length ? "50vw" : "30vw" }}
          onHide={() => onInactivePopupHide("inactivePopup")}
          footer={inactivePopupFooter("inactivePopup")}
          className="inactive-user-dialog"
        >
          {futurescheduleList.length ? (
            <div className="future-shedulelist-container">
              <div className="future-shedulelist-title">
                <p className="future-shedulelist-title-text">
                  Upcoming Lab Schedules for User
                </p>
              </div>
              <div className="future-shedulelist-table">
                <DataTable
                  responsiveLayout="scroll"
                  value={futurescheduleList}
                  className="shedulelist-table"
                >
                  <Column
                    field="lab_name"
                    header="Lab Name"
                    style={{ minWidth: "10rem" ,paddingLeft:"15px" }}
                  ></Column>
                  <Column
                    field="scheduled_start"
                    header="Start Date"
                    style={{ minWidth: "9rem" }}
                  ></Column>
                  <Column
                    field="scheduled_end"
                    header="End Date"
                    style={{ minWidth: "9rem" }}
                  ></Column>
                  <Column
                    field="user_time_zone"
                    header="Timezone"
                    style={{ minWidth: "1rem" }}
                  ></Column>
                  <Column
                    field="client_name"
                    header="Client Name"
                    style={{ minWidth: "10rem" }}
                  ></Column>
                  <Column
                    field="purpose"
                    header="Purpose"
                    style={{ minWidth: "10rem" }}
                  ></Column>
                </DataTable>
              </div>
              <div className="future-sheduled-warn p-mt-2">
                <Message
                  severity="warn"
                  text={`If you inactive the User, User will not be able to access the portal.Also, future schedules of labs for the user will be cancelled but Active lab schedules will remain and run as they are.`}
                  className="common-inline-error common-inline-warn"
                />
              </div>
            </div>
          ) : (
            <div className="nofuture-sheduled-container">
              <div className="nofuture-scheduled-content p-d-flex p-flex-column p-ai-center">
                <div className="funnel-image p-mt-2">
                  <img src={funnelImage} alt="funnel-image" />
                </div>
                <div className="nofuture-scheduled-message">
                  <p className="nofuture-scheduled-text">
                    We couldn’t find any lab Schedule!
                  </p>
                </div>
              </div>
              <div className="future-sheduled-warn p-mt-2">
                <Message
                  severity="warn"
                  text={`If you inactive the User, User will not be able to access the portal.`}
                  className="common-inline-error common-inline-warn"
                />
              </div>
            </div>
          )}
        </Dialog>
      </div>
    </div>
  );
};

export default UpdateUser;
