import "./UserGroups.css";
import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import CreateUserGroup from "./CreateUserGroup";
import LabServices from "../../../../services/LabServices";
import EditUserGroup from "./EditUserGroup";
import DeleteUserGroup from "./DeleteUserGroup";

const UserGroups = () => {

    const [userGroups, setUserGroups] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null); 

    useEffect(() => {
        async function fetchData() {
          //api call for listing user grops
            await LabServices.getUserGroupList().then((res) => {
                setUserGroups(res.data)
            })
        }
        fetchData();
    }, []);


    const onIndexTemplate = (data, props) => {
        return props.rowIndex + 1;
    }

    // tag column on listing page
    const tagTemplate = (rowData) => {
        const val = rowData.tags.map((r, index) => {
            return <Tag key={r.toString()} className="p-mr-2 p-mb-2"><svg className="p-mr-1" width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.55336 5.48551L8.78688 5.25199C9.51244 4.5303 9.51576 3.35713 8.79429 2.63136L6.83345 0.681641C6.10972 -0.0418703 4.93656 -0.0418703 4.21283 0.681641L1.04361 3.85086C0.320099 4.57459 0.320099 5.74775 1.04361 6.47148L2.99333 8.4212C3.71706 9.14471 4.89022 9.14471 5.61395 8.4212L5.76222 8.27293L6.57028 9.07358H7.84538V10.1856H9.21315V10.7898L10.277 11.861H12.501V9.43313L8.55336 5.48551ZM11.7596 11.1198H10.5957L9.93958 10.4674V9.42957H8.57552V8.31756H6.86674L5.75473 7.20556L5.08753 7.89871C4.65338 8.33232 3.95005 8.33232 3.51589 7.89871L1.56618 5.93046C1.13256 5.4963 1.13256 4.79297 1.56618 4.35882L4.7391 1.1859C5.17325 0.752284 5.87658 0.752284 6.31073 1.1859L8.26045 3.13561C8.69407 3.56977 8.69407 4.2731 8.26045 4.70725L7.51911 5.44859L11.7596 9.74093V11.1198ZM2.7704 4.30611C2.58438 4.49212 2.58438 4.7937 2.7704 4.97972C2.95641 5.16573 3.25799 5.16573 3.444 4.97971L5.31541 3.1083C5.50142 2.92229 5.50142 2.62071 5.31541 2.4347C5.1294 2.24869 4.82782 2.24869 4.64181 2.4347L2.7704 4.30611Z" fill="black"/>
            </svg>{r}</Tag>;
        });

        return <p className="data-tag">{val}</p>;
    };

    //description column
    const descriptionTemplate = (rowData) => {
        return (<div className="short-desc">
            {rowData.description}
        </div>)
    }

    //showng Active or Inactive group status
    const EnabledTemplate = (rowData) => {
        if (rowData.enabled) {
          return (
            <div className="enable-disable-container">
              <div className="enable-icon">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.777344 7.00005C0.777344 10.4365 3.56313 13.2223 6.99957 13.2223C8.6498 13.2223 10.2324 12.5667 11.3993 11.3998C12.5662 10.2329 13.2218 8.65029 13.2218 7.00005C13.2218 3.56362 10.436 0.777832 6.99957 0.777832C3.56313 0.777832 0.777344 3.56362 0.777344 7.00005ZM1.55512 7.00005C1.55512 3.99317 3.99268 1.55561 6.99957 1.55561C10.0064 1.55561 12.444 3.99317 12.444 7.00005C12.444 10.0069 10.0064 12.4445 6.99957 12.4445C3.99268 12.4445 1.55512 10.0069 1.55512 7.00005Z"
                    fill="#61B715"
                  />
                  <path
                    d="M6.28622 8.61488L4.54789 6.87821C4.49126 6.81209 4.49507 6.71352 4.55663 6.65195C4.61819 6.59039 4.71676 6.58659 4.78289 6.64321L6.28289 8.14321L9.21455 5.21488C9.28068 5.15825 9.37925 5.16206 9.44081 5.22362C9.50238 5.28518 9.50618 5.38375 9.44955 5.44988L6.28622 8.61488Z"
                    fill="#61B715"
                    stroke="#61B715"
                    strokeWidth="0.4"
                  />
                </svg>
              </div>
    
              <div className="datatable-enable-label">Active</div>
            </div>
          );
        } else {
          return (
            <div className="enable-disable-container">
              <div className="disable-icon">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.99957 13.2223C3.56313 13.2223 0.777344 10.4365 0.777344 7.00005C0.777344 3.56362 3.56313 0.777832 6.99957 0.777832C10.436 0.777832 13.2218 3.56362 13.2218 7.00005C13.2218 8.65029 12.5662 10.2329 11.3993 11.3998C10.2324 12.5667 8.6498 13.2223 6.99957 13.2223ZM6.99957 1.55561C3.99268 1.55561 1.55512 3.99317 1.55512 7.00005C1.55512 10.0069 3.99268 12.4445 6.99957 12.4445C10.0064 12.4445 12.444 10.0069 12.444 7.00005C12.444 3.99317 10.0064 1.55561 6.99957 1.55561ZM9.51568 5.11005L7.62568 7.00005L9.46123 8.82394C9.59337 8.97824 9.58448 9.20824 9.44084 9.35188C9.2972 9.49553 9.06719 9.50441 8.9129 9.37228L7.06957 7.52894L5.21457 9.38394C5.11953 9.49492 4.9703 9.54326 4.82824 9.50909C4.68618 9.47492 4.57526 9.364 4.54108 9.22194C4.50691 9.07988 4.55525 8.93065 4.66623 8.83561L6.52512 7.00005L4.62734 5.07894C4.49521 4.92465 4.50409 4.69465 4.64774 4.551C4.79138 4.40736 5.02138 4.39847 5.17568 4.53061L7.07734 6.43228L8.96734 4.56172C9.12164 4.42959 9.35164 4.43847 9.49528 4.58211C9.63893 4.72576 9.64781 4.95576 9.51568 5.11005Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="datatable-disable-label">Inactive</div>
            </div>
          );
        }
      };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="table-action-body" >
               <div className="table-update-icon">
                {/* Update user group component */}
                <EditUserGroup groupId={rowData.group_id} groupNameProp={rowData.name} setChanger={setUserGroups} />
                </div>
                <div className="table-delete-icon">
                  {/* delete user group component */}
                <DeleteUserGroup groupId={rowData.group_id} groupName={rowData.name} setChanger={setUserGroups} />
                </div>
            </div>

        );
    };

    return (
        <div className="datatable-crud-demo">
            <div className="admin-panel">
            <div className="admin-panel-search-bar">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            {/* Search bar */}
            <InputText
              type="search"
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search"
              className="global-search-filter"
            />
          </span>
        </div>
            {/* Create User Group component */}
            <CreateUserGroup setChanger={setUserGroups} />
            </div>
            <Toast ref={toast} position="top-center" />
            {/* usergroup  listing tablel */}
            <DataTable
                value={userGroups}
                ref={dt}
                className="lab-table-type"
                columnResizeMode="fit"
                paginator
                rows={50}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                globalFilter={globalFilter}
                responsiveLayout="scroll"
                autoLayout={true}
                let-i="rowIndex"
                style={{ width: "100%" }}
                removableSort={true}
            >
                <Column
                    field="Index"
                    header="#"
                    body={onIndexTemplate}
                    style={{ width: "3rem", paddingLeft: "10px" }}
                ></Column>
                <Column
                    sortable
                    field="name"
                    header="Group Name"
                    style={{ minWidth: "10rem", fontWeight: "600"  }}>
                </Column>
                <Column
                    sortable
                    field="description"
                    header="Description"
                    body={descriptionTemplate}
                    style={{ minWidth: "10rem" }}>
                </Column>
                <Column
                    field="total_users"
                    header="No. of members"
                    sortable
                    style={{ minWidth: "3rem", textAlign: "center" }}
                ></Column>
                <Column
                    sortable
                    field="tags"
                    header="Tags"
                    body={tagTemplate}
                    style={{ minWidth: "10rem", maxWidth: "18rem" }}
                ></Column>
                <Column
                    sortable
                    field="enabled"
                    header="Status"
                    body={EnabledTemplate}
                    style={{ minWidth: "6rem" }}
                ></Column>
                <Column
                    body={actionBodyTemplate}
                    exportable={false}
                    style={{ minWidth: "4rem" }}
                ></Column>
            </DataTable>
        </div>
    );
};

export default UserGroups;
