import React from 'react'
import { ProgressSpinner } from 'primereact/progressspinner';

const LoadingSpinner = () => {
    return (
        <div>
            {/* Loading spinner icon if data is loading */}
            <ProgressSpinner/>
        </div>
    )
}

export default LoadingSpinner
