const timezones = [
    { Abbr: "LINT", name: "(GMT+14:00) Line Islands Time", code: "GMT+14:00", AbbrName: "Line Islands Time" },
    { Abbr: "CHADT", name: "(GMT+13:45) Chatham Daylight Time", code: "GMT+13:45", AbbrName: "Chatham Daylight Time" },
    { Abbr: "TOT", name: "(GMT+13:00) Tonga Standard Time", code: "GMT+13:00", AbbrName: "Tonga Standard Time" },
    { Abbr: "GMT+13", name: "(GMT+13:00) Apia Standard Time", code: "GMT+13:00", AbbrName: "Apia Standard Time" },
    { Abbr: "GMT+13", name: "(GMT+13:00) GMT+13:00", code: "GMT+13:00", AbbrName: "GMT+13:00" },
    { Abbr: "PHOT", name: "(GMT+13:00) Phoenix Island Time", code: "GMT+13:00", AbbrName: "Phoenix Island Time" },
    { Abbr: "NZDT", name: "(GMT+13:00) New Zealand Daylight Time", code: "GMT+13:00", AbbrName: "New Zealand Daylight Time" },
    { Abbr: "CHAST", name: "(GMT+12:45) Chatham Standard Time", code: "GMT+12:45", AbbrName: "Chatham Standard Time" },
    { Abbr: "PETT", name: "(GMT+12:00) Kamchatka Time", code: "GMT+12:00", AbbrName: "Kamchatka Time" },
    { Abbr: "GILT", name: "(GMT+12:00) Gilbert Island Time", code: "GMT+12:00", AbbrName: "Gilbert Island Time" },
    { Abbr: "FJT", name: "(GMT+12:00) Fiji Standard Time", code: "GMT+12:00", AbbrName: "Fiji Standard Time" },
    { Abbr: "NZST", name: "(GMT+12:00) New Zealand Standard Time", code: "GMT+12:00", AbbrName: "New Zealand Standard Time" },
    { Abbr: "GMT+12", name: "(GMT+12:00) GMT+12:00", code: "GMT+12:00", AbbrName: "GMT+12:00" },
    { Abbr: "PETST", name: "(GMT+12:00) Petropavlovsk-Kamchatski Standard Time", code: "GMT+12:00", AbbrName: "Petropavlovsk-Kamchatski Standard Time" },
    { Abbr: "NFT", name: "(GMT+11:00) Norfolk Island Standard Time", code: "GMT+11:00", AbbrName: "Norfolk Island Standard Time" },
    { Abbr: "LMT", name: "(GMT+11:00) Solomon Islands Time", code: "GMT+11:00", AbbrName: "Solomon Islands Time" },
    { Abbr: "SAKT", name: "(GMT+11:00) Sakhalin Standard Time", code: "GMT+11:00", AbbrName: "Sakhalin Standard Time" },
    { Abbr: "BST", name: "(GMT+11:00) Bougainville Island", code: "GMT+11:00", AbbrName: "GMT+11:00" },
    { Abbr: "MAGT", name: "(GMT+11:00) Magadan Standard Time", code: "GMT+11:00", AbbrName: "Magadan Standard Time" },
    { Abbr: "AEDT", name: "(GMT+11:00) Australian Eastern Daylight Time", code: "GMT+11:00", AbbrName: "Australian Eastern Daylight Time" },
    { Abbr: "ACDT", name: "(GMT+10:30) Australian Central Daylight Time", code: "GMT+10:30", AbbrName: "Australian Central Daylight Time" },
    { Abbr: "LHST", name: "(GMT+10:30) Lord Howe Standard Time", code: "GMT+10:30", AbbrName: "Lord Howe Standard Time" },
    { Abbr: "CHST", name: "(GMT+10:00) Chamorro Standard Time", code: "GMT+10:00", AbbrName: "Chamorro Standard Time" },
    { Abbr: "PGT", name: "(GMT+10:00) Papua New Guinea Time", code: "GMT+10:00", AbbrName: "Papua New Guinea Time" },
    { Abbr: "AEST", name: "(GMT+10:00) Australian Eastern Standard Time", code: "GMT+10:00", AbbrName: "Australian Eastern Standard Time" },
    { Abbr: "VLAT", name: "(GMT+10:00) Vladivostok Standard Time", code: "GMT+10:00", AbbrName: "Vladivostok Standard Time" },
    { Abbr: "ACST", name: "(GMT+09:30) Australian Central Standard Time", code: "GMT+09:30", AbbrName: "Australian Central Standard Time" },
    { Abbr: "YAKT", name: "(GMT+09:00) Yakutsk Standard Time", code: "GMT+09:00", AbbrName: "Yakutsk Standard Time" },
    { Abbr: "AWDT", name: "(GMT+09:00) Australian Western Daylight Time", code: "GMT+09:00", AbbrName: "Australian Western Daylight Time" },
    { Abbr: "JST", name: "(GMT+09:00) Japan Standard Time", code: "GMT+09:00", AbbrName: "Japan Standard Time" },
    { Abbr: "KST", name: "(GMT+09:00) Korean Standard Time", code: "GMT+09:00", AbbrName: "Korean Standard Time" },
    { Abbr: "AWST", name: "(GMT+08:00) Australian Western Standard Time", code: "GMT+08:00", AbbrName: "Australian Western Standard Time" },
    { Abbr: "ACWST", name: "(GMT+08:45) Australian Central Western Standard Time", code: "GMT+08:45", AbbrName: "Australian Central Western Standard Time" },
    { Abbr: "BNT", name: "(GMT+08:00) Brunei Time", code: "GMT+08:00", AbbrName: "Brunei Time" },
    { Abbr: "TST", name: "(GMT+08:00) Taipei Standard Time", code: "GMT+08:00", AbbrName: "Taipei Standard Time" },
    { Abbr: "ULAT", name: "(GMT+08:00) Ulaanbaatar Standard Time", code: "GMT+08:00", AbbrName: "Ulaanbaatar Standard Time" },
    { Abbr: "SST", name: "(GMT+08:00) Singapore Standard Time", code: "GMT+08:00", AbbrName: "Singapore Standard Time" },
    { Abbr: "PST", name: "(GMT+08:00) Philippines Standard Time", code: "GMT+08:00", AbbrName: "Philippines Standard Time" },
    { Abbr: "MYT", name: "(GMT+08:00) Malaysian Time", code: "GMT+08:00", AbbrName: "Malaysian Time" },
    { Abbr: "AWST", name: "(GMT+08:00) Australian Western Standard Time", code: "GMT+08:00", AbbrName: "Australian Western Standard Time" },
    { Abbr: "MST", name: "(GMT+08:00) Malaysia Standard Time", code: "GMT+08:00", AbbrName: "Malaysia Standard Time" },
    { Abbr: "IRKT", name: "(GMT+08:00) Irkutsk Standard Time", code: "GMT+08:00", AbbrName: "Irkutsk Standard Time" },
    { Abbr: "HKT", name: "(GMT+08:00) Hong Kong Time", code: "GMT+08:00", AbbrName: "Hong Kong Time" },
    { Abbr: "CST", name: "(GMT+08:00) China Standard Time", code: "GMT+08:00", AbbrName: "China Standard Time" },
    { Abbr: "ICT", name: "(GMT+07:00) Indochina Time", code: "GMT+07:00", AbbrName: "Indochina Time" },
    { Abbr: "CXT", name: "(GMT+07:00) Christmas Island Time", code: "GMT+07:00", AbbrName: "Christmas Island Time" },
    { Abbr: "THA", name: "(GMT+07:00) Thailand Standard Time", code: "GMT+07:00", AbbrName: "Thailand Standard Time" },
    { Abbr: "KRAT", name: "(GMT+07:00) Krasnoyarsk Standard Time", code: "GMT+07:00", AbbrName: "Krasnoyarsk Standard Time" },
    { Abbr: "HOVST", name: "(GMT+07:00) Hovd Standard Time", code: "GMT+07:00", AbbrName: "Hovd Standard Time" },
    { Abbr: "NOVST", name: "(GMT+07:00) Novosibirsk Standard Time", code: "GMT+07:00", AbbrName: "Novosibirsk Standard Time" },
    { Abbr: "GMT+7", name: "(GMT+07:00) Tomsk", code: "GMT+07:00", AbbrName: "GMT+07:00" },
    { Abbr: "CCT", name: "(GMT+06:30) Cocos Islands Time", code: "GMT+06:30", AbbrName: "Cocos Islands Time" },
    { Abbr: "MST", name: "(GMT+06:30) Myanmar Time", code: "GMT+06:30", AbbrName: "Myanmar Time" },
    { Abbr: "BTT", name: "(GMT+06:00) Bhutan Time", code: "GMT+06:00", AbbrName: "Bhutan Time" },
    { Abbr: "BIOT", name: "(GMT+06:00) British Indian Ocean Time", code: "GMT+06:00", AbbrName: "British Indian Ocean Time" },
    { Abbr: "OMST", name: "(GMT+06:00) Omsk Standard Time", code: "GMT+06:00", AbbrName: "Omsk Standard Time" },
    { Abbr: "GMT+6", name: "(GMT+06:00) East Kazakhstan Time", code: "GMT+06:00", AbbrName: "East Kazakhstan Time" },
    { Abbr: "BST", name: "(GMT+06:00) Bangladesh Standard Time", code: "GMT+06:00", AbbrName: "Bangladesh Standard Time" },
    { Abbr: "NPT", name: "(GMT+05:45) Nepal Time", code: "GMT+05:45", AbbrName: "Nepal Time" },
    { Abbr: "SLT", name: "(GMT+05:30) Sri Lanka Time", code: "GMT+05:30", AbbrName: "Sri Lanka Time" },
    { Abbr: "IST", name: "(GMT+05:30) India Standard Time", code: "GMT+05:30", AbbrName: "India Standard Time" },
    { Abbr: "HMT", name: "(GMT+05:00) Heard and McDonald Islands Time", code: "GMT+05:00", AbbrName: "Heard and McDonald Islands Time" },
    { Abbr: "YEKT", name: "(GMT+05:00) Yekaterinburg Standard Time", code: "GMT+05:00", AbbrName: "Yekaterinburg Standard Time" },
    { Abbr: "PKT", name: "(GMT+05:00) Pakistan Standard Time", code: "GMT+05:00", AbbrName: "Pakistan Standard Time" },
    { Abbr: "UST", name: "(GMT+05:00) Uzbekistan Standard Time", code: "GMT+05:00", AbbrName: "Uzbekistan Standard Time" },
    { Abbr: "KGT", name: "(GMT+05:00) West Kazakhstan Time", code: "GMT+05:00", AbbrName: "West Kazakhstan Time" },
    { Abbr: "PKT", name: "(GMT+05:00) Phoenix Island Time", code: "GMT+05:00", AbbrName: "Phoenix Island Time" },
    { Abbr: "AFT", name: "(GMT+04:30) Afghanistan Time", code: "GMT+04:30", AbbrName: "Afghanistan Time" },
    { Abbr: "IRDT", name: "(GMT+04:30) Iran Daylight Time", code: "GMT+04:30", AbbrName: "Iran Daylight Time" },
    { Abbr: "AZST", name: "(GMT+04:00) Azerbaijan Standard Time", code: "GMT+04:00", AbbrName: "Azerbaijan Standard Time" },
    { Abbr: "RET", name: "(GMT+04:00) Réunion Time", code: "GMT+04:00", AbbrName: "Réunion Time" },
    { Abbr: "SAMST", name: "(GMT+04:00) Samara Standard Time", code: "GMT+04:00", AbbrName: "Samara Standard Time" },
    { Abbr: "SCT", name: "(GMT+04:00) Seychelles Time", code: "GMT+04:00", AbbrName: "Seychelles Time" },
    { Abbr: "GET", name: "(GMT+04:00) Georgia Standard Time", code: "GMT+04:00", AbbrName: "Georgia Standard Time" },
    { Abbr: "MUT", name: "(GMT+04:00) Mauritius Standard Time", code: "GMT+04:00", AbbrName: "Mauritius Standard Time" },
    { Abbr: "GST", name: "(GMT+04:00) Gulf Standard Time", code: "GMT+04:00", AbbrName: "Gulf Standard Time" },
    { Abbr: "MSD", name: "(GMT+04:00) Moscow Daylight Time", code: "GMT+04:00", AbbrName: "Moscow Daylight Time" },
    { Abbr: "AMST", name: "(GMT+04:00) Armenia Standard Time", code: "GMT+04:00", AbbrName: "Armenia Standard Time" },
    { Abbr: "GMT+4", name: "(GMT+04:00) Astrakhan, Ulyanovsk", code: "GMT+04:00", AbbrName: "GMT+04:00" },
    { Abbr: "NST", name: "(GMT+03:30) Newfoundland Standard Time", code: "GMT+03:30", AbbrName: "Newfoundland Standard Time" },
    { Abbr: "IRST", name: "(GMT+03:30) Iran Standard Time", code: "GMT+03:30", AbbrName: "Iran Standard Time" },
    { Abbr: "AST", name: "(GMT+03:00) Arabian Standard Time", code: "GMT+03:00", AbbrName: "Arabian Standard Time" },
    { Abbr: "IDT", name: "(GMT+03:00) Israel Daylight Time", code: "GMT+03:00", AbbrName: "Israel Daylight Time" },
    { Abbr: "MSK", name: "(GMT+03:00) Moscow Standard Time", code: "GMT+03:00", AbbrName: "Moscow Standard Time" },
    { Abbr: "EEST", name: "(GMT+03:00) Eastern European Summer Time", code: "GMT+03:00", AbbrName: "Eastern European Summer Time" },
    { Abbr: "EAT", name: "(GMT+03:00) East Africa Time", code: "GMT+03:00", AbbrName: "East Africa Time" },
    { Abbr: "TRT", name: "(GMT+03:00) Turkey Time", code: "GMT+03:00", AbbrName: "GMT+03:00" },
    { Abbr: "VOLT", name: "(GMT+03:00) Volgograd Standard Time", code: "GMT+03:00", AbbrName: "Volgograd Standard Time" },
    { Abbr: "CAT", name: "(GMT+02:00) Central Africa Time", code: "GMT+02:00", AbbrName: "Central Africa Time" },
    { Abbr: "CEST", name: "(GMT+02:00) Central European Summer Time", code: "GMT+02:00", AbbrName: "Central European Summer Time" },
    { Abbr: "GMT+2", name: "(GMT+02:00) South Africa Standard Time", code: "GMT+02:00", AbbrName: "South Africa Standard Time" },
    { Abbr: "MEST", name: "(GMT+02:00) Middle European Saving Time", code: "GMT+02:00", AbbrName: "Middle European Saving Time" },
    { Abbr: "IST", name: "(GMT+02:00) Israel Standard Time", code: "GMT+02:00", AbbrName: "Israel Standard Time" },
    { Abbr: "GMT+2", name: "(GMT+02:00) Eastern European Standard Time", code: "GMT+02:00", AbbrName: "Eastern European Standard Time" },
    { Abbr: "BST", name: "(GMT+01:00) British Summer Time", code: "GMT+01:00", AbbrName: "British Summer Time" },
    { Abbr: "WAT", name: "(GMT+01:00) West Africa Time", code: "GMT+01:00", AbbrName: "GMT+01:00" },
    { Abbr: "WAST", name: "(GMT+01:00) West Africa Standard Time", code: "GMT+01:00", AbbrName: "West Africa Standard Time" },
    { Abbr: "CET", name: "(GMT+01:00) Central European Standard Time", code: "GMT+01:00", AbbrName: "Central European Standard Time" },
    { Abbr: "IST", name: "(GMT+01:00) Irish Summer Time", code: "GMT+01:00", AbbrName: "Irish Summer Time" },
    { Abbr: "MET", name: "(GMT+01:00) Middle European Time", code: "GMT+01:00", AbbrName: "Middle European Time" },
    { Abbr: "GMT", name: "(GMT+00:00) Greenwich Mean Time", code: "GMT+00:00", AbbrName: "Greenwich Mean Time" },
    { Abbr: "AZODT", name: "(GMT+00:00) Azores Summer Time", code: "GMT+00:00", AbbrName: "Azores Summer Time" },
    { Abbr: "GMT", name: "(GMT+00:00) Coordinated Universal Time", code: "GMT+00:00", AbbrName: "Coordinated Universal Time" },
    { Abbr: "AZOST", name: "(GMT-01:00) Azores Time", code: "GMT-01:00", AbbrName: "Azores Time" },
    { Abbr: "CVT", name: "(GMT-01:00) Cape Verde Standard Time", code: "GMT-01:00", AbbrName: "Cape Verde Standard Time" },
    { Abbr: "NDT", name: "(GMT-02:30) Newfoundland Daylight Time", code: "GMT-02:30", AbbrName: "Newfoundland Daylight Time" },
    { Abbr: "GST", name: "(GMT-02:00) South Georgia and the South Sandwich Islands", code: "GMT-02:00", AbbrName: "South Georgia and the South Sandwich Islands" },
    { Abbr: "UYST", name: "(GMT-02:00) Uruguay Summer Time", code: "GMT-02:00", AbbrName: "Uruguay Summer Time" },
    { Abbr: "GMT-2", name: "(GMT-02:00) Coordinated Universal Time-02", code: "GMT-02:00", AbbrName: "GMT-02:00" },
    { Abbr: "PMDT", name: "(GMT-02:00) St. Pierre & Miquelon Daylight Time", code: "GMT-02:00", AbbrName: "St. Pierre & Miquelon Daylight Time" },
    { Abbr: "WGST", name: "(GMT-02:00) West Greenland Summer Time", code: "GMT-02:00", AbbrName: "West Greenland Summer Time" },
    { Abbr: "CLST", name: "(GMT-03:00) Chile Summer Time", code: "GMT-03:00", AbbrName: "Chile Summer Time" },
    { Abbr: "CLST", name: "(GMT-03:00) Chile Summer Time", code: "GMT-03:00", AbbrName: "GMT-03:00" },
    { Abbr: "ARST", name: "(GMT-03:00) Argentina Standard Time", code: "GMT-03:00", AbbrName: "Argentina Standard Time" },
    { Abbr: "PMST", name: "(GMT-02:00) St. Pierre & Miquelon Standard Time", code: "GMT-03:00", AbbrName: "St. Pierre & Miquelon Standard Time" },
    { Abbr: "GFT", name: "(GMT-03:00) French Guiana Time", code: "GMT-03:00", AbbrName: "French Guiana Time" },
    { Abbr: "ADT", name: "(GMT-03:00) Atlantic Daylight Time", code: "GMT-03:00", AbbrName: "Atlantic Daylight Time" },
    { Abbr: "UYT", name: "(GMT-03:00) Uruguay Standard Time", code: "GMT-03:00", AbbrName: "Uruguay Standard Time" },
    { Abbr: "FKT", name: "(GMT-03:00) Falkland Islands Time", code: "GMT-03:00", AbbrName: "Falkland Islands Time" },
    { Abbr: "BRST", name: "(GMT-03:00) Brasilia Standard Time", code: "GMT-03:00", AbbrName: "Brasilia Standard Time" },
    { Abbr: "AST", name: "(GMT-04:00) Atlantic Standard Time", code: "GMT-04:00", AbbrName: "Atlantic Standard Time" },
    { Abbr: "BOT", name: "(GMT-04:00) Bolivia Time", code: "GMT-04:00", AbbrName: "Bolivia Time" },
    { Abbr: "CLT", name: "(GMT-04:00) Chile Standard Time", code: "GMT-04:00", AbbrName: "Chile Standard Time" },
    { Abbr: "CDT", name: "(GMT-04:00) Cuba Daylight Time", code: "GMT-04:00", AbbrName: "Cuba Daylight Time" },
    { Abbr: "AMST", name: "(GMT-04:00) Amazon Standard Time", code: "GMT-04:00", AbbrName: "Amazon Standard Time" },
    { Abbr: "ECT", name: "(GMT-04:00) Eastern Caribbean Time", code: "GMT-04:00", AbbrName: "Eastern Caribbean Time" },
    { Abbr: "VET", name: "(GMT-04:00) Venezuela Time", code: "GMT-04:00", AbbrName: "Venezuela Time" },
    { Abbr: "EDT", name: "(GMT-04:00) Eastern Daylight Time", code: "GMT-04:00", AbbrName: "Eastern Daylight Time" },
    { Abbr: "GYT", name: "(GMT-04:00) Guyana Time", code: "GMT-04:00", AbbrName: "Guyana Time" },
    { Abbr: "PYT", name: "(GMT-04:00) Paraguay Standard Time", code: "GMT-04:00", AbbrName: "Paraguay Standard Time" },
    { Abbr: "CDT", name: "(GMT-05:00) Central Daylight Time", code: "GMT-05:00", AbbrName: "Central Daylight Time" },
    { Abbr: "COST", name: "(GMT-05:00) Colombia Standard Time", code: "GMT-05:00", AbbrName: "Colombia Standard Time" },
    { Abbr: "EST", name: "(GMT-05:00) Eastern Standard Time", code: "GMT-05:00", AbbrName: "Eastern Standard Time" },
    { Abbr: "ECT", name: "(GMT-05:00) Ecuador Time", code: "GMT-05:00", AbbrName: "Ecuador Time" },
    { Abbr: "CST", name: "(GMT-06:00) Central Standard Time", code: "GMT-06:00", AbbrName: "Central Standard Time" },
    { Abbr: "GALT", name: "(GMT-06:00) Galapagos Time", code: "GMT-06:00", AbbrName: "Galapagos Time" },
    { Abbr: "EAST", name: "(GMT-06:00) Easter Island Standard Time", code: "GMT-06:00", AbbrName: "Easter Island Standard Time" },
    { Abbr: "MDT", name: "(GMT-06:00) Mountain Daylight Time", code: "GMT-06:00", AbbrName: "Mountain Daylight Time" },
    { Abbr: "GMT-6", name: "(GMT-06:00) Mexican Pacific Daylight Time", code: "GMT-06:00", AbbrName: "Mexican Pacific Daylight Time" },
    { Abbr: "MST", name: "(GMT-07:00) Mountain Standard Time", code: "GMT-07:00", AbbrName: "Mountain Standard Time" },
    { Abbr: "PDT", name: "(GMT-07:00) Pacific Daylight Time", code: "GMT-07:00", AbbrName: "Pacific Daylight Time" },
    { Abbr: "GMT-7", name: "(GMT-07:00) Yukon Time", code: "GMT-07:00", AbbrName: "Yukon Time" },
    { Abbr: "GMT-8", name: "(GMT-08:00) GMT-0800", code: "GMT-08:00", AbbrName: "GMT-08:00" },
    { Abbr: "AKDT", name: "(GMT-08:00) Alaska Daylight Time", code: "GMT-08:00", AbbrName: "Alaska Daylight Time" },
    { Abbr: "CIST", name: "(GMT-08:00) Clipperton Island Standard Time", code: "GMT-08:00", AbbrName: "Clipperton Island Standard Time" },
    { Abbr: "PST", name: "(GMT-08:00) Pacific Standard Time", code: "GMT-08:00", AbbrName: "Pacific Standard Time" },
    { Abbr: "AKST", name: "(GMT-09:00) Alaska Standard Time", code: "GMT-09:00", AbbrName: "Alaska Standard Time" },
    { Abbr: "GIT", name: "(GMT-09:00) Gambier Island Time", code: "GMT-09:00", AbbrName: "Gambier Island Time" },
    { Abbr: "GMT-9", name: "(GMT-09:00) GMT-0900", code: "GMT-09:00", AbbrName: "GMT-09:00" },
    { Abbr: "HADT", name: "(GMT-09:00) Hawaii-Aleutian Daylight Time", code: "GMT-09:00", AbbrName: "Hawaii-Aleutian Daylight Time" },
    { Abbr: "MART", name: "(GMT-09:30) Marquesas Time", code: "GMT-09:30", AbbrName: "Marquesas Time" },
    { Abbr: "HAST", name: "(GMT-10:00) Hawaii-Aleutian Standard Time", code: "GMT-10:00", AbbrName: "Hawaii-Aleutian Standard Time" },
    { Abbr: "HST", name: "(GMT-10:00) Hawaii Standard Time", code: "GMT-10:00", AbbrName: "Hawaii Standard Time" },
    { Abbr: "SST", name: "(GMT-11:00) Samoa Standard Time", code: "GMT-11:00", AbbrName: "GMT-11:00" },
    { Abbr: "GMT-12", name: "(GMT-12:00) GMT-12:00", code: "GMT-12:00", AbbrName: "GMT-12:00" },
    { Abbr: "BIT", name: "(GMT-12:00) Baker Island Time", code: "GMT-12:00", AbbrName: "Baker Island Time" },

]


export default timezones