import React, { useState, useEffect, useRef } from 'react';
import './Graphs.css';
import '../reports/Reports.css';
import { Dropdown } from "primereact/dropdown";
import { Chart } from 'primereact/chart';
import LabServices from '../../services/LabServices';
import UtilityServices from "../utility/Utiity";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import moment from "moment";
import { Toast } from "primereact/toast";
import { Constants } from "../../constants/constants";

const EquipmentGraph = () => {
    const toast = useRef(null);
    const [selectedDays, setSelectedDays] = useState({ filterByDays: "Last 90 days", days: 90 });
    const [enable, setEnable] = useState(true);
    const [averagePercentage, setAveragePercentage] = useState(0)
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [totalDays, setTotalDays] = useState(0)

    // dropdown options for changing days
    const filterDays = [
        { filterByDays: "Last 7 days", days: 7 },
        { filterByDays: "Last 15 days", days: 15 },
        { filterByDays: "Last 30 days", days: 30 },
        { filterByDays: "Last 90 days", days: 90 }
    ];

    useEffect(() => {
        async function getEquipment() {
            let days = 90;
            setTotalDays(90);
            let param = `?days=${days}`
            // api call for getting  Equipment Graphs data
            await LabServices.getEquipmentUsage(param).then((res) => {
                setData(res.data);
                let sum = 0;
                let percentage = 0;
                let initDays = 90;
                res.data.physical_usage.forEach(element => {
                    sum = sum + element
                });
                percentage = (sum / initDays).toFixed(2);
                setAveragePercentage(percentage)
            })
        }
        getEquipment();
    }, []);

    // method called on start date change 
    const startDateChange = (e) => {
        setStartDate(e.target.value);
        console.log("StartD", e.value);
    };

    // method called on end date change 
    const endDateChange = (e) => {
        setEndDate(e.target.value);
        console.log("EndD", e.value);
    };

    // search button 
    const searchHandle = async () => {
        let g1 = new Date(startDate);
        let g2 = new Date(endDate);

        if (g1.getTime() < g2.getTime()) {
            console.log("g1 is lesser than g2");

            const oneDay = 24 * 60 * 60 * 1000;

            const diffDays = Math.round(Math.abs((g1 - g2) / oneDay));

            console.log("### No. of days:", diffDays)
            if (diffDays + 1 > 30) {
                setEnable(true);
            } else {
                setEnable(false);
            }
            setTotalDays(diffDays + 1);
            let d1 = moment(startDate).format("YYYY-MM-DD");
            let d2 = moment(endDate).format("YYYY-MM-DD");

            let param = `?startDate=${d1}&endDate=${d2}`
            console.log("### param:", param)
            await LabServices.getEquipmentUsage(param).then((res) => {
                setData(res.data);
                let sum = 0;
                let percentage = 0;
                res.data.physical_usage.forEach(element => {
                    sum = sum + element
                });
                percentage = (sum / (diffDays + 1)).toFixed(2);
                setAveragePercentage(percentage)
            })
        }
        //validation if start date is greater than end date
        else if (g1.getTime() > g2.getTime()) {
            return false;
        }
        else { //validation if start date is equals to end date
            return false;
        }
    };

    //reset button to reset alues to default
    const resetHandle = async (e) => {
        setStartDate("");
        setEndDate("");
        setSelectedDays({ filterByDays: "Last 90 days", days: 90 });
        setEnable(true);
        let days = 90;
        setTotalDays(90);
        let param = `?days=${days}`
        await LabServices.getEquipmentUsage(param).then((res) => {
            setData(res.data);
            let sum = 0;
            let percentage = 0;
            let initDays = 90;
            res.data.physical_usage.forEach(element => {
                sum = sum + element
            });
            percentage = (sum / initDays).toFixed(2);
            setAveragePercentage(percentage)
        })
    };

    // method called on changing days from dropdown
    const onDaysChange = (e) => {
        console.log(e.value, "city")
        setSelectedDays(e.value);
        let days = 90;
        if (e.value.days === 7) {
            days = 7;
            setTotalDays(7);
            setEnable(false)

        }
        else if (e.value.days === 15) {
            days = 15;
            setTotalDays(15);
            setEnable(false)

        }
        else if (e.value.days === 30) {
            days = 30;
            setTotalDays(30);
            setEnable(false)

        }
        else if (e.value.days === 90) {
            days = 90;
            setTotalDays(90);
            setEnable(true)

        }
        let param = `?days=${days}`
        LabServices.getEquipmentUsage(param).then((res) => {
            console.log("apiResss", res.data)
            setData(res.data);
            let sum = 0;
            let percentage = 0;
            res.data.physical_usage.forEach(element => {
                sum = sum + element
            });
            percentage = (sum / days).toFixed(2);
            setAveragePercentage(percentage)
        })
    }

    const records = [{ label: "Physical Resources" }, { label: "Virtual Resource" }]
    records[0].backgroundColor = "#FFBB28";
    records[0].data = data.physical_usage
    records[1].backgroundColor = "#0088FE";
    records[1].data = data.virtual_usage;
    

    const options2 = {
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
            intersect: false,
            mode: 'index',
        },
        plugins: {
            tooltip: {
                callbacks: {}
            },

            legend: {

                position: 'right',
                display: true,

                labels: {
                    color: "white",
                    boxWidth: 20,
                    padding: 30,


                },
            },
            datalabels: {
                anchor: 'end',
                align: 'center'
            }

        },
        scales: {
            y: {

                ticks: {
                    stepSize: 10,
                    color: '#ebedef'
                },

                grid: {
                    color: '#495057'
                },


            },
            x: {

                ticks: {
                    color: '#ebedef'
                },

            }
        }
    }

    for (let i = 0; i < records.length; i++) {
        console.log(records[i], "i=" + i)
    }

    const multiAxisData = {
        labels: data.lab_usage_dates,
        datasets: records
    };

    // method for generating pdf
    const generatePDF = (e) => {
        console.log('Inside generate PDF')
        let days = totalDays;
        let input = window.document.getElementById("chart");
        let filename = "equipment_usage.pdf";
        let canvasType = "chart";
        UtilityServices.exportPDF(days, input, filename, canvasType, "Equipment Usage");
        toast.current.show({
            severity: "success",
            summary: Constants.EXPORT_TOAST_SUMMARY,
            detail: Constants.EXPORT_TOAST_DETAIL,
            life: 6000,
        })
    };


    return (
        <div className='main-grid'>
            <Toast ref={toast} position="top-center" />
            <div className='chart ht equip-height'>
                <div className="parent-row">
                    <div className="item-left-trend-lab">
                        {/* Dropdown for changing days */}
                        <div className="chart-panel-item">
                            <Dropdown
                                value={selectedDays}
                                options={filterDays}
                                onChange={onDaysChange}
                                optionLabel="filterByDays"
                                placeholder="Select quick dates"
                                className='dropdown-days days-selection'
                            />
                        </div>
                        {/* Start date */}
                        <div className="chart-panel-item">
                            <Calendar
                                value={startDate}
                                onChange={(e) => startDateChange(e)}
                                dateFormat="mm/dd/yy"
                                mask="99/99/9999"
                                selectOtherMonths={true}
                                placeholder="Select Start Date"
                                icon="pi pi-calendar"
                                showIcon
                                className="date-icon mr-left-10"
                            />
                        </div>
                         {/* End Date */}
                        <div className="chart-panel-item">
                            <Calendar
                                value={endDate}
                                onChange={(e) => endDateChange(e)}
                                dateFormat="mm/dd/yy"
                                mask="99/99/9999"
                                selectOtherMonths={true}
                                placeholder="Select End Date"
                                icon="pi pi-calendar"
                                showIcon
                                className="date-icon mr-left-10"
                            />
                        </div>
                    </div>
                    <div className="item-right-trend-lab">
                         {/* Search Buttn */}
                        <div className="chart-panel-item">
                            <Button
                                id="search-button"
                                label="SEARCH"
                                className="p-button-primary search-btn"
                                onClick={searchHandle}
                            />
                        </div>
                        {/* Reset Buttn */}
                        <div className="chart-panel-item">
                            <Button
                                id="reset-button"
                                label="RESET"
                                className="p-button-primary reset-btn mr-left-10"
                                onClick={resetHandle}
                            />
                        </div>
                        {/* Export button */}
                        <div className="chart-panel-item">
                            <Button
                                id="export-button"
                                icon="pi pi-file-pdf"
                                label="EXPORT"
                                className="p-button-primary reset-btn mr-left-10"
                                onClick={generatePDF}
                            />
                        </div>
                    </div>
                </div>
                <div className='bar-label child chart-header'>Equipment Usage ( Average Physical Usage : {averagePercentage} )</div>
                {!enable ?
                    <div className='bar-chart' >
                        <Chart type="bar" id='chart' data={multiAxisData} options={options2} height="16rem" width="70vw" />
                    </div> :
                    <div className="chartWrapper">
                        <div className="chartAreaWrapper">
                            <div className="chartAreaWrapper2">
                                <Chart id='chart' type="bar" data={multiAxisData} options={options2} height="16rem" width="170vw" />
                            </div>
                        </div>
                    </div>
                }
                <div className="legend-info mg-t-5">Click over legends, to show/hide chart elements</div>
            </div>
        </div>
    )
}

export default EquipmentGraph;