import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import "../../Rbac.css";
import React, { useState, useRef,useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputSwitch } from "primereact/inputswitch";
import { confirmPopup } from "primereact/confirmpopup";
import parse from "html-react-parser";
import LabServices from "../../../../services/LabServices";
import loglevel from "../../../../logger/Logger";
import eventBus from "../../../utility/EventBus";
import { Message } from "primereact/message";
import { Dropdown } from "primereact/dropdown";

const UpdateLabCategory = ({ catId, catName, setChanger }) => {
  const [updateCatDisplay, setupdateCatDisplay] = useState(false);
  const updateCatDialogMap = {
    updateCatDisplay: setupdateCatDisplay,
  };
  const [cname, setCname] = useState("");
  const [description, setDescription] = useState("");
  const [tooltip, setTooltip] = useState("");
  const [enabled, setEnabled] = useState(true);
  const [isValidated, setIsValidated] = useState(false);
  const [commonErrorMsg, setCommonErrorMsg] = useState("");
  const [parentCategory, setParentCategory] = useState({});
  const [parentCategoryList, setParentCategoryList] = useState([]);
  const [isParentCategoryNotAllowed, setIsParentCategoryNotAllowed] = useState(false);
  const [parentCategoryNotAllowedMsg, setParentCategoryNotAllowedMsg] = useState(false);

  const toast = useRef(null);
  const errorRef = useRef();


  useEffect(async() => {
    await LabServices.getParenCategoryList()
    .then((res) => {
      setParentCategoryList(res.data)
      
    });
  },[])

  const onClick = async (name) => {
    setCommonErrorMsg("");
    LabServices.getLabCategory(catId).then((res) => {
      console.log(res.data,"cate")
      setCname(res.data.name);
      setDescription(res.data.description);
      setTooltip(res.data.tooltip);
      setEnabled(res.data.enabled);
      if(res.data.parentCategoryMasterEntity === null && res.data.subCategoryMasterEntities.length>0){
        setParentCategory({})
        setIsParentCategoryNotAllowed(true)
        setParentCategoryNotAllowedMsg(`Warning : This is parent category having ${res.data.subCategoryMasterEntities.length>1?`${res.data.subCategoryMasterEntities.length} subcategories`:`${res.data.subCategoryMasterEntities.length} subcategory`} `)
      }else{
        if(res.data.parentCategoryMasterEntity === null)
        setParentCategory({})
        else
        setParentCategory(res.data.parentCategoryMasterEntity)
        setIsParentCategoryNotAllowed(false)
        setParentCategoryNotAllowedMsg("")
      }
      
      if(res.data.parentCategoryMasterEntity === null && res.data.subCategoryMasterEntities.length===0){
      
          let categoryList = parentCategoryList.filter((category) =>{
            return category.categoryId!==res.data.categoryId
          })
          setParentCategoryList(categoryList)
          
       
      }
      
      
    });
    
    updateCatDialogMap[`${name}`](true);
  };

  const onHide = (name) => {
    updateCatDialogMap[`${name}`](false);
    setIsValidated(false);
    setCname("");
    setDescription("");
    setIsParentCategoryNotAllowed(false)
    setParentCategoryNotAllowedMsg("")
    setTooltip("");
    setEnabled("");
    setCommonErrorMsg("");
    setParentCategory({})
  };

  const updateSideBar = () => {
    eventBus.dispatch("labCreated", { message: "labCreated" });
  };

  const accept = async (requestBody) => {
    await LabServices.updateCategory(catId, requestBody)
      .then((response) => {
        if (response.status === 200) {
          toast.current.show({
            severity: "success",
            summary: "Lab Type Successfully Updated",
            detail: "The Lab Type has been successfully updated.",
            life: 6000,
          });
          updateCatDialogMap["updateCatDisplay"](false);
          updateSideBar();
        } else {
          loglevel.debug("Category Updation is Failed");
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setCommonErrorMsg(error.response.data.msg);
          errorRef.current.scrollIntoView({ behavior: "smooth" });
        }
      });

    await LabServices.getLabCategoryList().then((res) => {
      setChanger(res.data);
    });
  };

  const onUpdate = (e) => {
    setIsValidated(true);
    <Toast ref={toast} position="top-center" />;
    if (cname && description) {
      const requestBody = {
        name: `${cname}`,
        description: `${description}`,
        parent_category:Object.keys(parentCategory).length>0?parseInt(parentCategory.categoryId):null, 
        tooltip: `${tooltip}`,
        enabled: enabled,
      };

      var confirmationMsg = `Want to save changes?`;

      confirmPopup({
        target: e.currentTarget,
        message: parse(`${confirmationMsg}`),
        icon: "pi pi-exclamation-triangle",
        className: "popup",
        acceptLabel: "YES",
        rejectLabel: "NO",
        accept: () => accept(requestBody),
      });
    }
  };

  const renderFooter = (name) => {
    return (
      <div>
        <Button
          className="calender-button"
          style={{ background: "#49AFD9" }}
          onClick={(e) => onUpdate(e)}
        >
          <span className="name">Update</span>
        </Button>
      </div>
    );
  };

  return (
    <div className="user-tag-edit">
      <Toast ref={toast} position="top-center" />
      <Button
        icon="pi pi-pencil"
        className="p-button-rounded p-button-success ml-2"
        onClick={() => onClick("updateCatDisplay")}
      />
      <Dialog
        header={`Update Category : ${catName}`}
        visible={updateCatDisplay}
        footer={renderFooter("updateCatDisplay")}
        onHide={() => onHide("updateCatDisplay")}
        className="cat-edit-dialog-box"
      >
        <div ref={errorRef} className={commonErrorMsg ? "" : "hidden"}>
          {commonErrorMsg && (
            <Message
              severity="warn"
              text={commonErrorMsg}
              className="common-inline-error common-error-message p-mb-3"
            />
          )}
        </div>
        <div className="dialog-content">
          <div className="name-input">
            <div className="cat-name-label">
              Name:
              <span className="cat-required-field">*</span>
            </div>
            <div>
              <InputText
                value={cname}
                onChange={(e) => setCname(e.target.value)}
                placeholder="Enter Name"
                maxLength={20}
                className={
                  !cname && isValidated
                    ? "cat-input-error"
                    : "edit-cat-name-text-box"
                }
              />
              {!cname && isValidated ? (
                <div className="cat-error-message-inline">
                  <small className="cat-error-message" id="error-message">
                    Category name is mandatory
                  </small>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="cat-description">
            <div className="cat-description-label">
              Description:
              <span className="cat-required-field">*</span>
            </div>
            <div>
              <InputTextarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                maxLength={1000}
                rows={2}
                cols={40}
                placeholder="Enter Category Description"
                autoResize={true}
                className={
                  !description && isValidated
                    ? "desc-input-error"
                    : "cat-desc-box"
                }
              />
              {!description && isValidated ? (
                <div className="cat-error-message-inline">
                  <small className="cat-error-message" id="error-message">
                    Category description is mandatory
                  </small>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="name-input">
            <div className="cat-name-label">
              Parent Category :
            </div>
            <div>
              <Dropdown
                value={parentCategory}
                onChange={(e) => setParentCategory(e.target.value)}
                placeholder="Select Parent Category"
                options={parentCategoryList}
                optionLabel="name"
                className="cat-name-text-box"
                filter
                filterBy="name"
                disabled = {isParentCategoryNotAllowed}
              />
              {isParentCategoryNotAllowed ? (
                <div className="cat-error-message-inline">
                  <small className="cat-warning-msg" id="error-message">
                    {parentCategoryNotAllowedMsg}
                  </small>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="name-input">
            <div className="cat-name-label">
              Tooltip (<span className="optional-label">Optional</span>) :
            </div>
            <InputText
              value={tooltip}
              onChange={(e) => setTooltip(e.target.value)}
              placeholder="-"
              maxLength={30}
              className="edit-cat-tooltip-text-box"
            />
          </div>
          <div className="category-enable">
            <div className="enable-label">Status:</div>
            <InputSwitch
              className="category-enable-switch"
              checked={enabled}
              onChange={(e) => setEnabled(e.target.value)}
            />
            <div className="cat-enabled-text">
              {enabled ? "Category is Enable" : "Category is Disable"}
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default UpdateLabCategory;
