import React, { useState, useRef } from "react";
import { Toast } from "primereact/toast";
import "../labs/Container.css";
import loglevel from "../../logger/Logger";
import NetBoxInventory from "./NetBoxInventory";
import NetBoxReserve from "./NetBoxReserve";
import { useGlobalState } from "../../config/state";
import LabUserGuides from "../guides/lab-user-guides/LabUserGuides";
import AdminGuides from "../guides/admin-guides/AdminGuides";


function Netbox() {
  loglevel.debug("Entering into Netbox Component");
  const [activeIndex, setActiveIndex] = useState(0);
  const [isAdmin, setIsAdmin] = useGlobalState("isAdmin");
  const toast = useRef(null);

  const setIndex0 = (e) => {
    setActiveIndex(0);
  };

  const setIndex1 = (e) => {
    setActiveIndex(1);
  };

  const setIndex2 = (e) => {
    setActiveIndex(2);
  };

  return (
    <section>
      <Toast ref={toast} position="top-center" />
      <div className="tabmenu">
        <button
          className="menuitem-link"
          style={{
            borderColor:
              activeIndex === 0
                ? "#6A7A81 #6A7A81 #1B2A32 #6A7A81"
                : "#1B2A32 #1B2A32 #6A7A81 #1B2A32",
          }}
          onClick={setIndex0}
          data-testid="all-labs-title"
        >
          Reserve (Manual)
        </button>
        {/*<button*/}
        {/*  className="menuitem-link"*/}
        {/*  style={{*/}
        {/*    borderColor:*/}
        {/*      activeIndex === 1*/}
        {/*        ? "#6A7A81 #6A7A81 #1B2A32 #6A7A81"*/}
        {/*        : "#1B2A32 #1B2A32 #6A7A81 #1B2A32",*/}
        {/*  }}*/}
        {/*  onClick={setIndex1}*/}
        {/*>*/}
        {/*  TODO*/}
        {/*</button>*/}
      </div>
      <div className="data">
        {/*  da11 */}
        {activeIndex === 0 ? <NetBoxInventory /> : ""}
        {activeIndex === 1 ? <NetBoxReserve /> : ""}
      </div>
    </section>
  );
}

export default Netbox;
