import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'primereact/button'

const Privacy = () => {
    return (
        <div>
            <h1>Privacy page....</h1>
            <Link to="/" style={{textDecoration:"none"}}><Button>Go Back</Button></Link>
        </div>
    )
}

export default Privacy
