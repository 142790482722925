import React, { useRef, useState, useEffect } from "react";
import { Menubar } from "primereact/menubar";
import "./Header.css";
import { SplitButton } from "primereact/splitbutton";
import { NavLink, useNavigate } from "react-router-dom";
import { Divider } from "primereact/divider";
import LabServices from "../../services/LabServices";
import "./Notification.css";
import loglevel from "../../logger/Logger";
// import LogoTelcoLabs from "../../images/login-page-logo.svg"
// import LogoVmwBcm from "../../images/vmw-bcm-logo-white.svg"
import LogoVmwBcmSdeLabs from "../../images/sameline.svg"
import eventBus from "../utility/EventBus";
import { useGlobalState } from "../../config/state";
import {Button} from "primereact/button";
import Joyride from 'react-joyride';

const Header = () => {
  loglevel.debug("Entering into Header Component");
  let navigate = useNavigate();
  let firstName = localStorage.getItem("fname");
  let lastName = localStorage.getItem("lname");
  let [firstname] = useState(firstName);
  let [lastname] = useState(lastName);
  const [unreadCount, setUnreadCount] = useState(0);
  const [enableHelp, setEnableHelp] = useState(false);
  const [isAdmin, setIsAdmin] = useGlobalState('isAdmin');
  const logoutButton = useRef(null);

  useEffect(() => {
    async function fetchData() {
      // api call for listing notifications
      await LabServices.getUnreadNotificationCount()
        .then((res) => {
          setUnreadCount(res.data);
        })
        .catch((error) => {
          loglevel.error("getNotificationList", error);
        });
    }
    fetchData();
  }, []);

  useEffect(() => {
    // event bus to be called from different component
    eventBus.on("notificationCount", async () => {
      await LabServices.getUnreadNotificationCount()
        .then((res) => {
          setUnreadCount(res.data);
        })
        .catch((error) => {
          loglevel.error("getNotificationList", error);
        });
    });
  }, []);

 // user close account and logout option
  const userIitems = [
    {
      label: "Close My Account",
      icon: "pi pi-trash",
      command: async (e) => {
        navigate("/home/close-account");
      },
    },
    {
      label: "Logout",
      icon: "pi pi-sign-out",
      command: async (e) => {
        console.log("### removing local storage.....")
        localStorage.setItem("isAuthenticated", "false");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("expiresIn");
        localStorage.removeItem("email");
        localStorage.removeItem('isSSOLogin');
        navigate("/");
      },
    },

  ];

  // admin close account, setting  and logout option
  const adminIitems = [
    {
      label: "Settings",
      icon: "pi pi-cog",
      command: (e) => {
        navigate("/home/settings");
      },
    },
    {
      label: "Close My Account",
      icon: "pi pi-trash",
      command: async (e) => {
        navigate("/home/close-account");
      },
    },
    {
      label: "Logout",
      icon: "pi pi-sign-out",
      command: async (e) => {
        console.log("### removing local storage.....")
        localStorage.setItem("isAuthenticated", "false");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("expiresIn");
        localStorage.removeItem("email");
        localStorage.removeItem('isSSOLogin');
        navigate("/");
      },
    },
  ];

  const showHelp = (event) => {
      // event.preventDefault()
      setEnableHelp(event);
      // console.log("Before = ", enableHelp);
      // if (enableHelp) {
      //     setEnableHelp(false);
      // } else {
      //     setEnableHelp(true);
      // }
      // console.log("After = ", enableHelp);
  };

  const steps = [
    {
        target: ".p-accordion-header",
        content: <>
          Find the lab that you would like to schedule from the categories below:
          <ul>
            <li><b>Use Cases:</b> Demo Labs for common Telco use cases</li>
            <li><b style={{paddingRight: '9px'}}>Products:</b> Demo Labs for VMWare Telco Cloud Platform(TCP) products</li>
            <li><b style={{paddingRight: '8px'}}>Solutions:</b> Solution Specific Demo Labs</li>
          </ul>
          <b>Note:</b> Lab categories will be visible based on your lab access
        </>,
        disableBeacon: true, // required only for first step,
        spotlightPadding: 0,
        styles: {
          spotlight: {
            borderRadius: 0
          },
          options: {
            width: 640
          }
        },
        placement: 'right'
    },
    {
        target: "#myschedulelab",
        content: "Schedule/Request-access your Lab here and refer portal user guide under 'Guides'",
        spotlightPadding: 0,
    },
    {
        target: "#mymylabs",
        content: <>
          Once you have scheduled your lab, you can access your labs and view status under the 'My Labs' tab. <br></br><br></br>
          <b>Note:</b> You can only connect to the labs once they are ready and active.
          You will receive status updates regarding your lab via email
        </>,
        spotlightPadding: 0,
        styles: {
          spotlight: {
            borderRadius: 0
          },
          options: {
            width: 450
          }
        }
    },
    {
      target: '#mymyreports',
      content: "You can find your lab usage reports under 'My Reports' tab",
      spotlightPadding: 0,
      styles: {
        spotlight: {
          borderRadius: 0
        }
      }
    },
    {
        target: "#mysidebarguides",
        content: "You will find specific lab guide under the 'Lab User Guide' tab",
        spotlightPadding: 0,
        styles: {
          spotlight: {
            borderRadius: 0
          }
        }
    },
    {
        target: "#mycontactus",
        content: "Contact Us for any support / feedback / query",
        spotlightPadding: 0,
        styles: {
          spotlight: {
            borderRadius: 0
          },
          options: {
            width: 400
          }
        }
    },
  ];

  const handleJoyrideCallback = data => {
      const { action, index, status, type } = data;
      if (action ===  'close') {
          setEnableHelp(false)
      }
    }

  return (
    <>
      <Joyride
          run={enableHelp}
          callback={handleJoyrideCallback}
          continuous
          scrollToFirstStep
          showProgress
          showSkipButton
          steps={steps}
          spotlightClicks
          stepIndex="0"
          styles={{
              options: {
                  zIndex: 1000,
                  primaryColor: '#3698DC',
              },
              tooltipContainer: {
                textAlign: 'left'
              }
          }}
          locale={
             {
                 skip: 'Skip (press Esc to quit)',
             }
          }
      />
      <div className="menubar">
          <div className="start-menu">
            <div className="nav-icon" style={{display:"flex"}}>
                {/*<img src={LogoVmwBcmSdeLabs} alt="Logo" width={"500"} style={{ marginBottom:"-4px", marginLeft: "-20px"}}/>*/}
                <img src={LogoVmwBcmSdeLabs} alt="Logo" className={"sameline"}/>
                {/* Just Text without SVG */}
                {/*<p style={{color:"white", fontFamily:"Metropolis", fontSize:"1.7em"}}>Software Defined Edge Labs</p>*/}

                {/* Prev */}
                {/*<img src={LogoVmwBcmS} alt="Logo" width={"150"}/>*/}
                {/*<img src={LogoTelcoLabs} style={{ marginBottom:"20px", marginLeft: "10px", height:"22px" }}  alt="Logo" />*/}
            </div>
          </div>

         <div className="end-menu">
          <div className="p-d-flex p-ai-center">
            {/*<Button className="helper-icon-menu" style={{ background: "#49AFD9" }} onClick={showHelp}>*/}
            {/*    <svg width="30" height="20" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*        <path fill-rule="evenodd" clip-rule="evenodd" fill="black" d="M0 18C0 8.05887 8.05887 0 18 0C22.7739 0 27.3523 1.89642 30.7279 5.27208C34.1036 8.64773 36 13.2261 36 18C36 27.9411 27.9411 36 18 36C8.05887 36 0 27.9411 0 18ZM2.25 18C2.25 26.6985 9.30152 33.75 18 33.75C22.1772 33.75 26.1832 32.0906 29.1369 29.1369C32.0906 26.1832 33.75 22.1772 33.75 18C33.75 9.30152 26.6985 2.25 18 2.25C9.30152 2.25 2.25 9.30152 2.25 18ZM18.3263 7.77946C15.8581 7.73866 13.4998 8.79825 11.8913 10.6707C11.6682 10.8774 11.5384 11.1655 11.5312 11.4695C11.5311 11.7499 11.6448 12.0184 11.8462 12.2135C12.0477 12.4086 12.3197 12.5136 12.6 12.5045C12.8972 12.4971 13.1814 12.381 13.3988 12.1782C14.6156 10.7523 16.3954 9.93015 18.27 9.92821C20.925 9.92821 22.5788 11.6382 22.5788 13.7645V13.8207C22.5788 16.307 20.5988 17.6907 17.4713 17.927C17.2151 17.9368 16.9753 18.0555 16.8121 18.2531C16.6489 18.4507 16.5776 18.7086 16.6163 18.962V22.0557C16.6742 22.6327 17.1613 23.0711 17.7413 23.0682H17.865C18.442 23.0102 18.8804 22.5231 18.8775 21.9432V19.6257C22.2525 19.1532 24.9863 17.3757 24.9863 13.6857V13.6295C24.9525 10.2545 22.32 7.77946 18.3263 7.77946ZM19.1602 27.2197C19.1602 27.9964 18.5306 28.626 17.7539 28.626C16.9773 28.626 16.3477 27.9964 16.3477 27.2197C16.3477 26.4431 16.9773 25.8135 17.7539 25.8135C18.5306 25.8135 19.1602 26.4431 19.1602 27.2197Z"/>*/}
            {/*    </svg>*/}
            {/*    Show Help*/}
            {/*</Button>*/}
            <Button className="helper-icon-menu" style={{ background: "none", border:"none" }} onClick={showHelp}>
                <svg width="20" height="20" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 18C0 8.05887 8.05887 0 18 0C22.7739 0 27.3523 1.89642 30.7279 5.27208C34.1036 8.64773 36 13.2261 36 18C36 27.9411 27.9411 36 18 36C8.05887 36 0 27.9411 0 18ZM2.25 18C2.25 26.6985 9.30152 33.75 18 33.75C22.1772 33.75 26.1832 32.0906 29.1369 29.1369C32.0906 26.1832 33.75 22.1772 33.75 18C33.75 9.30152 26.6985 2.25 18 2.25C9.30152 2.25 2.25 9.30152 2.25 18ZM18.3263 7.77946C15.8581 7.73866 13.4998 8.79825 11.8913 10.6707C11.6682 10.8774 11.5384 11.1655 11.5312 11.4695C11.5311 11.7499 11.6448 12.0184 11.8462 12.2135C12.0477 12.4086 12.3197 12.5136 12.6 12.5045C12.8972 12.4971 13.1814 12.381 13.3988 12.1782C14.6156 10.7523 16.3954 9.93015 18.27 9.92821C20.925 9.92821 22.5788 11.6382 22.5788 13.7645V13.8207C22.5788 16.307 20.5988 17.6907 17.4713 17.927C17.2151 17.9368 16.9753 18.0555 16.8121 18.2531C16.6489 18.4507 16.5776 18.7086 16.6163 18.962V22.0557C16.6742 22.6327 17.1613 23.0711 17.7413 23.0682H17.865C18.442 23.0102 18.8804 22.5231 18.8775 21.9432V19.6257C22.2525 19.1532 24.9863 17.3757 24.9863 13.6857V13.6295C24.9525 10.2545 22.32 7.77946 18.3263 7.77946ZM19.1602 27.2197C19.1602 27.9964 18.5306 28.626 17.7539 28.626C16.9773 28.626 16.3477 27.9964 16.3477 27.2197C16.3477 26.4431 16.9773 25.8135 17.7539 25.8135C18.5306 25.8135 19.1602 26.4431 19.1602 27.2197Z" fill="white"/>
                </svg>
                <span className="help-content">
                    Show Help
                </span>
            </Button>
            <div className="header-divider" style={{ height: "40%" }}>
              <Divider
                layout="vertical"
                style={{
                  marginTop: "0.3rem",
                  padding: "0.8rem 0",
                  opacity: "0.6",
                }}
              />
            </div>
            <NavLink className="header-navlink" to="/home/about">
              <div className="helper-icon-menu">
                <div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 12C0 18.6274 5.37258 24 12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12ZM1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.20101 22.5 1.5 17.799 1.5 12ZM14.25 17.25H12.75V9.075H10.5C10.0858 9.075 9.75 9.41079 9.75 9.825C9.75 10.2392 10.0858 10.575 10.5 10.575H11.25V17.25H9.75C9.33579 17.25 9 17.5858 9 18C9 18.4142 9.33579 18.75 9.75 18.75H14.25C14.6642 18.75 15 18.4142 15 18C15 17.5858 14.6642 17.25 14.25 17.25ZM13.0275 6.3375C13.0275 6.9174 12.5574 7.3875 11.9775 7.3875C11.3976 7.3875 10.9275 6.9174 10.9275 6.3375C10.9275 5.7576 11.3976 5.2875 11.9775 5.2875C12.5574 5.2875 13.0275 5.7576 13.0275 6.3375Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="help-content">About</div>
              </div>
            </NavLink>
            <div className="header-divider" style={{ height: "40%" }}>
              <Divider
                layout="vertical"
                style={{
                  marginTop: "0.3rem",
                  padding: "0.8rem 0",
                  opacity: "0.6",
                }}
              />
            </div>
            <NavLink className="header-navlink" to="/home/contactus" id="mycontactus">
              <div className="helper-icon-menu">
                <div>
                  <svg
                    width="22"
                    height="16"
                    viewBox="0 0 22 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M20.334 0H1.66732C0.930938 0 0.333984 0.596954 0.333984 1.33333V14.6667C0.333984 15.403 0.930938 16 1.66732 16H20.334C21.0704 16 21.6673 15.403 21.6673 14.6667V1.33333C21.6673 0.596954 21.0704 0 20.334 0ZM19.3073 14.6667H2.77398L7.44065 9.84L6.48065 8.91333L1.66732 13.8933V2.34667L9.95399 10.5933C10.474 11.1103 11.3139 11.1103 11.834 10.5933L20.334 2.14V13.8067L15.4273 8.9L14.4873 9.84L19.3073 14.6667ZM19.254 1.33333H2.54065L10.894 9.64667L19.254 1.33333Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="help-content">Contact Us</div>
              </div>
            </NavLink>
            <div className="header-divider" style={{ height: "40%" }}>
              <Divider
                layout="vertical"
                style={{
                  marginTop: "0.3rem",
                  padding: "0.8rem 0",
                  opacity: "0.6",
                }}
              />
            </div>
            <NavLink className="header-navlink" to="/home/notifications">
              <div className="helper-icon-menu" id="mynotifications">
                <div>
                  {unreadCount ? (
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20.8325 17.2914C21.3196 18.0448 21.9059 18.7292 22.5755 19.3262C22.7254 19.4578 22.8114 19.6475 22.8116 19.847V20.7914C22.8116 21.175 22.5007 21.4859 22.1172 21.4859H2.81163C2.4281 21.4859 2.11719 21.175 2.11719 20.7914V19.847C2.11744 19.6475 2.20345 19.4578 2.3533 19.3262C3.01316 18.7274 3.59002 18.0431 4.06858 17.2914C4.60037 16.2516 4.9191 15.1159 5.00608 13.9512V10.5206C4.99707 6.77293 7.75345 3.59272 11.4644 3.06923V2.15951C11.4644 1.64749 11.8795 1.23242 12.3915 1.23242C12.9035 1.23242 13.3186 1.64749 13.3186 2.15951V3.03451C14.1201 3.13762 14.899 3.37225 15.6241 3.72895V4.16645C15.6361 4.57725 15.6967 4.98519 15.8047 5.38173C14.8252 4.73051 13.6754 4.38267 12.4991 4.38173C10.8759 4.3854 9.32091 5.03469 8.17706 6.18635C7.03321 7.33802 6.39453 8.89745 6.40191 10.5206V13.9581C6.31679 15.379 5.92595 16.7647 5.25608 18.0206C4.77663 18.7803 4.20775 19.4798 3.56163 20.1039H21.3464C20.7002 19.4798 20.1314 18.7803 19.6519 18.0206C18.982 16.7647 18.5912 15.379 18.5061 13.9581V10.5276C18.5063 9.90595 18.4127 9.28787 18.2283 8.69423C18.712 8.97131 19.2373 9.1683 19.7839 9.27756C19.855 9.68816 19.8921 10.1039 19.895 10.5206V13.9512C19.9819 15.1159 20.3007 16.2516 20.8325 17.2914ZM14.2908 22.222C14.1591 23.1158 13.4023 23.7845 12.4991 23.8053C11.5695 23.8188 10.7737 23.1417 10.638 22.222H14.2908Z"
                        fill="white"
                      />
                      <path
                        d="M20.8336 7.63878C22.7512 7.63878 24.3058 6.08421 24.3058 4.16656C24.3058 2.2489 22.7512 0.694336 20.8336 0.694336C18.9159 0.694336 17.3613 2.2489 17.3613 4.16656C17.3613 6.08421 18.9159 7.63878 20.8336 7.63878Z"
                        fill="#E62700"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M22.5755 19.3262C21.9059 18.7292 21.3196 18.0448 20.8325 17.2914C20.3007 16.2516 19.9819 15.1159 19.895 13.9512V10.5206C19.9045 6.73529 17.0942 3.53506 13.3394 3.05534V2.15951C13.3394 1.64749 12.9243 1.23242 12.4123 1.23242C11.9003 1.23242 11.4852 1.64749 11.4852 2.15951V3.06923C7.76601 3.58326 4.99851 6.76604 5.00608 10.5206V13.9512C4.9191 15.1159 4.60037 16.2516 4.06858 17.2914C3.59002 18.0431 3.01316 18.7274 2.3533 19.3262C2.20345 19.4578 2.11744 19.6475 2.11719 19.847V20.7914C2.11719 21.175 2.4281 21.4859 2.81163 21.4859H22.1172C22.3014 21.4859 22.478 21.4127 22.6082 21.2825C22.7385 21.1523 22.8116 20.9756 22.8116 20.7914V19.847C22.8114 19.6475 22.7254 19.4578 22.5755 19.3262ZM3.56163 20.097C4.20775 19.4728 4.77663 18.7734 5.25608 18.0137C5.92595 16.7578 6.31679 15.372 6.40191 13.9512V10.5206C6.32692 8.30451 7.4664 6.22387 9.37409 5.0936C11.2818 3.96333 13.654 3.96333 15.5617 5.0936C17.4694 6.22387 18.6088 8.30451 18.5339 10.5206V13.9512C18.619 15.372 19.0098 16.7578 19.6797 18.0137C20.1591 18.7734 20.728 19.4728 21.3741 20.097H3.56163ZM14.2908 22.222C14.1591 23.1158 13.4023 23.7845 12.4991 23.8053C11.5695 23.8188 10.7737 23.1417 10.638 22.222H14.2908Z"
                        fill="white"
                      />
                    </svg>
                  )}
                </div>
                <div className="help-content">Notifications</div>
              </div>
            </NavLink>

            <div className="header-divider" style={{ height: "40%" }}>
              <Divider
                layout="vertical"
                style={{
                  marginTop: "0.3rem",
                  padding: "0.8rem 0",
                  opacity: "0.6",
                }}
              />
            </div>

            {/* ----------------- */}
            <div className="header-logout">
              <SplitButton
                label={`${firstname} ${lastname}`}
                className="button-logout"
                onClick={() => logoutButton.current.onDropdownButtonClick()}
                model={isAdmin ? adminIitems : userIitems}
                ref={logoutButton}
              ></SplitButton>
            </div>
          </div>
        </div>

 </div>
    </>
  );
};

export default Header;
