import React, { useState, useEffect, useRef } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Button } from "primereact/button";
import LabServices from "../../services/LabServices";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import "./Reports.css";
import { SplitButton } from "primereact/splitbutton";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { Toast } from "primereact/toast";
import { Constants } from "../../constants/constants";

const LoginReports = () => {
    const dt = useRef(null);
    const toast = useRef(null);
    const exportButton = useRef(null);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [selectedDays, setSelectedDays] = useState({
        filterByDays: "Last 90 days",
        days: 90,
    });
    const [userLoginData, setUserLoginData] = useState([]);
    const [filteredUserLoginData, setFilteredUserLoginData] = useState([]);

    const [filteredValue, setFilteredValue] = useState([]);

    // Dropdown options for login report
    const filterDays = [
        { filterByDays: "Last 7 days", days: 7 },
        { filterByDays: "Last 15 days", days: 15 },
        { filterByDays: "Last 30 days", days: 30 },
        { filterByDays: "Last 90 days", days: 90 },
    ];

    //on Changing days from dropdown
    const onDaysChange = (e) => {
        setSelectedDays(e.value);
        let days = 90;
        if (e.value.days === 7) {
            days = 7;
            let today = new Date(); // current date
            let priorSeven = new Date(new Date().setDate(today.getDate() - 7)); //date prior 7 days
            setStartDate(priorSeven); //setting start date as prior 7 days
            setEndDate(today); //setting last date as current date
        } else if (e.value.days === 15) {
            days = 15;
            let today = new Date();
            let priorFifteen = new Date(new Date().setDate(today.getDate() - 15));
            setStartDate(priorFifteen);
            setEndDate(today);
        } else if (e.value.days === 30) {
            days = 30;
            let today = new Date();
            let priorThirty = new Date(new Date().setDate(today.getDate() - 30));
            setStartDate(priorThirty);
            setEndDate(today);
        } else if (e.value.days === 90) {
            days = 90;
            let today = new Date();
            let priorNinety = new Date(new Date().setDate(today.getDate() - 90));
            setStartDate(priorNinety);
            setEndDate(today);
        }
       // api call for login details list to refresh the list after each option selection from dropdown
        LabServices.getUserLoginDetails(days).then((res) => {
            setUserLoginData(res.data);
            setFilteredUserLoginData(res.data);
            setFilteredValue(res.data);
        });
    };

    const getUserLoginDetails = () => {
        // api call for user Login details
        LabServices.getUserLoginDetails(90).then((res) => {
            console.log(res.data)
            setUserLoginData(res.data);
            setFilteredUserLoginData(res.data);
            setFilteredValue(res.data);
        });
    };

    //setting start date
    const startDateChange = (e) => {
        setStartDate(e.target.value);
    };

    //setting end-date
    const endDateChange = (e) => {
        setEndDate(e.target.value);
    };

    const filteredLabs = (start, end) => {
        let filteredData = [];
        //filtering user login details on the basis of start and end date
        userLoginData.forEach((loginData) => {
            if (validateDateBetweenTwoDates(start, end, new Date(loginData.timestamp))) {
                filteredData.push(loginData)
            }
        });
        setFilteredUserLoginData(filteredData)
        setFilteredValue(filteredData);
    };

    // date validation method
    var getvalidDate = function (d) { return new Date(d.getFullYear(), d.getMonth(), d.getDate()) }

    // validating date btween two dates
    function validateDateBetweenTwoDates(startDate, endDate, givenDate) {
        return getvalidDate(givenDate) <= getvalidDate(endDate) &&
            getvalidDate(givenDate) >= getvalidDate(startDate);
    }

    const searchHandle = () => {
        filteredLabs(startDate, endDate)
    };

    // reset all filter to default
    const resetHandle = (e) => {
        setStartDate("");
        setEndDate("");
        setSelectedDays({ filterByDays: "Last 90 days", days: 90 });
        getUserLoginDetails();
    };

    useEffect(() => {
        let days = 90;
        let today = new Date();
        let priorNinety = new Date(new Date().setDate(today.getDate() - 90));
        setStartDate(priorNinety);
        setEndDate(today);
        //By default loading 90 days data
        getUserLoginDetails();
    }, []);

    // method to export data in csv file
    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
        toast.current.show({
            severity: "success",
            summary: Constants.EXPORT_TOAST_SUMMARY,
            detail: Constants.EXPORT_TOAST_DETAIL,
            life: 6000,
        })
    };

    // method to export data in pdf format
    const exportPdf = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "VMware User Login Report";
        // pdf column name
        const headers = [
            [
                "#",
                "USER NAME",
                "EMAIL ID",
                "LOGIN DATE AND TIME"
            ],
        ];

        console.log("pdf export filteredValue:", filteredValue)
        
        //populating data in pdf
        const data = filteredValue.map((loginData, index) => [
            index + 1,
            loginData.name,
            loginData.email,
            loginData.timestamp
        ]);

        console.log("pdf export data:", data)

        let content = {
            startY: 50,
            head: headers,
            body: data,
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        // name of pdf
        doc.save("user_login_report.pdf");
        toast.current.show({
            severity: "success",
            summary: Constants.EXPORT_TOAST_SUMMARY,
            detail: Constants.EXPORT_TOAST_DETAIL,
            life: 6000,
        })
    };

    // dropdown to show pdf and csv
    const items = [
        {
            label: "CSV",
            icon: "pi pi-file",
            command: (e) => {
                exportCSV();
            },
        },
        {
            label: "PDF",
            icon: "pi pi-file-pdf",
            command: (e) => {
                exportPdf();
            },
        },
    ];

    const onIndexTemplate = (rowData, props) => {
        return props.rowIndex + 1;
    };

    //user name
    const userBodyTemplate = (rowData) => {
        return <div className="short-desc">{rowData.name}</div>;
    };

    //email column
    const emailIdBodyTemplate = (rowData) => {
        return <div className="short-desc">{rowData.email}</div>;
    };

    // login date time column
    const loginDateTimeBodyTemplate = (rowData) => {
        let date = rowData.timestamp.split(' ')[0]
        let time = rowData.timestamp.split(' ')[1]
        let loginDateTime = date.split('-')[1] + '-' + date.split('-')[2] + '-' + date.split('-')[0] + ' ' + time

        return <div className="short-desc">{loginDateTime}</div>;
    };

    return (
        <div className="container">
            <Toast ref={toast} position="top-center" />
            <br/>
            {/* <div style={{ padding: "0.5rem 0" }}><span class="admin-report-header">User Login Reports</span></div> */}
            <div className="panel report-panel panel-content">
                <div className="panel">
                    <div className="panel-item">
                        {/* Dropdown option to show days */}
                        <Dropdown
                            value={selectedDays}
                            options={filterDays}
                            onChange={onDaysChange}
                            optionLabel="filterByDays"
                            placeholder="Select quick dates"
                            className="quick-date-selector"
                        />
                    </div>
                    <div className="panel-item start-date-calender">
                        {/* Start date */}
                        <Calendar
                            // id="range"
                            value={startDate}
                            onChange={(e) => startDateChange(e)}
                            dateFormat="mm/dd/yy"
                            mask="99/99/9999"
                            selectOtherMonths={true}
                            placeholder="Select Start Date"
                            icon="pi pi-calendar"
                            showIcon
                            className="date-icon"
                        />

                    </div>
                    <div className="panel-item end-date-calender">
                        {/* end date */}
                        <Calendar
                            value={endDate}
                            onChange={(e) => endDateChange(e)}
                            dateFormat="mm/dd/yy"
                            mask="99/99/9999"
                            selectOtherMonths={true}
                            placeholder="Select End Date"
                            icon="pi pi-calendar"
                            showIcon
                            className="date-icon"
                        />
                    </div>
                    </div>
                    <div className="action-buttons">
                        <div className="panel-item">
                            {/* search button */}
                            <Button
                                id="search-button"
                                label="SEARCH"
                                className="p-button-primary search-btn"
                                onClick={searchHandle}
                            />
                        </div>
                        <div className="panel-item">
                            {/* Reset button */}
                            <Button
                                id="reset-button"
                                label="RESET"
                                className="p-button-primary reset-btn"
                                onClick={resetHandle}
                            />
                        </div>
                        <div className="panel-item p-splitbutton">
                            {/* split button to show csv and pdf export option */}
                            <SplitButton
                                ref={exportButton}
                                className="p-splitbutton-button export-btn"
                                onClick={() => exportButton.current.onDropdownButtonClick()}
                                label="EXPORT"
                                model={items}
                            ></SplitButton>
                        </div>
                    </div>
                
            </div>

            <br />
            <DataTable
                ref={dt}
                value={filteredUserLoginData}
                responsiveLayout="scroll"
                className="table-type"
                columnResizeMode="fit"
                paginator
                rows={50}
                totalRecords={filteredUserLoginData.length}
                pageLinkSize={1}
                rowsPerPageOptions={[5, 10, 20, 50]}
                removableSort={true}
                exportFilename="user_login_report"
                onValueChange={filteredData => setFilteredValue(filteredData)}
            >
                <Column
                    field="Index"
                    header="#"
                    style={{ textAlign: "left", fontWeight: "600", width: "2rem", paddingLeft: "15px" }}
                    body={onIndexTemplate}
                />
                <Column
                    field="name"
                    sortable
                    header="User Name"
                    style={{ width: "15rem", maxWidth: "15rem", fontWeight: "600" }}
                    body={userBodyTemplate}
                />
                <Column
                    field="email"
                    sortable
                    header="Email ID"
                    style={{ width: "30rem", maxWidth: "30rem" }}
                    body={emailIdBodyTemplate}
                ></Column>
                <Column
                    field="timestamp"
                    sortable
                    header="Login Date And Time"
                    style={{ width: "15rem", maxWidth: "15rem" }}
                    body={loginDateTimeBodyTemplate}
                ></Column>

            </DataTable>
        </div>
    );
};

export default LoginReports;
