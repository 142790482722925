import React from 'react'
import { Button } from 'primereact/button'

const RequestPortalAccess = ({ portalRequest }) => {

  const openPortalAccessForm = () => {
    portalRequest();
  }

  return (
    <div>
      <div className='req-portal-access-div p-d-flex p-jc-center'>
        <Button label="Request portal access" onClick={openPortalAccessForm} className="req-portal-access-btn" />
      </div>
    </div>
  )
}

export default RequestPortalAccess