import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import "../../Rbac.css";
import "../InventoryMgmt.css"
import React, { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { confirmPopup } from "primereact/confirmpopup";
import parse from "html-react-parser";
import LabServices from "../../../../services/LabServices";
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from "primereact/dropdown";
import loglevel from "../../../../logger/Logger";
import { Message } from "primereact/message";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { RadioButton } from "primereact/radiobutton";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { isIPAddress, isIPV6Address, isIPV4Address, ipVersion } from "ip-address-validator";
import { InputTextarea } from 'primereact/inputtextarea';



const UpdateAsset = ({ assetId, assetNameProp, enabled, deleted, fetchData, showToastSuccess, manufacturersList, serverModelsList }) => {
  const [isValidated, setIsValidated] = useState(false);
  const [updateAssetDisplay, setUpdateAssetDisplay] = useState(false);
  const [assetName, setAssetName] = useState("");
  const [isAddTagVisible, setIsAddTagVisible] = useState(false);
  const [isAddManufacturerVisible, setIsAddManufacturerVisible] = useState(false);
  const [isAddModelVisible, setIsAddModelVisible] = useState(false);
  const [isAddGradeVisible, setIsAddGradeVisible] = useState(false);
  const [manufacturer, setManufacturer] = useState("");
  const [cmdbTags, setCmdbTags] = useState([]);
  const [bmc_ip, setBmc_ip] = useState("");
  const [serial_no, setSerial_no] = useState("");
  const [model, setModel] = useState("");
  const [gradeList, setGradeList] = useState([]);
  const [grade, setGrade] = useState("");
  const [newGrade, setNewGrade] = useState("");
  const [cmdbTagList, setCmdbTagList] = useState([]);
  const [modelsList, setModelsList] = useState([]);
  const [modelIds, setModelIds] = useState([]);
  const [newTag, setNewTag] = useState("");
  const [assetErrorMsg, setAssetErrorMsg] = useState("");
  const [disableAssetDisplay, setDisableAssetDisplay] = useState(false);
  const [isScheduledLabs, setIsScheduledLabs] = useState(null);
  const [enableAsset, setEnableAsset] = useState(true);
  const [enableCalendar, setEnableCalendar] = useState(false);
  const [dateDisableMessage, setDateDisableMessage] = useState("");
  const [disabledField, setDisabledField] = useState(null);
  const [isEmptyDate, setIsEmptyDate] = useState(false);
  const [disableScheduled, setDisableScheduled] = useState([]);
  const [isAlreadyDisabled, setIsAlreadyDisabled] = useState(null);
  const [date, setDate] = useState(null);
  const [strikeOutDates, setStrikeOutDates] = useState([]);
  const [isBMCIP, setIsBMCIP] = useState(false)
  const [isFutureDisable, setIsFutureDisable] = useState(null)
  const [assetDisabledBy, setAssetDisabledBy] = useState("")
  const [assetDisabledReason, setAssetDisabledReason] = useState("")
  const [remarks, setRemarks] = useState("")

  const toast = useRef(null);
  const errorRef = useRef(null);

  const updateList = () => fetchData()

  const showToast = (data) => showToastSuccess(data)

  const updateAssetDialogMap = {
    updateAssetDisplay: setUpdateAssetDisplay,
  };

  const disableAssetDialogMap = {
    disableAssetDisplay: setDisableAssetDisplay,
  };

  const onClick = async (name) => {
    setEnableCalendar(false); //testing
    // <------ Get asset details by id -----  >>
    await LabServices.getAssetById(assetId).then((res) => {
      setAssetName(res.data.asset_name);
      setRemarks(res.data.remarks)
      setManufacturer({ name: res.data.manufacturer });
      let cmdbTagsObj = res.data.cmdbTags.map(e => {
        return { tag: e }
      })
      setCmdbTags(cmdbTagsObj);
      if (res.data.bmc_ip) {
        isBMC_IPValid(res.data.bmc_ip)
        setBmc_ip(res.data.bmc_ip);
      }
      setSerial_no(res.data.serial_no);

      if (res.data.enabled && res.data.asset_disabled_date) {
        setIsFutureDisable(true)
        setEnableAsset(false);
      }
      else {
        setIsFutureDisable(false)
        setEnableAsset(res.data.enabled);
      }
      setGrade({ name: res.data.classifier });
      setModel({ name: res.data.server_model_name });
      setIsValidated(false);
      if (serverModelsList[res.data.manufacturer]) {
        let specificModels = serverModelsList[res.data.manufacturer].map((item) => {
          return { name: item.name }
        })
        setModelsList(specificModels)
      }

      if (serverModelsList[res.data.manufacturer]) {
        let modelIdList = serverModelsList[res.data.manufacturer].map(item => item.asset_server_model_id)
        setModelIds(modelIdList);
      }


      //testing
      let strikeOutDate = res.data.asset_disabled_strike_out_date.map((date) => {
        return new Date(date);
      });
      setStrikeOutDates(strikeOutDate);
      if (res.data.enabled === false) {
        setIsAlreadyDisabled(true);
        setDate(
          moment(res.data.asset_disabled_date).format("YYYY-MM-DD")
        );

        let message = `Physical Asset disabled from ${moment(res.data.asset_disabled_date).format("MM-DD-YYYY")} `;
        setDateDisableMessage(message);
        setDisabledField(false);
        setAssetDisabledBy(res.data.disabled_by)
        setAssetDisabledReason(res.data.reason_for_disable)
      } else if (res.data.enabled && res.data.asset_disabled_date) {
        setIsAlreadyDisabled(true);
        setDate(
          moment(res.data.asset_disabled_date).format("YYYY-MM-DD")
        );

        let message = `Physical Asset will be disabled from ${moment(res.data.asset_disabled_date).format("MM-DD-YYYY")} `;
        setDateDisableMessage(message);
        setDisabledField(false);
        setAssetDisabledBy(res.data.disabled_by)
        setAssetDisabledReason(res.data.reason_for_disable)
      }
      else if (res.data.enabled && res.data.asset_disabled_date === null) {
        setDateDisableMessage("");
        setDisabledField(true);
        setIsAlreadyDisabled(false);
        setDate(null);
      }

    });


    // fetch all CMDB tags for dropdown
    await LabServices.getCMDBTags()
      .then((res) => {
        let tagsList = res.data.map(e => (
          { tag: e.cmdbTag }
        ))
        setCmdbTagList(tagsList);
      })
      .catch((error) => {
        loglevel.error("Error while fetching tag list", error);
      });



    await LabServices.getGradeList()
      .then((res) => {
        let gradeListObj = res.data.map(e => {
          return { name: e }
        })
        setGradeList(gradeListObj)
      })
      .catch((error) => {
        loglevel.error("Error while fetching grades list", error);
      });

    updateAssetDialogMap[`${name}`](true);


  }

  const onHide = (name) => {
    setAssetName("");
    setCmdbTags([]);
    setIsValidated(false);
    setIsAddTagVisible(false);
    setIsAddManufacturerVisible(false);
    setIsAddModelVisible(false);
    setIsAddGradeVisible(false);
    setAssetErrorMsg('');
    setAssetDisabledBy("")
    setAssetDisabledReason("")
    setRemarks("");
    updateAssetDialogMap[`${name}`](false);
  };

  const accept = async (requestBody) => {
    await LabServices.updatePhysicalAsset(assetId, requestBody)
      .then((response) => {
        if (response.status === 200) {
          updateAssetDialogMap["updateAssetDisplay"](false);
          showToast({
            summary: "Physical Asset Successfully updated",
            detail: "Physical Asset has been successfully updated."
          })
          updateList()
        } else {
          loglevel.debug("Physical Asset updation Failed");
        }
      })
      .catch((error) => {
        loglevel.error("Physical Asset updation failure", error.response.data);
        if (error.response.status === 400) {
          setAssetErrorMsg(error.response.data.msg);
        }
      })
    setNewGrade('');
  };

  const onUpdate = (e) => {
    setIsValidated(true);
    <Toast ref={toast} position="top-center" />;

    let modelId = modelIds[modelsList.findIndex((e, index) => {
      return e.name === model.name
    })]

    let cmdbTagNames = cmdbTags.map(item => item.tag)
    if (assetName && cmdbTagNames.length && bmc_ip && serial_no && manufacturer && modelId && (grade.name || newGrade)) {
      let flag = true
      if (!enableAsset) {
        if (assetDisabledReason) {
          flag = true
        } else {
          flag = false
        }
      }
      if (flag) {
        const requestBody = {
          asset_name: assetName,
          cmdbTags: cmdbTagNames,
          bmc_ip: bmc_ip,
          serial_no: serial_no,
          manufacturer: manufacturer.name,
          asset_model_id: modelId,
          classifier: newGrade || grade.name,
          enabled: (!!enableAsset || !!isFutureDisable),
          asset_disabled_date: (!!enableAsset || isFutureDisable) && date !== null || (!!enableAsset === false && date) ? date : null,
          reason_for_disable: assetDisabledReason,
          disable_by: parseInt(localStorage.getItem('users_id')),
          remarks: remarks
        }

        var confirmationMsg = `Want to update the asset?`;

        confirmPopup({
          target: e.currentTarget,
          message: parse(`${confirmationMsg}`),
          icon: "pi pi-exclamation-triangle",
          className: "popup",
          acceptLabel: "YES",
          rejectLabel: "NO",
          accept: () => accept(requestBody),
        });
      }
    }
  };

  const OnManufacturerSelect = (e) => {

    if (e.target.value) {
      setManufacturer(e.target.value)
      let specificModels = serverModelsList[e.target.value.name].map(e => {
        return { name: e.name }
      })
      let modelIdList = serverModelsList[e.target.value.name].map(e => e.asset_server_model_id)
      setModelsList(specificModels)
      setModelIds(modelIdList)
    }
  }

  const OnAddTag = async () => {

    const requestBody = {
      name: newTag,
      description: ""
    }

    await LabServices.createCmdbTag(requestBody)
      .then((response) => {
        if (response.status === 201) {
          toast.current.show({
            severity: "success",
            summary: "CMDB Tag successfully added",
            detail: "CMDB Tag has been successfully added.",
            life: 6000,
          });
          updateAssetDialogMap["updateAssetDisplay"](true);
        } else {
          loglevel.debug("CMDB Tag not added.");
        }
      })
      .catch((error) => {
        loglevel.debug('Failure at Cmdb tag addition while updating asset.', error.response.data);
        if (error.response.status === 400) {
          setAssetErrorMsg(error.response.data.msg)
        }
      })

    setNewTag("");
    // re-fetch updated CMDB tags for dropdown
    await LabServices.getCMDBTags()
      .then((res) => {
        let tagsList = res.data.map(e => (
          { tag: e.cmdbTag }
        ))
        setCmdbTagList(tagsList);
      })
      .catch((error) => {
        loglevel.error("Error while fetching tag list", error);
      });

    setIsAddTagVisible(false);
  }

  const onNewGradeSelect = (e) => {
    setGrade('')
    setNewGrade(e.target.value)
  }

  const onNewGradeClose = () => {
    setIsAddGradeVisible(false)
    setNewGrade('')
  }

  const isBMC_IPValid = (value) => {
    if ((isIPV4Address(value) === false)) {
      setIsBMCIP(false)
    }
    else {
      setIsBMCIP(true)
    }
  }

  const onSelectDate = (e) => {
    var message = `Physical Asset will be disabled from ${moment(e.value).format("MM-DD-YYYY")}`;
    setDateDisableMessage(message);
    setIsEmptyDate(false);
  };

  const onBMC_IPChange = (e) => {
    let regexEmail = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    setBmc_ip(e.target.value)
    if (!regexEmail.test(e.target.value)) {
      setIsBMCIP(false)
      return;
    }
    else {
      setIsBMCIP(true)
    }

    isBMC_IPValid(e.target.value)


  }

  const onSerialNoChange = (e) => {
    setSerial_no(e.target.value)
  }


  const onDisableAsset = (name) => {
    setEnableAsset(false);
    setDisabledField(false);
    setEnableCalendar(true);
    setIsEmptyDate(true);
    disableAssetDialogMap[`${name}`](false);
  };

  const onChangeSwitch = async (e) => {
    if (e.value === false && isAlreadyDisabled === false) {
      await LabServices.getLabListForAsset(assetId)
        .then((res) => {
          if (res.data.length > 0) {
            setIsScheduledLabs(true);
          } else {
            setIsScheduledLabs(false);
          }
          setDisableScheduled(res.data);
        })
        .catch((error) => {
          loglevel.error(error);
        });

      disableAssetDialogMap["disableAssetDisplay"](true);
    } else if (e.value === false && isAlreadyDisabled) {
      setDisabledField(false);
      setEnableAsset(e.value);
    } else if (e.value && isAlreadyDisabled) {
      setEnableCalendar(false);
      setDateDisableMessage("");
      setDate(null);
      setDisabledField(true);
      setEnableAsset(e.value);
    } else {
      setEnableCalendar(false);
      setIsEmptyDate(false);
      setDateDisableMessage("");
      setDate(null);
      setDisabledField(true);
      setEnableAsset(e.value);
    }
  };

  const renderFooter = (name) => {
    return (
      <div>
        <Button
          className="calender-button"
          style={{ background: "#49AFD9" }}
          onClick={(e) => onUpdate(e)}
        >
          <span className="name">UPDATE ASSET</span>
        </Button>
      </div>
    );
  };

  const disableAssetRenderFooter = (name) => {
    return (
      <div className="disable-footer-button">
        <div>
          <Button
            label="DISABLE ASSET"
            className={
              "p-button-outlined p-button-danger"
            }
            onClick={() => onDisableAsset("disableAssetDisplay")}

          />
        </div>
        <div>
          {" "}
          <Button
            label="CANCEL"
            onClick={onCancel}
            className="p-button-outlined p-button-primary"
          />
        </div>
      </div>
    );
  };

  const disableAssetOnHide = (name) => {
    disableAssetDialogMap[`${name}`](false);
    setEnableAsset(true);
  };

  const onCancel = () => {
    setEnableAsset(true);
    setEnableCalendar(false);
    setDateDisableMessage("");
    disableAssetDialogMap["disableAssetDisplay"](false);
  };

  const onAssetDisableReasonEdit = (e) => {
    setAssetDisabledReason(e.target.value)
  }

  const onRemarkEdit = (e) => {
    setRemarks(e.target.value)
  }


  return (
    <div className=".asset-panel-content">
      <Toast ref={toast} position="top-center" />
      <div className=".asset-panel">
        <div className=".asset-panel-item">
          <div className={(!enabled && deleted) ? "update-button" : ""}>
            <Button
              id="reset-button"
              onClick={() => onClick("updateAssetDisplay")}
              icon="pi pi-pencil"
              className="p-button-rounded p-button-success mr-2"
              disabled={!enabled && deleted}
            />
          </div>
        </div>
      </div>
      <Dialog
        header={`Update Asset : ${assetNameProp}`}
        visible={updateAssetDisplay}
        footer={renderFooter("updateAssetDisplay")}
        onHide={() => onHide("updateAssetDisplay")}
        className="add-asset-dialog-box"
      >
        <div ref={errorRef} className={assetErrorMsg ? "" : "hidden"}>
          {assetErrorMsg && (
            <Message
              severity="warn"
              text={assetErrorMsg}
              className="common-inline-error common-error-message p-mb-3"
            />
          )}
        </div>

        {/*  --------------------------Asset Name:------------------------------ */}
        <div className="asset-input-text">
          <div className="asset-label">
            Asset Name<span className="asset-required-field">*</span> :
          </div>
          <div>
            <InputText
              value={assetName}
              onChange={(e) => setAssetName(e.target.value)}
              placeholder="Enter Asset Name"
              maxLength={20}
              className={
                !assetName && isValidated
                  ? "asset-input-error asset-text-box"
                  : "asset-text-box"
              }
            />
            {!assetName && isValidated ? (
              <div className="cat-error-message-inline">
                <small className="p-error p-d-block" id="error-message">
                  Asset name is mandatory
                </small>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/*  --------------------------Serial No.:------------------------------- */}
        <div className="asset-input-text">
          <div className="asset-label">
            Serial No.<span className="asset-required-field">*</span> :
          </div>
          <div>
            <InputText
              value={serial_no}
              onChange={(e) => onSerialNoChange(e)}
              placeholder="Enter Serial No."
              className={
                !serial_no && isValidated
                  ? "asset-input-error asset-text-box"
                  : "asset-text-box"
              }
            />
            {!serial_no && isValidated ? (
              <div className="cat-error-message-inline">
                <small className="p-error p-d-block" id="error-message">
                  Serial No. is mandatory
                </small>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/*  ---------------------------CMDB tags:------------------------------ */}
        <div className="asset-input-text">
          <div className="asset-label">
            CMDB tags<span className="asset-required-field">*</span> :
          </div>
          <div className="tags-container">
            <div>
              <MultiSelect
                showSelectAll={false}
                value={cmdbTags}
                name="asset-dropdown"
                options={cmdbTagList}
                optionLabel="tag"
                onChange={(e) => setCmdbTags(e.value)}
                placeholder="Select tags"
                className={
                  !cmdbTags.length && isValidated
                    ? "asset-input-error asset-dropdown"
                    : "asset-dropdown"
                  // ? "lab-dropdown asset-input-error p-invalid block select-cmdb"
                  // : "lab-dropdown select-cmdb"
                }
                filter
                filterBy="tag"
                resetFilterOnHide
                display="chip"
              />
              {!cmdbTags.length && isValidated ? (
                <div className="cat-error-message-inline">
                  <small className="p-error p-d-block" id="error-message">
                    CMDB tags are mandatory
                  </small>
                </div>
              ) : (
                ""
              )}
            </div>
            {isAddTagVisible ? (
              <div className="add-tag-ip-btn">
                <div>
                  <InputText
                    //value={reference}
                    onChange={(e) => setNewTag(e.target.value)}
                    // placeholder="Enter Serial No."
                    maxLength={20}
                    className="asset-additional-text-box"
                  // className={
                  //   !reference && isValidated ? "type-input-error" : "asset-text-box"
                  // }
                  />
                </div>
                <div>
                  <Button
                    label="Add"
                    className="assets-tab-add-button"
                    style={{ background: "#49AFD9" }}
                    onClick={() => OnAddTag()}
                  />
                </div>
                <div className="asset-plus-icon">
                  <i
                    className="pi pi-times-circle"
                    style={{ fontSize: "1.4em", color: "#ADBBC4" }}
                    onClick={() => setIsAddTagVisible(false)}
                  ></i>
                </div>
              </div>
            ) : (
              ""
            )}
            {!isAddTagVisible ? (
              <div className="asset-plus-icon">
                <i
                  className="pi pi-plus-circle"
                  style={{ fontSize: "1.4em", color: "#ADBBC4" }}
                  onClick={() => setIsAddTagVisible(true)}
                ></i>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/*  ----------------------------BMC IP:----------------------------- */}
        <div className="asset-input-text">
          <div className="asset-label">
            BMC IP<span className="asset-required-field">*</span> :
          </div>
          <div>
            <InputText
              value={bmc_ip}
              onChange={(e) => onBMC_IPChange(e)}
              keyfilter="num"
              placeholder="Enter BMC IP"
              maxLength={15}
              className={
                (bmc_ip && isBMCIP) || (!bmc_ip && !isValidated)
                  ? "asset-text-box"
                  : "asset-input-error asset-text-box"

              }
            />
            {!bmc_ip && isValidated ? (
              <div className="cat-error-message-inline">
                <small className="p-error p-d-block" id="error-message">
                  BMC IP is mandatory
                </small>
              </div>
            ) : (
              bmc_ip && !isBMCIP ? <div className="cat-error-message-inline">
                <small className="p-error p-d-block" id="error-message">
                  Enter valid BMC IP
                </small>
              </div> : ""
            )}
          </div>
        </div>
        {/*  ---------------------------Manufacturer:------------------------------ */}
        <div className="asset-input-text">
          <div className="asset-label">
            Manufacturer<span className="asset-required-field">*</span> :
          </div>
          <div className="tags-container">
            <div>
              <Dropdown
                value={manufacturer}
                name="asset-dropdown"
                options={manufacturersList}
                onChange={(e) => OnManufacturerSelect(e)}
                placeholder="Choose Manufacturer"
                className={
                  !manufacturer && isValidated
                    ? "asset-input-error asset-dropdown"
                    : "asset-dropdown"
                }
                filter
                optionLabel="name"
                filterBy="name"
                resetFilterOnHide
                display="chip"
                allowDuplicate={false}
              />
              {!manufacturer && isValidated ? (
                <div className="cat-error-message-inline">
                  <small className="p-error p-d-block" id="error-message">
                    Manufacturer is mandatory
                  </small>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>


        {/*  -------------------------- server Model:------------------------------- */}
        <div className="asset-input-text">
          <div className="asset-label">
            Model<span className="asset-required-field">*</span> :
          </div>
          <div className="tags-container">
            <div>
              <Dropdown
                value={model}
                name="asset-dropdown"
                options={modelsList}
                onChange={(e) => setModel(e.target.value)}
                placeholder="Choose Model"
                className={
                  !model && isValidated
                    ? "asset-input-error asset-dropdown"
                    : "asset-dropdown"
                }
                filter
                optionLabel="name"
                filterBy="name"
                resetFilterOnHide
                allowDuplicate={false}
              />
              {!model && isValidated ? (
                <div className="cat-error-message-inline">
                  <small className="p-error p-d-block" id="error-message">
                    Model is mandatory
                  </small>
                </div>
              ) : (
                ""
              )}
            </div>
            {isAddModelVisible ? (
              <div className="add-tag-ip-btn">
                <div>
                  <InputText
                    //value={reference}
                    onChange={(e) => setModel(e.target.value)}
                    // placeholder="Enter Serial No."
                    maxLength={20}
                    className="asset-additional-text-box"
                  // className={
                  //   !reference && isValidated ? "type-input-error" : "asset-text-box"
                  // }
                  />
                </div>
                <div>
                  <Button
                    label="Add"
                    className="assets-tab-add-button"
                    style={{ background: "#49AFD9" }}
                  //onClick={(e) => onAdd(e)}
                  />
                </div>
                <div className="asset-plus-icon">
                  <i
                    className="pi pi-times-circle"
                    style={{ fontSize: "1.4em", color: "#ADBBC4" }}
                    onClick={() => setIsAddModelVisible(false)}
                  ></i>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/*  -------------------------grade/classifier:-------------------------------- */}
        <div className="asset-input-text">
          <div className="asset-label">
            Grade/Classifier<span className="asset-required-field">*</span> :
          </div>
          <div className="tags-container">
            <div>
              <Dropdown
                value={grade}
                name="asset-dropdown"
                options={gradeList}
                disabled={newGrade ? true : false}
                onChange={(e) => setGrade(e.target.value)}
                placeholder="Choose Grade/Classifier"
                className={
                  !(grade || newGrade) && isValidated
                    ? "asset-input-error asset-dropdown"
                    : "asset-dropdown"
                }
                filter
                optionLabel="name"
                filterBy="name"
                resetFilterOnHide
                display="chip"
                allowDuplicate={false}
              />
              {!(grade || newGrade) && isValidated ? (
                <div className="cat-error-message-inline">
                  <small className="p-error p-d-block" id="error-message">
                    Grade/Classifier is mandatory
                  </small>
                </div>
              ) : (
                ""
              )}
            </div>
            {isAddGradeVisible ? (
              <div className="add-tag-ip-btn">
                <div>
                  <InputText
                    //value={grade}
                    onChange={(e) => onNewGradeSelect(e)}
                    // placeholder="Enter new Grade"
                    maxLength={20}
                    className="asset-additional-text-box"
                  />
                </div>
                <div className="asset-plus-icon">
                  <i
                    className="pi pi-times-circle"
                    style={{ fontSize: "1.4em", color: "#ADBBC4" }}
                    onClick={() => onNewGradeClose()}
                  ></i>
                </div>
              </div>
            ) : (
              ""
            )}
            {!isAddGradeVisible ? (
              <div className="asset-plus-icon">
                <i
                  className="pi pi-plus-circle"
                  style={{ fontSize: "1.4em", color: "#ADBBC4" }}
                  onClick={() => setIsAddGradeVisible(true)}
                ></i>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/*  -------------------------Enable:-------------------------------- */}

        <div className="enable-disable-parent-container">
          <div className="enable-disable-lab-container">
            <div className="enable-disable-lab-label">
              {" "}
              Status:<span className="lab-required-field">*</span>
            </div>

            <div className="enable-lab-switch">
              <div>
                <div className="enable-lab-radiobutton">
                  <RadioButton
                    inputId="option1"
                    name="Asset"
                    value={true}
                    onChange={(e) => onChangeSwitch(e)}
                    checked={enableAsset}
                    disabled={disabledField}
                  />
                  <label
                    htmlFor="option1"
                    className={
                      disabledField ? "disable-label" : "radiobutton-label"
                    }
                  >
                    Enable
                  </label>
                </div>
                <div className="disable-radio-btn">
                  <div className="disable-lab-radiobutton">
                    <RadioButton
                      inputId="option2"
                      name="Asset"
                      value={false}
                      onChange={(e) => onChangeSwitch(e)}
                      checked={!enableAsset}
                      disabled={!disabledField}
                    />
                    <label
                      htmlFor="option2"
                      className={
                        !disabledField ? "disable-label" : "radiobutton-label"
                      }
                    >
                      Disable
                    </label>
                  </div>
                  {enableCalendar ? (
                    <div className="disable-lab-calendar">
                      <Calendar
                        id="icon"
                        minDate={new Date()}
                        selectOtherMonths={true}
                        value={date}
                        disabledDates={strikeOutDates}
                        className={
                          isEmptyDate && enableCalendar ? "error-block" : ""
                        }
                        onChange={(e) =>
                          setDate(moment(e.value).format("YYYY-MM-DD"))
                        }
                        onSelect={(e) => onSelectDate(e)}
                        placeholder="Select Date"
                        showIcon
                        readOnlyInput
                      />
                      {isEmptyDate && enableCalendar ? (
                        <div className="error-message-inline">
                          <small className="error-message" id="error-message">
                            To Disable an asset, date is mandatory
                          </small>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="disable-lab-message">
                  {!enableAsset ? dateDisableMessage : ""}
                </div>
              </div>
            </div>
          </div>
          <div className={!enableCalendar && !enableAsset ? "enable-disable-disable-by-container" : "hidden"}>
            <div className="disabled-by-label">
              Disable by<span className="lab-required-field">*</span> :
            </div>
            <div className="disabled-by-value">
              {assetDisabledBy}
            </div>
          </div>
          <div className={!enableAsset ? "enable-disable-reason-container" : "hidden"}>
            <div className="disabled-reason-label">
              Reason for Disable<span className="lab-required-field">*</span> :
            </div>
            <div className="disabled-reason-textbox">
              <InputTextarea
                style={{ width: '220px', height: '64px' }}
                value={assetDisabledReason}
                onChange={(e) => onAssetDisableReasonEdit(e)}
                placeholder="Enter reason for disable"
                maxLength={500}
                className={
                  !assetDisabledReason
                    ? "lab-name-text-box-input-error"
                    : "lab-name-text-box"
                }
              />
              {!assetDisabledReason ? (
                <div className="error-message-inline">
                  <small className="error-message" id="error-message">
                    Reason for disable is mandatory
                  </small>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {/*  -------------------------Remarks:-------------------------------- */}
        <div className="asset-input-text">
          <div className="asset-label">
            Remarks<span className="optional-fields"> (Optional)</span>:
          </div>
          <div>
            <InputTextarea
              style={{ width: "220px", height: "66px" }}
              value={remarks}
              onChange={(e) => onRemarkEdit(e)}
              placeholder="Enter Remarks"
              maxLength={500}
            />
          </div>
        </div>

      </Dialog>
      {/* --------------------------Disabled Asset dialog----------------------- */}
      <Dialog
        header={`Disable Physical Asset : ${assetName}`}
        className="disable-lab-dialog"
        visible={disableAssetDisplay}
        footer={() => disableAssetRenderFooter("disableAssetDisplay")}
        onHide={() => disableAssetOnHide("disableAssetDisplay")}
      >
        <div ref={errorRef} className={assetErrorMsg ? "" : "hidden"}>
          {assetErrorMsg && (
            <Message
              severity="warn"
              text={assetErrorMsg}
              className="common-inline-error common-error-message p-mb-3"
            />
          )}
        </div>
        {isScheduledLabs ? (
          <div>
            <div className="p-mb-2">
              Upcoming Schedules for Labs associated with Physical Asset
            </div>
            <DataTable
              value={disableScheduled}
              // ref={dt}
              columnResizeMode="fit"
              className="disable-lab-crud-table"
              autoLayout={true}
              responsiveLayout="scroll"
            >
              <Column
                field="lab_name"
                header="Lab Name"
                style={{ minWidth: "10rem", paddingLeft: "15px" }}
              ></Column>
              <Column
                field="lab_owner_name"
                header="Lab Owner Name"
                style={{ minWidth: "10rem" }}
              ></Column>
              <Column
                field="schedule_start_date"
                header="Start Date"
                style={{ minWidth: "6.5rem" }}
              ></Column>
              <Column
                field="schedule_end_date"
                header="End Date"
                style={{ minWidth: "5rem" }}
              ></Column>
              <Column
                field="user_time_zone"
                header="Timezone"
                style={{ minWidth: "5rem" }}
              ></Column>
            </DataTable>
            <div className="lab-schedule-warning">
              <div className="lab-warning-icon">
                <svg
                  width="17"
                  height="15.98"
                  viewBox="0 0 18 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.7266 1.06685L17.22 13.0269V13.0469C17.5562 13.6669 17.5409 14.4182 17.1799 15.024C16.8188 15.6299 16.1653 16.0008 15.46 16.0002H2.4733C1.76195 16.0048 1.10172 15.6311 0.739365 15.019C0.377014 14.4068 0.367087 13.6483 0.713297 13.0269L7.2133 1.06685C7.56349 0.423434 8.23742 0.0229492 8.96996 0.0229492C9.70251 0.0229492 10.3764 0.423434 10.7266 1.06685ZM15.46 14.6469C15.6953 14.6468 15.9133 14.5227 16.0333 14.3202C16.154 14.118 16.1591 13.8671 16.0466 13.6602L9.5533 1.70019C9.43692 1.48457 9.21165 1.35017 8.96663 1.35017C8.72161 1.35017 8.49634 1.48457 8.37996 1.70019L1.88663 13.6602C1.77346 13.867 1.77795 14.1182 1.89845 14.3209C2.01895 14.5236 2.23753 14.6475 2.4733 14.6469H15.46ZM8.96663 13.1802C9.51892 13.1802 9.96663 12.7325 9.96663 12.1802C9.96663 11.6279 9.51892 11.1802 8.96663 11.1802C8.41435 11.1802 7.96663 11.6279 7.96663 12.1802C7.96663 12.7325 8.41435 13.1802 8.96663 13.1802ZM9.86663 9.34685C9.86663 9.8255 9.47861 10.2135 8.99996 10.2135C8.76778 10.2135 8.54529 10.1204 8.38237 9.95498C8.21945 9.78955 8.12972 9.56567 8.1333 9.33352V5.33352C8.1333 4.85487 8.52132 4.46685 8.99996 4.46685C9.47861 4.46685 9.86663 4.85487 9.86663 5.33352V9.34685Z"
                    fill="#E9ECEF"
                  />
                </svg>
              </div>
              <div className="lab-warning-msg">
                <div>
                  If you disable the Physical Asset, users won’t be able to
                  schedule the associated labs.
                </div>
                <div>
                  Also, future schedules of the associate labs will be
                  cancelled.
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="no-scheduled-icon">
              <div>
                <svg
                  width="44"
                  height="51"
                  viewBox="0 0 44 51"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.6556 49.8753C20.9977 49.8654 20.3548 49.8613 19.725 49.8315L19.687 50.6404C20.33 50.671 20.9845 50.6817 21.6564 50.685C22.1459 50.685 22.6296 50.6759 23.1042 50.6586L23.0736 49.8481C22.6081 49.8654 22.136 49.8753 21.6556 49.8753Z"
                    fill="#66B0D6"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.4979 42.6001C19.3284 42.6315 18.1802 42.7124 17.0874 42.8419L17.1815 43.6459C18.2504 43.5196 19.3746 43.4404 20.5186 43.4098L20.4979 42.6001Z"
                    fill="#66B0D6"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.85219 46.6353C9.85219 46.5495 9.86788 46.4645 9.89759 46.3811L9.13492 46.1079C9.07385 46.2788 9.04248 46.4562 9.04248 46.6353C9.04248 47.5334 9.79524 48.3324 11.2785 49.0075L11.6136 48.2704C10.4787 47.7538 9.85219 47.1718 9.85219 46.6353Z"
                    fill="#66B0D6"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.5483 43.252C13.2326 43.5285 12.1192 43.8751 11.2368 44.282L11.5752 45.0175C12.4023 44.6361 13.4588 44.3093 14.7142 44.0443L14.5483 43.252Z"
                    fill="#66B0D6"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.7495 49.8364C14.7722 50.0906 15.9096 50.2912 17.1312 50.434L17.2236 49.6292C16.0367 49.4906 14.9348 49.2958 13.9443 49.0498L13.7495 49.8364Z"
                    fill="#66B0D6"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M32.2958 44.4455C31.4465 44.0081 30.3388 43.6325 29.0033 43.3296L28.8242 44.1187C30.0747 44.4026 31.1469 44.765 31.9244 45.1653L32.2958 44.4455Z"
                    fill="#66B0D6"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M26.4669 42.8862C25.3807 42.745 24.2334 42.6526 23.0588 42.6113L23.0308 43.4202C24.1797 43.4615 25.3014 43.5523 26.3629 43.6901L26.4669 42.8862Z"
                    fill="#66B0D6"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M33.3422 46.635C33.3422 47.2244 32.5713 47.8748 31.2275 48.4212L31.5338 49.1706C33.6988 48.2924 34.1519 47.3061 34.1519 46.635L34.1503 46.5698L33.3422 46.635Z"
                    fill="#66B0D6"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M25.5752 49.6725L25.661 50.4781C26.876 50.3502 28.0151 50.1661 29.0468 49.93L28.8661 49.1401C27.8657 49.3688 26.7596 49.5487 25.5752 49.6725Z"
                    fill="#66B0D6"
                  />
                  <mask
                    id="mask0_13387_14263"
                    style={{ "maskType": "alpha" }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="44"
                    height="39"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 0H43.4363V38.8761H0V0Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask0_13387_14263)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M36.8754 8.35797C32.8368 9.35505 27.4543 9.90476 21.7187 9.90476C15.983 9.90476 10.5997 9.35505 6.56108 8.35797C2.03708 7.24038 0.830349 5.95029 0.830349 5.36508C0.830349 4.77987 2.03708 3.4906 6.56108 2.37302C10.5997 1.37511 15.983 0.825397 21.7187 0.825397C27.4543 0.825397 32.8368 1.37511 36.8754 2.37302C41.3994 3.4906 42.6062 4.77987 42.6062 5.36508C42.6062 5.95029 41.3994 7.24038 36.8754 8.35797ZM23.7987 24.4937V36.1961C23.5164 36.6699 22.8759 37.2039 21.9217 37.6092C20.8297 38.0723 20.0159 38.0855 19.6717 37.979V24.4937L3.31232 8.21187C7.15289 9.724 13.9583 10.7302 21.7187 10.7302C29.5145 10.7302 36.348 9.71492 40.1787 8.19124L23.7987 24.4937ZM43.4365 5.36508C43.4365 2.4019 33.7133 0 21.7187 0C9.72317 0 0 2.4019 0 5.36508C0 5.92387 0.346667 6.46203 0.987175 6.968L0.986349 6.96883L18.8463 24.8238V38.4098C19.1013 38.7359 19.566 38.8762 20.1248 38.8762C20.767 38.8762 21.5338 38.6905 22.2461 38.3884C23.419 37.8898 24.4672 37.0669 24.6224 36.2729V24.8238L42.4848 6.96883L42.4683 6.95314C43.0973 6.4513 43.4365 5.9181 43.4365 5.36508Z"
                      fill="#66B0D6"
                    />
                  </g>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.56115 2.37265C2.03715 3.49023 0.82959 4.7795 0.82959 5.36471C0.82959 5.95074 2.03715 7.24001 6.56115 8.3576C9.14794 8.99646 12.2894 9.4496 15.7197 9.69392L12.7574 1.31201C10.4834 1.57036 8.38032 1.92363 6.56115 2.37265Z"
                    fill="#66B0D6"
                    fillOpacity="0.4"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.5003 10.5367L22.2178 24.4867V37.4628C23.0011 37.0798 23.5483 36.6168 23.7992 36.1958V24.4933L40.1784 8.19092C37.3316 9.32336 32.8258 10.1743 27.5003 10.5367Z"
                    fill="#66B0D6"
                    fillOpacity="0.4"
                  />
                </svg>
              </div>
              <div className="lab-scheduled-msg">
                We couldn’t find any lab Schedule!
              </div>
            </div>
            <div className="lab-schedule-warning">
              <div className="lab-warning-icon">
                <svg
                  width="17"
                  height="15.98"
                  viewBox="0 0 18 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.7266 1.06685L17.22 13.0269V13.0469C17.5562 13.6669 17.5409 14.4182 17.1799 15.024C16.8188 15.6299 16.1653 16.0008 15.46 16.0002H2.4733C1.76195 16.0048 1.10172 15.6311 0.739365 15.019C0.377014 14.4068 0.367087 13.6483 0.713297 13.0269L7.2133 1.06685C7.56349 0.423434 8.23742 0.0229492 8.96996 0.0229492C9.70251 0.0229492 10.3764 0.423434 10.7266 1.06685ZM15.46 14.6469C15.6953 14.6468 15.9133 14.5227 16.0333 14.3202C16.154 14.118 16.1591 13.8671 16.0466 13.6602L9.5533 1.70019C9.43692 1.48457 9.21165 1.35017 8.96663 1.35017C8.72161 1.35017 8.49634 1.48457 8.37996 1.70019L1.88663 13.6602C1.77346 13.867 1.77795 14.1182 1.89845 14.3209C2.01895 14.5236 2.23753 14.6475 2.4733 14.6469H15.46ZM8.96663 13.1802C9.51892 13.1802 9.96663 12.7325 9.96663 12.1802C9.96663 11.6279 9.51892 11.1802 8.96663 11.1802C8.41435 11.1802 7.96663 11.6279 7.96663 12.1802C7.96663 12.7325 8.41435 13.1802 8.96663 13.1802ZM9.86663 9.34685C9.86663 9.8255 9.47861 10.2135 8.99996 10.2135C8.76778 10.2135 8.54529 10.1204 8.38237 9.95498C8.21945 9.78955 8.12972 9.56567 8.1333 9.33352V5.33352C8.1333 4.85487 8.52132 4.46685 8.99996 4.46685C9.47861 4.46685 9.86663 4.85487 9.86663 5.33352V9.34685Z"
                    fill="#E9ECEF"
                  />
                </svg>
              </div>
              <div className="lab-warning-msg">
                If you disable the Physical Asset, users won’t be able to
                schedule the associated lab.
              </div>
            </div>
          </div>
        )}
      </Dialog>
      {/* ------------------------------------------------- */}
    </div>

  );
};

export default UpdateAsset;
