import React, { useState } from 'react'
import PhysicalAssets from '../rbac/inventory-mgmt/physical-asset-crud/PhysicalAssets';
import ServerModels from '../rbac/inventory-mgmt/server-model-crud/ServerModels';
import CMDBTag from "../rbac/inventory-mgmt/cmdb-tags-crud/CMDBTag";
import './Admin.css'
import VirtualEnvironment from '../rbac/inventory-mgmt/virtual-environment/VirtualEnvironment';
import AssetScheduleTab from '../rbac/inventory-mgmt/AssetScheduleTab';

import { useGlobalState } from '../../config/state';
import OSTypes from '../rbac/inventory-mgmt/os-type-crud/OSType';

//Inventory management  Tabs
const InventoryMgmt = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isAdmin, setIsAdmin] = useGlobalState('isAdmin');

  const setIndex = (index) => {
    setActiveIndex(index);
  }

  return (
    <div>
      {(isAdmin) ? (
        <>
          <div className="tabmenu">
            {/* Physical Asset Tab */}
            <button
              className="menuitem-link"
              style={{
                borderColor:
                  activeIndex === 0
                    ? "#979797 #979797 #192a32 #979797" // Border colour of tab when it is active
                    : "#192a32 #192a32 #979797 #192a32",// Border colour of tab when it is inactive
              }}
              onClick={() => setIndex(0)} // on click Physical Asset tab
              data-testid="all-labs-title"
            >
              <div className={activeIndex === 0 ? "active-tab" : "inactive-tab"}> Physical Assets</div>
            </button>
            {/* Virtual Environment Tab */}
            <button
              className="menuitem-link"
              style={{
                borderColor:
                  activeIndex === 1
                    ? "#979797 #979797 #192a32 #979797" // Border colour of tab when it is active
                    : "#192a32 #192a32 #979797 #192a32", // Border colour of tab when it is inactive
              }}
              onClick={() => setIndex(1)} // on click  Virtual Environments tab
            >
              <div className={activeIndex === 1 ? "active-tab" : "inactive-tab"}> Virtual Environments</div>
            </button>

            {/* Server Model Tab */}
            <button
              className="menuitem-link"
              style={{
                borderColor:
                  activeIndex === 2
                    ? "#979797 #979797 #192a32 #979797" // Border colour of tab when it is active
                    : "#192a32 #192a32 #979797 #192a32", // Border colour of tab when it is inactive
              }}
              onClick={() => setIndex(2)} // on click Server model Tab
              data-testid="all-labs-title"
            >
              <div className={activeIndex === 2 ? "active-tab" : "inactive-tab"}> Server Models</div>
            </button>

            {/* CMDB Tags tab */}
            <button
              className="menuitem-link"
              style={{
                borderColor:
                  activeIndex === 3
                    ? "#979797 #979797 #192a32 #979797" // Border colour of tab when it is active
                    : "#192a32 #192a32 #979797 #192a32", // Border colour of tab when it is inactive
              }}
              onClick={() => setIndex(3)} //on click CMDB Tags Tab
              data-testid="all-labs-title"
            >
              <div className={activeIndex === 3 ? "active-tab" : "inactive-tab"}> CMDB Tags</div>
            </button>

            {/* Asset Schedules Tab */}
            <button
              className="menuitem-link"
              style={{
                borderColor:
                  activeIndex === 4
                    ? "#979797 #979797 #192a32 #979797" // Border colour of tab when it is active
                    : "#192a32 #192a32 #979797 #192a32", // Border colour of tab when it is inactive
              }}
              onClick={() => setIndex(4)} //on click Asset Schedules Tab
              data-testid="all-labs-title"
            >
              <div className={activeIndex === 4 ? "active-tab" : "inactive-tab"}> Asset Schedule</div>
            </button>

            {/* OS Type Tab */}
            <button
              className="menuitem-link"
              style={{
                borderColor:
                  activeIndex === 5
                    ? "#979797 #979797 #192a32 #979797" // Border colour of tab when it is active
                    : "#192a32 #192a32 #979797 #192a32", // Border colour of tab when it is inactive
              }}
              onClick={() => setIndex(5)} //on click Asset Schedules Tab
              data-testid="all-labs-title"
            >
              <div className={activeIndex === 4 ? "active-tab" : "inactive-tab"}> OS Type</div>
            </button>
          </div>
          <div className="data">
            {activeIndex === 0 ? <PhysicalAssets /> : ""} {/*Physical Asset Tab */}
            {activeIndex === 1 ? <VirtualEnvironment /> : ""} {/*Virtual Environment Tab */}
            {activeIndex === 2 ? <ServerModels /> : ""} {/*Server Models Tab */}
            {activeIndex === 3 ? <CMDBTag /> : ""} {/*CMDB Tags Tab */}
            {activeIndex === 4 ? <AssetScheduleTab /> : ""} {/* Asset Schedules Tab */}
            {activeIndex === 5 ? <OSTypes /> : ""} {/* OS Type Tab */}
          </div>
        </>
      ) : (
        window.location.assign("/not-found")
      )}
    </div>
  )
}

export default InventoryMgmt