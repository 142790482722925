import { Button } from "primereact/button";
import React, { useState, useEffect, useRef } from "react";
import "../labs/LabCard.css";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { Dialog } from "primereact/dialog";
import LabServices from "../../services/LabServices";
import loglevel from "../../logger/Logger";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { confirmPopup } from "primereact/confirmpopup";
import eventBus from "../utility/EventBus";
import { Message } from "primereact/message";
import { Dropdown } from 'primereact/dropdown';
import { ConfirmPopup } from "primereact/confirmpopup";
import { Tooltip } from "primereact/tooltip";

const ShareLab = (lab) => {
  const [displayBasic, setDisplayBasic] = useState(false);
  const [permission, setPermission] = useState("Lab User");
  const [activeIndex, setActiveIndex] = useState(0);
  const [sharedList, setSharedList] = useState(null);
  const [tooltipUsers, setTooltipUsers] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [email, setEmail] = useState("");
  const [commonErrorMsg, setCommonErrorMsg] = useState("");
  const toast = useRef(null);
  const dt = useRef(null);
  const errorRef = useRef();
  const labs = lab.lab;
  const perVal = ["Lab User", "Co-Owner"]


  useEffect(() => {
    async function fetchData() {
      var tooltipData = " "
      // api call for list of shared user
      await LabServices.getSharedUsersList(labs.schedule_id)
        .then((res) => {
          loglevel.debug("getSharedUsersList Response", res);
          setSharedList(res.data);
          for (let i = 0; i < res.data.length; i++) {
            tooltipData += res.data[i].email + ' (Permission: ' + res.data[i].permission + ") "
          }
          tooltipData = "This lab is shared with users: " +tooltipData
          setTooltipUsers(tooltipData);
        })
        .catch((error) => {
          loglevel.error("getSharedUsersList", error);
        });
    }
    fetchData();
  }, []);

  // for updating notification
  const updateHeaderNotification = () => {
    eventBus.dispatch("notificationCount", { message: "notificationCount" });
  };

  const dialogFuncMap = {
    displayBasic: setDisplayBasic,
  };

  // on click on share button
  const onClick = (name) => {
    setPermission("Lab User");
    setEmail("");
    setCommonErrorMsg("");
    dialogFuncMap[`${name}`](true);
  };

  // hiding dialog box
  const onHide = (name) => {
    setEmail("");
    setPermission("Lab User");
    setEmailError(false);
    setEmailErrorMsg("");
    dialogFuncMap[`${name}`](false);
    setActiveIndex(0);
    setCommonErrorMsg("");
  };

  const onShareLab = async () => {
    // if email is empty
    if(!email){
      setEmailError(true);
      setEmailErrorMsg("Email is mandatory");
    }
    if(email){
      // reuest body for sharing
    const requestBody = {
      schedule_id: labs.schedule_id,
      email: email,
      permission: permission,
      users_id: parseInt(localStorage.getItem("users_id")),
    };
    await LabServices.shareLab(requestBody)
      .then((response) => {
        if (response.status === 201) {
          // toast msg after successful lab sharing
          toast.current.show({
            severity: "success",
            summary: "Lab Successfully Shared",
            detail: `${response.data}`,
            life: 6000,
          });
          updateHeaderNotification();
          if (labs.permission === "Co-Owner")
            sharedLabCoOwnerCount();
          else sharedLabCount();
          setActiveIndex(1);
          setEmail("");
        } else {
          loglevel.debug("Lab sharing failed");
        }
        setCommonErrorMsg("");
      })
      .catch((error) => {
        loglevel.error("scheduleLab", error);
        if (error.response.status === 400) {
          setCommonErrorMsg(error.response.data.msg);
          // scrolling to top of dialog for error
          errorRef.current.scrollIntoView({ behavior: "smooth" });
        }
      });

      // refreshing list of user who has shared the lab
    await LabServices.getSharedUsersList(labs.schedule_id)
      .then((res) => {
        setSharedList(res.data);
      })
      .catch((error) => {
        loglevel.error("getSharedUsersList", error);
      });
    }
  };

  const renderFooter = (name) => {
    return (
      <div className="share-lab-reset-button">
        {/* for SHARE MY LAB tab footer button */}
        {activeIndex === 0 ? (
          <Button
            id="reset-button"
            label="Share lab"
            className="p-button-primary"
            onClick={() => onShareLab()}
          />
        ) : (
          // for SHARED WITH lab footer button
          <Button
            id="reset-button"
            label="Close"
            className="p-button-primary"
            onClick={() => onHide(name)}
          />
        )}
      </div>
    );
  };

  // SHARE MY TAB tab 
  const setIndex0 = (e) => {
    setActiveIndex(0);
    setEmail("");
    setCommonErrorMsg("");
  };

  // SHARED WITH tab
  const setIndex1 = (e) => {
    setActiveIndex(1);
    setCommonErrorMsg("");
  };

  // confirmation popup for removing user from share lab list
  const removeConfirm = (event, rowData) => {
    console.log(event, "working")
    confirmPopup({
      target: event.currentTarget,
      message: `Want to unshare the lab with "${rowData.email}" ?`,
      icon: "pi pi-exclamation-triangle",
      className: "popup",
      accept: () => deleteSharedUser(rowData),
    });
  };

  const deleteSharedUser = async (e) => {
    // api call on deleting user
    await LabServices.deleteSharedUser(e.id, labs.schedule_id)
      .then((res) => {
        if (res.status === 200) {
          toast.current.show({
            severity: "success",
            summary: "User Removed!",
            detail: `${res.data}`,
            life: 6000,
          });
          updateHeaderNotification();
          if (labs.permission === "Co-Owner") {
            sharedLabCoOwnerCount();
          } else {
            sharedLabCount();
          }
        } else {
          loglevel.debug("Delete user is Failed");
        }
        setCommonErrorMsg("");
      })
      .catch((error) => {
        setCommonErrorMsg(error.response.data.msg);
        errorRef.current.scrollIntoView({ behavior: "smooth" });
      });
   // refreshing list after deletion
    await LabServices.getSharedUsersList(labs.schedule_id)
      .then((res) => {
        setSharedList(res.data);
      })
      .catch((error) => {
        loglevel.error("getSharedUsersList", error);
      });
  };

  // updating count for share lab having permission as Lab User
  const sharedLabCount = () => {
    console.log("applying");
    eventBus.dispatch("sharedLabCountApply", {
      message: "sharedLabCountApply",
    });
  };

  // updating count for share lab having permission as co-owner
  const sharedLabCoOwnerCount = () => {
    eventBus.dispatch("sharedLabCoOwnerCountApply", {
      message: "sharedLabCoOwnerCountApply",
    });
  };


  const onEdit = async (e) => {
  console.log(e,"share")
   if(e.data.email === e.newData.email && e.data.permission!==e.newData.permission ||e.data.email !== e.newData.email && e.data.permission===e.newData.permission || e.data.email !== e.newData.email && e.data.permission!==e.newData.permission ){
    await LabServices.shareLabEditPermission(e.newData.id, labs.schedule_id,e.newData.email, e.newData.permission)
      .then((res) => {
        console.log(res,"ressssss")
        if(res.status ===200){
        if(e.data.email === e.newData.email && e.data.permission!==e.newData.permission){
        toast.current.show({
          severity: "success",
          summary:"Permission Updated Successfully",
          detail: `Permission has been Updated Successfully`,
          life: 6000,
        })
      };
      if(e.data.email !== e.newData.email && e.data.permission===e.newData.permission){
        toast.current.show({
          severity: "success",
          summary:"Email Updated Successfully",
          detail: `Email has been Updated Successfully`,
          life: 6000,
        })
      }
      if(e.data.email !== e.newData.email && e.data.permission!==e.newData.permission){
        toast.current.show({
          severity: "success",
          summary:"Email and Permission Updated Successfully",
          detail: `Email and Permission have been Updated Successfully`,
          life: 6000,
        })
      }
    }
      })
      .catch((error) => {
        loglevel.error("getSharedUsersList", error);
      });
    }
    if (e.newData.permission === "Lab User" && e.data.permission === "Co-Owner" && localStorage.getItem("email") === e.data.email) {
      sharedLabCount()
      sharedLabCoOwnerCount()
    } else {
      await LabServices.getSharedUsersList(labs.schedule_id)
        .then((res) => {
          setSharedList(res.data);
        })
        .catch((error) => {
          loglevel.error("getSharedUsersList", error);
        });
    }

  };


  const onEmailChange = (e) => {
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    setEmail(e.target.value);
    if (!regexEmail.test(e.target.value)) {
      setEmailError(true);
      setEmailErrorMsg("Enter a valid email address");
      return;
    } else {
      setEmailError(false);
      setEmailErrorMsg("");
    }
  };

  const onEmailChangeEditor = (e) => {
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    //setEmail(e.target.value);
    if (!regexEmail.test(e)) {
      setEmailError(true);
      setEmailErrorMsg("Enter a valid email address");
      return false;
    } else {
      setEmailError(false);
      setEmailErrorMsg("");
    }
  };

  const statusBodyTemplate = (rowdata) => {
    return (
      <div className="shared-lab-status">
        {rowdata.status ? (
          // registered user
          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.57009 0C3.95676 0 0.216919 3.58172 0.216919 8C0.216919 12.4183 3.95676 16 8.57009 16C13.1834 16 16.9233 12.4183 16.9233 8C16.9233 5.87827 16.0432 3.84344 14.4767 2.34315C12.9101 0.842855 10.7855 0 8.57009 0ZM8.57009 14.6667C4.72565 14.6667 1.60912 11.6819 1.60912 8C1.60912 4.3181 4.72565 1.33333 8.57009 1.33333C12.4145 1.33333 15.5311 4.3181 15.5311 8C15.5311 9.76811 14.7977 11.4638 13.4922 12.714C12.1868 13.9643 10.4163 14.6667 8.57009 14.6667ZM3.93408 8.49333L7.41457 11.8267L13.1156 6.36667C13.3521 6.10216 13.3362 5.70787 13.0791 5.46163C12.822 5.21538 12.4103 5.20015 12.1341 5.42667L7.41457 9.94667L4.91558 7.55333C4.63939 7.32682 4.2277 7.34205 3.97058 7.58829C3.71346 7.83454 3.69756 8.22883 3.93408 8.49333Z" fill="#60B515" />
          </svg>
        ) : (
          // not registerd in portal
          <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.3289 1.06667L18.1089 13.0267V13.0467C18.4599 13.6667 18.444 14.418 18.067 15.0239C17.69 15.6297 17.0076 16.0006 16.2712 16H2.7112C1.96846 16.0046 1.27907 15.631 0.900726 15.0188C0.522378 14.4066 0.512014 13.6481 0.873507 13.0267L7.66046 1.06667C8.02611 0.423251 8.72979 0.0227661 9.49467 0.0227661C10.2596 0.0227661 10.9632 0.423251 11.3289 1.06667ZM16.2712 14.6467C16.5169 14.6466 16.7445 14.5225 16.8698 14.32C16.9958 14.1178 17.0011 13.8669 16.8837 13.66L10.1038 1.7C9.98224 1.48439 9.74703 1.34998 9.49119 1.34998C9.23536 1.34998 9.00014 1.48439 8.87863 1.7L2.09864 13.66C1.98047 13.8668 1.98516 14.1181 2.11098 14.3207C2.2368 14.5234 2.46502 14.6473 2.7112 14.6467H16.2712ZM9.49119 13.18C10.0679 13.18 10.5353 12.7323 10.5353 12.18C10.5353 11.6277 10.0679 11.18 9.49119 11.18C8.91453 11.18 8.44705 11.6277 8.44705 12.18C8.44705 12.7323 8.91453 13.18 9.49119 13.18ZM10.4309 9.34667C10.4309 9.82532 10.0258 10.2133 9.526 10.2133C9.28356 10.2134 9.05126 10.1202 8.88114 9.9548C8.71103 9.78937 8.61734 9.56549 8.62107 9.33333V5.33334C8.62107 4.85469 9.02622 4.46667 9.526 4.46667C10.0258 4.46667 10.4309 4.85469 10.4309 5.33334V9.34667Z" fill="#FDD006" />
          </svg>
        )}
      </div>
    );
  };

  const rowEditorTemplate = (rowData, props) => {
    const rowEditor = props.rowEditor;
    if (rowEditor.editing) {
      return (<> {/* Tick button to confirm to change permission or email  */}
      <div onClick={rowEditor.onSaveClick} className={rowEditor.saveClassName}>
        <span className='p-clickable' >
          <svg width="30" height="20" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.99992 13.2501C5.89921 13.2467 5.80025 13.2228 5.70911 13.1798C5.61798 13.1368 5.53658 13.0757 5.46992 13.0001L0.469915 8.00009C0.374583 7.93828 0.29454 7.85562 0.235828 7.75835C0.177117 7.66108 0.141273 7.55174 0.131001 7.43859C0.120729 7.32544 0.136299 7.21143 0.176535 7.10518C0.216771 6.99893 0.280621 6.90321 0.363264 6.82524C0.445908 6.74728 0.545185 6.68911 0.653599 6.65512C0.762014 6.62114 0.876732 6.61223 0.989093 6.62907C1.10145 6.64591 1.20852 6.68806 1.30221 6.75233C1.3959 6.8166 1.47376 6.90132 1.52992 7.00009L5.99992 11.4401L16.4699 1.00009C16.6109 0.9087 16.7784 0.867279 16.9457 0.882467C17.113 0.897654 17.2704 0.968571 17.3926 1.08385C17.5148 1.19913 17.5947 1.3521 17.6196 1.51823C17.6445 1.68437 17.6129 1.85405 17.5299 2.00009L6.52991 13.0001C6.46325 13.0757 6.38185 13.1368 6.29072 13.1798C6.19958 13.2228 6.10062 13.2467 5.99992 13.2501Z" fill="#49AFD9" />
        </svg>
        </span>
      </div>
        {/* X button after click on edit button to edit share lab email or prmission */}
        <div type="button" onClick={rowEditor.onCancelClick} className={rowEditor.cancelClassName}>
          <span className='p-clickable'><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.06012 5.99996L11.4801 1.57996C11.5538 1.5113 11.6129 1.4285 11.6539 1.3365C11.6949 1.2445 11.7169 1.14518 11.7187 1.04448C11.7205 0.943778 11.702 0.843749 11.6642 0.750361C11.6265 0.656973 11.5704 0.572139 11.4992 0.50092C11.4279 0.429701 11.3431 0.373557 11.2497 0.335836C11.1563 0.298115 11.0563 0.27959 10.9556 0.281367C10.8549 0.283144 10.7556 0.305186 10.6636 0.346177C10.5716 0.387169 10.4888 0.446272 10.4201 0.519958L6.00012 4.93996L1.58012 0.519958C1.43795 0.387478 1.2499 0.315355 1.0556 0.318783C0.861298 0.322211 0.675912 0.400924 0.538499 0.538337C0.401086 0.67575 0.322374 0.861135 0.318946 1.05544C0.315518 1.24974 0.387641 1.43778 0.520121 1.57996L4.94012 5.99996L0.520121 10.42C0.379671 10.5606 0.300781 10.7512 0.300781 10.95C0.300781 11.1487 0.379671 11.3393 0.520121 11.48C0.660746 11.6204 0.851371 11.6993 1.05012 11.6993C1.24887 11.6993 1.4395 11.6204 1.58012 11.48L6.00012 7.05996L10.4201 11.48C10.5607 11.6204 10.7514 11.6993 10.9501 11.6993C11.1489 11.6993 11.3395 11.6204 11.4801 11.48C11.6206 11.3393 11.6995 11.1487 11.6995 10.95C11.6995 10.7512 11.6206 10.5606 11.4801 10.42L7.06012 5.99996Z" fill="white" />
          </svg>
          </span>
        </div></>)
    }
    else {
      // custom init element
      if (rowData.permission !== "Owner") { // if person is not owner
        return (<div className="share-lab-action">
          <div>
            {/* Edit button icon to edit permission and email */}
            <div type="button" onClick={rowEditor.onInitClick} className={rowEditor.initClassName}>
              <span className='p-row-editor-init-icon pi pi-fw  p-clickable'><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.696718 15.9812C0.606467 15.9821 0.51692 15.9652 0.433231 15.9314C0.349541 15.8976 0.273361 15.8476 0.209074 15.7842C0.135368 15.7114 0.0786553 15.6233 0.0429904 15.526C0.00732556 15.4287 -0.0064109 15.3248 0.00276306 15.2216L0.293474 11.6205C0.30594 11.4533 0.379353 11.2965 0.499785 11.1798L10.8716 0.807947C11.4495 0.266768 12.2184 -0.0232453 13.0097 0.00145818C13.7959 0.00755839 14.5503 0.312673 15.1197 0.854835C15.6659 1.41563 15.9799 2.16205 15.9991 2.9446C16.0182 3.72715 15.741 4.48803 15.2229 5.07483L4.84169 15.4935C4.72551 15.6093 4.57337 15.6821 4.41032 15.6998L0.762363 16.0281L0.696718 15.9812ZM1.6345 11.9863L1.43756 14.5558L4.00707 14.3213L14.2288 4.08079C14.4281 3.84492 14.5555 3.55679 14.5959 3.25065C14.6363 2.9445 14.5879 2.6332 14.4566 2.35373C14.3252 2.07426 14.1164 1.83836 13.855 1.67406C13.5935 1.50977 13.2904 1.42398 12.9816 1.42688C12.7787 1.41399 12.5752 1.44224 12.3834 1.50992C12.1917 1.5776 12.0155 1.68332 11.8656 1.82075L1.6345 11.9863Z" fill="#49AFD9" />
              </svg>
              </span>
            </div>
          </div>
          {/* Remove button to remove lab sharing */}
          <div
            className="shared-remove-template"
            onClick={(e) => removeConfirm(e, rowData)}
          >
            Remove
          </div>
        </div>


        )
      } else { // person is owner edit and remove option is disabled
        return (<div className="share-lab-action">
          <div>
            <Button onClick={rowEditor.onInitClick} className={rowEditor.initClassName} disabled>
              <span className='p-row-editor-init-icon pi pi-fw'><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.696718 15.9812C0.606467 15.9821 0.51692 15.9652 0.433231 15.9314C0.349541 15.8976 0.273361 15.8476 0.209074 15.7842C0.135368 15.7114 0.0786553 15.6233 0.0429904 15.526C0.00732556 15.4287 -0.0064109 15.3248 0.00276306 15.2216L0.293474 11.6205C0.30594 11.4533 0.379353 11.2965 0.499785 11.1798L10.8716 0.807947C11.4495 0.266768 12.2184 -0.0232453 13.0097 0.00145818C13.7959 0.00755839 14.5503 0.312673 15.1197 0.854835C15.6659 1.41563 15.9799 2.16205 15.9991 2.9446C16.0182 3.72715 15.741 4.48803 15.2229 5.07483L4.84169 15.4935C4.72551 15.6093 4.57337 15.6821 4.41032 15.6998L0.762363 16.0281L0.696718 15.9812ZM1.6345 11.9863L1.43756 14.5558L4.00707 14.3213L14.2288 4.08079C14.4281 3.84492 14.5555 3.55679 14.5959 3.25065C14.6363 2.9445 14.5879 2.6332 14.4566 2.35373C14.3252 2.07426 14.1164 1.83836 13.855 1.67406C13.5935 1.50977 13.2904 1.42398 12.9816 1.42688C12.7787 1.41399 12.5752 1.44224 12.3834 1.50992C12.1917 1.5776 12.0155 1.68332 11.8656 1.82075L1.6345 11.9863Z" fill="#767F84" />
              </svg>
              </span>
            </Button>
          </div>

          <div className="shared-remove-owner-template">Remove</div>
        </div>
        )
      }
    }
  }

  const permissionTemplate = (rowData, obj) => {
    return <div>{rowData.permission}</div>
  }

//method called when click on edit button to change  permission
  const permissionEditor = (options) => {
    console.log(options, "options")
    return (
      <Dropdown className="share-lab-category-dropdown" value={options.value} options={perVal}
        onChange={(e) => options.editorCallback(e.value)} placeholder="Select a Permission"
        itemTemplate={(option) => {
          return <span className={`product-badge status-${option}`}>{option}</span>
        }} />
    );
  }

//method called when click on edit button to change  email
  const emailEditor = (options) => {
    console.log(options,"emailedit")
   onEmailChangeEditor(options.value)
    return (
      <div>
    <InputText  value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
    {emailError && (
                    <small className="p-error p-d-block" id="error-message">
                      {emailErrorMsg}
                    </small>
                  )}
    </div>
    );
}

// if neither email nor permission is edited
const onRowEditValidator = (rowData) => {
if(emailError)
  return false
return true
}

  return (
    <div>
      <Toast ref={toast} position="top-center" />
      <ConfirmPopup />
      <Button
       // share lab button grade out when status is "Deleting"
        className={labs.status === "Deleting" ? "share-lab-button-disabled" : "share-lab-button"}
        onClick={() => onClick("displayBasic")}
        disabled={labs.status === "Deleting" ? true : false}
      ><div className="lab-sharing-btn">
          <div>
            <svg
              className="shared-icon"
              width="13"
              height="15"
              viewBox="0 0 13 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.75 2.72727L8.59625 3.69545L7.30437 2.61136V10.2273H5.69563V2.61136L4.40375 3.69545L3.25 2.72727L6.5 0L9.75 2.72727ZM13 6.13636V13.6364C13 14.3864 12.2688 15 11.375 15H1.625C0.723125 15 0 14.3864 0 13.6364V6.13636C0 5.37955 0.723125 4.77273 1.625 4.77273H4.0625V6.13636H1.625V13.6364H11.375V6.13636H8.9375V4.77273H11.375C12.2688 4.77273 13 5.37955 13 6.13636Z"
                fill={labs.status === "Deleting" ? "#303234" : "white"}
              />
            </svg>
          </div>
          <div className={labs.status === "Deleting" ? "share-lab-button-text-disabled" : "share-lab-button-text"} data-pr-tooltip={tooltipUsers}>
            Lab Sharing ({labs.shared_count})
            <Tooltip className="sidebarTooltip" target={labs.status === "Deleting" ? ".share-lab-button-text-disabled" : ".share-lab-button-text"} />
          </div>
        </div>
      </Button>
      <Dialog
        header={
          <>
          {/* Share lab details tab */}
            <div className="share-lab-header-title">Share Lab Details</div>
            <div className="share-lab-tabmenu">
              <button
                className="menuitem-link"
                style={{
                  borderColor:
                    activeIndex === 0
                      ? "#979797 #979797 #1B2A32 #979797"
                      : "#22343C #22343C #979797 #22343C",
                  backgroundColor: activeIndex === 0 ? "#1B2A32" : "#22343C",
                }}
                onClick={setIndex0}
                data-testid="all-labs-title"
              >
                <div className={activeIndex === 0 ? "active-tab" : "inactive-tab"}>SHARE MY LAB</div>
              </button>
              <button
                className="menuitem-link"
                style={{
                  borderColor:
                    activeIndex === 1
                      ? "#979797 #979797 #1B2A32 #979797"
                      : "#22343C #22343C #979797 #22343C",
                  backgroundColor: activeIndex === 1 ? "#1B2A32" : "#22343C",
                }}
                onClick={setIndex1}
              >
                {/* Shared with Tab */}
                <div className={activeIndex === 1 ? "active-tab" : "inactive-tab"}>SHARED WITH</div>
              </button>
            </div>
          </>
        }
        visible={displayBasic}
        footer={renderFooter("displayBasic")}
        onHide={() => onHide("displayBasic")}
        className="shared-lab-dialog"
      >
        <div className="share-lab-content-container">
          <div ref={errorRef} className={commonErrorMsg ? "" : "hidden"}>
            {commonErrorMsg && (
              <Message
                severity="warn"
                text={commonErrorMsg}
                className="common-inline-error common-error-message p-mb-3"
              />
            )}
          </div>{" "}
          {activeIndex === 0 ? (
            <div>
              <div className="share-lab-email-container">
                <div className="share-lab-email-label">
                  Enter Email Address :
                </div>
                <div>
                  {/* Email Input box */}
                  <InputText
                    keyfilter="email"
                    className="share-lab-email-input"
                    value={email}
                    onChange={(e) => onEmailChange(e)}
                  />
                  {emailError && (
                    <small className="p-error p-d-block" id="error-message">
                      {emailErrorMsg}
                    </small>
                  )}
                </div>
              </div>
              <div className="share-lab-permission-container">
                <div className="share-lab-permission">
                  <div className="share-lab-permission-label">Permission :</div>
                  <div className="share-lab-permission-radio-buttons">
                    <div className="field-radiobutton">
                      {/* Radio button for Lab user option */}
                      <RadioButton
                        name="permission1"
                        value="Lab User"
                        onChange={(e) => setPermission(e.value)}
                        checked={permission === "Lab User"}
                      />
                      <label className="share-lab-permission-button-label">
                        Lab User
                      </label>
                    </div>
                    <div className="field-radiobutton">
                      {/* Radio button for Co-Owner option */}
                      <RadioButton
                        name="permission2"
                        value="Co-Owner"
                        onChange={(e) => setPermission(e.value)}
                        checked={permission === "Co-Owner"}
                      />
                      <label className="share-lab-permission-button-label">
                        Co-Owner
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <DataTable
              value={sharedList}
              ref={dt}
              columnResizeMode="fit"
              className="shared-lab-crud-table"
              autoLayout={true}
              responsiveLayout="scroll"
              onRowEditComplete={onEdit}
              rowEditValidator={onRowEditValidator}
              editMode="row"

            >
              <Column
                field="email"
                header="Email ID"
                editor={(options) => emailEditor(options)}
                style={{ minWidth: "10rem", paddingLeft: "15px" }}
              ></Column>
              <Column
                field="sharing_date"
                header="Sharing Date"
                style={{ minWidth: "6.5rem", textAlign: "center" }}
              ></Column>
              <Column
                field="permission"
                header="Permission"
                style={{ minWidth: "5rem", textAlign: "center" }}
                editor={(options) => permissionEditor(options)}
                body={permissionTemplate}
              ></Column>
              <Column
                field="status"
                header="Status"
                body={statusBodyTemplate}
                style={{ minWidth: "3rem" }}
              ></Column>
              <Column
                header="Action"
                rowEditor
                body={rowEditorTemplate}
                style={{ minWidth: "3rem" }}
              ></Column>
            </DataTable>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default ShareLab;
