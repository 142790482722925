import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import React, { useState, useEffect, useRef } from "react";
import "../../Rbac.css";
import loglevel from "../../../../logger/Logger";
import { Toast } from "primereact/toast";
import LabServices from "../../../../services/LabServices";
import { Chips } from "primereact/chips";
import { Tooltip } from "primereact/tooltip";
import { RadioButton } from "primereact/radiobutton";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import parse from "html-react-parser";
import { confirmPopup } from "primereact/confirmpopup";
import eventBus from "../../../utility/EventBus";
import { Message } from 'primereact/message';
import { Editor } from "primereact/editor";
import { TreeSelect } from 'primereact/treeselect';
import { InputTextarea } from 'primereact/inputtextarea';

const UpdateLab = ({ labId, labName, enabled, deleted, setChanger, staticInstanceList, cmdbLabTypeTags, cmdbTags, permissionTagsInitial, labtypes, categoryList, labConcurrentInstances }) => {
  const [updateLabDisplay, setupdateLabDisplay] = useState(false);
  const [labRemarksDisplay, setLabRemarksDisplay] = useState(false)
  const [cname, setCname] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState(null);
  const [isAvailable, setIsAvailable] = useState(true);
  const [isStaticValidated, setIsStaticValidated] = useState(false)
  const [physicalServer, setPhysicalServer] = useState(1);
  const [labType, setLabType] = useState([]);
  const [labTypeList, setLabTypeList] = useState([]);
  const [typeVal, setTypeVal] = useState(null);
  const [typeValFixed, setTypeValFixed] = useState(null);
  // const [cmdbLabTypeTags, setCMDBLabTypeTags] = useState([]);
  // const [cmdbTags, setCMDBTags] = useState([]);
  const [permissionTags, setPermissionTags] = useState([]);
  // const [categoryList, setCategoryList] = useState(null);
  const [labUtilization, setLabUtilization] = useState("");
  const [maxSchedulePeriod, setMaxSchedulePeriod] = useState("");
  const [state, setState] = useState({ rows: [] });
  const [concurrentInstances, setConcurrentInstances] = useState(1);
  const [concurrentInstancesFixed, setConcurrentInstancesFixed] = useState(1);
  const [keywords, setKeywords] = useState([]);
  const toast = useRef(null);
  const [addExternalLink, setAddExternalLink] = useState({
    external_links: [],
  });
  const [defaultPermissionTag, setDefaultPermissionTag] = useState([]);
  const [additionalTags, setAdditionalTags] = useState("");
  const [enableLab, setEnableLab] = useState(true);
  const [enableTagInput, setenableTagInput] = useState(false);
  const [tag1, setTag1] = useState(false);
  const [tag2, setTag2] = useState(false);
  const [tag3, setTag3] = useState(false);
  const [enableTagCreationError, setEnableTagCreationError] = useState(false);
  const [disableLabDisplay, setDisableLabDisplay] = useState(false);
  const [date, setDate] = useState(null);
  const [enableCalendar, setEnableCalendar] = useState(false);
  const [dateDisableMessage, setDateDisableMessage] = useState("");
  const [disabledField, setDisabledField] = useState(null);
  const [validateMsg, setValidateMsg] = useState("");
  const [disableAddButton, setDisableAddButton] = useState(false);
  const [disableScheduled, setDisableScheduled] = useState([]);
  const [strikeOutDates, setStrikeOutDates] = useState([]);
  const [isScheduledLabs, setIsScheduledLabs] = useState(null);
  const [labUtilizationError, setLabUtilizationError] = useState(false);
  const [maxSchedulePeriodError, setMaxSchedulePeriodError] = useState(false);
  const [isAlreadyDisabled, setIsAlreadyDisabled] = useState(null);
  const [isEmptyDate, setIsEmptyDate] = useState(false);
  const [tagDescription, setTagDescription] = useState("")
  const [commonErrorMsg, setCommonErrorMsg] = useState("");
  const [initStatic, setInitStatic] = useState("");
  const [labStatic, setLabStatic] = useState([{ name: "Yes" }, { name: "No" }]);
  const [staticLabDetails, setStaticLabDetails] = useState([]);
  const [staticLabDetailsFixed, setStaticLabDetailsFixed] = useState([]);
  const [instanceQty, setInstanceQty] = useState(1);
  //const [staticInstanceList, setStaticInstanceList] = useState([])
  const [isLabStaticOnLoad, setIsLabStaticOnLoad] = useState();
  const [labUtilizationHours, setLabUtilizationHours] = useState("");
  const [labValidationError, setLabValidationError] = useState(false);
  const [labUtilizationErrorMsg, setLabUtilizationErrorMsg] = useState("");
  const [maxSchedulePeriodErrorMsg, setMaxSchedulePeriodErrorMsg] = useState("");
  const [isFutureDisable, setIsFutureDisable] = useState(null)
  const [urlWithPassword, setUrlWithPassword] = useState(true)
  const errorRef = useRef(null);
  const [labStatusRemarksForScheduled, setLabStatusRemarksForScheduled] = useState("")
  const [labStatusRemarksForActive, setLabStatusRemarksForActive] = useState("")
  const [labStatusRemarksForDeleted, setLabStatusRemarksForDeleted] = useState("")
  const [labStatusRemarksForFailed, setLabStatusRemarksForFailed] = useState("")
  const [labStatusRemarksForCancelled, setLabStatusRemarksForCancelled] = useState("")
  const [labStatusRemarksList, setLabStatusRemarksList] = useState([
    {status:'Scheduled', remarks:''},
    {status:'Active', remarks:''},
    {status:'Deleted', remarks:''},
    {status:'Cancelled', remarks:''},
    {status:'Failed', remarks:''},
  ])
  const [labDisabledBy, setLabDisabledBy] = useState(null)
  const [labDisabledReason, setLabDisabledReason] = useState("")

  let flag = 0;
  let serverValidated = 0;

  useEffect(async () => {
    setLabType(labtypes);
    setLabTypeList(labtypes);
    setPermissionTags(permissionTagsInitial);
  }, []);

  // ......................................Add Links................................
  const AddUrlhandleChange = (idx) => (e) => {
    const { name, value } = e.target;
    const external_links = [...addExternalLink.external_links];
    external_links[idx] = {
      ...addExternalLink.external_links[idx],
      [name]: value,
    };
    setAddExternalLink({
      external_links,
    });
  };
  const handleAddRowLink = () => {
    let currentRow = addExternalLink.external_links.length - 1
    if (addExternalLink.external_links[currentRow].label && addExternalLink.external_links[currentRow].url) {
      const item = {
        label: "",
        url: "",
      };
      setAddExternalLink({
        external_links: [...addExternalLink.external_links, item],
      });
    }
  };

  const handleRemoveSpecificRow = (idx) => () => {
    const external_links = [...addExternalLink.external_links];
    external_links.splice(idx, 1);
    setAddExternalLink({ external_links });
  };
  //...........................AddLinkEnd...............................................

  const handleChange = (idx) => (e) => {

    setValidateMsg("");
    setDisableAddButton(true);
    const { name, value } = e.target;
    const rows = [...state.rows];
    console.log(rows, "rows");
    rows[idx] = {
      ...state.rows[idx],
      [name]: value,
      sequence: idx + 1
    };
    setState({
      rows,
    });
    for (let row of rows) {
      if (row.assettags.length === 0) {
        console.log("iterate");
        setDisableAddButton(true);
        break;
      }
    }
  };
  const handleAddRow = (e) => {
    setDisableAddButton(true);
    setValidateMsg("");
    if (parseInt(e.target.value) >= 0 || e.target.value === "") {
      setPhysicalServer(e.target.value);

      const item = {
        assettags: [],
        type: {},
        sequence: 0
      };
      const re = /^[0-9\b]+$/;

      if (re.test(parseInt(e.target.value))) {
        if (state.rows.length < parseInt(e.target.value)) {
          let data = [];
          setState({ rows: [] });
          for (
            let i = 0;
            i < parseInt(e.target.value) - state.rows.length;
            i++
          ) {
            data.push(item);
          }

          setState({
            rows: [...state.rows, ...data],
          });
        } else {
          setState({
            rows: state.rows.slice(0, parseInt(e.target.value)),
          });
        }
      }
    }
  };

  const createLabDialogMap = {
    updateLabDisplay: setupdateLabDisplay,
  };

  const labRemarksDialogMap = {
    labRemarksDisplay: setLabRemarksDisplay,
  };

  const disableLabDialogMap = {
    disableLabDisplay: setDisableLabDisplay,
  };

  const onCategoryChange = (e) => {
    console.log(e.value, "selectedcatval");
    setCategory(e.value);
  };

  const onClick = async (name) => {
 
    setEnableCalendar(false);
    setIsStaticValidated(false)
    await LabServices.getLab(labId).then((res) => {
      console.log(res.data, "Lab values");

      setCname(res.data.name);
      setDescription(res.data.description);
      setIsAvailable(res.data.is_lab_available);
      //setEnableLab(res.data.enabled);
      if (res.data.enabled && res.data.lab_disabled_date) {
        setIsFutureDisable(true)
        setEnableLab(false);
      }
      else {
        setIsFutureDisable(false)
        setEnableLab(res.data.enabled);
      }
      setLabUtilization(parseInt(res.data.lab_utilization_expect_time / 24));
      setLabUtilizationHours(res.data.lab_utilization_expect_time % 24)
      console.log(res.data.labTypeDetails, "details labtype")
      setTypeVal({ labTypeId: res.data.labTypeDetails.labTypeId, name: res.data.labTypeDetails.name, is_static_workflow: res.data.labTypeDetails.is_static_lab });
      setTypeValFixed({ labTypeId: res.data.labTypeDetails.labTypeId, name: res.data.labTypeDetails.name, is_static_workflow: res.data.labTypeDetails.is_static_lab });
      setMaxSchedulePeriod(res.data.max_schedule_period);


      console.log(res.data.categoryDetails,"catdes##########################")
       let selectedCat = {}
       for (let i=0;i<res.data.categoryDetails.length;i++){
        selectedCat[res.data.categoryDetails[i]]=true
       }
      //   .filter((key) => key !== "updatedAt" && key !== "createdAt")
      //   .reduce((obj, key) => {
      //     obj[key] = res.data.categoryDetails[key];
      //     return obj;
      //   }, {});
      console.log(selectedCat,"catdes##########################")
      setCategory(selectedCat);

      // console.log(selectedCat, "selectedcat");

      setConcurrentInstances(res.data.lab_instance_qty);
      setConcurrentInstancesFixed(res.data.lab_instance_qty)
      let strikeOutDate = res.data.lab_disabled_stike_out_date.map((date) => {
        return new Date(date);
      });

      setStrikeOutDates(strikeOutDate);
      console.log(strikeOutDate, "Strikeout");

      if (res.data.enabled === false) {
        setIsAlreadyDisabled(true);
        setDate(
          moment(res.data.lab_disabled_date).format("YYYY-MM-DD")
        );
        let message = `Lab disabled from ${res.data.lab_disabled_date} `;
        setDateDisableMessage(message);
        setDisabledField(false);
        setLabDisabledBy(res.data.disable_by)
        setLabDisabledReason(res.data.reason_for_disable)
      } else if (res.data.enabled && res.data.lab_disabled_date) {
        setIsAlreadyDisabled(true);
        setDate(
          moment(res.data.lab_disabled_date).format("YYYY-MM-DD")
        );

        let message = `Lab will be disabled from ${moment(res.data.lab_disabled_date).format("MM-DD-YYYY")} `;
        setDateDisableMessage(message);
        setDisabledField(false);
        setLabDisabledBy(res.data.disable_by)
        setLabDisabledReason(res.data.reason_for_disable)
      } else if (res.data.enabled && res.data.lab_disabled_date === null) {
        setDateDisableMessage("");
        setDisabledField(true);
        setIsAlreadyDisabled(false);
        setDate(null);
      }
      console.log(res.data.tag, "responseTag");

      let array = [];
      for (var i = 0; i < res.data.tag.length; i++) {
        if (res.data.tag[i] === "read-only") {
          setTag1(true);
        } else if (res.data.tag[i] === "portal-user") {
          setTag2(true);
        } else if (res.data.tag[i] === "cec-dev-test") {
          setTag3(true);
        } else {
          array.push({ tag: res.data.tag[i] });
        }
      }

      console.log(array, "arrayTag");
      setDefaultPermissionTag(array);
      setPhysicalServer(res.data.labsPhysicalServerDetail.length);
      if (res.data.keywords) setKeywords(res.data.keywords.split(","));
      if (res.data.external_links.length > 0) {
        setAddExternalLink({ external_links: res.data.external_links });
      } else {
        setAddExternalLink({
          external_links: [
            {
              label: "",
              url: "",
            },
          ],
        });
      }
      const rows = [];

      for (let val = 0; val < res.data.labsPhysicalServerDetail.length; val++) {
        let dt = [];
        res.data.labsPhysicalServerDetail[val].cmdbTags.forEach((element) => {
          dt.push({ name: element, code: element });
        });
        rows.push({
          assettags: dt,
          type: res.data.labsPhysicalServerDetail[val].cmdbLabTypeEntity,
          sequence: res.data.labsPhysicalServerDetail[val].sequence
        });
      }

      setState({
        rows: [...rows],
      });

      // Static Lab details
      setIsLabStaticOnLoad(res.data.is_static_lab);
      if (res.data.is_static_lab) {
        setInitStatic({ name: "Yes" });
        let staticProcessList = labTypeList.filter(
          (process) => {
            console.log(process);

            return (process.is_static_workflow === true)
          })
        console.log(staticProcessList, "staticProcessList")

        setLabType(staticProcessList)
        setUrlWithPassword(res.data.url_with_password)
        let staticLabDtl = [];
        for (let i = 0; i < res.data.static_lab_details.length; i++) {
          staticLabDtl.push({
            lab_connect_url: res.data.static_lab_details[i].lab_connect_url,
            lab_reference_id: parseInt(res.data.static_lab_details[i].lab_reference_id),
            username: res.data.static_lab_details[i].username,
            password: res.data.static_lab_details[i].password,
            static_lab_connect_details_id: res.data.static_lab_details[i].static_lab_connect_details_id
          })
        }
        setStaticLabDetails(staticLabDtl);
        setStaticLabDetailsFixed(staticLabDtl);
      } else {
        setInitStatic({ name: "No" });
        let nonStaticProcessList = labTypeList.filter((process) => { return (process) })
        console.log(nonStaticProcessList, "nonstaticProcessList")
        setLabType(nonStaticProcessList)
      }
      if(res.data.lab_remark.length === 0) {
        setLabStatusRemarksList(labStatusRemarksList)
      }else {
        setLabStatusRemarksList(res.data.lab_remark)
      }
    });
    console.log(addExternalLink, "addexternalfrom api...");
    createLabDialogMap[`${name}`](true);
  };

  const onHide = (name) => {
    setCommonErrorMsg("");
    setIsStaticValidated(false)
    setState({ rows: [] });
    setLabUtilizationError(false);
    setEnableTagCreationError(false);
    setenableTagInput(false);
    setEnableCalendar(false);
    setDisableAddButton(false);
    setValidateMsg("");
    setUrlWithPassword(true)
    setLabStatusRemarksList(labStatusRemarksList);
    setLabDisabledBy(null)
    setLabDisabledReason("")
    createLabDialogMap[`${name}`](false);
  };

  const onTagChange = (e) => {
    setDefaultPermissionTag(e.target.value);
  };

  const onTag1Change = (e) => {
    setTag1(e.checked);
  };

  const onTag2Change = (e) => {
    setTag2(e.checked);
  };

  const onTag3Change = (e) => {
    setTag3(e.checked);
  };

  const onUrlWithPasswordChange = (e) => {
    setUrlWithPassword(e.checked);
  };

  const onCheckServer = async () => {
    var cmdbTagArray = [];
    state.rows.forEach((row) => {
      if (row.assettags.length === 0) {
        serverValidated = 1;
      }
      let array = [];
      row.assettags.forEach((tag) => {
        array.push(tag.name);
      });
      cmdbTagArray.push({ cmdbtags: array });
    });
    const reqBody = {
      lab_instance_qty: concurrentInstances,
      server_details: cmdbTagArray,
    };

    console.log(reqBody, "reqqqqqqqq");
    if (serverValidated === 0) {
      await LabServices.validatePhysicalServer(reqBody)
        .then((response) => {
          if (response.status === 201) {
            setDisableAddButton(false);
            setValidateMsg("");
            console.log("success");
          } else {
            loglevel.debug("Failed");
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            let msg = error.response.data.msg.map((item) => [
              <div>
                <li>{item}</li>
              </div>,
            ]);
            setDisableAddButton(true);
            setValidateMsg(msg);
          }
        });
    }
  };

  const onSelectDate = (e) => {
    var message = `Lab will be disabled from ${moment(e.value).format("MM-DD-YYYY")}`;
    setDateDisableMessage(message);
    setIsEmptyDate(false);
  };

  const onCancel = () => {
    setEnableLab(true);
    setEnableCalendar(false);
    setDateDisableMessage("");
    disableLabDialogMap["disableLabDisplay"](false);
  };

  const onDurationChange = e => {
    let value = e.target.value;
    let numbers = value.replace(/[^.\d]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2");
    e.target.value = numbers;
  };


  const onLabUtilization = (e) => {

    setLabValidationError(false)
    setLabUtilizationError(false)
    setLabUtilizationErrorMsg("")
    setMaxSchedulePeriodError(false)
    setMaxSchedulePeriodErrorMsg("")
    if (e.target.id === "labUtilizationHours") {
      setLabUtilizationHours(e.target.value)
    } else {
      setLabUtilization(e.target.value);
    }
  };

  const onMaxSchedulePeriod = (e) => {

    setLabUtilizationError(false)
    setLabValidationError(false)
    setLabUtilizationErrorMsg("")
    setMaxSchedulePeriodError(false)
    setMaxSchedulePeriodErrorMsg("")
    setMaxSchedulePeriod(e.target.value);
    if (e.target.value === "" || parseInt(e.target.value) <= 0) {
      setMaxSchedulePeriodError(true);
    } else {
      setMaxSchedulePeriodError(false);
    }
  };

  const onInstanceQtyChange = (e) => {
    setDisableAddButton(true);
    setValidateMsg("");
    setConcurrentInstances(e.value);
  };

  const is_url = (str) =>
  {
    var regexp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
          if (regexp.test(str))
          {
            return true;
          }
          else
          {
            return false;
          }
  }

  const onDisableLab = (name) => {
    setEnableLab(false);
    setDisabledField(false);
    setEnableCalendar(true);
    setIsEmptyDate(true);
    disableLabDialogMap[`${name}`](false);
  };
  const onChangeSwitch = async (e) => {
    console.log(e, "Input");
    if (e.value === false && isAlreadyDisabled === false) {
      await LabServices.getAllSchedules(labId)
        .then((res) => {
          if (res.data.length > 0) {
            setIsScheduledLabs(true);
          } else {
            setIsScheduledLabs(false);
          }
          setDisableScheduled(res.data);
        })
        .catch((error) => {
          loglevel.error(error);
        });

      disableLabDialogMap["disableLabDisplay"](true);
    } else if (e.value === false && isAlreadyDisabled) {
      setDisabledField(false);
      setEnableLab(e.value);
    } else if (e.value && isAlreadyDisabled) {
      setEnableCalendar(false);
      setDateDisableMessage("");
      setDate(null);
      setDisabledField(true);
      setEnableLab(e.value);
    } else {
      setEnableCalendar(false);
      setIsEmptyDate(false);
      setDateDisableMessage("");
      setDate(null);
      setDisabledField(true);
      setEnableLab(e.value);
    }
  };

  const disableLabRenderFooter = (name) => {
    return (
      <div className="disable-footer-button">

        <div>
          <Button
            label="DISABLE LAB"
            className={
              "p-button-outlined p-button-danger"
            }
            onClick={() => onDisableLab("disableLabDisplay")}

          />
        </div>

        <div>
          {" "}
          <Button
            label="CANCEL"
            onClick={onCancel}
            className="p-button-outlined p-button-primary"
          />
        </div>
      </div>
    );
  };

  const disableLabOnHide = (name) => {
    disableLabDialogMap[`${name}`](false);
    setEnableLab(true);
  };

  const createTag = (e) => {
    setAdditionalTags(e.target.value);
    setTagDescription(`The ${e.target.value} tag was created during Lab Updation.`)
    let defaultTags = ["read-only", "portal-user", "cec-dev-test"];
    let dropDownTags = [];
    permissionTags.forEach((item) => {
      dropDownTags.push(item.tag);
    });
    let tags = [...dropDownTags, ...defaultTags];
    if (tags.includes(e.target.value)) {
      setEnableTagCreationError(true);
    } else {
      setEnableTagCreationError(false);
    }
  };
  const onClickNo = () => {
    setAdditionalTags("");
    setTagDescription("")
    setenableTagInput(false);
  };

  const onClickYes = async () => {
    let tag = {
      name: additionalTags,
      description: tagDescription
    };
    setCommonErrorMsg("");
    let defaultTags = ["read-only", "portal-user", "cec-dev-test"];
    let dropDownTags = [];
    permissionTags.forEach((item) => {
      dropDownTags.push(item.tag);
    });
    let tags = [...dropDownTags, ...defaultTags];
    if (!tags.includes(additionalTags)) {
      setenableTagInput(false);
      setAdditionalTags("");
      setTagDescription("")
      setEnableTagCreationError(false);
      await LabServices.createTag(tag)
        .then((response) => {
          if (response.status === 201) {
            toast.current.show({
              severity: "success",
              summary: "Tag Successfully Created",
              detail: `The tag ${additionalTags} has been created successfully`,
              life: 4000,
            });
          } else {
            loglevel.debug("Tag creation is Failed");
          }
        })
        .catch((error) => {
          loglevel.error("CreateTag", error);
          if (error.response.status === 400) {
            setCommonErrorMsg(error.response.data.msg);
            errorRef.current.scrollIntoView({ behavior: "smooth" });
          }
        });
      await LabServices.getPermissionTagList()
        .then((res) => {
          console.log(res);
          let permissionTagArray = [];
          for (var i = 0; i < res.data.length; i++) {
            if (
              res.data[i] === "read-only" ||
              res.data[i] === "cec-dev-test" ||
              res.data[i] === "portal-user"
            ) {
              continue;
            } else {
              permissionTagArray.push({ tag: res.data[i] });
            }
          }
          console.log("permissionTagArray", permissionTagArray);
          setPermissionTags(permissionTagArray);
        })
        .catch((error) => {
          loglevel.error(error);
        });
    } else {
      setAdditionalTags("");
      setEnableTagCreationError(false);
    }
  };

  const maxSchedulePeriodReload = () => {
    console.log("applying");
    eventBus.dispatch("pageReloaded", {
      message: "pageReloaded",
    });
  };


  const accept = async (tagArray, array) => {
    setCommonErrorMsg("");
    let requestBody = {}
    if (initStatic.name === 'No') {
      requestBody = {
        name: `${cname}`,
        description: `${description}`,
        lab_instance_qty: parseInt(concurrentInstances),
        enabled: (!!enableLab || !!isFutureDisable),
        lab_disabled_date: (!!enableLab || isFutureDisable) && date !== null || (!!enableLab === false && date) ? date : null,
        is_lab_available: !!isAvailable,
        lab_utilization_expect_time: (Number(labUtilization) * 24 + Number(labUtilizationHours)),
        physical_resources: parseInt(physicalServer),
        tag: tagArray,
        physical_server_details: array,
        lab_type_id: parseInt(typeVal.labTypeId),
        categories_id: Object.keys(category),
        keywords: keywords.toString(),
        external_links: addExternalLink.external_links,
        max_schedule_period: parseInt(maxSchedulePeriod),
        lab_remark: labStatusRemarksList,
        reason_for_disable: labDisabledReason,
        disable_by: parseInt(localStorage.getItem('users_id')),
      };
    } else {
      let lUtilization = 0;
      let lUtilizationHours = 0;
      if (isNaN(parseInt(labUtilization)) && isNaN(parseInt(labUtilizationHours))) {
        return false
      } else if (isNaN(parseInt(labUtilization)) || isNaN(parseInt(labUtilizationHours))) {
        if (!isNaN(parseInt(labUtilization)) && isNaN(parseInt(labUtilizationHours))) {
          lUtilizationHours = 0;
          lUtilization = parseInt(labUtilization)
        }
        if (isNaN(parseInt(labUtilization)) && !isNaN(parseInt(labUtilizationHours))) {
          lUtilization = 0;
          lUtilizationHours = parseInt(labUtilizationHours);
        }
      } else if (!isNaN(parseInt(labUtilization)) && !isNaN(parseInt(labUtilizationHours))) {
        lUtilization = parseInt(labUtilization)
        lUtilizationHours = parseInt(labUtilizationHours)
      }

      requestBody = {
        name: `${cname}`,
        description: `${description}`,
        lab_instance_qty: parseInt(staticLabDetails.length),
        is_lab_available: !!isAvailable,
        lab_utilization_expect_time: (Number(lUtilization) * 24 + Number(lUtilizationHours)),
        tag: tagArray,
        lab_type_id: parseInt(typeVal.labTypeId),
        categories_id: Object.keys(category),
        keywords: keywords.toString(),
        external_links: addExternalLink.external_links,
        max_schedule_period: parseInt(maxSchedulePeriod),
        static_lab_details: staticLabDetails,
        is_static_lab: true,
        enabled: (!!enableLab || !!isFutureDisable),
        lab_disabled_date: (!!enableLab || isFutureDisable) && date !== null || (!!enableLab === false && date) ? date : null,
        url_with_password: urlWithPassword,
        lab_remark: labStatusRemarksList,
        reason_for_disable: labDisabledReason,
        disable_by: parseInt(localStorage.getItem('users_id')),
      };
    }


    await LabServices.UpdateLab(labId, requestBody)
      .then((response) => {
        if (response.status === 200) {
          toast.current.show({
            severity: "success",
            summary: "Lab Successfully Updated",
            detail: "The lab has been successfully updated.",
            life: 4000,
          });
          setDisabledField(true);
          createLabDialogMap["updateLabDisplay"](false);
        } else {
          loglevel.debug("Lab Updation is Failed");
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setCommonErrorMsg(error.response.data.msg);
          errorRef.current.scrollIntoView({ behavior: "smooth" });
        }
      });

    await LabServices.getLabList().then((res) => {
      console.log(res.data, "Lab list");
      setChanger(res.data);
    });

    maxSchedulePeriodReload();
  };

  const onUpdate = async (e) => {
    let array = [];

    console.log(state.rows, "state rows update");
    if (state.rows.length > 0) {
      state.rows.forEach((item) => {
        console.log("######## item:", item);
        if (
          item.assettags.length === 0 ||
          Object.keys(item.type).length === 0
        ) {
          flag = 1;
        }
        let assetTag = [];
        if (item.assettags) {
          item.assettags.forEach((x) => {
            assetTag.push(x.name);
          });
        }
        let object = { cmdbtags: assetTag, type: item.type.cmdbLabTypeId, sequence: item.sequence };
        array.push(object);
      });
    }

    let tags = [];
    if (tag1 === true) {
      tags.push("read-only");
    }
    if (tag2 === true) {
      tags.push("portal-user");
    }

    if (tag3 === true) {
      tags.push("cec-dev-test");
    }

    let selectedTagArray = [...defaultPermissionTag];
    let changedTagArray = [];
    selectedTagArray.forEach((element) => {
      changedTagArray.push(element.tag);
    });

    let tagArray = [...new Set([...tags, ...changedTagArray])];
    let isErrorFound = true
    if ((labUtilization === "" && labUtilizationHours === "") || (parseInt(labUtilization) === 0 && parseInt(labUtilizationHours) === 0)) {
      setLabUtilizationError(true)
      setLabValidationError(true)
      setLabUtilizationErrorMsg("Lab Utilization Expect Time must be greater than 0")


    } else if (maxSchedulePeriod === "" || parseInt(maxSchedulePeriod) === 0) {
      setMaxSchedulePeriodError(true)
      setMaxSchedulePeriodErrorMsg("maximum Schedule Period must be greater than 0")

    } else if ((parseInt(labUtilization) === 0 || labUtilization === "") && (parseInt(labUtilizationHours) > 0 && (parseInt(labUtilizationHours) > parseInt(maxSchedulePeriod) * 24))) {

      setMaxSchedulePeriodError(true)
      setMaxSchedulePeriodErrorMsg("Lab Utilization expect Time must not be greater than max Schedule Period")

    } else if ((parseInt(labUtilizationHours) === 0 || labUtilizationHours === "") && (parseInt(labUtilization) > 0 && (parseInt(labUtilization) > parseInt(maxSchedulePeriod)))) {

      setMaxSchedulePeriodError(true)
      setMaxSchedulePeriodErrorMsg("Lab Utilization Time must not be greater than max Schedule Period")

    } else if (!((labUtilization === "" && labUtilizationHours === "") || (parseInt(labUtilization) === 0 && parseInt(labUtilizationHours) === 0)) && ((parseInt(labUtilization) * 24 + parseInt(labUtilizationHours)) > parseInt(maxSchedulePeriod) * 24)) {
      setMaxSchedulePeriodError(true)
      setMaxSchedulePeriodErrorMsg("Lab Utilization Time must not be greater than max Schedule Period")
    } else {
      isErrorFound = false
      setLabUtilizationError(false)
      setLabUtilizationErrorMsg("")
      setMaxSchedulePeriodError(false)
      setMaxSchedulePeriodErrorMsg("")
      setLabValidationError(false)
    }

    if (
      cname &&
      description &&
      flag === 0 &&
      Object.keys(category).length>0 &&
      typeVal !== null &&
      physicalServer !== "" &&
      physicalServer !== null &&
      !isEmptyDate &&
      isErrorFound === false &&
      initStatic.name === 'No'
    ) {
      console.log(array, "arrayUpdatecomming");
      var confirmationMsg = `Want to update the changes?`;

      confirmPopup({
        target: e.currentTarget,
        message: parse(`${confirmationMsg}`),
        icon: "pi pi-exclamation-triangle",
        className: "popup",
        acceptLabel: "YES",
        rejectLabel: "NO",
        accept: () => accept(tagArray, array),
      });
    } else if (initStatic.name === 'Yes') {
      setIsStaticValidated(true)
      let validForm = true
      for (let i = 0; i < staticLabDetails.length; i++) {
        if(!is_url(staticLabDetails[i].lab_connect_url)){
          validForm = false
        }else{
          continue;
        }
      }
      console.log(array, "arrayUpdatecomming");
      var confirmationMsg = `Want to update the changes?`;
     if(validForm){
      confirmPopup({
        target: e.currentTarget,
        message: parse(`${confirmationMsg}`),
        icon: "pi pi-exclamation-triangle",
        className: "popup",
        acceptLabel: "YES",
        rejectLabel: "NO",
        accept: () => accept(tagArray, array),
      });
    }
    }
  };
  const renderFooter = (name) => {
    return (
      <div
        className={
          ((initStatic.name === 'No') && disableAddButton) ? "lab-crud-disable-button" :
            ((initStatic.name === 'No') && !disableAddButton) ? "lab-crud-button" : (initStatic.name === 'Yes' && isStaticFormValid()) ? "lab-crud-button" : "lab-crud-disable-button"
        }
      >
        <Button onClick={(e) => onUpdate(e)} disabled={initStatic.name === 'No' ? disableAddButton : (initStatic.name === 'Yes' && isStaticFormValid()) ? false : true}>
          <span className="name">UPDATE</span>
        </Button>
      </div>
    );
  };

  const setStatic = (e) => {
    setInitStatic(e);
    if (e.name === "Yes") {
      let staticProcessList = labTypeList.filter(
        (process) => {
          console.log(process);

          return (process.is_static_workflow === true)
        })
      console.log(staticProcessList, "staticProcessList")

      setLabType(staticProcessList)
      console.log("############ TypeVal: ", typeVal);


      if (!isLabStaticOnLoad) {
        setStaticLabDetails([{
          lab_connect_url: "",
          lab_reference_id: parseInt(staticInstanceList[0]),
          username: "",
          password: ""
        }])
      } else {
        setStaticLabDetails(staticLabDetailsFixed)
      }

      if (typeValFixed.labTypeId && typeValFixed.is_static_workflow) {
        setTypeVal(typeValFixed);
      } else {
        setTypeVal({});
      }
    } else {
      let nonStaticProcessList = labTypeList.filter((process) => { return (process) })
      console.log(nonStaticProcessList, "nonstaticProcessList");
      setLabType(nonStaticProcessList);
      if (typeValFixed.labTypeId && !typeValFixed.is_static_workflow) {
        setTypeVal(typeValFixed);
      } else {
        setTypeVal({});
      }
      if (!isLabStaticOnLoad) {
        let noOfConcurrentInstanceOnLoad = concurrentInstancesFixed;
        setConcurrentInstances(noOfConcurrentInstanceOnLoad);
      } else {
        setConcurrentInstances(1);
        setPhysicalServer(0);
        setState({ rows: [] })
        setDisableAddButton(true);
        setValidateMsg("");
      }
    }

  }

  const onStaticInstanceQtyChange = (e) => {

    let staticLabDtlList = staticLabDetails;
    let labDtlList = [];
    if (isLabStaticOnLoad) {
      let newInstanceQty = e.value;
      if (newInstanceQty < staticLabDetails.length) {
        for (let i = 0; i < e.value; i++) {
          labDtlList.push({
            lab_connect_url: staticLabDetails[i].lab_connect_url,
            lab_reference_id: parseInt(staticLabDetails[i].lab_reference_id),
            username: staticLabDetails[i].username,
            password: staticLabDetails[i].password,
            static_lab_connect_details_id: staticLabDetails[i].static_lab_connect_details_id
          })
        }
        setStaticLabDetails(labDtlList);
      } else {
        labDtlList = [];
        for (let i = 0; i < parseInt(staticLabDtlList.length); i++) {
          labDtlList.push({
            lab_connect_url: staticLabDtlList[i].lab_connect_url,
            lab_reference_id: parseInt(staticLabDtlList[i].lab_reference_id),
            username: staticLabDtlList[i].username,
            password: staticLabDtlList[i].password,
            static_lab_connect_details_id: staticLabDetails[i].static_lab_connect_details_id
          })
        }
        for (let i = 0; i < (parseInt(e.value) - parseInt(staticLabDtlList.length)); i++) {
          labDtlList.push({
            lab_connect_url: "",
            lab_reference_id: parseInt(staticInstanceList[0]),
            username: "",
            password: "",
            static_lab_connect_details_id: null
          })
        }
        setStaticLabDetails(labDtlList)
      }
    } else {
      for (let i = 0; i < parseInt(e.value); i++) {
        labDtlList.push({
          lab_connect_url: "",
          lab_reference_id: parseInt(staticInstanceList[0]),
          username: "",
          password: ""
        })
      }
      setStaticLabDetails(labDtlList)
    }

    isStaticFormValid();
  };

  const onStaticLabChange = (e)=>{
    setIsStaticValidated(false)
    setStatic(e.value)
  }

  const isStaticFormValid = () => {
    let lUtilization = 0;
    let lUtilizationHours = 0;
    if (isNaN(parseInt(labUtilization)) && isNaN(parseInt(labUtilizationHours))) {
      return false
    } else if (isNaN(parseInt(labUtilization)) || isNaN(parseInt(labUtilizationHours))) {
      if (!isNaN(parseInt(labUtilization)) && isNaN(parseInt(labUtilizationHours))) {
        lUtilizationHours = 0;
        lUtilization = parseInt(labUtilization)
      }
      if (isNaN(parseInt(labUtilization)) && !isNaN(parseInt(labUtilizationHours))) {
        lUtilization = 0;
        lUtilizationHours = parseInt(labUtilizationHours);
      }
    } else if (!isNaN(parseInt(labUtilization)) && !isNaN(parseInt(labUtilizationHours))) {
      lUtilization = parseInt(labUtilization)
      lUtilizationHours = parseInt(labUtilizationHours)
    }

    if (cname !== "" && description !== "" && ((parseInt(lUtilization) * 24 + parseInt(lUtilizationHours)) > 0 && (parseInt(lUtilization) * 24 + parseInt(lUtilizationHours)) <= parseInt(maxSchedulePeriod) * 24) && typeVal.labTypeId &&
      Object.keys(category).length>0 && parseInt(maxSchedulePeriod) && (staticLabDetails.length > 0)) {
      let isValidStaticLabConnectURL = false;
      let isUsernamePasswordValid = false;
      let isLabDisableReasonValid = false;
      for (let i = 0; i < staticLabDetails.length; i++) {
        if (staticLabDetails[i].lab_connect_url !== "") {
          isValidStaticLabConnectURL = true;
        } else {
          isValidStaticLabConnectURL = false;
          break;
        }
        if(urlWithPassword) {
          if(staticLabDetails[i].username !== "" && staticLabDetails[i].password !== "") {
            isUsernamePasswordValid = true;
          } else {
            isUsernamePasswordValid = false;
            break;
          }
        } else {
          isUsernamePasswordValid = true;
        }
      }
      if(!enableLab && labDisabledReason == "") {
        isLabDisableReasonValid = false
      } else {
        isLabDisableReasonValid = true
      }
      if (isValidStaticLabConnectURL && isUsernamePasswordValid && isLabDisableReasonValid) {
        return true
      } else {
        return false;
      }
    }
    return false;
  }

  const onStaticLabIdChange = (e, index) => {
    let staticLabDt = [];
    for (let i = 0; i < staticLabDetails.length; i++) {
      if (i === parseInt(index)) {
        staticLabDt.push({
          lab_connect_url: staticLabDetails[i].lab_connect_url,
          lab_reference_id: parseInt(e.value),
          username: staticLabDetails[i].username,
          password: staticLabDetails[i].password,
          static_lab_connect_details_id: staticLabDetails[i].static_lab_connect_details_id
        })
      } else {
        staticLabDt.push({
          lab_connect_url: staticLabDetails[i].lab_connect_url,
          lab_reference_id: parseInt(staticLabDetails[i].lab_reference_id),
          username: staticLabDetails[i].username,
          password: staticLabDetails[i].password,
          static_lab_connect_details_id: staticLabDetails[i].static_lab_connect_details_id
        })
      }
    }
    setStaticLabDetails(staticLabDt);
  };

  const setStaticConnetLabURL = (e, index) => {
    setIsStaticValidated(false)
    let staticLabDt = [];
    for (let i = 0; i < staticLabDetails.length; i++) {
      if (i === parseInt(index)) {
        staticLabDt.push({
          lab_connect_url: e.target.value,
          lab_reference_id: parseInt(staticLabDetails[i].lab_reference_id),
          username: staticLabDetails[i].username,
          password: staticLabDetails[i].password,
          static_lab_connect_details_id: staticLabDetails[i].static_lab_connect_details_id
        })
      } else {
        staticLabDt.push({
          lab_connect_url: staticLabDetails[i].lab_connect_url,
          lab_reference_id: parseInt(staticLabDetails[i].lab_reference_id),
          username: staticLabDetails[i].username,
          password: staticLabDetails[i].password,
          static_lab_connect_details_id: staticLabDetails[i].static_lab_connect_details_id
        })
      }
    }
    setStaticLabDetails(staticLabDt);
  }

  const setStaticUsername = (e, index) => {
    let staticLabDt = [];
    for (let i = 0; i < staticLabDetails.length; i++) {
      if (i === parseInt(index)) {
        staticLabDt.push({
          lab_connect_url: staticLabDetails[i].lab_connect_url,
          lab_reference_id: parseInt(staticLabDetails[i].lab_reference_id),
          username: e.target.value,
          password: staticLabDetails[i].password,
          static_lab_connect_details_id: staticLabDetails[i].static_lab_connect_details_id
        })
      } else {
        staticLabDt.push({
          lab_connect_url: staticLabDetails[i].lab_connect_url,
          lab_reference_id: parseInt(staticLabDetails[i].lab_reference_id),
          username: staticLabDetails[i].username,
          password: staticLabDetails[i].password,
          static_lab_connect_details_id: staticLabDetails[i].static_lab_connect_details_id
        })
      }
    }
    setStaticLabDetails(staticLabDt);
  }

  const setStaticPassword = (e, index) => {
    let staticLabDt = [];
    for (let i = 0; i < staticLabDetails.length; i++) {
      if (i === parseInt(index)) {
        staticLabDt.push({
          lab_connect_url: staticLabDetails[i].lab_connect_url,
          lab_reference_id: parseInt(staticLabDetails[i].lab_reference_id),
          username: staticLabDetails[i].username,
          password: e.target.value,
          static_lab_connect_details_id: staticLabDetails[i].static_lab_connect_details_id
        })
      } else {
        staticLabDt.push({
          lab_connect_url: staticLabDetails[i].lab_connect_url,
          lab_reference_id: parseInt(staticLabDetails[i].lab_reference_id),
          username: staticLabDetails[i].username,
          password: staticLabDetails[i].password,
          static_lab_connect_details_id: staticLabDetails[i].static_lab_connect_details_id
        })
      }
    }
    setStaticLabDetails(staticLabDt);
  }

  // const onLabStatusRemarkEditForScheduled = (e) => {
  //   setLabStatusRemarksForScheduled(e.target.value)
  // }

  // const onLabStatusRemarkEditForActive = (e) => {
  //   setLabStatusRemarksForActive(e.target.value)
  // }

  // const onLabStatusRemarkEditForDeleted = (e) => {
  //   setLabStatusRemarksForDeleted(e.target.value)
  // }

  // const onLabStatusRemarkEditForFailed = (e) => {
  //   setLabStatusRemarksForFailed(e.target.value)
  // }

  // const onLabStatusRemarkEditForCancelled = (e) => {
  //   setLabStatusRemarksForCancelled(e.target.value)
  // }

  const onUpdateLabRemarks = (name) => {
    let newLabStatusRemarksList = [
      { status: 'Scheduled', remarks: labStatusRemarksForScheduled },
      { status: 'Active', remarks: labStatusRemarksForActive },
      { status: 'Deleted', remarks: labStatusRemarksForDeleted },
      { status: 'Cancelled', remarks: labStatusRemarksForCancelled },
      { status: 'Failed', remarks: labStatusRemarksForFailed },
    ]
    setLabStatusRemarksList(newLabStatusRemarksList)
    labRemarksDialogMap[`${name}`](false);
  }

  const labRemarksDialog = (name) => {
    labStatusRemarksList.forEach(newobj => {
      if(newobj.status == "Scheduled") {
        setLabStatusRemarksForScheduled(newobj.remarks)
      } else if(newobj.status == "Active") {
        setLabStatusRemarksForActive(newobj.remarks)
      } else if(newobj.status == "Deleted") {
        setLabStatusRemarksForDeleted(newobj.remarks)
      } else if(newobj.status == "Failed") {
        setLabStatusRemarksForFailed(newobj.remarks)
      } else if(newobj.status == "Cancelled") {
        setLabStatusRemarksForCancelled(newobj.remarks)
      }
    })
    labRemarksDialogMap[`${name}`](true);
  }

  const labRemarksOnHide = (name) => {
    setLabStatusRemarksForCancelled("")
    setLabStatusRemarksForFailed("")
    setLabStatusRemarksForDeleted("")
    setLabStatusRemarksForActive("")
    setLabStatusRemarksForScheduled("")
    labRemarksDialogMap[`${name}`](false);
  }

  const labRemarksRenderFooter = (name) => {
    return (
      <div>
        <div className="reset-btn-section">
          <Button onClick={() => onUpdateLabRemarks(name)} className="lab-remarks-update-button" label="UPDATE"/>
          <Button label="Cancel" onClick={() => labRemarksOnHide("labRemarksDisplay")} className="p-button-primary" />
        </div>
      </div>
    )
  }
  const onLabDisableReasonEdit = (e) => {
    setLabDisabledReason(e.target.value)
  }

  return (
    <div>
      <Toast ref={toast} position="top-center" />
      <div className={!enabled && deleted ? "update-button" : ""}>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success ml-2"
          onClick={() => onClick("updateLabDisplay")}
          disabled={!enabled && deleted}
        />
      </div>
      <Dialog
        header={`Update Lab : ${cname}`}
        visible={updateLabDisplay}
        footer={renderFooter("updateLabDisplay")}
        onHide={() => onHide("updateLabDisplay")}
        className="lab-view-dialog-box"
      >

        <div ref={errorRef} className="dialog-content">
          {commonErrorMsg && <Message severity="warn" text={commonErrorMsg} className='common-inline-error common-error-message p-mb-3' />}
          <div className="first-section">
            <div className="lab-name-input-text">
              <div className="lab-name-label">
                Name:<span className="lab-required-field">*</span>
              </div>

              <div>
                <InputText
                  value={cname}
                  onChange={(e) => setCname(e.target.value)}
                  placeholder="Name"
                  maxLength={75}
                  className={
                    !cname
                      ? "lab-name-text-box-input-error"
                      : "lab-name-text-box"
                  }
                />
                {!cname ? (
                  <div className="error-message-inline">
                    <small className="error-message" id="error-message">
                      Lab Name is mandatory
                    </small>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="view-edit-lab-description">
              <div className="lab-description-label">
                Description:<span className="lab-required-field">*</span>
              </div>
              <div className={!description ? "desc-error" : "update-description"}>
                {/* <Editor style={{ width: "514px" }} value={description} onTextChange={(e) => onDescriptionChange(e)} /> */}
                <Editor style={{ width: "514px" }} value={description} onTextChange={(e) => setDescription(e.htmlValue)} />
                {!description ? (
                  <div className="error-message-inline">
                    <small className="error-message" id="error-message">
                      Description is mandatory
                    </small>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="lab-name-input-text">
              <Tooltip
                className="editLabToolTip"
                target={`.custom-target-icon`}
              />
              <div className="lab-keyword-label">
                Keywords<span className="optional-fields"> (Optional)</span>:
                <span
                  className="custom-target-icon"
                  data-pr-tooltip={
                    "“Keywords” are used to help in quick search. Multiple  “Keywords” can be entered using “Comma Separator”."
                  }
                  data-pr-position="top"
                >
                  <svg
                    width="13"
                    height="13"
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 5.33333C0 2.38781 2.38781 0 5.33333 0C6.74782 0 8.10437 0.561903 9.10457 1.5621C10.1048 2.56229 10.6667 3.91885 10.6667 5.33333C10.6667 8.27885 8.27885 10.6667 5.33333 10.6667C2.38781 10.6667 0 8.27885 0 5.33333ZM5.92444 2.62222C5.92444 2.96587 5.64587 3.24445 5.30222 3.24445C4.95858 3.24445 4.68 2.96587 4.68 2.62222C4.68 2.27858 4.95858 2 5.30222 2C5.64587 2 5.92444 2.27858 5.92444 2.62222ZM5.33333 9.77778C2.87873 9.77778 0.888889 7.78793 0.888889 5.33333C0.888889 2.87873 2.87873 0.888889 5.33333 0.888889C7.78793 0.888889 9.77778 2.87873 9.77778 5.33333C9.77778 6.51207 9.30953 7.64254 8.47603 8.47603C7.64254 9.30953 6.51207 9.77778 5.33333 9.77778ZM5.77778 7.55556H6.66667C6.91213 7.55556 7.11111 7.75454 7.11111 8C7.11111 8.24546 6.91213 8.44444 6.66667 8.44444H4C3.75454 8.44444 3.55556 8.24546 3.55556 8C3.55556 7.75454 3.75454 7.55556 4 7.55556H4.88889V4.88889H4.44444C4.19898 4.88889 4 4.6899 4 4.44444C4 4.19898 4.19898 4 4.44444 4H5.77778V7.55556Z"
                      fill="#49AFD9"
                    />
                  </svg>
                </span>
              </div>
              <Chips
                value={keywords}
                allowDuplicate={false}
                className="lab-keyword-text-box"
                onChange={(e) => setKeywords(e.value)}
                separator=","
              />
            </div>
            <div>
              <div className="external-link-container">
                <div className="lab-external-link-label">
                  External Links
                  <span className="optional-fields"> (Optional)</span>:
                  <span
                    className="custom-target-icon"
                    data-pr-position="top"
                    data-pr-tooltip={
                      "Multiple “External Links” can be entered using “Comma Separator”."
                    }
                  >
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 5.33333C0 2.38781 2.38781 0 5.33333 0C6.74782 0 8.10437 0.561903 9.10457 1.5621C10.1048 2.56229 10.6667 3.91885 10.6667 5.33333C10.6667 8.27885 8.27885 10.6667 5.33333 10.6667C2.38781 10.6667 0 8.27885 0 5.33333ZM5.92444 2.62222C5.92444 2.96587 5.64587 3.24445 5.30222 3.24445C4.95858 3.24445 4.68 2.96587 4.68 2.62222C4.68 2.27858 4.95858 2 5.30222 2C5.64587 2 5.92444 2.27858 5.92444 2.62222ZM5.33333 9.77778C2.87873 9.77778 0.888889 7.78793 0.888889 5.33333C0.888889 2.87873 2.87873 0.888889 5.33333 0.888889C7.78793 0.888889 9.77778 2.87873 9.77778 5.33333C9.77778 6.51207 9.30953 7.64254 8.47603 8.47603C7.64254 9.30953 6.51207 9.77778 5.33333 9.77778ZM5.77778 7.55556H6.66667C6.91213 7.55556 7.11111 7.75454 7.11111 8C7.11111 8.24546 6.91213 8.44444 6.66667 8.44444H4C3.75454 8.44444 3.55556 8.24546 3.55556 8C3.55556 7.75454 3.75454 7.55556 4 7.55556H4.88889V4.88889H4.44444C4.19898 4.88889 4 4.6899 4 4.44444C4 4.19898 4.19898 4 4.44444 4H5.77778V7.55556Z"
                        fill="#49AFD9"
                      />
                    </svg>
                  </span>
                </div>
                <div>
                  {addExternalLink.external_links.map((item, idx) => (
                    <div>
                      <div className="table-row" id="addr0" key={idx}>

                        <div className="table-data">
                          <InputText
                            type="text"
                            name="label"
                            value={addExternalLink.external_links[idx].label}
                            onChange={AddUrlhandleChange(idx)}
                            maxLength={50}
                            className="edit-external-url-label-text-box"
                            placeholder="Enter Label"
                          />

                        </div>
                        <div className="table-data">
                          <InputText
                            type="text"
                            name="url"
                            value={
                              addExternalLink.external_links[idx].url
                            }
                            onChange={AddUrlhandleChange(idx)}
                            maxLength={2000}
                            className="lab-external-url-text-box"
                            placeholder="Enter URL"
                          />
                        </div>
                        <div className="table-data">
                          {addExternalLink.external_links.length - 1 === idx ? (
                            <div onClick={handleAddRowLink} className="add-row-icon">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM10 18.75C5.16751 18.75 1.25 14.8325 1.25 10C1.25 5.16751 5.16751 1.25 10 1.25C14.8325 1.25 18.75 5.16751 18.75 10C18.75 12.3206 17.8281 14.5462 16.1872 16.1872C14.5462 17.8281 12.3206 18.75 10 18.75ZM10.625 9.37505H15.1062C15.4514 9.37505 15.7312 9.65487 15.7312 10C15.7312 10.3452 15.4514 10.625 15.1062 10.625H10.625V15.1063C10.625 15.4515 10.3452 15.7313 9.99999 15.7313C9.65481 15.7313 9.37499 15.4515 9.37499 15.1063V10.625H4.89374C4.54856 10.625 4.26874 10.3452 4.26874 10C4.26874 9.65487 4.54856 9.37505 4.89374 9.37505H9.37499V4.8938C9.37499 4.54862 9.65481 4.2688 9.99999 4.2688C10.3452 4.2688 10.625 4.54862 10.625 4.8938V9.37505Z"
                                  fill={(addExternalLink.external_links[idx].label && addExternalLink.external_links[idx].url) ? "#49AFD9" : "#6D7884"}
                                />
                              </svg>
                            </div>
                          ) : (
                            <div
                              className="remove-row-icon"
                              onClick={handleRemoveSpecificRow(idx)}
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0711 2.92892C13.1658 -0.976326 6.83418 -0.976326 2.92893 2.92892C-0.97631 6.83416 -0.976311 13.1658 2.92893 17.0711C6.83418 20.9763 13.1658 20.9763 17.0711 17.0711C18.9464 15.1957 20 12.6521 20 9.99998C20 7.34782 18.9464 4.80428 17.0711 2.92892ZM3.81282 16.1872C0.39573 12.7701 0.395729 7.22989 3.81282 3.8128C7.2299 0.395713 12.7701 0.395713 16.1872 3.8128C19.6043 7.22989 19.6043 12.7701 16.1872 16.1872C14.5462 17.8281 12.3206 18.75 10 18.75C7.67936 18.75 5.45376 17.8281 3.81282 16.1872ZM10.8838 10L14.0526 13.1687C14.2966 13.4128 14.2966 13.8085 14.0526 14.0526C13.8085 14.2967 13.4128 14.2967 13.1687 14.0526L9.99996 10.8839L6.83123 14.0526C6.58716 14.2967 6.19143 14.2967 5.94735 14.0526C5.70327 13.8085 5.70327 13.4128 5.94735 13.1687L9.11607 10L5.94735 6.83129C5.70327 6.58721 5.70327 6.19148 5.94735 5.9474C6.19143 5.70333 6.58716 5.70333 6.83123 5.9474L9.99996 9.11613L13.1687 5.9474C13.4128 5.70333 13.8085 5.70333 14.0526 5.9474C14.2966 6.19148 14.2966 6.58721 14.0526 6.83129L10.8838 10Z"
                                  fill="#49AFD9"
                                />
                              </svg>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

              </div>
            </div>
            <div className="lab-remarks-container">
              <div className="lab-remarks">
                <div className="lab-remarks-label-new">
                  Remarks
                  <span className="optional-fields"> (Optional)</span>:
                  <span
                    className="custom-target-icon"
                    data-pr-position="top"
                    data-pr-tooltip={
                      "Admin remarks during specific lab stage like Active, Scheduled, Failed, Deleted, etc."
                    }
                  >
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 5.33333C0 2.38781 2.38781 0 5.33333 0C6.74782 0 8.10437 0.561903 9.10457 1.5621C10.1048 2.56229 10.6667 3.91885 10.6667 5.33333C10.6667 8.27885 8.27885 10.6667 5.33333 10.6667C2.38781 10.6667 0 8.27885 0 5.33333ZM5.92444 2.62222C5.92444 2.96587 5.64587 3.24445 5.30222 3.24445C4.95858 3.24445 4.68 2.96587 4.68 2.62222C4.68 2.27858 4.95858 2 5.30222 2C5.64587 2 5.92444 2.27858 5.92444 2.62222ZM5.33333 9.77778C2.87873 9.77778 0.888889 7.78793 0.888889 5.33333C0.888889 2.87873 2.87873 0.888889 5.33333 0.888889C7.78793 0.888889 9.77778 2.87873 9.77778 5.33333C9.77778 6.51207 9.30953 7.64254 8.47603 8.47603C7.64254 9.30953 6.51207 9.77778 5.33333 9.77778ZM5.77778 7.55556H6.66667C6.91213 7.55556 7.11111 7.75454 7.11111 8C7.11111 8.24546 6.91213 8.44444 6.66667 8.44444H4C3.75454 8.44444 3.55556 8.24546 3.55556 8C3.55556 7.75454 3.75454 7.55556 4 7.55556H4.88889V4.88889H4.44444C4.19898 4.88889 4 4.6899 4 4.44444C4 4.19898 4.19898 4 4.44444 4H5.77778V7.55556Z"
                        fill="#49AFD9"
                      />
                    </svg>
                  </span>
                </div>
                <div className="lab-remarks-dropdown-textarea-container">
                  <div className="lab-remarks-dropdown">
                    <Button
                      label="UPDATE REMARKS"
                      className="server-button"
                      style={{ background: "#49AFD9", height: "33px", fontSize: "12px", fontWeight: "600", lineHeight: "12px", letterSpacing: "0.5px" }}
                      onClick={() => labRemarksDialog("labRemarksDisplay")}
                    />
                    <Dialog
                      header="Remarks"
                      visible={labRemarksDisplay}
                      footer={labRemarksRenderFooter("labRemarksDisplay")}
                      onHide={() => labRemarksOnHide("labRemarksDisplay")}
                      className="lab-view-dialog-box"
                    >
                      <div className="lab-remarks-container">
                        <div className="lab-remarks">
                          <div className="lab-remarks-label">
                            Scheduled:
                          </div>
                          <div className="lab-remarks-dropdown">
                            {/* <InputTextarea
                              style={{ width: '400px', height: '60px' }}
                              value={labStatusRemarksForScheduled}
                              onChange={(e) => onLabStatusRemarkEditForScheduled(e)}
                              placeholder="Enter Remarks"
                              maxLength={500}
                            /> */}
                            <Editor 
                              style={{ width: '400px', height: '60px' }} 
                              value={labStatusRemarksForScheduled} 
                              onTextChange={(e) => setLabStatusRemarksForScheduled(e.htmlValue)} 
                              maxLength={500}
                            />
                          </div>

                        </div>
                        <div className="lab-remarks">
                          <div className="lab-remarks-label">
                            Active:
                          </div>
                          <div className="lab-remarks-dropdown">
                            {/* <InputTextarea
                              style={{ width: '400px', height: '60px' }}
                              value={labStatusRemarksForActive}
                              onChange={(e) => onLabStatusRemarkEditForActive(e)}
                              placeholder="Enter Remarks"
                              maxLength={500}
                            /> */}
                            <Editor 
                              style={{ width: '400px', height: '60px' }} 
                              value={labStatusRemarksForActive} 
                              onTextChange={(e) => setLabStatusRemarksForActive(e.htmlValue)} 
                              maxLength={500}
                            />
                          </div>

                        </div>
                        <div className="lab-remarks">
                          <div className="lab-remarks-label">
                            Deleted:
                          </div>
                          <div className="lab-remarks-dropdown">
                            {/* <InputTextarea
                              style={{ width: '400px', height: '60px' }}
                              value={labStatusRemarksForDeleted}
                              onChange={(e) => onLabStatusRemarkEditForDeleted(e)}
                              placeholder="Enter Remarks"
                              maxLength={500}
                            /> */}
                            <Editor 
                              style={{ width: '400px', height: '60px' }} 
                              value={labStatusRemarksForDeleted} 
                              onTextChange={(e) => setLabStatusRemarksForDeleted(e.htmlValue)} 
                              maxLength={500}
                            />
                          </div>

                        </div>
                        <div className="lab-remarks">
                          <div className="lab-remarks-label">
                            Failed:
                          </div>
                          <div className="lab-remarks-dropdown">
                            {/* <InputTextarea
                              style={{ width: '400px', height: '60px' }}
                              value={labStatusRemarksForFailed}
                              onChange={(e) => onLabStatusRemarkEditForFailed(e)}
                              placeholder="Enter Remarks"
                              maxLength={500}
                            /> */}
                            <Editor 
                              style={{ width: '400px', height: '60px' }} 
                              value={labStatusRemarksForFailed} 
                              onTextChange={(e) => setLabStatusRemarksForFailed(e.htmlValue)} 
                              maxLength={500}
                            />
                          </div>

                        </div>
                        <div className="lab-remarks">
                          <div className="lab-remarks-label">
                            Cancelled:
                          </div>
                          <div className="lab-remarks-dropdown">
                            {/* <InputTextarea
                              style={{ width: '400px', height: '60px' }}
                              value={labStatusRemarksForCancelled}
                              onChange={(e) => onLabStatusRemarkEditForCancelled(e)}
                              placeholder="Enter Remarks"
                              maxLength={500}
                            /> */}
                            <Editor 
                              style={{ width: '400px', height: '60px' }} 
                              value={labStatusRemarksForCancelled} 
                              onTextChange={(e) => setLabStatusRemarksForCancelled(e.htmlValue)} 
                              maxLength={500}
                            />
                          </div>

                        </div>
                      </div>
                    </Dialog>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Divider />
          <div className="second-section">
            <div className="second-section-dropdowns">
              <div className="lab-view-label">
                Category:<span className="lab-required-field">*</span>
              </div>

              {/* <Dropdown
                value={category}
                options={categoryList}
                optionLabel="name"
                onChange={(e) => onCategoryChange(e)}
                placeholder="Select Category"
                className="lab-category-dropdown"
                filter
                filterBy="name"
              /> */}
            
        <TreeSelect 
        value={category} 
        options={categoryList} 
        onChange={(e) => setCategory(e.value)} 
        selectionMode="multiple" 
        metaKeySelection={false} 
        className={
          (category===null || (category!==null && Object.keys(category).length === 0))
            ? "lab-category-dropdown-error"
            : "lab-category-dropdown"
        }
        // optionLabel = {"description"}
        // children = {"xyz"}
        filter
        filterBy="label"
        placeholder="Select Items">
        </TreeSelect>
        {(category===null || (category!==null && Object.keys(category).length === 0)) ? (
                  <div className="error-message-inline">
                    <small className="error-message" id="error-message">
                      Category is mandatory
                    </small>
                  </div>
                ) : (
                  ""
                )}
            </div>
            <div className="second-section-dropdowns">
              <div className="lab-view-label">
                Static lab:<span className="lab-required-field">*</span>
              </div>

              <div>
                <Dropdown
                  value={initStatic}
                  options={labStatic}
                  optionLabel="name"
                  onChange={(e) => onStaticLabChange(e)}
                  placeholder="No"
                  className="lab-category-dropdown"
                />
              </div>
            </div>

            <div className="second-section-dropdowns">
              <div className="lab-view-label">
                Workflow:<span className="lab-required-field">*</span>
              </div>

              <Dropdown
                value={typeVal}
                options={labType}
                optionLabel="name"
                onChange={(e) => setTypeVal(e.value)}
                placeholder="Choose a workflow"
                className={
                  typeVal === null
                    ? "lab-category-dropdown-error"
                    : "lab-category-dropdown"
                }
                filter
                filterBy="name"
              />
              {typeVal === null ? (
                <div className="error-message-inline">
                  <small className="error-message" id="error-message">
                    Workflow is mandatory
                  </small>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="second-section-switch">
            <div className="lab-view-label">
              Available Lab:<span className="lab-required-field">*</span>
              <span
                className="custom-target-icon"
                data-pr-position="top"
                data-pr-tooltip={
                  "If “Available Lab” is “Disabled”, the lab will be considered as “Coming Soon” lab."
                }
              >
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 11 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 5.33333C0 2.38781 2.38781 0 5.33333 0C6.74782 0 8.10437 0.561903 9.10457 1.5621C10.1048 2.56229 10.6667 3.91885 10.6667 5.33333C10.6667 8.27885 8.27885 10.6667 5.33333 10.6667C2.38781 10.6667 0 8.27885 0 5.33333ZM5.92444 2.62222C5.92444 2.96587 5.64587 3.24445 5.30222 3.24445C4.95858 3.24445 4.68 2.96587 4.68 2.62222C4.68 2.27858 4.95858 2 5.30222 2C5.64587 2 5.92444 2.27858 5.92444 2.62222ZM5.33333 9.77778C2.87873 9.77778 0.888889 7.78793 0.888889 5.33333C0.888889 2.87873 2.87873 0.888889 5.33333 0.888889C7.78793 0.888889 9.77778 2.87873 9.77778 5.33333C9.77778 6.51207 9.30953 7.64254 8.47603 8.47603C7.64254 9.30953 6.51207 9.77778 5.33333 9.77778ZM5.77778 7.55556H6.66667C6.91213 7.55556 7.11111 7.75454 7.11111 8C7.11111 8.24546 6.91213 8.44444 6.66667 8.44444H4C3.75454 8.44444 3.55556 8.24546 3.55556 8C3.55556 7.75454 3.75454 7.55556 4 7.55556H4.88889V4.88889H4.44444C4.19898 4.88889 4 4.6899 4 4.44444C4 4.19898 4.19898 4 4.44444 4H5.77778V7.55556Z"
                    fill="#49AFD9"
                  />
                </svg>
              </span>
            </div>
            <div className="switch-option">
              <InputSwitch
                className="input-switch"
                checked={isAvailable}
                onChange={(e) => setIsAvailable(e.value)}
              />
              <div className="view-enable-text">
                {isAvailable ? "Available for Scheduling" : "Coming Soon"}
              </div>
            </div>
          </div>
          <Divider />
          <div className="third-section">
            <div className="tag-view-label">
              Tags<span className="optional-fields"> (Optional)</span>:
            </div>
            <div className="lab-tags">
              <div className="lab-tag">
                <Checkbox
                  checked={tag1}
                  onChange={(e) => onTag1Change(e)}
                ></Checkbox>
                <label htmlFor="cb1" className="checkbox-label">
                  read-only
                </label>
              </div>
              <div className="lab-tag">
                <Checkbox
                  checked={tag2}
                  onChange={(e) => onTag2Change(e)}
                ></Checkbox>
                <label htmlFor="cb1" className="checkbox-label">
                  Portal-User
                </label>
              </div>
              <div className="lab-tag">
                <Checkbox
                  className="lab-checkbox"
                  checked={tag3}
                  onChange={(e) => onTag3Change(e)}
                ></Checkbox>
                <label htmlFor="cb1" className="checkbox-label">
                  cec-dev-test
                </label>
              </div>
              <div
                className="add-tag-icon"
                onClick={() => setenableTagInput(true)}
              >
                {" "}
                <i
                  className="pi pi-plus-circle"
                  style={{ fontSize: "1.4em", color: "#49AFD9" }}
                ></i>
              </div>
              {enableTagInput ? (
                <div className="tag-input">
                  <InputText
                    value={additionalTags}
                    placeholder="Add tags"
                    onChange={(e) => createTag(e)}
                    className="addtags-text-box"
                  />
                  {enableTagCreationError ? (
                    <div className="error-message-inline">
                      <small className="error-message" id="error-message">
                        "{additionalTags}" already exists.
                      </small>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div className="icon-msg">Create a new Tag</div>
              )}
            </div>
            <div className="tag-add-confirmation">
              <div className="tag-dropdown">
                <MultiSelect
                  showSelectAll={false}
                  value={defaultPermissionTag}
                  name="category-dropdown"
                  options={permissionTags}
                  onChange={onTagChange}
                  placeholder="Select Tags"
                  optionLabel="tag"
                  className="lab-dropdown select-permissions"
                  display="chip"
                  filter
                  filterBy="tag"
                />
              </div>
              {enableTagInput ? (
                <div className="confirmation-button">
                  <div>
                    <i
                      className="pi pi-exclamation-triangle confirmation-content"
                      style={{ fontSize: "1em" }}
                    ></i>
                  </div>
                  <div className="confirmation-content">
                    Are you sure do you want to add new <b>Tag?</b>
                  </div>
                  <div className="confirmation-content">
                    <div className="tag-button-no" onClick={() => onClickNo()}>
                      NO
                    </div>
                  </div>
                  <div className="confirmation-content">
                    <Button
                      id="reset-button"
                      label="Yes"
                      className={additionalTags ? "tag-button-yes" : "tag-button-yes-disabled"}
                      onClick={() => onClickYes()}
                      disabled={additionalTags ? false : true}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className={initStatic.name === 'No' ? "fourth-section" : "hidden"}>
            <Divider />
            <div className="row-1">
              <div className="label-1">
                Physical Servers:
                <span
                  className="custom-target-icon"
                  data-pr-position="top"
                  data-pr-tooltip={"Minimum number of physical servers required to create this lab (Note: These are the dedicated servers specific to a lab or a solution and not part of the Physical ESXi servers from the nested cluster)"}
                >
                  <svg
                    width="13"
                    height="13"
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 5.33333C0 2.38781 2.38781 0 5.33333 0C6.74782 0 8.10437 0.561903 9.10457 1.5621C10.1048 2.56229 10.6667 3.91885 10.6667 5.33333C10.6667 8.27885 8.27885 10.6667 5.33333 10.6667C2.38781 10.6667 0 8.27885 0 5.33333ZM5.92444 2.62222C5.92444 2.96587 5.64587 3.24445 5.30222 3.24445C4.95858 3.24445 4.68 2.96587 4.68 2.62222C4.68 2.27858 4.95858 2 5.30222 2C5.64587 2 5.92444 2.27858 5.92444 2.62222ZM5.33333 9.77778C2.87873 9.77778 0.888889 7.78793 0.888889 5.33333C0.888889 2.87873 2.87873 0.888889 5.33333 0.888889C7.78793 0.888889 9.77778 2.87873 9.77778 5.33333C9.77778 6.51207 9.30953 7.64254 8.47603 8.47603C7.64254 9.30953 6.51207 9.77778 5.33333 9.77778ZM5.77778 7.55556H6.66667C6.91213 7.55556 7.11111 7.75454 7.11111 8C7.11111 8.24546 6.91213 8.44444 6.66667 8.44444H4C3.75454 8.44444 3.55556 8.24546 3.55556 8C3.55556 7.75454 3.75454 7.55556 4 7.55556H4.88889V4.88889H4.44444C4.19898 4.88889 4 4.6899 4 4.44444C4 4.19898 4.19898 4 4.44444 4H5.77778V7.55556Z"
                      fill="#49AFD9"
                    />
                  </svg>
                </span>
              </div>
              <div className="physical-server-error">
                <InputText
                  value={physicalServer}
                  onChange={(e) => handleAddRow(e)}
                  maxLength={20}
                  className={
                    physicalServer === "" ||
                      physicalServer == null
                      ? "text-box p-invalid block"
                      : "text-box"
                  }
                  keyfilter="num"
                />
                {physicalServer === "" ||
                  physicalServer == null ? (
                  <div
                    id="server-error-message"
                    className="server-error-message p-error block"
                  >
                    Physical servers must be greater than or equal to 0
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            {state.rows.map((item, idx) => (
              // physicalServerArray>0?
              <tr id="addr0">
                {console.log(
                  "state.rows[idx]##############",
                  state.rows[idx].assettags
                )}
                {console.log("cmdbTags ##############", cmdbTags)}
                <td>
                  <div className="server-type">
                    <div className="row-server">
                      <div className="label-server">
                        Server {idx + 1} CMDB Tags:
                        <span className="lab-required-field">*</span>
                      </div>
                      <div className="server-multiselect-error">
                        <MultiSelect
                          type="text"
                          name="assettags"
                          value={state.rows[idx].assettags}
                          onChange={handleChange(idx)}
                          options={cmdbTags}
                          optionLabel="name"
                          className={
                            state.rows[idx].assettags.length === 0
                              ? "lab-dropdown p-invalid block select-cmdb"
                              : "lab-dropdown select-cmdb"
                          }
                          placeholder="Select CMDB tags"
                          display="chip"
                          filter
                          filterBy="name"
                        />
                        {state.rows[idx].assettags.length === 0 ? (
                          <div
                            id="server-error-message"
                            className="server-error-message p-error block"
                          >
                            CMDB Tags are mandatory
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="row-type">
                      <div className="label-type">
                        Server {idx + 1} Image:
                        <span className="lab-required-field">*</span>
                      </div>
                      <div className="labtype-dropdown-error">
                        <Dropdown
                          type="text"
                          name="type"
                          value={state.rows[idx].type}
                          onChange={handleChange(idx)}
                          options={cmdbLabTypeTags}
                          optionLabel="name"
                          placeholder="Select Server Image"
                          className={
                            Object.keys(state.rows[idx].type).length === 0
                              ? "server-lab-dropdown p-invalid block"
                              : "server-lab-dropdown"
                          }
                          // display="chip"
                          filter
                          filterBy="name"
                        />
                        {Object.keys(state.rows[idx].type).length === 0 ? (
                          <div className=" labtype-error-message p-error block">
                            Server {idx + 1} Image is mandatory
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </div>
          <div className={initStatic.name === 'No' ? "" : "hidden"}>
            <Divider />
            <div className="concurrent-inst-container">
              <div className="lab-view-label">
                {" "}
                No. of Concurrent Instances:
              </div>
              <div className="concurrent-inst-dropdown">
                <Dropdown
                  value={concurrentInstances}
                  options={labConcurrentInstances}
                  onChange={(e) => onInstanceQtyChange(e)}
                  className="instance-dropdown"
                />
              </div>
              <div>
                <Button
                  className="server-button"
                  style={{ background: "#49AFD9" }}
                  onClick={() => onCheckServer()}
                >
                  <span className="refresh-icon">
                    <svg
                      width="10.72"
                      height="11.3"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.46668 3.88328C7.46668 4.08394 7.62935 4.24661 7.83001 4.24661H11.4767V0.603281C11.4767 0.401697 11.3133 0.238281 11.1117 0.238281C10.9101 0.238281 10.7467 0.401697 10.7467 0.603281V2.98328C9.34083 0.924576 6.72085 0.0768386 4.37548 0.921773C2.03011 1.76671 0.552718 4.09055 0.782792 6.57283C1.01286 9.05512 2.8922 11.0678 5.35291 11.4673C7.81362 11.8668 10.2332 10.552 11.2367 8.26995C11.3118 8.08291 11.2249 7.87003 11.0403 7.78901C10.8558 7.70798 10.6402 7.78807 10.5533 7.96995C9.68215 9.95407 7.57334 11.0917 5.43676 10.7301C3.30018 10.3685 1.68314 8.60031 1.51336 6.44001C1.34357 4.2797 2.66453 2.28064 4.71839 1.58971C6.77225 0.898769 9.03288 1.69295 10.2033 3.51661H7.83334C7.73583 3.51572 7.64204 3.55406 7.57308 3.62302C7.50412 3.69198 7.46578 3.78576 7.46668 3.88328Z"
                        fill="black"
                      />
                    </svg>{" "}
                    Check Availability
                  </span>
                </Button>
              </div>
            </div>
            <div className="validation-msg">{validateMsg}</div>
          </div>
          <div className={initStatic.name === 'No' ? "" : ""}>
            <Divider />
          </div>
          <div className={initStatic.name === 'No' ? "enable-disable-parent-container" : "hidden"}>
            <div className="enable-disable-lab-container">
              <div className=" enable-disable-lab-label">
                {" "}
                Status:<span className="lab-required-field">*</span>
                <span
                  className="custom-target-icon"
                  data-pr-position="top"
                  data-pr-tooltip={
                    "If Lab is “disabled” then “future schedules” will be cancelled. Currently “Active” labs will not be affected. To disable a lab, choosing date is mandatory."
                  }
                >
                  <svg
                    width="13"
                    height="13"
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 5.33333C0 2.38781 2.38781 0 5.33333 0C6.74782 0 8.10437 0.561903 9.10457 1.5621C10.1048 2.56229 10.6667 3.91885 10.6667 5.33333C10.6667 8.27885 8.27885 10.6667 5.33333 10.6667C2.38781 10.6667 0 8.27885 0 5.33333ZM5.92444 2.62222C5.92444 2.96587 5.64587 3.24445 5.30222 3.24445C4.95858 3.24445 4.68 2.96587 4.68 2.62222C4.68 2.27858 4.95858 2 5.30222 2C5.64587 2 5.92444 2.27858 5.92444 2.62222ZM5.33333 9.77778C2.87873 9.77778 0.888889 7.78793 0.888889 5.33333C0.888889 2.87873 2.87873 0.888889 5.33333 0.888889C7.78793 0.888889 9.77778 2.87873 9.77778 5.33333C9.77778 6.51207 9.30953 7.64254 8.47603 8.47603C7.64254 9.30953 6.51207 9.77778 5.33333 9.77778ZM5.77778 7.55556H6.66667C6.91213 7.55556 7.11111 7.75454 7.11111 8C7.11111 8.24546 6.91213 8.44444 6.66667 8.44444H4C3.75454 8.44444 3.55556 8.24546 3.55556 8C3.55556 7.75454 3.75454 7.55556 4 7.55556H4.88889V4.88889H4.44444C4.19898 4.88889 4 4.6899 4 4.44444C4 4.19898 4.19898 4 4.44444 4H5.77778V7.55556Z"
                      fill="#49AFD9"
                    />
                  </svg>
                </span>
              </div>
              <div className="enable-lab-switch">
                <div>
                  <div className="enable-lab-radiobutton">
                    <RadioButton
                      inputId="option1"
                      name="Lab"
                      value={true}
                      onChange={(e) => onChangeSwitch(e)}
                      checked={enableLab}
                      disabled={disabledField}
                    />
                    <label
                      htmlFor="option1"
                      className={
                        disabledField ? "disable-label" : "radiobutton-label"
                      }
                    >
                      Enable
                    </label>
                  </div>
                  <div className="disable-radio-btn">
                    <div className="disable-lab-radiobutton">
                      <RadioButton
                        inputId="option2"
                        name="Lab"
                        value={false}
                        onChange={(e) => onChangeSwitch(e)}
                        checked={!enableLab}
                        disabled={!disabledField}
                      />
                      <label
                        htmlFor="option2"
                        className={
                          !disabledField ? "disable-label" : "radiobutton-label"
                        }
                      >
                        Disable
                      </label>
                    </div>
                    {enableCalendar ? (
                      <div className="disable-lab-calendar">
                        <Calendar
                          id="icon"
                          minDate={
                            new Date()
                          }
                          selectOtherMonths={true}
                          value={date}
                          disabledDates={strikeOutDates}
                          className={
                            isEmptyDate && enableCalendar ? "error-block" : ""
                          }
                          onChange={(e) =>
                            setDate(moment(e.value).format("YYYY-MM-DD"))
                          }
                          onSelect={(e) => onSelectDate(e)}
                          placeholder="Select Date"
                          showIcon
                          readOnlyInput
                        />
                        {isEmptyDate && enableCalendar ? (
                          <div className="error-message-inline">
                            <small className="error-message" id="error-message">
                              To Disable a lab, the date should be mandatory
                            </small>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="disable-lab-message">
                      {!enableLab ? dateDisableMessage : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={!enableCalendar && !enableLab ? "enable-disable-disable-by-container" : "hidden"}>
              <div className="disabled-by-label">
                Disable by<span className="lab-required-field">*</span> :
              </div>
              <div className="disabled-by-value">
                {labDisabledBy}
              </div>
            </div>
            <div className={!enableLab ? "enable-disable-reason-container" : "hidden"}>
              <div className="disabled-reason-label">
                Reason for Disable<span className="lab-required-field">*</span> :
              </div>
              <div className="disabled-reason-textbox">
                <InputTextarea
                  style={{ width: '514px', height: '60px' }}
                  value={labDisabledReason}
                  onChange={(e) => onLabDisableReasonEdit(e)}
                  placeholder="Enter reason for disable"
                  maxLength={500}
                  className={
                    !labDisabledReason
                      ? "lab-name-text-box-input-error"
                      : "lab-name-text-box"
                  }
                />
                {!labDisabledReason ? (
                  <div className="error-message-inline">
                    <small className="error-message" id="error-message">
                      Reason for disable is mandatory
                    </small>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className={initStatic.name === 'No' ? "hidden" : ""}>
            <Divider />
            <div className="concurrent-send-cred-container">
              <div className="concurrent-inst-container">
                <div className="lab-view-label">
                  {" "}
                  No. of Concurrent Instances:
                </div>
                <div className="concurrent-inst-dropdown">
                  <Dropdown
                    value={staticLabDetails?.length}
                    options={labConcurrentInstances}
                    onChange={(e) => onStaticInstanceQtyChange(e)}
                    className="instance-dropdown"
                  />
                </div>
              </div>
              <div className="send-credentials-container">
                <div>
                  <Checkbox
                    className="send-credentials-checkbox"
                    onChange={(e) => onUrlWithPasswordChange(e)}
                    checked={urlWithPassword}
                  ></Checkbox>
                </div>

                <div htmlFor="cb1" className="checkbox-label">
                  Share credentials in email
                </div>
              </div>
            </div>
            {

              staticLabDetails.map((element, index) => (
                <div className="static-div m-t-15">
                  <div className="second-section m-0">
                    <div className="second-section-dropdowns w-50">
                      <div className="lab-view-label">
                        Lab Id:<span className="lab-required-field">*</span>
                      </div>

                      <div>
                        <Dropdown
                          value={staticLabDetails[index]?.lab_reference_id}
                          options={staticInstanceList}
                          onChange={(e) => onStaticLabIdChange(e, index)}
                          className="lab-category-dropdown w-77"
                        />
                      </div>
                    </div>
                    <div className="second-section-dropdowns  m-r-0">
                      <div className="lab-view-label">
                        Connect Lab URL:<span className="lab-required-field">*</span>
                      </div>

                      <div>
                        <InputText
                          value={staticLabDetails[index]?.lab_connect_url}
                          maxLength={8641}
                          className={!is_url(staticLabDetails[index]?.lab_connect_url) && isStaticValidated ?
                            "lab-external-url-text-box error-border" :
                            "lab-external-url-text-box"}
                          onChange={(e) => setStaticConnetLabURL(e, index)}
                          placeholder="Connect Lab URL"
                        />
                          {!is_url(staticLabDetails[index]?.lab_connect_url) && isStaticValidated ?
                              (
                                <div className="cat-error-message-inline">
                                  <small className="cat-error-message" id="error-message">
                                    Invalid link
                                  </small>
                                </div>
                              ):""}
                      </div>
                    </div>
                  </div>
                  <div className="second-section m-0 m-t-12">
                    <div className="second-section-dropdowns">
                      <div className="lab-view-label">Username:{urlWithPassword ? (<span className="lab-required-field">*</span>) : (<span></span>)}</div>

                      <div>
                        <InputText
                          value={staticLabDetails[index].username}
                          maxLength={8641}
                          className="text-box w-18_8"
                          onChange={(e) => setStaticUsername(e, index)}
                          placeholder="Enter Username"
                        />
                      </div>
                    </div>
                    <div className="second-section-dropdowns  m-r-0">
                      <div className="lab-view-label">Password:{urlWithPassword ? (<span className="lab-required-field">*</span>) : (<span></span>)}</div>

                      <div>
                        <InputText
                          value={staticLabDetails[index]?.password}
                          maxLength={8641}
                          className="text-box w-18_8"
                          onChange={(e) => setStaticPassword(e, index)}
                          placeholder="Enter Password"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
            <div className={initStatic.name === 'Yes' ? "" : ""}>
              <Divider />
            </div>
            <div className={initStatic.name === 'Yes' ? "enable-disable-parent-container" : "hidden"}>
              <div className="enable-disable-lab-container">
                <div className=" enable-disable-lab-label">
                  {" "}
                  Status:<span className="lab-required-field">*</span>
                  <span
                    className="custom-target-icon"
                    data-pr-position="top"
                    data-pr-tooltip={
                      "If Lab is “disabled” then “future schedules” will be cancelled. Currently “Active” labs will not be affected. To disable a lab, choosing date is mandatory."
                    }
                  >
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 5.33333C0 2.38781 2.38781 0 5.33333 0C6.74782 0 8.10437 0.561903 9.10457 1.5621C10.1048 2.56229 10.6667 3.91885 10.6667 5.33333C10.6667 8.27885 8.27885 10.6667 5.33333 10.6667C2.38781 10.6667 0 8.27885 0 5.33333ZM5.92444 2.62222C5.92444 2.96587 5.64587 3.24445 5.30222 3.24445C4.95858 3.24445 4.68 2.96587 4.68 2.62222C4.68 2.27858 4.95858 2 5.30222 2C5.64587 2 5.92444 2.27858 5.92444 2.62222ZM5.33333 9.77778C2.87873 9.77778 0.888889 7.78793 0.888889 5.33333C0.888889 2.87873 2.87873 0.888889 5.33333 0.888889C7.78793 0.888889 9.77778 2.87873 9.77778 5.33333C9.77778 6.51207 9.30953 7.64254 8.47603 8.47603C7.64254 9.30953 6.51207 9.77778 5.33333 9.77778ZM5.77778 7.55556H6.66667C6.91213 7.55556 7.11111 7.75454 7.11111 8C7.11111 8.24546 6.91213 8.44444 6.66667 8.44444H4C3.75454 8.44444 3.55556 8.24546 3.55556 8C3.55556 7.75454 3.75454 7.55556 4 7.55556H4.88889V4.88889H4.44444C4.19898 4.88889 4 4.6899 4 4.44444C4 4.19898 4.19898 4 4.44444 4H5.77778V7.55556Z"
                        fill="#49AFD9"
                      />
                    </svg>
                  </span>
                </div>
                <div className="enable-lab-switch">
                  <div>
                    <div className="enable-lab-radiobutton">
                      <RadioButton
                        inputId="option1"
                        name="Lab"
                        value={true}
                        onChange={(e) => onChangeSwitch(e)}
                        checked={enableLab}
                        disabled={disabledField}
                      />
                      <label
                        htmlFor="option1"
                        className={
                          disabledField ? "disable-label" : "radiobutton-label"
                        }
                      >
                        Enable
                      </label>
                    </div>
                    <div className="disable-radio-btn">
                      <div className="disable-lab-radiobutton">
                        <RadioButton
                          inputId="option2"
                          name="Lab"
                          value={false}
                          onChange={(e) => onChangeSwitch(e)}
                          checked={!enableLab}
                          disabled={!disabledField}
                        />
                        <label
                          htmlFor="option2"
                          className={
                            !disabledField ? "disable-label" : "radiobutton-label"
                          }
                        >
                          Disable
                        </label>
                      </div>
                      {enableCalendar ? (
                        <div className="disable-lab-calendar">
                          <Calendar
                            id="icon"
                            minDate={
                              new Date()
                            }
                            selectOtherMonths={true}
                            value={date}
                            disabledDates={strikeOutDates}
                            className={
                              isEmptyDate && enableCalendar ? "error-block" : ""
                            }
                            onChange={(e) =>
                              setDate(moment(e.value).format("YYYY-MM-DD"))
                            }
                            onSelect={(e) => onSelectDate(e)}
                            placeholder="Select Date"
                            showIcon
                            readOnlyInput
                          />
                          {isEmptyDate && enableCalendar ? (
                            <div className="error-message-inline">
                              <small className="error-message" id="error-message">
                                To Disable a lab, the date should be mandatory
                              </small>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="disable-lab-message">
                        {!enableLab ? dateDisableMessage : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={!enableCalendar && !enableLab ? "enable-disable-disable-by-container" : "hidden"}>
                <div className="disabled-by-label">
                  Disable by<span className="lab-required-field">*</span> :
                </div>
                <div className="disabled-by-value">
                  {labDisabledBy}
                </div>
              </div>
              <div className={!enableLab ? "enable-disable-reason-container" : "hidden"}>
                <div className="disabled-reason-label">
                  Reason for Disable<span className="lab-required-field">*</span> :
                </div>
                <div className="disabled-reason-textbox">
                  <InputTextarea
                    style={{width:'514px', height:'60px'}}
                    value={labDisabledReason}
                    onChange={(e) => onLabDisableReasonEdit(e)}
                    placeholder="Enter reason for disable"
                    maxLength={500}
                  />
                </div>
              </div>
            </div>

          </div>
          <div className={initStatic.name === 'Yes' ? "" : ""}>
            <Divider />
          </div>
          <div className="fifth-section">
            <div>
              <div className="col-1">
                <div>
                  <div className="text-box-label-1">
                    Lab Utilization Expected Time (Hours):
                    <span className="lab-required-field">*</span>
                    <span
                      className="custom-target-icon"
                      data-pr-position="top"
                      data-pr-tooltip={"Minimum time in hours this lab will be utilised to perform a PoC/Demo activity after it is successfully activated - default: 4 hours"}
                    >
                      <svg
                        width="13"
                        height="13"
                        viewBox="0 0 11 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0 5.33333C0 2.38781 2.38781 0 5.33333 0C6.74782 0 8.10437 0.561903 9.10457 1.5621C10.1048 2.56229 10.6667 3.91885 10.6667 5.33333C10.6667 8.27885 8.27885 10.6667 5.33333 10.6667C2.38781 10.6667 0 8.27885 0 5.33333ZM5.92444 2.62222C5.92444 2.96587 5.64587 3.24445 5.30222 3.24445C4.95858 3.24445 4.68 2.96587 4.68 2.62222C4.68 2.27858 4.95858 2 5.30222 2C5.64587 2 5.92444 2.27858 5.92444 2.62222ZM5.33333 9.77778C2.87873 9.77778 0.888889 7.78793 0.888889 5.33333C0.888889 2.87873 2.87873 0.888889 5.33333 0.888889C7.78793 0.888889 9.77778 2.87873 9.77778 5.33333C9.77778 6.51207 9.30953 7.64254 8.47603 8.47603C7.64254 9.30953 6.51207 9.77778 5.33333 9.77778ZM5.77778 7.55556H6.66667C6.91213 7.55556 7.11111 7.75454 7.11111 8C7.11111 8.24546 6.91213 8.44444 6.66667 8.44444H4C3.75454 8.44444 3.55556 8.24546 3.55556 8C3.55556 7.75454 3.75454 7.55556 4 7.55556H4.88889V4.88889H4.44444C4.19898 4.88889 4 4.6899 4 4.44444C4 4.19898 4.19898 4 4.44444 4H5.77778V7.55556Z"
                          fill="#49AFD9"
                        />
                      </svg>
                    </span>
                  </div>
                  {labUtilizationError ? (
                    <div className="error-message-inline">
                      <small className="error-message" id="error-message">
                        {labUtilizationErrorMsg}
                      </small>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <InputText
                    id="labUtilizationDays"
                    value={labUtilization}
                    onChange={(e) => onLabUtilization(e)}
                    maxLength={8641}
                    className={labValidationError ? "text-box-error" : "text-box"}
                    placeholder="4"
                    onInput={onDurationChange}
                    keyfilter="pnum"
                  />
                </div>
                <div className="p-ml-1">days</div>
                <div className="p-ml-3">
                  <InputText
                    id="labUtilizationHours"
                    value={labUtilizationHours}
                    onChange={(e) => onLabUtilization(e)}
                    maxLength={8641}
                    className={labValidationError ? "text-box-error" : "text-box"}
                    placeholder="4"
                    onInput={onDurationChange}
                    keyfilter="pnum"
                  />
                </div>
                <div className="p-ml-1">hours</div>
              </div>






              <div>
                <div className="max-schedule">
                  <div>
                    <div className="text-box-label-6">
                      Maximum Schedule Period (Days):
                      <span className="lab-required-field">*</span>
                      <span
                        className="custom-target-icon"
                        data-pr-position="top"
                        data-pr-tooltip={"Maximum duration in days the lab can be scheduled for an user"}
                      >
                        <svg
                          width="13"
                          height="13"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0 5.33333C0 2.38781 2.38781 0 5.33333 0C6.74782 0 8.10437 0.561903 9.10457 1.5621C10.1048 2.56229 10.6667 3.91885 10.6667 5.33333C10.6667 8.27885 8.27885 10.6667 5.33333 10.6667C2.38781 10.6667 0 8.27885 0 5.33333ZM5.92444 2.62222C5.92444 2.96587 5.64587 3.24445 5.30222 3.24445C4.95858 3.24445 4.68 2.96587 4.68 2.62222C4.68 2.27858 4.95858 2 5.30222 2C5.64587 2 5.92444 2.27858 5.92444 2.62222ZM5.33333 9.77778C2.87873 9.77778 0.888889 7.78793 0.888889 5.33333C0.888889 2.87873 2.87873 0.888889 5.33333 0.888889C7.78793 0.888889 9.77778 2.87873 9.77778 5.33333C9.77778 6.51207 9.30953 7.64254 8.47603 8.47603C7.64254 9.30953 6.51207 9.77778 5.33333 9.77778ZM5.77778 7.55556H6.66667C6.91213 7.55556 7.11111 7.75454 7.11111 8C7.11111 8.24546 6.91213 8.44444 6.66667 8.44444H4C3.75454 8.44444 3.55556 8.24546 3.55556 8C3.55556 7.75454 3.75454 7.55556 4 7.55556H4.88889V4.88889H4.44444C4.19898 4.88889 4 4.6899 4 4.44444C4 4.19898 4.19898 4 4.44444 4H5.77778V7.55556Z"
                            fill="#49AFD9"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>

                  <div className="p-ml-6">
                    <InputText
                      value={maxSchedulePeriod}
                      onChange={(e) => onMaxSchedulePeriod(e)}
                      maxLength={999}
                      className={
                        !maxSchedulePeriod ? "text-box-error" : "text-box"
                      }
                      placeholder="2"
                      onInput={onDurationChange}
                      keyfilter="pint"
                    />
                  </div>
                </div>
                {maxSchedulePeriodError ? (
                  <div className="inline-error-message">
                    <small className="error-message" id="error-message">
                      {maxSchedulePeriodErrorMsg}
                    </small>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        header={`Disable Lab : ${labName}`}
        className="disable-lab-dialog"
        visible={disableLabDisplay}
        footer={() => disableLabRenderFooter("disableLabDisplay")}
        onHide={() => disableLabOnHide("disableLabDisplay")}
      >
        <div className={commonErrorMsg ? "" : "hidden"}>
          {commonErrorMsg && <Message severity="warn" text={commonErrorMsg} className='common-inline-error common-error-message p-mb-3' />}
        </div>
        {isScheduledLabs ? (

          <div>
            <div className="p-mb-2">Upcomming Schedules for Lab</div>
            <DataTable
              value={disableScheduled}
              // ref={dt}
              columnResizeMode="fit"
              className="disable-lab-crud-table"
              autoLayout={true}
              responsiveLayout="scroll"
            >
              <Column
                field="lab_owner_name"
                header="Lab Owner Name"
                style={{ minWidth: "10rem" ,paddingLeft:"15px" }}
              ></Column>
              <Column
                field="schedule_start_date"
                header="Start Date"
                style={{ minWidth: "6.5rem" }}
              ></Column>
              <Column
                field="schedule_end_date"
                header="End Date"
                style={{ minWidth: "5rem" }}
              ></Column>
              <Column
                field="user_time_zone"
                header="Timezone"
                style={{ minWidth: "5rem" }}
              ></Column>
              <Column
                field="client_name"
                header="Client Name"
                style={{ minWidth: "5rem" }}
              ></Column>
              <Column
                field="purpose"
                header="Purpose"
                style={{ minWidth: "5rem" }}
              ></Column>
            </DataTable>
            <div className="lab-schedule-warning">
              <div className="lab-warning-icon">
                <svg
                  width="17"
                  height="15.98"
                  viewBox="0 0 18 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.7266 1.06685L17.22 13.0269V13.0469C17.5562 13.6669 17.5409 14.4182 17.1799 15.024C16.8188 15.6299 16.1653 16.0008 15.46 16.0002H2.4733C1.76195 16.0048 1.10172 15.6311 0.739365 15.019C0.377014 14.4068 0.367087 13.6483 0.713297 13.0269L7.2133 1.06685C7.56349 0.423434 8.23742 0.0229492 8.96996 0.0229492C9.70251 0.0229492 10.3764 0.423434 10.7266 1.06685ZM15.46 14.6469C15.6953 14.6468 15.9133 14.5227 16.0333 14.3202C16.154 14.118 16.1591 13.8671 16.0466 13.6602L9.5533 1.70019C9.43692 1.48457 9.21165 1.35017 8.96663 1.35017C8.72161 1.35017 8.49634 1.48457 8.37996 1.70019L1.88663 13.6602C1.77346 13.867 1.77795 14.1182 1.89845 14.3209C2.01895 14.5236 2.23753 14.6475 2.4733 14.6469H15.46ZM8.96663 13.1802C9.51892 13.1802 9.96663 12.7325 9.96663 12.1802C9.96663 11.6279 9.51892 11.1802 8.96663 11.1802C8.41435 11.1802 7.96663 11.6279 7.96663 12.1802C7.96663 12.7325 8.41435 13.1802 8.96663 13.1802ZM9.86663 9.34685C9.86663 9.8255 9.47861 10.2135 8.99996 10.2135C8.76778 10.2135 8.54529 10.1204 8.38237 9.95498C8.21945 9.78955 8.12972 9.56567 8.1333 9.33352V5.33352C8.1333 4.85487 8.52132 4.46685 8.99996 4.46685C9.47861 4.46685 9.86663 4.85487 9.86663 5.33352V9.34685Z"
                    fill="#E9ECEF"
                  />
                </svg>
              </div>
              <div className="lab-warning-msg">
                <div>
                  If you disable the lab, users won’t be able to schedule this
                  lab.
                </div>
                <div>Also, future schedules of this lab will be cancelled.</div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="no-scheduled-icon">
              <div>
                <svg
                  width="44"
                  height="51"
                  viewBox="0 0 44 51"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.6556 49.8753C20.9977 49.8654 20.3548 49.8613 19.725 49.8315L19.687 50.6404C20.33 50.671 20.9845 50.6817 21.6564 50.685C22.1459 50.685 22.6296 50.6759 23.1042 50.6586L23.0736 49.8481C22.6081 49.8654 22.136 49.8753 21.6556 49.8753Z"
                    fill="#66B0D6"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.4979 42.6001C19.3284 42.6315 18.1802 42.7124 17.0874 42.8419L17.1815 43.6459C18.2504 43.5196 19.3746 43.4404 20.5186 43.4098L20.4979 42.6001Z"
                    fill="#66B0D6"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.85219 46.6353C9.85219 46.5495 9.86788 46.4645 9.89759 46.3811L9.13492 46.1079C9.07385 46.2788 9.04248 46.4562 9.04248 46.6353C9.04248 47.5334 9.79524 48.3324 11.2785 49.0075L11.6136 48.2704C10.4787 47.7538 9.85219 47.1718 9.85219 46.6353Z"
                    fill="#66B0D6"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.5483 43.252C13.2326 43.5285 12.1192 43.8751 11.2368 44.282L11.5752 45.0175C12.4023 44.6361 13.4588 44.3093 14.7142 44.0443L14.5483 43.252Z"
                    fill="#66B0D6"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.7495 49.8364C14.7722 50.0906 15.9096 50.2912 17.1312 50.434L17.2236 49.6292C16.0367 49.4906 14.9348 49.2958 13.9443 49.0498L13.7495 49.8364Z"
                    fill="#66B0D6"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M32.2958 44.4455C31.4465 44.0081 30.3388 43.6325 29.0033 43.3296L28.8242 44.1187C30.0747 44.4026 31.1469 44.765 31.9244 45.1653L32.2958 44.4455Z"
                    fill="#66B0D6"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M26.4669 42.8862C25.3807 42.745 24.2334 42.6526 23.0588 42.6113L23.0308 43.4202C24.1797 43.4615 25.3014 43.5523 26.3629 43.6901L26.4669 42.8862Z"
                    fill="#66B0D6"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M33.3422 46.635C33.3422 47.2244 32.5713 47.8748 31.2275 48.4212L31.5338 49.1706C33.6988 48.2924 34.1519 47.3061 34.1519 46.635L34.1503 46.5698L33.3422 46.635Z"
                    fill="#66B0D6"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M25.5752 49.6725L25.661 50.4781C26.876 50.3502 28.0151 50.1661 29.0468 49.93L28.8661 49.1401C27.8657 49.3688 26.7596 49.5487 25.5752 49.6725Z"
                    fill="#66B0D6"
                  />
                  <mask
                    id="mask0_13387_14263"
                    style={{ "maskType": "alpha" }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="44"
                    height="39"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 0H43.4363V38.8761H0V0Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask0_13387_14263)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M36.8754 8.35797C32.8368 9.35505 27.4543 9.90476 21.7187 9.90476C15.983 9.90476 10.5997 9.35505 6.56108 8.35797C2.03708 7.24038 0.830349 5.95029 0.830349 5.36508C0.830349 4.77987 2.03708 3.4906 6.56108 2.37302C10.5997 1.37511 15.983 0.825397 21.7187 0.825397C27.4543 0.825397 32.8368 1.37511 36.8754 2.37302C41.3994 3.4906 42.6062 4.77987 42.6062 5.36508C42.6062 5.95029 41.3994 7.24038 36.8754 8.35797ZM23.7987 24.4937V36.1961C23.5164 36.6699 22.8759 37.2039 21.9217 37.6092C20.8297 38.0723 20.0159 38.0855 19.6717 37.979V24.4937L3.31232 8.21187C7.15289 9.724 13.9583 10.7302 21.7187 10.7302C29.5145 10.7302 36.348 9.71492 40.1787 8.19124L23.7987 24.4937ZM43.4365 5.36508C43.4365 2.4019 33.7133 0 21.7187 0C9.72317 0 0 2.4019 0 5.36508C0 5.92387 0.346667 6.46203 0.987175 6.968L0.986349 6.96883L18.8463 24.8238V38.4098C19.1013 38.7359 19.566 38.8762 20.1248 38.8762C20.767 38.8762 21.5338 38.6905 22.2461 38.3884C23.419 37.8898 24.4672 37.0669 24.6224 36.2729V24.8238L42.4848 6.96883L42.4683 6.95314C43.0973 6.4513 43.4365 5.9181 43.4365 5.36508Z"
                      fill="#66B0D6"
                    />
                  </g>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.56115 2.37265C2.03715 3.49023 0.82959 4.7795 0.82959 5.36471C0.82959 5.95074 2.03715 7.24001 6.56115 8.3576C9.14794 8.99646 12.2894 9.4496 15.7197 9.69392L12.7574 1.31201C10.4834 1.57036 8.38032 1.92363 6.56115 2.37265Z"
                    fill="#66B0D6"
                    fill-opacity="0.4"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.5003 10.5367L22.2178 24.4867V37.4628C23.0011 37.0798 23.5483 36.6168 23.7992 36.1958V24.4933L40.1784 8.19092C37.3316 9.32336 32.8258 10.1743 27.5003 10.5367Z"
                    fill="#66B0D6"
                    fill-opacity="0.4"
                  />
                </svg>
              </div>
              <div className="lab-scheduled-msg">
                We couldn’t find any lab Schedule!
              </div>
            </div>
            <div className="lab-schedule-warning">
              <div className="lab-warning-icon">
                <svg
                  width="17"
                  height="15.98"
                  viewBox="0 0 18 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.7266 1.06685L17.22 13.0269V13.0469C17.5562 13.6669 17.5409 14.4182 17.1799 15.024C16.8188 15.6299 16.1653 16.0008 15.46 16.0002H2.4733C1.76195 16.0048 1.10172 15.6311 0.739365 15.019C0.377014 14.4068 0.367087 13.6483 0.713297 13.0269L7.2133 1.06685C7.56349 0.423434 8.23742 0.0229492 8.96996 0.0229492C9.70251 0.0229492 10.3764 0.423434 10.7266 1.06685ZM15.46 14.6469C15.6953 14.6468 15.9133 14.5227 16.0333 14.3202C16.154 14.118 16.1591 13.8671 16.0466 13.6602L9.5533 1.70019C9.43692 1.48457 9.21165 1.35017 8.96663 1.35017C8.72161 1.35017 8.49634 1.48457 8.37996 1.70019L1.88663 13.6602C1.77346 13.867 1.77795 14.1182 1.89845 14.3209C2.01895 14.5236 2.23753 14.6475 2.4733 14.6469H15.46ZM8.96663 13.1802C9.51892 13.1802 9.96663 12.7325 9.96663 12.1802C9.96663 11.6279 9.51892 11.1802 8.96663 11.1802C8.41435 11.1802 7.96663 11.6279 7.96663 12.1802C7.96663 12.7325 8.41435 13.1802 8.96663 13.1802ZM9.86663 9.34685C9.86663 9.8255 9.47861 10.2135 8.99996 10.2135C8.76778 10.2135 8.54529 10.1204 8.38237 9.95498C8.21945 9.78955 8.12972 9.56567 8.1333 9.33352V5.33352C8.1333 4.85487 8.52132 4.46685 8.99996 4.46685C9.47861 4.46685 9.86663 4.85487 9.86663 5.33352V9.34685Z"
                    fill="#E9ECEF"
                  />
                </svg>
              </div>
              <div className="lab-warning-msg">
                If you disable the lab, users won’t be able to schedule this
                lab.
              </div>
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default UpdateLab;
