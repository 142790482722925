import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import React, { useState, useRef, useEffect } from 'react'
import LabServices from '../../../../services/LabServices';
import './VirtualEnvironment.css';
import { confirmPopup } from "primereact/confirmpopup";
import { InputTextarea } from 'primereact/inputtextarea';

const UpdateVirtualEnv = ({ isDisabled, rowId, fetchData, showToastSuccess }) => {

  const [updateVirtualEnvDisplay, setupdateVirtualEnvDisplay] = useState(false);
  const [virtualEnvId, setVirtualEnvId] = useState("");
  const [isStatic, setIsStatic] = useState(true);
  const [isStatus, setIsStatus] = useState(false);
  const [remarks, setRemarks] = useState("")

  const updateVirtualEnvDialogMap = {
    updateVirtualEnvDisplay: setupdateVirtualEnvDisplay,
  };

  const updateList = () => fetchData()

  const showToast = (data) => showToastSuccess(data)

  async function getVirtualEnvDetailsById() {
    await LabServices.getVirtualEnvironmentDetailsById(rowId).then((response) => {
      setVirtualEnvId(response.data.virtual_environment_id);
      setIsStatic(response.data.is_static_lab);
      setIsStatus(response.data.enabled);
      setRemarks(response.data.remarks);
    })
  }

  const onHide = (name) => {
    updateVirtualEnvDialogMap[`${name}`](false);
    setVirtualEnvId("");
    setIsStatic(true);
    setIsStatus(false);
    setRemarks("");
  }

  const onClick = async (name) => {
    await getVirtualEnvDetailsById();
    updateVirtualEnvDialogMap[`${name}`](true);
  };

  const onStaticChange = (e) => {
    setIsStatic(e.target.value);
  }

  const onStatusChange = (e) => {
    setIsStatus(e.target.value);
  }

  const renderFooter = (name) => {
    return (
      <div className="terms-section">
        <Button
          className="create-user-button"
          label="UPDATE"
          style={{ background: "#49AFD9" }}
          onClick={confirmUpdate}
        />
      </div>
    );
  };

  const confirmUpdate = (event) => {
    confirmPopup({
      target: event.currentTarget,
      className: "popup",
      message: "Want to update the changes?",
      icon: "pi pi-exclamation-triangle",
      accept: () => updateVitualEnvDetails(),
    });
  };

  const requestPayload = {
    virtual_environment_id: +virtualEnvId,
    is_static_lab: isStatic,
    enabled: isStatus,
    remarks: remarks
  }

  const updateVitualEnvDetails = () => {
    LabServices.updateVirtualEnvironmentDetailsById(rowId, requestPayload).then((response) => {
      updateVirtualEnvDialogMap[`updateVirtualEnvDisplay`](false);
      setVirtualEnvId("");
      setIsStatic(true);
      setIsStatus(false);
      setRemarks("");
      showToast({
        summary: "Virtual Environment Updated",
        detail: "Virtual Enviroment details has been updated successfully."
      })
      updateList()
    }).catch((error) => {
      console.log(error);
    })
  }

  const onRemarkEdit = (e) => {
    setRemarks(e.target.value)
  }

  return (
    <div className='update-virtual-env-container'>
      <div className="update-virtual-env-actions p-d-flex">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded update-user-button virtual-env-update-btn"
          disabled={!isDisabled}
          onClick={() => onClick("updateVirtualEnvDisplay")}
        />
      </div>
      {/* update virtual env dialog */}
      <Dialog
        header={`Update : Virtual Environment ID ${rowId}`}
        visible={updateVirtualEnvDisplay}
        footer={renderFooter("updateVirtualEnvDisplay")}
        onHide={() => onHide("updateVirtualEnvDisplay")}
        className="lab-dialog-box"
        style={{ width: "450px" }}
      >
        <div className="dialog-content">
          <div className="first-section">
            <div className="name-input-text">
              <div className="namefield-label virtual-env-label">
                Virtual Environment ID<span className="mandatory-label">*</span> :
              </div>
              <div>
                <InputText
                  name="virtualEnvId"
                  value={virtualEnvId}
                  maxLength={100}
                  placeholder="Enter Virtual Environment ID"
                  keyfilter="num"
                  className='namefield-text-box'
                  defaultValue={virtualEnvId}
                />
              </div>
            </div>
          </div>
          <div className="section third-section">
            <div className="name-input-text">
              <div className="namefield-label virtual-env-label">Static lab<span className="mandatory-label">*</span> :</div>
              <InputSwitch
                checked={isStatic}
                onChange={onStaticChange}
                defaultValue={isStatic}
              />
              <span className="virtual-env-toggle-label">
                {isStatic ? "Yes" : "No"}
              </span>
            </div>
          </div>
          <div className="section third-section">
            <div className="name-input-text">
              <div className="namefield-label virtual-env-label">Status<span className="mandatory-label">*</span> :</div>
              <InputSwitch
                checked={isStatus}
                onChange={onStatusChange}
                defaultValue={isStatus}
              />
              <span className="virtual-env-toggle-label">
              Virtual Environment is {isStatus? "Enabled" : "Disabled"}
              </span>
            </div>
          </div>
          <div className='section third-section'>
            <div className='name-input-text'>
              <div className="namefield-label virtual-env-label">Remarks<span className="optional-fields"> (Optional)</span>:</div>
              <InputTextarea
                style={{ width: '243px', height: '60px' }}
                value={remarks}
                onChange={(e) => onRemarkEdit(e)}
                placeholder="Enter Remarks"
                maxLength={500}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default UpdateVirtualEnv