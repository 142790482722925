import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { Dialog } from 'primereact/dialog'
import { Divider } from 'primereact/divider'
import { Dropdown } from 'primereact/dropdown'
import { InputTextarea } from 'primereact/inputtextarea'
import { Toast } from 'primereact/toast'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom"
import "./CloseAccount.css"
import loglevel from "../../logger/Logger";
import LabServices from '../../services/LabServices'

const CloseAccount = () => {
    const [comments, setComments] = useState("")
    const toast = useRef(null)
    let navigate = useNavigate();
    const [reason, setReason] = useState(1)
    const [isValidated, setIsValidate] = useState(false)
    const [closeAccountDisplay, setCloseAccountDisplay] = useState(false);
    const [deleteCheck,setDeleteCheck] = useState(false)
    const [emailLabel,setEmailLabel]=useState(null)
    const [emailUrl,setEmailUrl]=useState(null)
    const [reasonList,setReasonList] = useState([])
    const [reqBody,setReqBody] = useState({})
    const closeAccountDialogMap = {
        closeAccountDisplay: setCloseAccountDisplay,
    };

        useEffect(() => {
            async function fetchData() {
              // api call for listing reasons to close account
              await LabServices.getCloseAccountReasons()
                .then((res) => {
                  console.log(res,"reasons");
                setReasonList(res.data)
                })
                .catch((error) => {
                  loglevel.error(error);
                });

                // api to show email for contact
                await LabServices.getContactUsUrls(0)
                .then((res) => {
                  console.log(res.data, "urls");
                  setEmailLabel(res.data.contact1_text)
                  setEmailUrl(res.data.contact1_url)
                })
                .catch((error) => {
                  loglevel.error(error);
                });
       } fetchData();},[])

     // method call for closing dialog
    const onHide = (name) => {
        setDeleteCheck(false)
        setIsValidate(false)
        closeAccountDialogMap[`${name}`](false);
    };

    // on click on submit button
    const onSubmit = (name) => {
        setIsValidate(true)
        if(comments){
         setReqBody( {
            users_id: parseInt(localStorage.getItem("users_id")),
            reason: parseInt(reason),
            comment: comments
        })
        setDeleteCheck(false)
        setIsValidate(false)
        // opening confirmation dialog
        closeAccountDialogMap[`${name}`](true);
    }

    };

    // on cancelling close account
    const onCancel= (name) =>{
        closeAccountDialogMap[`${name}`](false);
    }

    const onDeleteAccount= async() =>{
       
      // api call for deleting account
        await LabServices.closeAccount(reqBody)
        .then((response) => {
          if (response.status === 201) {
            // toast msg after successfully closing account
            toast.current.show({
              severity: "success",
              summary:"Account Successfully Close",               
              detail:"Your account has been successfully closed",
              life: 4000,
            });
            setComments("")
            setIsValidate(false)
            setReason(1)
            setReqBody({})
            closeAccountDialogMap[`closeAccountDisplay`](false);
            localStorage.setItem("isAuthenticated", "false");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("expiresIn");
            localStorage.removeItem("email");
            localStorage.removeItem('isSSOLogin');
            navigate("/"); // for Logout navigating to login page

          } else {
            loglevel.debug("Lab creation is Failed");
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            //toast msg for error response         
            toast.current.show({
              severity: "error",
              detail: `${error.response.data.msg}`,
              life: 5000,
            });
          }
        });
    
    }

    const onCheck= (e) =>{   
      setDeleteCheck(e.checked)
    }

    // method call on changing reason for closing account
    const onReasonChange= (e) =>{
    console.log(e,"reasonChange")
        setReason(e.value)
      }
    
    // footer of dialog contain DELETE ACCOUNT and CANCEL Button
    const renderFooter = (name) => {
        return (
          <div className="disable-footer-button">
            <div>
              {/* Delete Account button */}
              <Button
                label="DELETE ACCOUNT"
                className={
                  deleteCheck?"p-button-outlined p-button-danger":"p-button-outlined p-button-secondary"
                }
                onClick={() => onDeleteAccount()}
                disabled = {!deleteCheck}
              />
            </div>
            <div>
              {" "}
              {/* cancel button */}
              <Button
                label="CANCEL"
                onClick={()=>onCancel("closeAccountDisplay")}
                className="p-button-outlined p-button-primary"
              />
            </div>
          </div>
        );
      };

    return (
        <div className='close-account'>
            <Toast ref={toast} position="top-center" />
            <div className="close-account-title">Close My Account</div>
            <Divider />
            <div>By requesting to close this account I understand that:</div>
            <div>
                <ul>
                    <li> I will no have visibility to the labs and will not be able to schedule demo/labs.</li>
                    <li>I will lose access to the portal.</li>
                    <li>The account closing is permanent and cannot be undone.</li>
                </ul>
            </div>

            <div className='p-mb-4'>If you need support please email {" "} <a
          className="link"
          href={emailUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {emailLabel}
        </a></div>
            <div className="feedback">
                <div className="field-title">Reason for closing this Account:</div>
                <div className="feedback-options">
                    <Dropdown
                        value={reason}
                        options={reasonList}
                        onChange={(e) => onReasonChange(e)}
                        optionLabel="value"
                        optionValue='acreasonId'
                        placeholder='Select Reason'
                        filter
                        filterBy="value"
                    />
                </div>
            </div>
            <div className="comments-container">
                <div className="field-title">
                    Comments:<span className="lab-required-field">*</span>
                </div>
                <div
                    className={!comments && isValidated ? "comments-error" : "comments"}
                >
                    <InputTextarea
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                        rows={2}
                        cols={40}
                        placeholder="Enter Comments"
                        autoResize={true}
                        className={!comments && isValidated?"p-error block":""}
                    />
                    {!comments && isValidated ? (
                        <div className="error-message-inline">
                            <small className="error-message" id="error-message">
                                Comment is mandatory
                            </small>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
            <Divider />
            {/* Submit button */}
            <div className="submit-button">
                <Button onClick={() => onSubmit("closeAccountDisplay")} style={{ 'fontSize': '13px', 'color': "black", "marginRight": "4px" }}>SUBMIT</Button>
            </div>
            <Dialog
                visible={closeAccountDisplay}
                header = {"Close My Account"}
                onHide={() => onHide("closeAccountDisplay")}
                footer = {()=>renderFooter("closeAccountDisplay")}
                className="close-account-dialog-box"
                style={{width:"26vw"}}
            >
               <div className='p-mb-3'>If you close your account, you will lose all the data permanently from the VMWare SDE Labs portal.</div>
               <div className='close-account-checkbox'>
               <div>
                {/* checkbox for confirmation before closing account */}
               <Checkbox
                  className="lab-checkbox"
                  checked={deleteCheck}
                  onChange={(e) => onCheck(e)}
                ></Checkbox>
               </div>
               <div className='p-ml-2'>I understand. Close my account.</div>
               </div>
            </Dialog>
        </div>
    )
}

export default CloseAccount