import React, {useRef, useState} from 'react'
import "./ForgotPassword.css"
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import AuthenticationService from '../../services/AuthenticationService';
import { InputText } from 'primereact/inputtext'
import { Message } from 'primereact/message';
import { Captcha } from 'primereact/captcha';
import { Toast } from "primereact/toast";

const ForgotPassword = () => {
    const [isCaptchaVerified, setCaptchaVerified] = useState(false);
    const toast = useRef(null);
    const [forgotPasswordDialog, setForgotPasswordDialog] = useState(false);
    const [resetpasswordSuccessDialog, setDisplaySuccessDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [emailErrorMsg, setEmailErrorMsg] = useState("");
    const [email, setEmail] = useState("");

    const onResetPassword = () => {
        // request body for resetting password
        const requestBody = {
            email: email
        }
        onForgotPassword(requestBody)
    }


    //requesting for forgot password
    const onForgotPassword = async (reqBody) => {
        if (email === "") { // if email field is empty
            setEmailErrorMsg("Email is mandatory")
        }
        else { //if email field is not empty
            setEmailErrorMsg("");
        }
        if (email !== "") {
            // api call for requesting or resetting password
            await AuthenticationService.resetPassword(reqBody).then(() => {
                dialogFuncMap[`forgotPasswordDialog`](false); // closing reset password dialog
                dialogFuncMap[`resetpasswordSuccessDialog`](true); // opening confirmation dialog
                setEmail("");
            }).catch((error) => {
                setErrorMessage(error.response.data.msg);
            })
        }
    }

    const showForgotPassword = (name) => {
        dialogFuncMap[`${name}`](true);
    }

    const dialogFuncMap = {
        'forgotPasswordDialog': setForgotPasswordDialog,
        'resetpasswordSuccessDialog': setDisplaySuccessDialog
    }

    // on hiding dialog
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
        setErrorMessage("");
        setEmailErrorMsg("");
        setEmail("");
        setCaptchaVerified(false);
    }

    const forgotPasswordFooter = () => {
        return (
            <div className='p-d-flex p-jc-center'>
                    <div className="card">
                      <Captcha siteKey={process.env.REACT_APP_SITE_KEY}
                               onResponse={verifyCaptcha} />
                      </div>
                <Button disabled={!isCaptchaVerified} className={isCaptchaVerified ? "email-success-btn" : "email-btn-disabled"} label="Email me a new password" onClick={() => onResetPassword()} />
            </div>
        );
    }

    const resetpasswordSuccessFooter = (name) => {
        return (
            // close Button
            <Button className='email-pswd-reset-success-close-btn' label="CLOSE" onClick={() => onHide(name)} />
        )
    }

    // on changing email field
    const onEmailChange = (e) => {
        let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        setEmail(e.target.value);
        if (!regexEmail.test(e.target.value)) { //if email is invalid
            setEmailErrorMsg("Enter a valid email address");
            return;
        }
        else { //if email is valid
            setEmailErrorMsg("");
        }
    }

    // const [isCaptchaVerified, setCaptchaVerified] = useState(false);
    // const toast = useRef(null);

    const verifyCaptcha =  async(value) => {
    console.log("Capcha value:" + JSON.stringify(value))
    await AuthenticationService.verifyCapcha(value.response).then((res) => {
        console.log("Res from google: " + res)
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'Captcha Verified Successfully', life:5000 });
        setCaptchaVerified(true);
    }).catch((error) => {
        console.log("Captcha verify", error)
        toast.current.show({ severity: 'info', summary: 'Error', detail: 'BOT Detected' });
      })
    }
    return (
        <>
            <span className="forgot-password-link" onClick={() => showForgotPassword('forgotPasswordDialog')}>Forgot password ?</span>

            <Dialog header="Forgot Password?" visible={forgotPasswordDialog} style={{ width: '50vw' }} footer={forgotPasswordFooter('forgotPasswordDialog')} onHide={() => onHide('forgotPasswordDialog')} className="forgot-password-dialog">
                <div>
                    <Toast ref={toast} position="top-center" />
                    {/* error message response on top of dialog */}
                    {errorMessage && <Message severity="warn" text={errorMessage} className="common-inline-error forgotpswd-inline-error" />}
                    <div className='p-mt-2 p-grid'>
                        <label className="email-label p-d-flex p-ai-center p-col-2">Email<span className="mandatory-label">*</span> :</label>
                        <div className='p-col-10'>
                            {/* Email inputbox */}
                            <InputText
                                name='email'
                                value={email}
                                maxLength={255}
                                placeholder="Enter Email Address"
                                className={emailErrorMsg || errorMessage ? "email-input-error email-input-text" : "email-input-text"}
                                onChange={onEmailChange}
                            />
                            <div className='error-msg-div'>
                                <small className="error-msg" id="error-message">{emailErrorMsg}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            
            {/* Thank you message after new password is requested */}
            <Dialog header="Email Sent. Thank you." visible={resetpasswordSuccessDialog} style={{ width: '35vw' }} footer={resetpasswordSuccessFooter('resetpasswordSuccessDialog')} onHide={() => onHide('resetpasswordSuccessDialog')} className="resetpassword-success-dialog">
                <div className='resetpswd-content'>
                    Your request for forgot Password has been received. You will receive a new Password for VMWare SDE Labs via email.
                </div>
            </Dialog>
        </>
    )
}

export default ForgotPassword