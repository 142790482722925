import React, { useState } from 'react'
import './Admin.css'

import { useGlobalState } from '../../config/state';
import PortalReports from '../reports/PortalReports';
import LoginReports from '../reports/LoginReports';
import ChartDashboard from '../charts/ChartDashboard';
import LineStylesChart from '../charts/LineStylesChart';
import LoginAttemptsGraph from '../charts/LoginAttemptsGraph';
import EquipmentGraph from '../charts/EquipmentGraph';
import DetailedEquipmentGraph from '../charts/DetailedEquipmentGraph';

//Inventory management  Tabs
const AdminReportsMgmt = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [isAdmin, setIsAdmin] = useGlobalState('isAdmin');
    const [adminReportsAccess, setAdminReportsAccess] = useGlobalState('adminReportsAccess')

    const setIndex = (index) => {
        setActiveIndex(index);
    }

    return (
        <div>
            {(isAdmin || adminReportsAccess) ? (
                <>
                    <div className="tabmenu">
                        {/* Physical Asset Tab */}
                        <button
                            className="menuitem-link"
                            style={{
                                borderColor:
                                    activeIndex === 0
                                        ? "#979797 #979797 #192a32 #979797" // Border colour of tab when it is active
                                        : "#192a32 #192a32 #979797 #192a32",// Border colour of tab when it is inactive
                            }}
                            onClick={() => setIndex(0)} // on click Physical Asset tab
                            data-testid="all-labs-title"
                        >
                            <div className={activeIndex === 0 ? "active-tab" : "inactive-tab"}> Customer Reports</div>
                        </button>
                        {/* Virtual Environment Tab */}
                        <button
                            className="menuitem-link"
                            style={{
                                borderColor:
                                    activeIndex === 1
                                        ? "#979797 #979797 #192a32 #979797" // Border colour of tab when it is active
                                        : "#192a32 #192a32 #979797 #192a32", // Border colour of tab when it is inactive
                            }}
                            onClick={() => setIndex(1)} // on click  Virtual Environments tab
                        >
                            <div className={activeIndex === 1 ? "active-tab" : "inactive-tab"}> User Login Reports</div>
                        </button>

                        {/* Server Model Tab */}
                        <button
                            className="menuitem-link"
                            style={{
                                borderColor:
                                    activeIndex === 2
                                        ? "#979797 #979797 #192a32 #979797" // Border colour of tab when it is active
                                        : "#192a32 #192a32 #979797 #192a32", // Border colour of tab when it is inactive
                            }}
                            onClick={() => setIndex(2)} // on click Server model Tab
                            data-testid="all-labs-title"
                        >
                            <div className={activeIndex === 2 ? "active-tab" : "inactive-tab"}> Purpose Usage</div>
                        </button>

                        {/* CMDB Tags tab */}
                        <button
                            className="menuitem-link"
                            style={{
                                borderColor:
                                    activeIndex === 3
                                        ? "#979797 #979797 #192a32 #979797" // Border colour of tab when it is active
                                        : "#192a32 #192a32 #979797 #192a32", // Border colour of tab when it is inactive
                            }}
                            onClick={() => setIndex(3)} //on click CMDB Tags Tab
                            data-testid="all-labs-title"
                        >
                            <div className={activeIndex === 3 ? "active-tab" : "inactive-tab"}> Portal Usage</div>
                        </button>

                        {/* Asset Schedules Tab */}
                        <button
                            className="menuitem-link"
                            style={{
                                borderColor:
                                    activeIndex === 4
                                        ? "#979797 #979797 #192a32 #979797" // Border colour of tab when it is active
                                        : "#192a32 #192a32 #979797 #192a32", // Border colour of tab when it is inactive
                            }}
                            onClick={() => setIndex(4)} //on click Asset Schedules Tab
                            data-testid="all-labs-title"
                        >
                            <div className={activeIndex === 4 ? "active-tab" : "inactive-tab"}> Login Attempts</div>
                        </button>

                        {/* Asset Schedules Tab */}
                        <button
                            className="menuitem-link"
                            style={{
                                borderColor:
                                    activeIndex === 5
                                        ? "#979797 #979797 #192a32 #979797" // Border colour of tab when it is active
                                        : "#192a32 #192a32 #979797 #192a32", // Border colour of tab when it is inactive
                            }}
                            onClick={() => setIndex(5)} //on click Asset Schedules Tab
                            data-testid="all-labs-title"
                        >
                            <div className={activeIndex === 5 ? "active-tab" : "inactive-tab"}> Equipment Usage</div>
                        </button>
                    </div>

                    <div className="data">
                        {activeIndex === 0 ? <PortalReports /> : ""} {/*Physical Asset Tab */}
                        {activeIndex === 1 ? <LoginReports /> : ""} {/*Virtual Environment Tab */}
                        {activeIndex === 2 ? <ChartDashboard /> : ""} {/*Server Models Tab */}
                        {activeIndex === 3 ? <LineStylesChart /> : ""} {/*CMDB Tags Tab */}
                        {activeIndex === 4 ? <LoginAttemptsGraph /> : ""} {/* Asset Schedules Tab */}
                        {activeIndex === 5 ? 
                            <div>
                                <div><EquipmentGraph /> </div>
                                <div><DetailedEquipmentGraph /></div>                 
                            </div>
                        : ""} {/* Asset Schedules Tab */}
                    </div>
                </>
            ) : (
                window.location.assign("/not-found")
            )}
        </div>
    )
}

export default AdminReportsMgmt