import React, { useState, useRef } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { InputSwitch } from 'primereact/inputswitch'
import { Divider } from 'primereact/divider'
import { Checkbox } from 'primereact/checkbox'
import { MultiSelect } from 'primereact/multiselect'
import "../../Rbac.css";
import LabServices from "../../../../services/LabServices";
import { Toast } from "primereact/toast";
import loglevel from "../../../../logger/Logger";
import eventBus from '../../../utility/EventBus'
import { Message } from "primereact/message";



const CreateUserGroup = ({ setChanger }) => {

    const [createUserGroupDisplay, setCreateUserGroupDisplay] = useState(false);
    const [userGroupTags, setUserGroupTags] = useState([]); // list of all the tags
    const [userList, setUserList] = useState([]);          // list of all the users
    const [groupName, setGroupName] = useState("");
    const [groupDesc, setGroupDesc] = useState("");
    const [enableGroup, setEnableGroup] = useState(true); // enable/disable switch
    const [tag1, setTag1] = useState(true);             // read only tag value
    const [tag2, setTag2] = useState(true);             // portal-user tag value
    const [tag3, setTag3] = useState(false);            // cec-dev tag value
    const [selectedTags, setSelectedTags] = useState([]); // user selected tags apart from above 3
    const [groupMembers, setGroupMembers] = useState([]);     // user selected members
    const [isValidated, setIsValidated] = useState(false);
    const [userGroupWarningMsg, setUserGroupWarningMsg] = useState('');
    const [userGroupErrorMsg, setUserGroupErrorMsg] = useState('');


    const toast = useRef(null);

    const createUserGroupDialogMap = {
        createUserGroupDisplay: setCreateUserGroupDisplay,
    };

    const onClick = async (name) => {
        // opens the dialog box
        createUserGroupDialogMap[`${name}`](true);
        setGroupName("");
        setGroupDesc("");
        setEnableGroup(true);
        setTag1(true);
        setTag2(true);
        setTag3(false);
        setSelectedTags([]); // user selected tags apart from above 3
        setGroupMembers([]);
        setIsValidated(false);

        // fetch permission tags for dropdown
        await LabServices.getPermissionTagList()
            .then((res) => {
                let tagList = [];

                for (var i = 0; i < res.data.length; i++) {
                    if (
                        res.data[i] === "read-only" ||
                        res.data[i] === "cec-dev-test" ||
                        res.data[i] === "portal-user"
                    ) {
                        continue;
                    } else {
                        tagList.push(res.data[i]);
                    }
                }
                setUserGroupTags(tagList);
            })
            .catch((error) => {
                loglevel.error("Error while fetching User Group tags", error);
            });

        // fetch all users for dropdown
        await LabServices.getUserList()
            .then((res) => {
                let usersDisplayData = res.data.map(user => {
                    return { ...user, displayName: `${user.name} (${user.email})` }
                })

                setUserList(usersDisplayData);
            })
            .catch((error) => {
                loglevel.error("Error while fetching User list", error);
            });
    };

    //closing dialog box
    const onHide = (name) => {
        createUserGroupDialogMap[`${name}`](false);
        setIsValidated(false);
        setUserGroupWarningMsg('');
        setUserGroupErrorMsg('');

    };

    const onMemberAdd = (e) => {
        if (!tag1 && !tag2 && !tag3 && !selectedTags.length) {
            setUserGroupWarningMsg('If no tag is selected, the selected members will not be added to the group.')
        }
        setGroupMembers(e.target.value)
    }

    //-----------------------------------------------------------------------------
    // On click on ADD -> create req body tor create group api call

    const onGroupAdd = async () => {
        if (groupName && groupDesc) {
            let postReqTags = []

            if (tag1 === true) {
                postReqTags.push('read-only')
            }
            if (tag2 === true) {
                postReqTags.push("portal-user")
            }
            if (tag3 === true) {
                postReqTags.push("cec-dev-test")
            }
            // this array will have all the user selected tag objects
            postReqTags = [...postReqTags, ...selectedTags]
            // this array will have all the user selected tag names
            let memberIds = groupMembers.map(user => {
                return user.users_id
            })
            // post data for creating a new user group
            const requestBody = {
                group_name: groupName,
                group_desc: groupDesc,
                enabled: enableGroup,
                tags: postReqTags,
                users: memberIds
            }

            // post api call to create a new user group
            await LabServices.createUserGroup(requestBody)
                .then((response) => {
                    if (response.status === 201) {
                        toast.current.show({
                            severity: "success",
                            summary: "User group successfully added",
                            detail: "User Group has been successfully added.",
                            life: 4000,
                        });
                        eventBus.dispatch("pageReloaded", { message: "pageReloaded" });
                    } else {
                        loglevel.debug("User group creation Failed");
                    }
                    onHide("createUserGroupDisplay");
                })
                .catch((error) => {
                    loglevel.error("User Group Creation failure", error.response.data);
                    if (error.response.status === 400) {
                        setUserGroupErrorMsg(error.response.data.msg);
                    }
                });
            // api call for user Group list to refresh
            await LabServices.getUserGroupList().then((res) => {
                setChanger(res.data)
                setIsValidated(false)

            })
        } else {
            setIsValidated(true)
        }
    };

    const renderFooter = (name) => {
        return (
            <div className="terms-section">
                {/* Add gruop button at footer */}
                <Button
                    className='create-user-button'
                    label="ADD GROUP"
                    style={{ background: "#49AFD9" }}
                    onClick={() => onGroupAdd()}
                />
            </div>
        );
    };
    return (
        <div className="admin-panel-content">
            <Toast ref={toast} position="top-center" />

            <div className="create-lab-button">
                {/* Create new Group button on listing page */}
                <Button
                    id="create-new-lab-button"
                    label="CREATE NEW USER GROUP"
                    className="p-button-primary"
                    onClick={() => onClick("createUserGroupDisplay")}
                />
            </div>
            {/* create group dialog */}
            <Dialog
                header="Create new User Group"
                visible={createUserGroupDisplay}
                footer={renderFooter("createUserGroupDisplay")}
                onHide={() => onHide("createUserGroupDisplay")}
                className="lab-dialog-box"
                style={{ width: "700px" }}
            >
                {userGroupErrorMsg && <Message severity="warn" text={userGroupErrorMsg} className='common-inline-error common-error-message p-mb-3' />}
                <div className="dialog-content">
                    {/* Group Name */}
                    <div className="section first-section">
                        <div className="name-input-text">
                            <div className="namefield-label">Group Name<span className="lab-required-field">*</span> :</div>
                            <div>
                                <InputText
                                    value={groupName}
                                    onChange={(e) => setGroupName(e.target.value)}
                                    placeholder="Enter Group Name"
                                    maxLength={20}
                                    className={!groupName && isValidated ? "asset-input-error asset-text-box" : "asset-text-box"}
                                    required
                                />
                                <div>
                                    {!groupName && isValidated ? (
                                        <div className="cat-error-message-inline">
                                            <small className="p-error p-d-block" id="error-message">Group name is mandatory</small>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div></div>
                    {/* Group desc */}
                    <div className="section second-section">
                        <div className="name-input-text">
                            <div className="namefield-label">Group Description<span className="lab-required-field">*</span> :</div>
                            <div>
                                <InputText
                                    value={groupDesc}
                                    onChange={(e) => setGroupDesc(e.target.value)}
                                    placeholder="Enter Group Description"
                                    maxLength={1000}
                                    className={!groupDesc && isValidated ? "email-text-box asset-input-error asset-text-box" : "email-text-box asset-text-box"}
                                    required
                                />
                                <div>
                                    {!groupDesc && isValidated ? (
                                        <div className="cat-error-message-inline">
                                            <small className="p-error p-d-block" id="error-message">Group description is mandatory</small>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div></div>
                    {/* Enable Group */}
                    <div className="section third-section">
                        <div className="name-input-text">
                            <div className="namefield-label">Enable Group:</div>
                            <InputSwitch
                                checked={enableGroup}
                                onChange={(e) => setEnableGroup(e.value)}
                            />
                            <div className='category-enable-text'>{enableGroup ? "Group is Active." : "Group is Inactive."}</div>
                        </div>
                    </div>
                    <Divider />
                    {/* Tags */}
                    <div className="section fourth-section p-d-flex">
                        <div className='section tags-label'>Tags<span className="optional-fields"> (Optional)</span> :</div>
                        <div className='tagscheck-and-tagsdropdown'>
                            <div className="lab-tags section">
                                <div className="lab-tag">
                                    <Checkbox
                                        onChange={(e) => setTag1(e.checked)}
                                        checked={tag1}
                                    ></Checkbox>
                                    <label htmlFor="cb1" className="checkbox-label">
                                        read-only
                                    </label>
                                </div>
                                <div className="lab-tag">
                                    <Checkbox
                                        onChange={(e) => setTag2(e.checked)}
                                        checked={tag2}
                                    ></Checkbox>
                                    <label htmlFor="cb1" className="checkbox-label">
                                        portal-user
                                    </label>
                                </div>
                                <div className="lab-tag">
                                    <Checkbox
                                        className="lab-checkbox"
                                        onChange={(e) => setTag3(e.checked)}
                                        checked={tag3}
                                    ></Checkbox>
                                    <label htmlFor="cb1" className="checkbox-label">
                                        cec-dev-test
                                    </label>
                                </div>
                            </div>
                            <div className="tag-dropdown section">
                                <MultiSelect
                                    showSelectAll={false}
                                    value={selectedTags}
                                    name="category-dropdown"
                                    options={userGroupTags}
                                    onChange={(e) => setSelectedTags(e.target.value)}
                                    placeholder="Select Tag"
                                    className="tags-dropdown select-permissions"
                                    filter
                                    display="chip"
                                    allowDuplicate={false}
                                />
                            </div>
                        </div>
                    </div>
                    <Divider />
                    {/* Members */}
                    <div className="section fifth-section">
                        <div className="name-input-text">
                            <div className="namefield-label">Members<span className="optional-fields"> (Optional)</span> :</div>
                            <div>
                                <MultiSelect
                                    showSelectAll={false}
                                    value={groupMembers}
                                    name="category-dropdown"
                                    options={userList}
                                    onChange={(e) => onMemberAdd(e)}
                                    placeholder="Search by Name or Email"
                                    // className="lab-dropdown groups-dropdown"
                                    className={!groupMembers.length && isValidated ? "asset-input-error groups-dropdown select-groups" : "groups-dropdown select-groups"}
                                    filter
                                    optionLabel='displayName'
                                    display='chip'
                                />
                            </div></div>
                        <div>
                            {userGroupWarningMsg && <Message severity="warn" text={userGroupWarningMsg} className='common-inline-error common-inline-warn p-mt-2' />}
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default CreateUserGroup