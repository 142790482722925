import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import React, { useEffect, useState, useRef } from 'react'
import LabServices from '../../../../services/LabServices'
import LoadingSpinner from '../../../loader/LoadingSpinner'
import CreateVirtualEnv from './CreateVirtualEnv'
import UpdateVirtualEnv from './UpdateVirtualEnv'
import VirtualEnvironmentDetails from './VirtualEnvironmentDetails'
import { Toast } from 'primereact/toast';

const VirtualEnvironment = () => {
    const toast = useRef(null);
    const [VirtualEnvDetails, setVirtualEnvDetails] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedDays, setSelectedDays] = useState({
        filterByDays: "Next 7 days",
        days: 7,
    });
    const filterDays = [
        { filterByDays: "Next 7 days", days: 7 },
        { filterByDays: "Next 15 days", days: 15 },
        { filterByDays: "Next 30 days", days: 30 },
        { filterByDays: "Next 90 days", days: 90 },
    ];
    const [loading, setLoading] = useState(false)

    const showToastSuccess = (data) => {
        toast.current.show({
          severity: "success",
          summary: data.summary,
          detail: data.detail,
          life: 6000,
        });
    }

    const fetchData = async () => {
        setLoading(true)
        await LabServices.getVirtualEnvironmentDetails(selectedDays.days).then((response) => {
            setVirtualEnvDetails(response.data);
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchData()
    }, [selectedDays])

    const onDaysChange = (e) => {
        setSelectedDays(e.value);
    };

    const indexTemplate = (rowData, props) => {
        return <span className="index-field">{props.rowIndex + 1}</span>
    }

    const isStaticTemplate = (rowData) => {
        return <span>{rowData.is_static_lab === true ? 'Yes' : 'No'}</span>
    }

    const remarksTemplate = (rowData) => {
        return <div className="cat-name-table short-desc">{rowData.remarks}</div>;
    }

    const EnabledTemplate = (rowData) => {
        if (rowData.enabled) {
            return (
                <div className="active-inactive-container">
                    <div className="enable-icon">
                        <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0.777344 7.00005C0.777344 10.4365 3.56313 13.2223 6.99957 13.2223C8.6498 13.2223 10.2324 12.5667 11.3993 11.3998C12.5662 10.2329 13.2218 8.65029 13.2218 7.00005C13.2218 3.56362 10.436 0.777832 6.99957 0.777832C3.56313 0.777832 0.777344 3.56362 0.777344 7.00005ZM1.55512 7.00005C1.55512 3.99317 3.99268 1.55561 6.99957 1.55561C10.0064 1.55561 12.444 3.99317 12.444 7.00005C12.444 10.0069 10.0064 12.4445 6.99957 12.4445C3.99268 12.4445 1.55512 10.0069 1.55512 7.00005Z"
                                fill="#61B715"
                            />
                            <path
                                d="M6.28622 8.61488L4.54789 6.87821C4.49126 6.81209 4.49507 6.71352 4.55663 6.65195C4.61819 6.59039 4.71676 6.58659 4.78289 6.64321L6.28289 8.14321L9.21455 5.21488C9.28068 5.15825 9.37925 5.16206 9.44081 5.22362C9.50238 5.28518 9.50618 5.38375 9.44955 5.44988L6.28622 8.61488Z"
                                fill="#61B715"
                                stroke="#61B715"
                                strokeWidth="0.4"
                            />
                        </svg>
                    </div>

                    <div className="datatable-enable-label">Active</div>
                </div>
            );
        } else {
            return (
                <div className="active-inactive-container">
                    <div className="disable-icon">
                        <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                opacity="0.4"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M6.99957 13.2223C3.56313 13.2223 0.777344 10.4365 0.777344 7.00005C0.777344 3.56362 3.56313 0.777832 6.99957 0.777832C10.436 0.777832 13.2218 3.56362 13.2218 7.00005C13.2218 8.65029 12.5662 10.2329 11.3993 11.3998C10.2324 12.5667 8.6498 13.2223 6.99957 13.2223ZM6.99957 1.55561C3.99268 1.55561 1.55512 3.99317 1.55512 7.00005C1.55512 10.0069 3.99268 12.4445 6.99957 12.4445C10.0064 12.4445 12.444 10.0069 12.444 7.00005C12.444 3.99317 10.0064 1.55561 6.99957 1.55561ZM9.51568 5.11005L7.62568 7.00005L9.46123 8.82394C9.59337 8.97824 9.58448 9.20824 9.44084 9.35188C9.2972 9.49553 9.06719 9.50441 8.9129 9.37228L7.06957 7.52894L5.21457 9.38394C5.11953 9.49492 4.9703 9.54326 4.82824 9.50909C4.68618 9.47492 4.57526 9.364 4.54108 9.22194C4.50691 9.07988 4.55525 8.93065 4.66623 8.83561L6.52512 7.00005L4.62734 5.07894C4.49521 4.92465 4.50409 4.69465 4.64774 4.551C4.79138 4.40736 5.02138 4.39847 5.17568 4.53061L7.07734 6.43228L8.96734 4.56172C9.12164 4.42959 9.35164 4.43847 9.49528 4.58211C9.63893 4.72576 9.64781 4.95576 9.51568 5.11005Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                    <div className="datatable-disable-label">Inactive</div>
                </div>
            );
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="table-action-body p-jc-end">
                <div>
                    <VirtualEnvironmentDetails
                        virEnvId={rowData.virtual_environment_id}
                    />
                </div>
                <UpdateVirtualEnv
                    rowId={rowData.virtual_environment_id}
                    isDisabled={rowData.is_editable}
                    fetchData={fetchData}
                    showToastSuccess={showToastSuccess} 
                />
            </div>
        );
    };

    const availabilityTemplate = (rowData) => {
        if (rowData.availability) {
            return (
                <div className="active-inactive-container">
                    <div className="enable-icon">
                        <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0.777344 7.00005C0.777344 10.4365 3.56313 13.2223 6.99957 13.2223C8.6498 13.2223 10.2324 12.5667 11.3993 11.3998C12.5662 10.2329 13.2218 8.65029 13.2218 7.00005C13.2218 3.56362 10.436 0.777832 6.99957 0.777832C3.56313 0.777832 0.777344 3.56362 0.777344 7.00005ZM1.55512 7.00005C1.55512 3.99317 3.99268 1.55561 6.99957 1.55561C10.0064 1.55561 12.444 3.99317 12.444 7.00005C12.444 10.0069 10.0064 12.4445 6.99957 12.4445C3.99268 12.4445 1.55512 10.0069 1.55512 7.00005Z"
                                fill="#61B715"
                            />
                            <path
                                d="M6.28622 8.61488L4.54789 6.87821C4.49126 6.81209 4.49507 6.71352 4.55663 6.65195C4.61819 6.59039 4.71676 6.58659 4.78289 6.64321L6.28289 8.14321L9.21455 5.21488C9.28068 5.15825 9.37925 5.16206 9.44081 5.22362C9.50238 5.28518 9.50618 5.38375 9.44955 5.44988L6.28622 8.61488Z"
                                fill="#61B715"
                                stroke="#61B715"
                                strokeWidth="0.4"
                            />
                        </svg>
                    </div>

                    <div className="datatable-enable-label">Available</div>
                </div>
            );
        } else {
            return (
                <div className="active-inactive-container">
                    <div className="disable-icon">
                        <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                opacity="0.4"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M6.99957 13.2223C3.56313 13.2223 0.777344 10.4365 0.777344 7.00005C0.777344 3.56362 3.56313 0.777832 6.99957 0.777832C10.436 0.777832 13.2218 3.56362 13.2218 7.00005C13.2218 8.65029 12.5662 10.2329 11.3993 11.3998C10.2324 12.5667 8.6498 13.2223 6.99957 13.2223ZM6.99957 1.55561C3.99268 1.55561 1.55512 3.99317 1.55512 7.00005C1.55512 10.0069 3.99268 12.4445 6.99957 12.4445C10.0064 12.4445 12.444 10.0069 12.444 7.00005C12.444 3.99317 10.0064 1.55561 6.99957 1.55561ZM9.51568 5.11005L7.62568 7.00005L9.46123 8.82394C9.59337 8.97824 9.58448 9.20824 9.44084 9.35188C9.2972 9.49553 9.06719 9.50441 8.9129 9.37228L7.06957 7.52894L5.21457 9.38394C5.11953 9.49492 4.9703 9.54326 4.82824 9.50909C4.68618 9.47492 4.57526 9.364 4.54108 9.22194C4.50691 9.07988 4.55525 8.93065 4.66623 8.83561L6.52512 7.00005L4.62734 5.07894C4.49521 4.92465 4.50409 4.69465 4.64774 4.551C4.79138 4.40736 5.02138 4.39847 5.17568 4.53061L7.07734 6.43228L8.96734 4.56172C9.12164 4.42959 9.35164 4.43847 9.49528 4.58211C9.63893 4.72576 9.64781 4.95576 9.51568 5.11005Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                    <div className="datatable-disable-label">In Use</div>
                </div>
            );
        }
    }

    const cellEditor = (options) => {
        return(<InputText type="text" value={options.value}
          onChange={(e) => options.editorCallback(e.target.value)}/>)
    };

    const onCellEditComplete = (e) => {
        // Return if no change is made
        if(e.newValue === e.value) return
        // Else process
        let { rowData, newValue, field, originalEvent: event } = e;
        rowData[field] = newValue;
        event.preventDefault();
    
       // Update data to backend and read it back
       LabServices.updateVirtualEnvironmentDetailsById(rowData.virtual_environment_id, rowData)
           .then((response) => {
                fetchData()
                toast.current.show({
                    severity: "success",
                    summary: `${response.data}`,
                    detail: "Remarks has been updated successfully.",
                    life: 5000,
                });
            }).catch((error) => {
                console.log(error);
            })
      };
    


    return (
        <div>
            <Toast ref={toast} position="top-center" />
            {loading ?
                <div className="loader">
                    <LoadingSpinner />
                </div> :
                <div className='virtual-env-container'>
                    <div className="admin-panel">
                        <div className="admin-panel-search-bar">
                            <span className="p-input-icon-left">
                                <i className="pi pi-search" />
                                <InputText
                                    type="search"
                                    onInput={(e) => setGlobalFilter(e.target.value)}
                                    placeholder="Search"
                                    className="global-search-filter"
                                />
                            </span>
                        </div>
                        <div className='admin-panel-right-container'>
                            <div className="virtual-env-day-filter">
                                <Dropdown
                                    value={selectedDays}
                                    options={filterDays}
                                    onChange={onDaysChange}
                                    optionLabel="filterByDays"
                                    placeholder="Select quick dates"
                                    className="quick-date-selector"
                                />
                            </div>
                            <CreateVirtualEnv 
                                fetchData={fetchData} 
                                showToastSuccess={showToastSuccess}
                            />
                        </div>
                    </div>
                    <div className='dataTable-virtual'>
                        <DataTable
                            responsiveLayout="scroll"
                            value={VirtualEnvDetails}
                            className="p-datatable-customers"
                            columnResizeMode="fit"
                            paginator
                            rows={50}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            globalFilter={globalFilter}
                            autoLayout={true}
                            removableSort
                            pageLinkSize={1}
                        >
                            <Column
                                field="id"
                                header="#"
                                style={{ fontWeight: "600", width: "3rem", paddingLeft: "15px" }}
                                body={indexTemplate}
                            ></Column>
                            <Column
                                sortable
                                style={{ fontWeight: "600", minWidth: "8rem", maxWidth: "8rem", textAlign: "center" }}
                                field="virtual_environment_id"
                                header="Virtual Environment ID"
                            ></Column>
                            <Column 
                                sortable 
                                style={{maxWidth: "12rem", textAlign: "center"}} 
                                field='remarks' 
                                header="Remarks" 
                                body={remarksTemplate}
                                editor={(options) => cellEditor(options)} 
                                onCellEditComplete={onCellEditComplete}
                            ></Column>
                            <Column
                                sortable
                                style={{ textAlign: "center", minWidth: "6rem" }}
                                field="is_static_lab"
                                header="Static"
                                body={isStaticTemplate}
                            ></Column>
                            <Column
                                sortable
                                style={{ textAlign: "center", minWidth: "6rem" }}
                                field="enabled"
                                header="Status"
                                body={EnabledTemplate}
                            ></Column>
                            <Column
                                sortable
                                style={{ textAlign: "center", minWidth: "6rem" }}
                                field="availability"
                                header="Availability"
                                body={availabilityTemplate}
                            ></Column>
                            <Column
                                className="setting-icon-column"
                                body={actionBodyTemplate}
                            ></Column>
                        </DataTable>
                    </div>
                </div>
            }
        </div>

    )
}

export default VirtualEnvironment