import React, { useState, useEffect, useRef } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Button } from "primereact/button";
import LabServices from "../../services/LabServices";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import "./Reports.css";
import Multiline from "../charts/Multiline";
import { MultiSelect } from "primereact/multiselect";
import { SplitButton } from "primereact/splitbutton";
import { FilterMatchMode } from "primereact/api";

import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { Toast } from "primereact/toast";
import { Constants } from "../../constants/constants";

const Reports = () => {
  const dt = useRef(null);
  const toast = useRef(null);
  const exportButton = useRef(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredData, setFilteredData] = useState("");
  const [selectedDays, setSelectedDays] = useState({
    filterByDays: "Last 90 days",
    days: 90,
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [userData, setUserData] = useState([]);
  const [day, setDay] = useState(90);
  const [labName, setLabName] = useState([]);
  const indexOfLastItem = itemsPerPage + currentPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const [globalFilterValue1, setGlobalFilterValue1] = useState("");
  const [users, setUsers] = useState([]);
  const [filters1, setFilters1] = useState(null);
  const [labNames, setLabNames] = useState([]);
  const [purposes, setPurposes] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [remarks, setRemarks] = useState([]);
  const [filteredValue, setFilteredValue] = useState([]);

// Dropdown options for user report
  const filterDays = [
    { filterByDays: "Last 7 days", days: 7 },
    { filterByDays: "Last 15 days", days: 15 },
    { filterByDays: "Last 30 days", days: 30 },
    { filterByDays: "Last 90 days", days: 90 },
  ];

  const [selectedLabDetails, setSelectedLabDetails] = useState(users)

  // Dropdown options for user report
  const onDaysChange = (e) => {
    setSelectedDays(e.value);
    let days = 90;
    if (e.value.days === 7) {
      days = 7;
      let today = new Date();//current date
      let priorSeven = new Date(new Date().setDate(today.getDate() - 7));//date prior 7 days
      setStartDate(priorSeven);//setting start date as prior 7 days
      setEndDate(today);//setting last date as current date
    } else if (e.value.days === 15) {
      days = 15;
      let today = new Date();
      let priorFifteen = new Date(new Date().setDate(today.getDate() - 15));
      setStartDate(priorFifteen);
      setEndDate(today);
    } else if (e.value.days === 30) {
      days = 30;
      let today = new Date();
      let priorThirty = new Date(new Date().setDate(today.getDate() - 30));
      setStartDate(priorThirty);
      setEndDate(today);
    } else if (e.value.days === 90) {
      days = 90;
      let today = new Date();
      let priorNinety = new Date(new Date().setDate(today.getDate() - 90));
      setStartDate(priorNinety);
      setEndDate(today);
    }
    let labUsers = [];
    // api call for user  list to refresh the list after each option selection from dropdown
    LabServices.userReports(days).then((res) => {
      res.data.forEach((item) => {
        labUsers.push({
          lab_details: { lab_name: item.lab_name },
          purpose_details: { purpose: item.purpose },
          customer_details: { customer: item.customer },
          start_time: item.start_time,
          end_time: item.end_time,
          remark_details: { remarks: item.remarks },
        });
      });
      setUsers(labUsers);
      setFilteredValue(labUsers);
    });
  };

  const getUser = () => {
    LabServices.userReports(day).then((res) => {
      setUserData(res.data);
      let users = [];
      let labFilters = [];
      let purposeFilters = [];
      let customerFilters = [];
      let remarkFilters = [];

      res.data.filter(function (item) {
        var lab = labFilters.findIndex((x) => x.lab_name === item.lab_name);
        if (lab <= -1) {
          labFilters.push({ lab_name: item.lab_name });
        }
        //purpose
        var purpose = purposeFilters.findIndex(
          (x) => x.purpose === item.purpose
        );
        if (purpose <= -1) {
          purposeFilters.push({ purpose: item.purpose });
        }
        // customers
        var customer = customerFilters.findIndex(
          (x) => x.customer === item.customer
        );
        if (customer <= -1) {
          customerFilters.push({ customer: item.customer });
        }
        // remarks
        var remarks = remarkFilters.findIndex((x) => x.remarks === item.remarks);
        if (remarks <= -1) {
          remarkFilters.push({ remarks: item.remarks });
        }
        return null;
      });

      setLabNames(labFilters);
      setPurposes(purposeFilters);
      setCustomers(customerFilters);
      setRemarks(remarkFilters);
      res.data.forEach((item, index) => {
        users.push({
          lab_details: { lab_name: item.lab_name },
          purpose_details: { purpose: item.purpose },
          customer_details: { customer: item.customer },
          start_time: item.start_time,
          end_time: item.end_time,
          remark_details: { remarks: item.remarks },
        });
      });
      setUsers(users);
      setFilteredValue(users);
    });
    initFilters1();
  };

  const initFilters1 = () => {
    setFilters1({
      lab_details: { value: null, matchMode: FilterMatchMode.IN },
      purpose_details: { value: null, matchMode: FilterMatchMode.IN },
      customer_details: { value: null, matchMode: FilterMatchMode.IN },
      remark_details: { value: null, matchMode: FilterMatchMode.IN },
    });
    setGlobalFilterValue1("");
  };
//setting start date
  const startDateChange = (e) => {
    setStartDate(e.target.value);
  };
// setting end date
  const endDateChange = (e) => {
    setEndDate(e.target.value);
  };

  // getting dates between start and end date
  var getDaysArray = function (start, end) {
    for (
      var arr = [], dt = new Date(start);
      dt <= end;
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt).toLocaleDateString("en-CA"));
    }
    return arr;
  };

  const filteredLabs = (start, end) => {
    var daylist = getDaysArray(start, end);
   // filter labs on the basis of start and end dates
    const filteredData = users.filter((labs) => {
      return daylist.includes(
        new Date(labs.start_time).toLocaleDateString("en-CA")
      );
    });
    return filteredData;
  };

  const currentItems = !filteredData
    ? userData.slice(indexOfFirstItem, indexOfLastItem)
    : filteredData.slice(indexOfFirstItem, indexOfLastItem);

    //searching on the basis of start and end date
  const searchHandle = () => {
    const filtered = filteredLabs(startDate, endDate)
    setUsers(filtered);
    setFilteredValue(filtered);
  };

  //resetting all fields
  const resetHandle = (e) => {
    setStartDate("");
    setEndDate("");
    setSelectedDays({ filterByDays: "Last 90 days", days: 90 });
    initFilters1();
    setUsers(users);
    setFilteredValue(users);
    getUser();
  };

  useEffect(() => {
    //api call when page loads for user reports
    getUser();
  }, []);

  // method to export user details in csv
  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
    toast.current.show({
      severity: "success",
      summary: Constants.EXPORT_TOAST_SUMMARY,
      detail: Constants.EXPORT_TOAST_DETAIL,
      life: 6000,
    })
  };

  // method to  export user details in pdf
  const exportPdf = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);

    const title = "VMware Reserved Labs Report";
    // clumns of pdf
    const headers = [
      [
        "#",
        "LAB NAME",
        "PURPOSE",
        "CUSTOMER",
        "START TIME",
        "END TIME",
        "REMARK",
      ],
    ];
    const data = filteredValue.map((labs, index) => [
      index + 1,
      labs.lab_details.lab_name,
      labs.purpose_details.purpose,
      labs.customer_details.customer,
      labs.start_time,
      labs.end_time,
      labs.remark_details.remarks,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    // pdf name
    doc.save("lab_user_report.pdf");
    toast.current.show({
      severity: "success",
      summary: Constants.EXPORT_TOAST_SUMMARY,
      detail: Constants.EXPORT_TOAST_DETAIL,
      life: 6000,
    })
  };

// dropdown to show pdf and csv
  const items = [
    {
      label: "CSV",
      icon: "pi pi-file",
      command: (e) => {
        exportCSV();
      },
    },
    {
      label: "PDF",
      icon: "pi pi-file-pdf",
      command: (e) => {
        exportPdf();
      },
    },
  ];

  // index on table
  const onIndexTemplate = (rowData, props) => {
    return props.rowIndex + 1;
  };

//lab name
  const labNameBodyTemplate = (rowData) => {
    const labDetails = rowData.lab_details;
    return (
      <React.Fragment>
        <span className="lab-name-text">{labDetails.lab_name}</span>
      </React.Fragment>
    );
  };

  //Filter on lab name
  const labNameFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={labNames}
        itemTemplate={labNameItemTemplate}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="lab_name"
        placeholder="Select Labs"
        className="p-column-filter name-filter"
        maxSelectedLabels={3}
      />
    );
  };

  const labNameItemTemplate = (option) => {
    return (
      <span className="p-multiselect-filteredData-option">
        <span className="lab-name-text">{option.lab_name}</span>
      </span>
    );
  };

  // purpose column
  const purposeBodyTemplate = (rowData) => {
    const labDetails = rowData.purpose_details;
    return (
      <React.Fragment>
        <span className="lab-name-text">{labDetails.purpose}</span>
      </React.Fragment>
    );
  };

  //purpose column filter
  const purposeFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={purposes}
        itemTemplate={purposeItemTemplate}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="purpose"
        placeholder="Select Purposes"
        className="p-column-filter name-filter"
        maxSelectedLabels={3}
      />
    );
  };

  const purposeItemTemplate = (option) => {
    return (
      <span className="p-multiselect-filteredData-option">
        <span className="lab-name-text">{option.purpose}</span>
      </span>
    );
  };

  // customer template
  const customerBodyTemplate = (rowData) => {
    const labDetails = rowData.customer_details;
    return (
      <React.Fragment>
        <span className="lab-name-text">{labDetails.customer}</span>
      </React.Fragment>
    );
  };

  //filter on customer column
  const customerFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={customers}
        itemTemplate={customerItemTemplate}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="customer"
        placeholder="Select Customers"
        className="p-column-filter name-filter"
        maxSelectedLabels={3}
      />
    );
  };

  const customerItemTemplate = (option) => {
    return (
      <span className="p-multiselect-filteredData-option">
        <span className="lab-name-text">{option.customer}</span>
      </span>
    );
  };

  // remarks template
  const remarkBodyTemplate = (rowData) => {
    const labDetails = rowData.remark_details;
    return (
      <React.Fragment>
        <span className="lab-name-text">{labDetails.remarks}</span>
      </React.Fragment>
    );
  };

  // filter on remarks column
  const remarkFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={remarks}
        itemTemplate={remarkItemTemplate}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="remarks"
        placeholder="Select Remarks"
        className="p-column-filter name-filter"
        maxSelectedLabels={3}
      />
    );
  };

  const remarkItemTemplate = (option) => {
    return (
      <span className="p-multiselect-filteredData-option">
        <span className="lab-name-text">{option.remarks}</span>
      </span>
    );
  };

  const onSelectionChange = (e) => {
    setSelectedLabDetails(e.value);
  }

  return (
    <div className="container">
      <Toast ref={toast} position="top-center" />
      <div className="panel-content">
        <div className="panel report-panel p-ai-center">
          <div className="date-selection">
            <div className="panel-item">
              {/* Dropdown option to show days */}
              <Dropdown
                value={selectedDays}
                options={filterDays}
                onChange={onDaysChange}
                optionLabel="filterByDays"
                placeholder="Select quick dates"
                className="quick-date-selector"
              />
            </div>
            <div className="panel-item start-date-calender">
              {/* start date */}
              <Calendar
                value={startDate}
                onChange={(e) => startDateChange(e)}
                dateFormat="mm/dd/yy"
                mask="99/99/9999"
                selectOtherMonths={true}
                placeholder="Select Start Date"
                icon="pi pi-calendar"
                showIcon
                className="date-icon"
              />
            </div>
            <div className="panel-item end-date-calender">
              {/* end date */}
              <Calendar
                value={endDate}
                onChange={(e) => endDateChange(e)}
                dateFormat="mm/dd/yy"
                mask="99/99/9999"
                selectOtherMonths={true}
                placeholder="Select End Date"
                icon="pi pi-calendar"
                showIcon
                className="date-icon"
              />
            </div>
          </div>
          <div className="action-buttons">
            <div className="panel-item">
               {/* search button */}
              <Button
                id="search-button"
                label="SEARCH"
                className="p-button-primary search-btn"
                onClick={searchHandle}
              />
            </div>
            <div className="panel-item">
                {/* Reset button */}
              <Button
                id="reset-button"
                label="RESET"
                className="p-button-primary reset-btn"
                onClick={resetHandle}
              />
            </div>
            <div className="panel-item p-splitbutton">
              {/* split button to show csv and pdf export option */}
              <SplitButton
                ref={exportButton}
                className="p-splitbutton-button export-btn"
                onClick={() => exportButton.current.onDropdownButtonClick()}
                label="EXPORT"
                model={items}
              ></SplitButton>
            </div>
          </div>
        </div>
      </div>
      <br />

      <DataTable
        ref={dt}
        value={users}
        exportFilename="lab_user_report"
        responsiveLayout="scroll"
        className="table-type"
        filters={filters1}
        paginator
        rows={50}
        totalRecords={users.length}
        pageLinkSize={1}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        removableSort={true}
        selection={selectedLabDetails}
        onSelectionChange={onSelectionChange}
        onValueChange={filteredData => setFilteredValue(filteredData)}
      >
        <Column
          header="#"
          body={onIndexTemplate}
          style={{fontWeight:"600", width:"3rem",paddingLeft:"15px" }}
        />
        <Column
          field={`lab_details.lab_name`}
          showFilterMatchModes={false}
          style={{fontWeight:"600"}}
          sortable
          header="Lab Name"
          filterMenuStyle={{ width: "14rem" }}
          body={labNameBodyTemplate}
          filter
          filterElement={labNameFilterTemplate}
          filterField={"lab_details"}
        />
        <Column
          field={`purpose_details.purpose`}
          showFilterMatchModes={false}
          sortable
          header="Purpose"
          filterMenuStyle={{ width: "14rem" }}
          body={purposeBodyTemplate}
          filter
          filterElement={purposeFilterTemplate}
          filterField="purpose_details"
        ></Column>
        <Column
          field={`customer_details.customer`}
          showFilterMatchModes={false}
          sortable
          header="Customer"
          filterMenuStyle={{ width: "14rem" }}
          body={customerBodyTemplate}
          filter
          filterElement={customerFilterTemplate}
          filterField="customer_details"
        ></Column>
        <Column
          field="start_time"
          sortable
          header="Start Time"
        ></Column>
        <Column
          field="end_time"
          sortable
          header="End Time"
        ></Column>
        <Column
          field={`remark_details.remarks`}
          showFilterMatchModes={false}
          sortable
          header="Remark"
          filterMenuStyle={{ width: "14rem" }}
          body={remarkBodyTemplate}
          filter
          filterElement={remarkFilterTemplate}
          filterField="remark_details"
        ></Column>
      </DataTable>
      <div className="p-grid">
        <div className="p-lg-12">
          <Multiline />
        </div>
      </div>
    </div>
  );
};

export default Reports;
