import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";
import { Message } from "primereact/message";
import "../../components/rbac/Rbac.css";
import React, { useState, useRef } from "react";
import LabServices from "../../services/LabServices";
import loglevel from "../../logger/Logger";

const DeleteAdminMessage = ({ message_id, setChanger }) => {
  const [deleteAdminMessageDisplay, setDeleteAdminMessageDisplay] = useState(false);
  const deleteAdminMessageDialogMap = {
    deleteAdminMessageDisplay: setDeleteAdminMessageDisplay,
  };
  const [checked, setChecked] = useState(false);
  const [commonErrorMsg, setCommonErrorMsg] = useState("");

  const toast = useRef(null);
  const errorRef = useRef();

  //on opening delete AdminMessage Dialog
  const onShow = (name) => {
    setChecked(false);
    setCommonErrorMsg("");
    deleteAdminMessageDialogMap[`${name}`](true);
  };

  //on closing Delete dialog
  const onHide = (name) => {
    deleteAdminMessageDialogMap[`${name}`](false);
    setChecked(false);
    setCommonErrorMsg("");
  };

  // o n deleting AdminMessage
  const onDelete = (e) => {
    if (checked) {
      //api call for deleting AdminMessage
      LabServices.deleteAdminMessage(message_id)
        .then((response) => {
          if (response.status === 200) {
            //toast nessage after successful AdminMessage deletion
            toast.current.show({
              severity: "success",
              summary: "Broadcast Successfully Deleted",
              life: 6000,
            });
            deleteAdminMessageDialogMap["deleteAdminMessageDisplay"](false);
          } else {
            loglevel.debug("Admin Message Deletion is Failed");
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            setCommonErrorMsg(error.response.data.msg);
            errorRef.current.scrollIntoView({ behavior: "smooth" });
          }
        });
    }

    //----- Added timeout as list fetching and delete api was getting called simultaneously resulting in fetching old AdminMessageHistory List--------
    setTimeout(() => {
      LabServices.getAdminMessageHistory().then((res) => {
        setChanger(res.data);
      });
    }, 1000);
  };

  const renderFooter = (name) => {
    return (
      <div className="delete-btn-section">
        {/* Delete button */}
        <Button
        label="DELETE BROADCAST"
          className={
            checked ? "p-button-outlined p-button-danger" : "p-button-outlined p-button-secondary"
          }
          onClick={(e) => onDelete(e)}
          disabled={!checked}
        >
        </Button>
        {/* Cancel button */}
        <Button
          className="p-button-primary"
          label="CANCEL"
          onClick={() => onHide(name)}
        />
      </div>
    );
  };

  return (
    <div className="tag-delete">
      <Toast ref={toast} position="top-center" />
      {/* Delete icon in AdminMessage listing page */}
      <Button
        icon="pi pi-trash"
        className="p-button-rounded global-delete-button"
        onClick={() => onShow("deleteAdminMessageDisplay")}
      />
      {/* Dialog for deleting AdminMessage */}
      <Dialog
        header={`Delete Broadcast`}
        visible={deleteAdminMessageDisplay}
        footer={renderFooter("deleteAdminMessageDisplay")}
        onHide={() => onHide("deleteAdminMessageDisplay")}
        className="tag-delete-dialog-box"
      >
        <div ref={errorRef} className={commonErrorMsg ? "" : "hidden"}>
          {commonErrorMsg && (
            <Message
              severity="warn"
              text={commonErrorMsg}
              className="common-inline-error common-error-message p-mb-3"
            />
          )}
        </div>{" "}
        <div>
          If you delete the broadcast, it won't be visible to selected users
        </div>
        <br></br>
        <div>
          {/* checkbox */}
          <Checkbox
            inputId="binary"
            className=".checkbox"
            checked={checked}
            onChange={(e) => setChecked(e.checked)}
          />
          {"  "} I understand. Still want to delete the broadcast.
        </div>
      </Dialog>
    </div>
  );
};

export default DeleteAdminMessage;
