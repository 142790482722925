import { jsPDF } from 'jspdf'
import * as html2canvas from 'html2canvas';
import 'primeicons/primeicons.css';
// import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

class UtilityServices {

    exportPDF(days, input, filename, canvasType, text) {
        html2canvas(input).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            let pdf;
            if (canvasType === 'chart') {
                pdf = new jsPDF({
                    orientation: 'landscape',
                    unit: 'px',
                    format:  ((days < 90) ? [500, 545] : [500, window.screen.width + 100]),
                    userUnit: "px"
                });
            } else {
                pdf = new jsPDF({ orientation: 'landscape', unit: "px", userUnit: "px" });
            }

            if(text === "Lab Usage And Purpose Lab") {
                pdf.setFontSize(17);
                pdf.setFillColor("#22343C");
                pdf.rect(10, 20, 125, 20, 'F');
                pdf.setTextColor("white");
                pdf.text(25, 33, "Lab Usage");

                pdf.setFontSize(17);
                pdf.setFillColor("#22343C");
                pdf.rect(350, 20, 125, 20, 'F');
                pdf.setTextColor("white");
                pdf.text(365, 33, "Purpose Usage");
            } 
            // else if(text === "Portal Usage") {
            //     pdf.setFontSize(17);
            //     pdf.setFillColor("#22343C");
            //     pdf.rect(10, 20, 125, 20, 'F');
            //     pdf.setTextColor("white");
            //     pdf.text(25, 33, "Portal Visited");

            //     pdf.setFontSize(17);
            //     pdf.setFillColor("#22343C");
            //     pdf.rect(350, 20, 125, 20, 'F');
            //     pdf.setTextColor("white");
            //     pdf.text(365, 33, "Portal Usage");
            // } 
            else if(text === "Trending Labs") {
                pdf.setFontSize(17);
                pdf.setFillColor("#22343C");
                pdf.rect(10, 10, 125, 20, 'F');
                pdf.setTextColor("white");
                pdf.text(25, 23, text);
            } else if(text === "Detailed Equipment Usage") {
                pdf.setFontSize(17);
                pdf.setFillColor("#22343C");
                pdf.rect(10, 20, 180, 20, 'F');
                pdf.setTextColor("white");
                pdf.text(25, 33, text);
            }  else if(text === "Equipment Usage") {
                pdf.setFontSize(17);
                pdf.setFillColor("#22343C");
                pdf.rect(10, 20, 125, 20, 'F');
                pdf.setTextColor("white");
                pdf.text(22, 33, text);
            }   else if(text === "Login Attempts") {
                pdf.setFontSize(17);
                pdf.setFillColor("#22343C");
                pdf.rect(10, 20, 125, 20, 'F');
                pdf.setTextColor("white");
                pdf.text(22, 33, text);
            }else {
                pdf.setFontSize(17);
                pdf.setFillColor("#22343C");
                pdf.rect(10, 20, 125, 20, 'F');
                pdf.setTextColor("white");
                pdf.text(25, 33, text);
            }
            

            pdf.html(input, {
                callback: function (doc) {
                    doc.save(filename);
                },
                margin: ((canvasType === 'chart' || text === "Portal Usage") ? [40, 0, 0, 0] : [10, 0, 0, 0]),
                x: 0,
                y: 30,
                html2canvas: {
                    scale: 0.57
                },
            });
        });
    }

}

export default new UtilityServices();
