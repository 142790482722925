import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import React, { useEffect, useState, useRef } from 'react'
import GuideService from '../../../services/GuideService';
import "./AdminGuides.css"
import CreateAdminGuides from './CreateAdminGuides';
import UpdateAdminGuides from './UpdateAdminGuides';
import DeleteAdminGuides from './DeleteAdminGuides';
import loglevel from '../../../logger/Logger';

const AdminGuides = () => {
  const [adminGuideDetails, setAdminGuideDetails] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const MAX_GROUPS = 5;//max guide to show in a row

  useEffect(() => {

    async function getAllAdminGuidesList() {
      //api call for admin guide
      await GuideService.getAdminGuideList().then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          res.data[i]["closedStatus"] = true;
          res.data[i]["closedGroupsStatus"] = true;

        };
        setAdminGuideDetails(res.data)
      }).catch((error) => {
        loglevel.error("getUserPermissions", error);
      })
    }
    getAllAdminGuidesList();
  }, []);

// toggling for show more and show less guides
  const toggleGroups = (props, rowData) => {
    rowData.closedGroupsStatus = !rowData.closedGroupsStatus
    setAdminGuideDetails((prevState) => [...prevState])
    console.log("closedGroupsStatus", rowData)
  }

  const groupTemplate = (rowData, props) => {
    let groupsArray = rowData.adminGuideLabel;
    let allGroupsCount = groupsArray?.length;
    let hiddenGroupscount = (allGroupsCount - MAX_GROUPS);
    let closedGroupsStatusRow = rowData.closedGroupsStatus

    if (closedGroupsStatusRow) { // if guide count is more than  MAX_GROUPS
      groupsArray = rowData.adminGuideLabel.slice(0, MAX_GROUPS)
    }
    else {
      groupsArray = rowData.adminGuideLabel
    }
    const val = groupsArray?.map((item, index) => {
      return <a href={`${item.user_guide_link}`}
        target="_blank"
        rel="noopener noreferrer" className='link-tag'>
        <Tag key={index}  >
          <div className='link-icon-container guide-links'>
            <div className='link-icon'>
              <div className="link-icon-div">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10.0843 3.91002C11.2532 2.75346 13.1354 2.75346 14.3043 3.91002L14.2943 3.93002C14.8522 4.48549 15.1657 5.24029 15.1657 6.02752C15.1657 6.81475 14.8522 7.56956 14.2943 8.12502L11.9493 10.45C10.7804 11.6066 8.89825 11.6066 7.72931 10.45C7.49316 10.2145 7.29873 9.94063 7.15431 9.64002L7.79931 9.00002C7.8424 8.95753 7.88926 8.91904 7.93931 8.88502C8.02876 9.20866 8.20111 9.50337 8.43931 9.74002C9.2182 10.5089 10.4704 10.5089 11.2493 9.74002L13.5943 7.41502C13.9628 7.04732 14.17 6.54812 14.17 6.02752C14.17 5.50693 13.9628 5.00772 13.5943 4.64002C12.8154 3.87118 11.5632 3.87118 10.7843 4.64002L9.55431 5.86002C9.21206 5.76701 8.85897 5.71993 8.50431 5.72002H8.25931L10.0843 3.91002ZM7.56931 13.38L8.79931 12.16C9.14084 12.2458 9.49221 12.2862 9.84431 12.28H10.0943L8.26931 14.09C7.10037 15.2466 5.21825 15.2466 4.04931 14.09C3.49023 13.5352 3.17578 12.7802 3.17578 11.9925C3.17578 11.2049 3.49023 10.4498 4.04931 9.89502L6.39931 7.55002C7.56825 6.39346 9.45037 6.39346 10.6193 7.55002C10.8555 7.78552 11.0499 8.05941 11.1943 8.36002L10.5493 9.00002C10.5087 9.04922 10.4635 9.09443 10.4143 9.13502C10.3249 8.81138 10.1525 8.51668 9.91431 8.28002C9.13542 7.51118 7.88321 7.51118 7.10431 8.28002L4.75931 10.605C4.39078 10.9727 4.18367 11.4719 4.18367 11.9925C4.18367 12.5131 4.39078 13.0123 4.75931 13.38C5.53821 14.1489 6.79042 14.1489 7.56931 13.38Z" fill="#1B2A32" />
                </svg>
              </div>
            </div>
            <div className='link-value'>{item.label_name}</div></div></Tag>
      </a>
    });
    return <span>
      <span className="data-tag parent left-align">{val}</span>
      {hiddenGroupscount > 0 ?
        <span onClick={() => toggleGroups(props, rowData)} className="show-more">
          {closedGroupsStatusRow ? `${hiddenGroupscount} more links` : `less links`}</span> : ""}
    </span>
  };

  //row index
  const indexTemplate = (rowData, props) => {
    return <span className="index-field">{props.rowIndex + 1}</span>
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="table-action-body">
        {/* update admin guide */}
        <div className="table-update-icon">
          <UpdateAdminGuides adminGuideId={rowData.admin_guide_id} setAlladminGuideDetails={setAdminGuideDetails} />
        </div>
        {/* delete Admin guide */}
        <div className="table-delete-icon">
          <DeleteAdminGuides adminGuideId={rowData.admin_guide_id} guideName={rowData.guide_name} setAlladminGuideDetails={setAdminGuideDetails} />
        </div>
      </div>
    );
  };



  return (
    <div className="datatable-crud-demo">
      <div className="admin-panel">
        <div className="admin-panel-search-bar">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            {/* global search bar */}
            <InputText
              type="search"
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search"
              className="global-search-filter"
            />
          </span>
        </div>
        {/* create Admin guide button */}
        <CreateAdminGuides setAlladminGuideDetails={setAdminGuideDetails} />
      </div>
      <Toast ref={toast} position="top-center" />

      <DataTable
        responsiveLayout="scroll"
        value={adminGuideDetails}
        ref={dt}
        className="user-permission-table lab-table-type"
        columnResizeMode="fit"
        paginator
        rows={50}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        globalFilter={globalFilter}
        autoLayout={true}
        removableSort
        pageLinkSize={1}
      >
        <Column
          field="id"
          header="#"
          body={indexTemplate}
          style={{ minWidth: "2rem", paddingLeft: "10px" }}
        ></Column>
        <Column
          sortable
          field="guide_name"
          header="Guide Name"
          style={{ width: "14rem", fontFamily: "Metropolis-Semibold" }}
        ></Column>
        <Column
          field="adminGuideLabel"
          header="Admin Guide Label"
          body={groupTemplate}
          style={{ minWidth: "11rem", maxWidth: "14px" }}
        ></Column>
        <Column
          className="setting-icon-column"
          body={actionBodyTemplate}
          style={{ minWidth: "3rem" }}
          colSpan={2}
        ></Column>
      </DataTable>
      <div></div>
    </div>
  );
}

export default AdminGuides