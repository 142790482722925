import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Divider } from "primereact/divider";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { confirmPopup } from "primereact/confirmpopup";
import parse from "html-react-parser";
import LabServices from "../../services/LabServices";
import loglevel from "../../logger/Logger";
import eventBus from "../utility/EventBus";
import moment from "moment";

const Notifications = () => {
  const toast = useRef(null);
  const [notification, setNotification] = useState(0);
  const [unreadCount, setUnreadCount] = useState(0);
  const dt = useRef(null);

  useEffect(() => {
    async function fetchData() {
      // api call for notification list
      await LabServices.getNotificationList()
        .then((res) => {
          loglevel.debug("getNotificationList Response", res);
          setNotification(res.data);
        })
        .catch((error) => {
          loglevel.error("getNotificationList", error);
        });
      await LabServices.getUnreadNotificationCount().then((res) => {
        setUnreadCount(res.data);
      });
    }
    fetchData();
  }, []);


  const updateHeaderNotification = () => {
    eventBus.dispatch("notificationCount", { message: "notificationCount" });
  };

  // clear all  notificationss
  const deleteNotifications = async () => {
    await LabServices.deleteNotifications()
      .then((response) => {
        if (response.status === 200) {
          toast.current.show({
            severity: "success",
            summary: "All Notifications Successfully cleared.",
            detail: `All Notifications has been successfully cleared.`,
            life: 6000,
          });
        } else {
          loglevel.debug("Notification Deletion is Failed");
        }
      })
      .catch((error) => {
        if (error.response.statusCode === 400) {
          toast.current.show({
            severity: "error",
            summary: "Notifications Clearance Failed.",
            detail: `${error.response.msg}`,
            life: 6000,
          });
        }
      });

    await LabServices.getNotificationList()
      .then((res) => {
        loglevel.debug("getNotificationList Response", res);
        setNotification(res.data);
      })
      .catch((error) => {
        loglevel.error("getNotificationList", error);
      });

    await LabServices.getUnreadNotificationCount().then((res) => {
      setUnreadCount(res.data);
    });
    updateHeaderNotification();
  };

  // method call for clearing all notification
  const clearNotification = (e) => {
    var confirmationMsg = `Once you clear all the Notifications, you cannot restore it.  <br/>
    Still want to clear all the Notifications?`;

    confirmPopup({
      target: e.currentTarget,
      icon: "pi pi-exclamation-triangle",
      message: parse(`${confirmationMsg}`),
      className: "popup",
      style: { width: "400px" },
      acceptLabel: "No", // Interchanged label names due to Global CSS
      rejectLabel: "Yes",
      reject: () => deleteNotifications(),
    });
  };

  const rowClass = (rowData) => {
    return {
      "row-readStatus": rowData.readStatus === false,
    };
  };

  // method call for when we click on unread notifications
  const changeReadStatus = async (rowData) => {
    //api call for reading notifications
    await LabServices.changeReadStatus(rowData.portal_notification_id)
      .then((res) => {
        if (res.status === 200) {
          LabServices.getNotificationList()
            .then((res) => {
              loglevel.debug("getNotificationList Response", res);
              setNotification(res.data);
            })
            .catch((error) => {
              loglevel.error("getNotificationList", error);
            });

          LabServices.getUnreadNotificationCount().then((res) => {
            setUnreadCount(res.data);
          });
          // updating notification list
          updateHeaderNotification();
        }
      })
      .catch((error) => {
        loglevel.error("change read status error", error);
      });
  };

  // formating date time
  const dateTimeTemplate = (rowData) => {
    const local = moment.utc(rowData.createdAt, 'MM-DD-YYYY HH:mm:ss').local();
    const momentFormat = moment(local, 'MM-DD-YYYY HH:mm a').format('MM-DD-YYYY HH:mm a');
    return (<span className="notification-date-time">{momentFormat}</span>)
  }

  // description template
  const descriptionTemplate = (rowData) => {
    return (
      <div className="notification-text">
        {rowData.readStatus ? (
          rowData.description
        ) : (
          <>
            <svg
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 8C6.20914 8 8 6.20914 8 4C8 1.79086 6.20914 0 4 0C1.79086 0 0 1.79086 0 4C0 6.20914 1.79086 8 4 8Z"
                fill="#E62700"
              />
            </svg>
            <span
              className="unread-desc"
              onClick={() => changeReadStatus(rowData)}
            >
              {rowData.description}
            </span>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="notification-container">
      <Toast ref={toast} position="top-center"></Toast>
      <div className="notification-header">
        <div className="notification-title">
          Notifications ( {unreadCount} )
        </div>
        {notification.length > 0 ? (
          <div className="clear-notification" onClick={clearNotification}>
            Clear All Notifications
          </div>
        ) : (
          <div className="clear-notification-disabled">
            Clear All Notifications
          </div>
        )}
      </div>
      <Divider style={{ margin: 0 }}  />
      <div className="datatable-notification">
        {notification.length > 0 ? ( //atleast one notification is there
          <DataTable
            ref={dt}
            value={notification}
            responsiveLayout="scroll"
            columnResizeMode="fit"
            paginator
            rows={10}
            pageLinkSize={1}
            rowsPerPageOptions={[10, 20, 50, 100]}
            autoLayout={true}
            rowClassName={rowClass}
            let-i="rowIndex"
            style={{ width: "100%" }}
          >
            <Column
              field="description"
              header="Description"
              body={descriptionTemplate}
              onClick={() => changeReadStatus()}
            ></Column>
            <Column
              field="createdAt"
              header="Date and Time"
              body={dateTimeTemplate}
              style={{ width: "170px" }}
              onClick={() => changeReadStatus()}
            ></Column>
          </DataTable>
         ) : ( //when there is no notifications
          <div className="no-lab-scheduled-icon">
            <div>
              <svg
                width="44"
                height="51"
                viewBox="0 0 44 51"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M21.6556 49.8753C20.9977 49.8654 20.3548 49.8613 19.725 49.8315L19.687 50.6404C20.33 50.671 20.9845 50.6817 21.6564 50.685C22.1459 50.685 22.6296 50.6759 23.1042 50.6586L23.0736 49.8481C22.6081 49.8654 22.136 49.8753 21.6556 49.8753Z"
                  fill="#66B0D6"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20.4979 42.6001C19.3284 42.6315 18.1802 42.7124 17.0874 42.8419L17.1815 43.6459C18.2504 43.5196 19.3746 43.4404 20.5186 43.4098L20.4979 42.6001Z"
                  fill="#66B0D6"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.85219 46.6353C9.85219 46.5495 9.86788 46.4645 9.89759 46.3811L9.13492 46.1079C9.07385 46.2788 9.04248 46.4562 9.04248 46.6353C9.04248 47.5334 9.79524 48.3324 11.2785 49.0075L11.6136 48.2704C10.4787 47.7538 9.85219 47.1718 9.85219 46.6353Z"
                  fill="#66B0D6"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14.5483 43.252C13.2326 43.5285 12.1192 43.8751 11.2368 44.282L11.5752 45.0175C12.4023 44.6361 13.4588 44.3093 14.7142 44.0443L14.5483 43.252Z"
                  fill="#66B0D6"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.7495 49.8364C14.7722 50.0906 15.9096 50.2912 17.1312 50.434L17.2236 49.6292C16.0367 49.4906 14.9348 49.2958 13.9443 49.0498L13.7495 49.8364Z"
                  fill="#66B0D6"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M32.2958 44.4455C31.4465 44.0081 30.3388 43.6325 29.0033 43.3296L28.8242 44.1187C30.0747 44.4026 31.1469 44.765 31.9244 45.1653L32.2958 44.4455Z"
                  fill="#66B0D6"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M26.4669 42.8862C25.3807 42.745 24.2334 42.6526 23.0588 42.6113L23.0308 43.4202C24.1797 43.4615 25.3014 43.5523 26.3629 43.6901L26.4669 42.8862Z"
                  fill="#66B0D6"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M33.3422 46.635C33.3422 47.2244 32.5713 47.8748 31.2275 48.4212L31.5338 49.1706C33.6988 48.2924 34.1519 47.3061 34.1519 46.635L34.1503 46.5698L33.3422 46.635Z"
                  fill="#66B0D6"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M25.5752 49.6725L25.661 50.4781C26.876 50.3502 28.0151 50.1661 29.0468 49.93L28.8661 49.1401C27.8657 49.3688 26.7596 49.5487 25.5752 49.6725Z"
                  fill="#66B0D6"
                />
                <mask
                  id="mask0_13387_14263"
                  style={{ "maskType": "alpha" }}
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="44"
                  height="39"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 0H43.4363V38.8761H0V0Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_13387_14263)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M36.8754 8.35797C32.8368 9.35505 27.4543 9.90476 21.7187 9.90476C15.983 9.90476 10.5997 9.35505 6.56108 8.35797C2.03708 7.24038 0.830349 5.95029 0.830349 5.36508C0.830349 4.77987 2.03708 3.4906 6.56108 2.37302C10.5997 1.37511 15.983 0.825397 21.7187 0.825397C27.4543 0.825397 32.8368 1.37511 36.8754 2.37302C41.3994 3.4906 42.6062 4.77987 42.6062 5.36508C42.6062 5.95029 41.3994 7.24038 36.8754 8.35797ZM23.7987 24.4937V36.1961C23.5164 36.6699 22.8759 37.2039 21.9217 37.6092C20.8297 38.0723 20.0159 38.0855 19.6717 37.979V24.4937L3.31232 8.21187C7.15289 9.724 13.9583 10.7302 21.7187 10.7302C29.5145 10.7302 36.348 9.71492 40.1787 8.19124L23.7987 24.4937ZM43.4365 5.36508C43.4365 2.4019 33.7133 0 21.7187 0C9.72317 0 0 2.4019 0 5.36508C0 5.92387 0.346667 6.46203 0.987175 6.968L0.986349 6.96883L18.8463 24.8238V38.4098C19.1013 38.7359 19.566 38.8762 20.1248 38.8762C20.767 38.8762 21.5338 38.6905 22.2461 38.3884C23.419 37.8898 24.4672 37.0669 24.6224 36.2729V24.8238L42.4848 6.96883L42.4683 6.95314C43.0973 6.4513 43.4365 5.9181 43.4365 5.36508Z"
                    fill="#66B0D6"
                  />
                </g>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.56115 2.37265C2.03715 3.49023 0.82959 4.7795 0.82959 5.36471C0.82959 5.95074 2.03715 7.24001 6.56115 8.3576C9.14794 8.99646 12.2894 9.4496 15.7197 9.69392L12.7574 1.31201C10.4834 1.57036 8.38032 1.92363 6.56115 2.37265Z"
                  fill="#66B0D6"
                  fillOpacity="0.4"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M27.5003 10.5367L22.2178 24.4867V37.4628C23.0011 37.0798 23.5483 36.6168 23.7992 36.1958V24.4933L40.1784 8.19092C37.3316 9.32336 32.8258 10.1743 27.5003 10.5367Z"
                  fill="#66B0D6"
                  fillOpacity="0.4"
                />
              </svg>
            </div>
            <div className="lab-scheduled-msg">
              There is no Notification currently.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notifications;
