import React, { useRef } from "react";
import { confirmPopup } from "primereact/confirmpopup";
import { Button } from "primereact/button";
import LabServices from "../../../../services/LabServices";
import loglevel from "../../../../logger/Logger";
import { Tooltip } from "primereact/tooltip";
import eventBus from "../../../utility/EventBus";

const ActiveLabCancelButton = ({schedule_id, status , permission, fetchData, showSuccessToast, showFailureToast}) => {
  loglevel.debug("Entering into CancelLab Component");

  const confirm1 = (event) => {
    confirmPopup({
        target: event.currentTarget,
        message: 'Do you want to cancel the scheduled lab?',
        icon: 'pi pi-exclamation-triangle',
        className: "popup",
        accept 
      });
  };

   // method call for updating notification
  const updateHeaderNotification = () => {
    eventBus.dispatch("notificationCount", { message: "notificationCount" });
  };

  //method to update the list
  const updateList = () => fetchData()

  //method to show success toast
  const displaySuccessToast = (detail) => showSuccessToast(detail)

  //method to show failure toast
  const displayFailureToast = (error) => showFailureToast(error)
 
  const accept = async () => {
    loglevel.debug("Entering into CancelLab Component: accept method");
    // api call for cancel lab
    await LabServices.cancelSchedule(schedule_id)
    .then((response) => {
      console.log("cancelSchedule Response", response)
      if (response.status === 200) {         
        //updating notifications
        updateHeaderNotification();
        //updating active lab list
        updateList();
        //Display toast message
        displaySuccessToast("Lab is cancelled. Check under History Tab for status");
      }
    })
    .catch((error) => {
      loglevel.error("cancelSchedule", error);  
      //Display toast message
      displayFailureToast(error);
    });
  };

  // Cancel button is disabled for status Failed, Deleted and Cancelled
  if (status === "Failed" || status === "Deleted" || status === "Cancelled") {
      return (
        <div className="cancel-button">
          <Button
            className="p-button-primary disabled"
            label="Cancel"
            disabled
          />
        </div>
      );
    }
    else if(status === "Deploying" || status === "Deleting"){
      return (
        <div className="card cancelLabButtonTarget" data-pr-tooltip="Lab can't be cancelled during deletion or deployment. It will be enabled once the lab is Active">
      <Tooltip target=".cancelLabButtonTarget"  position="top"  />
        <div className="cancel-button">
          <Button
            className="p-button-primary disabled"
            label="Cancel"
            disabled
          />
        </div>
        </div>
      );
    }
    else if (permission === "Lab User"){ // cancel button disabled for Lab User
      return (
        <div className="cancel-button">
          <Button
            className="p-button-primary disabled"
            label="Cancel"
            disabled
          />
        </div>
      );
    }
    else {
      return(
          <div className="cancel-button" >
              <Button label="Cancel" className="p-button-primary" name="cancel-button" onClick={confirm1} />
          </div>
      )
  }
}
export default ActiveLabCancelButton;