import React, { useState, useRef } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import funnelImage from "../../../../images/funnel_image.svg"
import { Message } from 'primereact/message';
import LabServices from '../../../../services/LabServices';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast'

const DeleteUser = ({ rowId,userName, setAlluserDetails,setChanger }) => {
    
    const toast = useRef(null);
    const [deleteUserPopup, setDeleteUserPopup] = useState(false);
    const [futurescheduleList, setFuturescheduleList] = useState([]);
    const [enableDisableButton, setEnableDisableButton] = useState(true);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [deleteUserChecked, setDeleteUserChecked] = useState(false);
    const deletedBy = localStorage.getItem('users_id');
    const [commonErrorMsg, setCommonErrorMsg] = useState("");
    const errorRef = useRef();


    const deleteUserDialogMap = {
        deleteUserPopup: setDeleteUserPopup
    };

    //on delete user dialog open
    const onShowDeleteUserPopup = (name) => {
        // fetching all future schedules
        LabServices.getFuturescheduleList(rowId).then((response) => {
            setFuturescheduleList(response.data);
            deleteUserDialogMap[`${name}`](true);
            setDeleteUserChecked(false);
            setEnableDisableButton(true);
            setCommonErrorMsg("");
        }).catch((error) => {
            console.log(error)
        })

        LabServices.getUserById(rowId).then((response) => {
            setFirstName(response.data.firstName);
            setLastName(response.data.lastName);
        }).catch((error) => {
            console.log(error)
        })
    }
   
    // dialog closes after deleting user
    const onDeleteUserPopupHide = (name) => {
        deleteUserDialogMap[`${name}`](false);
        setDeleteUserChecked(false);
        setEnableDisableButton(false);
        setCommonErrorMsg("");
    }

    const deleteUserPopupFooter = (name) => {
        return (
            <div>
                {/* Delete user button */}
                <Button  label="DELETE USER" className={enableDisableButton ? 'p-button-outlined p-button-secondary' : 'p-button-outlined p-button-danger'} onClick={onDeleteUser} disabled={enableDisableButton} />
                {/* cancel button */}
                <Button label="CANCEL" className='inactive-cancel-btn' onClick={() => onDeleteUserPopupHide(name)} />
            </div>
        );
    };

    // checkbox before deleting
    const onDeleteUserChecked = (e) => {
        setDeleteUserChecked(e.checked)
        if (deleteUserChecked === true) {
            setEnableDisableButton(true);
        }
        else {
            setEnableDisableButton(false);
        }
    }

    // on deleting user
    const onDeleteUser = async () => {
      // api call for deleting user
        await LabServices.deleteUser(rowId, deletedBy).then((response) => {
            deleteUserDialogMap[`deleteUserPopup`](false);
            setDeleteUserChecked(false);
            setEnableDisableButton(false);
            // toast message after successful deletion
            toast.current?.show({
                severity: "success",
                summary: `User Deleted Successfully`,
                detail: `${firstName} ${lastName} has been deleted successfully from the portal.`,
                life: 5000,
            });
        }).catch((error) => {
            if (error.response.status === 400) {
                setCommonErrorMsg(error.response.data.msg);
                // error message response
                errorRef.current.scrollIntoView({ behavior: "smooth" });
              }        })

        // refreshing user list on view page
        await LabServices.getUserPermissions().then((res) => {
            (res.data).forEach(element => {
                element["name"] = element.firstName + " " + element.lastName
            });
            setChanger(res.data.length)
            setAlluserDetails(res.data);
        })
    }

    return (
      <div className="delete-user-container">
        <Toast ref={toast} position="top-center" />
        <div className="delete-user-btn">
          {/* delete icon on listing page */}
          <Button
            icon="pi pi-trash"
            className="p-button-rounded user-delete-button"
            onClick={() => onShowDeleteUserPopup(`deleteUserPopup`)}
          />
        </div>
        {/* Delete user dialog popup */}
        <div className="delete-user-dialog">
          <Dialog
            header={`Delete user : ${userName}`}
            visible={deleteUserPopup}
            style={{ width: futurescheduleList.length ? "50vw" : "30vw" }}
            onHide={() => onDeleteUserPopupHide("deleteUserPopup")}
            footer={deleteUserPopupFooter("deleteUserPopup")}
            className="inactive-user-dialog"
          >
            <div ref={errorRef} className={commonErrorMsg ? "" : "hidden"}>
              {commonErrorMsg && (
                <Message
                  severity="warn"
                  text={commonErrorMsg}
                  className="common-inline-error common-error-message p-mb-3"
                />
              )}
            </div>
            {futurescheduleList.length ? ( // showing upcomming schedules for user
              <div className="future-shedulelist-container">
                <div className="future-shedulelist-title">
                  <p className="future-shedulelist-title-text">
                    Upcoming Lab Schedules for User
                  </p>
                </div>
                <div className="future-shedulelist-table">
                  <DataTable
                    responsiveLayout="scroll"
                    value={futurescheduleList}
                    className="shedulelist-table"
                  >
                    <Column
                      field="lab_name"
                      header="Lab Name"
                      style={{ minWidth: "10rem" ,paddingLeft:"15px" }}
                    ></Column>
                    <Column
                      field="scheduled_start"
                      header="Start Date"
                      style={{ minWidth: "9rem" }}
                    ></Column>
                    <Column
                      field="scheduled_end"
                      header="End Date"
                      style={{ minWidth: "9rem" }}
                    ></Column>
                    <Column
                      field="user_time_zone"
                      header="Timezone"
                      style={{ minWidth: "1rem" }}
                    ></Column>
                    <Column
                      field="client_name"
                      header="Client Name"
                      style={{ minWidth: "10rem" }}
                    ></Column>
                    <Column
                      field="purpose"
                      header="Purpose"
                      style={{ minWidth: "10rem" }}
                    ></Column>
                  </DataTable>
                </div>
                <div className="future-sheduled-warn p-mt-2">
                  <Message
                    severity="warn"
                    text={`If you delete the User, the User will lose all the data permanently from the VMWare SDE Labs portal.Also, future schedules of labs for the user will be cancelled but Active lab schedules will remain and run as they are.`}
                    className="common-inline-error common-inline-warn"
                  />
                </div>
              </div>
            ) : ( // if no upcomming schedules are there
              <div className="nofuture-sheduled-container">
                <div className="nofuture-scheduled-content p-d-flex p-flex-column p-ai-center">
                  <div className="funnel-image p-mt-2">
                    <img src={funnelImage} alt="funnel-image" />
                  </div>
                  <div className="nofuture-scheduled-message">
                    <p className="nofuture-scheduled-text">
                      We couldn’t find any lab Schedule!
                    </p>
                  </div>
                </div>
                <div className="future-sheduled-warn p-mt-2">
                  <Message
                    severity="warn"
                    text={`If you delete the User, the User will lose all the data permanently from the VMWare SDE Labs portal.`}
                    className="common-inline-error common-inline-warn"
                  />
                </div>
              </div>
            )}
            <div className="checkbox-div p-d-flex p-ai-center p-mt-3">
              {/* checkbox to confirm deletion of user */}
              <Checkbox
                className="reg-checkbox"
                inputId="binary"
                checked={deleteUserChecked}
                onChange={onDeleteUserChecked}
              />
              <label className="del-checkbox-label p-ml-1" htmlFor="binary">
                <span className="p-ml-1">
                  I understand. Still want to delete the user.
                </span>
              </label>
            </div>
          </Dialog>
        </div>
      </div>
    );
}

export default DeleteUser