import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import "./TimeoutModal.css";
import loglevel from '../../logger/Logger';
import { useNavigate } from "react-router-dom";


const IdleTimeOutModal = ({ showModal, handleContinue, handleLogout }) => {

    let navigate = useNavigate();


    loglevel.debug("Entering into IdleTimeOutModal Component");
    const footer = (
        <div>

            <Button label="NO"
                className="no-button"
                onClick={(e) => {
                    localStorage.setItem("isAuthenticated", "false");
                    navigate("/");
                }}
            />
            <Button label="YES" onClick={handleContinue} className="yes-button" />

        </div>
    );
    return (
        <div>
            <Dialog className='timeout-popup' onHide={handleLogout} visible={showModal} header="Warning" footer={footer} modal>
                Your session will expire soon. Do you want to continue this session?
            </Dialog>
        </div>
    )
}

export default IdleTimeOutModal;