import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import LabCard from "./LabCard";
import { Paginator } from "primereact/paginator";
import { MultiSelect } from "primereact/multiselect";
import "./Container.css";
import LabListView from "./LabListView";
import LoadingSpinner from "../loader/LoadingSpinner";
import LabServices from "../../services/LabServices";
import loglevel from "../../logger/Logger";
import { useGlobalState } from '../../config/state';
import eventBus from "../utility/EventBus";

function Labs() {
  const { id } = useParams();
  loglevel.debug("Entering into Labs Component");
  const [details, setDetails] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useGlobalState('isAdmin');
  const [adminReportsAccess, setAdminReportsAccess] = useGlobalState('adminReportsAccess')
  const [labCategories, setLabCategories] = useGlobalState('labCategories');
  const [isComingSoon, setIsComingSoon] = useState(false);

  useEffect(async () => {
    setLoading(true);
    if (localStorage.getItem("users_id")) {
      let labCatgList = [];
      let param = `?users_id=${localStorage.getItem("users_id")}`;
      // api call for listing labs
      await LabServices.getRbacLabList(param).then((res) => {
        setIsAdmin(res.data.isAdmin);
        setAdminReportsAccess(res.data.adminReportsAccess);
        setLabCategories(res.data.categories);
        labCatgList = res.data.categories;
      })
      // Available Lab tab
      if (activeIndex === 0) {

        labCatgList.forEach(category => {
          if (parseInt(id) === category.category_id) {
            loglevel.debug("getAvailableLabs details", category.activeLabList);
            setDetails(category.activeLabList);
            setIsComingSoon(false);
          }else if(category.subCategoryList.length>0){
            category.subCategoryList.forEach(subcat=>{
              if (parseInt(id) === subcat.category_id) {
                loglevel.debug("getAvailableLabs details", category.activeLabList);
                setDetails(subcat.activeLabList);
                setIsComingSoon(false);
              }
            })
          }
        });
        setLoading(false);
      } else { // Comming soon tab
        setLoading(true);
        labCatgList.forEach(category => {
          if (parseInt(id) === category.category_id) {
            loglevel.debug("getComingSoonLabs details", category.futuredLabList);
            setDetails(category.futuredLabList);
            setIsComingSoon(true);
          }else if(category.subCategoryList.length>0){
            category.subCategoryList.forEach(subcat=>{
              if (parseInt(id) === subcat.category_id) {
                loglevel.debug("getAvailableLabs details", category.activeLabList);
                setDetails(subcat.futuredLabList);
                setIsComingSoon(true);
              }
            })
          }
        });
        setLoading(false);
      }
    }
    // for page refreshing
    eventBus.on("pageReloaded", async () => {
      let labCatgList = [];
      let param = `?users_id=${localStorage.getItem("users_id")}`;
      await LabServices.getRbacLabList(param).then((res) => {
        setIsAdmin(res.data.isAdmin);
        setAdminReportsAccess(res.data.adminReportsAccess);
        setLabCategories(res.data.categories);
        labCatgList = res.data.categories;
      })
      if (activeIndex === 0) {

        labCatgList.forEach(category => {
          if (parseInt(id) === category.category_id) {
            loglevel.debug("getAvailableLabs details", category.activeLabList);
            setDetails(category.activeLabList);
            setIsComingSoon(false);
          }else if(category.subCategoryList.length>0){
            category.subCategoryList.forEach(subcat=>{
              if (parseInt(id) === subcat.category_id) {
                loglevel.debug("getAvailableLabs details", category.activeLabList);
                setDetails(subcat.activeLabList);
                setIsComingSoon(false);
              }
            })
          }
        });
        setLoading(false);
      } else {
        setLoading(true);
        labCatgList.forEach(category => {
          if (parseInt(id) === category.category_id) {
            loglevel.debug("getAvailableLabs details", category.activeLabList);
            setDetails(category.futuredLabList);
            setIsComingSoon(true);
          }else if(category.subCategoryList.length>0){
            category.subCategoryList.forEach(subcat=>{
              if (parseInt(id) === subcat.category_id) {
                loglevel.debug("getAvailableLabs details", category.activeLabList);
                setDetails(subcat.futuredLabList);
                setIsComingSoon(true);
              }
            })
          }
        });
        // labCatgList.forEach(category => {
        //   if (parseInt(id) === category.category_id) {
        //     loglevel.debug("getComingSoonLabs details", category.futuredLabList);
        //     setDetails(category.futuredLabList);
        //     setIsComingSoon(true);
        //   }
        // });
        setLoading(false);
      }
    }
    )
    if (activeIndex === 0) {

      labCategories.forEach(category => {
        if (parseInt(id) === category.category_id) {
          loglevel.debug("getAvailableLabs details", category.activeLabList);
          setDetails(category.activeLabList);
          setIsComingSoon(false);
        }else if(category.subCategoryList.length>0){
          category.subCategoryList.forEach(subcat=>{
            if (parseInt(id) === subcat.category_id) {
              loglevel.debug("getAvailableLabs details", category.activeLabList);
              setDetails(subcat.activeLabList);
              setIsComingSoon(false);
            }
          })
        }
      });
      setLoading(false);
    } else {
      setLoading(true);
      labCategories.forEach(category => {
        if (parseInt(id) === category.category_id) {
          loglevel.debug("getAvailableLabs details", category.activeLabList);
          setDetails(category.futuredLabList);
          setIsComingSoon(true);
        }else if(category.subCategoryList.length>0){
          category.subCategoryList.forEach(subcat=>{
            if (parseInt(id) === subcat.category_id) {
              loglevel.debug("getAvailableLabs details", category.activeLabList);
              setDetails(subcat.futuredLabList);
              setIsComingSoon(true);
            }
          })
        }
      });
      // labCatgList.forEach(category => {
      //   if (parseInt(id) === category.category_id) {
      //     loglevel.debug("getComingSoonLabs details", category.futuredLabList);
      //     setDetails(category.futuredLabList);
      //     setIsComingSoon(true);
      //   }
      // });
      setLoading(false);
    }
  }, [id, activeIndex]);
  let today = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

  const [isGrid, layoutToggle] = useState({
    value: true,
  });

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCatValue, setselectedCatValue] = useState("");
  const [date, setDate] = useState("");
  const [searchField, setSearchField] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [searchDate, setSearchDate] = useState("");
  const [dateArray, setDateArray] = useState("");
  const [showDateRange, setShowDateRange] = useState(true);

  // sort options
  const sortOption = [
    { name: "Recently Added", value: "scheduled_start" },
    { name: "Name(A-Z)", value: "name" },
    { name: "Name(Z-A)", value: "reverse" },
  ];
  const [sortType, setSortType] = useState("createdAt");
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(12);

  const indexOfLastItem = itemsPerPage + currentPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const categories = [];
  details.map((item) => {
    return categories.push(item.name);
  });

  const filteredLabs = details
    .filter((lab) => {
      loglevel.debug("Entering into Labs Component: filteredLabs method");
      // filtering Labs on the basis of searching by Lab name and description
      if (searchField !== "" && selectedCategory.length === 0 && dateArray === "") { 

        return (
          lab.name.toLowerCase().includes(searchField.toLowerCase()) || 
          lab.description.toLowerCase().includes(searchField.toLowerCase())
        );
      } else if ( //searching based on category and search field
        searchField !== "" &&
        selectedCategory.length > 0 &&
        dateArray === ""
      ) {

        return (
          (lab.name.toLowerCase().includes(searchField.toLowerCase()) ||
            lab.description.toLowerCase().indexOf(searchField) > -1) &&
          selectedCategory.includes(lab.name)
        );
      } else if ( // searching by category
        searchField === "" &&
        selectedCategory.length > 0 &&
        dateArray === ""
      ) {

        return selectedCategory.includes(lab.name);
      } else if ( // seaching by category and date in combination
        searchField === "" &&
        selectedCategory.length > 0 &&
        dateArray !== ""
      ) {
        return (
          selectedCategory.includes(lab.name) &&
          !dateArray.every((v) => lab.lab_not_available.includes(v))
        );
      }
      else if (selectedCategory.length > 0) { //if all the field are selected
        return (
          (lab.name.toLowerCase().includes(searchField.toLowerCase()) ||
            lab.description.toLowerCase().includes(searchField.toLowerCase())
          ) &&
          selectedCategory.includes(lab.name) &&
          !dateArray.every((v) => lab.lab_not_available.includes(v))
        );
      }
      else if (dateArray !== "" && searchField !== "") { //searching based on search field and date
        return (
          (lab.name.toLowerCase().includes(searchField.toLowerCase()) ||
            lab.description.toLowerCase().includes(searchField.toLowerCase())
          )
          &&
          !dateArray.every((v) => lab.lab_not_available.includes(v))
        );
      }
      else if (dateArray !== "") { // searching based on date
        return !dateArray.every((v) => lab.lab_not_available.includes(v));
      } else {
        return lab.name.toLowerCase().includes(searchField.toLowerCase());
      }
    })
    .sort((a, b) => {
      if (sortType === "createdAt") { // sorting on the basis of recently added
        let da = new Date(a.createdAt).getTime(),
          db = new Date(b.createdAt).getTime();
        return da - db;
      }
      if (sortType === "name") {  // sorting by name (A-Z)
        let fa = a.name.toLowerCase(),
          fb = b.name.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      }
      if (sortType === "reverse") { // sorting by name (Z-A)
        let fa = a.name.toLowerCase(),
          fb = b.name.toLowerCase();

        if (fa > fb) {
          return -1;
        }
        if (fa < fb) {
          return 1;
        }
        return 0;
      }
      return 0;
    });

  const [catTooltip, setCatTooltip] = useState(false);

  // on category change from dropdown
  const onCategoryChange = (e) => {
    setselectedCatValue(e.target.value);
    setSelectedCategory(e.target.value);
    e.target.value.length > 3 ? setCatTooltip(true) : setCatTooltip(false);
  };

  // on changing date
  const onSetDate = (e) => {
    setSearchDate(e.value);
    var fromDate = new Date(e.value[0]);
    var toDate;
    if (e.value[1] == null) {
      toDate = new Date(e.value[0]);
    } else {
      toDate = new Date(e.value[1]);
    }

    let datesArray = [];

    for (let q = fromDate; q <= toDate; q.setDate(q.getDate() + 1)) {
      datesArray.push(q.toLocaleDateString("en-CA"));
    }

    setDateArray(datesArray);
  };

  //on searching by name or discription
  const handleChange = (e) => {
    setSearchValue(e.target.value);
    setSearchField(e.target.value);
  };

  //on changing page from paginator
  const onBasicPageChange = (event) => {
    setCurrentPage(event.first);
    setItemsPerPage(event.rows);
  };

  //on resetting
  const resetHandle = (e) => {
    setSearchField("");
    setSearchValue("");
    setSelectedCategory("");
    setselectedCatValue("");
    setDate([]);
    setSearchDate([]);
    setDateArray("");
  };
   // on hiding calender date is reset to empty
  const onCalendarHide = () => {
    if (searchDate === '') {
      setSearchDate([]);
    }
  }
  const currentItems = filteredLabs.slice(indexOfFirstItem, indexOfLastItem);
  const filtered = currentItems.map((lab) => (
    // card for each lab
    <LabCard id="display-card" key={lab.labId} lab={lab} isComingSoon={isComingSoon} />
  ));

  // Available Lab tabs
  const setIndex0 = (e) => {
    setShowDateRange(true)
    setLoading(true);
    setActiveIndex(0);
    labCategories.forEach(category => {
      if (parseInt(id) === category.category_id) {
        loglevel.debug("getAvailableLabs details", category.activeLabList);
        setDetails(category.activeLabList);
        setIsComingSoon(false)
      }
    });
    setLoading(false);
  };

  //comming soon tab
  const setIndex1 = (e) => {
    setShowDateRange(false)
    setLoading(true);
    setActiveIndex(1);
    labCategories.forEach(category => {
      if (parseInt(id) === category.category_id) {
        loglevel.debug("getComingSoonLabs details", category.futuredLabList);
        setDetails(category.futuredLabList);
        setIsComingSoon(true)
      }
    });
    setLoading(false);
  };
  return (
    <section>
      {/* loading icon if data is not loaded */}
      {loading ? (
        <div className="loader">
          <LoadingSpinner />
        </div>
      ) : (
        <div>
          <div className="panel-content">
            <div className="panel">
              <div className="panel-item">
                <span className="p-input-icon-left">
                  {/* Search inputBox */}
                  <i className="pi pi-search" />
                  <InputText
                    onChange={handleChange}
                    placeholder="Search by Name or Description"
                    name="allLab-search-input"
                    value={searchValue}
                    className="searchbar-category-dropdown"
                    // style={{ minWidth: "12rem" }}
                    id="all-lab-input"
                  />
                </span>
              </div>

              <div className="panel-item">
                {/* Multiselect for categories */}
                <MultiSelect
                  tooltip={catTooltip ? selectedCatValue : ""}
                  tooltipOptions={{ position: "top" }}
                  showSelectAll={true}
                  selectAll={false}
                  value={selectedCatValue}
                  name="category-dropdown"
                  id="lab-dropdown"
                  options={categories}
                  onChange={onCategoryChange}
                  placeholder="Select Lab"
                  className="searchbar-category-dropdown"
                  display="chip"
                />
              </div>
              {/* date calendar */}
              {showDateRange ?
                <div className="panel-item">
                  <Calendar
                    value={searchDate}
                    minDate={today}
                    onChange={onSetDate}
                    onHide={onCalendarHide}
                    selectionMode="range"
                    dateFormat="dd-mm-yy"
                    readOnlyInput
                    placeholder="Select Date/Date Range"
                    name="labDropdown"
                    id="lab-calender"
                    className="category-dropdown"
                    selectOtherMonths={true}
                    style={{ minWidth: "6rem" }}
                    showIcon
                  />
                </div>
                : ""}
              <div className="reset-button reserved-reset-btn">
                {/* reset button to make all field empty */}
                <Button
                  id="reset-button"
                  label="RESET"
                  className="p-button-primary"
                  onClick={resetHandle}
                />
              </div>
            </div>
          </div>
          <div style={{ padding: "0.5rem 0" }}>
            <span className="result-count">
              Showing {currentItems.length} of {filteredLabs.length} results
            </span>
          </div>
          {/* Tab */}
          <div className="tabmenu">
            <button
              className="menuitem-link"
              style={{
                borderColor:
                  activeIndex === 0
                    ? "#979797 #979797 #1B2A32 #979797"
                    : "#1B2A32 #1B2A32 #979797 #1B2A32",
              }}
              onClick={setIndex0}
              data-testid="all-labs-title"
            >
              <div className={activeIndex === 0 ? "active-tab" : "inactive-tab"}>Available Labs</div>
            </button>
            <button
              className="menuitem-link"
              style={{
                borderColor:
                  activeIndex === 1
                    ? "#979797 #979797 #1B2A32 #979797"
                    : "#1B2A32 #1B2A32 #979797 #1B2A32",
              }}
              onClick={setIndex1}
            >
              <div className={activeIndex === 1 ? "active-tab" : "inactive-tab"}>Coming Soon</div>
            </button>
            <div className="float-right">
              <div className="sortMsg">sort by: </div>
              <div className="sortMsg pointer custom-basic-dropdown">
                {/* sort by dropdown */}
                <Dropdown
                  className="sort-dropdown sort-by-class" style={{border:'0px'}}
                  value={sortType}
                  options={sortOption}
                  onChange={(e) => {
                    setSortType(e.target.value);
                  }}
                  optionLabel="name"
                  placeholder="Recently Added"
                />
              </div>

              <div
                className="sortMsg pointer"
                style={{ color: isGrid.value ? "#49AFD9" : "#ADBBC4" }}
              >
                <i
                  className="pi pi-th-large"
                  onClick={() => layoutToggle({ value: true })} // grid view icon
                />
              </div>
              <div
                className="sortMsg pointer"
                style={{ color: isGrid.value ? "#ADBBC4" : "#49AFD9" }}
              >
                <i
                  className="pi pi-list"    // list view icon
                  onClick={() => layoutToggle({ value: false })}
                />
              </div>
            </div>
          </div>
          <br />
          {activeIndex === 0 ? ( // for Available lab tab
            isGrid.value ? ( // if grid view
              <div className="p-grid" id="all-labs" data-testid="list">
                {/*  if no labs is there */}
                {filtered.length === 0 ? <p className='no-data p-ml-2'>No available options</p> : filtered}
              </div>
            ) : ( // list view
              <div className="panel-content">
                <LabListView lab={currentItems} enabled={true} />
              </div>
            )
          ) : isGrid.value ? ( //for comming soon tab grid view
            <div className="p-grid" id="all-labs" data-testid="list">
              {filtered.length === 0 ? <p className='no-data p-ml-2'>No available options</p> : filtered}
            </div>
          ) : ( // list view for comming soon tab
            <div className="panel-content">
              <LabListView lab={currentItems} enabled={false} isComingSoon={isComingSoon} />
            </div>
          )}
          <br />
          <div className="card">
            {/* Paginator */}
            <Paginator
              className="paginator"
              first={currentPage}
              rows={itemsPerPage}
              totalRecords={filteredLabs.length}
              pageLinkSize={1}
              onPageChange={onBasicPageChange}
              rowsPerPageOptions={[6, 9, 12, 15]}
            ></Paginator>
          </div>
        </div>
      )}
    </section>
  );
}

export default Labs;
