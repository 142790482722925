import React, { useState, useRef } from 'react'
import 'primereact/resources/themes/bootstrap4-dark-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProtectedRoute from './components/route/ProtectedRoute';
import Login from './components/login/Login';
import Dashboard from './components/home/Dashboard';
import Register from './components/register/Register';
import Terms from './components/terms/Terms';
import Privacy from './components/privacy/Privacy';
import NotFound from './components/error-page/NotFound'
import { Toast } from "primereact/toast";
import Sso from './components/sso/Sso';
import Labs from './components/labs/Labs';
import ReservedLabs from './components/reserved-labs/Reserved-labs';
import http from "./http-comman";
import LabManagement from './components/admin/LabManagement';
import UserManagement from './components/admin/UserManagement';
import Reports from './components/reports/Reports';
import InventoryMgmt from './components/admin/InventoryMgmt';
import AboutUs from './components/header/AboutUs';
import ContactUs from './components/header/ContactUs';
import Notifications from './components/header/Notifications';
import LoginSsoError from './components/login/LoginSsoError'
import MessagesMgmt from './components/admin/MessagesMgmt';
import Settings from './components/header/Settings';
import AuthError from './components/error-page/AuthError';
import Guides from './components/guides/Guides';
import Netbox from './components/netbox/Netbox';
import CloseAccount from './components/header/CloseAccount';
import AdminReportsMgmt from './components/admin/AdminReportsMgmt';
import UtilizationReportsManagement from './components/admin/UtilizationReportsMgmt';

var errorToast;

http.interceptors.response.use(null, async error => {
  const expectedError = error.response && error.response.status;
  if (expectedError && error.response.status !== 401 && error.response.status !== 400 && error.response.status !== 406 && error.response.status !== 409 && error.response.status !== 403) {
    errorToast.current.show({
      severity: "error",
      summary: "Something went wrong.Please contact administrator",
      // sticky:true,
      life: 10000,
    });
  }

  if (expectedError && error.response.status == 401 && error.response.data.msg[0] === "Unauthorized" ){
    // let refreshToken = await http.get("/login/refreshtoken", localStorage.getItem('refreshToken'));
    // console.log("##### refreshToken:",refreshToken);
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("expiresIn");
    localStorage.removeItem("users_id");
    localStorage.removeItem("lname");
    localStorage.removeItem("lastInteractionTime");
    localStorage.removeItem("fname");
    localStorage.removeItem("loglevel");
    window.location.href = '/auth-error'
  }

  return Promise.reject(error);
});

http.interceptors.request.use(async request => {

  if (localStorage.getItem('isAuthenticated') && localStorage.getItem('accessToken')) {
    request.headers.common.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
  }
  return request;
});

const App = () => {
  errorToast = useRef(null);
  return (<>
    <div>
      <Toast ref={errorToast} position="top-center" />
      <BrowserRouter>
        <Routes>
          <Route path="/home" element={<Dashboard />}>
            <Route path="/home" element={<ProtectedRoute />}>
              <Route path="" element={<Labs />} />
            </Route>
            <Route path="/home/labs/:id" element={<ProtectedRoute />}>
              <Route path="" element={<Labs />} />
            </Route>
            <Route path="/home/reservedLabs" element={<ProtectedRoute />}>
              <Route path="" element={<ReservedLabs />} />
            </Route>
            <Route path="/home/user-reports" element={<ProtectedRoute />}>
              <Route path="" element={<Reports />} />
            </Route>
            <Route path="/home/admin-reports" element={<ProtectedRoute />}>
              <Route path="" element={<AdminReportsMgmt />} />
            </Route>

            <Route path="/home/admin/lab-management" element={<ProtectedRoute />}>
              <Route path="" element={<LabManagement />} />
            </Route>
            <Route path="/home/admin/user-management" element={<ProtectedRoute />}>
              <Route path="" element={<UserManagement />} />
            </Route>
            <Route path="/home/admin/inventory-management" element={<ProtectedRoute />}>
              <Route path="" element={<InventoryMgmt />} />
            </Route>
            <Route path="/home/user-reports" element={<ProtectedRoute />}>
              <Route path="" element={<Reports />} />
            </Route>
            <Route path="/home/admin-reports" element={<ProtectedRoute />}>
              <Route path="" element={<AdminReportsMgmt />} />
            </Route>
            <Route path="/home/utilization-reports" element={<ProtectedRoute />}>
              <Route path="" element={<UtilizationReportsManagement />} />
            </Route>
            <Route path="/home/guides" element={<ProtectedRoute />}>
              <Route path="" element={<Guides />} />
            </Route>
            <Route path="/home/netbox" element={<ProtectedRoute />}>
              <Route path="" element={<Netbox />} />
            </Route>
            <Route path="/home/about" element={<ProtectedRoute />}>
              <Route path="" element={<AboutUs />} />
            </Route>
            <Route path="/home/contactus" element={<ProtectedRoute />}>
              <Route path="" element={<ContactUs />} />
            </Route>
            <Route path="/home/notifications" element={<ProtectedRoute />}>
              <Route path="" element={<Notifications />} />
            </Route>
            <Route path="/home/settings" element={<ProtectedRoute />}>
              <Route path="" element={<Settings />} />
              </Route>
              <Route path="/home/close-account" element={<ProtectedRoute />}>
              <Route path="" element={<CloseAccount/>} />
              </Route>
            <Route path="/home/Admin/admin-messages" element={<ProtectedRoute />}>
              <Route path="" element={<MessagesMgmt />} />
            </Route>
          </Route>
          <Route path="/" element={<Login />} />
          {/* <Route path="/terms" element={<Terms />} /> */}
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/sso" element={<Sso />} />
          <Route path="/register" element={<Register />} />
          <Route path="/sso-user" element={<Sso />} />
          <Route path="/sso-error" element={<LoginSsoError />} />
          <Route path="/auth-error" element={<AuthError />} />
          <Route path="/not-found" element={<NotFound />} />

          <Route path="*" element={<NotFound />} />
        </Routes >
      </BrowserRouter >
    </div >
  </>
  )
}

export default App
