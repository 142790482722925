//require('dotenv');

require('dotenv').config();

// console.log("process.env.REACT_APP_HOST_URI ****="+process.env.REACT_APP_HOST_URI)
export const HOST_URI = process.env.REACT_APP_HOST_URI || "http://localhost:3000/api";
export const LOG_LEVEL = process.env.REACT_APP_DEFAULT_LOG_LEVEL || "DEBUG";
export const WARNING_TIMEOUT = process.env.REACT_APP_WARNING_TIMEOUT_MINUTES * 60 * 1000;
export const LOGOUT_TIMEOUT = process.env.REACT_APP_LOGOUT_TIMEOUT_MINUTES * 60 * 1000;
export const MAINTENANCE_MODE = process.env.REACT_APP_Maintenance_Mode
export const MAINTENANCE_MESSAGE = process.env.REACT_APP_Maintenance_Message