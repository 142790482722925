import React, { useRef } from "react";
import { confirmPopup } from "primereact/confirmpopup";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import LabServices from "../../services/LabServices";
import loglevel from "../../logger/Logger";
import { Tooltip } from "primereact/tooltip";
import eventBus from "../utility/EventBus";
import { Toast } from "primereact/toast";

const CancelLab = ({schedule_id, status , permission, fetchData}) => {
  loglevel.debug("Entering into CancelLab Component");
  const navigate = useNavigate();
  const toast = useRef(null);

  const confirm1 = (event) => {
    confirmPopup({
        target: event.currentTarget,
        message: 'Do you want to cancel the scheduled lab?',
        icon: 'pi pi-exclamation-triangle',
        className: "popup",
        accept 
      });
  };

   // method call for updating notification
  const updateHeaderNotification = () => {
    eventBus.dispatch("notificationCount", { message: "notificationCount" });
  };

  //method to update the list
  const updateList = () => fetchData()

  const accept = async () => {
    loglevel.debug("Entering into CancelLab Component: accept method");
    // api call for cancel lab
    await LabServices.cancelSchedule(schedule_id)
    .then((response) => {
      console.log("cancelSchedule Response", response)
      if (response.status === 200) {
        localStorage.setItem("Status","true") 

          //updating active lab list
          try {
            updateList()
          } catch (error) {
            navigate(0)
          }

        //updating notifications
        updateHeaderNotification();
      }
    })
    .catch((error) => {
      loglevel.error("cancelSchedule", error);
      if (error.response.status === 400) {
        toast.current.show({
          severity: "error",
          detail: `${error.response.data.msg}`,
          life: 6000,
        });
      }
    });
  };

  // Cancel button is disabled for status Failed, Deleted and Cancelled
  if (status === "Failed" || status === "Deleted" || status === "Cancelled") {
      return (
        <div className="cancel-button">
          <Button
            className="p-button-primary disabled"
            label="Cancel"
            disabled
          />
        </div>
      );
    }
    else if(status === "Deploying" || status === "Deleting"){
      return (
        <div className="card cancelLabButtonTarget" data-pr-tooltip="Lab can't be cancelled during deletion or deployment. It will be enabled once the lab is Active">
      <Tooltip target=".cancelLabButtonTarget"  position="top"  />
        <div className="cancel-button">
          <Button
            className="p-button-primary disabled"
            label="Cancel"
            disabled
          />
        </div>
        </div>
      );
    }
    else if (permission === "Lab User"){ // cancel button disabled for Lab User
      return (
        <div className="cancel-button">
          <Button
            className="p-button-primary disabled"
            label="Cancel"
            disabled
          />
        </div>
      );
    }
    else {
      return(
          <div className="cancel-button" >
            <Toast ref={toast} position="top-center"/>
              <Button label="Cancel" className="p-button-primary" name="cancel-button" onClick={confirm1} />
          </div>
      )
  }
}
export default CancelLab;