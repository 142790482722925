import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import "../../labs/Container.css";
import loglevel from "../../../logger/Logger";
import GuideService from "../../../services/GuideService";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import LabUserGuideDelete from "./LabUserGuideDelete";
import LabUserGuideCreate from "./LabUserGuideCreate";
import { useGlobalState } from "../../../config/state";
import LabServices from "../../../services/LabServices";
import { Tag } from "primereact/tag";
import LabUserGuideUpdate from "./LabUserGuideUpdate";


function LabUserGuide() {
  loglevel.debug("Entering into Guide Component");
  const [labUserGuides, setLabUserGuides] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [isAdmin, setIsAdmin] = useGlobalState("isAdmin");
  const toast = useRef(null);
  const dt = useRef(null);
  const [labList, setLabList] = useState([]);

  const MAX_GROUPS = 2;

  useEffect(() => {
    // method call for fetching user guide list
    fetchData();
    // method call for fetching lab list
    fetchLabList();
  }, []);

  const fetchData = async () => {
    // api call for user guide list
    await GuideService.getLabUserGuideList().then((res) => {

      for (let i = 0; i < res.data.length; i++) {
        res.data[i]["closedStatus"] = true;
        res.data[i]["closedGroupsStatus"] = true;

      };
      setLabUserGuides(res.data)
    })
      .catch((error) => {
        loglevel.error("getLabTypeList", error);
      });

  }

  // api call for lab list
  const fetchLabList = async () => {
    await LabServices.getLabList().then((res) => {
      console.log("Lab list", res.data);      
      setLabList(res.data)
    })
      .catch((error) => {
        loglevel.error("getLabList", error);
      });

  }

  // index column in user guide listing
  const onIndexTemplate = (data, props) => {
    return (
      <div className="cat-name-table">
        {props.rowIndex + 1}
      </div>)
  }

  //lab name column in user guide table
  const labnameTemplate = (rowData) => {
    return (
      <div className="cat-name-table">
        {rowData.lab_name}
      </div>)
  }

  const userGuideLabelTemplate = (rowData, props) => {

    let groupsArray = rowData.guideLabel;
    let allGroupsCount = groupsArray?.length;
    let hiddenGroupscount = (allGroupsCount - MAX_GROUPS);
    let closedGroupsStatusRow = rowData.closedGroupsStatus

    if (closedGroupsStatusRow) {
      groupsArray = rowData.guideLabel.slice(0, MAX_GROUPS)
    }
    else {
      groupsArray = rowData.guideLabel
    }

    const val = groupsArray?.map((item, index) => {
      return  <a href={`${item.user_guide_link}`}
      target="_blank"
      rel="noopener noreferrer" className='link-tag'>
      <Tag key={index}  >
        <div className='link-icon-container guide-links'>
          <div className='link-icon'>
            <div className="link-icon-div">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M10.0843 3.91002C11.2532 2.75346 13.1354 2.75346 14.3043 3.91002L14.2943 3.93002C14.8522 4.48549 15.1657 5.24029 15.1657 6.02752C15.1657 6.81475 14.8522 7.56956 14.2943 8.12502L11.9493 10.45C10.7804 11.6066 8.89825 11.6066 7.72931 10.45C7.49316 10.2145 7.29873 9.94063 7.15431 9.64002L7.79931 9.00002C7.8424 8.95753 7.88926 8.91904 7.93931 8.88502C8.02876 9.20866 8.20111 9.50337 8.43931 9.74002C9.2182 10.5089 10.4704 10.5089 11.2493 9.74002L13.5943 7.41502C13.9628 7.04732 14.17 6.54812 14.17 6.02752C14.17 5.50693 13.9628 5.00772 13.5943 4.64002C12.8154 3.87118 11.5632 3.87118 10.7843 4.64002L9.55431 5.86002C9.21206 5.76701 8.85897 5.71993 8.50431 5.72002H8.25931L10.0843 3.91002ZM7.56931 13.38L8.79931 12.16C9.14084 12.2458 9.49221 12.2862 9.84431 12.28H10.0943L8.26931 14.09C7.10037 15.2466 5.21825 15.2466 4.04931 14.09C3.49023 13.5352 3.17578 12.7802 3.17578 11.9925C3.17578 11.2049 3.49023 10.4498 4.04931 9.89502L6.39931 7.55002C7.56825 6.39346 9.45037 6.39346 10.6193 7.55002C10.8555 7.78552 11.0499 8.05941 11.1943 8.36002L10.5493 9.00002C10.5087 9.04922 10.4635 9.09443 10.4143 9.13502C10.3249 8.81138 10.1525 8.51668 9.91431 8.28002C9.13542 7.51118 7.88321 7.51118 7.10431 8.28002L4.75931 10.605C4.39078 10.9727 4.18367 11.4719 4.18367 11.9925C4.18367 12.5131 4.39078 13.0123 4.75931 13.38C5.53821 14.1489 6.79042 14.1489 7.56931 13.38Z" fill="#1B2A32" />
            </svg>
            </div>
          </div>
          <div className='link-value'>{item.label_name}</div></div></Tag>
    </a>
    }
    );

    return <span>
      <span className="data-tag parent left-align">{val}</span>
      {/* showing more and less option if user guide for particular lab is more */}
      {hiddenGroupscount > 0 ?
        <span onClick={() => toggleGroups(props, rowData)} className="show-more">

          {closedGroupsStatusRow ? `${hiddenGroupscount} more links` : `less links`}</span> : ""}
    </span>
  }

  const toggleGroups = (props, rowData) => {
    rowData.closedGroupsStatus = !rowData.closedGroupsStatus
    setLabUserGuides((prevState) => [...prevState])
    
  }

  const actionBodyTemplate = (rowData) => {
    return (

      <div className="table-action-body">
        <div className="table-update-icon">
          {/* importing Update user Guide component */}
          <LabUserGuideUpdate labList={labList} labUserGuideId={rowData.guide_id} setChanger={setLabUserGuides}/>
        </div>
        <div className="table-delete-icon">
          {/* importing delete user guide component */}
          <LabUserGuideDelete labUserGuideId={rowData.guide_id} labName={rowData.lab_name} setChanger={setLabUserGuides} />
        </div>
      </div>
    );
  };


  return (
    <section>
      <Toast ref={toast} position="top-center" />
        <div>
            <div className="panel-content">
              <div className="datatable-crud-demo w-100">

                <div className="admin-panel">
                  <div className="admin-panel-search-bar">
                    <span className="p-input-icon-left">
                      <i className="pi pi-search" />
                      {/* search bar */}
                      <InputText
                        type="search"
                        onInput={(e) => setGlobalFilter(e.target.value)}
                        placeholder="Search"
                        className="global-search-filter"
                      />
                    </span>
                  </div>
                  {isAdmin ? ( // only admins are allowed create user guide
                    <LabUserGuideCreate labList={labList} setChanger={setLabUserGuides}/>
                  ) : (
                    ""
                  )}
                </div>


                <Toast ref={toast} />
                {isAdmin? // if admin access the portal
                <DataTable
                  responsiveLayout="scroll"
                  value={labUserGuides}
                  ref={dt}
                  className="user-permission-table lab-table-type"
                  columnResizeMode="fit"
                  paginator
                  rows={50}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  globalFilter={globalFilter}
                  autoLayout={true}
                  removableSort
                  pageLinkSize={1}
                >
                  <Column
                    field="Index"
                    header="#"
                    body={onIndexTemplate}
                    style={{ width: "3rem", paddingLeft: "15px",textAlign:"center" }}
                  ></Column>
                  <Column
                    sortable
                    field="lab_name"
                    header="Lab Name"
                    body={labnameTemplate}
                    style={{ width: "15rem" }}>
                  </Column>
                  <Column
                    field="guideLabel"
                    header="User Guides Label"
                    body={userGuideLabelTemplate}
                    style={{ minWidth: "11rem", maxWidth: "14px" }}>
                  </Column>
                  <Column
                    body={actionBodyTemplate}
                    exportable={false}
                    style={{ minWidth: "4rem" }}>
                  </Column>
                </DataTable>:
                // if non admin access portal
                 <DataTable 
                 responsiveLayout="scroll"
                 value={labUserGuides}
                 ref={dt}
                 className="user-permission-table lab-table-type"
                 columnResizeMode="fit"
                 paginator
                 rows={50}
                 rowsPerPageOptions={[5, 10, 25, 50, 100]}
                 globalFilter={globalFilter}
                 autoLayout={true}
                 removableSort
                 pageLinkSize={1}
               >
                 <Column
                   field="Index"
                   header="#"
                   body={onIndexTemplate}
                   style={{ width: "3rem", paddingLeft: "15px",textAlign:"center" }}
                 ></Column>
                 <Column
                   sortable
                   field="lab_name"
                   header="Lab Name"
                   body={labnameTemplate}
                   style={{ width: "20rem" }}>
                 </Column>
                 <Column
                   sortable
                   field="guideLabel"
                   header="User Guides Label"
                   body={userGuideLabelTemplate}
                   style={{ minWidth: "11rem", maxWidth: "14px" }}>
                 </Column>
               </DataTable>

                }
              </div>
            </div>

          <br />

        </div>
      
    </section>
  );
}

export default LabUserGuide;
