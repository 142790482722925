import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import "../../Rbac.css";
import React, { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { confirmPopup } from "primereact/confirmpopup";
import parse from "html-react-parser";
import LabServices from "../../../../services/LabServices";
import loglevel from "../../../../logger/Logger";
import { Message } from "primereact/message";

const UpdateUserTag = ({ userTagName, userTagDescription, setChanger }) => {
  const [updateTagDisplay, setUpdateTagDisplay] = useState(false);
  const updateLabTypeDialogMap = {
    updateTagDisplay: setUpdateTagDisplay,
  };
  const [tagName, setTagName] = useState("");
  const [description, setDescription] = useState("");
  const [isValidated, setIsValidated] = useState(false);
  const [commonErrorMsg, setCommonErrorMsg] = useState("");

  const toast = useRef(null);
  const errorRef = useRef();

  //opening update tag dialog
  const onClick = (name) => {
    setCommonErrorMsg("");
    setTagName(userTagName);
    setDescription(userTagDescription);
    updateLabTypeDialogMap[`${name}`](true);
  };

  //closing update tag dialog
  const onHide = (name) => {
    updateLabTypeDialogMap[`${name}`](false);
    setIsValidated(false);
    setTagName("");
    setDescription("");
    setCommonErrorMsg("");
  };

  const accept = async (requestBody) => {
  // api call to update tag details
    await LabServices.updateTag(requestBody)
      .then((response) => {
        if (response.status === 200) {
          //toast message after successful tag details updation
          toast.current.show({
            severity: "success",
            summary: "Tag Successfully Updated",
            detail: "The tag has been successfully updated.",
            life: 6000,
          });
          updateLabTypeDialogMap["updateTagDisplay"](false);
        } else {
          loglevel.debug("Tag Updation is Failed");
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setCommonErrorMsg(error.response.data.msg);
          errorRef.current.scrollIntoView({ behavior: "smooth" });
        }
      });
    // refreshing tag list
    await LabServices.getUserTagList().then((res) => {
      setChanger(res.data);
    });
  };

  const onUpdate = (e) => {
    setIsValidated(true);
    <Toast ref={toast} position="top-center" />;
    if (tagName && description) {
      const requestBody = {
        name: `${tagName}`,
        description: `${description}`,
      };

      var confirmationMsg = `Want to update the changes?`;
      // confirmation popup 
      confirmPopup({
        target: e.currentTarget,
        message: parse(`${confirmationMsg}`),
        icon: "pi pi-exclamation-triangle",
        className: "popup",
        acceptLabel: "YES",
        rejectLabel: "NO",
        accept: () => accept(requestBody),
      });
    }
  };

  const renderFooter = (name) => {
    return (
      <div>
        {/* Update Button */}
        <Button
          className="calender-button"
          style={{ background: "#49AFD9" }}
          onClick={(e) => onUpdate(e)}
        >
          <span className="name">UPDATE</span>
        </Button>
      </div>
    );
  };

  return (
    <div className="user-tag-edit">
      <Toast ref={toast} position="top-center" />
      {/* Update icon on tag listing page */}
      <Button
        icon="pi pi-pencil"
        className="p-button-rounded p-button-success ml-2"
        onClick={() => onClick("updateTagDisplay")}
      />
      {/* Update Tag Dialog */}
      <Dialog
        header={`Update Tag : ${userTagName}`}
        visible={updateTagDisplay}
        footer={renderFooter("updateTagDisplay")}
        onHide={() => onHide("updateTagDisplay")}
        className="tag-dialog-box"
      >
        <div ref={errorRef} className={commonErrorMsg ? "" : "hidden"}>
          {commonErrorMsg && (
            <Message
              severity="warn"
              text={commonErrorMsg}
              className="common-inline-error common-error-message p-mb-3"
            />
          )}
        </div>
        <div className="tag-name-input-text">
          <div className="tag-name-label">
            Tag Name :<span className="cat-required-field">*</span>
          </div>
          <div>
            {/* Tag name inputbox */}
            <InputText
              value={tagName}
              onChange={(e) => setTagName(e.target.value)}
              placeholder="Enter Tag Name"
              disabled
              className="tag-name-text-box"
            />
          </div>
        </div>
        <div className="tag-desc-input-text">
          <div className="tag-name-label">
            Description :<span className="cat-required-field">*</span>
          </div>
          <div>
            {/* Textarea for description of tag */}
            <InputTextarea
              value={
                description === null
                  ? setDescription(
                      `The ${tagName} tag was created during Lab creation.`
                    )
                  : description
              }
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter Tag Description"
              maxLength={1000}
              autoResize={true}
              rows={2}
              cols={40}
              className={
                !description && isValidated
                  ? "tag-desc-input-error"
                  : "tag-desc-text-box"
              }
            />
            {/* //inline error message  for description*/}
            {!description && isValidated ? (
              <div className="cat-error-message-inline">
                <small className="cat-error-message" id="error-message">
                  Description is mandatory
                </small>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default UpdateUserTag;
