import React, { useState, useRef, useEffect } from "react";
import { confirmPopup } from "primereact/confirmpopup";
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import "./ScheduleLab.css";
import { Dropdown } from "primereact/dropdown";
import timezones from "./timezones";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import LabServices from "../../services/LabServices";
import moment from "moment";
import parse from "html-react-parser";
import loglevel from "../../logger/Logger";
import { DateTime } from "luxon";
import { Message } from "primereact/message";
import eventBus from "../utility/EventBus";
import { Constants } from "../../constants/constants";
import { Tooltip } from "primereact/tooltip";
import NetBoxService from "../../services/NetBoxService";

const ScheduleLab = ({
  lab_id,
  lab_name,
  lab_time_difference,
  max_schedule_period,
  readOnlyLab,
  lab_access_requested,
  isStaticLab,
  isLabAccessRequestPending
}) => {

  const [mandtooltip, setMandtooltip] = useState("Please complete all mandatory fields (*)");
  const [deal, setDeal] = useState("");
  const [sfid, setSfid] = useState("None");
  const dealRef = useRef(0);
  const sfidRef = useRef("None");
  const [checkedDeal, setCheckedDeal] = useState(false);
  const [checkedGoogle, setCheckedGoogle] = useState(false);
  const [checkedSfid, setCheckedSfid] = useState(false);
  const [sales, setSales] = useState(false);
  const [displayBasic, setDisplayBasic] = useState(false);
  const [displayReqLabAccess, setDisplayReqLabAccess] = useState(false);
  const [notAvailableDates, setNotAvailableDates] = useState([new Date()]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState(
    new Date(
      new Date(new Date().setMinutes(0)).setHours(
        0
      )
    )
  );
  const [isDisableButton, setIsDisableButton] = useState(true)
  const [serverErrorMsg, setServerErrorMsg] = useState("")
  const [isServerValidated, setIsServerValidated] = useState(false)
  const [endTime, setEndTime] = useState(
    new Date(
      new Date(new Date().setMinutes(0)).setHours(
        lab_time_difference
      )
    )
  );
  const [isSelectedStartDate, setIsSelectedStartDate] = useState(false);
  const [isSelectedEndDate, setIsSelectedEndDate] = useState(false);
  const [timezone, setTimeZone] = useState({});
  const [cname, setCname] = useState("");
  const [description, setDescription] = useState("");
  const [purpose, setPurpose] = useState("");
  const [isValidated, setIsValidated] = useState(false);
  const [purposeList, setPurposeList] = useState([]);
  const [commonErrorMsg, setCommonErrorMsg] = useState("")

  var timeValidationMessage = "";
  var dateValidationMessage = "";
  const toast = useRef(null);
  const errorRef = useRef(null);
  const [accessPurpose, setAccessPurpose] = useState("");
  const [comment, setComment] = useState("");
  let userTimezone = {}

    async function sendNetboxRequest(row) {
    let fname = localStorage.getItem("fname")
    let lname = localStorage.getItem("lname")
    let email = localStorage.getItem("email")
    let users_id = localStorage.getItem("users_id")
    let username = fname + ' ' + lname
    let dateRange = []
    dateRange.push(new Date().toLocaleDateString("en-CA"));
    dateRange.push(new Date().toLocaleDateString("en-CA"));
    let reqBody = {
      username: username,
      email: email,
      device_id:row.id,
      start_end_dates: row.start_end_dates,
      labid: row.labid,
      labname: row.labname,
      schedule_id: row.schedule_id
    }
    console.log("Sending Netbox device reservation API request for deviceid=",row.id,"dateRange=",dateRange)
      await NetBoxService.reserveNetboxDevice(reqBody).then( async (res)=> {
        console.log("RESP =",res)
        if(res.status === 200) {
          const reserve_details = `Device: ${row.name} (ID:${row.id}) Owner: ${username} FROM:${row.start_end_dates[0]} TO:${row.start_end_dates[1]}`
          toast.current.show({
          severity: "success",
          summary: "Reservation Success",
          detail: `${reserve_details}`,
          life: 20000,
        });
        }
      })
      .catch( (error)=> {
        loglevel.error("reserveNetboxDevice", error);
        toast.current.show({
          severity: "fail",
          summary: "Reservation Failed",
          detail: row.id,
          life: 9999,
        });
      })

     // Reload the page
     // setVisible(false)
     // setLoading(false);
     // await getNetBoxDeviceList();
    }

  useEffect(() => {
    async function fetchData() {
      //fetching purpose list
      await LabServices.getPurposeList()
        .then((response) => {
          loglevel.debug("getPurposeList Response", response);
          if (response.status === 200) {
            const purposeListData = response.data.values.map((value) => {
              return value;
            });
            setPurposeList(purposeListData);
          } else {
            loglevel.debug("getPurposeList is Failed");
          }
        })
        .catch((error) => {
          loglevel.error("getPurposeList", error);
        });
    }
    fetchData();
  }, []);

  //on update strickeout dates
  useEffect(() => { }, [notAvailableDates]);


  const updateHeaderNotification = () => {
    eventBus.dispatch("notificationCount", { message: "notificationCount" });
  };

  console.log("lab_name = ",lab_name)
  console.log("isLabAccessRequestPending = ",isLabAccessRequestPending)

  const accept = async (requestBody) => {
    loglevel.debug("Entering into ScheduleLab Component: accept method");
    if(deal == 0) {
      requestBody['deal_value'] = '0'
    } else {
      requestBody['deal_value'] = deal
    }
    requestBody['sfid'] = sfid

    await LabServices.scheduleLab(requestBody)
      .then(async (response) => {
        loglevel.debug("scheduleLab Response", response);
        if (response.status === 201) {
          toast.current.show({
            severity: "success",
            detail: "Lab is being scheduled. Check under My Labs for status",
            life: 6000,
          });

          // refresh notification tab after scheduling Lab
          updateHeaderNotification();

          apiCall(timezone);
          dialogFuncMap["displayBasic"](false);

          // netbox - Reserve the physical server at Netbox, only if lab uses physical servers
          if(response.data.labEntity.physical_resources > 0) {
            loglevel.debug("Asset ID", response.data.scheduleServerMapping[0].assetInventory.asset_inventory_id);
            loglevel.debug("Device ID", response.data.scheduleServerMapping[0].assetInventory.id);
            loglevel.debug("Device Name", response.data.scheduleServerMapping[0].assetInventory.name);
            loglevel.debug("Lab ID", response.data.labEntity.labId);
            loglevel.debug("Lab Name", response.data.labEntity.name);
            loglevel.debug("Schedule ID", response.data.scheduleId);
            let dateRange = []
            dateRange.push(startDate.toLocaleDateString("en-CA"));
            dateRange.push(endDate.toLocaleDateString("en-CA"));
            const row = {
              id: `${response.data.scheduleServerMapping[0].assetInventory.id}`,
              name: `${response.data.scheduleServerMapping[0].assetInventory.name}`,
              labid: `${response.data.labEntity.labId}`,
              labname: `${response.data.labEntity.name}`,
              start_end_dates: dateRange,
              schedule_id: response.data.scheduleId
            }
            sendNetboxRequest(row)
          }
          let new_task;
          let lab_name = response.data.labEntity.name;
          let user_email = response.data.UserEntity.email;
          let currTime = new Date().toUTCString().split(" ").join("-").replace(",","")
          let task_title = lab_name + '_' + user_email + '_' + currTime;
          await LabServices.sendClickUpAPI(deal, sfid, task_title)
          .then(async (res) => {
              console.log("RESPONSE =",res)
              if(res.status === 200) {
                  console.log("RESPONSE PASS")
                  new_task = res.data
                  console.log("New ClickUp Task =", new_task);
                  // toast.current.show({
                  //     severity: "success",
                  //     detail: "Salesforce details updated",
                  //     life: 3000,
                  // });
                  // localStorage.removeItem('deal')
                  // localStorage.removeItem('sfid')
              } else {
                  throw err("RESPONSE FAIL")
              }
          }).catch((err) => console.log("ERROR =",err))

        } else {
          loglevel.debug("ScheduleLab is Failed");
        }
      })
      .catch((error) => {
        loglevel.error("scheduleLab", error);
        if (error.response.status === 400) {
          setCommonErrorMsg(error.response.data.msg);
          errorRef.current.scrollIntoView({ behavior: "smooth" });
          apiCall(timezone);
        }
      });

    loglevel.debug("requestBody", requestBody);
  };

  const dialogFuncMap = {
    displayBasic: setDisplayBasic,
    displayReqLabAccess: setDisplayReqLabAccess,
  };

  // method call for update notification after scheduling lab
  const updateLabsOnReload = () => {
    eventBus.dispatch("pageReloaded", { message: "page Reloaded" });
  };

  const apiCall = async (timezone) => {
    loglevel.debug("Entering into ScheduleLab Component: apiCall method");

    // list of strike out date
    await LabServices.getlabNotAvailableData(lab_id, timezone.code)
      .then((response) => {
        loglevel.debug("getlabNotAvailableData", response);
        if (response.status === 200) {
          const notAvailableDatesData = response.data.map((dates) => {
            return new Date(dates);
          });
          setNotAvailableDates(notAvailableDatesData);
          loglevel.debug("timezone", timezone);
        } else {
          loglevel.debug("Fetching not Available dates Failed");
        }
      })
      .catch((error) => {
        loglevel.error("getlabNotAvailableData", error);
      });
  };

  // method call on changing timezone from dropdown
  const onTimezoneChange = async (e) => {
    setIsDisableButton(true)
    setMandtooltip("Please complete all mandatory fields (*)")
    setIsServerValidated(false)
    setTimeZone(e.value);
    apiCall(e.value);
  };

  // method to check server availability
  const onCheckServer = async () => {
    setIsServerValidated(true)
    if (startDate && endDate && validateDate() && validateTime()) {
      const reqBody = {
        labId: lab_id,
        scheduledStart: `${moment(startDate).format("MM-DD-YYYY")} ${moment(startTime).format("HH:mm")}`,
        scheduledEnd: `${moment(endDate).format("MM-DD-YYYY")} ${moment(endTime).format("HH:mm")}`,
        timeZone: timezone.code
      }
      // check server availability
      await LabServices.checkScheduleLabServer(reqBody)
        .then((response) => {
          if (response.status === 201) {
            setIsDisableButton(false);
            setMandtooltip("Click here to Schedule the Lab")
            setServerErrorMsg("")

            console.log("response.status =",response.status)
            toast.current.show({
              severity: "success",
              summary: "Server Availability checked successfully.",
              detail: "Servers available on network",
              life: 6000,
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            let msg = error.response.data.msg.map((item) => [
              <div>
                <li>{item}</li>
              </div>,
            ]);
            setIsDisableButton(true);
            setMandtooltip("Please complete all mandatory fields (*)")
            setServerErrorMsg(msg)
          }
        });
    }
  };

  // method for validating time
  function validateTime() {
    const sdate = moment(startDate).format("MM-DD-YYYY");
    const edate = moment(endDate).format("MM-DD-YYYY");

    let d1 = moment(startDate).format("MM/DD/YYYY");
    let d2 = moment(endDate).format("MM/DD/YYYY");
    let s1 = moment(startTime).format("HH:mm");
    let s2 = moment(endTime).format("HH:mm");
    const sdateTime = moment(d1 + " " + s1).format("MM/DD/YYYY HH:mm");
    const edateTime = moment(d2 + " " + s2).format("MM/DD/YYYY HH:mm");
    const diff = moment(edateTime).diff(moment(sdateTime), "hours");

    // start and end date is same
    if (sdate === edate) {
      const stime = moment(startTime).format("HH:mm");
      const etime = moment(endTime).format("HH:mm");

      // if start time is greater than end time
      if (moment(etime, "HH:mm").diff(moment(stime, "HH:mm"), "hours") < 0) {
        timeValidationMessage =
          "Invalid Time Range : End Time must be greater than Start Time";
        return false;
      } else if ( //if time difference is 0
        moment(etime, "HH:mm").diff(moment(stime, "HH:mm"), "hours") === 0
      ) {
        timeValidationMessage =
          "Invalid Time Range : End Time must be greater than Start Time";
        return false;
      } else if ( // if difference btw start and end time is less than minimum lab utilization time
        moment(etime, "HH:mm").diff(moment(stime, "HH:mm"), "hours") < lab_time_difference
      ) {
        timeValidationMessage =
          `Invalid Time Range : Minimum Lab Utilization Expected Time is ${lab_time_difference} hours`;
        return false;
      } else {
        return true;
      }
    } else {
      for (const i of notAvailableDates) {
        if (
          i.getTime() >= startDate.getTime() && // if strikeout dates not between start and end date
          i.getTime() <= endDate.getTime()
        ) { // if strikeout are in between start and end date
          dateValidationMessage =
            "Invalid Date Range : Start and End dates must not contain unavailable dates";
          return false;
        } else {
          continue;
        }
      }
      if (diff < lab_time_difference) {

        if (parseInt(lab_time_difference / 24) === 0) {
          if (lab_time_difference % 24 === 1) {
            timeValidationMessage =
              `Invalid Time Range : Minimum Lab Utilization Expected Time is ${lab_time_difference} hour`;
            return false;

          } else {
            timeValidationMessage =
              `Invalid Time Range : Minimum Lab Utilization Expected Time is ${lab_time_difference} hours`;
            return false;
          }

        } else if (parseInt(lab_time_difference / 24) === 1) {
          if (lab_time_difference % 24 === 0) {
            timeValidationMessage =
              `Invalid Time Range : Minimum Lab Utilization Expected Time is ${parseInt(lab_time_difference / 24)} day`;
            return false;
          } else if (lab_time_difference % 24 === 1) {
            timeValidationMessage =
              `Invalid Time Range : Minimum Lab Utilization Expected Time is ${parseInt(lab_time_difference / 24)} day ${parseInt(lab_time_difference % 24)} hour`;
            return false;
          } else {
            timeValidationMessage =
              `Invalid Time Range : Minimum Lab Utilization Expected Time is ${parseInt(lab_time_difference / 24)} day ${parseInt(lab_time_difference % 24)} hours`;
            return false;
          }

        } else if (parseInt(lab_time_difference / 24) > 1) {

          if (lab_time_difference % 24 === 0) {
            timeValidationMessage =
              `Invalid Time Range : Minimum Lab Utilization Expected Time is ${parseInt(lab_time_difference / 24)} days`;
            return false;
          } else if (lab_time_difference % 24 === 1) {
            timeValidationMessage =
              `Invalid Time Range : Minimum Lab Utilization Expected Time is ${parseInt(lab_time_difference / 24)} days ${parseInt(lab_time_difference % 24)} hour`;
            return false;
          } else {
            timeValidationMessage =
              `Invalid Time Range : Minimum Lab Utilization Expected Time is ${parseInt(lab_time_difference / 24)} days ${parseInt(lab_time_difference % 24)} hours`;
            return false;
          }
        }
      }
      return true;
    }
  }

  // method call on clicking schedule Lab button to open schedule lab screen
  const onClick = async (name) => {
    setCommonErrorMsg("")

    // validating browsers timezone
    timezones.forEach((item) => {
      if (DateTime.local().offsetNameLong === item.AbbrName) {
        userTimezone = item
        setTimeZone(item);
      }
    });

    // list of strike out date
    await LabServices.getlabNotAvailableData(lab_id, userTimezone.code)
      .then((response) => {
        loglevel.debug("getlabNotAvailableData Response", response);
        if (response.status === 200) {
          const notAvailableDatesData = response.data.map((dates) => {
            return new Date(dates);
          });

          setNotAvailableDates(notAvailableDatesData);
        } else {
          loglevel.debug("Fetching not Available dates Failed");
        }
      })
      .catch((error) => {
        loglevel.error("getlabNotAvailableData", error);
      });
    dialogFuncMap[`${name}`](true);
  };


  // setting values on Schedule Lab dialog open
  const onShowDialog = (name) => {
    localStorage.removeItem('deal')
    localStorage.removeItem('sfid')

    // for schedule Lab dialog
    if (name === "displayBasic") {
      setPurpose("");
      setCname("");
      setDescription("");
      setStartDate("");
      setEndDate("");
      setIsSelectedStartDate(false);
      setIsSelectedEndDate(false);
      setIsServerValidated(false)
      setIsValidated(false);
      setStartTime(new Date(
        new Date(new Date().setMinutes(0)).setHours(
          0
        )
      ));
      setEndTime(
        new Date(
          new Date(new Date().setMinutes(0)).setHours(
            lab_time_difference
          )
        )
      );
    } else if (name === "displayReqLabAccess") { // for requesting Lab Access
      setAccessPurpose('')
      setComment('')
    }


  };

  //resetting all value of schedule Lab Screen
  const onReset = () => {
    setStartDate("");
    setCommonErrorMsg("")
    setIsServerValidated(false)
    setEndDate("");
    setPurpose("");
    setDescription("");
    setCname("");
    setIsSelectedStartDate(false);
    setIsSelectedEndDate(false);
    setIsValidated(false);
    timezones.forEach((item) => {
      if (DateTime.local().offsetNameLong === item.AbbrName) {
        userTimezone = item
        setTimeZone(item);
      }
    })
    setStartTime(new Date(
      new Date(new Date().setMinutes(0)).setHours(
        0
      )
    ));
    setEndTime(
      new Date(
        new Date(new Date().setMinutes(0)).setHours(
          lab_time_difference
        )
      )
    );
  };

  // method call after closing Schedule Lab dialog 
  const onHide = (name) => {
    localStorage.removeItem('deal')
    localStorage.removeItem('sfid')
    setIsDisableButton(true)
    setMandtooltip("Please complete all mandatory fields (*)");
    setStartDate("");
    setEndDate("");
    setCommonErrorMsg("")
    setIsServerValidated(false)
    setPurpose("");
    setDescription("");
    setCname("");
    setIsSelectedStartDate(false);
    setIsSelectedEndDate(false);
    setIsValidated(false);
    setTimeZone({
      name: "(GMT+00:00) Greenwich Mean Time",
      code: "GMT+00:00",
    });
    setStartTime(new Date(
      new Date(new Date().setMinutes(0)).setHours(
        0
      )
    ));
    setEndTime(
      new Date(
        new Date(new Date().setMinutes(0)).setHours(
          lab_time_difference
        )
      )
    );
    dialogFuncMap[`${name}`](false);
  };

  // method call after closing Reuest Lab dialog 
  const onHideReqLabAccess = (name) => {
    dialogFuncMap[`${name}`](false);
  }

  // Validating Dates
  function validateDate() {
    let d1 = moment(startDate).format("MM/DD/YYYY");
    let d2 = moment(endDate).format("MM/DD/YYYY");
    let s1 = moment(startTime).format("HH:mm");
    let s2 = moment(endTime).format("HH:mm");
    const sdate = moment(d1 + " " + s1).format("MM/DD/YYYY HH:mm");
    const edate = moment(d2 + " " + s2).format("MM/DD/YYYY HH:mm");
    const diff = moment(edate).diff(moment(sdate), "hours");

    // if start date greater than end date
    if (moment(edate).diff(moment(sdate)) < 0 && d1 !== d2) {
      dateValidationMessage =
        "Invalid Date Range : End date must be greater than or equal to Start Date";
      return false;
    }

    // if time difference is greater than maximum schedule period
    if (diff > max_schedule_period) {
      dateValidationMessage = `Invalid Date Range : You cannot schedule lab for more than ${max_schedule_period / 24
        } days`;
      return false;
    }

    return true;
  }

  // method call to schedule Lab after filling all details
  const onSchedule = (event) => {
    setIsValidated(true);

    if (startDate === null) setIsSelectedStartDate(false);

    if (endDate === null) setIsSelectedEndDate(false);

    if (
      isSelectedStartDate &&
      isSelectedEndDate &&
      cname &&
      purpose &&
      startDate &&
      endDate &&
      validateDate() &&
      validateTime()

    ) {
      const requestBody = {
        labId: `${lab_id}`,
        userId: localStorage.getItem("users_id"),
        timeZone: timezone.code,
        timeZoneAbbr: timezone.Abbr,
        scheduledStart: `${moment(startDate).format("MM-DD-YYYY")} ${moment(
          startTime
        ).format("HH:mm")}`,
        scheduledEnd: `${moment(endDate).format("MM-DD-YYYY")} ${moment(
          endTime
        ).format("HH:mm")}`,
        client_name: `${cname}`,
        purpose: `${purpose}`,
        description: `${description}`,
          // deal_value: `${deal}`
          // salesforce_id: `${sfid}`
      };

      loglevel.debug(requestBody, "requestBody");

      var confirmationMsg = `You are scheduling to use "${lab_name} Lab" <br />
        FROM ${moment(startDate).format("MMMM-DD-YYYY")} ${moment(
        startTime
      ).format("HH:mm")} (${requestBody.timeZone})  <br/>
        TO  ${moment(endDate).format("MMMM-DD-YYYY")} ${moment(endTime).format(
        "HH:mm"
      )} (${requestBody.timeZone})`;

      confirmPopup({
        target: event.currentTarget,
        message: parse(`${confirmationMsg}`),
        icon: "pi pi-exclamation-triangle",
        className: "popup",
        acceptLabel: "CONFIRM",
        rejectLabel: "CANCEL",
        accept: () => accept(requestBody),
      });
    }
  };

  //sending Lab Access request
  const onSend = async (event) => {
    let userId = parseInt(localStorage.getItem("users_id"));
    if (checkedGoogle) {
    // if (accessPurpose) {
      const requestBody = {
        users_id: userId,
        purpose: "Sales",
        lab_id: lab_id,
        comment: "Refer to Google Form for more details"
      }

      //api call for schedule Lab request access
      await LabServices.sendLabAccessRequest(requestBody)
        .then((response) => {
          if (response.status === 201) {
            toast.current.show({
              severity: "success",
              summary: "Request sent successfully.",
              detail: "Request for Lab access has been successfully sent.",
              life: 6000,
            });
            dialogFuncMap["displayReqLabAccess"](false);
          } else {
            loglevel.debug("Request not sent.");
          }
        })
        .catch((error) => {
          loglevel.debug("Request Lab Access API catch block. ", error.response);
          if (error.response.status === 400) {
            setCommonErrorMsg(error.response.data.msg);
          }
        });
      // updating Labs 
      updateLabsOnReload();
    }
  }

  //method call on changing start date
  const startDateChange = (e) => {
    setIsDisableButton(true)
    setMandtooltip("Please complete all mandatory fields (*)");
    setIsServerValidated(false)

    if (e.value) {
      setStartDate(e.value);
      setEndDate(new Date(e.value.getTime() + lab_time_difference * 60 * 60 * 1000))
    }

    setStartTime(new Date(
      new Date(new Date().setMinutes(0)).setHours(
        0
      )
    ))
    setIsSelectedStartDate(true);
    setIsSelectedEndDate(true);
  };

  // method call on changing end date
  const endDateChange = (e) => {
    setIsDisableButton(true)
    setMandtooltip("Please complete all mandatory fields (*)");
    setIsServerValidated(false)
    setEndDate(e.value);
    setIsSelectedEndDate(true);
  };

  // method call on changing start time
  const startTimeChange = (e) => {
    setIsDisableButton(true)
    setMandtooltip("Please complete all mandatory fields (*)");
    setIsServerValidated(false)
    setStartTime(e.value);
  };

  //method call on changing end time
  const endTimeChange = (e) => {
    setIsDisableButton(true)
    setMandtooltip("Please complete all mandatory fields (*)");
    setIsServerValidated(false)
    setEndTime(e.value);
  };

  //footer of schedule lab dialog and request access dialog
  const renderFooter = (name) => {
    return (
      name === 'displayBasic' ?
        <div className={"flex-container"}>
          <div className="flex-child left-content">
          </div>
          <div className="right-content flex-child">
            <Tooltip target=".myTooltip"/>
            {/*<div className={(isDisableButton && !isStaticLab) ? "myTooltip schedule-lab-disable-button" : "myTooltip schedule-lab-button"}*/}
            {/*                data-pr-tooltip={mandtooltip}>*/}
            <div className={(isDisableButton && !isStaticLab) ? "myTooltip schedule-lab-button" : "myTooltip schedule-lab-button"}
                            data-pr-tooltip={mandtooltip}>
              <Button
                // tooltip={mandtooltip}
                // tooltipOptions={{ showOnDisabled: true }}
                data-testid="bt-schedule-test"
                onClick={(e) => onSchedule(e)}
                // disabled={(isDisableButton && !isStaticLab)}
                disabled={false}
              >
                <span className="name schedule-name">Schedule Lab</span>
              </Button>
            </div>
            <div className="reset-btn-section">
              <Button label="RESET" onClick={onReset} className="p-button-primary" />
            </div>
          </div>
        </div>
        :
        <div className={"footer-buttons"}>
          <div className="reset-btn-section">
            <Button label="CANCEL" onClick={() => onHideReqLabAccess('displayReqLabAccess')} className="p-button-primary" />
          </div>
          <div className={(checkedGoogle) ? "schedule-lab-button" : "submit-disable-button"}>
            {/* lab request access button */}
            <Button
              data-testid="bt-schedule-test"
              onClick={(e) => onSend(e)}
              disabled={!checkedGoogle}
            >Submit</Button>
          </div>
        </div>
    );
  };

  const setDefaultValueDeal = async (e) => {
      setCheckedDeal(e.checked)
      if(e.checked) {
          // Set default value
          dealRef.current.value = 0
          setDeal("0")
          dealRef.current.disabled=true
      } else {
          // Reset InputText field
          dealRef.current.value = ""
          setDeal("")
          dealRef.current.disabled=false
      }
      setDeal("0")
      console.log("Deal Value =",deal)
  }

    const setDefaultValueSfid = async (e) => {
      setCheckedSfid(e.checked)
      if(e.checked) {
          // Set default value
          sfidRef.current.value = "None"
          setSfid("None")
          sfidRef.current.disabled=true
      } else {
          // Reset InputText field
          sfidRef.current.value = ""
          setSfid("")
          sfidRef.current.disabled=false
      }
      console.log("Salesforce ID =",sfid)
  }

  const sendClickupRequest = async (event) => {
      let deal = event.target[0].value
      let sfid = event.target[2].value
      setDeal(deal)
      localStorage.setItem('deal',deal)
      setSfid(sfid)
      localStorage.setItem('sfid',sfid)
      event.preventDefault();
      console.clear()

      // Close the POPUP Dialog
      setSales(false)

      // Send ClickUp API request after Lab Schedule is successful
      // let new_task;
      // await LabServices.sendClickUpAPI(deal, sfid)
      //     .then(async (res) => {
      //         console.log("RESPONSE =",res)
      //         if(res.status === 200) {
      //             console.log("RESPONSE PASS")
      //             new_task = res.data
      //             console.log("New Task =", new_task);
      //             // toast.current.show({
      //             //     severity: "success",
      //             //     detail: "Thanks for details",
      //             //     life: 3000,
      //             // });
      //         } else {
      //             throw err("RESPONSE FAIL")
      //         }
      //     }).catch((err) => console.log("ERROR =",err))
  }

  const checkSalesPurposePOPUP = (purpose) => {
      purpose = purpose.value
      setPurpose(purpose)
      if(purpose == "Sales") {
          setSales(true)
      } else {
          setSales(false)
      }
  }

  const showPrevious = () => {
      // Deal Value
      // read from localStorage
      let deal = localStorage.getItem('deal')
      if (deal) {
          dealRef.current.value=deal
          setDeal(deal)
          if(deal == 0 || deal == '0') {
              dealRef.current.disabled=true
              setCheckedDeal(true)
          } else {
              dealRef.current.disabled=false
              setCheckedDeal(false)
          }
      } else {
          // Reset values
          setDeal("0")
          dealRef.current.value=0
          dealRef.current.disabled=false
          setCheckedDeal(false)
      }

      // Salesforce ID
      // read from localStorage
      let sfid = localStorage.getItem('sfid')
      if(sfid) {
          sfidRef.current.value=sfid
          setSfid(sfid)
          if(sfid == "None") {
              sfidRef.current.disabled=true
              setCheckedSfid(true)
          } else {
              sfidRef.current.disabled=false
              setCheckedSfid(false)
          }
      } else {
          // Reset values
          setSfid("None")
          sfidRef.current.value="None"
          sfidRef.current.disabled=false
          setCheckedSfid(false)
      }
  }

  const checkSalesPurpose = (purpose) => {
      if(purpose == "Sales") {
          return(
              <div className="card flex justify-content-center">
                  <Button onClick={() => window.open(Constants.EXPORT_CLICKUP_URL, "__blank")}>please fill this form</Button>
                  <Checkbox style={{marginLeft: "1.0rem"}} onChange={e => setChecked(e.checkedDeal)} checked={checkedDeal}></Checkbox>
                  <label style={{marginLeft: "0.5rem"}}>I have filled the form</label>
                  <div className="required-field">*</div>
                  {!checkedDeal ? (
                  <div className="error-message-inline">
                    <small className="error-message" id="error-message" style={{marginLeft: "12.0rem"}}>
                      clickUp Form is mandatory
                    </small>
                  </div>
                ) : (
                  ""
                )}
              </div>
          )
      }
  }

  return (
    <div className="schedule-lab-button">
      <Toast ref={toast} position="top-center" />

      {!readOnlyLab ?
        // If user does not have Readonly tag -> Schedule lab btn
        (<Button
          onClick={() => onClick("displayBasic")}
          style={{ background: "#49AFD9" }}
          className="calender-button"
          id="myschedulelab"
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.04004 0C2.2565 0 0 2.27756 0 5.08707C0 7.89658 2.2565 10.1741 5.04004 10.1741C7.82358 10.1741 10.0801 7.89658 10.0801 5.08707C10.0801 3.7379 9.54908 2.44398 8.60388 1.48997C7.65869 0.535958 6.37674 0 5.04004 0ZM3.52928 2.27314C2.51409 2.82836 1.88403 3.90244 1.8899 5.06785C1.89557 6.57415 2.94769 7.86971 4.40992 8.17096L4.32487 8.65741C2.88226 8.37474 1.74977 7.24386 1.45427 5.7909C1.15877 4.33794 1.75829 2.84821 2.97392 2.01478C4.18954 1.18135 5.78255 1.16788 7.01184 1.98063L6.74409 2.41303C5.78001 1.77169 4.54447 1.71792 3.52928 2.27314ZM5.32979 2.78166V5.21392L6.90795 6.2981C7.00842 6.35805 7.06781 6.46916 7.06229 6.58683C7.05678 6.70449 6.98725 6.80944 6.88162 6.85954C6.77599 6.90965 6.65157 6.89669 6.5583 6.82588L4.69978 5.55412V2.78166C4.69978 2.60607 4.84081 2.46372 5.01478 2.46372C5.18876 2.46372 5.32979 2.60607 5.32979 2.78166ZM0.629986 5.0867C0.629986 7.54503 2.60443 9.53789 5.04002 9.53789C6.20963 9.53789 7.33134 9.06893 8.15839 8.23417C8.98543 7.39941 9.45006 6.26723 9.45006 5.0867C9.45006 2.62838 7.47562 0.635517 5.04002 0.635517C2.60443 0.635517 0.629986 2.62838 0.629986 5.0867Z"
              fill="black"
            />
          </svg>
          <span className="name">Schedule Lab</span>
        </Button>) :

        (lab_access_requested && isLabAccessRequestPending ?
          // If user has read only tag and access has been requested -> Request sent msg to be shown
          (<div 
              className="request-sent-info" 
              id="myschedulelab"
              style={{padding: '5px'}}
            >
            <svg width="15" height="18" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3333 6.14664V8.99997H15.3333V20.3333C15.3333 21.0697 14.7364 21.6666 14 21.6666H1.99999C1.26361 21.6666 0.666656 21.0697 0.666656 20.3333V8.99997H2.66666V6.14664C2.60705 3.13744 4.99108 0.646122 7.99999 0.573303C11.0089 0.646122 13.3929 3.13744 13.3333 6.14664ZM7.99999 1.90664C5.7274 1.9792 3.94015 3.87368 3.99999 6.14664V8.99997H12V6.14664C12.0598 3.87368 10.2726 1.9792 7.99999 1.90664ZM1.99999 20.3333V10.3333H14V20.3333H1.99999ZM6.50969 13.959C6.68177 13.2407 7.3214 12.7322 8.05999 12.7266C8.82714 12.7279 9.48639 13.2713 9.63403 14.0241C9.78167 14.7769 9.37651 15.5291 8.66666 15.82V17.6666H7.33332V15.76C6.67744 15.4203 6.33762 14.6772 6.50969 13.959Z" fill="#FDD006" />
            </svg>

            <div className="request-sent-text">Lab access request already sent.</div>
          </div>)
          :
          // If user has read only tag and access has NOT been requested -> Req Access button
          (<Button
            onClick={() => onClick("displayReqLabAccess")}
            style={{ background: "#49AFD9", marginLeft: '5px' }}
            className="calender-button"
            id="myschedulelab"
          >
            <span className="name">Request Lab Access</span>
          </Button>))
      }

      {/* Dialog box for scheduling lab */}
      <Dialog
        style={{overflowWrap:"anywhere"}}
        header={`Schedule : ${lab_name}`}
        visible={displayBasic}
        className="dialog-box"
        footer={renderFooter("displayBasic")}
        onHide={() => onHide("displayBasic")}
        onShow={() => onShowDialog('displayBasic')}
      >
        {/* api error responce shown at top of dialog box */}
        <div ref={errorRef} className={commonErrorMsg ? "" : "hidden"}>
          {commonErrorMsg && <Message severity="warn" text={commonErrorMsg} className='common-inline-error common-error-message p-mb-3' />}
        </div>
        <div className="client-info">
          <div className="client-purpose">
            <div className="client-name">
              <div className="client-info-label">
                <div>Customer/Client Name:</div>
                <div className="required-field">*</div>
              </div>
              <div className="input-text-box">
                <InputText
                  value={cname}
                  onChange={(e) => setCname(e.target.value)}
                  placeholder="Customer Name or 'VMWare' for internal"
                  className={
                    !cname && isValidated ? "input-error" : "input-client"
                  }
                  maxLength={20}
                />
                {!cname && isValidated ? (
                  <div className="error-message-inline">
                    <small className="error-message" id="error-message">
                      Client Name is required
                    </small>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="purpose">
              <div className="purpose-label">
                <div>Purpose:</div>
                <div className="required-field">*</div>
              </div>
              <div className="purpose-dropdown">
                <Dropdown
                  value={purpose}
                  options={purposeList}
                  // onChange={(e) => setPurpose(e.value)}
                  onChange={checkSalesPurposePOPUP}
                  placeholder="Select Purpose"
                  className={!purpose && isValidated ? "input-error" : ""}
                />
                {!purpose && isValidated ? (
                  <div className="error-message-inline">
                    <small className="error-message">Purpose is required</small>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
            {/*{checkSalesPurpose(purpose)}*/}
            <Dialog
                style={{width: "40vw"}}
                header="Sales Data"
                visible={sales}
                // className="dialog-box"
                // footer={renderFooter("displayBasic")}
                onHide={() => setSales(false)}
                onShow={() => showPrevious()}
            >
                <div className="sales">
                    <form onSubmit={sendClickupRequest}>
                        <div>
                            <label>Deal Size $</label>
                            <InputText style={{marginLeft: "1.6rem"}} ref={dealRef} type="text" placeholder={"Deal Value in Dollars"} required ></InputText>
                            <Checkbox style={{marginLeft: "1.0rem"}} onChange={(e) => setDefaultValueDeal(e)} checked={checkedDeal}></Checkbox>
                            <label style={{marginLeft: "0.5rem"}}>Data Not Available</label>
                        </div>
                        <div>
                            <label>Salesforce ID</label>
                            <InputText style={{marginLeft: "0.9rem"}} ref={sfidRef} type="text" placeholder={"Salesforce ID"} required></InputText>
                            <Checkbox style={{marginLeft: "1.0rem", marginTop: "2.0rem"}} onChange={(e) => setDefaultValueSfid(e)} checked={checkedSfid}></Checkbox>
                            <label style={{marginLeft: "0.5rem"}}>Data Not Available</label>
                        </div>
                    {/*{checkedDeal ? (*/}
                    {/*    // <div className="error-message-inline">*/}
                    {/*    // <small className="error-message" id="error-message" style={{marginLeft: "12.0rem"}}>*/}
                    {/*    // clickUp Form is mandatory*/}
                    {/*    // </small>*/}
                    {/*    // </div>*/}
                    {/*) : ("")}*/}
                        <div>
                            <br/>
                            <Button style={{marginLeft: "12.0rem"}}>Submit</Button>
                        </div>
                    </form>
                    {/*<Button onClick={() => window.open(Constants.EXPORT_CLICKUP_URL, "__blank")}>please fill this form</Button>*/}
                </div>
            </Dialog>

          <div className="desription">
            <span>Description:</span>
            <div className="desription-input-textarea">
              {" "}
              <InputTextarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={2}
                cols={40}
                placeholder="Enter Description"
                maxLength={250}
              />
            </div>
          </div>
        </div>



        <div className="calender-schedule">
          <div className="calender-container">
            <div className="timezone">
              <div className="timezone-label">Select Timezone:</div>

              <Dropdown
                value={timezone}
                options={timezones}
                onChange={onTimezoneChange}
                optionLabel="name"
                className="timezone-dropdown"
                filter
                filterBy="name"
              />
            </div>
            <div className="timezone-calendar">
              <div className="start-end-date">
                <div className="startDate">
                  <div className="calender-popup-label">
                    <div className="label">Select Start Date:</div>
                    <div className="required-field">*</div>
                  </div>
                  <div className="calender-popup">
                    <Calendar
                      value={startDate}
                      onChange={(e) => startDateChange(e)}
                      disabledDates={notAvailableDates}
                      dateFormat="mm/dd/yy"
                      showIcon
                      mask="99/99/9999"
                      selectOtherMonths={true}
                      className={
                        !startDate && (isValidated || isServerValidated) && !isSelectedStartDate
                          ? "input-error"
                          : ""
                      }
                      minDate={
                        new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
                      }
                      placeholder="Select Start Date"
                    />
                    {(!startDate || startDate === null) &&
                      (isValidated || isServerValidated) &&
                      !isSelectedStartDate ? (
                      <div className="error-message-inline">
                        <small className="error-message" id="error-message">
                          Start Date is required
                        </small>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="endDate">
                  <div className="calender-popup-label">
                    <div className="start-end-date">Select End Date:</div>
                    <div className="required-field">*</div>
                  </div>
                  <div className="calender-popup">
                    <Calendar
                      value={endDate}
                      onChange={(e) => endDateChange(e)}
                      disabledDates={notAvailableDates}
                      dateFormat="mm/dd/yy"
                      showIcon
                      mask="99/99/9999"
                      selectOtherMonths={true}
                      className={
                        !endDate && (isValidated || isServerValidated) && !isSelectedEndDate
                          ? "input-error"
                          : ""
                      }
                      minDate={
                        new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
                      }
                      placeholder="Select End Date"
                    />
                    {(!endDate || endDate === null) &&
                      (isValidated || isServerValidated) &&
                      !isSelectedEndDate ? (
                      <div className="error-message-inline">
                        <small className="error-message" id="error-message">
                          End Date is required
                        </small>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              {startDate &&
                endDate &&
                isSelectedStartDate &&
                isSelectedEndDate &&
                !validateDate() ? (
                <div >
                  <small className="error-message" id="error-message">
                    {dateValidationMessage}
                  </small>
                </div>
              ) : startDate &&
                endDate &&
                isSelectedStartDate &&
                isSelectedEndDate &&
                validateDate() &&
                !validateTime() ? (
                <div>
                  <small className="error-message" id="error-message">
                    {dateValidationMessage}
                  </small>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="timeslots">Select Timeslots</div>

            <div className="start-end-time">
              <div className="time-container">
                <div className="start-time">
                  <span> Start Time</span>
                  <div>
                    <Calendar
                      value={startTime}
                      onChange={(e) => startTimeChange(e)}
                      readOnlyInput
                      stepMinute={60}
                      timeOnly
                      inline
                    />
                  </div>
                </div>
                <div className="end-time">
                  <span>End Time</span>
                  <div>
                    <Calendar
                      value={endTime}
                      onChange={(e) => endTimeChange(e)}
                      timeOnly
                      stepMinute={60}
                      readOnlyInput
                      inline
                    />
                  </div>
                </div>
              </div>
              {
                startDate &&
                  endDate &&
                  isSelectedStartDate &&
                  isSelectedEndDate &&
                  validateDate() &&
                  !validateTime() ? (
                  <span className="time-error-message">
                    <small className="error-message" id="error-message">
                      {timeValidationMessage}
                    </small>
                  </span>
                ) : (
                  ""
                )}
            </div>
            {/*{isStaticLab ? "" : <div className="check-server">*/}
            {/*  <div>Check Resource Availability:<span className="required-field">*</span></div>*/}
            {/*  <div className="check-serer-section"><Button*/}
            {/*    className="schedule-lab-button"*/}
            {/*    style={{ background: "#49AFD9", height: "33px" }}*/}
            {/*    onClick={() => onCheckServer()}*/}
            {/*  >*/}
            {/*    <span className="refresh-icon">*/}
            {/*      <svg*/}
            {/*        width="10.72"*/}
            {/*        height="11.3"*/}
            {/*        viewBox="0 0 12 12"*/}
            {/*        fill="none"*/}
            {/*        xmlns="http://www.w3.org/2000/svg"*/}
            {/*      >*/}
            {/*        <path*/}
            {/*          d="M7.46668 3.88328C7.46668 4.08394 7.62935 4.24661 7.83001 4.24661H11.4767V0.603281C11.4767 0.401697 11.3133 0.238281 11.1117 0.238281C10.9101 0.238281 10.7467 0.401697 10.7467 0.603281V2.98328C9.34083 0.924576 6.72085 0.0768386 4.37548 0.921773C2.03011 1.76671 0.552718 4.09055 0.782792 6.57283C1.01286 9.05512 2.8922 11.0678 5.35291 11.4673C7.81362 11.8668 10.2332 10.552 11.2367 8.26995C11.3118 8.08291 11.2249 7.87003 11.0403 7.78901C10.8558 7.70798 10.6402 7.78807 10.5533 7.96995C9.68215 9.95407 7.57334 11.0917 5.43676 10.7301C3.30018 10.3685 1.68314 8.60031 1.51336 6.44001C1.34357 4.2797 2.66453 2.28064 4.71839 1.58971C6.77225 0.898769 9.03288 1.69295 10.2033 3.51661H7.83334C7.73583 3.51572 7.64204 3.55406 7.57308 3.62302C7.50412 3.69198 7.46578 3.78576 7.46668 3.88328Z"*/}
            {/*          fill="black"*/}
            {/*        />*/}
            {/*      </svg>{" "}*/}
            {/*      Check Check*/}
            {/*    </span>*/}
            {/*  </Button></div>*/}
            {/*</div>}*/}
            {isServerValidated && serverErrorMsg ? <div>{serverErrorMsg}</div> : ""}
          </div>
        </div>
      </Dialog>

      {/* Dialog box for request lab access */}
      <Dialog
          style={{width: '25vw'}}
        header="Request Lab Access"
        visible={displayReqLabAccess}
        className="req-access-dialog-box"
        footer={renderFooter("displayReqLabAccess")}
        onHide={() => onHideReqLabAccess("displayReqLabAccess")}
        onShow={() => onShowDialog('displayReqLabAccess')}
      >
          <div>We require some information in order to provide you access to this lab '{lab_name}'.</div>
          <br></br>
          <div>Please complete the Google form by clicking the link below</div>
          <br></br>
          {/*<Button onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSe1O5WGS11rH2DgAMFh1j5tbmzb85Vla1OG5ZVv6lmmqzazKQ/viewform')}>*/}
          <Button onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSfx0j1oWCCLfV29peAM4_aLZkMd9lHjSmKH3sJSP5K-shP1Sg/viewform')}>
              Google Form
          </Button>
          <div>
              <br></br>
          <Checkbox required style={{marginLeft: "1.0rem"}} onChange={e => setCheckedGoogle(e.checked)} checked={checkedGoogle}></Checkbox>
          <label style={{marginLeft: "0.5rem"}}>I have submitted the required information</label>
              <div className="required-field"></div>
                  {!checkedGoogle ? (
                  <div className="error-message-inline">
                    <small className="error-message" id="error-message" style={{marginLeft: "1.0rem"}}>
                      Please provide required information using the Google form
                    </small>
                  </div>
                ) : (
                  ""
                )}

          </div>
        {/* ------------------------------------ */}
        {/*<div className="req-access-purpose">*/}
        {/*  <div className="req-access-purpose-label">*/}
        {/*    Purpose*/}
        {/*    <span className="asset-required-field">*</span> :*/}
        {/*  </div>*/}

        {/*  <div className="req-access-purpose-dropdown">*/}
        {/*    <Dropdown*/}
        {/*      value={accessPurpose}*/}
        {/*      options={purposeList}*/}
        {/*      onChange={(e) => setAccessPurpose(e.value)}*/}
        {/*      placeholder="Select Purpose"*/}
        {/*      className={!purpose && isValidated ? "input-error" : ""}*/}
        {/*    />*/}
        {/*    {!purpose && isValidated ? (*/}
        {/*      <div className="error-message-inline">*/}
        {/*        <small className="error-message">Purpose is required</small>*/}
        {/*      </div>*/}
        {/*    ) : (*/}
        {/*      ""*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className="req-access-comment">*/}
        {/*  <div className="req-access-comment-label">Comments <span className="optional-text ml-2">(Optional)</span> :</div>*/}
        {/*  <div className="comment-input-textarea">*/}
        {/*    <InputTextarea*/}
        {/*      value={comment}*/}
        {/*      onChange={(e) => setComment(e.target.value)}*/}
        {/*      rows={2}*/}
        {/*      cols={40}*/}
        {/*      placeholder="Add Comment"*/}
        {/*      maxLength={250}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
      </Dialog>
    </div>
  );
};
export default ScheduleLab;
