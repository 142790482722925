import React, { useState, useRef } from "react";
import { Toast } from "primereact/toast";
import "../labs/Container.css";
import loglevel from "../../logger/Logger";
import AdminGuides from "./admin-guides/AdminGuides";
import PortalUsageGuides from "./portal-usage-guides/PortalUsageGuides";
import LabUserGuides from "./lab-user-guides/LabUserGuides";
import { useGlobalState } from "../../config/state";


function Guides() {
  loglevel.debug("Entering into Guide Component");
  const [activeIndex, setActiveIndex] = useState(0);
  const [isAdmin, setIsAdmin] = useGlobalState("isAdmin");
  const toast = useRef(null);

  const setIndex0 = (e) => {
    setActiveIndex(0);
  };

  const setIndex1 = (e) => {
    setActiveIndex(1);
  };

  const setIndex2 = (e) => {
    setActiveIndex(2);
  };

  return (
    <section>
      <Toast ref={toast} position="top-center" />
      <div className="tabmenu">
        <button
          className="menuitem-link"
          style={{
            borderColor:
              activeIndex === 0
                ? "#6A7A81 #6A7A81 #1B2A32 #6A7A81"
                : "#1B2A32 #1B2A32 #6A7A81 #1B2A32",
          }}
          onClick={setIndex0}
          data-testid="all-labs-title"
        >
          Portal Usage Guide
        </button>
        <button
          className="menuitem-link"
          style={{
            borderColor:
              activeIndex === 1
                ? "#6A7A81 #6A7A81 #1B2A32 #6A7A81"
                : "#1B2A32 #1B2A32 #6A7A81 #1B2A32",
          }}
          onClick={setIndex1}
        >
          Lab User Guide
        </button>
        {isAdmin ? <button
          className="menuitem-link"
          style={{
            borderColor:
              activeIndex === 2
                ? "#6A7A81 #6A7A81 #1B2A32 #6A7A81"
                : "#1B2A32 #1B2A32 #6A7A81 #1B2A32",
          }}
          onClick={setIndex2}
        >
          Admin Guide
        </button> : ""
        }


      </div>
      <div className="data">
        {activeIndex === 0 ? <PortalUsageGuides /> : ""}
        {activeIndex === 1 ? <LabUserGuides /> : ""}        
        {activeIndex === 2 ? <AdminGuides /> : ""}

      </div>
    </section>
  );
}

export default Guides;
