import React, { useState } from "react";
import RbacLabs from "../rbac/lab-management/lab-crud/RbacLabs";
import LabCategories from "../rbac/lab-management/labcategory-crud/LabCategories";
import "./Admin.css";
import LabType from "../rbac/lab-management/labtype-crud/LabType";
import LabScheduleTab from "../rbac/lab-management/lab-crud/LabScheduleTab";

import { useGlobalState } from '../../config/state';
import ActiveLabsTab from "../rbac/lab-management/active-labs/ActiveLabsTab";

const LabManagement = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isAdmin, setIsAdmin] = useGlobalState('isAdmin');

  const setIndex0 = (e) => {
    setActiveIndex(0);
  };

  const setIndex1 = (e) => {
    setActiveIndex(1);
  };

  const setIndex2 = (e) => {
    setActiveIndex(2);
  };

  const setIndex3 = (e) => {
    setActiveIndex(3);
  };

  const setIndex4 = (e) => {
    setActiveIndex(4);
  };

  return (
    <div>
      {(isAdmin) ? (
        <>
          <div className="tabmenu">
            {/* Labs Tab */}
            <button
              className="menuitem-link"
              style={{
                borderColor:
                  activeIndex === 0
                    ? "#979797 #979797 #192a32 #979797" //Border colour of tab when it is active
                    : "#192a32 #192a32 #979797 #192a32", // Border colour of tab when it is inactive
              }}
              onClick={setIndex0} // on click Labs Tab
              data-testid="all-labs-title"
            >
              <div className={activeIndex === 0 ? "active-tab" : "inactive-tab"}>  Labs</div>
            </button>

            {/* Workflow Tab */}
            <button
              className="menuitem-link"
              style={{
                borderColor:
                  activeIndex === 1
                    ? "#979797 #979797 #192a32 #979797" //Border colour of tab when it is active
                    : "#192a32 #192a32 #979797 #192a32", //Border colour of tab when it is inactive
              }}
              onClick={setIndex1} // On click Workflow Tab
            >
              <div className={activeIndex === 1 ? "active-tab" : "inactive-tab"}>  Workflow</div>
            </button>

            {/* Lab Categories Tab */}
            <button
              className="menuitem-link"
              style={{
                borderColor:
                  activeIndex === 2
                    ? "#979797 #979797 #192a32 #979797" //Border colour of tab when it is active
                    : "#192a32 #192a32 #979797 #192a32", //Border colour of tab when it is inactive
              }}
              onClick={setIndex2} // on click Lab Categories Tab
              data-testid="all-labs-title"
            >
              <div className={activeIndex === 2 ? "active-tab" : "inactive-tab"}>  Lab Categories</div>
            </button>

            {/* Lab Schedules Tab */}
            <button
              className="menuitem-link"
              style={{
                borderColor:
                  activeIndex === 3
                    ? "#979797 #979797 #192a32 #979797" //Border colour of tab when it is active
                    : "#192a32 #192a32 #979797 #192a32", //Border colour of tab when it is inactive
              }} 
              onClick={setIndex3} // on click Lab schedules Tab
              data-testid="all-labs-title"
            >
              <div className={activeIndex === 3 ? "active-tab" : "inactive-tab"}>  Lab Schedule</div>
            </button>

            {/* Active Labs Tab */}
            <button
              className="menuitem-link"
              style={{
                borderColor:
                  activeIndex === 4
                    ? "#979797 #979797 #192a32 #979797" //Border colour of tab when it is active
                    : "#192a32 #192a32 #979797 #192a32", //Border colour of tab when it is inactive
              }} 
              onClick={setIndex4} // on click Lab schedules Tab
              data-testid="all-labs-title"
            >
              <div className={activeIndex === 4 ? "active-tab" : "inactive-tab"}>  Active Labs</div>
            </button>
          </div>
          <div className="data">
            {activeIndex === 0 ? <RbacLabs /> : ""}       {/*Labs Tab */}
            {activeIndex === 1 ? <LabType /> : ""}        {/*Workflow Tab */}
            {activeIndex === 2 ? <LabCategories /> : ""}  {/*Lab Categories Tab */}
            {activeIndex === 3 ? <LabScheduleTab /> : ""} {/*Lab Schedules Tab */}
            {activeIndex === 4 ? <ActiveLabsTab /> : ""} {/*Active Labs Tab */}

          </div>
        </>
      ) : (
        window.location.assign("/not-found")
      )}
    </div>
  );
};

export default LabManagement;
