import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import "../../rbac/Rbac.css";
import React, { useState, useRef } from "react";
import { Message } from "primereact/message";
import GuideService from "../../../services/GuideService";
import loglevel from "../../../logger/Logger";


const LabUserGuideDelete = ({ labUserGuideId, labName, setChanger }) => {
  const [deleteLabUserGuideDisplay, setDeleteLabUserGuideDisplay] = useState(false);
  const deleteLabUserGuideDialogMap = {
    deleteLabUserGuideDisplay: setDeleteLabUserGuideDisplay,
  };

  const [labUserGuideErrorMsg, setLabUserGuideErrorMsg] = useState([]);

  const toast = useRef(null);

  // dialog box open to delete
  const onClick = async (name) => {
    setLabUserGuideErrorMsg('');

    deleteLabUserGuideDialogMap[`${name}`](true);
  };

  // on dialog box close
  const onHide = (name) => {
    deleteLabUserGuideDialogMap[`${name}`](false);
  };

  //on deleting guide
  const onDelete = async (e) => {    
      await GuideService.deleteLabUserGuide(labUserGuideId)
        .then((response) => {
          if (response.status === 200) {
            // toast message when guide successfully deleted
            toast.current.show({
              severity: "success",
              summary: "Lab User Guide Successfully Deleted",
              detail: `The lab user guide named "${labName}" has been deleted successfully.`,
              life: 6000,
            });
            deleteLabUserGuideDialogMap["deleteLabUserGuideDisplay"](false);
          } else {
            loglevel.debug("lab user guide deletion has failed");
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            setLabUserGuideErrorMsg(error.response.data.msg);
          }
        });
        // refreshing the guide list after deletion
    await GuideService.getLabUserGuideList().then((res) => {
      console.log(res.data, "Guide list");
      for (let i = 0; i < res.data.length; i++) {
        res.data[i]["closedStatus"] = true;
        res.data[i]["closedGroupsStatus"] = true;

      };
      setChanger(res.data);
    });
  };

  const renderFooter = (name) => {
    return (
      <div className="disable-footer-button">
        {/* Delete user guide button */}
        <Button
            label="DELETE LAB USER GUIDE"
            className={
              "p-button-outlined p-button-danger"
            }
            onClick={() => onDelete("deleteLabUserGuideDisplay")}
           
          />
        <div>
          {" "}
          {/* Cancel button */}
          <Button
            label="CANCEL"
            onClick={() => onHide('deleteLabUserGuideDisplay')}
            className="p-button-outlined p-button-primary"
          />
        </div></div>
    );
  };


  return (
    <div className="lab-type-edit">
      <Toast ref={toast} position="top-center" />
      {/* Delete button icon on view page */}
      <Button
        icon="pi pi-trash"
        className="p-button-rounded global-delete-button"
        onClick={() => onClick("deleteLabUserGuideDisplay")}
      />
      <Dialog
        header={`Delete Lab User Guide - ${labName}`}
        visible={deleteLabUserGuideDisplay}
        footer={renderFooter("deleteLabUserGuideDisplay")}
        onHide={() => onHide("deleteLabUserGuideDisplay")}
        className="lab-type-delete-dialog-box"
      >
        <div>
          {labUserGuideErrorMsg && <Message severity="error" text={labUserGuideErrorMsg} className='common-inline-error common-inline-error p-mt-1 p-mb-3 full-width' />}
        </div>
        {
          (
            <div style={{ width: "365px" }}>
              <div>Do you want to delete this lab user guide?</div>
            </div>
          )
        }
      </Dialog>
    </div>
  );
};

export default LabUserGuideDelete;
