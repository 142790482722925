import loglevel from "../../logger/Logger"
import { Dialog } from "primereact/dialog";
import LabServices from "../../services/LabServices";
import "./../login/Login.css";
import React, { useState, useRef, useEffect } from "react";

const Footer = () => {
    loglevel.debug("Entering into footer component")
    const [contactUsDisplay, setContactUsDisplay] = useState(false);
    const [emailLabel, setEmailLabel] = useState(null)
    const [emailUrl, setEmailUrl] = useState(null)
    const [slackLabel, setSlackLabel] = useState(null)
    const [slackUrl, setSlackUrl] = useState(null)
    let year = new Date().getFullYear();
    useEffect(async () => {
        await LabServices.getContactUsUrls(0)
            .then((res) => {
                console.log(res.data, "urls");
                setEmailLabel(res.data.contact1_text)
                setEmailUrl(res.data.contact1_url)
                setSlackLabel(res.data.contact2_text)
                setSlackUrl(res.data.contact2_url)
            })
            .catch((error) => {
                loglevel.error(error);
            });
    }, []);

    const contactUsDialogMap = {
        contactUsDisplay: setContactUsDisplay,
    };

    const contactUsDialog = (name) => {
        contactUsDialogMap[`${name}`](true);
    }

    const onHide = (name) => {
        contactUsDialogMap[`${name}`](false);
    }

    const renderFooter = (name) => {
        return (
            <div>
                <div className="reset-btn-section">
                    {/* <Button label="Close" onClick={() => onHide("contactUsDisplay")} className="p-button-primary" /> */}
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className="login-footer">
                <div className=" login-footer-right">
                    <div className='policy-container'>
                        <div className='portal-policy-link footer-copyright'>Copyright © 2005-2024 Broadcom. All Rights Reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries.</div>
                        <a href="https://www.vmware.com/help/legal.html" target="_blank" className='portal-policy-link text-no-decoration'>Terms of Use</a>
                        <a href="https://www.vmware.com/help/privacy/california-privacy-rights.html" target="_blank" className='portal-policy-link text-no-decoration'>Your California Privacy Rights</a>
                        <a href="https://www.vmware.com/help/privacy.html" target="_blank" className='portal-policy-link text-no-decoration'>Privacy</a>
                        <a onClick={() => contactUsDialog("contactUsDisplay")} className="portal-policy-link text-no-decoration">Contact Us</a>
                    </div>
                </div>
            </div>
            <Dialog
                header="Contact Us"
                visible={contactUsDisplay}
                footer={renderFooter("contactUsDisplay")}
                onHide={() => onHide("contactUsDisplay")}
                style={{ display: "flex", justifyContent: "center" }}
                className="lab-view-dialog-box"
            >
                <div className="link-message">
                    Please reach out to us via{" "}
                    <a
                        className="link"
                        href={emailUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {emailLabel}
                    </a>{" "}
                    (Via email) or{" "}
                    <a
                        className="link"
                        href={slackUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {slackLabel}
                    </a>{" "}
                    (Google Chat Space)
                </div>
            </Dialog>
        </div>
    )

}

export default Footer;