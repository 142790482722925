import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import LabServices from "../../../../services/LabServices";
import loglevel from "../../../../logger/Logger";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { Checkbox } from "primereact/checkbox";
import { Message } from "primereact/message";
import eventBus from "../../../utility/EventBus";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";

const LabScheduleAssetDetails = ({ labScheduleId, isTab }) => {
    const [labAssetDetails, setLabAssetDetails] = useState();
    const [commonErrorMsg, setCommonErrorMsg] = useState("");
    const toast = useRef(null);
    const dt = useRef(null);
    const errorRef = useRef();

    useEffect(() => {
        setCommonErrorMsg("");
        getLabAssetDetails(labScheduleId);

    }, []);

    const getLabAssetDetails = async (labScheduleId) => {
        await LabServices.getLabAssetDetails(labScheduleId).then((res) => {
            console.log("Lab Asset Details: ", res.data)
            setLabAssetDetails(res.data)
        }).catch((error) => {
            loglevel.error("rowExpansionTemplate getLabAssetDetails:", error);
        });
    }

    const serversTemplate = (rowData) => {
        return <div className="short-desc">Server {rowData.server_sequence}</div>;
    }

    const modelServerTemplate = (rowData) => {
        return <div className="short-desc">{rowData.model_name}</div>;
    }

    const bmcIPTemplate = (rowData) => {
        return <div className="short-desc">{rowData.bmc_ip}</div>;
    }

    const serialNumTemplate = (rowData) => {
        return <div className="short-desc">{rowData.serial_no}</div>;
    }

    const assetNameTemplate = (rowData) => {
        return <div className="short-desc">{rowData.asset_name}</div>;
    }

    const cmdbTagsTemplate = (rowData) => {
        const val = rowData.cmdbTags.map((r, index) => {
            return <Tag key={r.toString()} className="p-mr-2 dt-tag"><svg className="p-mr-1" width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.55336 5.48551L8.78688 5.25199C9.51244 4.5303 9.51576 3.35713 8.79429 2.63136L6.83345 0.681641C6.10972 -0.0418703 4.93656 -0.0418703 4.21283 0.681641L1.04361 3.85086C0.320099 4.57459 0.320099 5.74775 1.04361 6.47148L2.99333 8.4212C3.71706 9.14471 4.89022 9.14471 5.61395 8.4212L5.76222 8.27293L6.57028 9.07358H7.84538V10.1856H9.21315V10.7898L10.277 11.861H12.501V9.43313L8.55336 5.48551ZM11.7596 11.1198H10.5957L9.93958 10.4674V9.42957H8.57552V8.31756H6.86674L5.75473 7.20556L5.08753 7.89871C4.65338 8.33232 3.95005 8.33232 3.51589 7.89871L1.56618 5.93046C1.13256 5.4963 1.13256 4.79297 1.56618 4.35882L4.7391 1.1859C5.17325 0.752284 5.87658 0.752284 6.31073 1.1859L8.26045 3.13561C8.69407 3.56977 8.69407 4.2731 8.26045 4.70725L7.51911 5.44859L11.7596 9.74093V11.1198ZM2.7704 4.30611C2.58438 4.49212 2.58438 4.7937 2.7704 4.97972C2.95641 5.16573 3.25799 5.16573 3.444 4.97971L5.31541 3.1083C5.50142 2.92229 5.50142 2.62071 5.31541 2.4347C5.1294 2.24869 4.82782 2.24869 4.64181 2.4347L2.7704 4.30611Z" fill="black" />
            </svg>{r}</Tag>;
        });

        return <p className="lab-tag">{val}</p>;
    };

    return (
        <div>
            {" "}
            <Toast ref={toast} position="top-center" />
            <div className="orders-subtable">
                {labAssetDetails
                    ? (<div>
                        <div className="flex-container">
                            <div className="virtual-text mr-top-bot-5 mr-l-r-10 flex-child-virtual">Username: {labAssetDetails.username}</div>
                            <div className="virtual-text mr-top-bot-5 mr-l-r-10 flex-child-virtual">Password: {labAssetDetails.password}</div>
                            <div className="virtual-text mr-top-bot-5 mr-l-r-10 flex-child-virtual">Client Name: {labAssetDetails.client_name}</div>
                            <div className="virtual-text mr-top-bot-5 mr-l-r-10 flex-child-virtual">Purpose: {labAssetDetails.purpose}</div>
                            <div className="virtual-text mr-top-bot-5 mr-l-r-10 flex-child-virtual">Description: {labAssetDetails.description}</div>
                        </div>
                        {labAssetDetails.asset_inventory_list.length > 0
                            ? (<DataTable value={labAssetDetails.asset_inventory_list} responsiveLayout="scroll" className="asset-dtl mr-l-r-10 lab-sch-asset" style={{width:"90%"}}>
                                <Column field="asset_inventory_id" body={serversTemplate} header="Servers" sortable style={{ minWidth: '8rem', paddingLeft: "15px" }}></Column>
                                <Column field="asset_name" header="Asset Name" sortable body={assetNameTemplate} style={{ minWidth: "10rem" }}></Column>
                                <Column field="serial_no" header="Serial No." sortable body={serialNumTemplate} style={{ minWidth: "7rem" }}></Column>
                                <Column field="cmdbTags" header="CMDB Tags" body={cmdbTagsTemplate} sortable style={{ minWidth: "12rem" }}></Column>
                                <Column field="bmc_ip" header="BMC IP" sortable body={bmcIPTemplate} style={{ minWidth: "8rem" }}></Column>
                                <Column field="model_name" header="Model Server" sortable body={modelServerTemplate} style={{ minWidth: "8rem" }}></Column>
                            </DataTable>)
                            : ""}

                    </div>
                    )
                    : ""}
            </div>

        </div>
    );
};

export default LabScheduleAssetDetails;
