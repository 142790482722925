import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import "../../Rbac.css";
import "./UserTags.css";
import React, { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { InputTextarea } from "primereact/inputtextarea";
import LabServices from "../../../../services/LabServices";
import loglevel from "../../../../logger/Logger";

const CreateUserTag = ({ setChanger }) => {
  const [isValidated, setIsValidated] = useState(false);
  const [createTagDisplay, setCreateTagDisplay] = useState(false);
  const createTagDialogMap = {
    createTagDisplay: setCreateTagDisplay,
  };
  const [tagName, setTagName] = useState("");
  const [description, setDescription] = useState("");
  const [commonErrorMsg, setCommonErrorMsg] = useState("");

  const toast = useRef(null);
  const errorRef = useRef();

  // on opening create tag dialog
  const onClick = async (name) => {
    setTagName("");
    setDescription("");
    setIsValidated(false);
    setCommonErrorMsg("");
    createTagDialogMap[`${name}`](true);
  };

  // on closing create tag dialog
  const onHide = (name) => {
    setTagName("");
    setDescription("");
    setIsValidated(false);
    setCommonErrorMsg("");
    createTagDialogMap[`${name}`](false);
  };

  // on creating tag
  const onAdd = async () => {
    setIsValidated(true);
    if (tagName && description) {
      const requestBody = {
        name: `${tagName}`,
        description: `${description}`,
      };


      //api call for creating tag
      await LabServices.createUserTag(requestBody)
        .then((response) => {
          if (response.status === 201) {
            //toast after successful tag creation
            toast.current.show({
              severity: "success",
              summary: "Tag Successfully Created",
              detail: "The tag has been successfully created.",
              life: 6000,
            });
            //closing dialog after successful tag creation
            createTagDialogMap["createTagDisplay"](false);
          } else {
            loglevel.debug("Tag Creation is Failed");
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            setCommonErrorMsg(error.response.data.msg);
            //error message from response
            errorRef.current.scrollIntoView({ behavior: "smooth" });
          }
        });

      await LabServices.getUserTagList().then((res) => {
        setChanger(res.data);
      });
    }
  };

  const renderFooter = (name) => {
    return (
      <div>
        {/* Create Tag Button */}
        <Button
          className="calender-button"
          style={{ background: "#49AFD9" }}
          onClick={() => onAdd()}
        >
          <span className="name">CREATE TAG</span>
        </Button>
      </div>
    );
  };

  return (
    <div className="admin-panel-content">
      <Toast ref={toast} position="top-center" />
      <div className="create-lab-button">
        {/* Create new Tag button on listing page */}
        <Button
          id="reset-button"
          label="Create New Tag"
          className="p-button-primary"
          onClick={() => onClick("createTagDisplay")}
        />
      </div>
      {/* Create Tag Dialog */}
      <Dialog
        header="Create New Tag"
        visible={createTagDisplay}
        footer={renderFooter("createTagDisplay")}
        onHide={() => onHide("createTagDisplay")}
        className="tag-dialog-box"
      >
        <div ref={errorRef} className={commonErrorMsg ? "" : "hidden"}>
          {commonErrorMsg && (
            <Message
              severity="warn"
              text={commonErrorMsg}
              className="common-inline-error common-error-message p-mb-3"
            />
          )}
        </div>{" "}
        <div className="tag-name-input-text">
          <div className="tag-name-label">
            Tag Name :<span className="cat-required-field">*</span>
          </div>
          <div>
            {/* Input box for tag name */}
            <InputText
              value={tagName}
              onChange={(e) => setTagName(e.target.value)}
              placeholder="Enter Tag Name"
              maxLength={20}
              className={
                !tagName && isValidated
                  ? "tag-input-error"
                  : "tag-name-text-box"
              }
            />
            {!tagName && isValidated ? (
              <div className="cat-error-message-inline">
                <small className="cat-error-message" id="error-message">
                  Tag Name is mandatory
                </small>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="tag-desc-input-text">
          <div className="tag-name-label">
            Description :<span className="cat-required-field">*</span>
          </div>
          <div>
            {/* Text area for Description */}
            <InputTextarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter Tag Description"
              maxLength={1000}
              autoResize={true}
              rows={2}
              cols={40}
              className={
                !description && isValidated
                  ? "tag-desc-input-error"
                  : "tag-desc-text-box"
              }
            />
            {!description && isValidated ? (
              <div className="cat-error-message-inline">
                <small className="cat-error-message" id="error-message">
                  Description is mandatory
                </small>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default CreateUserTag;
