import React, { useEffect, useState, useRef } from 'react'
import { Divider } from "primereact/divider";
import '../header/AboutUs.css'
import parse from "html-react-parser";
import { Editor } from 'primereact/editor';
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import LabServices from '../../services/LabServices';
import { useGlobalState } from '../../config/state';
import { Toast } from "primereact/toast";
require('dotenv');

const AboutUs = () => {
  const [isAdmin, setIsAdmin] = useGlobalState('isAdmin');
  const [text1, setText1] = useState('');
  const [text2, setText2] = useState('');
  const version = `${process.env.REACT_APP_RELEASE_VERSION}${process.env.REACT_APP_BUILD_NUMBER}`
  const [displayBasic, setDisplayBasic] = useState(false);
  const [newFeaturesDialog, setNewFeatureDialog] = useState(false);
  const [displayText, setDisplayText] = useState("")
  const [whatsNew, setWhatsNew] = useState("")
  const [data, setData] = useState([])
  const toast = useRef(null);
  const dialogFuncMap = {
    displayBasic: setDisplayBasic,
    newFeaturesDialog: setNewFeatureDialog,
  };

  let valueProp = "";
  let whatsNewVar = ""


  useEffect(async () => {
    //api for about us data
    await LabServices.getAboutUsList()
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data, "aboutusres")
          setData(response.data)
          for (let i = 0; i < response.data.length; i++) {
            //for value proposition
            if (response.data[i].customer_experience.toLowerCase() === "value proposition") {
              setDisplayText(response.data[i].contents)
            } else if (response.data[i].customer_experience.toLowerCase() === "whats new") { //for whats new 
              setWhatsNew(response.data[i].contents)
            } else {
              continue;
            }
          }
        }
      })
      .catch((error) => { // catching error
        if (error.response.status === 400) {
          toast.current.show({
            severity: "error",
            detail: `${error.response.data.msg}`,
            life: 5000,
          });
        }
      });

  }, [])


 // method call on  Hiding/Closing editor
  const onHide = (name) => {
    setText1("")
    setText2("")
    dialogFuncMap[`${name}`](false);
  };

 // method call on opening editor after click on edit button
  const onClick = (name) => {
    if (name === "displayBasic") {
      for (let i = 0; i < data.length; i++) {
        if (data[i].customer_experience.toLowerCase() === "value proposition") {
          setText1(data[i].contents)
        } else {
          continue
        }
      }
    } else {
      for (let i = 0; i < data.length; i++) {
        if (data[i].customer_experience.toLowerCase() === "whats new") {
          setText2(data[i].contents)
        } {
          continue
        }
      }
    }
    dialogFuncMap[`${name}`](true);
  };

  // editing value proposition
  const setValueProposition = (e) => {
    console.log(e, "e.htmlvalue")
    valueProp = e.htmlValue

  }

  // editing whats new feature
  const setNewFeatures = (e) => {
    console.log(e.htmlValue, "htmlvalue")
    whatsNewVar = e.htmlValue

  }

  // method call after click on save button
  const onSave = async (name) => {
    console.log(name, "nameof dialog")
    let params = ""
    let reqBody = {
      customer_experience: "",
      contents: ""
    }
    if (name === "displayBasic") {
      for (let i = 0; i < data.length; i++) {
        if (data[i].customer_experience.toLowerCase() === "value proposition") { // setting params for value proposition
          params = `/?about_us_id=${data[i].aboutUsId}`
          reqBody.customer_experience = "value proposition"
          reqBody.contents = valueProp === "" ? text1 : valueProp
        } else {
          continue;
        }
      }
    } else {
      for (let i = 0; i < data.length; i++) {
        if (data[i].customer_experience.toLowerCase() === "whats new") { // setting params for whats new
          params = `/?about_us_id=${data[i].aboutUsId}`
          reqBody.customer_experience = "whats new"
          reqBody.contents = whatsNewVar === "" ? text2 : whatsNewVar
        } else {
          continue;
        }
      }
    }

    // updating about us content
    await LabServices.updateAboutUs(params, reqBody)
      .then((response) => {
        if (response.status === 200) {
          dialogFuncMap[`${name}`](false);
          //toast message after updating content
          toast.current.show({
            severity: "success",
            summary: "Successfully Updated",
            detail: ` ${name === "displayBasic" ? "Value Proposition" : "WhatsNew Features"} has been updated successfully`,
            life: 4000,
          });

        }
      })
      .catch((error) => { // catching error
        if (error.response.status === 400) {
          // toast msg for error response
          toast.current.show({
            severity: "error",
            detail: `${error.response.data.msg}`,
            life: 5000,
          });

        }
      });

    // calling api for About us list to refresh value 
    await LabServices.getAboutUsList()
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data, "aboutusres")
          setData(response.data)

          for (let i = 0; i < response.data.length; i++) {
            // setting contents for value proposition
            if (response.data[i].customer_experience.toLowerCase() === "value proposition") {
              setDisplayText(response.data[i].contents)
              // setting contents for whats new
            } else if (response.data[i].customer_experience.toLowerCase() === "whats new") {
              setWhatsNew(response.data[i].contents)
            } else {
              continue;
            }
          }
        }
      })

    valueProp = ""
    whatsNewVar = ""
  }

 // footer of Dialog
  const renderFooter = (name) => {
    return (
      // save button
      <div>
        <Button
          label="SAVE"
          onClick={() => onSave(name)}
          className="blue-outlined-button p-button-outlined"
        />
      </div>
    );
  };

  return (
    <div className='about-us-page'>
      <Toast ref={toast} position="top-center" />
      <div className='about-heading'>
        <div className='left-heading'>About VMWare SDE Labs</div>
        <div className='right-heading'><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.00005 13.2218C3.56362 13.2218 0.777832 10.436 0.777832 6.99957C0.777832 3.56313 3.56362 0.777344 7.00005 0.777344C10.4365 0.777344 13.2223 3.56313 13.2223 6.99957C13.2223 8.6498 12.5667 10.2324 11.3998 11.3993C10.2329 12.5662 8.65029 13.2218 7.00005 13.2218Z" fill="#49AFD9" />
          <path d="M6.28671 8.61586L4.54838 6.87919C4.49175 6.81306 4.49556 6.71449 4.55712 6.65293C4.61868 6.59137 4.71725 6.58756 4.78338 6.64419L6.28338 8.14419L9.21504 5.21586C9.28117 5.15923 9.37974 5.16304 9.4413 5.2246C9.50286 5.28616 9.50667 5.38473 9.45004 5.45086L6.28671 8.61586Z" fill="white" stroke="white" strokeWidth="0.4" />
        </svg> Version # {version}</div>
      </div>
      <Divider  style={{color:"6A7A81"}}/>
      <div className='about-section-header'><div className='section-title'>Value Proposition</div>{isAdmin ? <div className='section-edit-button'><Button id="valueProp" className="blue-outlined-button p-button-outlined" onClick={() => onClick('displayBasic')}>Edit </Button></div> : ""}</div>
      <div className='about-section about-section-one'>

        <div className="edit-value"> {parse(`${displayText}`)}</div>

      </div>
      <Divider style={{color:"6A7A81"}}/>
      <div className='about-section-header'><div className='section-title'>What's new in SDE Labs</div>{isAdmin ? <div className='section-edit-button'><Button id="newFeatures" className="blue-outlined-button p-button-outlined" onClick={() => onClick('newFeaturesDialog')}>Edit </Button></div> : ""}</div>
      <div className='about-section about-section-one'>
        <div className='edit-value'>
          {parse(`${whatsNew}`)}
        </div>

      </div>
      <Divider style={{color:"6A7A81"}}/>
      <Dialog
        className="show-more-dialog"
        dismissableMask={true}
        visible={displayBasic}
        style={{ width: "45vw" }}
        footer={() => renderFooter("displayBasic")}
        onHide={() => onHide("displayBasic")}
      >
        {/* editor for editing value proposition content */}
        <Editor style={{ height: '320px' }} value={text1} onTextChange={(e) => setValueProposition(e)} />
      </Dialog>

      <Dialog
        className="show-more-dialog"
        dismissableMask={true}
        visible={newFeaturesDialog}
        style={{ width: "45vw" }}
        footer={() => renderFooter("newFeaturesDialog")}
        onHide={() => onHide("newFeaturesDialog")}
      >
        {/* editor for editing whats's new content */}
        <Editor style={{ height: '320px' }} value={text2} onTextChange={(e) => setNewFeatures(e)} />
      </Dialog>

    </div>

  )
}

export default AboutUs