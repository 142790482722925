import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { Tag } from 'primereact/tag';
import React, { useState, useRef } from 'react'
import LabServices from '../../../../services/LabServices';


const AssetDetails = ({assetId , assetNameProp, remarks, reason, grade}) => {
    const [assetDetailsDisplay, setAssetDetailsDisplay] = useState(false);
    const [selectedDays, setSelectedDays] = useState({ filterByDays: "Next 7 days", days: 7, flag: "next" });
    const [data,setData] = useState([])
    const [errorMsg,setErrorMsg]=useState("")
    const errorRef =useRef(null)
    const filterDays = [
        { filterByDays: "Last 90 days", days: 90, flag: "last" },
        { filterByDays: "Last 30 days", days: 30, flag: "last" },
        { filterByDays: "Last 15 days", days: 15, flag: "last" },
        { filterByDays: "Last 7 days", days: 7, flag: "last" },
        { filterByDays: "Next 7 days", days: 7, flag: "next" },
        { filterByDays: "Next 15 days", days: 15, flag: "next" },
        { filterByDays: "Next 30 days", days: 30, flag: "next" },
        { filterByDays: "Next 90 days", days: 90, flag: "next" },
    ];

    const assetDetailsDialogMap = {
        assetDetailsDisplay: setAssetDetailsDisplay,
    };


    const onClick = (name) => {
        setErrorMsg("")
        setSelectedDays({ filterByDays: "Next 7 days", days: 7, flag: "next" })
        LabServices.getAssetDetails(assetId, 7, "next")
        .then((res) => {
            if (res.status === 200) {
              setData(res.data)
            } 
          })
          .catch((error) => {
            if (error.response.status === 400) {
              setErrorMsg(error.response.data.msg)
            }
          })
        assetDetailsDialogMap[`${name}`](true);
    }


    const onHide = (name) => {
        setErrorMsg("")
        setSelectedDays({ filterByDays: "Next 7 days", days: 7, flag: "next" })
        assetDetailsDialogMap[`${name}`](false);
    };

    const onDaysChange = (e) => {
      // console.log(e.value.filterByDays,"val")
      // console.log(e.value.days,"val")
      // console.log(e.value.flag,"val")
setSelectedDays(e.value)
      LabServices.getAssetDetails(assetId, e.value.days, e.value.flag)
        .then((res) => {
            if (res.status === 200) {
              setData(res.data)
            } 
          })
          .catch((error) => {
            if (error.response.status === 400) {
              setErrorMsg(error.response.data.msg)
            }
          })
    }

    const renderFooter = (name) => {
      return (
        <div>
        <Button
          label="CLOSE"
          onClick={() => onHide("assetDetailsDisplay")}
          className="blue-outlined-button p-button-outlined"
        />
      </div>
      )
    };

    const statusTemplate =(rowData)=>{
      if (rowData.status === "Active") {
        return (<Tag className="p-mr-2 tag activestatus">{rowData.status}</Tag>)
    }
    if (rowData.status === "Failed") {
        return (<Tag className="p-mr-2 tag failedstatus">{rowData.status}</Tag>)
    }
    if (rowData.status === "Scheduled" || rowData.status === "Deploying") {
        return (<Tag className="p-mr-2 tag schedulestatus">{rowData.status}</Tag>)
    }
    if (rowData.status === "Deleted") {
        return (<Tag className="p-mr-2 tag deletestatus">{rowData.status}</Tag>)
    }
    else {
        return (<Tag className="p-mr-2 tag cancelstatus">{rowData.status}</Tag>)
    }
    }


    return (
        <div>
            <div
                className="asset-details"
                onClick={() => onClick("assetDetailsDisplay")}
            >
               See Details
            </div>
        <Dialog
          visible={assetDetailsDisplay}
          header={`Details : ${assetNameProp}`}
          footer={renderFooter("assetDetailsDisplay")}
          onHide={() => onHide("assetDetailsDisplay")}
          className="add-asset-dialog-box"
        //onShow={()=>onShowDialog("assetDetailsDisplay")}
        >
          <div ref={errorRef} className={errorMsg ? "" : "hidden"}>
            {errorMsg && (
              <Message
                severity="warn"
                text={errorMsg}
                className="common-inline-error common-error-message p-mb-3"
              />
            )}
          </div>
            {grade && (
              <div style={{display: 'flex'}}>
                <div>
                  Grade :
                </div>
                <div style={{paddingLeft: "5px", paddingBottom: "10px"}}>
                  {grade}
                </div>
              </div>
            )}
            {remarks && (
                <div style={{ width: "850px", display: "flex", flexDirection: 'row' }}>
                    <div style={{ minWidth: "70px" }}>
                        Remarks :
                    </div>
                    <div style={{ paddingLeft: "5px", paddingBottom: "10px" }}>
                        {remarks}
                    </div>
                    <br /><br />
                </div>
            )}
            {reason && (
                <div style={{ width: "850px", display: "flex", flexDirection: 'row' }}>
                    <div style={{ minWidth: "70px" }}>
                        Reason for disable :
                    </div>
                    <div style={{ paddingLeft: "5px", paddingBottom: "10px" }}>
                        {reason}
                    </div>
                    <br /><br />
                </div>
            )}
          <div>
            <Dropdown
              value={selectedDays}
              options={filterDays}
              onChange={(e) => onDaysChange(e)}
              optionLabel="filterByDays"
              placeholder="Select quick dates"
              className="quick-date-selector"
            />
          </div>
          <div className='table-title'>Schedules for Labs associated with Physical Asset</div>
          <DataTable
            value={data}
            className="asset-crud-table"
            columnResizeMode="fit"
            //  paginator
            //rows={50}
            breakpoint="1024px"
            //rowsPerPageOptions={[5, 10, 20, 50, 100]}
            //globalFilter={globalFilter}
            responsiveLayout="scroll"
            autoLayout={true}
            style={{ width: "100%" }}
            pageLinkSize={1}
          >
            <Column
              sortable
              field="lab_name"
              header="Lab Name"
              style={{ minWidth: "10rem", paddingLeft: "15px", fontWeight: "600" }}
            ></Column>
            <Column
              sortable
              field="lab_owner_name"
              header="Lab Owner Name"
              style={{ minWidth: "7rem" }}
            ></Column>
            <Column
              sortable
              field="schedule_start_date"
              header="Start Date"
              style={{ minWidth: "9rem" }}
            ></Column>
            <Column
              sortable
              field="schedule_end_date"
              header="End Date"
              style={{ minWidth: "9rem" }}
            ></Column>
            <Column
              sortable
              field="user_time_zone"
              header="Timezone"
              style={{ minWidth: "9rem" }}
            ></Column>
            <Column
              sortable
              field="status"
              header="Status"
              style={{ minWidth: "8rem" }}
              body={statusTemplate}
            ></Column>


          </DataTable>
        </Dialog>
        </div>
    )
}

export default AssetDetails