import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import LabServices from "../../../services/LabServices";
import loglevel from "../../../logger/Logger";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

const AssetScheduleTab = () => {
    const [globalFilter, setGlobalFilter] = useState(null);
    const [commonErrorMsg, setCommonErrorMsg] = useState("");
    const [assetScheduleList, setAssetScheduleList] = useState([]);
    const toast = useRef(null);
    const dt = useRef(null);
    const filterDays = [
        { filterByDays: "Last 90 days", days: 90, flag: "last" },
        { filterByDays: "Last 30 days", days: 30, flag: "last" },
        { filterByDays: "Last 15 days", days: 15, flag: "last" },
        { filterByDays: "Last 7 days", days: 7, flag: "last" },
        { filterByDays: "Next 7 days", days: 7, flag: "next" },
        { filterByDays: "Next 15 days", days: 15, flag: "next" },
        { filterByDays: "Next 30 days", days: 30, flag: "next" },
        { filterByDays: "Next 90 days", days: 90, flag: "next" },
    ];

    const [selectedDays, setSelectedDays] = useState({
        filterByDays: "Next 7 days",
        days: 7,
        flag: "next"
    });

    const [expandedRows, setExpandedRows] = useState(null);

    useEffect(() => {
        async function fetchData() {
            setCommonErrorMsg("");
            let days = selectedDays.days;
            let flag = selectedDays.flag;
            getAllLabScheduleList(days, flag);
        }
        fetchData();
    }, []);
    const onDaysChange = (e) => {
        setSelectedDays(e.value);
        let days = e.value.days;
        let flag = e.value.flag
        setAssetScheduleList([]);
        setExpandedRows(null);
        getAllLabScheduleList(days, flag);

    };

    const getAllLabScheduleList = async (days, flag) => {
        await LabServices.getAssetSchedule(days, flag).then((res) => {
            console.log("Asset Schedule List: ", res.data)
            setAssetScheduleList(res.data);
        }).catch((error) => {
            loglevel.error("getAllLabScheduleList:", error);
        });
    }

    const statusTemplate = (rowData) => {
        return <div className={`short-desc status-label 
                                ${rowData.status === 'Scheduled' ? "scheduled-status" :
                rowData.status === 'Active' ? "active-status" :
                    rowData.status === 'Failed' ? "failed-status" :
                        rowData.status === 'Deleted' ? "deleted-status" :
                            rowData.status === 'Deleting' ? "deleting-status" :
                                rowData.status === 'Deploying' ? "deploying-status" :
                                    rowData.status === 'Cancelled' ? "cancelled-status" : ""
            }`
        }>{rowData.status}</div>;
    };

    return (
        <div className="datatable-crud-demo">
            <div className="admin-panel">
                <div className="admin-panel-search-bar">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            type="search"
                            onInput={(e) => setGlobalFilter(e.target.value)}
                            placeholder="Search"
                            className="global-search-filter"
                        />
                    </span>
                </div>
            </div>

            <Toast ref={toast} position="top-center" />
            <div className="lab-schdle-dropd" style={{marginBottom: "10px"}}>
                    <Dropdown
                        value={selectedDays}
                        options={filterDays}
                        onChange={onDaysChange}
                        optionLabel="filterByDays"
                        placeholder="Select quick dates"
                        className="quick-date-selector"
                    />
                </div>
            <DataTable value={assetScheduleList}
                ref={dt}
                className="lab-crud-table lab-sch-dtl"
                columnResizeMode="fit"
                paginator
                rows={50}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                responsiveLayout="scroll"
                autoLayout={true}
                removableSort={true}
                pageLinkSize={1}
                let-i="rowIndex"
                style={{ width: '100%' }}
                globalFilter={globalFilter}
                //rowExpansionTemplate={rowExpansionTemplate} dataKey="schedule_id"
            >
                <Column sortable field="asset_name" header="Asset Name" style={{ minWidth: '9rem',paddingLeft: '15px' }} />
                <Column sortable field="bmc_ip" header="BMC IP" style={{ minWidth: '8rem'}} /> 
                <Column sortable field="lab_name" header="Lab Name" style={{ minWidth: '9rem' }} />
                <Column sortable field="sequence" header="Servers Sequence" style={{ width:"10rem", textAlign: "center"}}/>
                <Column sortable field="lab_owner_name" header="Owner" style={{ minWidth: '9rem' }} />
                <Column sortable field="schedule_start_date" header="Start Date" style={{ minWidth: '9rem' }} />
                <Column sortable field="schedule_end_date" header="End Date" style={{ minWidth: '9rem' }} />
                <Column sortable field="user_time_zone" header="Timezone" style={{ minWidth: '8rem' }} />
                <Column sortable field="status" header="Status"
                    body={statusTemplate} style={{ maxWidth: "6rem" }} />

            </DataTable>
        </div >
    );
};

export default AssetScheduleTab;
