import React, { useEffect } from "react";
import { Chart } from "primereact/chart";
import "./Graphs.css";
const Graphs = ({ labs, labCount, purpose, purposeCount }) => {
  const COLORS = ["#FF8042", "#0088FE", "#00C49F", "#FFBB28"];


  //creating custom tooltip for graph
  const footer = (tooltipItems) => {
    let sum = 0;

    tooltipItems.forEach(function (tooltipItem) {
      sum += tooltipItem.parsed.y;
    });
    return 'Count: ' + sum;
  };

  //lab usage
  const basicData = {
    labels: labs,
    datasets: [

      {
        data: labCount,
        backgroundColor: [
          "#FFA726",
          "#66BB6A",
          "#42A5F5",
          '#FF8042',
          '#f1749e',
          '#6372c3',
          '#61beb5',
          '#af50bf',

        ],

        borderColor: "#fff",
        order: 0
      },
    ],

  };

  // purpose usage 
  const purposeData = {
    labels: purpose,
    datasets: [

      {
        data: purposeCount,
        backgroundColor: [
          "#FFA726",
          "#66BB6A",
          "#42A5F5",
          '#FF8042',
          '#f1749e',
          '#6372c3',
          '#61beb5',
          '#af50bf',
        ],
        borderColor: "#fff",
        order: 0
      },
    ],

  };

// plugings for pie chart
  let optionPie = {
    maintainAspectRatio: false,

    plugins: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'right',
        labels: {
          color: "white",
          boxWidth: 40,
        },
      }
    }

  }


  return (
    <>
      <div className="pie-charts mg-b-10">
        {!labCount ? // if nodata is there
          <div className="no-available-data">
            <div className="pie-label">Top 5 Lab Usage</div>
            <p>No Data Available  for Lab Usage</p>

          </div> : labCount.every(item => item === 0) ? // if all lab have 0 usage

            <div className="no-available-data">
              <div className="pie-label">Top 5 Lab Usage</div>
              <p>No Data Available  for Lab Usage</p>
            </div> :
            <div>
              <div className="pie-label">Top 5 Lab Usage</div>
              <Chart type="pie" data={basicData} options={optionPie} height="16rem" width="35vw" />
            </div>
        }
        {!purposeCount ? // if no data is there
          <div className="no-available-data">
            <div className="pie-label">Purpose Usage</div>
            <p>No Data Available  for Purpose Usage</p>

          </div> : purposeCount.every(item => item === 0) ? // if each data is 0

            <div className="no-available-data">
              <div className="pie-label">Purpose Usage</div>
              <p>No Data Available  for Purpose Usage</p>
            </div> :
            <div>
              <div className="pie-label">Purpose Usage</div>
              <Chart type="pie" data={purposeData} options={optionPie} height="16rem" width="35vw" />
            </div>
        }
      </div>
      <div className="legend-info">Click over legends, to show/hide chart elements</div>
    </>
  );
};

export default Graphs;
