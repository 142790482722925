import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputSwitch } from 'primereact/inputswitch'
import { InputText } from 'primereact/inputtext'
import { Message } from 'primereact/message'
import { Toast } from 'primereact/toast'
import React, { useState, useRef } from 'react'
import LabServices from '../../../../services/LabServices'
import '../virtual-environment/VirtualEnvironment.css'
import { InputTextarea } from 'primereact/inputtextarea';

const CreateVirtualEnv = ({ fetchData, showToastSuccess }) => {
    const [createVirtualEnvDisplay, setcreateVirtualEnvDisplay] = useState(false);
    const [virtualEnvId, setVirtualEnvId] = useState("");
    const [isStatic, setIsStatic] = useState(true);
    const [isStatus, setIsStatus] = useState(false);
    const errorRef = useRef();
    const [commonErrorMsg, setCommonErrorMsg] = useState("");
    const toast = useRef(null);
    const [virtualIdErrorMsg, setVirtualIdErrorMsg] = useState("")
    const [remarks, setRemarks] = useState("")

    const createVirtualEnvDialogMap = {
        createVirtualEnvDisplay: setcreateVirtualEnvDisplay,
    };

    const showToast = (data) => showToastSuccess(data)

    const updateList = () => fetchData()

    const onHide = (name) => {
        setVirtualEnvId("");
        setIsStatic(true);
        setIsStatus(false);
        setVirtualIdErrorMsg("");
        setCommonErrorMsg("");
        setRemarks("");
        createVirtualEnvDialogMap[`${name}`](false);
    }

    const onClick = async (name) => {
        createVirtualEnvDialogMap[`${name}`](true);
    };

    const onVirtualEnvIdChange = (e) => {
        if (e.target.value === "") {
            setVirtualIdErrorMsg("Virtual ID is mandatory");
        }
        else {
            setVirtualIdErrorMsg("")
        }
        setVirtualEnvId(e.target.value);
    }

    const onStaticChange = (e) => {
        setIsStatic(e.target.value);
    }

    const onStatusChange = (e) => {
        setIsStatus(e.target.value);
    }

    const requestPayload = {
        virtual_environment_id: +virtualEnvId,
        is_static_lab: isStatic,
        enabled: isStatus,
        remarks: remarks
    }

    const createVirtualEnv = (e) => {
        if (virtualEnvId === "") {
            setVirtualIdErrorMsg("Virtual ID is mandatory");
        }
        else {
            setVirtualIdErrorMsg("")
        }
        if (virtualEnvId !== "") {
            LabServices.createVirtualEnvironmentDetails(requestPayload).then((response) => {
                console.log("respp", response);
                createVirtualEnvDialogMap[`createVirtualEnvDisplay`](false);
                updateList()
                showToast({
                    summary: "Virtual Environment Created",
                    detail: "Virtual Environment has been created successfully."
                })
                setVirtualEnvId("");
                setIsStatic(true);
                isStatus(false);
                setRemarks("");
            }).catch((error) => {
                if (error.response?.status === 400) {
                    setCommonErrorMsg(error.response.data.msg);
                    errorRef.current.scrollIntoView({ behavior: "smooth" });
                }
            })
        }
    }

    const renderFooter = (name) => {
        return (
            <div className="terms-section">
                <Button
                    className="create-user-button"
                    label="CREATE"
                    style={{ background: "#49AFD9" }}
                    onClick={(e) => createVirtualEnv(e)}
                />
            </div>
        );
    };

    const onRemarkEdit = (e) => {
        setRemarks(e.target.value)
    }

    return (
        <div className='create-virtual-env-container'>
            <Toast ref={toast} position="top-center" />
            <div className="admin-panel-content">
                <div className="create-lab-button">
                    <Button
                        id="create-new-lab-button"
                        label="Create new Virtual Environment"
                        className="p-button-primary"
                        onClick={() => onClick("createVirtualEnvDisplay")}
                    />
                </div>
            </div>
            {/* create virtual env dialog */}
            <Dialog
                header="Create - Virtual Environment"
                visible={createVirtualEnvDisplay}
                footer={renderFooter("createVirtualEnvDisplay")}
                onHide={() => onHide("createVirtualEnvDisplay")}
                className="lab-dialog-box"
                style={{ width: "450px" }}
            >
                <div className="dialog-content">
                    <div ref={errorRef} className={commonErrorMsg ? "" : "hidden"}>
                        {commonErrorMsg && (
                            <Message
                                severity="warn"
                                text={commonErrorMsg}
                                className="common-inline-error common-error-message p-mb-3"
                            />
                        )}
                    </div>{" "}
                    <div className="first-section">
                        <div className="name-input-text">
                            <div className="namefield-label virtual-env-label">
                                Virtual Environment ID<span className="mandatory-label">*</span> :
                            </div>
                            <div>
                                <InputText
                                    name="virtualEnvId"
                                    value={virtualEnvId}
                                    maxLength={100}
                                    placeholder="Enter Virtual Environment ID"
                                    keyfilter="num"
                                    onChange={onVirtualEnvIdChange}
                                    className={virtualIdErrorMsg ? 'input-error-border-color namefield-text-box' : 'namefield-text-box'}
                                />
                                {virtualIdErrorMsg && (
                                    <small
                                        className="input-error-message p-d-block"
                                        id="error-message"
                                    >
                                        {virtualIdErrorMsg}
                                    </small>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="section third-section">
                        <div className="name-input-text">
                            <div className="namefield-label virtual-env-label">Static lab<span className="mandatory-label">*</span> :</div>
                            <InputSwitch
                                checked={isStatic}
                                onChange={onStaticChange}
                            />
                            <span className="virtual-env-toggle-label">
                                {isStatic ? "Yes" : "No"}
                            </span>
                        </div>
                    </div>
                    <div className="section third-section">
                        <div className="name-input-text">
                            <div className="namefield-label virtual-env-label">Status<span className="mandatory-label">*</span> :</div>
                            <InputSwitch
                                checked={isStatus}
                                onChange={onStatusChange}
                            />
                            <span className="virtual-env-toggle-label">
                                Virtual Environment is {isStatus? "Enabled" : "Disabled"}
                            </span>
                        </div>
                    </div>
                    <div className='section third-section'>
                        <div className='name-input-text'>
                            <div className="namefield-label virtual-env-label">Remarks<span className="optional-fields"> (Optional)</span>:</div>
                            <InputTextarea
                              style={{ width: '243px', height: '60px' }}
                              value={remarks}
                              onChange={(e) => onRemarkEdit(e)}
                              placeholder="Enter Remarks"
                              maxLength={500}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default CreateVirtualEnv