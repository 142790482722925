import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Message } from "primereact/message";
import { useRef, useState } from "react";
import loglevel from "../../../../logger/Logger";
import LabServices from "../../../../services/LabServices";


const ApproveLabRequest = ({ rowData, fetchData, showSuccessToast }) => {
    const [approveLabRequestDisplay, setApproveLabRequestDisplay] = useState(false);
    const approveLabRequestDialogMap = {
        approveLabRequestDisplay: setApproveLabRequestDisplay,
    };
    const [commonErrorMsg, setCommonErrorMsg] = useState("");
    const errorRef = useRef();
    const tableData = [{lab_name: rowData.lab_name, email: rowData.email}]

    //on opening approve lab request Dialog
    const onShow = (name) => {
        setCommonErrorMsg("");
        approveLabRequestDialogMap[`${name}`](true);
    };

    //on closing approve dialog
    const onHide = (name) => {
        approveLabRequestDialogMap[`${name}`](false);
        setCommonErrorMsg("");
    };

    const renderFooter = (name) => {
        return (
            <div className="delete-btn-section">
                {/* Approve button */}
                <Button
                    className="calender-button"
                    style={{ background: "#49AFD9" }}
                    onClick={(e) => onApprove(e)}
                >
                    <span className="name">Approve Lab Access Request</span>
                </Button>
                {/* Cancel button */}
                <Button
                    className="p-button-primary"
                    label="CANCEL"
                    onClick={() => onHide(name)}
                />
            </div>
        );
    };

    const refreshLabAccessList = () => fetchData()

    const showToast = (detail) => showSuccessToast(detail)

    const onApprove = (e) => {
        //api call for approving request
        LabServices.approveLabRequest(rowData.lab_request_id)
            .then((response) => {
                if (response.status == 200) {
                    approveLabRequestDialogMap["approveLabRequestDisplay"](false);
                    //Refresh Lab Access List after approve to show updated list
                    refreshLabAccessList()
                    //show toast message
                    showToast(`Lab Access Request submitted by ${rowData.email} has been approved successfully`)
                } else {
                    loglevel.error("Lab Access approval failed")
                }
            })
            .catch((error) => {
                setCommonErrorMsg(error.response.data.msg);
                errorRef.current.scrollIntoView({ behavior: "smooth" });
            });
    };

    return (
        <div>
            <Button
                icon="pi pi-check"
                className="p-button-rounded p-button-success ml-2"
                onClick={() => onShow("approveLabRequestDisplay")}
            />

            <Dialog
                header={`Approve Lab Access Request`}
                visible={approveLabRequestDisplay}
                footer={renderFooter("approveLabRequestDisplay")}
                onHide={() => onHide("approveLabRequestDisplay")}
                className="tag-delete-dialog-box"
            >
                <div ref={errorRef} className={commonErrorMsg ? "" : "hidden"}>
                    {commonErrorMsg && (
                        <Message
                            severity="warn"
                            text={commonErrorMsg}
                            className="common-inline-error common-error-message p-mb-3"
                        />
                    )}
                </div>{" "}

                <div>
                    Below are the details of the Lab Access Request you have selected to approve.
                </div>
                <br></br>
                <DataTable
                    value={tableData}
                    columnResizeMode="fit"
                    className="disable-lab-crud-table"
                    autoLayout={true}
                    responsiveLayout="scroll"
                >
                    <Column
                        field="email"
                        header="Email"
                        style={{ minWidth: "10rem", paddingLeft: "15px" }}
                    ></Column>
                    <Column
                        field="lab_name"
                        header="Lab Name"
                        style={{ minWidth: "10rem", paddingLeft: "15px" }}
                    ></Column>
                </DataTable>
                <br></br>
            </Dialog>
        </div>
    );
}

export default ApproveLabRequest;