import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import LabServices from "../../../../services/LabServices";
import "../../Rbac.css";
import UpdateServerModel from "./UpdateServerModel";
import CreateServerModel from "./CreateServerModel";
import DeleteServerModel from "./DeleteServerModel";
import loglevel from "../../../../logger/Logger";
import manufacturerIcon from "../../../../images/manufacturer_icon.svg"
import { Tag } from "primereact/tag";

const ServerModels = () => {
  const [models, setModels] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);

  // api to fetch the list of all server models
  useEffect(() => {
    async function fetchData() {
      await LabServices.getServerModelsList()
        .then((res) => {
          setModels(res.data);
        })
        .catch((error) => {
          loglevel.error("Error in get Server Model List", error);
        });
    }
    fetchData();
  }, []);

  const onIndexTemplate = (data, props) => {
    return <div className="asset-data-table">{props.rowIndex + 1}</div>;
  };

  const manufacturerTemplate = (rowData) => {
    return <Tag className="p-mr-2"><span className="cmdb-icon p-d-flex"><img className="p-mr-1" src={manufacturerIcon} />{rowData.manufacturer}</span></Tag>;
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="table-action-body usergroup-action-body">
        <div className="table-update-icon">
          <UpdateServerModel
            id={rowData.asset_server_model_id}
            serverName={rowData.name}
            setChanger={setModels}
          />
        </div>
        <div className="table-delete-icon">
          <DeleteServerModel
            serverId={rowData.asset_server_model_id}
            serverName={rowData.name}
            setChanger={setModels}
          />
        </div>
      </div>

    );
  };


  return (
    <div className="datatable-crud-demo">
      <div className="admin-panel">
        <div className="admin-panel-search-bar">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search"
              className="global-search-filter"
            />
          </span>
        </div>
        <CreateServerModel setChanger={setModels} />
      </div>
      <Toast ref={toast} position="top-center" />
      <DataTable
        value={models}
        ref={dt}
        className="asset-crud-table"
        columnResizeMode="fit"
        globalFilter={globalFilter}
        paginator
        rows={50}
        breakpoint="1024px"
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        responsiveLayout="scroll"
        autoLayout={true}
        let-i="rowIndex"
        style={{ width: "100%" }}
        removableSort={true}
        pageLinkSize={1}
      >
        <Column
          field="Index"
          header="#"
          body={onIndexTemplate}
          style={{ width: "3rem", paddingLeft: "9px", fontWeight: "600" }}
        ></Column>
        <Column
          sortable
          field="name"
          header="Model Name"
          style={{ minWidth: "10rem", fontWeight: "600" }}
        ></Column>
        <Column
          sortable
          field="description"
          header="Description"
          style={{ minWidth: "10rem" }}
        ></Column>
        <Column
          sortable
          field="model_no"
          header="Model No."
          style={{ minWidth: "8rem" }}
        ></Column>
        <Column
          sortable
          field="manufacturer"
          header="Manufacturer"
          style={{ minWidth: "9rem" }}
          body={manufacturerTemplate}
        ></Column>
        <Column
          body={actionBodyTemplate}
          exportable={false}
          style={{ minWidth: "5rem" }}
        ></Column>
      </DataTable>
    </div>
  );
};

export default ServerModels;
