import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import "../../rbac/Rbac.css";
import React, { useState, useRef } from "react";
import { Message } from "primereact/message";
import GuideService from "../../../services/GuideService";
import loglevel from "../../../logger/Logger";

const DeleteAdminGuides = ({ adminGuideId, guideName, setAlladminGuideDetails }) => {
  const [deleteAdminGuideDisplay, setDeleteAdminGuideDisplay] = useState(false);
  const deleteAdminGuideDialogMap = {
    deleteAdminGuideDisplay: setDeleteAdminGuideDisplay,
  };
  const [AdminGuideErrorMsg, setAdminGuideErrorMsg] = useState('');
  const toast = useRef(null);

  // opening dialog for deleting guide
  const onClick = async (name) => {
    setAdminGuideErrorMsg('');
    deleteAdminGuideDialogMap[`${name}`](true);
  };

  // hiding or closing dialog
  const onHide = (name) => {
    setAdminGuideErrorMsg('');
    deleteAdminGuideDialogMap[`${name}`](false);
  };

  // method called on deleting Guide
  const onDelete = async (e) => {
    await GuideService.deleteAdminGuide(adminGuideId)
      .then((response) => {
        if (response.status === 200) {
          // toast message after successful deletion
          toast.current.show({
            severity: "success",
            summary: "Admin Guide Successfully Deleted",
            detail: `The admin guide named "${guideName}" has been deleted successfully.`,
            life: 6000,
          });
          deleteAdminGuideDialogMap["deleteAdminGuideDisplay"](false);
        } else {
          loglevel.debug("admin guide deletion has failed");
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setAdminGuideErrorMsg(error.response.data.msg);
        }
      });

      //api call for refreshing list of guides
    await GuideService.getAdminGuideList().then((res) => {
      for (let i = 0; i < res.data.length; i++) {
        res.data[i]["closedStatus"] = true;
        res.data[i]["closedGroupsStatus"] = true;

      };
      setAlladminGuideDetails(res.data);
    });
  };

  const renderFooter = (name) => {
    return (
      <div className="disable-footer-button">
      
      {/* Delete button */}
        <Button
            label="DELETE ADMIN GUIDE"
            className={
              "p-button-outlined p-button-danger"
            }
            onClick={() => onDelete("deleteAdminGuideDisplay")}
           
          />
        <div>
          {" "}
          {/* cancel button */}
          <Button
            label="CANCEL"
            onClick={() => onHide('deleteAdminGuideDisplay')}
            className="p-button-outlined p-button-primary"
          />
        </div>
      </div>
    );
  };

  return (
    <div className="lab-type-edit">
      <Toast ref={toast} position="top-center" />
      {/* Delete icon on main listing page */}
      <Button
        icon="pi pi-trash"
        className="p-button-rounded global-delete-button"
        onClick={() => onClick("deleteAdminGuideDisplay")}
      />
      <Dialog
        header={`Delete Admin Guide - ${guideName}`}
        visible={deleteAdminGuideDisplay}
        footer={renderFooter("deleteAdminGuideDisplay")}
        onHide={() => onHide("deleteAdminGuideDisplay")}
        className="lab-type-delete-dialog-box"
      >
        <div>
          {AdminGuideErrorMsg && <Message severity="error" text={AdminGuideErrorMsg} className='common-inline-error common-inline-error p-mt-1 p-mb-3 full-width' />}
        </div>
        {
          (
            <div style={{ width: "365px" }}>
              <div>Do you want to delete this admin guide?</div>
            </div>
          )
        }
      </Dialog>
    </div>
  );
};

export default DeleteAdminGuides;
