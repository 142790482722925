import React, { useState, useEffect, useRef } from "react";
import "../../Rbac.css";
import LabServices from "../../../../services/LabServices";
import loglevel from "../../../../logger/Logger";
import LoadingSpinner from "../../../loader/LoadingSpinner";
import { Paginator } from "primereact/paginator";
import ActiveLabCard from "./ActiveLabCard";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import eventBus from "../../../utility/EventBus";

const ActiveLabsTab = () => {
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(12);
    const toast = useRef(null);

    const [selectedLabValue, setSelectedLabValue] = useState("");
    const [selectedLab, setSelectedLab] = useState("");
    const [labTooltip, setLabTooltip] = useState(false);

    const [searchField, setSearchField] = useState("");
    const [searchValue, setSearchValue] = useState("");

    const [searchStatus, setSearchStatus] = useState("");
    const [selectedStatus, setSelectedStatus] = useState("");
    const [statusTooltip, setStatusTooltip] = useState(false);

    const indexOfLastItem = itemsPerPage + currentPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    // sorting dropdown in card and list view
    const sortOption = [
        { name: "Recently Booked", value: "createdAt" },
        { name: "Name(A-Z)", value: "name" },
        { name: "Name(Z-A)", value: "reverse" },
        { name: "Booking Date", value: "scheduled_start" },
    ];
    const [sortType, setSortType] = useState("createdAt");

    const labs = [];
    const labStatus = ["Active", "Scheduled"];
    details.map((lab) => {
        if (!labs.includes(lab.name)) {
            return labs.push(lab.name);
        }
        return 0;
    })

    async function fetchData() {
        setLoading(true)
        await LabServices.getActiveLabsList()
            .then((res) => {
                loglevel.debug("getActiveLabsList Response", res)
                const labs = res.data
                setLoading(false)
                setDetails(labs)
            })
            .catch((error) => {
                loglevel.error("getActiveLabsList", error);
            });
    }

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        eventBus.on(
            "sharedLabUserCount",
            async () =>
                await LabServices.getActiveLabsList()
                    .then((res) => {
                        loglevel.debug("getActiveLabsList Response", res)
                        const labs = res.data
                        setDetails(labs)
                    })
                    .catch((error) => {
                        loglevel.error("getActiveLabsList", error);
                    })
        );
    }, []);

    //method to show success toast message
    const showSuccessToast = (detail) => {
        toast.current.show({
            severity: "success",
            detail: detail,
            life: 6000,
            style: {
                fontSize: "1rem",
                letterSpacing: "2px",
                wordSpacing: "2px",
            }
        });
    }

    //method to show success toast message
    const showFailureToast = (error) => {
        toast.current.show({
            severity: "error",
            detail: `${error.response.data.msg}`,
            life: 6000,
        });
    }

    //On input in search bar for name & description
    const handleChange = (e) => {
        setSearchValue(e.target.value);
        setSearchField(e.target.value);
    };

    //On changing lab in search
    const onLabChange = (e) => {
        setSelectedLabValue(e.target.value);
        setSelectedLab(e.value);
        e.target.value.length > 3 ? setLabTooltip(true) : setLabTooltip(false);
    }

    //On changing status in search
    const onStatusChange = (e) => {
        setSearchStatus(e.value);
        setSelectedStatus(e.value);
        e.target.value.length > 3
            ? setStatusTooltip(true)
            : setStatusTooltip(false);
    };

    //On reset in search bar
    const resetHandle = (e) => {
        setSearchField("");
        setSearchValue("");
        setSelectedLab("");
        setSelectedLabValue("");
        setSearchStatus("");
        setSelectedStatus("");
    };

    //On changing page from paginator
    const onBasicPageChange = (event) => {
        setCurrentPage(event.first);
        setItemsPerPage(event.rows);
    };

    const filteredLabs = details
        .filter((lab) => {
            if (
                searchField !== "" &&
                selectedLab.length === 0 &&
                selectedStatus.length === 0
            ) { // If Searching Labs by Name and description only
                return (
                    lab.name.toLowerCase().includes(searchField.toLowerCase()) ||
                    lab.description.toLowerCase().includes(searchField.toLowerCase())
                );
            } else if (
                searchField === "" &&
                selectedLab.length > 0 &&
                selectedStatus.length === 0
            ) { // Searching lab by dropdown name only
                return selectedLab.includes(lab.name);
            } else if (
                searchField === "" &&
                selectedLab.length === 0 &&
                selectedStatus.length > 0
            ) { // Searching by status only
                return selectedStatus.includes(lab.status);
            } else if (
                searchField !== "" &&
                selectedLab.length > 0 &&
                selectedStatus.length === 0
            ) { // Searching Labs by name and dropdown name in combination
                return (
                    (
                        lab.name.toLowerCase().includes(searchField.toLowerCase()) ||
                        lab.description.toLowerCase().indexOf(searchField) > -1
                    ) &&
                    selectedLab.includes(lab.name)
                );
            } else if (
                searchField === "" &&
                selectedLab.length > 0 &&
                selectedStatus.length > 0
            ) { // Searching labs by dropdown name and status
                return (
                    selectedLab.includes(lab.name) &&
                    selectedStatus.includes(lab.status)
                );
            } else if (
                searchField !== "" &&
                selectedLab.length === 0 &&
                selectedStatus.length > 0
            ) { // Searching labs by name, description & status
                return (
                    (
                        lab.name.toLowerCase().includes(searchField.toLowerCase()) ||
                        lab.description.toLowerCase().indexOf(searchField) > -1
                    ) &&
                    selectedStatus.includes(lab.status)
                );
            } else if (
                searchField !== "" &&
                selectedLab.length > 0 &&
                selectedStatus.length > 0
            ) { // Searching labs by name, dropdown name and status all in combination
                return (
                    (
                        lab.name.toLowerCase().includes(searchField.toLowerCase()) ||
                        lab.description.toLowerCase().indexOf(searchField) > -1
                    ) &&
                    selectedLab.includes(lab.name) &&
                    selectedStatus.includes(lab.status)
                );
            } else {
                return lab.name.toLowerCase().includes(searchField.toLowerCase());
            }
        })
        .sort((a, b) => { // Sorting on the basis of recently added
            if (sortType === "createdAt") {
                let da = new Date(a.createdAt).getTime();
                let db = new Date(b.createdAt).getTime();

                if (da < db) {
                    return 1;
                }
                if (da > db) {
                    return -1;
                }
                return 0;
            }
            if (sortType === "scheduled_start") { //Sorting on the basis of scheduled start date

                let da = new Date(a.scheduled_start).getTime();
                let db = new Date(b.scheduled_start).getTime();

                if (da < db) {
                    return -1;
                }
                if (da > db) {
                    return 1;
                }
                return 0;
            }
            if (sortType === "name") { //Sorting on the basis of name ( A-Z)

                let fa = a.name.toLowerCase(),
                    fb = b.name.toLowerCase();
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            }
            if (sortType === "reverse") { //Sorting on the basis of name ( Z-A)

                let fa = a.name.toLowerCase(),
                    fb = b.name.toLowerCase();
                if (fa > fb) {
                    return -1;
                }
                if (fa < fb) {
                    return 1;
                }
                return 0;
            }
            return 0;
        });

    const currentItems = filteredLabs.slice(indexOfFirstItem, indexOfLastItem);

    const filtered = currentItems.map((lab) => {
        return (
            <ActiveLabCard
                key={lab.schedule_id}
                lab={lab}
                fetchData={fetchData}
                showSuccessToast={showSuccessToast}
                showFailureToast={showFailureToast}
            />
        )
    })

    return (
        <section>
            <Toast ref={toast} position="top-center" />
            {loading ? (
                <div className="loader">
                    <LoadingSpinner />
                </div>
            ) : (
                <div>
                    <br />
                    {/* Top search bar panel */}
                    <div className="panel-content">
                        <div className="panel">
                            {/* Input area to search labs on the basis of name & description */}
                            <div className="panel-item">
                                <span className="p-input-icon-left">
                                    <i className="pi pi-search" />
                                    <InputText
                                        value={searchValue}
                                        name="search-input"
                                        onChange={handleChange}
                                        placeholder="Search by Name or Description"
                                        className="searchbar-category-dropdown"
                                        id="searchField"
                                    />
                                </span>
                            </div>
                            {/* Dropdown to search labs on the basis of labs */}
                            <div className="panel-item">
                                <MultiSelect
                                    tooltip={labTooltip ? selectedLabValue : ""}
                                    tooltipOptions={{ position: "top" }}
                                    showSelectAll={false}
                                    value={selectedLabValue}
                                    name="search-name-dropdown"
                                    id="reserved-lab-cat-dropdown"
                                    options={labs}
                                    onChange={onLabChange}
                                    placeholder="Select Lab"
                                    className="searchbar-category-dropdown"
                                    display="chip"
                                />
                            </div>
                            {/* Dropdown to search labs on the basis of lab schedule status */}
                            <div className="panel-item">
                                <MultiSelect
                                    tooltip={statusTooltip ? searchStatus : ""}
                                    tooltipOptions={{ position: "top" }}
                                    showSelectAll={false}
                                    value={searchStatus}
                                    id="reserved-lab-status-dropdown"
                                    name="search-status-dropdown"
                                    options={labStatus}
                                    onChange={onStatusChange}
                                    placeholder="Select Status"
                                    className="category-dropdown"
                                    display="chip"
                                />
                            </div>
                            {/* Reset button to reset all search fields */}
                            <div className="reset-button reserved-reset-btn">
                                <Button
                                    id="resetBtn"
                                    label="RESET"
                                    className="p-button-primary"
                                    onClick={resetHandle}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="tabmenuactivelabs">
                        <div>
                            <span className="result-count">
                                Showing {currentItems.length} of {filteredLabs.length} results
                            </span>
                        </div>
                        <div >
                            <div className="sortMsg">Sort By: </div>
                            <div className="sortMsg pointer custom-basic-dropdown">
                                <Dropdown
                                    id="sort-dropdown"
                                    name="sort-options"
                                    value={sortType}
                                    options={sortOption}
                                    onChange={(e) => {
                                        setSortType(e.target.value); // setting sorting type
                                    }}
                                    optionLabel="name"
                                    placeholder="Recently Booked"
                                />
                            </div>
                        </div>
                    </div>

                    {/* <Divider /> */}

                    <br />
                    <div className="p-grid">
                        {filtered.length === 0 ? (
                            //Active labs tab
                            <div className="no-lab-scheduled-icon">
                                <div>
                                    <svg
                                        width="44"
                                        height="51"
                                        viewBox="0 0 44 51"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M21.6556 49.8753C20.9977 49.8654 20.3548 49.8613 19.725 49.8315L19.687 50.6404C20.33 50.671 20.9845 50.6817 21.6564 50.685C22.1459 50.685 22.6296 50.6759 23.1042 50.6586L23.0736 49.8481C22.6081 49.8654 22.136 49.8753 21.6556 49.8753Z"
                                            fill="#66B0D6"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M20.4979 42.6001C19.3284 42.6315 18.1802 42.7124 17.0874 42.8419L17.1815 43.6459C18.2504 43.5196 19.3746 43.4404 20.5186 43.4098L20.4979 42.6001Z"
                                            fill="#66B0D6"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M9.85219 46.6353C9.85219 46.5495 9.86788 46.4645 9.89759 46.3811L9.13492 46.1079C9.07385 46.2788 9.04248 46.4562 9.04248 46.6353C9.04248 47.5334 9.79524 48.3324 11.2785 49.0075L11.6136 48.2704C10.4787 47.7538 9.85219 47.1718 9.85219 46.6353Z"
                                            fill="#66B0D6"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M14.5483 43.252C13.2326 43.5285 12.1192 43.8751 11.2368 44.282L11.5752 45.0175C12.4023 44.6361 13.4588 44.3093 14.7142 44.0443L14.5483 43.252Z"
                                            fill="#66B0D6"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M13.7495 49.8364C14.7722 50.0906 15.9096 50.2912 17.1312 50.434L17.2236 49.6292C16.0367 49.4906 14.9348 49.2958 13.9443 49.0498L13.7495 49.8364Z"
                                            fill="#66B0D6"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M32.2958 44.4455C31.4465 44.0081 30.3388 43.6325 29.0033 43.3296L28.8242 44.1187C30.0747 44.4026 31.1469 44.765 31.9244 45.1653L32.2958 44.4455Z"
                                            fill="#66B0D6"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M26.4669 42.8862C25.3807 42.745 24.2334 42.6526 23.0588 42.6113L23.0308 43.4202C24.1797 43.4615 25.3014 43.5523 26.3629 43.6901L26.4669 42.8862Z"
                                            fill="#66B0D6"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M33.3422 46.635C33.3422 47.2244 32.5713 47.8748 31.2275 48.4212L31.5338 49.1706C33.6988 48.2924 34.1519 47.3061 34.1519 46.635L34.1503 46.5698L33.3422 46.635Z"
                                            fill="#66B0D6"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M25.5752 49.6725L25.661 50.4781C26.876 50.3502 28.0151 50.1661 29.0468 49.93L28.8661 49.1401C27.8657 49.3688 26.7596 49.5487 25.5752 49.6725Z"
                                            fill="#66B0D6"
                                        />
                                        <mask
                                            id="mask0_13387_14263"
                                            style={{ "maskType": "alpha" }}
                                            maskUnits="userSpaceOnUse"
                                            x="0"
                                            y="0"
                                            width="44"
                                            height="39"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M0 0H43.4363V38.8761H0V0Z"
                                                fill="white"
                                            />
                                        </mask>
                                        <g mask="url(#mask0_13387_14263)">
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M36.8754 8.35797C32.8368 9.35505 27.4543 9.90476 21.7187 9.90476C15.983 9.90476 10.5997 9.35505 6.56108 8.35797C2.03708 7.24038 0.830349 5.95029 0.830349 5.36508C0.830349 4.77987 2.03708 3.4906 6.56108 2.37302C10.5997 1.37511 15.983 0.825397 21.7187 0.825397C27.4543 0.825397 32.8368 1.37511 36.8754 2.37302C41.3994 3.4906 42.6062 4.77987 42.6062 5.36508C42.6062 5.95029 41.3994 7.24038 36.8754 8.35797ZM23.7987 24.4937V36.1961C23.5164 36.6699 22.8759 37.2039 21.9217 37.6092C20.8297 38.0723 20.0159 38.0855 19.6717 37.979V24.4937L3.31232 8.21187C7.15289 9.724 13.9583 10.7302 21.7187 10.7302C29.5145 10.7302 36.348 9.71492 40.1787 8.19124L23.7987 24.4937ZM43.4365 5.36508C43.4365 2.4019 33.7133 0 21.7187 0C9.72317 0 0 2.4019 0 5.36508C0 5.92387 0.346667 6.46203 0.987175 6.968L0.986349 6.96883L18.8463 24.8238V38.4098C19.1013 38.7359 19.566 38.8762 20.1248 38.8762C20.767 38.8762 21.5338 38.6905 22.2461 38.3884C23.419 37.8898 24.4672 37.0669 24.6224 36.2729V24.8238L42.4848 6.96883L42.4683 6.95314C43.0973 6.4513 43.4365 5.9181 43.4365 5.36508Z"
                                                fill="#66B0D6"
                                            />
                                        </g>
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M6.56115 2.37265C2.03715 3.49023 0.82959 4.7795 0.82959 5.36471C0.82959 5.95074 2.03715 7.24001 6.56115 8.3576C9.14794 8.99646 12.2894 9.4496 15.7197 9.69392L12.7574 1.31201C10.4834 1.57036 8.38032 1.92363 6.56115 2.37265Z"
                                            fill="#66B0D6"
                                            fillOpacity="0.4"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M27.5003 10.5367L22.2178 24.4867V37.4628C23.0011 37.0798 23.5483 36.6168 23.7992 36.1958V24.4933L40.1784 8.19092C37.3316 9.32336 32.8258 10.1743 27.5003 10.5367Z"
                                            fill="#66B0D6"
                                            fillOpacity="0.4"
                                        />
                                    </svg>
                                </div>
                                {/* If Active Labs tab contain no labs  */}
                                <div className="lab-scheduled-msg"> We couldn't find any lab scheduled!</div>
                            </div>
                        ) : (
                            // Render the filtered list of Labs
                            filtered
                        )}
                    </div>

                    <br />
                    {/* //Paginator */}
                    <div className="card">
                        <Paginator
                            className="paginator"
                            first={currentPage}
                            rows={itemsPerPage}
                            totalRecords={filteredLabs.length}
                            pageLinkSize={1}
                            onPageChange={onBasicPageChange}
                            rowsPerPageOptions={[3, 6, 9, 12, 15]}
                        ></Paginator>
                    </div>
                </div>
            )}
        </section>
    );
}

export default ActiveLabsTab;