import http from "../http-comman";

class AuthenticationService {
    login(requestPayload) {
        return http.post("/login",requestPayload);
    }

    // request portal access
    requestPortalAccess(requestPayload) {
        return http.post("user/access/request", requestPayload);
    }

    getPortalUserCount() {
        return http.put(`/user/portal/visited?users_id=${localStorage.getItem("users_id")}`)
    }

    resetPassword(requestPayload) {
        return http.put(`/login/resetpassword`, requestPayload)
    }

    ssoLogin() {
        return http.get("/login/sso");
    }

    ssoLoginBcom() {
        return http.get("/login/sso/bcom");
    }

    validateToken(token) {
        return http.get("/login/validate-token", token);
    }
    verifyCapcha(response) {
        return http.post(`/captcha/verify?response=${response}`)
    }
}

export default new AuthenticationService();