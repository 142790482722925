import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useState, useRef, useEffect } from "react";
import "../../Rbac.css";
import LabServices from "../../../../services/LabServices";
import loglevel from "../../../../logger/Logger";
import eventBus from "../../../utility/EventBus";
import { Message } from "primereact/message";
import { Dropdown } from "primereact/dropdown";

const CreateLabCategory = ({ setChanger }) => {
  const [createLabDisplay, setcreateLabDisplay] = useState(false);
  const [cname, setCname] = useState("");
  const [description, setDescription] = useState("");
  const [tooltip, setTooltip] = useState("");
  const [enabled, setEnabled] = useState(true);
  const [isValidated, setIsValidated] = useState(false);
  const [commonErrorMsg, setCommonErrorMsg] = useState("");
  const [parentCategory, setParentCategory] = useState({});
  const [parentCategoryList, setParentCategoryList] = useState([]);
  const toast = useRef(null);
  const errorRef = useRef();

  const createLabDialogMap = {
    createLabDisplay: setcreateLabDisplay,
  };

  const updateSideBar = () => {
    eventBus.dispatch("labCreated", { message: "labCreated" });
  };


  useEffect(async() => {
    await LabServices.getParenCategoryList()
    .then((res) => {
      setParentCategoryList(res.data)
      
    });
  },[])

  const onClick = async(name) => {
    await LabServices.getParenCategoryList()
    .then((res) => {
      setParentCategoryList(res.data)
      
    });
    setCname("");
    setDescription("");
    setEnabled(true);
    setParentCategory({})
    setTooltip("");
    setCommonErrorMsg("");
    createLabDialogMap[`${name}`](true);
  };

  const onHide = (name) => {
    setCname("");
    setDescription("");
    setParentCategory({})
    setEnabled(true);
    setTooltip("");
    setIsValidated(false);
    setCommonErrorMsg("");
    createLabDialogMap[`${name}`](false);
  };

  const onShowDialog = (name) => {
    setCname("");
    setDescription("");
    setEnabled(true);
    setTooltip("");
    setIsValidated(false);
    setCommonErrorMsg("");
    setParentCategory({})
    createLabDialogMap[`${name}`](true);
  };

  const addCategory = async (e) => {
    setIsValidated(true);
    <Toast ref={toast} position="top-center" />;
    if (cname && description) {
      const requestBody = {
        name: `${cname}`,
        description: `${description}`,
        parent_category:Object.keys(parentCategory).length>0?parseInt(parentCategory.categoryId):null,
        tooltip: `${tooltip}`,
        enabled: enabled,
      };

      await LabServices.addCategory(requestBody)
        .then((response) => {
          loglevel.debug("addCategory Response", response);
          if (response.status === 201) {
            toast.current.show({
              severity: "success",
              summary: "Lab Category Successfully Added",
              detail: "The lab category has been successfully added.",
              life: 6000,
            });
            createLabDialogMap["createLabDisplay"](false);
            updateSideBar();
          } else {
            loglevel.debug("addCategory is Failed");
          }
        })
        .catch((error) => {
          loglevel.error("addCategory", error.response.data);
          if (error.response.data.statusCode === 400) {
            setCommonErrorMsg(error.response.data.msg);
            errorRef.current.scrollIntoView({ behavior: "smooth" });
          }
        });

      await LabServices.getLabCategoryList()
        .then((res) => {
          setChanger(res.data);
        })
        .catch((error) => {
          loglevel.error("getCategoryList", error);
        });
    }
  };

  const renderFooter = (name) => {
    return (
      <div className="lab-btn-section">
        <Button
          onClick={(e) => addCategory(e)}
          style={{ background: "#49AFD9" }}
        >
          <span className="name">Add category</span>
        </Button>
      </div>
    );
  };

  return (
    <div className="admin-panel-content">
      <Toast ref={toast} position="top-center" />
      <div className="create-lab-button">
        <Button
          id="reset-button"
          label="CREATE NEW CATEGORY"
          className="p-button-primary"
          onClick={() => onClick("createLabDisplay")}
        />
      </div>
      <Dialog
        header="Add New Category"
        visible={createLabDisplay}
        onHide={() => onHide("createLabDisplay")}
        className="lab-dialog-box"
        footer={renderFooter("createLabDisplay")}
        onShow={() => onShowDialog("createLabDisplay")}
        style={{ width: "700px" }}
      >
        <div ref={errorRef} className={commonErrorMsg ? "" : "hidden"}>
          {commonErrorMsg && (
            <Message
              severity="warn"
              text={commonErrorMsg}
              className="common-inline-error common-error-message p-mb-3"
            />
          )}
        </div>{" "}
        <div className="dialog-content">
          <div className="name-input">
            <div className="cat-name-label">
              Name:
              <span className="cat-required-field">*</span>
            </div>
            <div>
              <InputText
                value={cname}
                onChange={(e) => setCname(e.target.value)}
                placeholder="Enter Name"
                maxLength={20}
                className={
                  !cname && isValidated
                    ? "cat-input-error"
                    : "cat-name-text-box"
                }
              />
              {!cname && isValidated ? (
                <div className="cat-error-message-inline">
                  <small className="cat-error-message" id="error-message">
                    Category name is mandatory
                  </small>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="cat-description">
            <div className="cat-description-label">
              Description:
              <span className="cat-required-field">*</span>
            </div>
            <div>
              <InputTextarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter Category Description"
                autoResize={true}
                maxLength={1000}
                rows={2}
                cols={40}
                className={
                  !description && isValidated
                    ? "desc-input-error"
                    : "cat-desc-box"
                }
              />
              {!description && isValidated ? (
                <div>
                  <small className="cat-error-message" id="error-message">
                    Category description is mandatory
                  </small>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="name-input">
            <div className="cat-name-label">
              Parent Category :
              
            </div>
            <div>
              <Dropdown
                value={parentCategory}
                onChange={(e) => setParentCategory(e.target.value)}
                placeholder="Select Parent Category"
                options={parentCategoryList}
                optionLabel="name"
                className="cat-name-text-box"
                filter
                filterBy="name"
              />
              
            </div>
          </div>
          <div className="name-input">
            <div className="cat-name-label">
              Tooltip (<span className="optional-label">Optional</span>) :
            </div>
            <InputText
              value={tooltip}
              onChange={(e) => setTooltip(e.target.value)}
              placeholder="Enter Tooltip"
              maxLength={30}
              className="cat-name-text-box"
            />
          </div>
          <div className="category-enable">
            <div className="enable-label">Status:</div>
            <InputSwitch
              className="category-enable-switch"
              checked={enabled}
              onChange={(e) => setEnabled(e.value)}
            />
            <div className="cat-enabled-text">
              {enabled ? `Category is Enable` : `Category is Disable`}
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default CreateLabCategory;
