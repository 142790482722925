import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import moment from 'moment'
import { WARNING_TIMEOUT, LOGOUT_TIMEOUT } from "../../config/configuration";
import IdleTimeOutModal from './IdleTimeOutModal'
import loglevel from '../../logger/Logger';
import AuthenticationService from '../../services/AuthenticationService';

const IdleTimeOutHandler = (props) => {
    loglevel.debug("Entering into IdleTimeOutHandler Component");
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false)
    const [isLogout, setLogout] = useState(false)
    let timer;
    let logoutTimer;
    const events = ['click', 'scroll', 'load', 'keydown', 'mousemove']
    const eventHandler = (eventType) => {
        if (!isLogout) {
            localStorage.setItem('lastInteractionTime', moment())
            if (timer) {
                props.onActive();
                startTimer();
            }
        }
    };
    const startTimer = () => {
        loglevel.debug("Entering into IdleTimeOutHandler Component: startTimer method");
        if (timer) {
            clearTimeout(timer)
            clearTimeout(logoutTimer);
        }
        timer = setTimeout(() => {
            let lastInteractionTime = localStorage.getItem('lastInteractionTime')
            const diff = moment.duration(moment().diff(moment(lastInteractionTime)));
            let timeOutInterval = WARNING_TIMEOUT;
            if (isLogout) {
                clearTimeout(timer)
            } else {
                if (diff._milliseconds < timeOutInterval) {
                    startTimer();
                    props.onActive();
                } else {
                    props.onIdle();
                    setShowModal(true)
                    logoutTimer = setTimeout(async () => {
                        localStorage.removeItem("isAuthenticated");
                        localStorage.removeItem("accessToken");
                        localStorage.removeItem("expiresIn");
                        localStorage.removeItem("users_id");
                        localStorage.removeItem("lname");
                        localStorage.removeItem("lastInteractionTime");
                        localStorage.removeItem("fname");
                        localStorage.removeItem("loglevel");
                        localStorage.removeItem("email");
                        localStorage.removeItem('isSSOLogin');
                        setShowModal(false);
                        navigate("/");
                        // if (localStorage.getItem('isSSOLogin')) {
                        //     //Calling SSO Logout API
                        //     const requestBody = {
                        //         email: localStorage.getItem("email"),
                        //     }
                        //     await AuthenticationService.logout(requestBody).then((res) => {
                        //         console.log("Logout IDP URL:", JSON.stringify(res))
                        //         if (res.status === 200) {
                        //             localStorage.removeItem("email");
                        //             localStorage.removeItem('isSSOLogin');
                        //             window.location.assign(res.data.context);
                        //         }
                        //     })
                        // } else {
                        //     navigate(0);
                        // }
                    }, LOGOUT_TIMEOUT - WARNING_TIMEOUT);
                }
            }
        }, WARNING_TIMEOUT)
    }
    const addEvents = () => {
        events.forEach(eventName => {
            window.addEventListener(eventName, eventHandler)
        })
        startTimer();
    }

    const removeEvents = () => {
        events.forEach(eventName => {
            window.removeEventListener(eventName, eventHandler)
        })
    };
    useEffect(() => {
        addEvents();
        return (() => {
            removeEvents();
            clearTimeout(timer);
            clearTimeout(logoutTimer);
        })
    })
    const handleContinueSession = () => {
        setShowModal(false)
        setLogout(false)
    }

    const hide = () => {
        setShowModal(false)
    }

    return (
        <div className='idle-timeout-popup'>
            <IdleTimeOutModal
                showModal={showModal}
                handleContinue={handleContinueSession}
                handleLogout={hide}
            />
        </div>
    )

}

export default IdleTimeOutHandler;