import React, { useState } from 'react'
import './PortalPolicy.css'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';


const Terms = () => {
    const [displayTerms, setDisplayTerms] = useState(false);
    const dialogFuncMap = {
        'displayTerms': setDisplayTerms
    }
    // to ope policies dialog box
    const openTermsDialog = (name) => {
        dialogFuncMap[`${name}`](true);
    }

     // to close policies dialog box
    const closeTermsDialog = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const TermsFooter = (name) => {
        return (
            <div>
                {/* close button */}
                <Button label="CLOSE" onClick={() => closeTermsDialog('displayTerms')} className="terms-close-btn" />
            </div>
        );
    }
    return (
        <>
            <a className='portal-policy-link' onClick={() => openTermsDialog('displayTerms')}>Portal Usage Policy</a>
            <Dialog header="Terms of Use" visible={displayTerms} style={{ width: '50vw' }} footer={TermsFooter('displayTerms')} onHide={() => closeTermsDialog('displayTerms')} className='terms-dialog'>
                <div className='reg-checkbox-label'>I have read and agree with the following:</div>
                {/* vmware policies */}
                <div className='policy-container mg-t-15'>
                    <a href="https://www.vmware.com/help/legal.html" target="_blank" className='portal-policy-link text-no-decoration'>Terms of Use</a>
                    <a href="https://www.vmware.com/help/privacy/california-privacy-rights.html" target="_blank" className='portal-policy-link text-no-decoration'>Your California Privacy Rights</a>
                    <a href="https://www.vmware.com/help/privacy.html" target="_blank" className='portal-policy-link text-no-decoration'>Privacy</a>
                </div>
            </Dialog>
        </>
    )
}

export default Terms
