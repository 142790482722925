import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import LabServices from "../../../../services/LabServices";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import "../../Rbac.css";
import "./UserTags.css"
import UpdateUserTag from "./UpdateUserTag";
import CreateUserTag from "./CreateUserTag";
import DeleteUserTag from "./DeleteUserTag";
import loglevel from "../../../../logger/Logger";

const UserTags = () => {

  const [userTag, setUserTag] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    async function fetchData() {
      //api call for user tag list
      await LabServices.getUserTagList().then((res) => {
        setUserTag(res.data)
      })
        .catch((error) => {
          loglevel.error("getuserTagList", error);
        });
    }
    fetchData();
  }, []);

  // index column in datatable
  const onIndexTemplate = (data, props) => {
    return (
      <div className="cat-name-table">
        {props.rowIndex + 1}
      </div>)
  }

  // labname in datatable
  const labnameTemplate = (rowData) => {
    return (
      <Tag className="user-tag" >
        <span className="user-tag-icon">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.05238 5.48517L8.2859 5.25165C9.01146 4.52997 9.01478 3.3568 8.29331 2.63102L6.33248 0.681306C5.60875 -0.042206 4.43558 -0.042206 3.71185 0.681306L0.542634 3.85052C-0.180878 4.57425 -0.180878 5.74742 0.542634 6.47115L2.49235 8.42087C3.21608 9.14438 4.38925 9.14438 5.11298 8.42087L5.26124 8.2726L6.0693 9.07324H7.3444V10.1852H8.71217V10.7894L9.77599 11.8607H12V9.43279L8.05238 5.48517ZM11.2587 11.1194H10.0948L9.4387 10.467V9.42914H8.07464V8.31713H6.36586L5.25385 7.20513L4.58665 7.89828C4.15249 8.33189 3.44917 8.33189 3.01501 7.89828L1.0653 5.93003C0.631683 5.49587 0.631683 4.79254 1.0653 4.35839L4.23822 1.18547C4.67237 0.751855 5.3757 0.751855 5.80985 1.18547L7.75957 3.13519C8.19318 3.56934 8.19318 4.27267 7.75957 4.70682L7.01823 5.44816L11.2587 9.7405V11.1194ZM2.26942 4.30578C2.08341 4.49179 2.08341 4.79337 2.26942 4.97938C2.45543 5.16539 2.75701 5.16539 2.94302 4.97938L4.81443 3.10797C5.00044 2.92196 5.00044 2.62038 4.81443 2.43437C4.62842 2.24836 4.32684 2.24836 4.14083 2.43437L2.26942 4.30578Z" fill="black" />
          </svg>
        </span>
        {rowData.name}
      </Tag>)
  }

  // description column in datatable
  const descriptionTemplate = (rowData) => {
    return (
      <div className="tag-data-short-desc-table">{(rowData.description === null) ? `The ${rowData.name} tag was created during Lab creation.` : rowData.description}</div>
    );
  };


  const actionBodyTemplate = (rowData) => {
    return (
      <div className="table-action-body">
        <div className="table-update-icon">
          {/* Update User Tag component */}
          <UpdateUserTag userTagName={rowData.name} userTagDescription={rowData.description} setChanger={setUserTag} />
        </div>
        <div className="table-delete-icon">
          {/* Delete User Tag component */}
          <DeleteUserTag userTagName={rowData.name} setChanger={setUserTag} />
        </div>
      </div>
    );
  };

  return (
    <div className="datatable-crud-demo">
      <div className="admin-panel">
        <div className="admin-panel-search-bar">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            {/* Search bar */}
            <InputText
              type="search"
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search"
              className="global-search-filter"
            />
          </span>
        </div>
        {/* Create user Tag Component */}
        <CreateUserTag setChanger={setUserTag} />
      </div>
      <Toast ref={toast} position="top-center" />
      {/* User tag list */}
      <DataTable
        value={userTag}
        ref={dt}
        className="lab-crud-table"
        columnResizeMode="fit"
        paginator
        rows={50}
        pageLinkSize={1}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        globalFilter={globalFilter}
        responsiveLayout="scroll"
        autoLayout={true}
        removableSort={true}
        let-i="rowIndex"
        style={{ width: "100%" }}
      >
        <Column
          field="Index"
          header="#"
          body={onIndexTemplate}
          style={{ width: "3rem", paddingLeft: "15px" }}
        ></Column>
        <Column
          sortable
          field="name"
          header="Tag Name"
          body={labnameTemplate}
          style={{ minWidth: "9rem" }}>
        </Column>
        <Column
          sortable
          field="description"
          header="Description"
          body={descriptionTemplate}
          style={{ minWidth: "12rem" }}>
        </Column>
        <Column
          body={actionBodyTemplate}
          exportable={false}
          style={{ minWidth: "4rem" }}
        ></Column>
      </DataTable>
    </div>
  );
};

export default UserTags;
