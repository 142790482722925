import React, { useEffect, useState,useRef } from "react";
import "primeflex/primeflex.css";
import "./Sidebar.css";
import { NavLink } from "react-router-dom";
import { Accordion, AccordionTab } from 'primereact/accordion';
import LabServices from "../../services/LabServices";
import { Tooltip } from 'primereact/tooltip';
import loglevel from "../../logger/Logger";
import eventBus from "../utility/EventBus";
import { Toast } from "primereact/toast";
import TreeSidebar from "./TreeSidebar"
import { Badge } from 'primereact/badge';
        

import { useGlobalState } from '../../config/state';

const Sidebar = () => {
  const [result, setResult] = useState([]);
  const toast = useRef(null);
  const [isAdmin, setIsAdmin] = useGlobalState('isAdmin');
  const [adminReportsAccess, setAdminReportsAccess] = useGlobalState('adminReportsAccess')
  const [labCategories, setLabCategories] = useGlobalState('labCategories');
  const [userNotification, setUserNotification] = useState(0);

  useEffect(async() => {

    setResult(labCategories);
    if(localStorage.getItem("users_id")) {
      let param = `?users_id=${localStorage.getItem("users_id")}`;
      await LabServices.getRbacLabList(param).then((res) => {
        setIsAdmin(res.data.isAdmin);
        setAdminReportsAccess(res.data.adminReportsAccess);
        setLabCategories(res.data.categories);
        setResult(res.data.categories)
      })
    }
    getPendingRequestCount();
  }, []);

  useEffect(() => {
    eventBus.on("labCreated", async () => {
      let param = `?users_id=${localStorage.getItem("users_id")}`;
      await LabServices.getRbacLabList(param).then((res) => {
        setIsAdmin(res.data.isAdmin);
        setAdminReportsAccess(res.data.adminReportsAccess);
        setLabCategories(res.data.categories);
        setResult(res.data.categories)
        })
      }
    )

    eventBus.on("pageReloaded", async () =>
 
    {
      let param = `?users_id=${localStorage.getItem("users_id")}`;
      await LabServices.getRbacLabList(param).then((res) => {
        setIsAdmin(res.data.isAdmin);
        setAdminReportsAccess(res.data.adminReportsAccess);
        setLabCategories(res.data.categories);
        setResult(res.data.categories);
        console.log(result, "************Result ***********");
      })
    }
    )

    eventBus.on("requestNotification", async () => {
      getPendingRequestCount();
    })

  }, [])

  const getPendingRequestCount = async () => {
    let labCount;
    let portalCount;
    await LabServices.getLabAccessRequestsCount().then((res) => {
      labCount = res.data
    })

    await LabServices.getPortalAccessRequestsCount().then((res) => {
      portalCount = res.data
    })

    setUserNotification(labCount+portalCount)
  }

  return (
    <>
      <div className="sidebar p-d-flex p-flex-column">
      <Toast ref={toast} />
        {/* <Accordion activeIndex={0} className="sidebar-accordian">
          <AccordionTab header="Labs">
            {result.map((res) => {
              return (
                <div key={res.category_id}>
                  <Tooltip
                    className="sidebarTooltip"
                    target={`.custom-target-icon${res.category_id}`}
                  />
                  <NavLink
                    className={`sidebar-item custom-target-icon${res.category_id}`}
                    data-pr-tooltip={res.category_tooltip}
                    data-pr-position="top"
                    to={`/home/labs/${res.category_id}`}
                  >
                    <span style={{ padding: "0.5rem" }}>
                      {res.category_name}
                    </span>
                  </NavLink>
                </div>
              );
            })}
          </AccordionTab>
        </Accordion> */}
        <Accordion activeIndex={0} className="sidebar-accordian">
            <AccordionTab
              header="Labs"
            >
        <TreeSidebar data = {result} child = {false}/>
        </AccordionTab>
        </Accordion>
        <NavLink to="/home/reservedLabs" className="sidebar-item" id="mymylabs">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_7652_1177)">
              <path
                d="M4.57089 13.3165C2.33102 11.0755 2.13864 7.50696 4.12427 5.03871L3.61464 4.53133C1.18689 7.48446 1.61327 11.8483 4.56752 14.2761C4.58777 14.2918 4.60689 14.3087 4.62714 14.3245L4.99389 13.7001C4.84764 13.5797 4.70702 13.4515 4.57089 13.3165ZM2.69664 3.61221L2.18927 3.10596C-0.829108 6.60808 -0.677233 11.9181 2.64489 15.2425C2.94302 15.5406 3.26252 15.8173 3.59889 16.0716L3.96339 15.4517C3.67764 15.2312 3.40652 14.9927 3.15002 14.7373C0.106892 11.6953 -0.0438576 6.83646 2.69664 3.61221Z"
                fill="white"
              />
              <path
                d="M17.0585 7.56883C16.8211 6.14683 16.2102 4.77433 15.2247 3.61446L15.7299 3.10596C16.9606 4.53358 17.6637 6.26271 17.8404 8.03458L17.0585 7.56883ZM15.8604 8.41708C15.7614 7.06596 15.2664 5.72946 14.354 4.59321C14.3394 4.57408 14.3236 4.55608 14.309 4.53696L13.8027 5.04658C14.5036 5.92071 14.9311 6.93208 15.092 7.97271L15.8604 8.41708Z"
                fill="white"
              />{" "}
              <path
                d="M3.64385 2.66509L3.13647 2.15884C6.4721 -0.714406 11.4457 -0.714406 14.7813 2.15884L14.2762 2.66509C11.2218 0.0685936 6.69935 0.0674687 3.64385 2.66509ZM4.56185 3.58422L5.07035 4.09159C6.1706 3.20172 7.54422 2.71797 8.95947 2.72247C10.3781 2.71797 11.7551 3.20397 12.8565 4.09834L13.3616 3.58872C10.8078 1.48272 7.11897 1.48047 4.56185 3.58422ZM8.75922 15.1301C8.75022 15.1301 8.74235 15.1301 8.73335 15.129L8.6366 15.1245L8.57585 15.1211L8.4566 15.1121L8.4116 15.1087C8.3621 15.1042 8.3126 15.0997 8.26197 15.0941H8.2496C7.62185 15.0221 7.00872 14.8556 6.4316 14.598L7.09422 13.4718C7.4081 13.6001 7.73435 13.6946 8.06735 13.7553C8.29685 13.797 8.5286 13.8228 8.76035 13.8318V13.1175C8.66022 13.113 8.55897 13.1051 8.45885 13.0927C8.11797 13.0522 7.78385 12.9701 7.46322 12.8486L8.3846 11.2826H8.76147V10.5682H6.81185C6.1886 10.5705 5.6846 10.0676 5.68235 9.44659C5.68122 8.92347 6.04122 8.46784 6.55197 8.34859L6.84897 8.27884L6.82647 7.97397C6.8231 7.92447 6.82197 7.88509 6.82197 7.84572C6.82197 7.80634 6.82197 7.77484 6.82535 7.73997C6.88385 6.77922 7.7096 6.04684 8.67035 6.10534C9.2351 6.13909 9.7481 6.44622 10.0462 6.92659C10.1475 7.09197 10.2206 7.27084 10.2622 7.45872L10.3331 7.77147L10.6503 7.73547C11.2342 7.66797 11.7765 7.96497 12.0499 8.44647L12.6686 8.08759C12.3108 7.46209 11.646 7.03347 10.8753 7.01097C10.413 5.73409 9.00447 5.07372 7.72872 5.53609C6.7916 5.87472 6.1526 6.74547 6.10985 7.73997C5.17047 8.12809 4.72272 9.20359 5.11085 10.143C5.39547 10.8315 6.06597 11.2803 6.8096 11.2803H7.55435L5.7971 14.2706L5.43372 14.8871L4.77785 16.0031L4.42122 16.6117L4.77785 16.8221L5.0366 16.974L5.0456 16.9582L5.30885 16.5116L5.40897 16.3406L6.06822 15.2212C6.78822 15.552 7.55997 15.7556 8.3486 15.8253H8.3621C8.41947 15.8298 8.47685 15.8343 8.5331 15.8377L8.57022 15.84C8.58035 15.84 8.59047 15.84 8.6006 15.84L8.7221 15.8445H8.7581V15.1301H8.75922Z"
                fill="white"
              />
              <path
                d="M14.0052 18L10.1206 15.7568V11.2725L14.0052 9.0293L15.4329 9.82692L15.0256 10.4164L14.0052 9.85392L10.835 11.6843V15.345L14.0052 17.1754L17.1755 15.345V13.0849H17.8899V15.7579L14.0052 18ZM18.0339 10.1858L17.4072 9.65142L13.5069 14.2302L12.2727 13.0185L11.6967 13.6047L13.5586 15.4339L18.0339 10.1858Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_7652_1177">
                <rect width="18" height="18" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span style={{ padding: "0.5rem" }}>My Labs</span>
        </NavLink>

        <NavLink to="/home/user-reports" className="sidebar-item" id="mymyreports">
          <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.57089 13.3165C2.33102 11.0755 2.13864 7.50696 4.12427 5.03871L3.61464 4.53133C1.18689 7.48446 1.61327 11.8483 4.56752 14.2761C4.58777 14.2918 4.60689 14.3087 4.62714 14.3245L4.99389 13.7001C4.84764 13.5797 4.70702 13.4515 4.57089 13.3165ZM2.69664 3.61221L2.18927 3.10596C-0.829108 6.60808 -0.677233 11.9181 2.64489 15.2425C2.94302 15.5406 3.26252 15.8173 3.59889 16.0716L3.96339 15.4517C3.67764 15.2312 3.40652 14.9927 3.15002 14.7373C0.106892 11.6953 -0.0438576 6.83646 2.69664 3.61221Z"
              fill="white"
            />
            <path
              d="M17.0585 7.56883C16.8211 6.14683 16.2102 4.77433 15.2247 3.61446L15.7299 3.10596C16.9606 4.53358 17.6637 6.26271 17.8404 8.03458L17.0585 7.56883ZM15.8604 8.41708C15.7614 7.06596 15.2664 5.72946 14.354 4.59321C14.3394 4.57408 14.3236 4.55608 14.309 4.53696L13.8027 5.04658C14.5036 5.92071 14.9311 6.93208 15.092 7.97271L15.8604 8.41708Z"
              fill="white"
            />
            <path
              d="M3.64385 2.66509L3.13647 2.15884C6.4721 -0.714406 11.4457 -0.714406 14.7813 2.15884L14.2762 2.66509C11.2218 0.0685936 6.69935 0.0674687 3.64385 2.66509ZM4.56185 3.58422L5.07035 4.09159C6.1706 3.20172 7.54422 2.71797 8.95947 2.72247C10.3781 2.71797 11.7551 3.20397 12.8565 4.09834L13.3616 3.58872C10.8078 1.48272 7.11897 1.48047 4.56185 3.58422ZM8.75922 15.1301C8.75022 15.1301 8.74235 15.1301 8.73335 15.129L8.6366 15.1245L8.57585 15.1211L8.4566 15.1121L8.4116 15.1087C8.3621 15.1042 8.3126 15.0997 8.26197 15.0941H8.2496C7.62185 15.0221 7.00872 14.8556 6.4316 14.598L7.09422 13.4718C7.4081 13.6001 7.73435 13.6946 8.06735 13.7553C8.29685 13.797 8.5286 13.8228 8.76035 13.8318V13.1175C8.66022 13.113 8.55897 13.1051 8.45885 13.0927C8.11797 13.0522 7.78385 12.9701 7.46322 12.8486L8.3846 11.2826H8.76147V10.5682H6.81185C6.1886 10.5705 5.6846 10.0676 5.68235 9.44659C5.68122 8.92347 6.04122 8.46784 6.55197 8.34859L6.84897 8.27884L6.82647 7.97397C6.8231 7.92447 6.82197 7.88509 6.82197 7.84572C6.82197 7.80634 6.82197 7.77484 6.82535 7.73997C6.88385 6.77922 7.7096 6.04684 8.67035 6.10534C9.2351 6.13909 9.7481 6.44622 10.0462 6.92659C10.1475 7.09197 10.2206 7.27084 10.2622 7.45872L10.3331 7.77147L10.6503 7.73547C11.2342 7.66797 11.7765 7.96497 12.0499 8.44647L12.6686 8.08759C12.3108 7.46209 11.646 7.03347 10.8753 7.01097C10.413 5.73409 9.00447 5.07372 7.72872 5.53609C6.7916 5.87472 6.1526 6.74547 6.10985 7.73997C5.17047 8.12809 4.72272 9.20359 5.11085 10.143C5.39547 10.8315 6.06597 11.2803 6.8096 11.2803H7.55435L5.7971 14.2706L5.43372 14.8871L4.77785 16.0031L4.42122 16.6117L4.77785 16.8221L5.0366 16.974L5.0456 16.9582L5.30885 16.5116L5.40897 16.3406L6.06822 15.2212C6.78822 15.552 7.55997 15.7556 8.3486 15.8253H8.3621C8.41947 15.8298 8.47685 15.8343 8.5331 15.8377L8.57022 15.84C8.58035 15.84 8.59047 15.84 8.6006 15.84L8.7221 15.8445H8.7581V15.1301H8.75922Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.2308 9H10.6154C10.2755 9 10 9.27552 10 9.61538V16.3846C10 16.7245 10.2755 17 10.6154 17H19.2308C19.5706 17 19.8462 16.7245 19.8462 16.3846V9.61538C19.8462 9.27552 19.5706 9 19.2308 9ZM10.6154 16.3846V9.61538H19.2308V16.3846H10.6154ZM13.3846 10.5385H11.5385V15.4615H12.0308V11.0308H12.8923V15.4615H13.3846V10.5385ZM14 13.3077H15.8462V15.4615H15.3538V13.8H14.4923V15.4615H14V13.3077ZM18.3077 12.3846H16.4615V15.4615H16.9538V12.8769H17.8154V15.4615H18.3077V12.3846Z"
              fill="white"
            />
          </svg>
          <span style={{ padding: "0.5rem" }}>My Reports</span>
        </NavLink>
        <NavLink to="/home/guides" className="sidebar-item" id="mysidebarguides">
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.6669 15.6667V10.1111H15.6669V9.83332C15.6669 9.67991 15.5425 9.55554 15.3891 9.55554H10.3891C10.2357 9.55554 10.1113 9.67991 10.1113 9.83332V15.9444C10.1113 16.0978 10.2357 16.2222 10.3891 16.2222H10.6669V15.6667ZM11.778 16.7778V11.2222H16.778V10.9444C16.778 10.791 16.6536 10.6667 16.5002 10.6667H11.5002C11.3468 10.6667 11.2224 10.791 11.2224 10.9444V17.0555C11.2224 17.209 11.3468 17.3333 11.5002 17.3333H11.778V16.7778ZM16.778 15.6667H13.4447V16.2222H16.778V15.6667ZM13.4447 14.5555H16.778V15.1111H13.4447V14.5555ZM12.6113 18.4444H17.6113C17.7647 18.4444 17.8891 18.3201 17.8891 18.1667V12.0555C17.8891 11.9021 17.7647 11.7778 17.6113 11.7778H12.6113C12.4579 11.7778 12.3336 11.9021 12.3336 12.0555V18.1667C12.3336 18.3201 12.4579 18.4444 12.6113 18.4444ZM12.8891 17.8889H17.3335V12.3333H12.8891V17.8889ZM16.778 13.4444H13.4447V14H16.778V13.4444Z"
              fill="white"
            />
            <path
              d="M4.57016 13.3165C2.33028 11.0755 2.13791 7.50699 4.12353 5.03874L3.61391 4.53136C1.18616 7.48449 1.61253 11.8484 4.56678 14.2761C4.58703 14.2919 4.60616 14.3087 4.62641 14.3245L4.99316 13.7001C4.84691 13.5797 4.70628 13.4515 4.57016 13.3165ZM2.69591 3.61224L2.18853 3.10599C-0.82984 6.60811 -0.677965 11.9181 2.64416 15.2425C2.94228 15.5406 3.26178 15.8174 3.59816 16.0716L3.96266 15.4517C3.67691 15.2312 3.40578 14.9927 3.14928 14.7374C0.10616 11.6954 -0.04459 6.83649 2.69591 3.61224Z"
              fill="white"
            />
            <path
              d="M17.0585 7.56886C16.8211 6.14686 16.2102 4.77436 15.2247 3.61449L15.7299 3.10599C16.9606 4.53361 17.6637 6.26274 17.8404 8.03461L17.0585 7.56886ZM15.8604 8.41711C15.7614 7.06599 15.2664 5.72949 14.354 4.59324C14.3394 4.57411 14.3236 4.55611 14.309 4.53699L13.8027 5.04661C14.5036 5.92074 14.9311 6.93211 15.092 7.97274L15.8604 8.41711Z"
              fill="white"
            />
            <path
              d="M3.64409 2.66509L3.13672 2.15884C6.47234 -0.714406 11.446 -0.714406 14.7816 2.15884L14.2765 2.66509C11.2221 0.0685936 6.69959 0.0674687 3.64409 2.66509ZM4.56209 3.58422L5.07059 4.09159C6.17084 3.20172 7.54447 2.71797 8.95972 2.72247C10.3783 2.71797 11.7553 3.20397 12.8567 4.09834L13.3618 3.58872C10.8081 1.48272 7.11922 1.48047 4.56209 3.58422ZM8.75947 15.1301C8.75047 15.1301 8.74259 15.1301 8.73359 15.129L8.63684 15.1245L8.57609 15.1211L8.45684 15.1121L8.41184 15.1087C8.36234 15.1042 8.31284 15.0997 8.26222 15.0941H8.24984C7.62209 15.0221 7.00897 14.8556 6.43184 14.598L7.09447 13.4718C7.40834 13.6001 7.73459 13.6946 8.06759 13.7553C8.29709 13.797 8.52884 13.8228 8.76059 13.8318V13.1175C8.66047 13.113 8.55922 13.1051 8.45909 13.0927C8.11822 13.0522 7.78409 12.9701 7.46347 12.8486L8.38484 11.2826H8.76172V10.5682H6.81209C6.18884 10.5705 5.68484 10.0676 5.68259 9.4466C5.68147 8.92347 6.04147 8.46784 6.55222 8.34859L6.84922 8.27884L6.82672 7.97397C6.82334 7.92447 6.82222 7.88509 6.82222 7.84572C6.82222 7.80634 6.82222 7.77484 6.82559 7.73997C6.88409 6.77922 7.70984 6.04684 8.67059 6.10534C9.23534 6.13909 9.74834 6.44622 10.0465 6.92659C10.1477 7.09197 10.2208 7.27084 10.2625 7.45872L10.3333 7.77147L10.6506 7.73547C11.2345 7.66797 11.7767 7.96497 12.0501 8.44647L12.6688 8.08759C12.3111 7.46209 11.6462 7.03347 10.8756 7.01097C10.4132 5.73409 9.00472 5.07372 7.72897 5.53609C6.79184 5.87472 6.15284 6.74547 6.11009 7.73997C5.17072 8.12809 4.72297 9.20359 5.11109 10.143C5.39572 10.8315 6.06622 11.2803 6.80984 11.2803H7.55459L5.79734 14.2706L5.43397 14.8871L4.77809 16.0031L4.42147 16.6117L4.77809 16.8221L5.03684 16.974L5.04584 16.9582L5.30909 16.5116L5.40922 16.3406L6.06847 15.2212C6.78847 15.552 7.56022 15.7556 8.34884 15.8253H8.36234C8.41972 15.8298 8.47709 15.8343 8.53334 15.8377L8.57047 15.84C8.58059 15.84 8.59072 15.84 8.60084 15.84L8.72234 15.8445H8.75834V15.1301H8.75947Z"
              fill="white"
            />
          </svg>
          <span style={{ padding: "0.5rem" }}>Guides</span>
        </NavLink>
        {/*<NavLink to="/home/netbox" className="sidebar-item" id="mysidebarnetbox">*/}
        {/*  <svg*/}
        {/*    width="19"*/}
        {/*    height="19"*/}
        {/*    viewBox="0 0 19 19"*/}
        {/*    fill="none"*/}
        {/*    xmlns="http://www.w3.org/2000/svg"*/}
        {/*  >*/}
        {/*    <path*/}
        {/*      fillRule="evenodd"*/}
        {/*      clipRule="evenodd"*/}
        {/*      d="M10.6669 15.6667V10.1111H15.6669V9.83332C15.6669 9.67991 15.5425 9.55554 15.3891 9.55554H10.3891C10.2357 9.55554 10.1113 9.67991 10.1113 9.83332V15.9444C10.1113 16.0978 10.2357 16.2222 10.3891 16.2222H10.6669V15.6667ZM11.778 16.7778V11.2222H16.778V10.9444C16.778 10.791 16.6536 10.6667 16.5002 10.6667H11.5002C11.3468 10.6667 11.2224 10.791 11.2224 10.9444V17.0555C11.2224 17.209 11.3468 17.3333 11.5002 17.3333H11.778V16.7778ZM16.778 15.6667H13.4447V16.2222H16.778V15.6667ZM13.4447 14.5555H16.778V15.1111H13.4447V14.5555ZM12.6113 18.4444H17.6113C17.7647 18.4444 17.8891 18.3201 17.8891 18.1667V12.0555C17.8891 11.9021 17.7647 11.7778 17.6113 11.7778H12.6113C12.4579 11.7778 12.3336 11.9021 12.3336 12.0555V18.1667C12.3336 18.3201 12.4579 18.4444 12.6113 18.4444ZM12.8891 17.8889H17.3335V12.3333H12.8891V17.8889ZM16.778 13.4444H13.4447V14H16.778V13.4444Z"*/}
        {/*      fill="white"*/}
        {/*    />*/}
        {/*    <path*/}
        {/*      d="M4.57016 13.3165C2.33028 11.0755 2.13791 7.50699 4.12353 5.03874L3.61391 4.53136C1.18616 7.48449 1.61253 11.8484 4.56678 14.2761C4.58703 14.2919 4.60616 14.3087 4.62641 14.3245L4.99316 13.7001C4.84691 13.5797 4.70628 13.4515 4.57016 13.3165ZM2.69591 3.61224L2.18853 3.10599C-0.82984 6.60811 -0.677965 11.9181 2.64416 15.2425C2.94228 15.5406 3.26178 15.8174 3.59816 16.0716L3.96266 15.4517C3.67691 15.2312 3.40578 14.9927 3.14928 14.7374C0.10616 11.6954 -0.04459 6.83649 2.69591 3.61224Z"*/}
        {/*      fill="white"*/}
        {/*    />*/}
        {/*    <path*/}
        {/*      d="M17.0585 7.56886C16.8211 6.14686 16.2102 4.77436 15.2247 3.61449L15.7299 3.10599C16.9606 4.53361 17.6637 6.26274 17.8404 8.03461L17.0585 7.56886ZM15.8604 8.41711C15.7614 7.06599 15.2664 5.72949 14.354 4.59324C14.3394 4.57411 14.3236 4.55611 14.309 4.53699L13.8027 5.04661C14.5036 5.92074 14.9311 6.93211 15.092 7.97274L15.8604 8.41711Z"*/}
        {/*      fill="white"*/}
        {/*    />*/}
        {/*    <path*/}
        {/*      d="M3.64409 2.66509L3.13672 2.15884C6.47234 -0.714406 11.446 -0.714406 14.7816 2.15884L14.2765 2.66509C11.2221 0.0685936 6.69959 0.0674687 3.64409 2.66509ZM4.56209 3.58422L5.07059 4.09159C6.17084 3.20172 7.54447 2.71797 8.95972 2.72247C10.3783 2.71797 11.7553 3.20397 12.8567 4.09834L13.3618 3.58872C10.8081 1.48272 7.11922 1.48047 4.56209 3.58422ZM8.75947 15.1301C8.75047 15.1301 8.74259 15.1301 8.73359 15.129L8.63684 15.1245L8.57609 15.1211L8.45684 15.1121L8.41184 15.1087C8.36234 15.1042 8.31284 15.0997 8.26222 15.0941H8.24984C7.62209 15.0221 7.00897 14.8556 6.43184 14.598L7.09447 13.4718C7.40834 13.6001 7.73459 13.6946 8.06759 13.7553C8.29709 13.797 8.52884 13.8228 8.76059 13.8318V13.1175C8.66047 13.113 8.55922 13.1051 8.45909 13.0927C8.11822 13.0522 7.78409 12.9701 7.46347 12.8486L8.38484 11.2826H8.76172V10.5682H6.81209C6.18884 10.5705 5.68484 10.0676 5.68259 9.4466C5.68147 8.92347 6.04147 8.46784 6.55222 8.34859L6.84922 8.27884L6.82672 7.97397C6.82334 7.92447 6.82222 7.88509 6.82222 7.84572C6.82222 7.80634 6.82222 7.77484 6.82559 7.73997C6.88409 6.77922 7.70984 6.04684 8.67059 6.10534C9.23534 6.13909 9.74834 6.44622 10.0465 6.92659C10.1477 7.09197 10.2208 7.27084 10.2625 7.45872L10.3333 7.77147L10.6506 7.73547C11.2345 7.66797 11.7767 7.96497 12.0501 8.44647L12.6688 8.08759C12.3111 7.46209 11.6462 7.03347 10.8756 7.01097C10.4132 5.73409 9.00472 5.07372 7.72897 5.53609C6.79184 5.87472 6.15284 6.74547 6.11009 7.73997C5.17072 8.12809 4.72297 9.20359 5.11109 10.143C5.39572 10.8315 6.06622 11.2803 6.80984 11.2803H7.55459L5.79734 14.2706L5.43397 14.8871L4.77809 16.0031L4.42147 16.6117L4.77809 16.8221L5.03684 16.974L5.04584 16.9582L5.30909 16.5116L5.40922 16.3406L6.06847 15.2212C6.78847 15.552 7.56022 15.7556 8.34884 15.8253H8.36234C8.41972 15.8298 8.47709 15.8343 8.53334 15.8377L8.57047 15.84C8.58059 15.84 8.59072 15.84 8.60084 15.84L8.72234 15.8445H8.75834V15.1301H8.75947Z"*/}
        {/*      fill="white"*/}
        {/*    />*/}
        {/*  </svg>*/}
        {/*  <span style={{ padding: "0.5rem" }}>NETBOX da11</span>*/}
        {/*</NavLink>*/}

        {(!isAdmin && adminReportsAccess) ? (
          <NavLink
            className={`sidebar-item`}
            data-pr-tooltip={"Labs Report"}
            data-pr-position="top"
            to="/home/admin-reports"
          >
            <svg
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.57089 13.3165C2.33102 11.0755 2.13864 7.50696 4.12427 5.03871L3.61464 4.53133C1.18689 7.48446 1.61327 11.8483 4.56752 14.2761C4.58777 14.2918 4.60689 14.3087 4.62714 14.3245L4.99389 13.7001C4.84764 13.5797 4.70702 13.4515 4.57089 13.3165ZM2.69664 3.61221L2.18927 3.10596C-0.829108 6.60808 -0.677233 11.9181 2.64489 15.2425C2.94302 15.5406 3.26252 15.8173 3.59889 16.0716L3.96339 15.4517C3.67764 15.2312 3.40652 14.9927 3.15002 14.7373C0.106892 11.6953 -0.0438576 6.83646 2.69664 3.61221Z"
                fill="white"
              />
              <path
                d="M17.0585 7.56883C16.8211 6.14683 16.2102 4.77433 15.2247 3.61446L15.7299 3.10596C16.9606 4.53358 17.6637 6.26271 17.8404 8.03458L17.0585 7.56883ZM15.8604 8.41708C15.7614 7.06596 15.2664 5.72946 14.354 4.59321C14.3394 4.57408 14.3236 4.55608 14.309 4.53696L13.8027 5.04658C14.5036 5.92071 14.9311 6.93208 15.092 7.97271L15.8604 8.41708Z"
                fill="white"
              />
              <path
                d="M3.64385 2.66509L3.13647 2.15884C6.4721 -0.714406 11.4457 -0.714406 14.7813 2.15884L14.2762 2.66509C11.2218 0.0685936 6.69935 0.0674687 3.64385 2.66509ZM4.56185 3.58422L5.07035 4.09159C6.1706 3.20172 7.54422 2.71797 8.95947 2.72247C10.3781 2.71797 11.7551 3.20397 12.8565 4.09834L13.3616 3.58872C10.8078 1.48272 7.11897 1.48047 4.56185 3.58422ZM8.75922 15.1301C8.75022 15.1301 8.74235 15.1301 8.73335 15.129L8.6366 15.1245L8.57585 15.1211L8.4566 15.1121L8.4116 15.1087C8.3621 15.1042 8.3126 15.0997 8.26197 15.0941H8.2496C7.62185 15.0221 7.00872 14.8556 6.4316 14.598L7.09422 13.4718C7.4081 13.6001 7.73435 13.6946 8.06735 13.7553C8.29685 13.797 8.5286 13.8228 8.76035 13.8318V13.1175C8.66022 13.113 8.55897 13.1051 8.45885 13.0927C8.11797 13.0522 7.78385 12.9701 7.46322 12.8486L8.3846 11.2826H8.76147V10.5682H6.81185C6.1886 10.5705 5.6846 10.0676 5.68235 9.44659C5.68122 8.92347 6.04122 8.46784 6.55197 8.34859L6.84897 8.27884L6.82647 7.97397C6.8231 7.92447 6.82197 7.88509 6.82197 7.84572C6.82197 7.80634 6.82197 7.77484 6.82535 7.73997C6.88385 6.77922 7.7096 6.04684 8.67035 6.10534C9.2351 6.13909 9.7481 6.44622 10.0462 6.92659C10.1475 7.09197 10.2206 7.27084 10.2622 7.45872L10.3331 7.77147L10.6503 7.73547C11.2342 7.66797 11.7765 7.96497 12.0499 8.44647L12.6686 8.08759C12.3108 7.46209 11.646 7.03347 10.8753 7.01097C10.413 5.73409 9.00447 5.07372 7.72872 5.53609C6.7916 5.87472 6.1526 6.74547 6.10985 7.73997C5.17047 8.12809 4.72272 9.20359 5.11085 10.143C5.39547 10.8315 6.06597 11.2803 6.8096 11.2803H7.55435L5.7971 14.2706L5.43372 14.8871L4.77785 16.0031L4.42122 16.6117L4.77785 16.8221L5.0366 16.974L5.0456 16.9582L5.30885 16.5116L5.40897 16.3406L6.06822 15.2212C6.78822 15.552 7.55997 15.7556 8.3486 15.8253H8.3621C8.41947 15.8298 8.47685 15.8343 8.5331 15.8377L8.57022 15.84C8.58035 15.84 8.59047 15.84 8.6006 15.84L8.7221 15.8445H8.7581V15.1301H8.75922Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.2308 9H10.6154C10.2755 9 10 9.27552 10 9.61538V16.3846C10 16.7245 10.2755 17 10.6154 17H19.2308C19.5706 17 19.8462 16.7245 19.8462 16.3846V9.61538C19.8462 9.27552 19.5706 9 19.2308 9ZM10.6154 16.3846V9.61538H19.2308V16.3846H10.6154ZM13.3846 10.5385H11.5385V15.4615H12.0308V11.0308H12.8923V15.4615H13.3846V10.5385ZM14 13.3077H15.8462V15.4615H15.3538V13.8H14.4923V15.4615H14V13.3077ZM18.3077 12.3846H16.4615V15.4615H16.9538V12.8769H17.8154V15.4615H18.3077V12.3846Z"
                fill="white"
              />
            </svg>
            <span style={{ padding: "0.5rem" }}>Admin Reports</span>
          </NavLink>
        ) : (
          ""
        )}

       

        {isAdmin ? (
          <Accordion activeIndex={0} className="admin-sidebar-accordian">
            <AccordionTab
              header="Admin"
              className="admin-sidebar-accordian-tab"
            >
              <div>
                {/* <Tooltip className="sidebarTooltip" target={`.custom-target-icon`} /> */}
                <NavLink
                  className={`sidebar-item custom-target-icon`}
                  data-pr-tooltip={"Lab Management"}
                  data-pr-position="top"
                  to="/home/Admin/lab-management"
                >
                  <span style={{ padding: "0.5rem" }}>Lab Management</span>
                </NavLink>
                {/* <Tooltip className="sidebarTooltip" target={`.custom-target-icon`} /> */}
                <NavLink
                  className={`sidebar-item custom-target-icon`}
                  data-pr-tooltip={"User Management"}
                  data-pr-position="top"
                  to="/home/Admin/user-management"
                >
                  <span style={{ padding: "0.5rem" }}>User Management</span>
                  {userNotification > 0 ? <span><Badge value={userNotification}></Badge></span> : ""}
                  
                </NavLink>
                <NavLink
                  className={`sidebar-item custom-target-icon`}
                  data-pr-tooltip={"Inventory Management"}
                  data-pr-position="top"
                  to="/home/Admin/inventory-management"
                >
                  <span style={{ padding: "0.5rem" }}>
                    Inventory Management
                  </span>
                </NavLink>
                <NavLink
                  className={`sidebar-item custom-target-icon`}
                  data-pr-tooltip={"Labs Report"}
                  data-pr-position="top"
                  to="/home/admin-reports"
                >
                  <span style={{ padding: "0.5rem" }}>Admin Reports</span>
                </NavLink>
                <NavLink
                  className={`sidebar-item custom-target-icon`}
                  data-pr-tooltip={"Utilization Report"}
                  data-pr-position="top"
                  to="/home/utilization-reports"
                >
                  <span style={{ padding: "0.5rem" }}>Utilization Reports</span>
                </NavLink>
                <NavLink className={`sidebar-item custom-target-icon`} data-pr-tooltip={"Labs Report"} data-pr-position="top" to="/home/Admin/admin-messages">
                  <span style={{ padding: '0.5rem' }}>Broadcast</span>
                </NavLink>
              </div>
            </AccordionTab>
          </Accordion>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Sidebar;
