import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import LabServices from "../../../../services/LabServices";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import "../../Rbac.css";
import "./OSTypes.css"
import CreateOSType from "./CreateOSType";
import UpdateOSType from "./UpdateOSType";
import DeleteOSType from "./DeleteOSType";
import loglevel from "../../../../logger/Logger";

const OSTypes = () => {

  const [osType, setOSType] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    async function fetchData() {
      await LabServices.getCMDBLabTypeTags().then((res) => {
        // console.log(res.data,"OS type list")
        setOSType(res.data)
      })
        .catch((error) => {
          loglevel.error("getOSTypesList", error);
        });
    }
    fetchData();
  }, []);

  const onIndexTemplate = (data, props) => {
    return (
      <div className="cat-name-table">
        {props.rowIndex + 1}
      </div>)
  }

  const tagnameTemplate = (rowData) => {
    return (

      <Tag className="os-type" >
        <div className="os-type-head">
          <div className="os-type-icon">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M12.4445 1.94434H1.55561C1.12605 1.94434 0.777832 2.29256 0.777832 2.72211V11.2777C0.777832 11.7072 1.12605 12.0554 1.55561 12.0554H12.4445C12.8741 12.0554 13.2223 11.7072 13.2223 11.2777V2.72211C13.2223 2.29256 12.8741 1.94434 12.4445 1.94434ZM3.11117 9.72211H2.33339V10.4999H3.11117V9.72211ZM9.33339 9.72211H3.88894V10.4999H9.33339V9.72211ZM12.4445 2.72211H1.55561V5.05545H12.4445V2.72211ZM1.55561 5.83322H12.4445V8.16656H1.55561V5.83322ZM1.55561 8.94434V11.2777H12.4445V8.94434H1.55561ZM3.88894 6.611H9.33339V7.38878H3.88894V6.611ZM3.11117 6.611H2.33339V7.38878H3.11117V6.611ZM3.88894 3.49989H9.33339V4.27767H3.88894V3.49989ZM3.11117 3.49989H2.33339V4.27767H3.11117V3.49989Z" fill="black" />
            </svg>
          </div>
          <div>
            {rowData.name}
          </div>
        </div>
      </Tag>)
  }

  const descriptionTemplate = (rowData) => {
    return (
      <div className="tag-data-short-desc-table">{rowData.description}</div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="table-action-body">
        <div className="table-update-icon">
          <UpdateOSType
            cmdbLabTypeId={rowData.cmdbLabTypeId}
            osName={rowData.name}
            osDescription={rowData.description}
            setChanger={setOSType} />
        </div>

        <div className="table-delete-icon">
          <DeleteOSType
            cmdbLabTypeId={rowData.cmdbLabTypeId}
            osName={rowData.name}
            setChanger={setOSType} />
        </div>
      </div>
    );
  };

  return (
    <div className="datatable-crud-demo">
      <div className="admin-panel">
        <div className="admin-panel-search-bar">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search"
              className="global-search-filter"
            />
          </span>
        </div>
        <CreateOSType setChanger={setOSType} />
      </div>
      <Toast ref={toast} position="top-center" />
      <DataTable
        value={osType}
        ref={dt}
        className="lab-crud-table"
        columnResizeMode="fit"
        paginator
        rows={50}
        // breakpoint="1024px"
        pageLinkSize={1}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        globalFilter={globalFilter}
        responsiveLayout="scroll"
        autoLayout={true}
        removableSort={true}
        let-i="rowIndex"
        style={{ width: "100%" }}
      >
        <Column
          field="Index"
          header="#"
          body={onIndexTemplate}
          style={{ width: "3rem", paddingLeft: "15px", fontWeight: "600" }}
        ></Column>
        <Column
          sortable
          field="name"
          header="OS name"
          body={tagnameTemplate}
          style={{ minWidth: "11rem" }}>
        </Column>
        <Column
          sortable
          field="description"
          header="Description"
          body={descriptionTemplate}
          placeholder="-"
          style={{ minWidth: "12rem" }}>
        </Column>
        <Column
          body={actionBodyTemplate}
          exportable={false}
          style={{ minWidth: "4rem" }}
        ></Column>
      </DataTable>
    </div>
  );
};


export default OSTypes;
