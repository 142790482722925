import http from "../http-comman";

class GuideService {
    getLabUserGuideList() {
        return http.get("/lab/user/guide?users_id="+parseInt(localStorage.getItem("users_id")));
    }

    getLabUserGuideDetails(labUserGuideId) {
        return http.get("/lab/user/guide/"+parseInt(labUserGuideId));
    }
    
    deleteLabUserGuide(labUserGuideId) {
        return http.delete("/lab/user/guide/"+labUserGuideId);
    }

    addLabUserGuide(labId, payload) {
        return http.post("/lab/user/guide?users_id="+parseInt(localStorage.getItem("users_id"))+"&lab_id="+parseInt(labId), payload);
    }

    updateLabUserGuide(labUserGuideId, labId, payload) {
        return http.put("/lab/user/guide/"+labUserGuideId+"?users_id="+parseInt(localStorage.getItem("users_id"))+"&lab_id="+parseInt(labId), payload);
    }

    //AdminGuide

    getAdminGuideList() {
        return http.get("/lab/admin/guide");
    }

    getAdminGuideDetails(adminGuideId) {
        return http.get(`lab/admin/guide/${adminGuideId}`);
    }
    
    deleteAdminGuide(adminGuideId) {
        return http.delete("lab/admin/guide/"+adminGuideId);
    }

    addAdminGuide(payload) {
        return http.post("/lab/admin/guide", payload);
    }

    updateAdminGuide(adminGuideId,payload) {
        return http.put(`lab/admin/guide/${adminGuideId}`, payload);
    }

    // portal Usage GUIDE

    getPortalUsageGuideList() {
        return http.get("/lab/portal/usage/guide/");
    }

    getPortalUsageGuideDetails(portalUsageGuideId) {
        return http.get(`lab/portal/usage/guide/${portalUsageGuideId}`);
    }
    
    deletePortalUsageGuide(portalUsageGuideId) {
        return http.delete("lab/portal/usage/guide/"+portalUsageGuideId);
    }

    addPortalUsageGuide(payload) {
        return http.post("/lab/portal/usage/guide/", payload);
    }

    updatePortalUsageGuide(portalUsageGuideId,payload) {
        return http.put(`lab/portal/usage/guide/${portalUsageGuideId}`, payload);
    }
}

export default new GuideService();