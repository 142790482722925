import React from 'react';
import loglevel from '../../logger/Logger';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, errorMessage: '' };
    }

    static getDerivedStateFromError(error) {
        return {
            hasError:true,
            errorMessage:error.message
        }
    }

    componentDidCatch(error, info) {
        loglevel.error("ErrorBoundary Component: catched Error",error);
        loglevel.info("ErrorBoundary Component: catched  Info", info);
    }
    render() {
        if (this.state.hasError) {
            return (
            <div>
                <h1>Oops, Something went wrong!</h1>
                <h4 style={{color:"red"}}>{this.state.errorMessage}</h4>
                <button type="button" onClick={() => this.setState({ hasError: false })}>
                Try again?
                </button>
            </div>
            );
        }
        return this.props.children;
    }  
}
export default ErrorBoundary;
