import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Message } from "primereact/message";
import { useRef, useState } from "react";
import loglevel from "../../../../logger/Logger";
import LabServices from "../../../../services/LabServices";


const DenyLabRequest = ({ rowData, fetchData, showSuccessToast }) => {
    const [denyLabRequestDisplay, setDenyLabRequestDisplay] = useState(false);
    const denyLabRequestDialogMap = {
        denyLabRequestDisplay: setDenyLabRequestDisplay,
    };
    const [commonErrorMsg, setCommonErrorMsg] = useState("");
    const errorRef = useRef();
    const tableData = [{lab_name: rowData.lab_name, email: rowData.email}]

    //on opening deny lab request Dialog
    const onShow = (name) => {
        setCommonErrorMsg("");
        denyLabRequestDialogMap[`${name}`](true);
    };

    //on closing Delete dialog
    const onHide = (name) => {
        denyLabRequestDialogMap[`${name}`](false);
        setCommonErrorMsg("");
    };

    const renderFooter = (name) => {
        return (
            <div className="delete-btn-section">
                {/* Delete button */}
                <Button
                    label="Deny Lab Access Request"
                    className="p-button-outlined p-button-danger"
                    onClick={(e) => onDelete(e)}
                >
                </Button>
                {/* Cancel button */}
                <Button
                    className="p-button-primary"
                    label="CANCEL"
                    onClick={() => onHide(name)}
                />
            </div>
        );
    };

    const refreshLabAccessList = () => fetchData()

    const showToast = (detail) => showSuccessToast(detail)

    const onDelete = (e) => {
        //api call for denying request
        LabServices.denyLabRequest(rowData.lab_request_id)
            .then(async (response) => {
                if (response.status == 200) {
                    denyLabRequestDialogMap["denyLabRequestDisplay"](false);
                    //Refresh Lab Access List after deny to show updated list
                    await refreshLabAccessList()
                    //show toast message
                    showToast(`Lab Access Request submitted by ${rowData.email} has been denied successfully`)
                } else {
                    loglevel.error("Lab Access denial failed")
                }
            })
            .catch((error) => {
                setCommonErrorMsg(error.response.data.msg);
                errorRef.current.scrollIntoView({ behavior: "smooth" });
            });
    };

    return (
        <div>
            <Button
                icon="pi pi-times"
                className="p-button-rounded global-delete-button"
                onClick={() => onShow("denyLabRequestDisplay")}
            />

            <Dialog
                header={`Deny Lab Access Request`}
                visible={denyLabRequestDisplay}
                footer={renderFooter("denyLabRequestDisplay")}
                onHide={() => onHide("denyLabRequestDisplay")}
                className="tag-delete-dialog-box"
            >
                <div ref={errorRef} className={commonErrorMsg ? "" : "hidden"}>
                    {commonErrorMsg && (
                        <Message
                            severity="warn"
                            text={commonErrorMsg}
                            className="common-inline-error common-error-message p-mb-3"
                        />
                    )}
                </div>{" "}

                <div>
                    Below are the details of the Lab Access Request you have selected to deny.
                </div>
                <br></br>
                <DataTable
                    value={tableData}
                    columnResizeMode="fit"
                    className="disable-lab-crud-table"
                    autoLayout={true}
                    responsiveLayout="scroll"
                >
                    <Column
                        field="email"
                        header="Email"
                        style={{ minWidth: "10rem", paddingLeft: "15px" }}
                    ></Column>
                    <Column
                        field="lab_name"
                        header="Lab Name"
                        style={{ minWidth: "10rem", paddingLeft: "15px" }}
                    ></Column>
                </DataTable>
                <br></br>
            </Dialog>
        </div>
    );
}

export default DenyLabRequest;