import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import "../../rbac/Rbac.css";
import React, { useState, useRef } from "react";
import { Message } from "primereact/message";
import GuideService from "../../../services/GuideService";
import loglevel from "../../../logger/Logger";

const DeletePortalUsageGuide = ({ portalUsageGuideId, guideName, setAllGuideDetails }) => {
    const [deletePortalUsageGuideDisplay, setDeletePortalUsageGuideDisplay] = useState(false);
    const deletePortalUsageGuideDialogMap = {
        deletePortalUsageGuideDisplay: setDeletePortalUsageGuideDisplay,
    };
    const [portalUsageGuideErrorMsg, setPortalUsageGuideErrorMsg] = useState('');
    const toast = useRef(null);

    const onClick = async (name) => {
        setPortalUsageGuideErrorMsg('');
        deletePortalUsageGuideDialogMap[`${name}`](true);
    };

    const onHide = (name) => {
        setPortalUsageGuideErrorMsg('');
        deletePortalUsageGuideDialogMap[`${name}`](false);
    };

    const onDelete = async (e) => {
        await GuideService.deletePortalUsageGuide(portalUsageGuideId)
            .then((response) => {
                if (response.status === 200) {
                    toast.current.show({
                        severity: "success",
                        summary: "Portal Usage Guide Successfully Deleted",
                        detail: `The portal usage guide named "${guideName}" has been deleted successfully.`,
                        life: 6000,
                    });
                    deletePortalUsageGuideDialogMap["deletePortalUsageGuideDisplay"](false);
                } else {
                    loglevel.debug("portal usage guide deletion has failed");
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    setPortalUsageGuideErrorMsg(error.response.data.msg);
                }
            });

        await GuideService.getPortalUsageGuideList().then((res) => {
            for (let i = 0; i < res.data.length; i++) {
                res.data[i]["closedStatus"] = true;
                res.data[i]["closedGroupsStatus"] = true;

            };
            setAllGuideDetails(res.data);
        });
    };

    const renderFooter = (name) => {
        return (
            <div className="disable-footer-button">

                <Button
            label="DELETE PORTAL USAGE GUIDE"
            className={
              "p-button-outlined p-button-danger"
            }
            onClick={() => onDelete("deletePortalUsageGuideDisplay")}
           
          />
                <div>
                    {" "}
                    <Button
                        label="CANCEL"
                        onClick={() => onHide('deletePortalUsageGuideDisplay')}
                        className="p-button-outlined p-button-primary"
                    />
                </div></div>
        );
    };

    return (
        <div className="lab-type-edit">
            <Toast ref={toast} position="top-center" />
            <Button
                icon="pi pi-trash"
                className="p-button-rounded global-delete-button"
                onClick={() => onClick("deletePortalUsageGuideDisplay")}
            />
            <Dialog
                header={`Delete Portal Usage Guide - ${guideName}`}
                visible={deletePortalUsageGuideDisplay}
                footer={renderFooter("deletePortalUsageGuideDisplay")}
                onHide={() => onHide("deletePortalUsageGuideDisplay")}
                className="lab-type-delete-dialog-box"
            >
                <div>
                    {portalUsageGuideErrorMsg && <Message severity="error" text={portalUsageGuideErrorMsg} className='common-inline-error common-inline-error p-mt-1 p-mb-3 full-width' />}
                </div>
                {
                    (
                        <div style={{ width: "365px" }}>
                            <div>Do you want to delete this portal usage guide?</div>
                        </div>
                    )
                }
            </Dialog>
        </div>
    );
};

export default DeletePortalUsageGuide;
