import React, { useState, useEffect, useRef } from 'react'
import './Graphs.css'
import { Dropdown } from "primereact/dropdown";
import { Chart } from 'primereact/chart';
import LabServices from '../../services/LabServices';
import UtilityServices from "../utility/Utiity";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import moment from "moment";
import { Toast } from "primereact/toast";
import { Constants } from "../../constants/constants";

const DetailedEquipmentGraph = () => {
    const toast = useRef(null);
    const [selectedDays, setSelectedDays] = useState({ filterByDays: "Last 90 days", days: 90 });
    const [enable, setEnable] = useState(true);
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [totalDays, setTotalDays] = useState(0)

    // dropdown options for changing days
    const filterDays = [
        { filterByDays: "Last 7 days", days: 7 },
        { filterByDays: "Last 15 days", days: 15 },
        { filterByDays: "Last 30 days", days: 30 },
        { filterByDays: "Last 90 days", days: 90 }
    ];
    
    // colurs for bars 
    const colors = ['#42A5F5', '#425656', '#FFA726', '#00FF00', '#008000', '#008000', '#0000FF', '#FF00FF', '#808000', '#800000']

    useEffect(() => {
        async function getEquipment() {
            let param = "?days=90"
            setTotalDays(90);
            // api call for getting Detailed Equipment Graphs data
            await LabServices.getDetailedEquipmentUsage(param).then((res) => {

                let basicData = {};
                basicData.labels = res.data.labels;
                console.log("###### DATA: ", res.data)
                let datasets = [];
                for (let i = 0; i < res.data.details.length; i++) {
                    datasets.push({
                        label: res.data.details[i].label,
                        backgroundColor: colors[i],
                        data: res.data.details[i].data
                    })
                }
                basicData.datasets = datasets;
                setData(basicData);

            })
        }
        getEquipment();
    }, []);

    // method called on start date change 
    const startDateChange = (e) => {
        setStartDate(e.target.value);
        console.log("StartD", e.value);
    };

    // method called on end date change 
    const endDateChange = (e) => {
        setEndDate(e.target.value);
        console.log("EndD", e.value);
    };

    // search button 
    const searchHandle = async () => {
        let g1 = new Date(startDate);
        let g2 = new Date(endDate);

        if (g1.getTime() < g2.getTime()) {

            const oneDay = 24 * 60 * 60 * 1000;
            const diffDays = Math.round(Math.abs((g1 - g2) / oneDay));

            if (diffDays + 1 > 30) {
                setEnable(true);
            } else {
                setEnable(false);
            }

            setTotalDays(diffDays + 1);

            let d1 = moment(startDate).format("YYYY-MM-DD");
            let d2 = moment(endDate).format("YYYY-MM-DD");

            let param = `?startDate=${d1}&endDate=${d2}`
            console.log("### param:", param)
            await LabServices.getDetailedEquipmentUsage(param).then((res) => {
                let basicData = {};
                basicData.labels = res.data.labels;
                console.log("###### DATA: ", res.data)
                let datasets = [];
                for (let i = 0; i < res.data.details.length; i++) {
                    datasets.push({
                        label: res.data.details[i].label,
                        backgroundColor: colors[i],
                        data: res.data.details[i].data
                    })
                }
                basicData.datasets = datasets;
                setData(basicData);
            })
        }
        //validation if start date is greater than end date
        else if (g1.getTime() > g2.getTime()) {
            return false;
        }
        //validation if start date is equals to end date
        else {
            return false;
        }
    };

    // reset button to reset alues to default
    const resetHandle = async (e) => {
        setStartDate("");
        setEndDate("");
        setSelectedDays({ filterByDays: "Last 90 days", days: 90 });
        setEnable(true);
        let days = 90;
        setTotalDays(90);
        let param = `?days=${days}`
        await LabServices.getDetailedEquipmentUsage(param).then((res) => {
            let basicData = {};
            basicData.labels = res.data.labels;
            console.log("###### DATA: ", res.data)
            let datasets = [];
            for (let i = 0; i < res.data.details.length; i++) {
                datasets.push({
                    label: res.data.details[i].label,
                    backgroundColor: colors[i],
                    data: res.data.details[i].data
                })
            }
            basicData.datasets = datasets;
            setData(basicData);
        })
    };

    // method called on changing days from dropdown
    const onDaysChange = (e) => {
        console.log(e.value, "city")
        setSelectedDays(e.value);
        let days = 90;
        if (e.value.days === 7) {
            days = 7;
            setTotalDays(7);
            setEnable(false)

        }
        else if (e.value.days === 15) {
            days = 15;
            setTotalDays(15);
            setEnable(false)

        }
        else if (e.value.days === 30) {
            days = 30;
            setTotalDays(30);
            setEnable(false)

        }
        else if (e.value.days === 90) {
            days = 90;
            setTotalDays(90);
            setEnable(true)

        }

        let param = "?days=" + days
        LabServices.getDetailedEquipmentUsage(param).then((res) => {
            let basicData = {};
            basicData.labels = res.data.labels;
            console.log("###### DATA: ", res.data)
            let datasets = [];
            for (let i = 0; i < res.data.details.length; i++) {
                datasets.push({
                    label: res.data.details[i].label,
                    backgroundColor: colors[i],
                    data: res.data.details[i].data
                })
            }
            basicData.datasets = datasets;
            setData(basicData);
        })
    }

    // all the plugins related to graph
    const basicOptions = {
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
            intersect: false,
            mode: 'index',
        },

        plugins: {
            tooltip: {
                callbacks: {}
            },
            legend: {
                position: 'right',
                labels: {
                    color: '#FFFFFF'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#FFFFFF',

                },
                grid: {
                    color: '#495057'
                }
            },
            y: {
                ticks: {
                    stepSize: 10,
                    color: '#FFFFFF'
                },
                grid: {
                    color: '#495057'
                }
            }
        }
    };

    // method for generating pdf
    const generatePDF = e => {
        let days = totalDays
        let input = window.document.getElementById("det_chart");
        let filename = "detailed_equipment_usage.pdf";
        let canvasType = "chart";
        UtilityServices.exportPDF(days, input, filename, canvasType, "Detailed Equipment Usage");
        toast.current.show({
            severity: "success",
            summary: Constants.EXPORT_TOAST_SUMMARY,
            detail: Constants.EXPORT_TOAST_DETAIL,
            life: 6000,
        })
    };


    return (
        <div className='main-grid'>
            <Toast ref={toast} position="top-center" />
            <div className='chart ht equip-height'>
                <div className="parent-row">
                    <div className="item-left-trend-lab">
                        {/* Dropdown for changing days */}
                        <div className="chart-panel-item">
                            <Dropdown
                                value={selectedDays}
                                options={filterDays}
                                onChange={onDaysChange}
                                optionLabel="filterByDays"
                                placeholder="Select quick dates"
                                className='dropdown-days days-selection'
                            /></div>
                            {/* Start date */}
                        <div className="chart-panel-item">
                            <Calendar
                                value={startDate}
                                onChange={(e) => startDateChange(e)}
                                dateFormat="mm/dd/yy"
                                mask="99/99/9999"
                                selectOtherMonths={true}
                                placeholder="Select Start Date"
                                icon="pi pi-calendar"
                                showIcon
                                className="date-icon mr-left-10"
                            /></div>

                            {/* End Date */}
                        <div className="chart-panel-item">
                            <Calendar
                                value={endDate}
                                onChange={(e) => endDateChange(e)}
                                dateFormat="mm/dd/yy"
                                mask="99/99/9999"
                                selectOtherMonths={true}
                                placeholder="Select End Date"
                                icon="pi pi-calendar"
                                showIcon
                                className="date-icon mr-left-10"
                            /></div>
                    </div>
                    <div className="item-right-trend-lab">

                        {/* Search Buttn */}
                        <div className="chart-panel-item">
                            <Button
                                id="search-button"
                                label="SEARCH"
                                className="p-button-primary search-btn"
                                onClick={searchHandle}
                            /></div>

                            {/* Reset Buttn */}
                        <div className="chart-panel-item">
                            <Button
                                id="reset-button"
                                label="RESET"
                                className="p-button-primary reset-btn mr-left-10"
                                onClick={resetHandle}
                            /></div>
                            
                            {/* Export button */}
                        <div className="chart-panel-item">
                            <Button
                                id="export-button"
                                icon="pi pi-file-pdf"
                                label="EXPORT"
                                className="p-button-primary reset-btn mr-left-10"
                                onClick={generatePDF}
                            /></div>
                    </div>
                </div>
                <div id='detailed_chart_div'>
                    <div className='bar-label child chart-header label_with_bg equip-header-text'>Detailed Equipment Usage</div>

                    {!enable ?
                        <div className='bar-chart' >
                            <Chart type="bar" id="det_chart" data={data} options={basicOptions} height="16rem" width="70vw" />
                        </div> :
                        <div className="chartWrapper">
                            <div className="chartAreaWrapper">
                                <div className="chartAreaWrapper2">
                                    <Chart type="bar" id="det_chart" data={data} options={basicOptions} height="16rem" width="170vw" />
                                </div>
                            </div>
                        </div>
                    }
                    <div className="legend-info mg-t-5">Click over legends, to show/hide chart elements</div>
                </div>

            </div>
        </div>
    )
}

export default DetailedEquipmentGraph;