import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import "../../Rbac.css";
import React, { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { confirmPopup } from "primereact/confirmpopup";
import parse from "html-react-parser";
import LabServices from "../../../../services/LabServices";
import loglevel from "../../../../logger/Logger";
import { Message } from "primereact/message";

const UpdateOSType = ({ cmdbLabTypeId, osName, osDescription, setChanger }) => {
  const [updateOSTypeDisplay, setUpdateOSTypeDisplay] = useState(false);
  const updateOSTypeDialogMap = {
    updateOSTypeDisplay: setUpdateOSTypeDisplay,
  };
  const [osTypeName, setOSTypeName] = useState("");
  const [description, setDescription] = useState("");
  const [isValidated, setIsValidated] = useState(false);
  const [commonErrorMsg, setCommonErrorMsg] = useState("");

  const toast = useRef(null);
  const errorRef = useRef();

  const onClick = (name) => {
    setCommonErrorMsg("");
    setOSTypeName(osName);
    setDescription(osDescription);
    updateOSTypeDialogMap[`${name}`](true);
  };

  const onHide = (name) => {
    updateOSTypeDialogMap[`${name}`](false);
    setIsValidated(false);
    setOSTypeName("");
    setDescription("");
    setCommonErrorMsg("");
  };

  const accept = async (requestBody) => {
    await LabServices.updateOSType(cmdbLabTypeId, requestBody)
      .then((response) => {
        if (response.status === 200) {
          toast.current.show({
            severity: "success",
            summary: "OS Type Successfully Updated",
            detail: "The OS Type has been successfully updated.",
            life: 6000,
          });
          updateOSTypeDialogMap["updateOSTypeDisplay"](false);
        } else {
          loglevel.debug("OS Type Updation is Failed");
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setCommonErrorMsg(error.response.data.msg);
          errorRef.current.scrollIntoView({ behavior: "smooth" });
        }
      });

    await LabServices.getCMDBLabTypeTags().then((res) => {
      setChanger(res.data);
    });
  };

  const onUpdate = (e) => {
    setIsValidated(true);
    <Toast ref={toast} position="top-center" />;
    if (osTypeName && description) {
      const requestBody = {
        name: `${osTypeName}`,
        description: `${description}`,
      };

      var confirmationMsg = `Want to update the changes?`;

      confirmPopup({
        target: e.currentTarget,
        message: parse(`${confirmationMsg}`),
        icon: "pi pi-exclamation-triangle",
        className: "popup",
        acceptLabel: "YES",
        rejectLabel: "NO",
        accept: () => accept(requestBody),
      });
    }
  };

  const renderFooter = (name) => {
    return (
      <div>
        <Button
          className="calender-button"
          style={{ background: "#49AFD9" }}
          onClick={(e) => onUpdate(e)}
        >
          <span className="name">UPDATE</span>
        </Button>
      </div>
    );
  };

  return (
    <div >
      <Toast ref={toast} position="top-center" />
      <Button
        icon="pi pi-pencil"
        className="p-button-rounded p-button-success ml-2"
        onClick={() => onClick("updateOSTypeDisplay")}
      />
      <Dialog
        header={`Update OS Type: ${osName}`}
        visible={updateOSTypeDisplay}
        footer={renderFooter("updateOSTypeDisplay")}
        onHide={() => onHide("updateOSTypeDisplay")}
        className="tag-dialog-box"
      >
        <div ref={errorRef} className={commonErrorMsg ? "" : "hidden"}>
          {commonErrorMsg && (
            <Message
              severity="warn"
              text={commonErrorMsg}
              className="common-inline-error common-error-message p-mb-3"
            />
          )}
        </div>
        <div className="tag-name-input-text">
          <div className="tag-name-label">
            OS Type Name :<span className="cat-required-field">*</span>
          </div>
          <div>
            <InputText
              value={osTypeName}
              onChange={(e) => setOSTypeName(e.target.value)}
              placeholder="Enter OS Type Name"
              className="tag-name-text-box"
            />
          </div>
        </div>
        <div className="tag-desc-input-text">
          <div className="tag-name-label">
            Description :<span className="cat-required-field">*</span>
          </div>
          <div>
            <InputTextarea
              value={
                description
              }
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter Description"
              maxLength={1000}
              autoResize={true}
              rows={2}
              cols={40}
              className={
                !description && isValidated
                  ? "tag-desc-input-error"
                  : "tag-desc-text-box"
              }
            />
            {!description && isValidated ? (
              <div className="cat-error-message-inline">
                <small className="cat-error-message" id="error-message">
                  Description is mandatory
                </small>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default UpdateOSType;
