import React, { useState, useEffect, useRef } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Button } from "primereact/button";
import LabServices from "../../../services/LabServices";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import "../Reports.css";
import { SplitButton } from "primereact/splitbutton";
import moment from "moment";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import LoadingSpinner from "../../loader/LoadingSpinner";
import loglevel from "../../../logger/Logger";
import { Toast } from "primereact/toast";
import { Constants } from "../../../constants/constants";

const WeeklyPercentUtilizationReport = () => {
    const dt = useRef(null);
    const toast = useRef(null);
    const exportButton = useRef(null);
    const [assetWeeklyPercentUsageData, setAssetWeeklyPercentUsageData] = useState([]);
    const [selectedDays, setSelectedDays] = useState({ filterByDays: "Last 90 days", days: 90 });
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [loading, setLoading] = useState(false);
    // Dropdown options for login report
    const filterDays = [
        { filterByDays: "Last 7 days", days: 7 },
        { filterByDays: "Last 15 days", days: 15 },
        { filterByDays: "Last 30 days", days: 30 },
        { filterByDays: "Last 90 days", days: 90 },
    ];

    // method called on changing start date
    const startDateChange = (e) => {
        setStartDate(e.target.value);
    };

    // method called on changing end date
    const endDateChange = (e) => {
        setEndDate(e.target.value);
    };

    // search button to search data based on start and end dates
    const searchHandle = async () => {
        let g1 = new Date(startDate);
        let g2 = new Date(endDate);

        if (g1.getTime() < g2.getTime()) {
            let d1 = moment(startDate).format("YYYY-MM-DD");
            let d2 = moment(endDate).format("YYYY-MM-DD");

            let param = `?startDate=${d1}&endDate=${d2}`
            await fetchData(param)
        }
        else if (g1.getTime() > g2.getTime()) {
            console.log("g1 is greater than g2");
            return false;
        }
        else {
            console.log("both are equal");
            return false;
        }
    };

    // resetting fields to default on reset button click
    const resetHandle = async (e) => {
        setStartDate("");
        setEndDate("");
        setSelectedDays({ filterByDays: "Last 90 days", days: 90 });
        let days = 90;
        let param = `?days=${days}`
        await fetchData(param)
    };

    const fetchData = async (param) => {
        setLoading(true)
        await LabServices.getAssetUtilizationWeeklyPercentage(param).then((res) => {
            setAssetWeeklyPercentUsageData(res.data);
        }).catch((error) => {
            loglevel.error("getAssetUtilizationDailyPercentage", error);
        }).finally(() => {
            setLoading(false)
        });
    }

    useEffect(() => {
        async function fetch() {
            let days = 90;
            let param = `?days=${days}`
            await fetchData(param)
        }
        fetch()
    }, []);

    // method call on changing days from dropdown
    const onDaysChange = async (e) => {
        console.log(e.value, "city")
        setSelectedDays(e.value);
        let days = 90;
        if (e.value.days === 7) {
            days = 7;
        } else if (e.value.days === 30) {
            days = 30;
        } else if (e.value.days === 90) {
            days = 90;
        } else {
            days = 15
        }
        let param = `?days=${days}`
        await fetchData(param)
    }

    // method to export data in csv file
    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
        toast.current.show({
            severity: "success",
            summary: Constants.EXPORT_TOAST_SUMMARY,
            detail: Constants.EXPORT_TOAST_DETAIL,
            life: 6000,
        })
    };

    // method to export data in pdf format
    const exportPdf = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "SDE Labs Weekly Percentage Utilization";
        // pdf column name
        const headers = [
            [
                "#",
                "WEEK",
                "TOTAL USED COUNT",
                "PERCENTAGE UTILIZATION"
            ],
        ];

        //populating data in pdf
        const data = assetWeeklyPercentUsageData.map((weeklyData, index) => [
            index + 1,
            `${weeklyData.week} (From ${weeklyData.weekStartDate} to ${weeklyData.weekEndDate})`,
            weeklyData.weeklyUsedCount,
            weeklyData.weeklyPercentUsed
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data,
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        // name of pdf
        doc.save("weekly_percentage_utilization_report.pdf");
        toast.current.show({
            severity: "success",
            summary: Constants.EXPORT_TOAST_SUMMARY,
            detail: Constants.EXPORT_TOAST_DETAIL,
            life: 6000,
        })
    };

    // dropdown to show pdf and csv
    const items = [
        {
            label: "CSV",
            icon: "pi pi-file",
            command: (e) => {
                exportCSV();
            },
        },
        {
            label: "PDF",
            icon: "pi pi-file-pdf",
            command: (e) => {
                exportPdf();
            },
        },
    ];

    const onIndexTemplate = (rowData, props) => {
        return props.rowIndex + 1;
    };

    const percentTemplate = (rowData) => {
        return (
            <div>
                {`${rowData.weeklyPercentUsed} %`}
            </div>
        );
    };

    const weekTemplate = (rowData) => {
        return (
            <div>
                {`${rowData.week} (From ${rowData.weekStartDate} to ${rowData.weekEndDate})`}
            </div>
        );
    };


    return (
        <div>
            <Toast ref={toast} position="top-center" />
            {loading ?
                <div className="loader">
                    <LoadingSpinner />
                </div> :
                <div className="container">
                    <br />
                    <div className="panel report-panel panel-content">
                        <div className="panel">
                            <div className="panel-item">
                                {/* Dropdown option to show days */}
                                <Dropdown
                                    value={selectedDays}
                                    options={filterDays}
                                    onChange={onDaysChange}
                                    optionLabel="filterByDays"
                                    placeholder="Select quick dates"
                                    className="quick-date-selector"
                                />
                            </div>
                            <div className="panel-item start-date-calender">
                                {/* Start date */}
                                <Calendar
                                    // id="range"
                                    value={startDate}
                                    onChange={(e) => startDateChange(e)}
                                    dateFormat="mm/dd/yy"
                                    mask="99/99/9999"
                                    selectOtherMonths={true}
                                    placeholder="Select Start Date"
                                    icon="pi pi-calendar"
                                    showIcon
                                    className="date-icon"
                                />

                            </div>
                            <div className="panel-item end-date-calender">
                                {/* end date */}
                                <Calendar
                                    value={endDate}
                                    onChange={(e) => endDateChange(e)}
                                    dateFormat="mm/dd/yy"
                                    mask="99/99/9999"
                                    selectOtherMonths={true}
                                    placeholder="Select End Date"
                                    icon="pi pi-calendar"
                                    showIcon
                                    className="date-icon"
                                />
                            </div>
                        </div>
                        <div className="action-buttons">
                            <div className="panel-item">
                                {/* search button */}
                                <Button
                                    id="search-button"
                                    label="SEARCH"
                                    className="p-button-primary search-btn"
                                    onClick={searchHandle}
                                />
                            </div>
                            <div className="panel-item">
                                {/* Reset button */}
                                <Button
                                    id="reset-button"
                                    label="RESET"
                                    className="p-button-primary reset-btn"
                                    onClick={resetHandle}
                                />
                            </div>
                            <div className="panel-item p-splitbutton">
                                {/* split button to show csv and pdf export option */}
                                <SplitButton
                                    ref={exportButton}
                                    className="p-splitbutton-button export-btn"
                                    onClick={() => exportButton.current.onDropdownButtonClick()}
                                    label="EXPORT"
                                    model={items}
                                ></SplitButton>
                            </div>
                        </div>
                    </div>

                    <br />
                    <div className="utilization-table-container">
                        <DataTable
                            ref={dt}
                            value={assetWeeklyPercentUsageData}
                            responsiveLayout="scroll"
                            className="p-datatable-customers"
                            columnResizeMode="fit"
                            paginator
                            rows={50}
                            totalRecords={assetWeeklyPercentUsageData.length}
                            pageLinkSize={1}
                            rowsPerPageOptions={[5, 10, 20, 50]}
                            removableSort={true}
                            exportFilename="weekly_percentage_utilization_report"
                        >
                            <Column
                                field="Index"
                                header="#"
                                style={{ textAlign: "left", fontWeight: "600", width: "2rem", paddingLeft: "15px" }}
                                body={onIndexTemplate}
                                exportable={false}
                            />
                            <Column
                                field="week"
                                sortable
                                header="Week"
                                body={weekTemplate}
                                style={{ textAlign: "center" }}
                            />
                            <Column
                                field="weeklyUsedCount"
                                sortable
                                header="Total Used Count"
                                style={{ textAlign: "center" }}
                            ></Column>
                            <Column
                                field="weeklyPercentUsed"
                                sortable
                                header="Percentage Utilization"
                                style={{ textAlign: "center" }}
                                body={percentTemplate}
                            ></Column>

                        </DataTable>
                    </div>
                </div>
            }
        </div>

    );
}

export default WeeklyPercentUtilizationReport;