import React, { useState } from 'react'
import AdminMessage from '../admin-messages/AdminMessage';
import AdminMessageHistory from '../admin-messages/AdminMessageHistory';
import './Admin.css'

import { useGlobalState } from '../../config/state';

const MessagesMgmt = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [isAdmin, setIsAdmin] = useGlobalState('isAdmin');

    const setIndex = (index) => {
        setActiveIndex(index);
    }
    return (
        <div>
            {(isAdmin) ? (
                <>
                    <div className="tabmenu">
                        {/* Admin Message Tab */}
                        <button
                            className="menuitem-link"
                            style={{
                                borderColor:
                                    activeIndex === 0
                                        ? "#979797 #979797 #192a32 #979797" // Border colour of tab when it is active
                                        : "#192a32 #192a32 #979797 #192a32", // Border colour of tab when it is inactive
                            }}
                            onClick={() => setIndex(0)} // on click Admin message tab
                            data-testid="all-labs-title"
                        >
                            <div className={activeIndex === 0 ? "active-tab" : "inactive-tab"}> Share New Messages</div>
                        </button>
                        {/* Admin Message History Tab */}
                        <button
                            className="menuitem-link"
                            style={{
                                borderColor:
                                    activeIndex === 1
                                        ? "#979797 #979797 #192a32 #979797" // Border colour of tab when it is active
                                        : "#192a32 #192a32 #979797 #192a32", // Border colour of tab when it is inactive
                            }}
                            onClick={() => setIndex(1)} // on click Admin History Tab
                        >
                            <div className={activeIndex === 1 ? "active-tab" : "inactive-tab"}> History</div>
                        </button>
                    </div>
                    <div className="data">
                        {activeIndex === 0 ? <AdminMessage /> : ""}        {/*Admin Message Tab */}
                        {activeIndex === 1 ? <AdminMessageHistory /> : ""} {/*Admin Message History Tab */}
                    </div>
                </>
            ) : (
                window.location.assign("/not-found")
            )}
        </div>
    )
}

export default MessagesMgmt