import React, { useState, useRef } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { InputSwitch } from 'primereact/inputswitch'
import { Divider } from 'primereact/divider'
import { Checkbox } from 'primereact/checkbox'
import { MultiSelect } from 'primereact/multiselect'
import "./UserGroups.css";
import "../../Rbac.css";
import LabServices from "../../../../services/LabServices";
import { Toast } from "primereact/toast";
import loglevel from "../../../../logger/Logger";
import eventBus from '../../../utility/EventBus'
import { confirmPopup } from "primereact/confirmpopup";
import parse from "html-react-parser";
import { Message } from "primereact/message";

const EditUserGroup = ({ groupId, groupNameProp, setChanger }) => {

    const [EditUserGroupDisplay, setEditUserGroupDisplay] = useState(false);
    const [enableGroup, setEnableGroup] = useState(false);
    const [selectedTagValue, setSelectedTagValue] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [permissionTags, setPermissionTags] = useState([]);
    const [tag1, setTag1] = useState(false);
    const [tag2, setTag2] = useState(false);
    const [tag3, setTag3] = useState(false);
    const [members, setMembers] = useState([]);
    const toast = useRef(null);
    const [groupName, setGroupName] = useState("");
    const [groupDesc, setGroupDesc] = useState("");
    const [isValidated, setIsValidated] = useState(false);
    const [userGroupWarningMsg, setUserGroupWarningMsg] = useState('');
    const [userGroupErrorMsg, setUserGroupErrorMsg] = useState('');


    const EditUserGroupDialogMap = {
        EditUserGroupDisplay: setEditUserGroupDisplay,
    };

    // when user clicks on pencil icon
    const onClick = async (name) => {
        
        setIsValidated(false);
        setUserGroupWarningMsg('');
        // fetches data for that specific group
        await LabServices.getUserGroup(groupId).then((res) => {
            setGroupName(res.data.group_name);
            setGroupDesc(res.data.group_desc);
            setEnableGroup(res.data.enabled);

            let selectedUsersDisplayData = res.data.users.map(user => {
                return { ...user, displayName: `${user.name} (${user.email})` }
            })

            let totalUsersDisplayData = res.data.total_users.map(user => {
                return { ...user, displayName: `${user.name} (${user.email})` }
            })
            setMembers(totalUsersDisplayData)
            setSelectedMembers(selectedUsersDisplayData);

            let selectedTagArray = []
            let formattedSelectedTagArray = []
            for (var i = 0; i < res.data.tags.length; i++) {
                //predefined tags read-only ,portal-user and cec-dev-test
                if (res.data.tags[i] === "read-only") {
                    setTag1(true)
                } else if (res.data.tags[i] === "portal-user") {
                    setTag2(true)
                } else if (res.data.tags[i] === "cec-dev-test") {
                    setTag3(true)
                } else {
                    selectedTagArray.push(res.data.tags[i])
                }
            }
            selectedTagArray.forEach(element => {
                formattedSelectedTagArray.push({ tag: element })
            });
            setSelectedTagValue(formattedSelectedTagArray);
        })
        let permissionTagsArray = [];

        //fetching permission tag list
        await LabServices.getPermissionTagList().then((res) => {

            let additionalTagArray = []
            for (var i = 0; i < res.data.length; i++) {

                if (res.data[i] === "read-only" || res.data[i] === "cec-dev-test" || res.data[i] === "portal-user") {

                    continue
                } else {
                    additionalTagArray.push(res.data[i])
                }

            }

            additionalTagArray.forEach(element => {
                permissionTagsArray.push({ tag: element })
            });
            setPermissionTags(permissionTagsArray);

        })


        //opening edit user group dialog
        EditUserGroupDialogMap[`${name}`](true);
    };

   // on closing edit User Group dialog
    const onHide = (name) => {
        EditUserGroupDialogMap[`${name}`](false);
        setIsValidated(false);
        setUserGroupWarningMsg('')
    };
 
    //on adding members
    const onMemberAdd = (e) => {
        if(!tag1 && !tag2 && !tag3 && !selectedTagValue.length) { // if no tag selected
            setUserGroupWarningMsg('If no tag is selected, the selected members will not be added to the group.')
        }
        setSelectedMembers(e.target.value) // setting member
    }

    // on updating user group details
    const onUpdate = (e) => {
        setIsValidated(true);
        <Toast ref={toast} position="top-center"/>;

        let tags = []  // contains predefined(read-only,portal-user,cec-dev-test) permission tags only
        if (tag1 === true) {
            tags.push('read-only')

        }
        if (tag2 === true) {
            tags.push("portal-user")
        }

        if (tag3 === true) {
            tags.push("cec-dev-test")
        }

        let TagObjArray = [...selectedTagValue];
        let unformattedTagArray = []; //contains

        TagObjArray.forEach(e => {
            unformattedTagArray.push(e.tag)
        })

        let tagArray = [...new Set([...tags, ...unformattedTagArray])]
        let userIdArray = selectedMembers.map(user => {
            return user.users_id
        });
        if (groupName && groupDesc) {
            const requestBody = {
                group_name: groupName,
                group_desc: groupDesc,
                enabled: enableGroup,
                tags: tagArray,
                users: userIdArray
            }

            var confirmationMsg = `Want to update the changes?`;

            confirmPopup({
                target: e.currentTarget,
                message: parse(`${confirmationMsg}`),
                icon: "pi pi-exclamation-triangle",
                className: "popup",
                acceptLabel: "YES",
                rejectLabel: "NO",
                accept: () => accept(requestBody),
            });
        }
    };

    const accept = async (requestBody) => {

        await LabServices.UpdateUserGroup(groupId, requestBody)
                .then((response) => {
                    if (response.status === 200) {
                        toast.current.show({
                            severity: "success",
                            summary: "User group successfully updated",
                            detail: "User Group has been successfully updated.",
                            life: 6000,
                        });
                        EditUserGroupDialogMap["EditUserGroupDisplay"](false);
                        eventBus.dispatch("pageReloaded", { message: "pageReloaded" });
                    } else {
                        loglevel.debug("User Group updation is Failed");
                    }
                })
                .catch((error) => {
                loglevel.error("Edit User group", error.response.data);
                    if (error.response.status === 400) {
                    setUserGroupErrorMsg(error.response.data.msg);
                    }
                });

            await LabServices.getUserGroupList().then((res) => {
                setChanger(res.data)
            })

    };

    const renderFooter = (name) => {
        return (
            <div className="terms-section">
                <Button
                    className='create-user-button'
                    label="UPDATE GROUP"
                    style={{ background: "#49AFD9" }}
                    onClick={(e) => onUpdate(e)}
                />
            </div>
        );
    };
    return (
        <div className="panel-content user-tag-edit">
            <Toast ref={toast} position="top-center"/>
            <div className="create-lab-button">
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-success mr-2"
                    onClick={() => onClick("EditUserGroupDisplay")}
                />
            </div>

            <Dialog
                header={`Update User Group : ${groupNameProp}`}
                visible={EditUserGroupDisplay}
                footer={renderFooter("EditUserGroupDisplay")}
                onHide={() => onHide("EditUserGroupDisplay")}
                className="lab-dialog-box"
                style={{ width: "700px" }}
            >
      {userGroupErrorMsg && <Message severity="warn" text={userGroupErrorMsg} className='common-inline-error common-error-message p-mb-3'/>}
                <div className="dialog-content">
                    {/* Group Name */}
                    <div className="section first-section">
                        <div className="name-input-text">
                            <div className="namefield-label">Group Name<span className="lab-required-field">*</span> :</div>
                            <div>
                                <InputText
                                    value={groupName}
                                    onChange={(e) => setGroupName(e.target.value)}
                                    placeholder="Enter Group Name"
                                    maxLength={20}
                                    className={!groupName && isValidated ? "asset-input-error usergroup-text-box" : "usergroup-text-box"}

                                />
                                <div>
                                    {!groupName && isValidated ? (
                                        <div>
                                            <small className="p-error p-d-block" id="error-message">Group name is mandatory</small>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Group desc */}
                    <div className="section second-section">
                        <div className="name-input-text">
                            <div className="namefield-label">Group Description<span className="lab-required-field">*</span> :</div>
                            <div>
                                <InputText
                                    value={groupDesc}
                                    onChange={(e) => setGroupDesc(e.target.value)}
                                    placeholder="Enter Group Description"
                                    maxLength={200}
                                    className={!groupDesc && isValidated ? "asset-input-error usergroup-text-box" : "usergroup-text-box"}
                                />
                                <div>
                                    {!groupDesc && isValidated ? (
                                        <div>
                                            <small className="p-error p-d-block" id="error-message">Group description is mandatory</small>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Enable Group */}
                    <div className="section third-section">
                        <div className="name-input-text">
                            <div className="namefield-label">Enable Group:</div>
                            <InputSwitch
                                checked={enableGroup}
                                onChange={(e) => setEnableGroup(e.value)}
                            />
                            <div className='category-enable-text'>{enableGroup ? "Group is Active." : "Group is Inactive."}</div>
                        </div>
                    </div>
                    <Divider />
                    {/* Tags */}
                    <div className="section fourth-section">
                        <div className='usergroup-tag-section'>
                            <div className='section tags-label'>Tags<span className="optional-fields"> (Optional)</span> :</div>
                            <div className="lab-tags section">
                                <div className="lab-tag">
                                    <Checkbox
                                        onChange={(e) => setTag1(e.checked)}
                                        checked={tag1}
                                    ></Checkbox>
                                    <label htmlFor="cb1" className="checkbox-label">
                                        read-only
                                    </label>
                                </div>
                                <div className="lab-tag">
                                    <Checkbox
                                        onChange={(e) => setTag2(e.checked)}
                                        checked={tag2}
                                    ></Checkbox>
                                    <label htmlFor="cb1" className="checkbox-label">
                                        Portal-User
                                    </label>
                                </div>
                                <div className="lab-tag">
                                    <Checkbox
                                        className="lab-checkbox"
                                        onChange={(e) => setTag3(e.checked)}
                                        checked={tag3}
                                    ></Checkbox>
                                    <label htmlFor="cb1" className="checkbox-label">
                                        cec-dev-test
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="tag-dropdown section usergroup-tag-multiselect">
                            <MultiSelect
                                showSelectAll={false}
                                value={selectedTagValue}
                                name="category-dropdown"
                                options={permissionTags}
                                onChange={(e) => setSelectedTagValue(e.target.value)}
                                placeholder="Select Tags"
                                className="tags-dropdown select-permissions"
                                filter
                                display="chip"
                                allowDuplicate={false}
                                optionLabel="tag"
                            />
                        </div>
                    </div>     
                    {/* Members */}
                    <div className="section fifth-section">
                        <div className="name-input-text">
                            <div className="namefield-label">Members<span className="optional-fields"> (Optional)</span> :</div>
                            <div>
                                <MultiSelect
                                    showSelectAll={false}
                                    value={selectedMembers}
                                    name="category-dropdown"
                                    options={members}
                                    onChange={(e) => onMemberAdd(e)}
                                    placeholder="Search by Name or Email"
                                    className="groups-dropdown select-groups"
                                    filter
                                    optionLabel='displayName'
                                    display='chip'
                                />
                            </div>
                        </div>
                        <div>
                        {userGroupWarningMsg && <Message severity="warn" text={userGroupWarningMsg} className='common-inline-error common-inline-warn p-mt-2' />}
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default EditUserGroup