import React, { useEffect, useRef , useState } from "react";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { useGlobalState } from "../../config/state";
import { Tag } from "primereact/tag";
import NetBoxService from "../../services/NetBoxService";
import loglevel from "../../logger/Logger";
import LoadingSpinner from "../loader/LoadingSpinner";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {Calendar} from "primereact/calendar";
import VirtualEnvironmentDetails from "../rbac/inventory-mgmt/virtual-environment/VirtualEnvironmentDetails";
import UpdateVirtualEnv from "../rbac/inventory-mgmt/virtual-environment/UpdateVirtualEnv";

const NetBoxInventory = () => {
  const [isAdmin, setIsAdmin] = useGlobalState("isAdmin");
  const [globalFilter, setGlobalFilter] = useState(null);
  const [netBoxDevices, setnetBoxDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date(new Date().getTime() + 1 * 60 * 60 * 1000));
  const [dateRange, setDateRange] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [visible, setVisible] = useState(false);
  const [detailsVisible, setdetailsVisible] = useState(false);
  const [row, setRow] = useState({});
  const [position, setPosition] = useState('center');
  const [updateVirtualEnvDisplay, setupdateVirtualEnvDisplay] = useState(false);

  const dt = useRef(null);
  const toast = useRef(null);
  const MAX_GROUPS = 5;

  async function sendAPIRequest(row) {
    console.log("Sending Netbox device reservation API request for deviceid=",row.id,"dateRange=",dateRange)
    let fname = localStorage.getItem("fname")
    let lname = localStorage.getItem("lname")
    let email = localStorage.getItem("email")
    let users_id = localStorage.getItem("users_id")
    let username = fname + ' ' + lname
    let reqBody = {
      username: username,
      email: email,
      device_id:row.id,
      start_end_dates: dateRange
    }
      // await NetBoxService.reserveNetboxDevice(row.id,dateRange,fname).then( async (res)=> {
      await NetBoxService.reserveNetboxDevice(reqBody).then( async (res)=> {
        console.log("RESP =",res)
        if(res.status === 200) {
          const reserve_details = `Device: ${row.name} Owner: ${username} FROM:${dateRange[0]} TO:${dateRange[1]}`
          toast.current.show({
          severity: "success",
          summary: "Reservation Success",
          detail: `${reserve_details}`,
          life: 8000,
        });
        }
      })
      .catch( (error)=> {
        loglevel.error("reserveNetboxDevice", error);
        toast.current.show({
          severity: "fail",
          summary: "Reservation Failed",
          detail: row.id,
          life: 9999,
        });
      })

     // Reload the page
     setVisible(false)
     setLoading(false);
     await getNetBoxDeviceList();
    }

    async function getNetBoxDeviceList(){
      await NetBoxService.getNetboxDevicesList().then( (res)=> {
          console.log("RESP =",res);
          for(var i=0; i<res.data.length;i++){
            res.data[i].tags_string = "";
            for(var j=0;j<res.data[i].tags.length; j++) {
              res.data[i].tags_string += res.data[i].tags[j].name;
            }
          }
          setnetBoxDevices(res.data);
      })
      .catch( (error)=> {
        loglevel.error("getNetBoxDeviceList", error);
      })
    }

  useEffect( async () => {
    await getNetBoxDeviceList();
    setLoading(false);
  },[]);

  const toggleGroups = (props, rowData) => {
    rowData.closedGroupsStatus = !rowData.closedGroupsStatus
    setnetBoxDevices((prevState) => [...prevState])
  }

  const groupTemplate = (rowData, props) => {
    let groupsArray = rowData.guideLabel;
    let allGroupsCount = groupsArray?.length;
    let hiddenGroupscount = (allGroupsCount - MAX_GROUPS);
    let closedGroupsStatusRow = rowData.closedGroupsStatus

    if (closedGroupsStatusRow) {
      groupsArray = rowData.guideLabel.slice(0, MAX_GROUPS)
    }
    else {
      groupsArray = rowData.guideLabel
    }
    const val = groupsArray?.map((item, index) => {
      return <a href={item.user_guide_link}
        target="_blank"
        rel="noopener noreferrer" className='link-tag'>
        <Tag key={index}  >
          <div className='link-icon-container guide-links'>
            <div className='link-icon'>
              <div className="link-icon-div">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M10.0843 3.91002C11.2532 2.75346 13.1354 2.75346 14.3043 3.91002L14.2943 3.93002C14.8522 4.48549 15.1657 5.24029 15.1657 6.02752C15.1657 6.81475 14.8522 7.56956 14.2943 8.12502L11.9493 10.45C10.7804 11.6066 8.89825 11.6066 7.72931 10.45C7.49316 10.2145 7.29873 9.94063 7.15431 9.64002L7.79931 9.00002C7.8424 8.95753 7.88926 8.91904 7.93931 8.88502C8.02876 9.20866 8.20111 9.50337 8.43931 9.74002C9.2182 10.5089 10.4704 10.5089 11.2493 9.74002L13.5943 7.41502C13.9628 7.04732 14.17 6.54812 14.17 6.02752C14.17 5.50693 13.9628 5.00772 13.5943 4.64002C12.8154 3.87118 11.5632 3.87118 10.7843 4.64002L9.55431 5.86002C9.21206 5.76701 8.85897 5.71993 8.50431 5.72002H8.25931L10.0843 3.91002ZM7.56931 13.38L8.79931 12.16C9.14084 12.2458 9.49221 12.2862 9.84431 12.28H10.0943L8.26931 14.09C7.10037 15.2466 5.21825 15.2466 4.04931 14.09C3.49023 13.5352 3.17578 12.7802 3.17578 11.9925C3.17578 11.2049 3.49023 10.4498 4.04931 9.89502L6.39931 7.55002C7.56825 6.39346 9.45037 6.39346 10.6193 7.55002C10.8555 7.78552 11.0499 8.05941 11.1943 8.36002L10.5493 9.00002C10.5087 9.04922 10.4635 9.09443 10.4143 9.13502C10.3249 8.81138 10.1525 8.51668 9.91431 8.28002C9.13542 7.51118 7.88321 7.51118 7.10431 8.28002L4.75931 10.605C4.39078 10.9727 4.18367 11.4719 4.18367 11.9925C4.18367 12.5131 4.39078 13.0123 4.75931 13.38C5.53821 14.1489 6.79042 14.1489 7.56931 13.38Z" fill="#1B2A32" />
              </svg>
              </div>
            </div>
            <div className='link-value'>{item.label_name}</div></div></Tag>
      </a>
    });
    return <span>
      <span className="data-tag parent left-align">{val}</span>
      {hiddenGroupscount > 0 ?
        <span onClick={() => toggleGroups(props, rowData)} className="show-more">

          {closedGroupsStatusRow ? `${hiddenGroupscount} more links` : `less links`}</span> : ""}
    </span>
  };

  const onIndexTemplate = (data, props) => {
    return <div className="index-field">{props.rowIndex + 1}</div>;
  };

  const showToastSuccess = (data) => {
        toast.current.show({
          severity: "success",
          summary: data.summary,
          detail: data.detail,
          life: 6000,
        });
  }

  function setDateValues(e) {
    setStartDate(e.value)
    console.log("startDate=",startDate)

    var fromDate = new Date(e.value[0]);
    var toDate;
    if (e.value[1] == null) {
      toDate = new Date(e.value[0]);
    } else {
      toDate = new Date(e.value[1]);
    }
    let datesArray = [];
    // All dates
    // for (let q = fromDate; q <= toDate; q.setDate(q.getDate() + 1)) {
    //   datesArray.push(q.toLocaleDateString("en-CA"));
    // }
    datesArray.push(fromDate.toLocaleDateString("en-CA"));
    datesArray.push(toDate.toLocaleDateString("en-CA"));
    console.log("datesArray=",datesArray)
    setDateRange(datesArray)
  }

  const footerContent = (rowData) => {
    // console.log("ROWDATA=",rowData)
    return (
        <div>
        <div className="card flex justify-content-center">
          <Calendar
              value={startDate}
              onChange={(e) => setDateValues(e)}
              // onHide={setStartDate(null)}
              minDate={new Date(new Date().getTime() + 1 * 60 * 60 * 1000)}
              style={{ paddingLeft: '0.5rem', paddingRight: '1.5rem'}}
              selectionMode="range"
              // hideOnRangeSelection
              // readOnlyInput
              // showIcon
              // showButtonBar
              inline
          />
        </div>
        <div style={{ paddingTop: '1.5rem', paddingRight: '1.5rem'}}>
          <Button label="Cancel" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text"/>
          <Button label="Reserve" icon="pi pi-check" onClick={() => sendAPIRequest(rowData)} autoFocus/>
        </div>
        </div>
    )
  }

  const detailsContent = (rowData) => {
    console.log("ROWDATA=",rowData)
    rowData = [rowData]
    return (
        <div >
          {/*{JSON.stringify(rowData[0], undefined, 4)}*/}
                <DataTable
        value={rowData}
        ref={dt}
        className="p-datatable-customers"
        columnResizeMode="fit"
        // paginator
        // rows={100}
        // pageLinkSize={1}
        // rowsPerPageOptions={[5, 10, 20, 50, 100]}
        globalFilter={globalFilter}
        responsiveLayout="scroll"
        autoLayout={true}
        let-i="rowIndex"
        style={{ width: "100%" }}
        removableSort={true}
      >
        <Column
          sortable
          field="id"
          header="Device ID"
          style={{ width: "14rem", fontFamily: "Metropolis-Semibold" }}
        ></Column>
        <Column
          sortable
          field="name"
          header="Device name"
          style={{ width: "14rem", fontFamily: "Metropolis-Semibold" }}
        ></Column>
        <Column
          sortable
          field="role.name"
          header="Role"
          style={{ width: "14rem", fontFamily: "Metropolis-Semibold" }}
        ></Column>
        <Column
          sortable
          field="device_type.manufacturer.display"
          header="Manufacturer"
          style={{ width: "14rem", fontFamily: "Metropolis-Semibold" }}
        ></Column>
        <Column
          sortable
          field="custom_fields.poc_project_name"
          header="Project"
          style={{ width: "14rem", fontFamily: "Metropolis-Semibold" }}
        ></Column>
        <Column
          sortable
          field="device_type.model"
          header="Device Type"
          style={{ width: "14rem", fontFamily: "Metropolis-Semibold" }}
        ></Column>
                </DataTable>
        </div>
    )
  }

  const showDetails = (rowData) => {
    console.log("rowData=",rowData)
    let rowbackup = rowData
    setdetailsVisible(true)
    setRow(rowbackup)
  }

  const reserveNetbox = (rowData) => {
    console.log("rowData=",rowData)
    let rowbackup = rowData
    setVisible(true)
    setRow(rowbackup)
  }

  const actionBodyTemplate = (rowData) => {
    return (
        // <div className="table-action-body p-jc-end">
        //     <div>
        //         <VirtualEnvironmentDetails
        //             virEnvId={rowData.virtual_environment_id}
        //         />
        //     </div>
        //     <UpdateVirtualEnv
        //         rowId={rowData.virtual_environment_id}
        //         isDisabled={rowData.is_editable}
        //         fetchData={fetchData}
        //         showToastSuccess={showToastSuccess}
        //     />
        // </div>
        <div className="table-action-body p-jc-end">
            <div className="update-virtual-env-actions p-d-flex">
                <Button
                    icon="pi pi-calendar"
                    className="p-button-rounded"
                    disabled={false}
                    onClick={() => reserveNetbox(rowData)}
                />
            <Dialog
                header={`Schedule/Reserve: ${row.name}`}
                visible={visible}
                position={position}
                style={{ width: '20%'}}
                onHide={() => {if (!visible) return; setVisible(false); }}
                footer={footerContent(row)}
                draggable={false} resizable={false}
            ></Dialog>
            </div>
        </div>
    );
  };

    const seedetailsTemplate = (rowData) => {
    return (
        // <div className="table-action-body p-jc-end">
        //     <div>
        //         <VirtualEnvironmentDetails
        //             virEnvId={rowData.virtual_environment_id}
        //         />
        //     </div>
        //     <UpdateVirtualEnv
        //         rowId={rowData.virtual_environment_id}
        //         isDisabled={rowData.is_editable}
        //         fetchData={fetchData}
        //         showToastSuccess={showToastSuccess}
        //     />
        // </div>
        <div className="table-action-body p-jc-end">
          <div className="update-virtual-env-actions p-d-flex">
            <div
                className="asset-details"
                onClick={() => showDetails(rowData)}
            >
              See Details
            </div>
            <Dialog
                header={`Device Details: ${rowData.name}`}
                visible={detailsVisible}
                position={position}
                style={{width: '80%'}}
                onHide={() => {
                  if (!detailsVisible) return;
                  setdetailsVisible(false);
                }}
                footer={detailsContent(row)}
                // draggable={true} resizable={true}
            ></Dialog>
          </div>
        </div>
    );
    };

  return (
      <div>
        <Toast ref={toast} position="top-center"/>
        {loading ? (
            <div className="loader">
            <LoadingSpinner/>
                </div>
            ) : (
                <div className="virtual-env-container">
                    <div className="admin-panel">
                        <div className="admin-panel-search-bar">
                          <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText
                                type="search"
                                onInput={(e) => setGlobalFilter(e.target.value)}
                                placeholder="Search"
                                className="global-search-filter"
                            />
                          </span>
                        </div>
                      </div>
      {isAdmin?
      <DataTable
        value={netBoxDevices}
        // ref={dt}
        className="p-datatable-customers"
        columnResizeMode="fit"
        paginator
        rows={100}
        pageLinkSize={1}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        globalFilter={globalFilter}
        responsiveLayout="scroll"
        autoLayout={true}
        let-i="rowIndex"
        // style={{ width: "100%" }}
        removableSort={true}
      >
        <Column
            sortable
            header="Device ID"
            field="id"
            // body={onIndexTemplate}
            // style={{ fontWeight: "600", minWidth: "8rem", maxWidth: "8rem", textAlign: "center" }}
            // style={{ width: "3rem", paddingLeft: "15px", textAlign:"center" }}
            style={{ textAlign: "center" }}
        ></Column>
        <Column
          sortable
          field="custom_fields.poc_project_owner"
          header="Owner"
          style={{ width: "14rem", fontFamily: "Metropolis-Semibold" }}
        ></Column>
        <Column
          sortable
          field="description"
          header="Description"
          style={{ width: "14rem", fontFamily: "Metropolis-Semibold" }}
        ></Column>
        <Column
          sortable
          field="custom_fields.poc_project_startdate"
          header="Start Date"
          style={{ width: "14rem", fontFamily: "Metropolis-Semibold" }}
        ></Column>
        <Column
          sortable
          field="custom_fields.poc_project_duration"
          header="Expiry Date"
          style={{ width: "14rem", fontFamily: "Metropolis-Semibold" }}
        ></Column>
        <Column
          sortable
          field="name"
          header="Netbox Device"
          style={{ width: "14rem", fontFamily: "Metropolis-Semibold" }}
        ></Column>
        <Column
          sortable
          field="tags_string"
          header="TAGS"
          style={{ width: "14rem", fontFamily: "Metropolis-Semibold" }}
        ></Column>
        <Column
          sortable
          field="device_type.display"
          header="Device Type"
          style={{ width: "14rem", fontFamily: "Metropolis-Semibold" }}
        ></Column>
        <Column
          sortable
          field="status.label"
          header="Status"
          style={{ width: "14rem", fontFamily: "Metropolis-Semibold" }}
        ></Column>
        <Column
          sortable
          field="device_type.manufacturer.name"
          header="Manufacturer"
          style={{ width: "14rem", fontFamily: "Metropolis-Semibold" }}
        ></Column>
        <Column
          sortable
          field="custom_fields.poc_project_name"
          header="Project"
          style={{ width: "14rem", fontFamily: "Metropolis-Semibold" }}
        ></Column>
        <Column
          className="setting-icon-column"
          body={seedetailsTemplate}
          // style={{ minWidth: "3rem" }}
          // colSpan={2}
        ></Column>
        <Column
          header="Reserve"
          className="setting-icon-column"
          body={actionBodyTemplate}
          // style={{ minWidth: "3rem" }}
          // colSpan={2}
        ></Column>
      </DataTable>:
          <DataTable></DataTable>
    }
    </div>
      )}
    </div>
  );
};

export default NetBoxInventory;
