import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
  const auth = localStorage.getItem("isAuthenticated"); // determine if authorized

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return auth === "true" ? <Outlet /> : <Navigate to="/" />;
}

export default ProtectedRoute;
