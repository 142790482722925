import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from "primereact/dialog";
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { Dropdown } from 'primereact/dropdown';
import GuideService from '../../../services/GuideService';
import loglevel from "../../../logger/Logger";

const LabUserGuideCreate = ({ labList, setChanger }) => {
  const toast = useRef(null);
  const errorRef = useRef(null);
  const [labName, setLabName] = useState("");
  const [labId, setLabId] = useState();
  const [selectedLab, setSelectedLab] = useState();
  const [createLabUserGuideDisplay, setCreateLabUserGuideDisplay] = useState(false);
  const [commonErrorMsg, setCommonErrorMsg] = useState("");
  const [labUserGuideLinks, setLabUserGuideLinks] = useState({
    guide_links: [
      {
        label_name: "",
        user_guide_link: "",
      },
    ],
  });

  const [formSubmit, setFormSubmit] = useState(false);

  const createLabUserGuideDialogMap = {
    createLabUserGuideDisplay: setCreateLabUserGuideDisplay,
  };

  useEffect(() => {

  })

  // open dialog box to create user guide
  const onClick = (name) => {
    setCommonErrorMsg("")
    createLabUserGuideDialogMap[`${name}`](true);
    setFormSubmit(false);
    setSelectedLab();
    setLabId();
    setLabName("");
    setLabUserGuideLinks({
      guide_links: [
        {
          label_name: "",
          user_guide_link: "",
        },
      ],
    })
   
  }

  const onShowDialog = (name) => {
    createLabUserGuideDialogMap[`${name}`](true);
    setFormSubmit(false);
  }

// on closing dialog
  const onHide = (name) => {
    setCommonErrorMsg("")
    createLabUserGuideDialogMap[`${name}`](false);
    setFormSubmit(false)
    setLabName("");
    setLabUserGuideLinks({
      guide_links: [
        {
          label_name: "",
          user_guide_link: "",
        },
      ],
    })

  }

  // on changing label and url 
  const AddUrlhandleChange = (idx) => (e) => {
    setFormSubmit(false)
    setCommonErrorMsg("")
    const { name, value } = e.target;
    const guide_links = [...labUserGuideLinks.guide_links];
    guide_links[idx] = {
      ...labUserGuideLinks.guide_links[idx],
      [name]: value,
    };
  
    // setting links foe user guide
    setLabUserGuideLinks({
      guide_links,
    });
  };

  // on adding row
  const handleAddRowLink = () => {

    let currentRow = labUserGuideLinks.guide_links.length - 1
    if (labUserGuideLinks.guide_links[currentRow].label_name && labUserGuideLinks.guide_links[currentRow].user_guide_link) {

      const item = {
        label_name: "",
        user_guide_link: "",
      };

      setLabUserGuideLinks({
        guide_links: [...labUserGuideLinks.guide_links, item],
      });
    }
  };

  //on Removing specific row
  const handleRemoveSpecificRow = (idx) => () => {
    const guide_links = [...labUserGuideLinks.guide_links];
    guide_links.splice(idx, 1);
    setLabUserGuideLinks({ guide_links });
  };

  const labDetails = (obj) => {
    setCommonErrorMsg("")
    setSelectedLab(obj.value);
    setLabId(obj.value.labId);
    setLabName(obj.value.name);
  }


  const renderFooter = (name) => {
    return (
      <div className="lab-btn-section">
        {/* Create Guide button in footer */}
        <Button
          onClick={(e) => addLabUserGuide(e)}
          style={{ background: "#49AFD9" }}
        >
          <span className="name">CREATE GUIDE</span>
        </Button>
      </div>
    );
  };

  // url validation
  const is_url = (str) =>
  {
    // let regexp =  /^(http:\/\/www\.|https:\/\/www\.|www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/
    var regexp = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
          if (regexp.test(str))
          {
            return true;
          }
          else
          {
            return false;
          }
  }

  //method called to create user guide
  const addLabUserGuide = async (e) => {
    setFormSubmit(true);
    let validForm = true;
    let guideLinkArray = []
    for (let i = 0; i < labUserGuideLinks.guide_links.length; i++) {
      //if guide name and url is present and successfully validated
      if (labUserGuideLinks.guide_links[i].label_name && labUserGuideLinks.guide_links[i].user_guide_link && is_url(labUserGuideLinks.guide_links[i].user_guide_link)) {
        guideLinkArray.push(
          {
            label_name: labUserGuideLinks.guide_links[i].label_name,
            user_guide_link: labUserGuideLinks.guide_links[i].user_guide_link,
          }
        )
         //if guide name or link is not present     
      } else if ((!labUserGuideLinks.guide_links[i].label_name &&
        (labUserGuideLinks.guide_links[i].user_guide_link) && is_url(labUserGuideLinks.guide_links[i].user_guide_link)) ||
        (labUserGuideLinks.guide_links[i].label_name &&
          (!labUserGuideLinks.guide_links[i].user_guide_link) ||  (labUserGuideLinks.guide_links[i].user_guide_link) && !is_url(labUserGuideLinks.guide_links[i].user_guide_link))) {
        validForm = false;
      } else if ((!labUserGuideLinks.guide_links[i].label_name &&
        !labUserGuideLinks.guide_links[i].user_guide_link && i === 0)) {
        validForm = false;
      } else if (validForm) { // form is valid (guide name ,url and it is validate successfully)
        validForm = true;
      }
    }


    if (labName && validForm && guideLinkArray.length >= 1) {

      const payload = {
        lab_name: labName,
        user_guide: guideLinkArray
      }

      //api call for creating user guide
      await GuideService.addLabUserGuide(labId, payload)
        .then((response) => {
          if (response.status === 201) {
            toast.current.show({
              severity: "success",
              summary: "Lab User Guide Successfully Added",
              detail: "The lab user guide has been successfully added.",
              life: 6000,
            });
            createLabUserGuideDialogMap["createLabUserGuideDisplay"](false);
          } else {
            loglevel.debug("lab user guide deletion has failed");
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            setCommonErrorMsg(error.response.data.msg);
          }
        });

        // refreshig guide list
      await GuideService.getLabUserGuideList().then((res) => {

        for (let i = 0; i < res.data.length; i++) {
          res.data[i]["closedStatus"] = true;
          res.data[i]["closedGroupsStatus"] = true;

        };
        setChanger(res.data);
      });
    }
  }


  return (
    <>
      <div className="admin-panel-content">
        <Toast ref={toast} position="top-center" />
        <div className="create-lab-button">
          {/* create button to create user guide */}
          <Button
            id="reset-button"
            label="CREATE NEW LAB USER GUIDE"
            className="p-button-primary"
            onClick={() => onClick("createLabUserGuideDisplay")}
          />
        </div>
        <Dialog
          header="Create Portal Usage Guide"
          visible={createLabUserGuideDisplay}
          onHide={() => onHide("createLabUserGuideDisplay")}
          className="lab-dialog-box"
          footer={renderFooter("createLabUserGuideDisplay")}
          onShow={() => onShowDialog("createLabUserGuideDisplay")}
          style={{ width: "710px" }}
        >
           {/* error message response from api at top of dialog */}
          <div ref={errorRef} className={commonErrorMsg ? "" : "hidden"}>
            {commonErrorMsg && (
              <Message
                severity="warn"
                text={commonErrorMsg}
                className="common-inline-error common-error-message p-mb-3"
              />
            )}
          </div>
          <div className="dialog-content">
            <div className="guide-name-input">
              <div className="guide-name-label">
                Lab Name:
                <span className="cat-required-field">*</span>
              </div>
              <div><Dropdown
                value={selectedLab}
                options={labList}
                onChange={(e) => labDetails(e)}
                optionLabel="name"
                placeholder="Choose a Lab Name"
                className={
                  !labName && formSubmit
                    ? "cat-input-error"
                    : "cat-name-text-box"
                }
              />
                {!labName && formSubmit ? (
                  <div className="cat-error-message-inline">
                    <small className="cat-error-message" id="error-message">
                      Lab name is mandatory
                    </small>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="guide-link-container">

              {labUserGuideLinks.guide_links.map((item, idx) => (
                <div>
                  <div className="table-row" id="addr0" key={idx}>
                    <div className="table-data">
                      {idx === 0 ? (
                        <div className="guide-link-label">
                          User Guide Link:
                          <span className="cat-required-field">*</span>
                        </div>
                      ) : (
                        <div className="guide-link-label">
                          User Guide Link
                          <span className="optional-label">
                            {" "}
                            ( Optional ) :
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="table-data">
                      {/* Label name input box */}
                      <InputText
                        type="text"
                        name="label_name"
                        value={labUserGuideLinks.guide_links[idx].label_name}
                        onChange={AddUrlhandleChange(idx)}
                        maxLength={50}
                        className={
                          (idx === 0 && labUserGuideLinks.guide_links[idx].label_name) && formSubmit ?
                            "edit-external-url-label-text-box" :
                            (idx === 0 && !labUserGuideLinks.guide_links[idx].label_name) && formSubmit ?
                              "edit-external-url-label-text-box error-border" :
                              (idx !== 0 &&
                                !labUserGuideLinks.guide_links[idx].label_name &&
                                labUserGuideLinks.guide_links[idx].user_guide_link) && formSubmit ?
                                "edit-external-url-label-text-box error-border" :
                                "edit-external-url-label-text-box"
                        }
                        placeholder="Enter Guide Label"
                      />
                      {
                        (idx === 0 && labUserGuideLinks.guide_links[idx].label_name) && formSubmit ?
                          ("") :
                          (idx === 0 && !labUserGuideLinks.guide_links[idx].label_name) && formSubmit ?
                            (
                              <div className="cat-error-message-inline">
                                <small className="cat-error-message" id="error-message">
                                  Label is mandatory
                                </small>
                              </div>
                            ) :
                            (idx !== 0 &&
                              !labUserGuideLinks.guide_links[idx].label_name &&
                              labUserGuideLinks.guide_links[idx].user_guide_link) && formSubmit ?
                              (
                                <div className="cat-error-message-inline">
                                  <small className="cat-error-message" id="error-message">
                                    Label is mandatory
                                  </small>
                                </div>
                              ) :
                              ("")
                      }
                    </div>
                    <div className="table-data">
                      {/* User guide link input box */}
                      <InputText
                        type="text"
                        name="user_guide_link"
                        value={
                          labUserGuideLinks.guide_links[idx].user_guide_link
                        }
                        onChange={AddUrlhandleChange(idx)}
                        maxLength={2000}
                        className={
                          (idx === 0 && labUserGuideLinks.guide_links[idx].user_guide_link) && is_url(labUserGuideLinks.guide_links[idx].user_guide_link) && formSubmit ?
                            "lab-external-url-text-box" :
                            (idx === 0 && (!labUserGuideLinks.guide_links[idx].user_guide_link ||(labUserGuideLinks.guide_links[idx].user_guide_link && !is_url(labUserGuideLinks.guide_links[idx].user_guide_link)))) && formSubmit ?
                              "lab-external-url-text-box error-border" :
                              (idx !== 0 &&
                               ( !labUserGuideLinks.guide_links[idx].user_guide_link || (labUserGuideLinks.guide_links[idx].user_guide_link && !is_url(labUserGuideLinks.guide_links[idx].user_guide_link))) &&
                               labUserGuideLinks.guide_links[idx].label_name) && formSubmit ?
                                "lab-external-url-text-box error-border" :
                                "lab-external-url-text-box"
                        }
                        placeholder="Enter URL"
                      />
                      {
                      (idx === 0 && labUserGuideLinks.guide_links[idx].user_guide_link) && !is_url(labUserGuideLinks.guide_links[idx].user_guide_link) && formSubmit ?
                        (<div className="cat-error-message-inline">
                             <small className="cat-error-message" id="error-message">
                               Invalid link
                             </small>
                           </div>) :
                        (idx === 0 && !labUserGuideLinks.guide_links[idx].user_guide_link) && formSubmit ?
                          (
                            <div className="cat-error-message-inline">
                              <small className="cat-error-message" id="error-message">
                                Link is mandatory
                              </small>
                            </div>
                          ) :
                          (idx !== 0 &&
                            !labUserGuideLinks.guide_links[idx].user_guide_link &&
                            labUserGuideLinks.guide_links[idx].label_name) && formSubmit ?
                            (
                              <div className="cat-error-message-inline">
                                <small className="cat-error-message" id="error-message">
                                  Link is mandatory
                                </small>
                              </div>
                            ) :(idx !== 0 &&
                              labUserGuideLinks.guide_links[idx].user_guide_link && !is_url(labUserGuideLinks.guide_links[idx].user_guide_link) &&
                              labUserGuideLinks.guide_links[idx].label_name) && formSubmit ?
                              (
                                <div className="cat-error-message-inline">
                                  <small className="cat-error-message" id="error-message">
                                    Invalid Link
                                  </small>
                                </div>
                              ):""
                    }
                    </div>
                    <div className="table-data">
                      {labUserGuideLinks.guide_links.length - 1 === idx ? (
                        <div onClick={handleAddRowLink} className="add-row-icon">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM10 18.75C5.16751 18.75 1.25 14.8325 1.25 10C1.25 5.16751 5.16751 1.25 10 1.25C14.8325 1.25 18.75 5.16751 18.75 10C18.75 12.3206 17.8281 14.5462 16.1872 16.1872C14.5462 17.8281 12.3206 18.75 10 18.75ZM10.625 9.37505H15.1062C15.4514 9.37505 15.7312 9.65487 15.7312 10C15.7312 10.3452 15.4514 10.625 15.1062 10.625H10.625V15.1063C10.625 15.4515 10.3452 15.7313 9.99999 15.7313C9.65481 15.7313 9.37499 15.4515 9.37499 15.1063V10.625H4.89374C4.54856 10.625 4.26874 10.3452 4.26874 10C4.26874 9.65487 4.54856 9.37505 4.89374 9.37505H9.37499V4.8938C9.37499 4.54862 9.65481 4.2688 9.99999 4.2688C10.3452 4.2688 10.625 4.54862 10.625 4.8938V9.37505Z"
                              fill={(labUserGuideLinks.guide_links[idx].label_name && labUserGuideLinks.guide_links[idx].user_guide_link)?"#49AFD9": "#6D7884"}
                            />
                          </svg>
                        </div>
                      ) : (
                        <div
                          className="remove-row-icon"
                          onClick={handleRemoveSpecificRow(idx)}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M17.0711 2.92892C13.1658 -0.976326 6.83418 -0.976326 2.92893 2.92892C-0.97631 6.83416 -0.976311 13.1658 2.92893 17.0711C6.83418 20.9763 13.1658 20.9763 17.0711 17.0711C18.9464 15.1957 20 12.6521 20 9.99998C20 7.34782 18.9464 4.80428 17.0711 2.92892ZM3.81282 16.1872C0.39573 12.7701 0.395729 7.22989 3.81282 3.8128C7.2299 0.395713 12.7701 0.395713 16.1872 3.8128C19.6043 7.22989 19.6043 12.7701 16.1872 16.1872C14.5462 17.8281 12.3206 18.75 10 18.75C7.67936 18.75 5.45376 17.8281 3.81282 16.1872ZM10.8838 10L14.0526 13.1687C14.2966 13.4128 14.2966 13.8085 14.0526 14.0526C13.8085 14.2967 13.4128 14.2967 13.1687 14.0526L9.99996 10.8839L6.83123 14.0526C6.58716 14.2967 6.19143 14.2967 5.94735 14.0526C5.70327 13.8085 5.70327 13.4128 5.94735 13.1687L9.11607 10L5.94735 6.83129C5.70327 6.58721 5.70327 6.19148 5.94735 5.9474C6.19143 5.70333 6.58716 5.70333 6.83123 5.9474L9.99996 9.11613L13.1687 5.9474C13.4128 5.70333 13.8085 5.70333 14.0526 5.9474C14.2966 6.19148 14.2966 6.58721 14.0526 6.83129L10.8838 10Z"
                              fill="#49AFD9"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}

            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
}

export default LabUserGuideCreate;
