import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";
import { Message } from "primereact/message";
import "../../Rbac.css";
import React, { useState, useRef } from "react";
import LabServices from "../../../../services/LabServices";
import loglevel from "../../../../logger/Logger";

const DeleteUserGroup = ({ groupId, groupName, setChanger }) => {
  const [deleteGroupDisplay, setDeleteGroupDisplay] = useState(false);
  const deleteGroupDialogMap = {
    deleteGroupDisplay: setDeleteGroupDisplay,
  };
  const [checked, setChecked] = useState(false);
  const [commonErrorMsg, setCommonErrorMsg] = useState("");

  const toast = useRef(null);
  const errorRef = useRef();

  //on opening Delete user group  dialog
  const onShow = (name) => {
    setChecked(false);
    setCommonErrorMsg("");
    deleteGroupDialogMap[`${name}`](true);
  };

   //on closing Delete user group  dialog
  const onHide = (name) => {
    deleteGroupDialogMap[`${name}`](false);
    setChecked(false);
    setCommonErrorMsg("");
  };

  // on deleting User group
  const onDelete = (e) => {
    if (checked) {
      //api call for deleting user group
      LabServices.deleteUserGroup(groupId)
        .then((response) => {
          if (response.status === 200) {
            //toast message after successful deletion of user group
            toast.current.show({
              severity: "success",
              summary: "User Group Successfully Deleted",
              detail: `The user group named "${groupName}" has been deleted successfully.`,
              life: 6000,
            });
            // dialog box automaticaly closes after successful deletion of user group
            deleteGroupDialogMap["deleteGroupDisplay"](false);
          } else {
            loglevel.debug("User Group Deletion is Failed");
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            setCommonErrorMsg(error.response.data.msg);
            errorRef.current.scrollIntoView({ behavior: "smooth" });
          }
        });
    }

    //----- Added timeout as list fetching and delete api was getting called simultaneously resulting in fetching old Tag List--------
    setTimeout(() => {
      LabServices.getUserGroupList().then((res) => {
        setChanger(res.data);
      });
    }, 1000);
  };

  const renderFooter = (name) => {
    return (
      <div className="delete-btn-section">
        {/* Delete Group button on bottom of dialog */}
        <Button
          label="DELETE GROUP"
          className={
            checked ? "p-button-outlined p-button-danger" : "p-button-outlined p-button-secondary"
          }
          onClick={(e) => onDelete(e)}
          disabled={!checked}
        >
        </Button>
       {/* Cancel button on bottom of dialog to cancel deletion */}
        <Button
          className="p-button-primary"
          label="CANCEL"
          onClick={() => onHide(name)}
        />
      </div>
    );
  };

  return (
    <div className="tag-delete">
      <Toast ref={toast} position="top-center" />
      {/* Delete Icon on grops listing page */}
      <Button
        icon="pi pi-trash"
        className="p-button-rounded global-delete-button"
        onClick={() => onShow("deleteGroupDisplay")}
      />
      {/* Dialog box for group deletion */}
      <Dialog
        header={`Delete Group : ${groupName}`}
        visible={deleteGroupDisplay}
        footer={renderFooter("deleteGroupDisplay")}
        onHide={() => onHide("deleteGroupDisplay")}
        className="tag-delete-dialog-box"
      >
        {/* Error message response shown on top of dialog */}
        <div ref={errorRef} className={commonErrorMsg ? "" : "hidden"}>
          {commonErrorMsg && (
            <Message
              severity="warn"
              text={commonErrorMsg}
              className="common-inline-error common-error-message p-mb-3"
            />
          )}
        </div>
        <div>
          If you delete the group, it will remove the associated Tag / Members
          from this group.{" "}
        </div>
        <br></br>
        {/* Checkbox to confirm deletion */}
        <div>
          <Checkbox
            inputId="binary"
            className=".checkbox"
            checked={checked}
            onChange={(e) => setChecked(e.checked)}
          />
          {"  "} I understand. Delete the group.
        </div>
      </Dialog>
    </div>
  );
};

export default DeleteUserGroup;
