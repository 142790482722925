import { Button } from "primereact/button";
import React, { useState, useEffect, useRef } from "react";
import "../labs/LabCard.css";
import { Dialog } from "primereact/dialog";
import LabServices from "../../services/LabServices";
import loglevel from "../../logger/Logger";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";

const ShareLab = (lab) => {
  const [displayBasic, setDisplayBasic] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [sharedList, setSharedList] = useState(null);

  const toast = useRef(null);
  const dt = useRef(null);
  const labs = lab.lab;

  useEffect(() => {
    async function fetchData() {
      // List of lshared  lab users
      await LabServices.getSharedUsersList(labs.schedule_id)
        .then((res) => {
          loglevel.debug("getSharedUsersList Response", res);
          setSharedList(res.data);
          console.log("Shared list users", sharedList);
        })
        .catch((error) => {
          loglevel.error("getSharedUsersList", error);
        });
    }
    fetchData();
  }, []);

  const dialogFuncMap = {
    displayBasic: setDisplayBasic,
  };

  // open  dialog to show share lab list data
  const onClick = (name) => {
    dialogFuncMap[`${name}`](true);
  };

  // close  dialog of share lab list data
  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  //close button on footer
  const renderFooter = (name) => {
    return (
      <div className="share-lab-reset-button">
        <Button
          id="reset-button"
          label="Close"
          className="p-button-primary"
          onClick={() => onHide(name)}
        />
      </div>
    );
  };

  // method called on clicking to SHARED WITH TAB
  const setIndex0 = (e) => {
    setActiveIndex(0);
  };

  return (
    <div>
      <Toast ref={toast} position="top-center" />
      {/* share Lab button on card with count */}
      <Button
        className={labs.status === "Deleting"?"share-lab-button-disabled":"share-lab-button"}
        onClick={() => onClick("displayBasic")}
        disabled={labs.status === "Deleting"?true:false}
      >
        <div className="lab-sharing-btn">
        <div>
        <svg
          className="shared-icon"
          width="13"
          height="15"
          viewBox="0 0 13 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.75 2.72727L8.59625 3.69545L7.30437 2.61136V10.2273H5.69563V2.61136L4.40375 3.69545L3.25 2.72727L6.5 0L9.75 2.72727ZM13 6.13636V13.6364C13 14.3864 12.2688 15 11.375 15H1.625C0.723125 15 0 14.3864 0 13.6364V6.13636C0 5.37955 0.723125 4.77273 1.625 4.77273H4.0625V6.13636H1.625V13.6364H11.375V6.13636H8.9375V4.77273H11.375C12.2688 4.77273 13 5.37955 13 6.13636Z"
            fill={labs.status === "Deleting"?"#303234":"white"}
          />
        </svg>
        </div>
        <div className={labs.status === "Deleting"?"share-lab-button-text-disabled":"share-lab-button-text"}>
          Lab Sharing ({labs.shared_count})
        </div>
        </div>
      </Button>
      <Dialog
        header={
          <>
            <div className="share-lab-header-title">Share Lab Details</div>
            <div className="share-lab-tabmenu">
              <button
                className="menuitem-link"
                style={{
                  borderColor:
                    activeIndex === 0
                      ? "#979797 #979797 #1B2A32 #979797"
                      : "#22343C #22343C #979797 #22343C",
                  backgroundColor: activeIndex === 0 ? "#1B2A32" : "#22343C",
                }}
                onClick={setIndex0}
              >
                SHARED WITH
              </button>
            </div>
          </>
        }
        visible={displayBasic}
        footer={renderFooter("displayBasic")}
        onHide={() => onHide("displayBasic")}
        className="shared-lab-dialog"
      >
        {/* list of shared lab details in tabular form */}
        <div className="share-lab-content-container">
          <DataTable
            value={sharedList}
            ref={dt}
            columnResizeMode="fit"
            className="shared-lab-crud-table"
            autoLayout={true}
            responsiveLayout="scroll"
          >
            <Column
              field="email"
              header="Email ID"
              style={{ minWidth: "10rem" ,paddingLeft:"15px" }}
            ></Column>
            <Column
              field="sharing_date"
              header="Sharing Date"
              style={{ minWidth: "6.5rem" }}
            ></Column>
            <Column
              field="permission"
              header="Permission"
              style={{ minWidth: "6rem" }}
            ></Column>
          </DataTable>
        </div>
      </Dialog>
    </div>
  );
};

export default ShareLab;
