import { Button } from 'primereact/button'
import React from 'react'
import { Link } from 'react-router-dom'
import "./notFound.css"

const NotFound = () => {
    return ( <>
            <div className="notFound">
                <h1>404 Error !!</h1>
                <h2>Sorry, this page is not found.</h2>
                <Link to="/"><Button>Return to Home Page</Button></Link>
            </div>
        </>
    )
}

export default NotFound
