import React, { useState, useRef } from 'react'
import { RadioButton } from "primereact/radiobutton";
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from "primereact/inputtextarea";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import "./AdminMessage.css"
import LabServices from '../../services/LabServices';
import moment from "moment";
import { Toast } from 'primereact/toast';
import eventBus from '../utility/EventBus';

const AdminMessage = () => {
  const [options, setOptions] = useState("All");
  const [comments, setComments] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [messageRecepients, setMessageRecepients] = useState([]);
  const [selectedReceipients, setSelectedReceipients] = useState([]);
  const [selectedGrpIds, setSelectedGrpIds] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [expiryDateErrMsg, setExpiryDateErrMsg] = useState("");
  const [commentsErrMsg, setCommentsErrMsg] = useState("");
  const [recepientsErrMsg, setRecepientsErrMsg] = useState("");
  const toast = useRef(null);

  // method called on switching between radiobuttons
  const handleOptions = (e) => {
    setOptions(e.target.value)
    setSelectedReceipients([]);
    setExpiryDate("");
    setComments("");
    setSelectedGrpIds([]);
    setSelectedTags([]);
    e.target.value === "Groups" && LabServices.getUserGroups().then((response) => {
      let groups = [];
      response.data.map((group) => {
        groups.push({ recepients: group.name, group_id: group.group_id });
      })
      setMessageRecepients(groups)
    })

    e.target.value === "Tags" && LabServices.getPermissionTagList().then((response) => {
      let tags = [];
      response.data.map((tag) => {
        tags.push({ recepients: tag });
      })
      setMessageRecepients(tags)
    })

  }

  const adminMessage = () => {
    // request body
    const requestBody = {
      broadcastToAll: options !== "All" ? false : true,
      message: comments,
      tags: options === "Tags" ? selectedTags : [],
      groups: options === "Groups" ? selectedGrpIds : [],
      expiry_date: (moment(expiryDate).format("YYYY-MM-DD"))
    }
    onSendMessage(requestBody);
  }

  //for msg refresh
  const messageBroadcast = () => {
    eventBus.dispatch("Broadcast", { message: "Broadcast" });
  }

  // method for broadcasting message
  const onSendMessage = (requestBody) => {
    if (!comments) { // if comment is empty
      setCommentsErrMsg("Message is mandatory")
    }
    else {
      setCommentsErrMsg("")
    }
    if (!expiryDate) { //if expiry date is not selected
      setExpiryDateErrMsg("Expiry date is mandatory")
    }
    else {
      setExpiryDateErrMsg("");
    }
    if (selectedTags.length === 0 && selectedGrpIds.length === 0) {
      setRecepientsErrMsg("Recepients is mandatory");
    }
    else {
      setRecepientsErrMsg("");
    }
    if (comments && expiryDate) {
      //api call for message broadcast
      LabServices.createAdminMessage(requestBody).then((response) => {
        console.log(response);
        toast.current.show({
          severity: "success",
          summary: "Message sent",
          detail: response.data,
          life: 5000,
        });
        console.log(response)
        console.log("requestBody", requestBody);
        setOptions("All");
        setExpiryDate("");
        setComments("");
        setSelectedGrpIds([]);
        setSelectedTags([]);
      }).catch((error) => {
        console.log(error);
      })
      messageBroadcast();
    }
  }

  // method for clearing all field on press reset button
  const onMessageReset = () => {
    setOptions("All");
    setExpiryDate("");
    setComments("");
    setCommentsErrMsg("");
    setExpiryDateErrMsg("");
    setRecepientsErrMsg("");
    setSelectedGrpIds([]);
    setSelectedTags([]);
  }

  // method called for changing Expiry date
  const onExpiryDateChange = (e) => {
    setExpiryDate(e.value);
    if (!e.value) {
      setExpiryDateErrMsg("Expiry date is mandatory")
    }
    else {
      setExpiryDateErrMsg("");
    }
  }

  // method called for changing Message
  const onMessageChange = (e) => {
    setComments(e.target.value);
    if (!e.target.value) {
      setCommentsErrMsg("Message is mandatory")
    }
    else {
      setCommentsErrMsg("")
    }
  }

  // Toggling between Groups and Tags
  const onMultiSelectRecepientsChange = (e) => {
    setSelectedReceipients(e.target.value);
    console.log("onMultiSelectRecepientsChange", e.target.value);
    if (options === "Groups") {
      let grpId = e.target.value.map(group => group.group_id);
      setSelectedGrpIds(grpId);
      console.log("grpIds", grpId);
    }
    if (options === "Tags") {
      let tagsArr = e.target.value.map(tag => tag.recepients);
      setSelectedTags(tagsArr);
    }
    if (e.target.length === 0) {
      setRecepientsErrMsg("Recepients is mandatory");
    }
    else {
      setRecepientsErrMsg("");
    }
  }

  return (
    <div className="parent-container">
      {/* Toast messages */}
      <Toast ref={toast} position="top-center" />
      <div className="radiobutton-container">
        <div className="field-title">Send to :</div>
        <div className="radiobutton-options">

          {/* Radiobuttons All Tags and Groups */}
          <div className="radiobutton-option1">
            <RadioButton
              inputId="option1"
              name="option"
              value="All"
              onChange={(e) => handleOptions(e)}
              checked={options === "All"}
            />
            <label>All</label>
          </div>
          <div className="radiobutton-option-2">
            <RadioButton
              inputId="option2"
              name="option"
              value="Groups"
              onChange={(e) => handleOptions(e)}
              checked={options === "Groups"}
            />
            <label htmlFor="option1">Groups</label>
          </div>
          <div className="radiobutton-option2">
            <RadioButton
              inputId="option3"
              name="option"
              value="Tags"
              onChange={(e) => handleOptions(e)}
              checked={options === "Tags"}
            />
            <label htmlFor="option1">Tags</label>
          </div>
        </div>
      </div>
      {options !== "All" && <div className="feedback">
        <div className="field-title">Recepients <span className="lab-required-field">*</span> :</div>
        <div className="admin-msg-recep-options">
          <MultiSelect
            value={selectedReceipients}
            optionLabel="recepients"
            options={messageRecepients}
            onChange={onMultiSelectRecepientsChange}
            placeholder={`Enter to Add ${options}`}
            filter
            filterBy="recepients"
            className={options === "Tags" && !recepientsErrMsg ? "tags-dropdown" : options === "Groups" && !recepientsErrMsg ? "groups-dropdown" : options === "Tags" && recepientsErrMsg ? "error-block tags-dropdown" : options === "Groups" && recepientsErrMsg ? "error-block groups-dropdown" : ""}
            display="chip"
          />
          {recepientsErrMsg &&
            <div className="error-message-inline">
              <small className="error-message" id="error-message">
                {recepientsErrMsg}
              </small>
            </div>}
        </div>
      </div>}

       {/* selecting Expiry date */}
      <div className='expiry-date'>
        <label htmlFor="icon" className='expiry-date-label'>Expiry Date <span className="lab-required-field">*</span> :</label>
        <Calendar placeholder='Select Expiry Date' id="icon" showIcon selectOtherMonths={true} className={expiryDateErrMsg ? 'error-block admin-msg-calender disable-lab-calendar' : 'admin-msg-calender disable-lab-calendar'} value={expiryDate} onChange={onExpiryDateChange} minDate={
          new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
        } />
        {expiryDateErrMsg &&
          <div className="error-message-inline expiry-date-inline-error">
            <small className="error-message" id="error-message">
              {expiryDateErrMsg}
            </small>
          </div>}
      </div>

      {/* Message */}
      <div className="comments-container">
        <div className="field-title">
          Message <span className="lab-required-field">*</span> :
        </div>
        <div
          className={commentsErrMsg ? "comments-error admin-msg-comments" : "comments admin-msg-comments"}
        >
          <InputTextarea
            value={comments}
            onChange={onMessageChange}
            rows={2}
            cols={40}
            placeholder="Enter Message"
            autoResize={true}
          />
          {commentsErrMsg &&
            <div className="error-message-inline">
              <small className="error-message" id="error-message">
                {commentsErrMsg}
              </small>
            </div>}
        </div>
      </div>
      <div>
        <Divider style={{ margin: 0 }} />
      </div>
      <div className='submit-reset-actions p-d-flex p-mt-3'>
        {/* Send button */}
        <div className='primary-blue-btn'>
          <Button  label="SEND" onClick={adminMessage}></Button>
        </div>
        {/* Reset button */}
        <div className='reset-btn p-ml-2'>
          <Button className='p-button-primary' label="RESET" onClick={onMessageReset}></Button>
        </div>
      </div>
    </div>
  )
}

export default AdminMessage