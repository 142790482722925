import React, { useState, useRef } from 'react'
import "./Register.css"
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import RequestPortalAccess from './RequestPortalAccess';
import { Link } from "react-router-dom";
import BackArrow from "../../images/line.svg"
import * as CompanyEmailValidator from 'company-email-validator';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button'
import AuthenticationService from '../../services/AuthenticationService';
import { Toast } from "primereact/toast";
import LoadingSpinner from "../loader/LoadingSpinner";
import { Message } from 'primereact/message';
import PortalPolicy from '../portal-policy/PortalPolicy';
import PortalService from '../../services/PortalService';
import { MAINTENANCE_MODE } from "../../config/configuration";
import { MAINTENANCE_MESSAGE } from "../../config/configuration";
import Footer from "../footer/Footer";
import { Captcha } from 'primereact/captcha';
import LogoVmwBcmSdeLabs from "../../images/nextline.svg";

const Register = () => {
    let year = new Date().getFullYear();
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);

    const [isCaptchaVerified, setCaptchaVerified] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [customerPartnerName, setCustomerPartnerName] = useState("");
    const [purpose, setPurpose] = useState("");
    const [checked, setChecked] = useState(false);
    const [fnameError, setFnameError] = useState(false);
    const [fnameErrorMsg, setFnameErrorMsg] = useState("");
    const [lnameError, setLnameError] = useState(false);
    const [lnameErrorMsg, setLnameErrorMsg] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMsg, setEmailErrorMsg] = useState("");
    const [businessNameError, setBusinessNameError] = useState(false);
    const [businessNameErrorMsg, setBusinessNameErrorMsg] = useState("")
    const [customerPartnerNameError, setCustomerPartnerNameError] = useState(false);
    const [customerPartnerNameErrorMsg, setCustomerPartnerNameErrorMsg] = useState("")
    const [purposeError, setPurposeError] = useState(false);
    const [purposeValid, setPurposeValid] = useState(false);
    const [purposeValidError, setPurposeValidError] = useState(false);
    const [purposeErrorMsg, setPurposeErrorMsg] = useState("");
    const [termsError, setTermsError] = useState(false);
    const [termsErrorMsg, setTermsErrorMsg] = useState("");
    const [validErrorMsg, setValidErrorMsg] = useState("");
    const [displaySuccessDialog, setDisplaySuccessDialog] = useState(false);

    if (loading) {
        return <div className="loader"><LoadingSpinner /></div>
    }

    const dialogFuncMap = {
        'displaySuccessDialog': setDisplaySuccessDialog,
    }

    const onPortalAccess = () => {
        openSuccessDialog('displaySuccessDialog');
    }

    const openSuccessDialog = (name) => {
        dialogFuncMap[`${name}`](true);
    }

    const renderSuccessFooter = () => {
        return (
            <Button className='success-dialog-close-btn' label='CLOSE' onClick={() => onHide('displaySuccessDialog')} />
        )
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
        setFirstName("");
        setLastName("");
        setEmail("");
        setBusinessName("");
        setPurpose("");
        setChecked(false);
        setFnameError(false);
        setLnameError(false);
        setEmailError(false);
        setBusinessNameError(false);
        setPurposeError(false);
        setTermsError(false);
        setValidErrorMsg("")
    }

    const onFnameChange = (e) => {
        setFirstName(e.target.value);
        if (e.target.value === "") {
            setFnameError(true);
            setFnameErrorMsg("First name is mandatory.")
        }

        else if (e.target.value.length > 100) {
            setFnameError(true);
            setFnameErrorMsg(`Maximum 100 Characters (Current- ${e.target.value.length})`)
        }
        else {
            setFnameError(false);
            setFnameErrorMsg("");
        }
    }

    const onLnameChange = (e) => {
        setLastName(e.target.value);
        if (e.target.value === "") {
            setLnameError(true);
            setLnameErrorMsg("Last name is mandatory.")
        }

        else if (e.target.value.length > 100) {
            setLnameError(true);
            setLnameErrorMsg(`Maximum 100 Characters (Current- ${e.target.value.length})`)
        }
        else {
            setLnameError(false);
            setLnameErrorMsg("");
        }
    }

    const onEmailChange = (e) => {

        // Check if email format is correct
        let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        setEmail(e.target.value);
        if (!regexEmail.test(e.target.value)) {
            setEmailError(true);
            setEmailErrorMsg("Enter a valid email address");
            return;
        }
        else {
            setEmailError(false);
            setEmailErrorMsg("");
        }

        // Allow only Business Email
        console.log("company mail", CompanyEmailValidator.isCompanyEmail(e.target.value));
        if ((CompanyEmailValidator.isCompanyEmail(e.target.value) === false)) {
            setEmailError(true);
            setEmailErrorMsg("Enter a valid business email address");
            return;
        }
        else {
            setEmailError(false);
            setEmailErrorMsg("");
        }

        // Check if it is VMWare email ID
        let regexVmwareEmail = /^[\w-\.]+@(vmware\.)+[\w-]{2,4}$/
        if(regexVmwareEmail.test(e.target.value.toLowerCase())) {
            setEmailError(true);
            setEmailErrorMsg("VMWare users, Please login via SSO, in case of any issue contact support team")
            return;
        }
        else {
            setEmailError(false);
            setEmailErrorMsg("");
        }
    }

    const onBusinessNameChange = (e) => {
        setBusinessName(e.target.value)
        if (e.target.value === "") {
            setBusinessNameError(true);
            setBusinessNameErrorMsg("Company/Organization name is mandatory.")
        }

        else if (e.target.value.length > 100) {
            setBusinessNameError(true);
            setBusinessNameErrorMsg(`Maximum 100 Characters (Current- ${e.target.value.length})`)
            return
        }
        else {
            setBusinessNameError(false);
            setBusinessNameErrorMsg("");
        }
    }

    const onCustomerPartnerNameChange = (e) => {
        setCustomerPartnerName(e.target.value)
        if (e.target.value === "") {
            setCustomerPartnerNameError(true);
            setCustomerPartnerNameErrorMsg("VMWare/Partner Contact Name/Email is mandatory.")
        }

        else if (e.target.value.length > 100) {
            setCustomerPartnerNameError(true);
            setCustomerPartnerNameErrorMsg(`Maximum 100 Characters (Current- ${e.target.value.length})`)
            return
        }
        else {
            setCustomerPartnerNameError(false);
            setCustomerPartnerNameErrorMsg("");
        }
    }

    const onPurposeChange = (e) => {
        setPurpose(e.target.value)
        if (e.target.value === "") {
            setPurposeError(true);
            setPurposeValid(false)
            setPurposeErrorMsg("Purpose is mandatory.")
        }
        else if(e.target.value.length >= 10 && e.target.value.length<=500 ){
             setPurposeValid(true)
             setPurposeError(false);
             setPurposeErrorMsg("")
             setPurposeValidError(`Maximum 500 Characters (Current- ${e.target.value.length})`)
        }

        else if (e.target.value.length > 500) {
            setPurposeError(true);
            setPurposeValid(false)
            setPurposeErrorMsg(`Maximum 500 Characters (Current- ${e.target.value.length})`)
            return
        }
        else if (e.target.value.length < 10) {
            setPurposeError(true);
            setPurposeValid(false)
            setPurposeErrorMsg(`Minimum 10 Characters (Current- ${e.target.value.length})`)
            return
        }
        else {
            setPurposeError(false);
            setPurposeErrorMsg("");
        }

    }

    const onCheckedChange = (e) => {
        setChecked(e.checked);
        if (e.checked === false) {
            setTermsError(true);
            setTermsErrorMsg("Accepting portal usage policy is mandatory.")
        }
        else {
            setTermsError(false);
            setTermsErrorMsg("");
        }
    }

    const requestPortalAccess = () => {
        const requestBody = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            businessName: businessName,
            purpose: purpose,
            customerPartnerName: customerPartnerName,
        }
        portalAccess(requestBody);
    }

    const portalAccess = async (requestBody) => {
        if (firstName === "") {
            setFnameError(true);
            setFnameErrorMsg("First name is mandatory.")
        }
        else {
            setFnameError(false);
            setFnameErrorMsg("");
        }
        if (lastName === "") {
            setLnameError(true);
            setLnameErrorMsg("Last name is mandatory.")
        }
        else {
            setLnameError(false);
            setLnameError("");
        }
        let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!regexEmail.test(email)) {
            setEmailError(true);
            setEmailErrorMsg("Enter a valid email address");
        }
        else {
            setEmailError(false);
            setEmailErrorMsg("");
        }

        if ((CompanyEmailValidator.isCompanyEmail(email) === false)) {
            setEmailError(true);
            setEmailErrorMsg("Enter a valid business email address");
        }
        else {
            setEmailError(false);
            setEmailErrorMsg("");
        }

        let regexVmwareEmail = /^[\w-\.]+@(vmware\.)+[\w-]{2,4}$/
        if(regexVmwareEmail.test(email.toLowerCase())) {
            setEmailError(true);
            setEmailErrorMsg("Please login via SSO, in case of any issue contact support team")
        }
        else {
            setEmailError(false);
            setEmailErrorMsg("");
        }

        if (businessName === "") {
            setBusinessNameError(true);
            setBusinessNameErrorMsg("Company/Organization name is mandatory.")
        }
        else {
            setBusinessNameError(false);
            setBusinessNameErrorMsg("");
        }

        if (customerPartnerName === "") {
            setCustomerPartnerNameError(true);
            setCustomerPartnerNameErrorMsg("VMWare/Partner Contact Name/Email is mandatory.")
        }
        else {
            setCustomerPartnerNameError(false);
            setCustomerPartnerNameErrorMsg("");
        }

        if (purpose === "") {
            setPurposeError(true);
            setPurposeErrorMsg("Purpose is mandatory.")
            console.log(purpose.length, "ppp")
        }
        else if (purpose.length > 500) {
            setPurposeError(true);
            setPurposeErrorMsg(`Maximum 500 Characters (Current- ${purpose.length})`)
            return
        }
        else if (purpose.length < 10) {
            setPurposeError(true);
            setPurposeErrorMsg(`Minimum 10 Characters (Current- ${purpose.length})`)
            return
        }
        else {
            setPurposeError(false);
            setPurposeErrorMsg("");

        }

        if (checked === false) {
            setTermsError(true);
            setTermsErrorMsg("Accepting portal usage policy is mandatory.")
            return;
        }
        else {
            setTermsError(false);
            setTermsErrorMsg("");
        }
        if(!termsError && !businessNameError && !lnameError && !fnameError && !emailError && !purposeError && !customerPartnerNameError ){
        await AuthenticationService.requestPortalAccess(requestBody).then((response) => {
            onPortalAccess();
            setFirstName("");
            setLastName("");
            setEmail("");
            setBusinessName("");
            setPurpose("");
            setChecked(false);
            setCustomerPartnerName("");
        }).catch((error) => {
            console.log("portalAccess", error)
            if (error.response.data.statusCode === 409) {
                setValidErrorMsg(error.response.data.msg);
            }
        }).finally(() => {
            setLoading(false);
        })
    }
    }

    const isRequestPortalFormValid = () => {
        if(
            firstName !== "" &&
            lastName !== "" &&
            email !== "" &&
            businessName !== "" &&
            purpose !== "" &&
            checked &&
            !emailError &&
            !purposeError &&
            isCaptchaVerified &&
            customerPartnerName !== ""
        ) {
            return true
        } else {
            return false
        }
    }

    // const [isCaptchaVerified, setCaptchaVerified] = useState(false);
    const verifyCaptcha =  async(value) => {
        console.log("Capcha value:" + JSON.stringify(value))
        await AuthenticationService.verifyCapcha(value.response).then((res) => {
            console.log("Res from google: " + res)
            toast.current.show({ severity: 'info', summary: 'Success', detail: 'Captcha Verified Successfully (Not a BOT)', life:5000 });
            setCaptchaVerified(true);
        }).catch((error) => {
            console.log("Captcha verify", error)
            toast.current.show({ severity: 'info', summary: 'Error', detail: 'BOT Detected' });
        })
    }

    return (
        <div className='register-container'>
            <div>
        {(MAINTENANCE_MODE.toString() === "true" ||MAINTENANCE_MODE.toString() === "True" )  && <div className="maintainance-container"><div className="floatAboveEverything maintainance-warn"><i className="pi pi-exclamation-triangle" style={{'fontSize': '1.5em',marginRight:"10px"}}></i> {MAINTENANCE_MESSAGE}</div></div>}
          <div className={(MAINTENANCE_MODE.toString() === "true" ||MAINTENANCE_MODE.toString() === "True" )?"maintainance-mode":"login"}>
            <Toast ref={toast} position="top-center" />
            <div className="p-grid register-grid">
            <div className="main-logo p-ai-center">
                        {/* <img className="vmware-logo" src={textLogo} alt="textLogo" /> */}
                        <Link to="/" className='p-d-flex back-link'><div className='p-d-flex'>
                        <img className='back-arrow-img' src={BackArrow} alt="textLogo" />
                        <p className='p-ml-1 back-arrow-link'>Back</p>
                    </div>
                    </Link>
            </div>
                <div className="p-col-5 register-left-screen">


                    <div>
                        {/* <div className="logo p-d-flex p-jc-center"> */}
                        <div>
                            {/* <img className="logo-image" src={Logo} alt="Logo" /> */}
                            <div className="logo p-d-flex p-jc-center">
                                <img src={LogoVmwBcmSdeLabs} alt="Logo" className={"sameline"}/>
                            </div>
                        {/* </div> */}
                        <div className='form-fields p-fluid p-mx-auto'>
                            {/* {validErrorMsg && <div className='protal-access-quote-div'>
                                <span className='mandatory-label'>{validErrorMsg}</span>
                            </div>} */}
                            {validErrorMsg && <Message severity="warn" text={validErrorMsg} className="common-inline-error" />}
                            <div >
                                <p className='register-title'>Register New User</p>
                            </div>
                            <div>
                                <InputText placeholder='First Name*' keyfilter={/^[a-zA-Z0-9 ]*$/} className={fnameError ? 'input-border-error-color input-field-common' : 'input-field-common'} value={firstName} onChange={onFnameChange} />
                                <div>
                                    {fnameError && (
                                        <small className="input-error-message  p-d-block" id="error-message">{fnameErrorMsg}</small>
                                    )}
                                </div>
                            </div>
                            <div className='p-mt-3'>
                                <InputText placeholder='Last Name*' keyfilter={/^[a-zA-Z0-9 ]*$/} className={lnameError ? 'input-border-error-color input-field-common' : 'input-field-common'} value={lastName} onChange={onLnameChange} />
                                <div>
                                    {lnameError && (
                                        <small className="input-error-message  p-d-block" id="error-message">{lnameErrorMsg}</small>
                                    )}
                                </div>
                            </div>
                            <div className='p-mt-3'>

                                <InputText placeholder='Business Email*' className={emailError ? 'input-border-error-color input-field-common' : 'input-field-common'} value={email} onChange={onEmailChange} />
                                <div>
                                    {emailError && (
                                        <small className="input-error-message  p-d-block" id="error-message">{emailErrorMsg}</small>
                                    )}
                                </div>
                            </div>
                            <div className='p-mt-3'>
                                <InputText placeholder='Company/Organization Name*' keyfilter={/^[a-zA-Z0-9 ]*$/} className={businessNameError ? 'input-border-error-color input-field-common' : 'input-field-common'} value={businessName} onChange={onBusinessNameChange} />
                                <div>
                                    {businessNameError && (
                                        <small className="input-error-message  p-d-block" id="error-message">{businessNameErrorMsg}</small>
                                    )}
                                </div>
                            </div>
                            <div className='p-mt-3'>
                                <InputText placeholder='VMWare/Partner Contact Name/Email*' keyfilter={/^[a-zA-Z0-9 ]*$/} className={customerPartnerNameError ? 'input-border-error-color input-field-common' : 'input-field-common'} value={customerPartnerName} onChange={onCustomerPartnerNameChange} />
                                <div>
                                    {customerPartnerNameError && (
                                        <small className="input-error-message  p-d-block" id="error-message">{customerPartnerNameErrorMsg}</small>
                                    )}
                                </div>
                            </div>
                            <div className='p-mt-3'>
                                <InputTextarea placeholder='Brief Description of Project/Engagement*'  className={purposeError ? 'input-border-error-color textarea-field-common' : 'textarea-field-common'} value={purpose} onChange={onPurposeChange} />
                                <div>
                                    {purposeError ?
                                        <small className="input-error-message  p-d-block" id="error-message">{purposeErrorMsg}</small>:
                                   purposeValid? <small  className="valid-message" id="error-message">{purposeValidError}</small>:"" }
                                </div>
                            </div>
                            <div className="card">
                                <br/>
                                <Captcha
                                    siteKey={process.env.REACT_APP_SITE_KEY}
                                    onResponse={verifyCaptcha}
                                />
                            </div>
                            <div className="checkbox-div p-d-flex p-ai-center p-mt-3">
                                <Checkbox className="reg-checkbox" inputId="binary" checked={checked} onChange={onCheckedChange} />
                                <label className='reg-checkbox-label p-ml-1' htmlFor="binary">I accept the <span className='terms-condition-span'><PortalPolicy /></span></label>
                            </div>
                            <div>
                                {termsError && (
                                    <small className="input-error-message p-d-block" id="error-message">{termsErrorMsg}</small>
                                )}
                            </div>
                            <div className='portal-access-btn p-mt-3'>
                                <div className='req-portal-access-div p-d-flex p-jc-center'>
                                    <Button label="Request portal access" onClick={requestPortalAccess} disabled={isRequestPortalFormValid() ? false : true} className={isRequestPortalFormValid() ? "req-portal-access-btn" : "req-portal-access-btn-disabled"} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="register-footer p-d-flex p-jc-center">
                        <Footer/>
                    </div>
                    </div>
                </div>
                <div className="p-col-7 register-right-screen-image"></div>
            </div>

            {/* success dialog */}
            <Dialog header="Thank you." visible={displaySuccessDialog} footer={renderSuccessFooter('displaySuccessDialog')} onHide={() => onHide('displaySuccessDialog')} className="access-success-dialog">
                <p>Your request for portal access has been received. You will be notified via email once access has been provided.</p>
            </Dialog>
        </div>
        </div>
        </div>
    )
}

export default Register
