import React, { useEffect } from 'react'
import { Button } from 'primereact/button'
import { Link, useSearchParams, useNavigate } from 'react-router-dom'
import Authentication from "../../services/AuthenticationService";
import loglevel from "../../logger/Logger"

const Sso = () => {
    // for navigating to particular url
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    let responseId = searchParams.get("responseId");

    useEffect(() => {
            let users_id = searchParams.get("users_id");
            let fname = searchParams.get("fname");
            let lname = searchParams.get("lname");
            localStorage.setItem("accessToken", searchParams.get("accessToken"));
            localStorage.setItem("expiresIn", searchParams.get("expiresIn"));
            localStorage.setItem("isAuthenticated", "true");
            localStorage.setItem("users_id", users_id);
            localStorage.setItem("fname", fname);
            localStorage.setItem("lname", lname);
            localStorage.setItem("email", searchParams.get("email"));
            //navigating to lab listing page
            navigate("/home/labs/1")

            Authentication.getPortalUserCount().then((res) => {
                loglevel.debug("User Portal Count", res);
              }).catch((error) => {
                loglevel.error("User Portal Count", error)
            })
    }, [responseId,searchParams,navigate]);


    const template1 = responseId === localStorage.getItem('ssoRequestId') ? <></> : <div className='sso'>
        <p>Something went wrong. Please try later</p>
        <Link to="/" style={{ textDecoration: "none" }}><Button>Go Back</Button></Link>
    </div>

    return (<div>{ template1 }</div>)
}

export default Sso
