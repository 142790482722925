import React, { useEffect, useState } from 'react'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import LabServices from '../../services/LabServices';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import DeleteAdminMessage from './DeleteAdminMessage';

const AdminMessageHistory = () => {
  const [messageHistory, setMessageHistory] = useState([]);

  useEffect(() => {
    // api call for message History
    LabServices.getAdminMessageHistory().then((response) => {
      console.log("response", response.data);
      setMessageHistory(response.data);
    })
    return () => {
      setMessageHistory([]);
    }
  }, [])


  // index column in datatable
  const indexTemplate = (rowData, props) => {
    return <span className="index-field">{props.rowIndex + 1}</span>
  }

  //recipient column in datatable
  const recepientsTemplate = (rowData) => {
    const groups = rowData.groups;
    const tags = rowData.tags;
    const getRecepient = groups.length ? groups : tags;
    const recepients = getRecepient.map((recepient) => {
      return tags.length ? <Tag key={recepient.toString()} className="p-m-1 tags" value={recepient}></Tag> : <Button key={recepient.toString()} className="p-m-1 group-btn-tag" ><svg width="15.8" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M11.1187 4.13943C11.1255 4.26739 11.1255 4.39563 11.1187 4.5236C11.2256 4.54054 11.3335 4.54984 11.4417 4.55144H11.5475C12.6317 4.49362 13.5243 3.67839 13.6799 2.60385C13.8355 1.5293 13.2108 0.494337 12.1876 0.131318C11.1643 -0.2317 10.0271 0.178203 9.47069 1.11058C10.4943 1.77892 11.1135 2.91695 11.1187 4.13943ZM9.95509 4.1448C9.95509 5.51932 8.84083 6.63358 7.46632 6.63358C6.09181 6.63358 4.97754 5.51932 4.97754 4.1448C4.97754 2.77029 6.09181 1.65603 7.46632 1.65603C8.84083 1.65603 9.95509 2.77029 9.95509 4.1448ZM3.64121 9.46222C4.70076 8.44258 6.13059 7.89961 7.59987 7.95893C9.06651 7.89545 10.4945 8.43924 11.5474 9.46222L11.681 9.62925V13.1425C11.6766 13.3743 11.5803 13.5949 11.4132 13.7557C11.2461 13.9166 11.022 14.0044 10.7902 13.9999H4.39286C4.16101 14.0044 3.93689 13.9166 3.76981 13.7557C3.60274 13.5949 3.50641 13.3743 3.50202 13.1425V9.61811L3.64121 9.46222ZM3.86958 4.55731H3.69698V4.57958C2.60774 4.58013 1.67063 3.80925 1.46114 2.74035C1.25166 1.67144 1.82845 0.603859 2.83732 0.193212C3.84619 -0.217434 5.00459 0.143856 5.60114 1.05521C4.38392 1.77904 3.70575 3.15064 3.86958 4.55731ZM14.9661 7.02916C14.0185 6.08586 12.7222 5.5778 11.386 5.62609C11.1833 5.62667 10.9807 5.63782 10.7791 5.6595C10.5242 6.19785 10.142 6.66603 9.66557 7.02359C10.7279 7.31749 11.6902 7.89488 12.4494 8.69391L12.5886 8.84981L12.8893 9.19501V11.8174H15.083V7.18506L14.9661 7.02916ZM3.71367 5.64259H4.19807C4.44833 6.21642 4.84125 6.71667 5.33945 7.09576C4.34437 7.39547 3.44193 7.94345 2.71705 8.68813L2.57786 8.8496L2.27163 9.1948V11.8116H0V7.20155L0.133625 7.04565C1.08117 6.10235 2.37751 5.5943 3.71367 5.64259Z" fill="#49AFD9" />
      </svg>
        {recepient}</Button>;
    })
    return (!tags.length && !groups.length ? <span>All</span> : <span className={tags ? "data-tag" : "data-groups"}>{recepients}</span>)
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="table-action-body">
        <div className="table-delete-icon">
          {/* Delete admin message component */}
          <DeleteAdminMessage message_id={rowData.message_id} setChanger={setMessageHistory} />
        </div>
      </div>
    );
  }

  return (
    <DataTable
      responsiveLayout="scroll"
      value={messageHistory}
      className="user-permission-table lab-table-type"
      columnResizeMode="fit"
      paginator
      rows={50}
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      autoLayout={true}
      removableSort
      pageLinkSize={1}
    >
      <Column
        field="id"
        header="#"
        body={indexTemplate}
        style={{ minWidth: "3rem" ,paddingLeft:"15px" }}
      ></Column>
      <Column field='message' header="Message" style={{ width: "25rem" }}></Column>
      <Column body={recepientsTemplate} header="Recepients" style={{ minWidth: "10rem", maxWidth: "18rem" }}></Column>
      <Column field='shared_date' sortable header="Shared Date" style={{ width: "8rem" }}></Column>
      <Column field='expiry_date' sortable header="Expiry Date" style={{ width: "8rem" }}></Column>
      <Column body={actionBodyTemplate} exportable={false} style={{ width: "8rem"}}></Column>
    </DataTable>
  )
}

export default AdminMessageHistory