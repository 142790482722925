import axios from "axios";
import { HOST_URI } from "./config/configuration";

export default axios.create({
  baseURL: `${HOST_URI}`,
  headers: {
    crossDomain: true,
    accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  }
});