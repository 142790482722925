import React, { useState, useEffect } from "react";
import '../../App.css'
import Header from './../header/Header';
import Sidebar from './../sidebar/Sidebar';
import { Outlet } from "react-router-dom";
import IdleTimeOutHandler from '../session-timeout/IdleTimeOutHandler'
import loglevel from "../../logger/Logger";
import { useGlobalState } from "../../config/state";
import LabServices from "../../services/LabServices";
import eventBus from "../utility/EventBus";
import BroadcastMessage from "../broadcast-messages/BroadcastMessage";


function Dashboard() {
  const [isAdmin, setIsAdmin] = useGlobalState('isAdmin');
  const [labCategories, setLabCategories] = useGlobalState('labCategories');

  useEffect(async () => {
    if (performance.navigation.type === 1) {
      if (localStorage.getItem("users_id")) {
        eventBus.dispatch("pageReloaded", { message: "pageReloaded" });
      }
    }

  }, [])

  loglevel.debug("Entering into Dashboard Component");
  const [isActive, setIsActive] = useState(true)
  const [isLogout, setLogout] = useState(false)
  return (<>
    <Header />
    <IdleTimeOutHandler
      // onActive={()=>{setIsActive(true)}} 
      // onIdle={()=>{setIsActive(false)}}
      // onLogout={()=>{setLogout(true)}}


      onActive={() => { setIsActive(isActive) }}
      onIdle={() => { setIsActive(!isActive) }}
      onLogout={() => { setLogout(!isLogout) }}
    />
    <div className="content">
      <Sidebar />
      <div  style={{ width: "84%" }}>
        <div>
        <BroadcastMessage />
        </div>
        <div className="outlet-section">
        <Outlet />
        </div>
      </div>
    </div>
  </>
  )
}

export default Dashboard;