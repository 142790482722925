import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import LabServices from "../../../../services/LabServices";
import "../../Rbac.css";
import LabTypeUpdate from "./LabTypeUpdate";
import LabTypeCreate from "./LabTypeCreate";
import LabTypeDelete from "./LabTypeDelete";
import loglevel from "../../../../logger/Logger";
import { InputText } from "primereact/inputtext";

const LabType = () => {

  const [labType, setLabType] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);

// useEffect hook will call fetchData() after render.
  useEffect(() => {
    async function fetchData() {
      await LabServices.getLabType().then((res) => {
        console.log(res.data, "Lab list")
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].is_static_lab = res.data[i].is_static_lab ? "Yes" : "No"
        }
        let labTypeDetails = res.data.filter((type) => {
          return type.is_default == false
        })
        setLabType(labTypeDetails)

      })
        .catch((error) => {
          loglevel.error("getLabTypeList", error);
        });
    }
    fetchData();
  }, []);



// Styling for datatable column 'index' 
  const onIndexTemplate = (data, props) => {
    return (
      <div className="cat-name-table">
        {props.rowIndex + 1}
      </div>)
  }

  // Styling for datatable column 'Lab Name'
  const labnameTemplate = (rowData) => {
    return (
      <div className="cat-name-table">
        {rowData.name}
      </div>)
  }

  // Styling for datatable column 'Static'
  const isStaticTemplate = (rowData) => {
    return (
      <div >
        {rowData.is_static_lab}
      </div>)
  }

  // Styling for datatable column 'Description'
  const descriptionTemplate = (rowData) => {
    return <div className="short-desc">{rowData.description}</div>;
  };

  // Styling for datatable column 'Action' i.e. Update and Delete
  const actionBodyTemplate = (rowData) => {
    return (

      <div className="table-action-body">
        <div className="table-update-icon">
          {/* Pass data from LabType file to LabTypeUpdate file */}
          <LabTypeUpdate labTypeId={rowData.labTypeId} nameLabType={rowData.name} setChanger={setLabType} />
        </div>
        <div className="table-delete-icon">
          {/* Pass data from LabType file to LabTypeDelete file */}
          <LabTypeDelete labTypeId={rowData.labTypeId} nameLabType={rowData.name} setChanger={setLabType} />
        </div>
      </div>
    );
  };

// Render output UI
  return (
    <div className="datatable-crud-demo">
      <div className="admin-panel">
        <div className="admin-panel-search-bar">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search"
              className="global-search-filter"
            />
          </span>
        </div>
        {/* Pass data from LabType file to LabTypeCreate file */}
        <LabTypeCreate setChanger={setLabType} />
      </div>

      {/* Server Error showing place for Dialogue Box */}
      <Toast ref={toast} position="top-center" />
      {/* Datatable to show list of all workflow(Lab Type)*/}
      <DataTable
        value={labType}
        ref={dt}
        className="lab-type-crud-table"
        columnResizeMode="fit"
        paginator
        rows={50}
        breakpoint="1024px"
        pageLinkSize={1}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        globalFilter={globalFilter}
        responsiveLayout="scroll"
        autoLayout={true}
        removableSort={true}
        let-i="rowIndex"
        style={{ width: "100%" }}
      >
        <Column
          field="Index"
          header="#"
          body={onIndexTemplate}
          style={{ width: "3rem", fontWeight: "600" }}
        ></Column>
        <Column
          sortable
          field="name"
          header="Workflow"
          body={labnameTemplate}
          style={{ minWidth: "7rem", fontWeight: "600" }}>
        </Column>
        <Column
          sortable
          field="description"
          header="Description"
          body={descriptionTemplate}
          style={{ minWidth: "11rem", maxWidth: "14px" }}>
        </Column>
        <Column
          sortable
          field="is_static_lab"
          header="Static"
          body={isStaticTemplate}
          style={{ minWidth: "7rem" }}>
        </Column>
        <Column
          sortable
          field="preprocess_total_hours"
          header="PreProcess Time (Hours)"
          style={{ minWidth: "11rem",textAlign: "center"  }}>
        </Column>
        <Column
          sortable
          field="postprocess_total_hours"
          header="PostProcess Time (Hours)"
          style={{ minWidth: "11rem" ,textAlign: "center" }}>
        </Column>
        <Column
          sortable
          field="timeout_hours"
          header="Timeout (Hours)"
          style={{ minWidth: "11rem" ,textAlign: "center" }}>
        </Column>
        <Column
          body={actionBodyTemplate}
          exportable={false}
          style={{ minWidth: "4rem" }}
        ></Column>
      </DataTable>
    </div>
  );
};

export default LabType;
