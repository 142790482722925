import "./UserPermission.css";
import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Tag } from "primereact/tag";
import LabServices from "../../../../services/LabServices";
import CreateUser from "./CreateUser";
import UpdateUser from "./UpdateUser";
import loglevel from "../../../../logger/Logger";
import LoadingSpinner from "../../../loader/LoadingSpinner";
import DeleteUser from './DeleteUser'

const UserPermission = () => {

  const [userDetails, setUserDetails] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const [loading, setLoading] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const MAX_TAGS = 7;
  const MAX_GROUPS = 3;

  useEffect(() => {
    setLoading(true);
    async function getUserPermissions() {
      //api call for list user 
      await LabServices.getUserPermissions().then((res) => {
        // setting total registered users
        setUserCount(res.data.length)
        for (let i = 0; i < res.data.length; i++) {
          res.data[i]["closedStatus"] = true;
          res.data[i]["closedGroupsStatus"] = true;
          res.data[i]["name"] = res.data[i].firstName + " " + res.data[i].lastName
        };
        setUserDetails(res.data)
      }).catch((error) => {
        loglevel.error("getUserPermissions", error);
      }).finally(() => {
        setLoading(false)
      })
    }
    getUserPermissions();
  }, []);

  if (loading) {
    return <div className="loader"><LoadingSpinner /></div>
  }

  // on click show more/show less tag
  const toggle = (props, rowData) => {
    rowData.closedStatus = !rowData.closedStatus
    setUserDetails((prevState) => [...prevState])
  }

  // on click show more/show less group tag
  const toggleGroups = (props, rowData) => {
    rowData.closedGroupsStatus = !rowData.closedGroupsStatus
    setUserDetails((prevState) => [...prevState])
    console.log("closedGroupsStatus", rowData)
  }

  const tagTemplate = (rowData, props) => {
    let tagsArray = rowData.tags;
    let allTagsCount = tagsArray?.length;
    let hiddenTagscount = (allTagsCount - MAX_TAGS);
    let closedStatusRow = rowData.closedStatus

    if (closedStatusRow) {
      // if tags are more than max limit
      tagsArray = rowData.tags.slice(0, MAX_TAGS)
    }
    else {
      tagsArray = rowData.tags
    }
    const val = tagsArray?.map((r, index) => {
      return <Tag key={r.toString()} className="p-m-1 tags"><svg className="p-mr-1" width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.55336 5.48551L8.78688 5.25199C9.51244 4.5303 9.51576 3.35713 8.79429 2.63136L6.83345 0.681641C6.10972 -0.0418703 4.93656 -0.0418703 4.21283 0.681641L1.04361 3.85086C0.320099 4.57459 0.320099 5.74775 1.04361 6.47148L2.99333 8.4212C3.71706 9.14471 4.89022 9.14471 5.61395 8.4212L5.76222 8.27293L6.57028 9.07358H7.84538V10.1856H9.21315V10.7898L10.277 11.861H12.501V9.43313L8.55336 5.48551ZM11.7596 11.1198H10.5957L9.93958 10.4674V9.42957H8.57552V8.31756H6.86674L5.75473 7.20556L5.08753 7.89871C4.65338 8.33232 3.95005 8.33232 3.51589 7.89871L1.56618 5.93046C1.13256 5.4963 1.13256 4.79297 1.56618 4.35882L4.7391 1.1859C5.17325 0.752284 5.87658 0.752284 6.31073 1.1859L8.26045 3.13561C8.69407 3.56977 8.69407 4.2731 8.26045 4.70725L7.51911 5.44859L11.7596 9.74093V11.1198ZM2.7704 4.30611C2.58438 4.49212 2.58438 4.7937 2.7704 4.97972C2.95641 5.16573 3.25799 5.16573 3.444 4.97971L5.31541 3.1083C5.50142 2.92229 5.50142 2.62071 5.31541 2.4347C5.1294 2.24869 4.82782 2.24869 4.64181 2.4347L2.7704 4.30611Z" fill="black" />
      </svg>{r}</Tag>;
    }
    );
    return (
      <span>
        <span className="data-tag">{val}</span>
        {hiddenTagscount > 0 ? // for toggling option
          <span onClick={() => toggle(props, rowData)} className="show-more">

            {closedStatusRow ? `${hiddenTagscount} more tags` : `less tags`}</span> : ""}
      </span>
    )
  };

  const groupTemplate = (rowData, props) => {
    let groupsArray = rowData.groups;
    let allGroupsCount = groupsArray?.length;
    let hiddenGroupscount = (allGroupsCount - MAX_GROUPS);
    let closedGroupsStatusRow = rowData.closedGroupsStatus

    if (closedGroupsStatusRow) {
      // if groups are more than max limit defined
      groupsArray = rowData.groups.slice(0, MAX_GROUPS)
    }
    else {
      groupsArray = rowData.groups
    }
    //converting each group into tag
    const val = groupsArray?.map((value, index) => {
      return <Button key={value.toString()} className="p-m-1 group-btn-tag" ><svg className="p-mr-1" width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1187 4.13943C11.1255 4.26739 11.1255 4.39563 11.1187 4.5236C11.2256 4.54054 11.3335 4.54984 11.4417 4.55144H11.5475C12.6317 4.49362 13.5243 3.67839 13.6799 2.60385C13.8355 1.5293 13.2108 0.494337 12.1876 0.131318C11.1643 -0.2317 10.0271 0.178203 9.47069 1.11058C10.4943 1.77892 11.1135 2.91695 11.1187 4.13943ZM9.95509 4.1448C9.95509 5.51932 8.84083 6.63358 7.46632 6.63358C6.09181 6.63358 4.97754 5.51932 4.97754 4.1448C4.97754 2.77029 6.09181 1.65603 7.46632 1.65603C8.84083 1.65603 9.95509 2.77029 9.95509 4.1448ZM3.64121 9.46222C4.70076 8.44258 6.13059 7.89961 7.59987 7.95893C9.06651 7.89545 10.4945 8.43924 11.5474 9.46222L11.681 9.62925V13.1425C11.6766 13.3743 11.5803 13.5949 11.4132 13.7557C11.2461 13.9166 11.022 14.0044 10.7902 13.9999H4.39286C4.16101 14.0044 3.93689 13.9166 3.76981 13.7557C3.60274 13.5949 3.50641 13.3743 3.50202 13.1425V9.61811L3.64121 9.46222ZM3.86958 4.55731H3.69698V4.57958C2.60774 4.58013 1.67063 3.80925 1.46114 2.74035C1.25166 1.67144 1.82845 0.603859 2.83732 0.193212C3.84619 -0.217434 5.00459 0.143856 5.60114 1.05521C4.38392 1.77904 3.70575 3.15064 3.86958 4.55731ZM14.9661 7.02916C14.0185 6.08586 12.7222 5.5778 11.386 5.62609C11.1833 5.62667 10.9807 5.63782 10.7791 5.6595C10.5242 6.19785 10.142 6.66603 9.66557 7.02359C10.7279 7.31749 11.6902 7.89488 12.4494 8.69391L12.5886 8.84981L12.8893 9.19501V11.8174H15.083V7.18506L14.9661 7.02916ZM3.71367 5.64259H4.19807C4.44833 6.21642 4.84125 6.71667 5.33945 7.09576C4.34437 7.39547 3.44193 7.94345 2.71705 8.68813L2.57786 8.8496L2.27163 9.1948V11.8116H0V7.20155L0.133625 7.04565C1.08117 6.10235 2.37751 5.5943 3.71367 5.64259Z" fill="#89CBDF" />
      </svg>
        {value}</Button>;
    });
    return <span>
      <span className="data-group">{val}</span>
      {hiddenGroupscount > 0 ? // for toggling option
        <span onClick={() => toggleGroups(props, rowData)} className="show-more">

          {closedGroupsStatusRow ? `${hiddenGroupscount} more groups` : `less groups`}</span> : ""}
    </span>
  };

  const indexTemplate = (rowData, props) => {
    return <span className="index-field">{props.rowIndex + 1}</span>
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="table-action-body">
        <div className="table-update-icon">
          {/* Update user component */}
          <UpdateUser rowId={rowData.users_id} userName={rowData.name} setAlluserDetails={setUserDetails} />
        </div>
        <div className="table-delete-icon">
          {/* Delete user component */}
          <DeleteUser rowId={rowData.users_id} userName={rowData.name} setAlluserDetails={setUserDetails} setChanger={setUserCount} />
        </div>
      </div>
    );
  };

  // active/Inactive user
  const EnabledTemplate = (rowData) => {
    if (rowData.enabled) {
      return (
        <div className="enable-disable-container">
          <div className="enable-icon">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.777344 7.00005C0.777344 10.4365 3.56313 13.2223 6.99957 13.2223C8.6498 13.2223 10.2324 12.5667 11.3993 11.3998C12.5662 10.2329 13.2218 8.65029 13.2218 7.00005C13.2218 3.56362 10.436 0.777832 6.99957 0.777832C3.56313 0.777832 0.777344 3.56362 0.777344 7.00005ZM1.55512 7.00005C1.55512 3.99317 3.99268 1.55561 6.99957 1.55561C10.0064 1.55561 12.444 3.99317 12.444 7.00005C12.444 10.0069 10.0064 12.4445 6.99957 12.4445C3.99268 12.4445 1.55512 10.0069 1.55512 7.00005Z"
                fill="#61B715"
              />
              <path
                d="M6.28622 8.61488L4.54789 6.87821C4.49126 6.81209 4.49507 6.71352 4.55663 6.65195C4.61819 6.59039 4.71676 6.58659 4.78289 6.64321L6.28289 8.14321L9.21455 5.21488C9.28068 5.15825 9.37925 5.16206 9.44081 5.22362C9.50238 5.28518 9.50618 5.38375 9.44955 5.44988L6.28622 8.61488Z"
                fill="#61B715"
                stroke="#61B715"
                strokeWidth="0.4"
              />
            </svg>
          </div>

          <div className="datatable-enable-label">Active</div>
        </div>
      );
    } else {
      return (
        <div className="enable-disable-container">
          <div className="disable-icon">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.99957 13.2223C3.56313 13.2223 0.777344 10.4365 0.777344 7.00005C0.777344 3.56362 3.56313 0.777832 6.99957 0.777832C10.436 0.777832 13.2218 3.56362 13.2218 7.00005C13.2218 8.65029 12.5662 10.2329 11.3993 11.3998C10.2324 12.5667 8.6498 13.2223 6.99957 13.2223ZM6.99957 1.55561C3.99268 1.55561 1.55512 3.99317 1.55512 7.00005C1.55512 10.0069 3.99268 12.4445 6.99957 12.4445C10.0064 12.4445 12.444 10.0069 12.444 7.00005C12.444 3.99317 10.0064 1.55561 6.99957 1.55561ZM9.51568 5.11005L7.62568 7.00005L9.46123 8.82394C9.59337 8.97824 9.58448 9.20824 9.44084 9.35188C9.2972 9.49553 9.06719 9.50441 8.9129 9.37228L7.06957 7.52894L5.21457 9.38394C5.11953 9.49492 4.9703 9.54326 4.82824 9.50909C4.68618 9.47492 4.57526 9.364 4.54108 9.22194C4.50691 9.07988 4.55525 8.93065 4.66623 8.83561L6.52512 7.00005L4.62734 5.07894C4.49521 4.92465 4.50409 4.69465 4.64774 4.551C4.79138 4.40736 5.02138 4.39847 5.17568 4.53061L7.07734 6.43228L8.96734 4.56172C9.12164 4.42959 9.35164 4.43847 9.49528 4.58211C9.63893 4.72576 9.64781 4.95576 9.51568 5.11005Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="datatable-disable-label">Inactive</div>
        </div>
      );
    }
  };

  return (
    <div className="datatable-crud-demo">
      <div className="admin-panel">
        <div className="user-search-bar">
          <div className="p-input-icon-left">
            <i className="pi pi-search" />
            {/* Search bar */}
            <InputText
              type="search"
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search"
              className="global-search-filter"
            />
          </div>
          {/* Total Registered user count */}
          <div className="user-count">Total Registered Users : {userCount}</div>
        </div>
        {/* Create user component */}
        <CreateUser setAlluserDetails={setUserDetails} setChanger={setUserCount} />
      </div>
      <Toast ref={toast} position="top-center" />

      {/* Datatable for listing */}
      <DataTable
        value={userDetails}
        ref={dt}
        className="lab-table-type"
        columnResizeMode="fit"
        paginator
        rows={50}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        globalFilter={globalFilter}
        responsiveLayout="scroll"
        autoLayout={true}
        pageLinkSize={1}
        style={{ width: "100%" }}
        removableSort={true}
      >

        <Column
          field="id"
          header="#"
          style={{ fontWeight: "600", width: "3rem", paddingLeft: "15px" }}
          body={indexTemplate}
        ></Column>
        <Column sortable field="name" header="User Name" style={{ minWidth: "8rem", fontWeight: "600" }} ></Column>
        <Column sortable field="email" header="Email ID" style={{ minWidth: "13rem", maxWidth: "13rem" }}></Column>
        <Column
          sortable
          field="tags"
          header="Tags"
          body={tagTemplate}
          style={{ minWidth: "10rem", maxWidth: "18rem" }}
        ></Column>
        <Column
          field="groups"
          header="Groups"
          body={groupTemplate}
          style={{ minWidth: "14rem" }}
          sortable
        ></Column>
        <Column
          field="enabled"
          header="Status"
          body={EnabledTemplate}
          style={{ minWidth: "8rem" }}
          sortable
        ></Column>
        <Column
          className="setting-icon-column"
          body={actionBodyTemplate}
          style={{ minWidth: "3rem" }}
          colSpan={2}
        ></Column>
      </DataTable>
      <div>
      </div>
    </div>
  );
};

export default UserPermission;
