import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import "../../Rbac.css";
import "./OSTypes.css";
import React, { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import LabServices from "../../../../services/LabServices";
import loglevel from "../../../../logger/Logger";
import { Message } from "primereact/message";

const CreateOSType = ({ setChanger }) => {
  const [isValidated, setIsValidated] = useState(false);
  const [createOSTypeDisplay, setCreateOSTypeDisplay] = useState(false);
  const createOSTypeDialogMap = {
    createOSTypeDisplay: setCreateOSTypeDisplay,
  };
  const [osTypeName, setOSTypeName] = useState("");
  const [description, setDescription] = useState("");
  const [commonErrorMsg, setCommonErrorMsg] = useState("");

  const toast = useRef(null);
  const errorRef = useRef();

  const onClick = async (name) => {
    setOSTypeName("");
    setDescription("");
    setIsValidated(false);
    setCommonErrorMsg("");
    createOSTypeDialogMap[`${name}`](true);
  };

  const onHide = (name) => {
    setOSTypeName("");
    setDescription("");
    setIsValidated(false);
    setCommonErrorMsg("");
    createOSTypeDialogMap[`${name}`](false);
  };


  const onAdd = async () => {
    setIsValidated(true);
    <Toast ref={toast} position="top-center" />;
    if (osTypeName && description) {
      const requestBody = {
        name: `${osTypeName}`,
        description: `${description}`,
      };

      await LabServices.addOSType(requestBody)
        .then((response) => {
          if (response.status === 201) {
            toast.current.show({
              severity: "success",
              summary: "OS Type Successfully Created",
              detail: "The os type has been successfully created.",
              life: 6000,
            });
            createOSTypeDialogMap["createOSTypeDisplay"](false);
          } else {
            loglevel.debug("OS Type Creation is Failed");
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            setCommonErrorMsg(error.response.data.msg)
            errorRef.current.scrollIntoView({ behavior: "smooth" });;
          }
        });

      await LabServices.getCMDBLabTypeTags().then((res) => {
        setChanger(res.data);
      });
    }
  };

  const renderFooter = (name) => {
    return (
      <div>
        <Button
          className="calender-button"
          style={{ background: "#49AFD9" }}
          onClick={() => onAdd()}
        >
          <span className="name">CREATE OS Type</span>
        </Button>
      </div>
    );
  };

  return (
    <div className="admin-panel-content">
      <Toast ref={toast} position="top-center" />
      {/*<div className="admin-panel-labtype">
        <div className="admin-panel-item"> */}
      <div className="create-lab-button">
        <Button
          id="reset-button"
          label="Create New OS Type"
          className="p-button-primary"
          onClick={() => onClick("createOSTypeDisplay")}
        />
      </div>
      {/* </div>
      </div> */}
      <Dialog
        header="Create New OS Type"
        visible={createOSTypeDisplay}
        footer={renderFooter("createOSTypeDisplay")}
        onHide={() => onHide("createOSTypeDisplay")}
        className="tag-dialog-box"
      >
        <div ref={errorRef} className={commonErrorMsg ? "" : "hidden"}>
          {commonErrorMsg && <Message severity="warn" text={commonErrorMsg} className='common-inline-error common-error-message p-mb-3' />}
        </div>        <div className="tag-name-input-text">
          <div className="tag-name-label">OS Type Name :
            <span className="cat-required-field">*</span>
          </div>
          <div>
            <InputText
              value={osTypeName}
              onChange={(e) => setOSTypeName(e.target.value)}
              placeholder="Enter OS Type Name"
              maxLength={20}
              // className="lab-name-text-box"
              className={
                !osTypeName && isValidated ? "tag-input-error" : "tag-name-text-box"
              }
            />
            {!osTypeName && isValidated ? (
              <div className="cat-error-message-inline">
                <small className="cat-error-message" id="error-message">
                  OS Type Name is mandatory
                </small>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="tag-desc-input-text">
          <div className="tag-name-label">Description :
            <span className="cat-required-field">*</span>
          </div>
          <div>
            <InputTextarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter OS Type Description"
              maxLength={1000}
              autoResize={true}
              rows={2}
              cols={40}
              className={
                !description && isValidated ? "tag-desc-input-error" : "tag-desc-text-box"
              }
            />
            {!description && isValidated ? (
              <div className="cat-error-message-inline">
                <small className="cat-error-message" id="error-message">
                  Description is mandatory
                </small>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default CreateOSType;
