
import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import LabServices from "../../services/LabServices"
import loglevel from "../../logger/Logger"
import UpdateSettings from "./UpdateSettings";
import { Divider } from "primereact/divider";
import './Settings.css'

const Settings = () => {
  const [configurationData, setConfigurationData] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    async function fetchData() {
      // api call for listing all config value
      await LabServices.getConfigList()
        .then((res) => {
          loglevel.debug("getReservedLabs Response", res);
          setConfigurationData(res.data);
        })
        .catch((error) => {
          loglevel.error("getLabsCategoryList", error);
        });
    }
    fetchData();
  }, []);

  // method call for updating setting
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="tag-edit-delete">
        <UpdateSettings
          configName={rowData.config_name}
          setChanger={setConfigurationData}
        />
      </div>
    );
  };

  const onIndexTemplate = (data, props) => {
    return <div className="cat-name-table">{props.rowIndex + 1}</div>;
  };

  // config name in datatable
  const configNameTemplate = (rowData) => {
    return <div className="cat-name-table">{rowData.config_name}</div>;
  };

// config values in datatable
  const configValueTemplate = (rowData) => {
    return <div>{rowData.config_value}</div>;
  };

  //config description 
  const descriptionTemplate = (rowData) => {
    return (
      <div className="cat-data-short-desc-table">{rowData.description}</div>
    );
  };


  return (
    <div className="datatable-crud-demo">
      <div className="configuration-title">Configuration Settings</div>
      <Divider />
      <div className="admin-panel">
        <div className="admin-panel-search-bar">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => setGlobalFilter(e.target.value)} // global filter for searching
              placeholder="Search"
              className="global-search-filter"
            />
          </span>
        </div>
      </div>
      <Toast ref={toast} position="top-center" />
      {/* DataTable for list cong values */}
      <DataTable
        value={configurationData}
        ref={dt}
        className="lab-crud-table"
        columnResizeMode="fit"
        paginator
        rows={50}
        pageLinkSize={1}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        globalFilter={globalFilter}
        responsiveLayout="scroll"
        autoLayout={true}
        let-i="rowIndex"
        style={{ width: "100%" }}
        removableSort={true}
      >
        <Column
          field="Index"
          header="#"
          body={onIndexTemplate}
          style={{ width: "3rem", paddingLeft: "15px",textAlign:"center" }}
        ></Column>
        <Column
          sortable
          field="config_name"
          header="Configuration Name"
          body={configNameTemplate}
          style={{ minWidth: "10rem" }}
        ></Column>
        <Column
          sortable
          field="description"
          header="Configuration Description"
          body={descriptionTemplate}
          style={{ width: "20rem" }}
        ></Column>
        <Column
          sortable
          field="config_value"
          header="Configuration Value"
          body={configValueTemplate}
          style={{ minWidth: "16rem" }}
        ></Column>
        <Column
          body={actionBodyTemplate}
          exportable={false}
          style={{ minWidth: "4rem" }}
        ></Column>
      </DataTable>
    </div>
  );
};

export default Settings