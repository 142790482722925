import http from "../http-comman";

class PortalService {
    
    openPage(name) {
        if (name === 'terms') {
            window.location.href = 'https://www.vmware.com/help/legal.html';
        } else if (name === 'calPrivacy') {
            window.location.href = 'https://www.vmware.com/help/privacy/california-privacy-rights.html';
        } else {
            window.location.href = 'https://www.vmware.com/help/privacy.html';
        }
    }

}

export default new PortalService();